import React from 'react';

interface Props {
  onEdit: (event: React.SyntheticEvent) => void;
  onDelete: (event: React.SyntheticEvent) => void;
}

const FieldControls: React.FC<Props> = ({ onEdit, onDelete }) => (
  <>
    <i
      onClick={onEdit}
      style={{ top: '7px', right: '35px', fontSize: '17px', fontWeight: 300 }}
      className="close position-absolute far fa-edit"
    />
    <i
      onClick={onDelete}
      style={{ top: '7px', right: '10px', fontSize: '17px', fontWeight: 300 }}
      className="close position-absolute far fa-trash-alt"
    />
  </>
);

export default FieldControls;
