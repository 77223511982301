import React, { useMemo } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { getSelectYearRange } from 'utils';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | number[] | null;
  range?: number[];
}

const SelectYear: React.FC<Props> = ({
  labelKey = 'Year',
  value,
  isMulti = false,
  range = getSelectYearRange(2021, 2050),
  ...props
}) => {
  const { options, values } = useMemo(
    () =>
      range.reduce(
        (acc: any, i: number) => {
          const item = { value: i, label: i };
          if ((Array.isArray(value) ? value : [value]).includes(item.value)) acc.values.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], values: [] }
      ),
    [value, range]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      isMulti={isMulti}
      value={isMulti ? values : values[0] || null}
      options={options}
      placeholderKey={!props.isLoading ? 'Select year' : ''}
    />
  );
};

export default SelectYear;
