import React from 'react';
import { ManualsModal } from 'components/_modals';
import { ManualsTable } from 'components/_tables';
import { DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageManuals: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isDataManualsEnabled}>
    <DataTableTemplate paddingBottom>
      <ManualsModal />
      <ManualsTable />
    </DataTableTemplate>
  </PagePermissionsProvider>
);

export default PageManuals;
