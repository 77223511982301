import React, { useCallback, useContext } from 'react';
import { MapContext } from 'context/map';
import { Button } from 'components/_common';
import { MapParams } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';

const ZoomInButton: React.FC = () => {
  const { map } = useContext(MapContext);

  const handleButtonClick = useCallback(() => map?.zoomTo(Math.round(map?.getZoom() + 1)), [map]);

  return (
    <Button
      variant="light"
      disabled={MapParams.maxZoom - (map?.getZoom() || 0) < 1}
      onClick={handleButtonClick}
      data-marker="main_map_zoom_plus"
      icon={<IconPlus />}
      tooltipKey="Zoom in"
      tooltipPlacement="left"
    />
  );
};

export default ZoomInButton;
