import styled from 'styled-components';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Table } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { categoriesFetchedSelector, categoriesSelector } from 'modules/assets/selectors';
import { fetchServiceTimeAction, copyFromPreviousYearAction } from 'modules/tasks';
import { Button, FormInput, ContentContainer, OverlayTriggerTooltip } from 'components/_common';
import { EmptyTable, Spinner } from '@utiligize/shared/components';
import { IconCalculator } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';
import { PaginationType } from 'constants/index';

const TableAssetServiceTime: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.TOTAL_HOURS));
  const year = filters?.year as number;
  const categoriesFetched = useSelector(categoriesFetchedSelector);
  const [serviceTimeFetched, setServiceTimeFetched] = useState<boolean>(false);
  const categories = useSelector(categoriesSelector);
  const { values, dirty, isSubmitting, handleChange, submitForm, resetForm } = useConfiguredFormik(year);
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleFetchServiceTimeResponse = useCallback(
    (action: Shared.ReduxAction<Tasks.ServiceTime[]>) => {
      const serviceTimeFormObj = action.payload.reduce(
        (acc: any, item: Tasks.ServiceTime) => {
          acc[item.serviceType][item.assetCategoryCode] = {
            hours: Math.floor(item.scheduledTime / 60),
            mins: item.scheduledTime % 60,
            calculatedTime: item.calculatedTime,
            numberOfRepairs: item.numberOfRepairs,
            co2e_scope_1_kg: item.co2e_scope_1_kg,
            co2e_scope_2_kg: item.co2e_scope_2_kg,
            co2e_scope_3_kg: item.co2e_scope_3_kg,
          };
          return acc;
        },
        { serviceAndInspection: {}, serviceOnly: {}, repairOnly: {} }
      );
      resetForm({ values: serviceTimeFormObj });
      setServiceTimeFetched(true);
    },
    [resetForm]
  );

  useEffect(() => {
    setServiceTimeFetched(false);
    dispatch(fetchServiceTimeAction(Number(year))).then(handleFetchServiceTimeResponse);
  }, [dispatch, year, handleFetchServiceTimeResponse]);

  const handleCopyFromPrevYearClick = useCallback((): void => {
    dispatch(
      setModalConfirmationMetaAction({
        title: getIntl('Are you sure to copy data from {{prevYear}} to {{year}}?', { prevYear: year - 1, year }),
        submitButtonProps: {
          labelKey: 'Copy',
        },
        onConfirm: () => dispatch(copyFromPreviousYearAction(year)).then(handleFetchServiceTimeResponse),
      })
    );
  }, [dispatch, getIntl, year, handleFetchServiceTimeResponse]);

  const renderDurationCell = (assetCategoryCode: Type.AssetCategories, serviceType: Tasks.ServiceType) => (
    <td className="d-flex align-items-center">
      <FormInput
        size="sm"
        type="number"
        name={`${serviceType}.${assetCategoryCode}.hours`}
        value={(values as any)[serviceType][assetCategoryCode]?.hours || ''}
        onChange={handleChange}
        min={0}
        placeholderKey="h"
      />
      <label className="mb-0 px-1">:</label>
      <FormInput
        size="sm"
        type="number"
        name={`${serviceType}.${assetCategoryCode}.mins`}
        value={(values as any)[serviceType][assetCategoryCode]?.mins || ''}
        onChange={handleChange}
        min={0}
        max={59}
        placeholderKey="m"
      />
    </td>
  );

  const renderCO2Cells = (assetCategoryCode: Type.AssetCategories, serviceType: Tasks.ServiceType) => (
    <>
      <td>
        <FormInput
          size="sm"
          type="number"
          name={`${serviceType}.${assetCategoryCode}.co2e_scope_1_kg`}
          value={(values as any)[serviceType][assetCategoryCode]?.co2e_scope_1_kg || ''}
          onChange={handleChange}
          min={0}
        />
      </td>
      <td>
        <FormInput
          size="sm"
          type="number"
          name={`${serviceType}.${assetCategoryCode}.co2e_scope_2_kg`}
          value={(values as any)[serviceType][assetCategoryCode]?.co2e_scope_2_kg || ''}
          onChange={handleChange}
          min={0}
        />
      </td>
      <td>
        <FormInput
          size="sm"
          type="number"
          name={`${serviceType}.${assetCategoryCode}.co2e_scope_3_kg`}
          value={(values as any)[serviceType][assetCategoryCode]?.co2e_scope_3_kg || ''}
          onChange={handleChange}
          min={0}
        />
      </td>
    </>
  );

  const renderTableBody = () => {
    if (!categoriesFetched || !serviceTimeFetched) {
      return (
        <EmptyTable colSpan={11}>
          <Spinner />
        </EmptyTable>
      );
    }
    return categories.map(category => (
      <tr key={category.id}>
        <td>{category.code}</td>
        <td>{getIntl(category.name)}</td>
        <td>{(values as any)['serviceAndInspection'][category.code]?.calculatedTime}</td>
        {renderDurationCell(category.code, 'serviceAndInspection')}
        {renderCO2Cells(category.code, 'serviceAndInspection')}
        <td>{(values as any)['serviceOnly'][category.code]?.calculatedTime}</td>
        {renderDurationCell(category.code, 'serviceOnly')}
        {renderCO2Cells(category.code, 'serviceOnly')}
        <td>{(values as any)['repairOnly'][category.code]?.calculatedTime}</td>
        {renderDurationCell(category.code, 'repairOnly')}
        {renderCO2Cells(category.code, 'repairOnly')}
      </tr>
    ));
  };

  const renderCalculateTimeTh = (overlayId: string) => (
    <th rowSpan={2} className="text-center">
      <OverlayTriggerTooltip overlayId={overlayId} overlayChildren={getIntl('Calculated time')}>
        <IconCalculator width={20} height={20} />
      </OverlayTriggerTooltip>
    </th>
  );

  return (
    <StyledForm onSubmit={handleFormSubmit}>
      <ContentContainer>
        <div className="dataTables_wrapper dt-bootstrap4">
          <Row>
            <Col className="d-flex justify-content-between mb-2">
              <div className="ml-auto">
                <Button
                  type="button"
                  className="mr-2"
                  labelKey={getIntl('Copy from {{year}} year', { year: year - 1 })}
                  onClick={handleCopyFromPrevYearClick}
                />
                <Button disabled={!dirty || isSubmitting} type="submit" labelKey="Save" variant="primary" />
              </div>
            </Col>
          </Row>
          <Table bordered striped className="utiligize-table dataTable">
            <thead>
              <tr role="row">
                <th rowSpan={3} colSpan={2} className="align-middle">
                  {getIntl('Categories')}
                </th>
                <th colSpan={5} className="text-center">
                  {getIntl('Log Maintenance')}
                </th>
                <th colSpan={5} className="text-center">
                  {getIntl('Inspection')}
                </th>
                <th colSpan={5} className="text-center">
                  {getIntl('Repair')}
                </th>
              </tr>
              <tr role="row">
                {renderCalculateTimeTh('Log Maintenance')}
                <th rowSpan={2}>{getIntl('Scheduled time, per asset')}</th>
                <th colSpan={3} className="text-center">
                  {getIntl('CO₂e scopes (kg)')}
                </th>
                {renderCalculateTimeTh('Inspection')}
                <th rowSpan={2}>{getIntl('Scheduled time, per asset')}</th>
                <th colSpan={3} className="text-center">
                  {getIntl('CO₂e scopes (kg)')}
                </th>
                {renderCalculateTimeTh('Repair')}
                <th rowSpan={2}>{getIntl('Scheduled time, per asset')}</th>
                <th colSpan={3} className="text-center">
                  {getIntl('CO₂e scopes (kg)')}
                </th>
              </tr>
              <tr role="row">
                <th className="text-center">{getIntl('1')}</th>
                <th className="text-center">{getIntl('2')}</th>
                <th className="text-center">{getIntl('3')}</th>
                <th className="text-center">{getIntl('1')}</th>
                <th className="text-center">{getIntl('2')}</th>
                <th className="text-center">{getIntl('3')}</th>
                <th className="text-center">{getIntl('1')}</th>
                <th className="text-center">{getIntl('2')}</th>
                <th className="text-center">{getIntl('3')}</th>
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </Table>
        </div>
      </ContentContainer>
    </StyledForm>
  );
};

const StyledForm = styled(Form)`
  td {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  input {
    min-width: 50px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
`;

export default TableAssetServiceTime;
