import React from 'react';
import { useLocale } from 'hooks';
import { Tabs, Tab, ChartIframe, RecalculationSpinner, ContentContainer } from 'components/_common';
import { Routes } from 'constants/index';

interface Props {
  loading: boolean;
}

const BudgetingBenchmarkingTabs: React.FC<Props> = ({ loading }) => {
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter>
      <Tab eventKey={Routes.BudgetingBenchmarking} title={getIntl('Efficiency targets (DKK)')}>
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of benchmarking efficiency targets (DKK)" src="revenue_bm" />
          </ContentContainer>
        )}
      </Tab>
      <Tab
        eventKey={`${Routes.BudgetingBenchmarking}${Routes.HASH.CumulativeEfficiencyTargets}`}
        title={getIntl('Cumulative efficiency targets')}
      >
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of cumulative benchmarking efficiency targets" src="revenue_bm_cumsum" />
          </ContentContainer>
        )}
      </Tab>
      <Tab
        eventKey={`${Routes.BudgetingBenchmarking}${Routes.HASH.EfficiencyTargetsPercentage}`}
        title={getIntl('Efficiency targets (%)')}
      >
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of benchmarking efficiency targets (%)" src="revenue_bm_pct" />
          </ContentContainer>
        )}
      </Tab>
    </Tabs>
  );
};

export default BudgetingBenchmarkingTabs;
