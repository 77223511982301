import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setLayoutAction } from 'modules/layouts';
import { componentPriceScenarioModalMetaSelector } from 'modules/layouts/selectors';
import { componentPriceScenarioSelectorFactory } from 'modules/setup/selectors';
import { Modal, Button, FormInput, HiddenFormSubmit } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';
import PortfolioInfo from './PortfolioInfo';

const ModalComponentPriceScenarios: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { id, isDuplicate }: Layouts.ModalMeta = useSelector(componentPriceScenarioModalMetaSelector) || {
    id: null,
    isDuplicate: false,
  };
  const componentPriceScenario: Setup.ComponentPriceScenario | undefined = useSelector(
    componentPriceScenarioSelectorFactory(id)
  );
  const isEditMode: boolean = Boolean(id && componentPriceScenario);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ componentPriceScenarioModalMeta: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, resetForm, setValues } =
    useConfiguredFormik({ id, toggleModal, isDuplicate });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!componentPriceScenario) return;
    setValues({
      description: isDuplicate
        ? `${componentPriceScenario.description} (${getIntl('copy')})`
        : componentPriceScenario.description,
      price_evolution: componentPriceScenario.price_evolution_percent,
    });
    setShow(true);
  }, [componentPriceScenario, isDuplicate, getIntl, setValues]);

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create scenario" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? (isDuplicate ? 'Duplicate scenario' : 'Edit scenario') : 'Create scenario'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              labelKey="Scenario name"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Price evolution (% of price change per year compared to the current one)"
              type="number"
              name="price_evolution"
              value={values.price_evolution ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={
                Boolean(touched.price_evolution && errors.price_evolution) ? (errors.price_evolution as string) : ''
              }
            />
          </Form.Group>
          <PortfolioInfo />
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalComponentPriceScenarios;
