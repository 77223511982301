import styled from 'styled-components';
import React from 'react';

const Card: React.FC<{
  marginBottom?: boolean;
  className?: string;
  children?: React.ReactNode;
}> = ({ marginBottom = false, className = '', children }) => (
  <StyledCard $marginBottom={marginBottom} className={className}>
    {children}
  </StyledCard>
);

const StyledCard = styled.div<{ $marginBottom: boolean }>`
  position: relative;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey100};
  border-radius: 6px;
  padding: 0.25rem 0.5rem 0.75rem;
  margin-bottom: ${props => (props.$marginBottom ? '0.5rem' : 0)};

  && label {
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: ${props => props.theme.colors.grey400};
    margin-bottom: 0;
    line-height: 1;
  }

  label + span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: ${props => props.theme.colors.grey900};
    line-height: 1;

    &.muted {
      color: ${props => props.theme.colors.grey300};
      font-weight: 400;
    }
  }

  ul {
    column-count: 4;
    padding-left: 15px;
    margin: 0;

    li {
      color: ${props => props.theme.colors.grey900};
      font-weight: 400;
      font-size: 12px;
    }
  }
`;

export default Card;
