import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'components/_common';
import { IconRecalculate } from '@utiligize/shared/resources';

export default function useRecalculateButton(action: () => Promise<void>) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleRecalculateButtonClick = useCallback(() => {
    setLoading(true);
    dispatch(action()).finally(() => setLoading(false));
  }, [dispatch, action]);

  const ButtonRecalculate = (
    <Button
      icon={<IconRecalculate />}
      labelKey="Recalculate"
      onClick={handleRecalculateButtonClick}
      loading={loading}
      variant="primary-outline"
    />
  );

  return { loading, ButtonRecalculate };
}
