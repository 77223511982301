import React from 'react';
import { Spinner } from 'components/_common';
import { useLocale } from 'hooks';

const RecalculationSpinner = () => {
  const { getIntl } = useLocale();
  return <Spinner inCard isInFullHeightContainer text={getIntl('Recalculation is in progress')} />;
};

export default RecalculationSpinner;
