import classNames from 'classnames';
import styled from 'styled-components';
import React, { useEffect, memo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal as BootstrapModal, ModalProps } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { onlyModalToastsSelector } from 'modules/layouts/selectors';
import { deleteToastAction } from 'modules/layouts';
import { Button, ButtonProps, Alert } from 'components/_common';

const ModalError: React.FC<Shared.Toast & { className?: string }> = memo(({ id, message, className = '' }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(deleteToastAction(id)), 4000);
  }, [id, dispatch]);

  return (
    <Alert key={id} className={classNames('text-center', className)} variant="danger">
      {message}
    </Alert>
  );
});

interface Props extends ModalProps {
  size?: 'sm' | 'lg' | 'xl';
  title?: string;
  titleKey?: string;
  backdrop?: true | false | 'static';
  children?: React.ReactElement;
  customButton?: React.ReactElement;
  cancelButtonProps?: ButtonProps;
  submitButtonProps?: ButtonProps;
  collapsible?: boolean;
}

const Modal: React.FC<Props> = ({
  size = 'lg',
  title = '',
  titleKey,
  backdrop = 'static',
  children,
  customButton,
  cancelButtonProps,
  submitButtonProps,
  collapsible = false,
  ...props
}) => {
  const { getIntl } = useLocale();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const onlyModalToasts = useSelector(onlyModalToastsSelector);

  const handleCollapsibleButtonClick = useCallback(() => {
    setCollapsed(collapsed => !collapsed);
  }, []);

  return (
    <StyledBootstrapModal
      backdrop={backdrop}
      size={size}
      centered
      {...props}
      data-collapsible={collapsible}
      data-collapsed={collapsed}
    >
      {(title || titleKey) && (
        <StyledBootstrapModalHeader closeButton data-collapsible={collapsible}>
          <BootstrapModal.Title>{title || (titleKey && getIntl(titleKey))}</BootstrapModal.Title>
          {collapsible && (
            <button type="button" className="close" onClick={handleCollapsibleButtonClick}>
              <span aria-hidden="true">{collapsed ? '+' : '-'}</span>
            </button>
          )}
        </StyledBootstrapModalHeader>
      )}
      {children && (
        <BootstrapModal.Body>
          {children}
          {Boolean(onlyModalToasts?.length) &&
            onlyModalToasts.map(toast => <ModalError key={toast.id} className="mt-3 mb-0" {...toast} />)}
        </BootstrapModal.Body>
      )}
      {(customButton || cancelButtonProps || submitButtonProps) && (
        <BootstrapModal.Footer>
          {customButton}
          {cancelButtonProps && (
            <Button
              dataMarker="popup_modal_close"
              variant="primary-outline"
              {...cancelButtonProps}
              labelKey={cancelButtonProps.labelKey || 'Cancel'}
            />
          )}
          {submitButtonProps && <Button marginLeft variant="primary" {...submitButtonProps} />}
        </BootstrapModal.Footer>
      )}
    </StyledBootstrapModal>
  );
};

const StyledBootstrapModal = styled(BootstrapModal)<{
  'data-collapsible': boolean;
  'data-collapsed': boolean;
}>`
  ${props =>
    props['data-collapsible'] &&
    `
      .modal-header {
        ${props['data-collapsed'] && `border-bottom: 0;`}
      }

      .modal-body {
        transition: ${
          props['data-collapsed']
            ? `max-height 0.15s ease-out;`
            : `max-height 0.15s ease-in, opacity 0.15s ease-in 0.15s;`
        };
        max-height: ${props['data-collapsed'] ? 0 : '1000px'};
        opacity: ${props['data-collapsed'] ? 0 : 1};
      }

      .modal-footer {
        transition: ${
          props['data-collapsed']
            ? `max-height 0.15s ease-out;`
            : `max-height 0.15s ease-in, opacity 0.15s ease-in 0.15s;`
        };
        max-height: ${props['data-collapsed'] ? 0 : `100px`};
        opacity: ${props['data-collapsed'] ? 0 : 1};
      }

      ${
        props['data-collapsed'] &&
        `
          .modal-body,
          .modal-footer {
            overflow: hidden;
            padding: 0;
          }
        `
      };
    `};
`;

const StyledBootstrapModalHeader = styled(BootstrapModal.Header)<{ 'data-collapsible': boolean }>`
  align-items: center;

  ${props =>
    props['data-collapsible'] &&
    `
      .close:last-child {
        margin-left: 1rem;
      }
    `};
`;

export default Modal;
