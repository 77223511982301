import React from 'react';
import { useLocale } from 'hooks';

const PageNotFound: React.FC = () => {
  const { getIntl } = useLocale();

  return (
    <div className="error-page mt-0 pt-5">
      <h2 className="headline text-warning"> 404</h2>
      <div className="error-content">
        <h3>
          <i className="fas fa-exclamation-triangle text-warning"></i> {getIntl('Oops! Page not found')}.
        </h3>
        <p>
          {getIntl(
            'We could not find the page you were looking for. Meanwhile, you may use the dashboard menu or the search form'
          )}
          .
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
