import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { fetchAssetCategoriesCNAIMTemplatesAction } from 'modules/assets';
import { categoriesCNAIMTemplatesSelector } from 'modules/assets/cnaim.selectors';
import { FormReactSelect } from 'components/_common';
import { PaginationType } from 'constants/index';

type Option = {
  value: string;
  label: string;
  link: string;
};

const SelectAssetTemplate: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const templates = useSelector(categoriesCNAIMTemplatesSelector);
  const { meta }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.CATEGORIES_CNAIM));

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchAssetCategoriesCNAIMTemplatesAction()).finally(() => setIsLoading(false));
  }, [dispatch]);

  const handleSelectChange = useCallback(
    (option: Option) => {
      dispatch(
        setPaginationAction({ type: PaginationType.CATEGORIES_CNAIM, modifier: { meta: { link: option.link } } })
      );
    },
    [dispatch]
  );

  const { value, options } = useMemo(
    () =>
      Object.keys(templates || {}).reduce(
        (acc: { value: Option | null; options: Option[] }, key: string) => {
          const item = { value: key, label: key, link: `${(templates as any)[key]}#${key}` };
          if (meta?.link === item.link) acc.value = item;
          acc.options.push(item);
          return acc;
        },
        { value: null, options: [] }
      ),
    [templates, meta]
  );

  return (
    <FormReactSelect
      labelKey=""
      isDisabled={!templates}
      value={value}
      options={options}
      onChange={handleSelectChange}
      isLoading={isLoading}
      isSearchable={false}
    />
  );
};

export default SelectAssetTemplate;
