import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode[];
  marginTop?: boolean;
}

const SectionHint: React.FC<Props> = ({ children, marginTop = false }) => (
  <StyledContainer $marginTop={marginTop}>{children}</StyledContainer>
);

const StyledContainer = styled.div<{ $marginTop: boolean }>`
  margin-top: ${({ $marginTop }) => ($marginTop ? 5 : 0)}px;
  color: var(--map-text-color-secondary-light);
  font-size: 10.5px;
  padding-left: 2px;
`;

export default SectionHint;
