import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

export const MapContext = createContext<{
  map: Map.MapboxMap | null;
  setMap: Dispatch<SetStateAction<Map.MapboxMap | null>>;
}>({ map: null, setMap: () => null });

const MapContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [map, setMap] = useState<Map.MapboxMap | null>(null);
  return <MapContext.Provider value={{ map, setMap }}>{children}</MapContext.Provider>;
};

export default MapContextProvider;
