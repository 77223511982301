import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setLayoutAction } from 'modules/layouts';
import { investmentWorkPriceModalMetaSelector } from 'modules/layouts/selectors';
import { workPriceSelectorFactory } from 'modules/setup/selectors';
import { Modal, Button, SelectGridZone, HiddenFormSubmit } from 'components/_common';
import SelectWorkType from './SelectWorkType';
import UnitsInputGroup from '../UnitsInputGroup';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalWorkPrice: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { id, isDuplicate }: Layouts.ModalMeta = useSelector(investmentWorkPriceModalMetaSelector) || {
    id: null,
    isDuplicate: false,
  };
  const workPrice: Setup.WorkPrice | undefined = useSelector(workPriceSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && workPrice);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ investmentWorkPriceModalMeta: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useConfiguredFormik({ id, toggleModal, isDuplicate });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!workPrice) return;
    setValues({
      work_type: workPrice.work_type,
      grid_zone_id: workPrice.grid_zone_id,
      price: workPrice.price,
      price_per_unit: workPrice.price_per_unit,
    });
    setShow(true);
  }, [workPrice, isDuplicate, getIntl, setValues]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, { name }: Type.SelectActionMetaBase) => {
      if (name === 'work_type') {
        setFieldValue('price_per_unit', option.priceUnit);
      }
      setFieldValue(name, option?.value ?? null);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create work price" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? (isDuplicate ? 'Duplicate work price' : 'Edit work price') : 'Create work price'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectWorkType
              name="work_type"
              value={values?.work_type || null}
              onChange={handleSelectChange}
              labelKey="Work type"
              onBlur={() => setFieldTouched('work_type')}
              errorKey={Boolean(touched.work_type && errors.work_type) ? (errors.work_type as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectGridZone
              name="grid_zone_id"
              labelKey="Grid zone"
              value={values?.grid_zone_id}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('grid_zone_id')}
              errorKey={Boolean(touched.grid_zone_id && errors.grid_zone_id) ? (errors.grid_zone_id as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <UnitsInputGroup
              labelKey="Price"
              name="price"
              value={values.price || ''}
              units={values.price_per_unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.price && errors.price) ? (errors.price as string) : ''}
              disabled={!values?.work_type}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalWorkPrice;
