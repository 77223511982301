import { createSelector } from 'reselect';
import {
  isPageTasksMobileSubmittedTabActiveSelector,
  isPageTasksUpcomingTabActiveSelector,
  isPageTasksTabActiveSelector,
  isPageTasksFinishedTabActiveSelector,
  isPageTaskDetailsActiveSelectorFactory,
} from 'modules/router/selectors';
import { employeesGanttChartTasksHashSelector, tasksGanttChartHashSelector } from 'modules/employees/selectors';
import {
  isPageTasksEmployeesGanttChartTabActiveSelector,
  isPageTasksTasksGanttChartTabActiveSelector,
} from 'modules/router/selectors';
import { PaginationType } from 'constants/index';

const rootSelector = (state: State.Root) => state.tasks;

// ------------------------------------
// NEW TASKS Selectors
// ------------------------------------
export const newTasksCountSelector = createSelector(rootSelector, (tasks: Tasks.Root): number => tasks.newTasksCount);

const newTasksHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.NewTask> => tasks.newTasksHash
);

export const newTasksSelector = createSelector(
  newTasksHashSelector,
  (newTasksHash: Type.Hash<Tasks.NewTask>): Tasks.NewTask[] => Object.values(newTasksHash)
);

// ------------------------------------
// TASKS COMPLAINTS Selectors
// ------------------------------------
export const tasksComplaintsCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.tasksComplaintsCount
);

const tasksComplaintsHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.TaskComplaint> => tasks.tasksComplaintsHash
);

export const tasksComplaintsSelector = createSelector(
  tasksComplaintsHashSelector,
  (tasksComplaintsHash: Type.Hash<Tasks.TaskComplaint>): Tasks.TaskComplaint[] => Object.values(tasksComplaintsHash)
);

// ------------------------------------
// MOBILE SUBMITTED TASKS Selectors
// ------------------------------------
export const mobileSubmittedTasksCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.mobileSubmittedTasksCount
);

const mobileSubmittedTasksHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.Task> => tasks.mobileSubmittedTasksHash
);

export const mobileSubmittedTasksSelector = createSelector(
  mobileSubmittedTasksHashSelector,
  (mobileSubmittedTasksHash: Type.Hash<Tasks.Task>): Tasks.Task[] => Object.values(mobileSubmittedTasksHash)
);

// ------------------------------------
// UPCOMING TASKS Selectors
// ------------------------------------
export const upcomingTasksCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.upcomingTasksCount
);

const upcomingTasksHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.Task> => tasks.upcomingTasksHash
);

export const upcomingTasksSelector = createSelector(
  upcomingTasksHashSelector,
  (upcomingTasksHash: Type.Hash<Tasks.Task>): Tasks.Task[] => Object.values(upcomingTasksHash)
);

// ------------------------------------
// TASKS Selectors
// ------------------------------------
export const tasksCountSelector = createSelector(rootSelector, (tasks: Tasks.Root): number => tasks.tasksCount);

const tasksHashSelector = createSelector(rootSelector, (tasks: Tasks.Root): Type.Hash<Tasks.Task> => tasks.tasksHash);

export const tasksSelector = createSelector(tasksHashSelector, (tasksHash: Type.Hash<Tasks.Task>): Tasks.Task[] =>
  Object.values(tasksHash)
);

export const tasksByUUIDCodeSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Tasks.UAVTask[] => tasks.tasksByUUIDCode
);

// ------------------------------------
// FINISHED TASKS Selectors
// ------------------------------------
export const finishedTasksCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.finishedTasksCount
);

const finishedTasksHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.Task> => tasks.finishedTasksHash
);

export const finishedTasksSelector = createSelector(
  finishedTasksHashSelector,
  (finishedTasksHash: Type.Hash<Tasks.Task>): Tasks.Task[] => Object.values(finishedTasksHash)
);

// Extra selectors
export const tasksActiveTypeSelector = createSelector(
  [
    isPageTasksMobileSubmittedTabActiveSelector,
    isPageTasksUpcomingTabActiveSelector,
    isPageTasksTabActiveSelector,
    isPageTasksFinishedTabActiveSelector,
  ],
  (
    isPageTasksMobileSubmittedTabActive: boolean,
    isPageTasksUpcomingTabActive: boolean,
    isPageTasksTabActive: boolean,
    isPageTasksFinishedTabActive: boolean
  ): Type.PaginationType | null => {
    switch (true) {
      case isPageTasksMobileSubmittedTabActive:
        return PaginationType.TASKS_MOBILE_SUBMITTED;
      case isPageTasksUpcomingTabActive:
        return PaginationType.UPCOMING_TASKS;
      case isPageTasksTabActive:
        return PaginationType.TASKS;
      case isPageTasksFinishedTabActive:
        return PaginationType.TASKS_FINISHED;
      default:
        return null;
    }
  }
);

export const taskSelectorFactory = (id: number | null) =>
  createSelector(
    [
      mobileSubmittedTasksHashSelector,
      upcomingTasksHashSelector,
      tasksHashSelector,
      finishedTasksHashSelector,
      employeesGanttChartTasksHashSelector,
      tasksGanttChartHashSelector,
      taskDetailsSelector,
      isPageTasksMobileSubmittedTabActiveSelector,
      isPageTasksUpcomingTabActiveSelector,
      isPageTasksTabActiveSelector,
      isPageTasksFinishedTabActiveSelector,
      isPageTasksEmployeesGanttChartTabActiveSelector,
      isPageTasksTasksGanttChartTabActiveSelector,
      isPageTaskDetailsActiveSelectorFactory(id),
    ],
    (
      mobileSubmittedTasksHash: Type.Hash<Tasks.Task>,
      upcomingTasksHash: Type.Hash<Tasks.Task>,
      tasksHash: Type.Hash<Tasks.Task>,
      finishedTasksHash: Type.Hash<Tasks.Task>,
      employeesGanttChartTasksHash: Type.Hash<Tasks.Task>,
      tasksGanttChartHash: Type.Hash<Tasks.Task>,
      taskDetails: Tasks.Root['taskDetails'],
      //
      isPageTasksMobileSubmittedTabActive: boolean,
      isPageTasksUpcomingTabActive: boolean,
      isPageTasksTabActive: boolean,
      isPageTasksFinishedTabActive: boolean,
      isPageTasksEmployeesGanttChartTabActive: boolean,
      isPageTasksTasksGanttChartTabActive: boolean,
      isPageTaskDetailsActive: boolean
    ): Tasks.Task | undefined => {
      if (!id) return;
      switch (true) {
        case isPageTasksMobileSubmittedTabActive:
          return mobileSubmittedTasksHash[`_${id}_`];
        case isPageTasksUpcomingTabActive:
          return upcomingTasksHash[`_${id}_`];
        case isPageTasksTabActive:
          return tasksHash[`_${id}_`];
        case isPageTasksFinishedTabActive:
          return finishedTasksHash[`_${id}_`];
        case isPageTasksEmployeesGanttChartTabActive:
          return employeesGanttChartTasksHash[`_${id}_`];
        case isPageTasksTasksGanttChartTabActive:
          return tasksGanttChartHash[`_${id}_`];
        case isPageTaskDetailsActive:
          return taskDetails || undefined;
        default:
          return;
      }
    }
  );

// ------------------------------------
// REPEATED TASKS SECTIONS Selectors
// ------------------------------------
export const repeatedTasksSectionsCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.repeatedTasksSectionsCount
);

const repeatedTasksSectionsHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.RepeatedTasksSection> => tasks.repeatedTasksSectionsHash
);

export const repeatedTasksSectionsSelector = createSelector(
  repeatedTasksSectionsHashSelector,
  (repeatedTasksSectionsHash: Type.Hash<Tasks.RepeatedTasksSection>): Tasks.RepeatedTasksSection[] =>
    Object.values(repeatedTasksSectionsHash)
);

export const repeatedTasksSectionsByDepartmentNumberSelectorFactory = (departmentNumber: number | null) =>
  createSelector(
    repeatedTasksSectionsSelector,
    (repeatedTasksSections: Tasks.RepeatedTasksSection[]): Tasks.RepeatedTasksSection[] => {
      return repeatedTasksSections.filter(section => section.departmentNumber === departmentNumber);
    }
  );

export const repeatedTasksSectionSelectorFactory = (id: number | null) =>
  createSelector(
    repeatedTasksSectionsHashSelector,
    (repeatedTasksSectionsHash: Type.Hash<Tasks.RepeatedTasksSection>): Tasks.RepeatedTasksSection =>
      repeatedTasksSectionsHash[`_${id}_`]
  );

// ------------------------------------
// EXT REPEATED TASKS Selectors
// ------------------------------------
const extRepeatedTasksHashSelectorFactory = (departmentNumber: number | null) =>
  createSelector(rootSelector, (tasks: Tasks.Root): Type.Hash<Tasks.ExtRepeatedTask> => {
    return tasks.tasksDepartmentsHash[departmentNumber!]?.externalTasksHash || {};
  });

const extRepeatedTasksIdsSelector = createSelector(rootSelector, (tasks: Tasks.Root): string[] => {
  if (!Object.keys(tasks.tasksDepartmentsHash).length) return [];
  return Object.values(tasks.tasksDepartmentsHash).reduce((acc: string[], department: Tasks.Department): string[] => {
    const taskNumbers = Object.values(department.externalTasksHash || {}).map(i => i.taskNumber);
    return [...acc, ...taskNumbers];
  }, []);
});

export const extRepeatedTasksSelectorFactory = (departmentNumber: number | null) =>
  createSelector(
    extRepeatedTasksHashSelectorFactory(departmentNumber),
    (extRepeatedTasksHash: Type.Hash<Tasks.ExtRepeatedTask>): Tasks.ExtRepeatedTask[] => {
      return Object.values(extRepeatedTasksHash);
    }
  );

export const extRepeatedTaskSelectorFactory = (departmentNumber: number | null, taskNumber: string | null) =>
  createSelector(
    extRepeatedTasksHashSelectorFactory(departmentNumber),
    (extRepeatedTasksHash: Type.Hash<Tasks.ExtRepeatedTask>): Tasks.ExtRepeatedTask | null => {
      return extRepeatedTasksHash?.[`_${taskNumber}_`] || null;
    }
  );

// ------------------------------------
// REPEATED TASKS Selectors
// ------------------------------------
export const repeatedTasksFetchedSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): boolean => tasks.repeatedTasksFetched
);

export const repeatedTasksLoadingSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): boolean => tasks.repeatedTasksLoading
);

const repeatedTasksHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.RepeatedTask> => tasks.repeatedTasksHash
);

const repeatedTasksSelector = createSelector(
  repeatedTasksHashSelector,
  (repeatedTasksHash: Type.Hash<Tasks.RepeatedTask>): Tasks.RepeatedTask[] => Object.values(repeatedTasksHash)
);

export const expandedRepeatedTasksSelectorFactory = (departmentNumber: number | null) =>
  createSelector(
    [repeatedTasksSelector, extRepeatedTasksIdsSelector],
    (repeatedTasks: Tasks.RepeatedTask[], extRepeatedTasksIds: string[]): Tasks.RepeatedTask[] => {
      return repeatedTasks.reduce((acc: Tasks.RepeatedTask[], task: Tasks.RepeatedTask) => {
        if (task.departmentNumber !== departmentNumber) return acc;
        const deleted = Boolean(extRepeatedTasksIds.length) && !extRepeatedTasksIds.includes(task.taskNumber);
        const expandedTask = { ...task, deleted };
        acc.push(expandedTask);
        return acc;
      }, []);
    }
  );

export const repeatedTasksSplitBySectionHashSelectorFactory = (departmentNumber: number | null) =>
  createSelector(
    expandedRepeatedTasksSelectorFactory(departmentNumber),
    (
      expandedRepeatedTasks: Tasks.RepeatedTask[]
    ): {
      [key: string]: Tasks.RepeatedTask[];
    } => {
      return expandedRepeatedTasks.reduce((acc: { [key: string]: Tasks.RepeatedTask[] }, task: Tasks.RepeatedTask) => {
        if (acc[task.workSectionName]) {
          acc[task.workSectionName].push(task);
        } else {
          acc[task.workSectionName] = [task];
        }
        return acc;
      }, {});
    }
  );
export const repeatedTaskSelectorFactory = (id: number | null) =>
  createSelector(
    repeatedTasksHashSelector,
    (repeatedTasksHash: Type.Hash<Tasks.RepeatedTask>): Tasks.RepeatedTask => repeatedTasksHash[`_${id}_`]
  );

// ------------------------------------
// CHECKLISTS Selectors
// ------------------------------------
export const checklistsCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.checklistsCount
);

const checklistsHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.Checklist> => tasks.checklistsHash
);

export const checklistsSelector = createSelector(
  checklistsHashSelector,
  (checklistsHash: Type.Hash<Tasks.Checklist>): Tasks.Checklist[] => Object.values(checklistsHash)
);

export const checklistSelectorFactory = (id: number | null) =>
  createSelector(
    checklistsHashSelector,
    (checklistsHash: Type.Hash<Tasks.Checklist>): Tasks.Checklist | undefined => checklistsHash[`_${id}_`]
  );

export const totalHoursSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Tasks.TotalHour[] => tasks.totalHours
);

export const totalHoursAmountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.totalHoursAmount
);

export const employeeHoursAvailableSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.employeeHoursAvailable
);

// ------------------------------------
// TASK HARDWARE Selectors
// ------------------------------------
export const taskHardwareCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.taskHardwareCount
);

export const taskHardwareSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Tasks.Hardware[] => tasks.taskHardware
);

// ------------------------------------
// TASK REGISTERED HOURS Selectors
// ------------------------------------

export const usersRegisteredHoursCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.usersRegisteredHoursCount
);

const usersRegisteredHoursHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.UserRegisteredHours> => tasks.usersRegisteredHoursHash
);

export const usersRegisteredHoursSelector = createSelector(
  usersRegisteredHoursHashSelector,
  (usersRegisteredHoursHash: Type.Hash<Tasks.UserRegisteredHours>): Tasks.UserRegisteredHours[] =>
    Object.values(usersRegisteredHoursHash)
);

export const tasksRegisteredHoursCountSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): number => tasks.tasksRegisteredHoursCount
);

const tasksRegisteredHoursHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.TaskRegisteredHours> => tasks.tasksRegisteredHoursHash
);

export const tasksRegisteredHoursSelector = createSelector(
  tasksRegisteredHoursHashSelector,
  (tasksRegisteredHoursHash: Type.Hash<Tasks.TaskRegisteredHours>): Tasks.TaskRegisteredHours[] =>
    Object.values(tasksRegisteredHoursHash)
);

// ------------------------------------
// OTHER Selectors
// ------------------------------------
export const tasksDepartmentsFetchedSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): boolean | null => tasks.tasksDepartmentsFetched
);

const tasksDepartmentsHashSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Type.Hash<Tasks.Department> => tasks.tasksDepartmentsHash
);

export const tasksDepartmentsSelector = createSelector(
  tasksDepartmentsHashSelector,
  (tasksDepartmentsHash: Type.Hash<Tasks.Department>): Tasks.Department[] => Object.values(tasksDepartmentsHash)
);

export const taskDetailsSelector = createSelector(
  rootSelector,
  (tasks: Tasks.Root): Tasks.Root['taskDetails'] => tasks.taskDetails
);
