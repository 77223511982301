import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.interventions;

export const interventionsCountSelector = createSelector(
  rootSelector,
  (interventions: Interventions.Root): number => interventions.interventionsCount
);

const interventionsHashSelector = createSelector(
  rootSelector,
  (interventions: Interventions.Root): Type.Hash<Interventions.Intervention> => interventions.interventionsHash
);

export const interventionsSelector = createSelector(
  interventionsHashSelector,
  (interventionsHash: Type.Hash<Interventions.Intervention>): Interventions.Intervention[] =>
    Object.values(interventionsHash)
);
