import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { appIsSupervisorUserSelector, isMaintenancePlanningEnabledSelector } from 'modules/app/selectors';
import { PageNotFound } from 'pages';
import { ViewUsers, ViewGroups, ViewAccessLogs, ViewSupervisor } from 'components/Admin';
import { Routes } from 'constants/index';

const PageAdmin: React.FC = () => {
  const appIsSupervisorUser = useSelector(appIsSupervisorUserSelector);
  const isMaintenancePlanningEnabled = useSelector(isMaintenancePlanningEnabledSelector);

  return (
    <Switch>
      <Route exact path={Routes.Admin} component={ViewUsers} />
      <Route exact path={Routes.AdminGroups} component={ViewGroups} />
      {isMaintenancePlanningEnabled && <Route exact path={Routes.AdminAccessLogs} component={ViewAccessLogs} />}
      {appIsSupervisorUser && <Route exact path={Routes.AdminSupervisor} component={ViewSupervisor} />}
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default PageAdmin;
