import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { scenarioCalculationSelector } from 'modules/setup/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { fetchScenarioCalculationAction, patchScenarioCalculationAction } from 'modules/setup';
import { DataTable, Checkbox, Alert } from 'components/_common';
import { PaginationType } from 'constants/index';
import { setModalConfirmationMetaAction } from 'modules/layouts';

const TableScenarioCalculation: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const portfolioId = useSelector(portfolioIdSelector);
  const items = useSelector(scenarioCalculationSelector);
  const count = items?.length || 0;

  const sendRequest = useCallback(() => dispatch(fetchScenarioCalculationAction()), [dispatch]);

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const scenarioId = Number(event.currentTarget.getAttribute('data-id'));
      const { name, checked } = event.currentTarget as { name: keyof Setup.ScenarioCalculation; checked: boolean };
      const currentDayAlreadySelectedItem = checked && items.find(item => item[name]);
      const action = (
        scenarioId: Setup.ScenarioCalculation['scenario_id'],
        name: keyof Setup.ScenarioCalculation,
        checked: boolean,
        skipPromiseResolveActions: boolean = false
      ) => dispatch(patchScenarioCalculationAction(scenarioId, { [name]: checked }, skipPromiseResolveActions));

      if (currentDayAlreadySelectedItem) {
        dispatch(
          setModalConfirmationMetaAction({
            titleKey: 'Do you want to replace scenario calculation for this day?',
            bodyComponent: (
              <Alert className="mb-0 text-center" variant="warning">
                {getIntl(
                  'Your previously selected calculation for {{prevScenarioDescription}} scenario will be replaced',
                  { prevScenarioDescription: currentDayAlreadySelectedItem.description }
                )}
                .
              </Alert>
            ),
            submitButtonProps: { labelKey: 'Update', variant: 'primary' },
            onConfirm: () => {
              setLoading(true);
              return action(currentDayAlreadySelectedItem.scenario_id, name, false, true).then(() =>
                action(scenarioId, name, checked).finally(() => setLoading(false))
              );
            },
          })
        );
      } else {
        setLoading(true);
        return action(scenarioId, name, checked).finally(() => setLoading(false));
      }
    },
    [dispatch, items, getIntl]
  );

  return (
    <DataTable
      paginationType={PaginationType.SETUP_SCENARIO_CALCULATION}
      totalAmount={count}
      sendRequest={sendRequest}
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
      containerClassName="border-top-0"
    >
      {(items || []).map((item: Setup.ScenarioCalculation) => (
        <tr key={item.scenario_id}>
          <td>{item.description}</td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="monday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.monday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="tuesday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.tuesday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="wednesday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.wednesday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="thursday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.thursday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="friday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.friday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="saturday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.saturday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
          <td className="text-center">
            <Checkbox
              data-id={item.scenario_id}
              name="sunday"
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.sunday}
              onChange={handleCheckboxClick}
              disabled={loading}
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableScenarioCalculation;
