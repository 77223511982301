import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { repeatedTasksSectionModalIdSelector } from 'modules/layouts/selectors';
import { setRepeatedTasksSectionModalIdAction } from 'modules/layouts';
import { repeatedTasksSectionSelectorFactory } from 'modules/tasks/selectors';
import {
  Modal,
  Button,
  FormInput,
  HiddenFormSubmit,
  SelectTaskDepartment,
  SelectAssetCategories,
} from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const RepeatedTasksSectionsModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(repeatedTasksSectionModalIdSelector);
  const repeatedTasksSection: Tasks.RepeatedTasksSection | undefined = useSelector(
    repeatedTasksSectionSelectorFactory(id)
  );
  const isEditMode: boolean = Boolean(id && repeatedTasksSection);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setRepeatedTasksSectionModalIdAction(null));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setValues,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!repeatedTasksSection) return;
    setValues({
      name: repeatedTasksSection.name,
      departmentNumber: repeatedTasksSection.departmentNumber,
      assetCategories: repeatedTasksSection.assetcategories.map(item => item.assetcategoryCode),
    });
    setShow(true);
  }, [repeatedTasksSection, setValues]);

  const handleSelectChange = useCallback(
    (value: Type.SelectOption) => {
      setFieldValue('departmentNumber', value?.value || '');
    },
    [setFieldValue]
  );

  const handleCategoriesChange = useCallback(
    (values: { value: any; label: string }[]) => {
      const assetCategories = values ? values.map(i => i.value) : [];
      setFieldValue('assetCategories', assetCategories);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button
        icon={<IconPlus />}
        labelKey="Create repeated tasks section"
        onClick={toggleModal}
        size="large"
        variant="primary"
      />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit repeated tasks section' : 'Create repeated tasks section'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectTaskDepartment
              value={values.departmentNumber}
              isDisabled={isEditMode}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('departmentNumber')}
              errorKey={
                Boolean(touched.departmentNumber && errors.departmentNumber) ? (errors.departmentNumber as string) : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <SelectAssetCategories
              value={values.assetCategories}
              onChange={handleCategoriesChange as any}
              onBlur={() => setFieldTouched('assetCategories')}
              errorKey={
                Boolean(touched.assetCategories && errors.assetCategories) ? (errors.assetCategories as string) : ''
              }
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default RepeatedTasksSectionsModal;
