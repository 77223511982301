import React, { useState, useEffect } from 'react';
import { loadImages } from 'utils/map';
import IconsMap, { IconType } from '../common/IconsMap';

interface Props {
  map: Map.MapboxMap | null;
  isMapStyleLoaded: boolean;
  children: React.ReactNode | React.ReactNode[];
}

const SetupImages: React.FC<Props> = ({ children, map, isMapStyleLoaded }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!map || !isMapStyleLoaded) return;
    (async () => {
      await loadImages(
        map,
        Object.keys(IconsMap).map(key => ({ id: key, source: IconsMap[key as IconType] }))
      );
      setLoaded(true);
    })();
  }, [map, isMapStyleLoaded]);

  return <>{loaded ? children : null}</>;
};

export default React.memo(SetupImages);
