import { handleActions } from 'redux-actions';
import { fetchInstructionsAction, fetchInstructionSnapshotAction, deleteInstructionAction } from './actions';

const initialState: Instructions.Root = {
  loading: false,
  isFetched: false,
  count: 0,
  rows: [],
  builderData: {} as Builder.Data,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchInstructionsAction]: {
    next: (
      state: Instructions.Root,
      action: Shared.ReduxAction<Omit<Instructions.Root, 'loading' | 'isFetched'>>
    ): Instructions.Root => ({
      ...state,
      ...action.payload,
      loading: false,
      isFetched: true,
    }),
    throw: (state: Instructions.Root): Instructions.Root => ({
      ...state,
      loading: false,
      isFetched: false,
    }),
  },
};

export { fetchInstructionsAction, fetchInstructionSnapshotAction, deleteInstructionAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
