import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const ContainerTopControls: React.FC<Props> = ({ children }) => <StyledTopControls>{children}</StyledTopControls>;

const StyledTopControls = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export default ContainerTopControls;
