import React, { useMemo, useCallback } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { TaskAppActions } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  isMulti?: true | undefined;
  actions: string | string[] | null;
  excludedOptions?: Type.TaskAppActions[];
  onChange: (value: any, meta: any) => void;
}

const SelectTaskAppAction: React.FC<Props> = ({
  isMulti,
  labelKey = 'App action',
  actions,
  excludedOptions = [],
  onChange,
  ...props
}) => {
  const { getIntl } = useLocale();

  const { options, value } = useMemo(
    () =>
      Object.values(TaskAppActions)
        .filter((value: Type.TaskAppActions) => !excludedOptions.includes(value))
        .reduce(
          (acc: any, action: Type.TaskAppActions) => {
            const item = { value: action, label: getIntl(action) };
            if (actions?.includes(item.value)) acc.value.push(item);
            acc.options.push(item);
            return acc;
          },
          { options: [], value: [] }
        ),
    [getIntl, excludedOptions, actions]
  );

  const handleSelectChange = useCallback(
    (actions: Type.SelectOption[], meta: any) => {
      onChange(
        {
          value: isMulti ? actions?.map((i: Type.SelectOption) => i.value) || null : (actions as any)?.value,
        },
        meta
      );
    },
    [isMulti, onChange]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={handleSelectChange}
      isMulti={isMulti}
    />
  );
};

export default SelectTaskAppAction;
