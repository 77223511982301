import React from 'react';
import { Form } from 'react-bootstrap';
import { useToggle, useEventPreventedExec } from 'hooks';
import { FormInput, Modal, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes } from 'constants/index';

const UrlModal: React.FC<Builder.ModalProps> = ({ formField, addField, onChangeModal, onCloseModal: closeModal }) => {
  const isEditMode: boolean = Boolean(formField);
  const [show, toggleModal] = useToggle(true);
  const formik = useConfiguredFormik({ formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={BuilderFieldsTypes.URL}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey="Enter URL"
            name="url"
            value={values.url}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.url && errors.url) ? (errors.url as string) : ''}
          />
        </Form.Group>
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default UrlModal;
