import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checklistSelectorFactory, checklistsSelector } from 'modules/tasks/selectors';
import { fetchChecklistsAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { NonAssetTaskAppActions } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  appAction: Type.TaskAppActions | null;
  assetCategoryCode: Type.AssetCategories | null;
}

const SelectTaskChecklist: React.FC<Props> = ({
  labelKey = 'Checklist',
  value,
  appAction,
  assetCategoryCode,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const checklists = useSelector(checklistsSelector);
  const checklist = useSelector(checklistSelectorFactory(value));
  const isDisabled = useMemo(
    () => !appAction || (!assetCategoryCode && !NonAssetTaskAppActions.includes(appAction)),
    [appAction, assetCategoryCode]
  );

  useEffect(() => {
    if (isDisabled) return;
    setIsLoading(true);
    dispatch(fetchChecklistsAction({ appAction, assetCategoryCode })).then(() => setIsLoading(false));
  }, [isDisabled, appAction, assetCategoryCode, dispatch]);

  const selectOptions = useMemo(() => {
    return checklists.reduce((acc: any[], checklist: Tasks.Checklist) => {
      if (checklist.name.includes(inputValue)) {
        acc.push({ value: checklist.id, label: checklist.name });
      }
      return acc;
    }, []);
  }, [checklists, inputValue]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={value ? { value, label: checklist?.name || '' } : null}
      isDisabled={isLoading || isDisabled || props.isDisabled}
      isLoading={isLoading}
      options={selectOptions}
      onInputChange={setInputValue}
      inputValue={inputValue}
      isClearable
    />
  );
};

export default SelectTaskChecklist;
