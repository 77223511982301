import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { answersItemsSelector } from 'modules/answers/selectors';
import { fetchAnswersAction, deleteAnswerAction } from 'modules/answers';
import { AnswerDeleteAlert } from 'components';
import { Alert, Button, Spinner } from 'components/_common';
import { Routes, PaginationType } from 'constants/index';
import Card from './Card';
import TabFilters from './TabFilters';
import { IconDelete, IconDetails } from '@utiligize/shared/resources';

interface Props {
  uuid: string;
}

const TabAnswers: React.FC<Props> = ({ uuid }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const items = useSelector(answersItemsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const simulationVersionId = useSelector(simulationSelectedOptionVersionIdSelector);

  const paginationType = PaginationType.ANSWERS;
  const { query, column, sort }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));

  const sendRequest = useCallback(() => {
    if (!portfolioId || !simulationVersionId) return;
    setLoading(true);
    dispatch(
      fetchAnswersAction({ uuid, portfolioId, simulationVersionId, skipPagination: true, queryInput: query })
    ).finally(() => setLoading(false));
  }, [dispatch, uuid, portfolioId, simulationVersionId, query]);

  useEffect(() => {
    sendRequest();
  }, [sendRequest, column, sort]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const name: string = event.currentTarget.getAttribute('data-name') || '';
      dispatch(
        setModalConfirmationMetaAction({
          bodyComponent: <AnswerDeleteAlert name={name} />,
          onConfirm: () => dispatch(deleteAnswerAction({ id })).then(sendRequest),
        })
      );
    },
    [dispatch, sendRequest]
  );

  const renderContent = () => {
    if (loading) return <Spinner isInFullHeightContainer />;
    if (!items.length) {
      return (
        <Alert className="mb-0 text-center">
          {getIntl(query ? 'There is no data for current search query' : 'There is no data for selected asset')}
        </Alert>
      );
    }
    return (
      <>
        {items.map((item: Answers.Item, index: number) => {
          const hasForm: boolean = Boolean(item.form);
          const name = hasForm ? item.form.name : item.formName;
          return (
            <Card key={item.id} marginBottom={items.length - 1 !== index}>
              <Row className="mb-1">
                <Col>
                  <label>{getIntl('Form name')}:</label>
                  <span>{name}</span>
                </Col>
                <Col>
                  <label>{getIntl('Date of answer')}:</label>
                  <span>{dateFormat(item.createdAt)}</span>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col>
                  <label>{getIntl('Type')}:</label>
                  <span>{hasForm ? getIntl(item.form?.formtype?.name) : '-'}</span>
                </Col>
                <Col>
                  <label>{getIntl('Asset category')}:</label>
                  <span>{hasForm ? getIntl(item.form.assetcategory?.name) || '-' : item.assetCategory}</span>
                </Col>
              </Row>
              <Row className="mb-1">
                <Col>
                  <label>{getIntl('Username')}:</label>
                  <span>{item.sso_user.fullName}</span>
                </Col>
                <Col>
                  <label>{getIntl('Asset code')}:</label>
                  <span>{item.assetNumber || '-'}</span>
                </Col>
              </Row>
              <StyledBottomControls>
                <Button
                  tooltipKey="Details"
                  icon={<IconDetails />}
                  linkProps={{
                    to: {
                      pathname: generatePath(Routes.HistoryAnswers, { id: item.id }),
                      state: { prevRoute: window.location.pathname },
                    },
                  }}
                  size="small"
                  variant="primary"
                />
                <Button
                  marginLeft
                  tooltipKey="Delete"
                  icon={<IconDelete />}
                  data-id={item.id}
                  data-name={name}
                  onClick={handleDeleteBtnClick}
                  size="small"
                  variant="primary"
                />
              </StyledBottomControls>
            </Card>
          );
        })}
      </>
    );
  };

  return (
    <TabFilters paginationType={paginationType} loading={loading}>
      {renderContent()}
    </TabFilters>
  );
};

const StyledBottomControls = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export default TabAnswers;
