import styled from 'styled-components';
import React, { useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { finalChecksChartData } from 'modules/statistics/selectors';
import { Spinner } from 'components/_common';
import { getIntlExporting } from 'components/_charts/Chart';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);

interface Props {
  height: string;
  className?: string;
}

const StatisticsFinalChecksChart: React.FC<Props> = ({ className }) => {
  const { getIntl } = useLocale();
  const chartData = useSelector(finalChecksChartData);

  const options = useMemo(
    () => ({
      chart: {
        type: 'column',
      },
      title: {
        text: getIntl('Final checks per asset'),
      },
      subtitle: {
        text: `<h6>${getIntl('Task related forms')} - ${chartData?.finalTaskRelatedForm}, ${getIntl(
          'tool inspections per year'
        )} - ${chartData?.toolInspectionPerYear}</h6>`,
        useHTML: true,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: { title: { text: '' } },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.0f}',
          },
        },
      },
      tooltip: false,
      legend: false,
      series: [
        {
          name: getIntl('Final checks per asset'),
          colorByPoint: true,
          data: chartData?.seriesData,
        },
      ],

      drilldown: {
        breadcrumbs: {
          position: {
            align: 'right',
          },
        },
        series: chartData?.drilldownSeries,
      },

      ...getIntlExporting(getIntl),
    }),
    [chartData, getIntl]
  );

  return !chartData ? (
    <div className={className}>
      <Spinner isInFullHeightContainer />
    </div>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ className, 'data-marker': 'chart_maintenance_statistics_final_checks' }}
    />
  );
};

const StyledStatisticsFinalChecksChart = styled(StatisticsFinalChecksChart)`
  ${({ height }) => `height: ${height};`};
  border: 1px solid ${props => props.theme.colors.grey100};
  margin-top: 1rem;

  .highcharts-credits {
    display: none;
  }
`;

export default StyledStatisticsFinalChecksChart;
