import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { showAddressSearchSelector } from 'modules/map/selectors';
import { setAddressSearchAction } from 'modules/map';
import { Button } from 'components/_common';
import { IconSearch } from '@utiligize/shared/resources';

const AddressSearchButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const showAddressSearch = useSelector(showAddressSearchSelector);

  const toggleAddressSearchVisibility = useCallback(() => {
    dispatch(setAddressSearchAction(!showAddressSearch));
  }, [dispatch, showAddressSearch]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: showAddressSearch })}
      onClick={toggleAddressSearchVisibility}
      icon={<IconSearch />}
      tooltipKey={(showAddressSearch ? 'Hide' : 'Show') + ' address search'}
      tooltipPlacement="left"
    />
  );
};

export default AddressSearchButton;
