import React from 'react';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { CreatePlans, PlanCNAIM, PlanSummary, DetailedPlan, Statistics } from 'components/Maintenance';
import PagePermissionsProvider from './PagePermissionsProvider';
import PageNotFound from './PageNotFound';
import { Routes, PermissionsTypes, StorageKeys } from 'constants/index';

const PageMaintenance: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isMaintenanceEnabled}>
      <Switch>
        <Route exact path={Routes.Maintenance} component={CreatePlans} />
        {featureToggles[StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM] && (
          <Route exact path={Routes.MaintenancePlanCNAIM} component={PlanCNAIM} />
        )}
        <Route exact path={Routes.MaintenancePlanSummary} component={PlanSummary} />
        <Route exact path={Routes.MaintenanceDetailedPlan} component={DetailedPlan} />
        <Route path={Routes.MaintenanceStatistics} component={Statistics} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </PagePermissionsProvider>
  );
};

export default PageMaintenance;
