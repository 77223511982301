import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import classnames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import {
  createMapStateSelectorFactory,
  mapStateThemeSelector,
  primarySubstationsListSelector,
  settingsSelector,
} from 'modules/map/selectors';
import { mapFilterPrimarySubstationsIdSelector, singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import LayerHint from 'components/Map/common/LayerHint';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { ReactComponent as StationsIcon } from 'components/Map/icons/icon • map panel section • stations.svg';
import { setLayoutAction } from 'modules/layouts';
import { MapThemes } from 'constants/map';

interface Props {
  alwaysOpen: boolean;
}

const FilterPrimarySubstations: React.FC<Props> = ({ alwaysOpen }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const filterKey = 'filter_primary_substations';

  const settings = useSelector(settingsSelector);
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);
  const mapFilterPrimarySubstationsId = useSelector(mapFilterPrimarySubstationsIdSelector);
  const primarySubstationsList = useSelector(primarySubstationsListSelector);
  const theme = useSelector(mapStateThemeSelector);
  const globalFilters = useSelector(createMapStateSelectorFactory('globalFilters'));

  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState('');

  const isGroupChecked = useMemo(() => !!globalFilters[filterKey]?.list.length, [globalFilters, filterKey]);
  const options = useMemo(
    () => primarySubstationsList.filter(option => option.name.toLowerCase().includes(search.toLowerCase())),
    [primarySubstationsList, search]
  );
  const activeOptions = useMemo(() => settings.themeActiveFilters?.[theme || '']?.filter_primary_substation, [theme]); // eslint-disable-line
  const checkedOptions = useMemo(
    () => options?.filter((f: any) => globalFilters[filterKey]?.list.includes(f.id)),
    [options, globalFilters]
  );

  useEffect(() => {
    if (mapFilterPrimarySubstationsId || !options?.[0]?.id) return;
    dispatch(setLayoutAction({ mapFilterPrimarySubstationsId: Number(options[0].id) }));
  }, [mapFilterPrimarySubstationsId, dispatch, options]);

  const handleGroupFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const initFilters = settings.globalFilters?.[filterKey];
      const prevState = globalFilters[filterKey];
      const filter = { ...prevState, list: checked ? initFilters?.list.map(i => i.id) : [] } as Map.LayerFilter;
      dispatch(mapStateAction({ globalFilters: { ...globalFilters, [filterKey]: filter } }));
    },
    [dispatch, globalFilters, settings.globalFilters, filterKey]
  );

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const id = Number(event.target.id);
      const checked = event.target.checked;

      if (singleDiagramEnabled) {
        dispatch(setLayoutAction({ mapFilterPrimarySubstationsId: id }));
      } else {
        const prevState = globalFilters[filterKey];
        const prev = prevState?.list ?? [];
        const checkedItems = checked ? [...prev, id] : prev.filter(i => i !== id);
        const filter = { ...prevState, list: checkedItems } as Map.LayerFilter;
        dispatch(mapStateAction({ globalFilters: { ...globalFilters, [filterKey]: filter } }));
      }
    },
    [singleDiagramEnabled, dispatch, globalFilters, filterKey]
  );

  const onStateChange = useCallback(
    (state: boolean) => {
      if (state && !alwaysOpen) setTimeout(() => inputRef.current?.focus(), 100);
    },
    [alwaysOpen]
  );

  return (
    <CollapseGroup
      id="stations"
      titleKey="Primary substations"
      alwaysOpen={alwaysOpen}
      dataMarker="panel_primary_substations"
      icon={<StationsIcon className="filter-group-icon" />}
      disabled={
        settings.isAssetGroupDisabled || !Boolean(settings?.globalFilters?.[filterKey]) || theme === MapThemes.TASK
      }
      onStateChange={onStateChange}
      description={
        <GroupHiddenDescription
          values={(checkedOptions?.map(o => o.full_name ?? o.name) ?? []) as string[]}
          defaultValue="Nothing selected"
        />
      }
    >
      {settings?.globalFilters?.[filterKey] ? (
        <StyledStationsFilter
          $singleDiagramEnabled={singleDiagramEnabled}
          className={classnames({ active: isGroupChecked && !singleDiagramEnabled })}
        >
          <div className="stations-search-wrapper">
            {!singleDiagramEnabled && (
              <div className="station-filter">
                <ControlBox
                  type="checkbox"
                  id="station-filter"
                  name="station-filter"
                  checked={isGroupChecked}
                  onChange={handleGroupFilterChange}
                />
              </div>
            )}
            <div className="station-search">
              <span className={classnames('station-search-icon', { focused })}>
                <i className="fas fa-search fa-stack-1x" />
              </span>
              <input
                placeholder={getIntl('Search for primary substations')}
                value={search}
                onChange={e => setSearch(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                ref={inputRef}
              />
            </div>
          </div>
          <OverlayScrollbarsComponent style={{ maxHeight: '140px' }}>
            <div className="scroll-inner">
              {options?.length ? (
                options.map(option => {
                  const isChecked = singleDiagramEnabled
                    ? mapFilterPrimarySubstationsId === option.id
                    : Boolean(globalFilters[filterKey]?.list.includes(option.id));
                  return (
                    <StyledStationItem key={option.id}>
                      <ControlBox
                        type={singleDiagramEnabled ? 'radio' : 'checkbox'}
                        id={String(option.id)}
                        name={option.name ?? ''}
                        checked={isChecked}
                        labelKey={option.full_name ?? option.name}
                        onChange={handleFilterChange}
                        suffix={
                          activeOptions?.includes(option.id) && (
                            <LayerHint text={getIntl('Filter that can affect selected theme')} type="dot" />
                          )
                        }
                      />
                    </StyledStationItem>
                  );
                })
              ) : (
                <StyledWarning>{getIntl('Station is not found')}</StyledWarning>
              )}
            </div>
          </OverlayScrollbarsComponent>
        </StyledStationsFilter>
      ) : (
        <StyledWarning>{getIntl('Station filter is not found')}</StyledWarning>
      )}
    </CollapseGroup>
  );
};

const StyledStationsFilter = styled.div<{ $singleDiagramEnabled: boolean }>`
  .os-host {
    margin: 0 -18px;
  }

  .stations-search-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
  }

  .station-filter {
    margin-right: 10px;
  }

  .scroll-inner {
    padding: 0 18px;
    margin-left: ${props => (!props.$singleDiagramEnabled ? '10px' : 'unset')};
    position: relative;
  }

  .station-search {
    position: relative;
    width: 100%;

    &,
    & input::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.02em;
      color: var(--map-text-color-secondary);
    }

    input {
      background: #ffffff;
      border: 0.5px solid var(--map-outline-color);
      box-shadow: 0px 2px 2px #44366630;
      border-radius: 8px;
      padding: 7px 40px 7px 40px;
      outline: none;
      width: 100%;

      &:hover {
        border-color: var(--map-active-color);
      }

      &:focus {
        border-color: var(--map-active-color);
      }
    }

    &:hover .station-search-icon {
      color: var(--map-active-color);
    }

    .station-search-icon {
      width: 1rem;
      position: absolute;
      top: 1px;
      left: 16px;
      z-index: 10;
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--map-text-color-secondary);
      transition: color 0.2s linear;

      &.focused {
        color: var(--map-active-color);
      }
    }
  }
`;

const StyledStationItem = styled.div`
  display: flex;
  align-items: center;
`;

const StyledWarning = styled.div`
  color: var(--map-text-color-inactive);
`;

export default FilterPrimarySubstations;
