import styled from 'styled-components';
import classnames from 'classnames';
import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction, fetchNearestAssetLocationAction } from 'modules/map';
import {
  createMapStateSelectorFactory,
  enabledLayersListSelector,
  mapStateCollapseGroupStateSelector,
  mapStateThemeSelector,
  settingsSelector,
} from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import LayerHint from 'components/Map/common/LayerHint';
import LayerElementsCount from 'components/Map/common/LayerElementsCount';
import ControlBoxWithCollapseIcon from './CheckboxWithCollapseIcon';
import { showLayer, hideLayer, layerListToRecord } from 'utils/map';
import { MapParams } from 'constants/index';
import { IconVoltage } from '@utiligize/shared/resources';

interface Props {
  map: Map.MapboxMap;
  layers: Map.StyleLayer[];
  item: Map.LegendItem;
}

const FilterAssetsList: React.FC<Props> = ({ map, layers: mapLayers, item }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const settings = useSelector(settingsSelector);
  const theme = useSelector(mapStateThemeSelector);
  const layerFilters = useSelector(createMapStateSelectorFactory('layerFilters'));
  const enabledLayers = useSelector(createMapStateSelectorFactory('enabledLayers'));
  const collapseGroupState = useSelector(mapStateCollapseGroupStateSelector);
  const enabledLayersList = useSelector(enabledLayersListSelector);
  const activeFilters = useMemo(() => settings.themeActiveFilters?.[theme || '']?.filter_assets, [theme]); // eslint-disable-line

  const toggleLayers = useCallback(
    (state: boolean, layerIds: string[], layers: string[]) => {
      if (!theme) layers.forEach(i => (state ? showLayer(map, i) : hideLayer(map, i)));

      const newState = {
        enabledLayers: {
          ...enabledLayers,
          ...layerListToRecord(layers, state),
        },
      } as Partial<Map.MapState>;

      const filters = layerIds
        .map(name => {
          const layer = mapLayers.find(k => k.legend?.id === name);
          const initFilterList = layer?.legend?.filters?.list.map(i => i.id) ?? [];
          const filters = layerFilters[name];
          const needToShow = state && !filters?.list.length;
          const needToHide = !state && filters?.list.length;
          return needToShow || needToHide
            ? { name, filter: { ...filters, list: needToShow ? initFilterList : [] } }
            : null;
        })
        .filter(Boolean);

      if (filters.length) {
        newState.layerFilters = filters.reduce(
          (acc, item) => {
            acc[item!.name] = item!.filter;
            return acc;
          },
          { ...layerFilters }
        );
      }

      dispatch(mapStateAction(newState));
    },
    [dispatch, enabledLayers, layerFilters, map, mapLayers, theme]
  );

  const onVisibilityGroupChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, legendItem: Map.LegendItem) => {
      const childLayers = legendItem.layers!.filter(i => !i.settings?.no_data).map(i => i.name);
      const isChecked = event.target.checked;
      const layers = settings.assetLayers!.filter(i => childLayers?.some(j => i.startsWith(j)));
      toggleLayers(isChecked, childLayers, layers);
    },
    [toggleLayers, settings.assetLayers]
  );

  const onVisibilityItemChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.id;
      const isChecked = event.target.checked;
      const layers = settings.assetLayers!.filter(i => i.startsWith(name));
      toggleLayers(isChecked, [name], layers);
    },
    [settings.assetLayers, toggleLayers]
  );

  const onFilterVisibilityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    table: string,
    filterValue: string | number
  ) => {
    const filters = layerFilters[table];
    const prev = filters?.list ?? [];
    const checked = event.target.checked;
    const checkedItems = checked ? [...prev, filterValue] : prev.filter(i => i !== filterValue);
    const filter = { ...filters, list: checkedItems };
    const isLayerVisible = enabledLayersList.some(i => i.startsWith(table));
    const layers = settings.assetLayers!.filter(i => i.startsWith(table));
    const needToShow = !!(!isLayerVisible && checkedItems.length);
    const needToHide = !!(isLayerVisible && !checkedItems.length);

    const newState = { layerFilters: { ...layerFilters, [table]: filter } } as Partial<Map.MapState>;

    if (needToShow || needToHide) {
      const action = needToShow ? showLayer : hideLayer;
      if (!theme) {
        action(map, table);
        layers.forEach(i => action(map, i));
      }
      newState.enabledLayers = {
        ...enabledLayers,
        ...layerListToRecord(layers, needToShow),
      };
    }

    dispatch(mapStateAction(newState));
  };

  const renderListItem = (item: Map.LegendItem): React.ReactNode => {
    return item.layers ? renderListGroupItem(item) : renderListSingleItem(item);
  };

  const flyToLayer = useCallback(
    (map: Map.MapboxMap, layer: string, zoom: number) => {
      const center = map.getCenter();
      dispatch(
        fetchNearestAssetLocationAction({
          layer,
          zoom,
          lngLat: { lat: center.lat, lng: center.lng },
        })
      ).then((action: Shared.ReduxAction<Map.NearestAssetLocationData>) => {
        const location = action.payload;
        if (location.center[0] === null || location.center[1] === null) return;
        map.flyTo({ center: location.center, zoom: Math.max(10, location.zoom) });
      });
    },
    [dispatch]
  );

  const renderListGroupItem = (item: Map.LegendItem): React.ReactNode => {
    const isActive = !!item.layers?.some(
      l => enabledLayersList.some(i => i.startsWith(l.name)) && !l.settings?.no_data
    );
    const isDisabled = !!item.layers?.every(l => l.settings?.no_data);
    const isFilterActive = activeFilters?.some(f => item.layers?.some(i => i.cnaim_ids.includes(+Object.keys(f)[0])));
    return (
      <div className="position-relative">
        <ControlBoxWithCollapseIcon
          item={item}
          showIcon
          checked={isActive}
          disabled={isDisabled}
          onControlClick={onVisibilityGroupChange}
          suffix={isFilterActive && <LayerHint text={getIntl('This filter affects current theme')} type="dot" />}
        />
        {item.elementType === 'group_list' &&
          !isDisabled &&
          collapseGroupState?.[item.name] &&
          item.layers?.map(item => (
            <div
              key={item.name}
              className={classnames('group-list-item', {
                active: isActive,
                disabled: isDisabled,
              })}
            >
              {renderListItem(item)}
            </div>
          ))}
      </div>
    );
  };

  const renderListSingleItem = (item: Map.LegendItem): React.ReactNode => {
    const layer = mapLayers.find(k => k.legend?.id === item.name);
    const hasFilters = !!layer?.legend?.filters;
    const isDisabled = item.settings?.no_data;
    const isChecked = enabledLayersList.some(i => i.startsWith(item.name)) && !isDisabled;
    const activeCnaims = activeFilters?.filter(f => item.cnaim_ids.includes(+Object.keys(f)[0])) ?? [];
    const minZoom = layer?.sublayers[0].minzoom ?? MapParams.minZoom;
    return (
      layer && (
        <>
          <div className="position-relative">
            <ControlBoxWithCollapseIcon
              item={item}
              showIcon
              checked={isChecked && !isDisabled}
              disabled={isDisabled}
              onControlClick={onVisibilityItemChange}
              suffix={
                activeCnaims.length > 0 && <LayerHint text={getIntl('This filter affects current theme')} type="dot" />
              }
            />
            {layer.legend?.hint && isChecked && <LayerHint text={layer.legend?.hint} />}
            {!layer.legend?.iconClass?.includes('legend-filter') && (
              <i
                className={classnames([item.name, layer.legend?.iconClass], {
                  'd-none': !isChecked,
                })}
              />
            )}
            <div className="controls">
              {hasFilters && !isDisabled && !collapseGroupState?.[item.name] && (
                <div className="inline-filters">
                  {layer.legend!.filters!.list.map(filter => {
                    const isChecked = (layerFilters[item.name]?.list?.includes(filter.id) ?? false) && !isDisabled;
                    return (
                      <React.Fragment key={filter.id}>
                        {filter.color && isChecked && (
                          <LayerElementsCount text={filter.cnt!} onClick={() => flyToLayer(map, item.name, minZoom)}>
                            <i
                              className={classnames([layer.legend?.iconClass])}
                              style={{ backgroundColor: filter.color }}
                            />
                          </LayerElementsCount>
                        )}
                        {filter.icon && isChecked && (
                          <LayerElementsCount text={filter.cnt!} onClick={() => flyToLayer(map, item.name, minZoom)}>
                            <i className={classnames([layer.legend?.iconClass, filter.icon])} />
                          </LayerElementsCount>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {hasFilters && !isDisabled && collapseGroupState?.[item.name] && (
            <ul className="filter-values">
              {layer.legend!.filters!.list.map(filter => {
                const isChecked = (layerFilters[item.name]?.list?.includes(filter.id) ?? false) && !isDisabled;
                const isFilterActive = activeCnaims?.some(f => f[Object.keys(f)[0]]?.includes(+filter.id));
                return (
                  <li className="filter-item" key={filter.id}>
                    <ControlBox
                      type="checkbox"
                      id={filter.id + ''}
                      name={filter.name ?? ''}
                      checked={isChecked}
                      labelKey={filter.full_name ?? filter.name}
                      hint={getIntl(filter.hint ?? '')}
                      onChange={e => onFilterVisibilityChange(e, item.name, filter.id)}
                      disabled={isDisabled}
                      icon={<IconVoltage />}
                      suffix={
                        isFilterActive && <LayerHint text={getIntl('This filter affects current theme')} type="dot" />
                      }
                    />
                    <div className="controls">
                      <div className="inline-filters">
                        {filter.color && isChecked && (
                          <LayerElementsCount text={filter.cnt!} onClick={() => flyToLayer(map, item.name, minZoom)}>
                            <i className={layer.legend?.iconClass} style={{ backgroundColor: filter.color }} />
                          </LayerElementsCount>
                        )}
                        {filter.icon && isChecked && (
                          <LayerElementsCount text={filter.cnt!} onClick={() => flyToLayer(map, item.name, minZoom)}>
                            <i className={classnames([layer.legend?.iconClass, filter.icon])} />
                          </LayerElementsCount>
                        )}
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      )
    );
  };

  return (
    <StyledAssetList>
      {item.layers?.map(subItem => {
        const isGroup = !!subItem.layers;
        const isActive = isGroup
          ? subItem.layers?.some(l => enabledLayersList.some(i => i.startsWith(l.name)) && !l.settings?.no_data)
          : enabledLayersList.some(i => i.startsWith(subItem.name)) && !subItem.settings?.no_data;
        const isDisabled = isGroup ? subItem.layers?.every(l => l.settings?.no_data) : subItem.settings?.no_data;
        return (
          <div
            key={subItem.name}
            className={classnames('asset-list-item', {
              active: isActive,
              disabled: isDisabled,
            })}
            data-marker={`assets__filter${isActive ? '__active' : isDisabled ? '__disabled' : ''}`}
          >
            {renderListItem(subItem)}
          </div>
        );
      })}
    </StyledAssetList>
  );
};

const StyledAssetList = styled.div`
  .asset-list-item,
  .group-list-item {
    position: relative;

    &.disabled {
      cursor: default;
      opacity: 1;
    }
  }

  .group-list-item {
    margin-left: 22px;
  }

  .filter-item {
    display: flex;
    align-items: center;
  }

  .controls {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    .inline-filters {
      & > * {
        padding: 4px;
        margin: -4px;
        border-radius: 8px;
      }
    }

    &,
    & .inline-filters {
      display: flex;
      align-items: center;
    }

    .inline-filters {
      & > *:not(:last-child) {
        margin-right: 5px;
      }

      i {
        position: static;
        transform: none;
      }
    }
  }
`;

export default FilterAssetsList;
