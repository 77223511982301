import React from 'react';
import { DataTableTemplate } from 'components/_common';
import ModalGroups from './ModalGroups';
import TableGroups from './TableGroups';

const ViewGroups: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <ModalGroups />
    <TableGroups />
  </DataTableTemplate>
);

export default ViewGroups;
