import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.assetModels;

const assetModelsItemsHashSelector = createSelector(
  rootSelector,
  (assetModels: AssetModels.Root): Type.Hash<AssetModels.Item> => assetModels.itemsHash
);

export const assetModelsItemsSelector = createSelector(
  assetModelsItemsHashSelector,
  (itemsHash: Type.Hash<AssetModels.Item>): AssetModels.Item[] => Object.values(itemsHash)
);

export const assetModelsItemSelectorFactory = (id: number | null) =>
  createSelector(
    assetModelsItemsHashSelector,
    (itemsHash: Type.Hash<AssetModels.Item>): AssetModels.Item => itemsHash[`_${id}_`]
  );

export const assetModelsCountSelector = createSelector(
  rootSelector,
  (assetModels: AssetModels.Root): number => assetModels.count
);
