import * as Sentry from '@sentry/react';
import { isDevelopment } from 'constants/index';

export const isPromise = (value: any) =>
  value !== null && typeof value === 'object' && typeof value.then === 'function';

const promiseGlobalCatchMiddleware = () => (next: any) => (action: any) => {
  // If not a promise, continue on
  if (!isPromise(action.payload) || action.meta?.localError) {
    return next(action);
  }

  // Dispatch initial pending promise, but catch any errors
  return next(action).catch((error: any) => {
    if (isDevelopment && error) {
      // here you can decide to show or hide errors
      console.error(
        `${action.type} unhandled rejection caught at middleware with reason: ${JSON.stringify(error.message)}.`
      );
    } else {
      Sentry.captureException(error);
    }
    return error;
  });
};

export default promiseGlobalCatchMiddleware;
