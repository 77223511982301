import { createSelector } from 'reselect';
import { rootSelector } from './selectors';

// ------------------------------------
// Selectors
// ------------------------------------
export const fusesCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.fusesCount
);

const fusesHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): Type.Hash<NetworkLoading.Fuse> => networkLoading.fusesHash
);

export const fusesSelector = createSelector(
  fusesHashSelector,
  (fusesHash: Type.Hash<NetworkLoading.Fuse>): NetworkLoading.Fuse[] => Object.values(fusesHash)
);

export const fusesForecastsCountSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): number => networkLoading.fusesForecastsCount
);

const fusesForecastsHashSelector = createSelector(
  rootSelector,
  (networkLoading: NetworkLoading.Root): Type.Hash<NetworkLoading.FuseForecast> => networkLoading.fusesForecastsHash
);

export const fusesForecastsSelector = createSelector(
  fusesForecastsHashSelector,
  (fusesForecastHash: Type.Hash<NetworkLoading.FuseForecast>): NetworkLoading.FuseForecast[] =>
    Object.values(fusesForecastHash)
);
