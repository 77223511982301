import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import { taskCompletionsSelector } from 'modules/assets/selectors';
import { fetchAssetTaskCompletionsAction } from 'modules/assets';
import { Alert, Spinner } from 'components/_common';
import { Routes } from 'constants/index';
import Card from './Card';

interface Props {
  uuid: string;
}

const TabTaskCompletions: React.FC<Props> = ({ uuid }) => {
  const [loading, setLoading] = useState(true);
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const items = useSelector(taskCompletionsSelector);

  useEffect(() => {
    dispatch(fetchAssetTaskCompletionsAction({ uuid })).finally(() => setLoading(false));
  }, [dispatch, uuid]);

  const renderAnswerLink = (item: Tasks.TaskCompletion) => {
    if (!item.answer?.id) return '';
    return (
      <Link
        to={{
          pathname: generatePath(Routes.HistoryAnswers, { id: item.answer.id }),
          state: { prevRoute: window.location.pathname },
        }}
      >
        {item.answer.id}
      </Link>
    );
  };

  if (loading) return <Spinner isInFullHeightContainer />;
  if (!items.length)
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;

  return (
    <>
      {items.map((item: Tasks.TaskCompletion, index: number) => (
        <Card key={item.answer?.id + item.task?.id} marginBottom={items.length - 1 !== index}>
          <Row className="mb-1">
            <Col>
              <label>{getIntl('Date')}:</label>
              <span>{dateFormat(item.date)}</span>
            </Col>
            <Col>
              <label>{getIntl('Name')}:</label>
              <span>{item.user.fullName}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{getIntl('Task')}:</label>
              <span>
                <Link to={generatePath(Routes.Tasks, { id: item.task?.id })}>{item.task?.name}</Link>
              </span>
            </Col>
            <Col>
              <label>{getIntl('Answer')}:</label>
              <span>{renderAnswerLink(item)}</span>
            </Col>
          </Row>
        </Card>
      ))}
    </>
  );
};

export default TabTaskCompletions;
