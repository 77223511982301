import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRecalculateButton, useBudgetingResetButton } from 'hooks';
import { recalculateBudgetingAction } from 'modules/budgeting';
import { DataTableTemplate } from 'components/_common';
import BudgetingBenchmarkingTabs from './BudgetingBenchmarkingTabs';

const BudgetingBenchmarking: React.FC = () => {
  const { loading: resetLoading, ButtonReset } = useBudgetingResetButton();
  const { loading, ButtonRecalculate } = useRecalculateButton(recalculateBudgetingAction);
  return (
    <DataTableTemplate>
      <Row>
        <Col>
          {ButtonReset}
          {ButtonRecalculate}
        </Col>
      </Row>
      <BudgetingBenchmarkingTabs loading={resetLoading || loading} />
    </DataTableTemplate>
  );
};

export default BudgetingBenchmarking;
