import { handleActions } from 'redux-actions';
import {
  fetchFormsAction,
  fetchFormCountByTypeAndAssetCategoryCodeAction,
  fetchFormSnapshotAction,
  deleteFormAction,
  sendFileAction,
  createFormAction,
  updateFormAction,
  copyFormAction,
  fetchFormsByTypeAction,
  fetchFormTypesAction,
  fetchLogMaintenanceFormsAction,
  fetchTimestampOptionsAction,
} from './actions';

const initialState: Forms.Root = {
  count: 0,
  itemsHash: {} as Type.Hash<Forms.Item>,
  builderData: {} as Builder.Data,
  timestamps: {} as Forms.Timestamps,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchFormsAction.toString()]: {
    next: (
      state: Forms.Root,
      action: Shared.ReduxAction<Pick<Forms.Root, 'count' | 'itemsHash' | 'builderData'>>
    ): Forms.Root => ({
      ...state,
      ...action.payload,
    }),
  },
  [fetchTimestampOptionsAction.toString()]: {
    next: (state: Forms.Root, action: Shared.ReduxAction<Forms.Timestamps>): Forms.Root => ({
      ...state,
      timestamps: {
        ...state.timestamps,
        ...action.payload,
      },
    }),
  },
};

export {
  fetchFormsAction,
  fetchFormCountByTypeAndAssetCategoryCodeAction,
  fetchFormSnapshotAction,
  deleteFormAction,
  sendFileAction,
  createFormAction,
  updateFormAction,
  copyFormAction,
  fetchFormsByTypeAction,
  fetchFormTypesAction,
  fetchLogMaintenanceFormsAction,
  fetchTimestampOptionsAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
