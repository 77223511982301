import * as Yup from 'yup';
import { useFormik, FormikProps as Props, FormikErrors } from 'formik';
import { push } from 'connected-react-router';
import { useBuilderState } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { inspectionsBuilderDataSelector } from 'modules/inspections/selectors';
import { BuilderFormTypeNames, Routes } from 'constants/index';

interface Values {
  name: string;
  userIds: number[];
  isAllUsersChecked: boolean;
}

export type FormikProps = Props<Values>;
export type Errors = FormikErrors<Values>;

const useConfiguredFormik = ({
  isEditMode,
  usersIds,
  toggleModal,
}: {
  isEditMode: boolean;
  usersIds: number[];
  toggleModal: () => void;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const builderData: Builder.Data = useSelector(inspectionsBuilderDataSelector);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectValue, setLocationState } = isEditMode ? useBuilderState() : ({} as any);

  const formik = useFormik<Values>({
    initialValues: {
      name: isEditMode ? selectValue.name : '',
      userIds: (isEditMode && (selectValue.userIds as number[])) || [],
      isAllUsersChecked: isEditMode && selectValue.userIds?.length === usersIds.length,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      userIds: Yup.array().when('isAllUsersChecked', {
        is: false,
        then: Yup.array().min(1, 'Please select one user at least!').required(),
      }),
    }),

    onSubmit: values => {
      if (isEditMode) {
        setLocationState({ selectValue: { ...selectValue, name: values.name, userIds: values.userIds } });
        toggleModal();
      } else {
        const formtype = builderData.formTypes.find(
          (i: Type.FormType) => i.name === BuilderFormTypeNames.ToolInspection
        );
        if (!formtype) throw Error('formtype not found');

        const selectValue: Builder.SelectValue = {
          name: values.name,
          formtype,
          userIds: values.userIds,
          assetCategories: null as any,
          formSubcomponents: null,
        };

        dispatch(
          push(Routes.InspectionBuilder, {
            isNew: true,
            isDuplicate: false,
            selectValue,
            blocks: [],
            snapshots: [],
            fieldTypes: builderData.fieldTypes,
            formTypes: builderData.formTypes,
          } as Builder.LocationState)
        );
      }
    },
  });

  return formik;
};

export default useConfiguredFormik;
