import React from 'react';
import { Form, Col } from 'react-bootstrap';
import { useToggle, useEventPreventedExec } from 'hooks';
import { FormInput, Modal, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes } from 'constants/index';

const NumberInputModal: React.FC<Builder.ModalProps> = ({
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const isEditMode: boolean = Boolean(formField);
  const [show, toggleModal] = useToggle(true);
  const formik = useConfiguredFormik({ formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={BuilderFieldsTypes.NumberInput}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey="Label (not shown to user)"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <FormInput
            labelKey="Prefix"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <FormInput
              labelKey="Placeholder"
              name="placeholder"
              value={values.placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.placeholder && errors.placeholder) ? (errors.placeholder as string) : ''}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <FormInput
              labelKey="Unit"
              name="unit"
              value={values.unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.unit && errors.unit) ? (errors.unit as string) : ''}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <FormInput
              labelKey="Min Value"
              type="number"
              name="min"
              value={values.min}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.min && errors.min) ? (errors.min as string) : ''}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <FormInput
              labelKey="Max Value"
              type="number"
              name="max"
              value={values.max}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.max && errors.max) ? (errors.max as string) : ''}
            />
          </Form.Group>
        </Form.Row>
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default NumberInputModal;
