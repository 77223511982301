import React from 'react';
import { useSelector } from 'react-redux';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { DataTableTemplate } from 'components/_common';
import TabsFuses from './TabsFuses';
import TableFilters from './TableFilters';
import { PaginationType, Routes } from 'constants/index';

const ViewFuses: React.FC = () => {
  const isForecastTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingFuses}${Routes.HASH.Forecast}`)
  );
  const type = isForecastTabActive
    ? PaginationType.NETWORK_LOADING_FUSES_FORECAST
    : PaginationType.NETWORK_LOADING_FUSES;

  return (
    <DataTableTemplate>
      <TableFilters type={type} />
      <TabsFuses type={type} />
    </DataTableTemplate>
  );
};

export default ViewFuses;
