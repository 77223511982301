import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { metersMissingInGISSelector, metersMissingInGISCountSelector } from 'modules/dataQuality/selectors';
import { fetchMetersMissingInGISAction } from 'modules/dataQuality';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableMissingInGIS: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(metersMissingInGISCountSelector);
  const items = useSelector(metersMissingInGISSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      if (!portfolioId) return Promise.resolve();
      return dispatch(fetchMetersMissingInGISAction({ ...params, portfolioId })).then(
        (action: Shared.ReduxAction<{ missingInGISHash: Type.Hash<DataQuality.MissingIn> }>) =>
          Object.values(action.payload?.missingInGISHash || {})
      );
    },
    [dispatch, portfolioId]
  );

  return (
    <DataTable
      paginationType={PaginationType.METERS_MISSING_IN_GIS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 286px)"
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items.map((item: DataQuality.MissingIn) => (
        <tr key={item.installation_number}>
          <td>{dateFormat(item.last_measurement) || '-'}</td>
          <td>{item.installation_number || '-'}</td>
          <td>{item.metering_point || '-'}</td>
          <td>{item.meter_number || '-'}</td>
          <td>{item.address || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableMissingInGIS;
