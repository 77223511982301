import styled from 'styled-components';
import classNames from 'classnames';
import React, { useMemo, useCallback, useState } from 'react';
import { useLocale } from 'hooks';
import { useReadableTable } from 'hooks';
import { OverlayTriggerTooltip } from 'components/_common';
import TextOverflow from './TextOverflow';

interface Props {
  assetMeta: Asset.AssetMeta;
}

enum InfoTypes {
  MISC = 'misc',
  BASIC = 'basic',
}

const AssetDetails: React.FC<Props> = ({ assetMeta }) => {
  const { getIntl } = useLocale();
  const { formatProp, formatValue } = useReadableTable();
  const [infoType, setInfoType] = useState<InfoTypes>(assetMeta?.[InfoTypes.MISC] ? InfoTypes.MISC : InfoTypes.BASIC);

  const infoTypes = useMemo(() => Object.values(InfoTypes), []);

  const InfoTypeLocalesMap = useMemo(
    () => ({
      [InfoTypes.MISC]: getIntl('Misc information'),
      [InfoTypes.BASIC]: getIntl('Basic information'),
    }),
    [getIntl]
  );

  // Note: properties without null values
  const properties = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(assetMeta?.[infoType] ?? {})
          .filter(([key, value]) => value !== null)
          .map(([key, value]) => [
            formatProp(key, { translate: false }),
            formatValue(key, value as string | number | boolean, { translate: false }),
          ])
      ),
    [infoType, assetMeta, formatProp, formatValue]
  );

  const handleInfoIconClick = useCallback((event: React.MouseEvent) => {
    const nextInfoType = event.currentTarget.getAttribute('data-type') as InfoTypes;
    setInfoType(nextInfoType);
  }, []);

  return (
    <StyledContainer>
      <StyledHeader>
        <StyledHeaderActions>
          {infoTypes
            .filter(type => Boolean(assetMeta[type]))
            .map((type: InfoTypes) => (
              <OverlayTriggerTooltip
                key={type}
                placement="top"
                overlayId={type}
                overlayChildren={InfoTypeLocalesMap[type]}
              >
                <i
                  onClick={handleInfoIconClick}
                  data-type={type}
                  className={classNames('fas fa-list', { active: infoType === type })}
                />
              </OverlayTriggerTooltip>
            ))}
        </StyledHeaderActions>
      </StyledHeader>
      {Boolean(Object.keys(properties).length) ? (
        <StyledBody>
          {Object.entries(properties).map(([key, value]: [string, any]) => (
            <React.Fragment key={key}>
              <TextOverflow className="asset-details-property-key" data-text={key}>
                {key}:
              </TextOverflow>
              <TextOverflow className="asset-details-property-value" data-text={formatValue(key, value)}>
                {value}
              </TextOverflow>
            </React.Fragment>
          ))}
        </StyledBody>
      ) : (
        <StyledNoPropertiesMessage>{getIntl('No data found for this asset')}</StyledNoPropertiesMessage>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: 10px;
  font-size: 12px;

  .details-body {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
    row-gap: 2px;

    .key {
      text-align: left;
    }

    .value {
      text-align: right;
    }
  }

  .no-properties {
    color: var(--map-popup-color);
    background-color: var(--map-background-hover);
    border: 1px solid var(--map-outline-color);
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledHeaderActions = styled.div`
  display: flex;

  & > * {
    transition: color 0.2s linear;
    color: var(--map-text-color-inactive);
    cursor: pointer;

    &.active,
    &:hover {
      color: var(--map-active-color);
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

const StyledBody = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  column-gap: 20px;
  row-gap: 2px;

  .asset-details-property-key {
    text-align: left;
  }

  .asset-details-property-value {
    text-align: right;
  }
`;

const StyledNoPropertiesMessage = styled.div`
  color: var(--map-popup-color);
  background-color: var(--map-background-hover);
  border: 1px solid var(--map-outline-color);
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
`;

export default AssetDetails;
