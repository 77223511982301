import { handleActions } from 'redux-actions';
import { getCO2EmissionChartData } from './actions';

const initialState = {};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {};

export { getCO2EmissionChartData };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
