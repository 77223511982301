import React from 'react';
import { useLocale } from 'hooks';
import { Tabs, Tab, ChartIframe, RecalculationSpinner, ContentContainer } from 'components/_common';
import { Routes } from 'constants/index';

interface Props {
  loading: boolean;
}

const BudgetingAssetBaseTabs: React.FC<Props> = ({ loading }) => {
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter>
      <Tab eventKey={Routes.BudgetingAssetBase} title={getIntl('Assets book value')}>
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of assets book value" src="revenue_capex" />
          </ContentContainer>
        )}
      </Tab>
      <Tab eventKey={`${Routes.BudgetingAssetBase}${Routes.HASH.Depreciations}`} title={getIntl('Depreciations (DKK)')}>
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of depreciations (DKK)" src="revenue_depreciations" />
          </ContentContainer>
        )}
      </Tab>
    </Tabs>
  );
};

export default BudgetingAssetBaseTabs;
