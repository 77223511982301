import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import {
  summaryPlanMetaCurrencySelector,
  summaryPlanPaginatedItemsSelector,
  summaryPlanCountSelector,
} from 'modules/investment/selectors';
import { DataTable } from 'components/_common';
import { PaginationType, TableHeaders } from 'constants/index';
import { fetchSummaryPlanAction } from 'modules/investment/actions';

const TableSummary: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, numberFormat } = useLocale();

  const paginationType = PaginationType.SUMMARY_PLAN;

  const items = useSelector(summaryPlanPaginatedItemsSelector);
  const count = useSelector(summaryPlanCountSelector);
  const currency = useSelector(summaryPlanMetaCurrencySelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchSummaryPlanAction({ ...params, skipPagination: true })).then(
        (action: Shared.ReduxAction<{ summaryPlanItems: Investment.Root['summaryPlanItems'] }>) =>
          action.payload?.summaryPlanItems || []
      ),
    [dispatch]
  );

  const customHeaders = useMemo(
    () => [
      ...TableHeaders[paginationType].map(header => ({
        ...header,
        title: getIntl(header.titleKey!, { currency }),
        subTitles: header.subTitles?.map(i => ({ ...i, title: getIntl(i.titleKey!, { currency }) })),
      })),
    ],
    [paginationType, currency, getIntl]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      customHeaders={customHeaders}
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
      isClientSidePagination
      isResetFiltersButtonEnabled
    >
      {items.map(item => (
        <tr key={item.replacement_cost + item.suggested_replacement_year}>
          <td>{item.asset_register_category || '-'}</td>
          <td>{item.suggested_replacement_year || '-'}</td>
          <td>{item.replacement_reason ? getIntl(item.replacement_reason) : '-'}</td>
          <td>{item.description || '-'}</td>
          <td>{item.voltage_level_text || '-'}</td>
          <td>{item.department || '-'}</td>
          <td>{item.name || '-'}</td>
          <td>{numberFormat(item.replacement_cost, { minimumFractionDigits: 2, fallback: '-' })}</td>
          <td>{numberFormat(item.co2e_t_per_year, { fallback: '-' })}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableSummary;
