import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { scenarioSelectedOptionSelector } from 'modules/options/selectors';
import {
  createMapStateSelectorFactory,
  enabledLayersListSelector,
  settingsSelector,
  showCableAnimationSelector,
} from 'modules/map/selectors';
import { setMapSourceField, showTheme, hideTheme } from 'utils/map';
import filterReplacements from './helpers';

interface Props {
  map: Map.MapboxMap;
}

const Replacements: React.FC<Props> = ({ map }) => {
  const settings = useSelector(settingsSelector);
  const selectedScenario = useSelector(scenarioSelectedOptionSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const replacementsFilters = useSelector(createMapStateSelectorFactory('replacementsFilters'));
  const scenario = useSelector(createMapStateSelectorFactory('replacementsScenario'));
  const enabledLayersList = useSelector(enabledLayersListSelector);

  // update ids
  useEffect(() => {
    setMapSourceField(map, settings.replacementsLayers!, {
      scenario_id: String(selectedScenario?.value),
    });
  }, [map, selectedScenario]); // eslint-disable-line

  // filter by replacement reason
  useEffect(() => {
    if (!scenario || !replacementsFilters) return;
    filterReplacements(map, scenario, replacementsFilters, settings);
  }, [map, scenario, replacementsFilters]); // eslint-disable-line

  // show and update all layers when theme is active
  useEffect(() => {
    showTheme(map, settings.replacementsLayers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  // hide all theme layers when theme is inactive
  useEffect(() => {
    return () => {
      hideTheme(map, settings.replacementsLayers!);
    };
  }, [map]); // eslint-disable-line

  return null;
};

export default React.memo(Replacements);
