import styled from 'styled-components';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { mapStateAction } from 'modules/map';
import ControlBox, { ControlBoxProps } from 'components/Map/common/ControlBox';
import { mapStateCollapseGroupStateSelector } from 'modules/map/selectors';

interface Props extends Pick<ControlBoxProps, 'checked' | 'disabled' | 'suffix'> {
  item: Map.LegendItem;
  showIcon?: boolean;
  onControlClick: (event: React.ChangeEvent<HTMLInputElement>, legendItem: Map.LegendItem) => void;
}

const ControlBoxWithCollapseIcon: React.FC<Props> = ({
  item,
  checked,
  disabled,
  showIcon = false,
  onControlClick,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const collapseGroupState = useSelector(mapStateCollapseGroupStateSelector);

  const handleControlClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onControlClick(event, item);
    },
    [item, onControlClick]
  );

  const handleIconClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      dispatch(mapStateAction({ collapseGroupState: { [item.name]: !collapseGroupState?.[item.name] } }));
    },
    [item, collapseGroupState, dispatch]
  );

  return (
    <StyledContainer>
      <ControlBox
        type="checkbox"
        id={item.name}
        name={item.name}
        checked={checked}
        dataMarker={item.name}
        disabled={disabled}
        labelKey={item.title}
        onChange={handleControlClick}
        icon={
          showIcon ? (
            <button className="collapse-icon" onClick={handleIconClick} disabled={disabled}>
              <i
                className={classnames('fa fa-angle-left legend-collapse-icon filter-header', {
                  open: collapseGroupState?.[item.name],
                })}
                aria-hidden="true"
              />
            </button>
          ) : undefined
        }
        {...props}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  align-items: center;

  .collapse-icon {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 1px;
    border-radius: 4px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin: 0 -5px;

    &:disabled {
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      background-color: var(--map-panel-color);
    }

    .legend-collapse-icon {
      margin: 0 0 0 4px;
    }
  }
`;

export default ControlBoxWithCollapseIcon;
