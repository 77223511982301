import styled from 'styled-components';
import React, { useCallback, useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useEventPreventedExec, useLocale, useSplitPermissionsByGroups } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsUsersModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { permissionsUserSelectorFactory, permissionsTypesSelector } from 'modules/permissions/selectors';
import { fetchPermissionsTypesAction } from 'modules/permissions';
import { Modal, HiddenFormSubmit, SelectPermissionsGroup, Alert, Button, FormInput } from 'components/_common';
import { sortPermissions } from 'utils';
import useConfiguredFormik from './useConfiguredFormik';
import { IconPlus } from '@utiligize/shared/resources';

const ModalUsers: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(permissionsUsersModalIdSelector);
  const permissionsTypes: Permissions.PermissionsTypes[] | null = useSelector(permissionsTypesSelector);
  const user: Users.User | null = useSelector(permissionsUserSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && user);
  const [show, setShow] = useState(false);

  useEffect(() => {
    // pre-fetch permissions types, for create group modal flow
    if (!permissionsTypes) dispatch(fetchPermissionsTypesAction());
  }, [permissionsTypes, dispatch]);

  const toggleModal = useCallback((): void => {
    setShow(show => {
      // Reset redux layouts state for isEditMode
      if (show && id) dispatch(setLayoutAction({ permissionsUsersModalId: null }));
      return !show;
    });
  }, [id, dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, setFieldValue, resetForm } =
    useConfiguredFormik({
      id,
      initialValues: {
        firstName: isEditMode ? user!.firstName : '',
        lastName: isEditMode ? user!.lastName : '',
        email: isEditMode ? user!.email : '',
        initials: isEditMode ? user!.initials! : '',
        groupPermissions: isEditMode ? user!.groupPermissions!.map(i => i.id) : [],
        userPermissions: (isEditMode
          ? (Object.keys(user!.userPermissions!) as Permissions.PermissionsTypes[])
          : permissionsTypes! || []
        )
          .sort(sortPermissions)
          .reduce((acc: Permissions.Permissions, key: Permissions.PermissionsTypes) => {
            acc[key] = isEditMode && user!.userPermissions![key as Permissions.PermissionsTypes];
            return acc;
          }, {} as Permissions.Permissions),
      },
      toggleModal,
    });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (id && user) setShow(true);
    if (!show) resetForm();
  }, [id, user, show, resetForm]);

  const handleSelectChange = useCallback(
    async (groupPermissionsIds: number[]) => {
      setFieldValue('groupPermissions', groupPermissionsIds?.length ? groupPermissionsIds : []);
    },
    [setFieldValue]
  );

  const { views, taskType, assetCategories, readWrite } = useSplitPermissionsByGroups({
    objKey: 'userPermissions',
    permissions: values.userPermissions,
    onChange: handleChange,
  });

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const email = e.target.value;
      setFieldValue('email', email);
      if (!touched.initials) setFieldValue('initials', email.split('@')?.[0]);
    },
    [touched.initials, setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create user" onClick={toggleModal} size="large" variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit user' : 'Create user'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <StyledForm onSubmit={handleFormSubmit}>
          <Form.Row>
            <Form.Group as={Col}>
              <FormInput
                autoFocus={!isEditMode}
                labelKey="First name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={Boolean(touched.firstName && errors.firstName) ? (errors.firstName as string) : ''}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <FormInput
                labelKey="Last name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={Boolean(touched.lastName && errors.lastName) ? (errors.lastName as string) : ''}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <FormInput
                labelKey="Email"
                name="email"
                value={values.email}
                onChange={handleEmailChange}
                // onBlur={handleBlur}
                errorKey={Boolean(touched.email && errors.email) ? (errors.email as string) : ''}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <FormInput
                labelKey="Initials"
                name="initials"
                value={values.initials}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={Boolean(touched.initials && errors.initials) ? (errors.initials as string) : ''}
              />
            </Form.Group>
          </Form.Row>
          <Form.Group>
            <SelectPermissionsGroup ids={values.groupPermissions} setIds={handleSelectChange} isMulti />
          </Form.Group>
          <Form.Row>{views}</Form.Row>
          {Boolean(touched.userPermissions && errors.userPermissions) && (
            <Alert show variant="warning" className="mt-3 mb-0">
              {getIntl(errors.userPermissions as string)}
            </Alert>
          )}
          <hr />
          <Form.Row>{taskType}</Form.Row>
          <hr />
          <Form.Row>{assetCategories}</Form.Row>
          <hr />
          <Form.Row>{readWrite}</Form.Row>
          <HiddenFormSubmit />
        </StyledForm>
      </Modal>
    </>
  );
};

const StyledForm = styled(Form)`
  .form-row > .form-group:last-child {
    margin-bottom: 0;
  }
`;

export default ModalUsers;
