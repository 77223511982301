import React, { useCallback, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useLocale, useToggle, useEventPreventedExec } from 'hooks';
import { FormInput, Modal, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes } from 'constants/index';

const PhotoModal: React.FC<Builder.ModalProps> = ({
  type,
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const isEditMode: boolean = Boolean(formField);
  const { getIntl } = useLocale();
  const [show, toggleModal] = useToggle(true);
  const [file, setFile] = useState<File | undefined>();
  const formik = useConfiguredFormik({ type, file, formField, addField, onChangeModal, isEditMode, toggleModal });
  const { isSubmitting, values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleFileChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { files } = event.target as HTMLInputElement;
      if (!files?.[0]) return;
      let reader = new FileReader();

      reader.onloadend = () => {
        setFieldValue('name', files?.[0].name);
        setFieldValue('link', reader.result);
        setFile(files?.[0]);
      };

      reader.readAsDataURL(files?.[0]);
    },
    [setFieldValue]
  );

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={type} // AssetPhoto or Photo or Image
      cancelButtonProps={{
        disabled: isSubmitting,
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        loading: isSubmitting,
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey={type === BuilderFieldsTypes.AssetPhoto ? 'Tool name' : 'Label (shown to user)'}
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.File
              label={getIntl(type === BuilderFieldsTypes.Image ? 'Add Image' : 'Add Photo')}
              accept="image/*"
              onChange={handleFileChange}
              isInvalid={Boolean(touched.link && errors.link)}
              feedback={touched.link ? getIntl(errors.link as string) : ''}
            />
          </Form.Group>
          {values.link && (
            <Form.Group as={Col} className="d-flex justify-content-center">
              <img src={values.link} alt={values.name} className="img-thumbnail mt-2" height={200} width={200} />
            </Form.Group>
          )}
        </Form.Row>
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default PhotoModal;
