import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { uploadDERPhaseInAction } from 'modules/newLoad';

const useConfiguredFormik = ({ toggleModal }: { toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<{
    description: string;
    der_description_id: number | null;
    file: File | null;
  }>({
    initialValues: {
      description: '',
      der_description_id: null,
      file: null,
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Field can't be empty!"),
      der_description_id: Yup.number().nullable().required("Field can't be empty!"),
      file: Yup.mixed().required('Please upload DERs phase-in excel first'),
    }),

    onSubmit: values => {
      const formData: FormData = new FormData();
      formData.append('f', values.file as Blob);

      return dispatch(
        uploadDERPhaseInAction(
          { description: values.description, der_description_id: values.der_description_id! },
          formData
        )
      )
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
