import React from 'react';
import { useLocale } from 'hooks';
import { Tabs, Tab } from 'components/_common';
import TableTenants from './TableTenants';
import ModalTenants from './ModalTenants';
import TableFeatures from './TableFeatures';
import ModalFeatures from './ModalFeatures';
import { Routes } from 'constants/index';

const ViewSupervisor: React.FC = () => {
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter unmountOnExit>
      <Tab eventKey={Routes.AdminSupervisor} title={getIntl('Tenants')}>
        <TableTenants />
        <ModalTenants />
      </Tab>
      <Tab eventKey={`${Routes.AdminSupervisor}${Routes.HASH.Features}`} title={getIntl('Features')}>
        <TableFeatures />
        <ModalFeatures />
      </Tab>
    </Tabs>
  );
};

export default ViewSupervisor;
