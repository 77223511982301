import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { createMaintenanceDetailedPlanAction } from 'modules/maintenance';

interface Values extends Omit<Maintenance.DetailedPlan, 'id' | 'maintenanceYear'> {
  assetCategoryCode: Type.AssetCategories | null;
}

const useConfiguredFormik = ({ toggleModal }: { toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const formik = useFormik<Values>({
    initialValues: {
      planId: null,
      planName: '',
      assetCategoryCode: null,
      name: '',
      overrideYear: null,
    },

    validationSchema: Yup.object().shape({
      planId: Yup.number().nullable().required("Field can't be empty!"),
      name: Yup.string()
        .required("Field can't be empty!")
        .when('assetCategoryCode', (assetCategoryCode: Type.AssetCategories | null, schema: any) =>
          schema.test({
            test: (name: string) => !name || (assetCategoryCode && name.includes(assetCategoryCode)),
            message: getIntl('Asset code must be in {{assetCategoryCode}} category', { assetCategoryCode }),
          })
        )
        .when('assetCategoryCode', (assetCategoryCode: Type.AssetCategories | null, schema: any) =>
          schema.test({
            test: (name: string) => !name || !assetCategoryCode || name.length >= 5,
            message: 'Please provide valid asset code',
          })
        ),
      overrideYear: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      return dispatch(createMaintenanceDetailedPlanAction(values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
