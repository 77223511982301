import { withIntl } from 'hoc';
import {
  FormInput as SharedFormInput,
  Checkbox as SharedCheckbox,
  FormReactSelect as SharedFormReactSelect,
  DataTableTemplate as SharedDataTableTemplate,
  Button as SharedButton,
  OverlayTriggerTooltip,
  ErrorBoundary,
} from '@utiligize/shared/components';

const FormInput = withIntl(SharedFormInput);
const Checkbox = withIntl(SharedCheckbox);
const FormReactSelect = withIntl(SharedFormReactSelect);
const DataTableTemplate = withIntl(SharedDataTableTemplate);
const Button = withIntl(SharedButton);

export { FormInput, Checkbox, FormReactSelect, DataTableTemplate, Button, OverlayTriggerTooltip, ErrorBoundary }; // styled components error fix

export type { FormInputProps } from '@utiligize/shared/components/FormInput';
export type { FormReactSelectProps, InputActionMeta } from '@utiligize/shared/components/FormReactSelect';
export type { ButtonProps } from '@utiligize/shared/components/Button';
