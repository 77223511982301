import React from 'react';
import { Switch, Route } from 'react-router';
import { BudgetingRevenueCap, BudgetingParameters, BudgetingBenchmarking, BudgetingAssetBase } from 'components';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageBudgeting: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isBudgetingEnabled}>
    <Switch>
      <Route exact path={Routes.Budgeting} component={BudgetingParameters} />
      <Route exact path={Routes.BudgetingRevenueCap} component={BudgetingRevenueCap} />
      <Route exact path={Routes.BudgetingBenchmarking} component={BudgetingBenchmarking} />
      <Route exact path={Routes.BudgetingAssetBase} component={BudgetingAssetBase} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageBudgeting;
