import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';
import { MapTheme } from 'constants/index';
import { IconMapThemeStandard, IconMapThemeResponsive, IconMapThemeMonochrome } from '@utiligize/shared/resources';

const MapThemeIconMap: { [key: string]: React.ReactNode } = {
  [MapTheme.Standard]: <IconMapThemeStandard width={19} height={19} />,
  [MapTheme.Responsive]: <IconMapThemeResponsive width={19} height={19} />,
  [MapTheme.Monochrome]: <IconMapThemeMonochrome width={19} height={19} />,
};

const SelectMapTheme: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const selectedMapTheme = useSelector(selectedMapThemeSelector);

  const getLabel = useCallback(
    (theme: Layouts.MapTheme) => (
      <span className="d-flex align-items-center">
        {MapThemeIconMap[theme]}&nbsp;&nbsp;{getIntl(theme)}
      </span>
    ),
    [getIntl]
  );

  const options = useMemo(
    () => Object.values(MapTheme).map(theme => ({ value: theme, label: getLabel(theme) })),
    [getLabel]
  );

  const handleSelectChange = useCallback(
    (option: { value: Layouts.MapTheme }): void => {
      dispatch(setLayoutAction({ selectedMapTheme: option.value }));
    },
    [dispatch]
  );

  return (
    <FormReactSelect
      labelKey="Select map theme"
      value={{ value: selectedMapTheme, label: getLabel(selectedMapTheme) }}
      options={options}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
    />
  );
};

export default SelectMapTheme;
