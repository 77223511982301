import React from 'react';
import { Switch, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { ViewSaifiCMLplots, ViewReinvestments, ViewConstraints, ViewPoFModels } from 'components/Reliability';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes, StorageKeys } from 'constants/index';

const PageReinvestmentReliability: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);
  const isViewReinvestmentsEnabled = featureToggles[StorageKeys.FEATURE_TOGGLE_RELIABILITY_REINVESTMENTS];

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isReliabilityEnabled}>
      <Switch>
        <Route exact path={Routes.Reliability} component={ViewSaifiCMLplots} />
        {isViewReinvestmentsEnabled && (
          <Route exact path={Routes.ReliabilityReinvestments} component={ViewReinvestments} />
        )}
        <Route exact path={Routes.ReliabilityConstrains} component={ViewConstraints} />
        <Route exact path={Routes.ReliabilityPoFModels} component={ViewPoFModels} />
      </Switch>
    </PagePermissionsProvider>
  );
};

export default PageReinvestmentReliability;
