import * as d3 from 'd3';
import { useEffect, RefObject, useState } from 'react';
import { MockDataType, MockGraphType, Vertice, Edge, VoltagesUsed, CnaimLookup } from '../core/NetworkChartData';
import { SingleLineDiagramTypes } from 'constants/index';

const icons: any = {
  18: {
    icon: 'switch',
    asset_register_category: '10 kV Switchgear (GM) Secondary',
  },
  72: { icon: 'switch', asset_register_category: '60 kV Switchgear (GM)' },
  100: {
    icon: 'line',
    asset_register_category: 'Cable connector for building topology',
  },
  53: {
    icon: 'line',
    asset_register_category: '66kV UG Cable (Non Pressurised)',
  },
  63: { icon: 'small_circle', asset_register_category: 'HV Cable Connector' },
  40: {
    icon: 'line',
    asset_register_category: '66kV OHL (Tower Line) Conductor',
  },
  46: { icon: 'line', asset_register_category: '10 kV UG Cable (Oil)' },
  47: {
    icon: 'line',
    asset_register_category: '10 kV UG Cable (Non Pressurised)',
  },
  94: { icon: 'triangle', asset_register_category: 'Secondary Substation' },
  95: { icon: 'triangle', asset_register_category: 'Primary Substation' },
  79: { icon: 'two_circles', asset_register_category: '66kV Transformer (GM)' },
  112: { icon: 'small_circle', asset_register_category: 'HV busbars' },
  111: { icon: 'small_circle', asset_register_category: 'MV busbars' },
  29: {
    icon: 'two circles',
    asset_register_category: '6.6/11kV Transformer (GM)',
  },
};
interface ContentRect {
  width: number;
  height: number;
}

export const useResizeObserver = (ref: RefObject<HTMLDivElement>) => {
  const [dimensions, setDimensions] = useState<ContentRect | null>(null);
  useEffect(() => {
    if (!ref.current) return;

    const observeTarget = ref.current;

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => setDimensions(entry.contentRect));
    });
    resizeObserver.observe(observeTarget);

    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, [ref]);

  return dimensions;
};

export const setupGraph = (
  data: MockDataType,
  type: string,
  innerWidth: number,
  innerHeight: number,
  mapFilterPrimarySubstationsId: number | null,
  margin: { top: number; right: number; left: number; bottom: number }
) => {
  let linksArray = [];
  const projection = d3
    .geoMercator()
    .scale(100)
    .translate([innerWidth / 2, innerHeight / 2]);

  // Filter data by primary_substation_id, so we get different maps for each substation
  if (type === SingleLineDiagramTypes.Geospatial) {
    data.vertices = data.vertices.filter((v: Vertice) => v.primary_substation_id === mapFilterPrimarySubstationsId);
  }

  const nameSet = new Set(data.vertices.map((v: Vertice) => v.name));

  for (let i = 0; i < data.edges.length; i++) {
    const link = data.edges[i];
    if (nameSet.has(link.from) && nameSet.has(link.to)) {
      link.source = data.vertices.find((v: Vertice) => v.name === link.from);
      link.target = data.vertices.find((v: Vertice) => v.name === link.to);
      linksArray.push(link);
    }
  }

  // find double edges and offset them by delta
  const mapOfLinks: { [key: string]: number } = {};

  for (let i = 0; i < data.edges.length; i++) {
    const key1 = data.edges[i].from;
    const key2 = data.edges[i].to;
    const keyUnique = [key1, key2].sort().join('+');
    if (mapOfLinks[keyUnique] !== undefined) {
      mapOfLinks[keyUnique] = mapOfLinks[keyUnique] + 1;
      data.edges[i].indexUnique = mapOfLinks[keyUnique];
    } else {
      mapOfLinks[keyUnique] = 0;
      data.edges[i].indexUnique = 0;
    }
  }

  // find overlapping nodes (geographically) and offset them by delta
  const mapOfNodes: { [key: string]: number } = {};

  for (let i = 0; i < data.vertices.length; i++) {
    const keyUnique = `${data.vertices[i].geom.coordinates[0]}-${data.vertices[i].geom.coordinates[0]}`;

    if (mapOfNodes[keyUnique] !== undefined) {
      mapOfNodes[keyUnique] = mapOfNodes[keyUnique] + 1;
      data.vertices[i].indexUnique = mapOfNodes[keyUnique];
    } else {
      mapOfNodes[keyUnique] = 0;
      data.vertices[i].indexUnique = 0;
    }
  }

  // Prepare scales
  const invert = true;
  const scales = setupScales(data.vertice_coordinates, innerWidth, innerHeight, margin, invert);
  let initialXScale = scales.xScale;
  let initialYScale = scales.yScale;

  for (let i = 0; i < data.vertices.length; i++) {
    if (type === 'Tree') {
      // DONE: create custom projection for the tree layout
      data.vertices[i].V1 = initialXScale(data.vertice_coordinates[i].V1);
      data.vertices[i].V2 = initialYScale(data.vertice_coordinates[i].V2);
    }

    if (type === SingleLineDiagramTypes.Geospatial) {
      // use projection
      let [V1, V2]: any = projection([data.vertices[i].geom.coordinates[0], data.vertices[i].geom.coordinates[1]]); // Note that d3.geoMercator takes [longitude, latitude]

      // @ts-ignore
      if (data.vertices[i]?.indexUnique > 1) {
        // in the same place, arrange nodes radially
        const keyUnique = `${data.vertices[i].geom.coordinates[0]}-${data.vertices[i].geom.coordinates[0]}`;
        const maxNodesSamePlace = mapOfNodes[keyUnique];

        [V1, V2] = transformNodeIndex(
          data.vertices[i].indexUnique as number,
          maxNodesSamePlace as number,
          V1 as number,
          V2 as number
        );
      }

      data.vertices[i].V1 = V1;
      data.vertices[i].V2 = -V2;
    }

    if (type === 'GEM') {
      data.vertices[i].V1 = data.vertice_coordinates[i].V1;
      data.vertices[i].V2 = data.vertice_coordinates[i].V2;
    }
  }

  if (data.voltages_used && data.cnaim_look_up) {
    for (let i = 0; i < data.edges.length; i++) {
      // Add cnaim_look_up to edges
      const cnaim_look_up =
        data.cnaim_look_up && data.cnaim_look_up.filter((row: CnaimLookup) => row.id === data.edges[i].cnaim_id)[0];

      data.edges[i].cnaim_look_up = (cnaim_look_up && icons[cnaim_look_up.id] && icons[cnaim_look_up.id].icon) || '';
      data.edges[i].asset_register_category = cnaim_look_up && cnaim_look_up.asset_register_category;
    }

    for (let i = 0; i < data.vertices.length; i++) {
      // Add voltage_used
      const voltage_used = data.voltages_used.filter((row: VoltagesUsed) => row.id === data.vertices[i].voltage_id)[0];
      data.vertices[i].voltage_used = voltage_used ? voltage_used.voltage_id : 999;

      data.vertices[i].voltage_level_text = voltage_used && voltage_used.voltage_level_text;

      // Add cnaim_look_up to vertices
      const cnaim_look_up =
        data.cnaim_look_up && data.cnaim_look_up.filter((row: CnaimLookup) => row.id === data.vertices[i].cnaim_id)[0];

      data.vertices[i].cnaim_look_up = (cnaim_look_up && icons[cnaim_look_up.id] && icons[cnaim_look_up.id].icon) || '';
      data.vertices[i].asset_register_category = cnaim_look_up && cnaim_look_up.asset_register_category;
    }
  }

  // Arrange transformers vertically on map
  if (type === SingleLineDiagramTypes.Geospatial) {
    linksArray.forEach(link => {
      if (link.asset_register_category?.match(/transformer/i)) {
        if (link.source && link.target) {
          link.source.V1 = link.target.V1;

          if ((link.source.V2 = link.target.V2)) {
            link.source.V2 -= 0.0001;
          }
        }
      }
    });
  }

  return {
    nodes: data.vertices,
    links: linksArray,
  };
};

export const setupScales = (
  data: any,
  innerWidth: number,
  innerHeight: number,
  margin: { top: number; right: number; left: number; bottom: number },
  invert: boolean
) => {
  const minXValue = data.reduce((min: number, p: Vertice) => (p.V1 !== undefined && p.V1 < min ? p.V1 : min), Infinity);
  const maxXValue = data.reduce(
    (max: number, p: Vertice) => (p.V1 !== undefined && p.V1 > max ? p.V1 : max),
    -Infinity
  );
  const minYValue = data.reduce((min: number, p: Vertice) => (p.V2 !== undefined && p.V2 < min ? p.V2 : min), Infinity);
  const maxYValue = data.reduce(
    (max: number, p: Vertice) => (p.V2 !== undefined && p.V2 > max ? p.V2 : max),
    -Infinity
  );

  // const xRange = maxXValue - minXValue;
  // const yRange = maxYValue - minYValue;

  // const xScalingFactor = innerWidth / xRange;
  // const yScalingFactor = innerHeight / yRange;

  // const minScalingFactor = Math.min(xScalingFactor, yScalingFactor);

  // // calculate the scaled ranges
  // const scaledXRange = xRange * minScalingFactor;
  // const scaledYRange = yRange * minScalingFactor;

  // const xOffset = (innerWidth - scaledXRange) / 2;
  // const yOffset = (innerHeight - scaledYRange) / 2;

  const xScale = d3.scaleLinear().domain([minXValue, maxXValue]).range([margin.top, innerWidth]);
  // .range([xOffset, innerWidth]);
  // .range([xOffset, xOffset + scaledXRange]);

  const yScale = d3
    .scaleLinear()
    .domain([maxYValue, minYValue])
    .range([invert ? innerHeight : margin.top, invert ? margin.top : innerHeight]);
  // .range([invert ? innerHeight - yOffset : yOffset, invert ? yOffset : innerHeight - yOffset]);

  return { xScale, yScale };
}; // end setupScales

const transformIndex = (index: number) => {
  if (index % 2 === 0) {
    if (index === 0) {
      return index;
    }
    if (index > 1) {
      return -index / 2;
    }
  } else {
    if (index === 1) {
      return index;
    }
    if (index > 1) {
      return (index + 1) / 2;
    }
  }
  return 0;
}; // end transformIndex

const transformNodeIndex = (index: number, maxNodesSamePlace: number, V1: number, V2: number) => {
  const ratio = 360 / (maxNodesSamePlace - 1);
  const degrees = ratio * index;

  const x = Math.cos((degrees * Math.PI) / 180) * 0.0002 + V1;
  const y = Math.sin((degrees * Math.PI) / 180) * 0.0002 + V2;

  return [x, y];
}; // end transformNodeIndex

const getOffsetLine = (x1: number, x2: number, y1: number, y2: number, delta: number) => {
  // Handle special case where line is vertical
  if (x1 === x2) {
    return {
      x1: x1 + delta,
      x2: x2 + delta,
      y1: y1,
      y2: y2,
    };
  }

  // Handle special case where line is horizontal
  if (y1 === y2) {
    return {
      x1: x1,
      x2: x2,
      y1: y1 + delta,
      y2: y2 + delta,
    };
  }

  const m = (y2 - y1) / (x2 - x1); // slope of the original line
  const perpM = -1 / m; // slope of the line perpendicular to the original line

  let deltaX = Math.sqrt(Math.abs(delta) ** 2 / (1 + perpM ** 2));
  let deltaY = perpM * deltaX;

  if (m < 0) {
    deltaX = -deltaX;
    deltaY = -deltaY;
  }

  if (delta < 0) {
    deltaX = -deltaX;
    deltaY = -deltaY;
  }

  return {
    x1: x1 + deltaX,
    x2: x2 + deltaX,
    y1: y1 + deltaY,
    y2: y2 + deltaY,
  };
}; // end getOffsetLine

export const drawGraph = (
  graph: MockGraphType,
  linksGroup: any,
  nodesGroup: any,
  delta: number,
  xScale: any,
  yScale: any,
  handleDrawGraphClick?: any
) => {
  const nodes = nodesGroup
    .selectAll('g')
    .data(graph.nodes)
    .join('g')
    // .attr('class', (d: any) => `node ${d.type}`)
    .attr('class', (d: Vertice) => `node voltages-used-${d.voltage_used}`)
    .attr('transform', (d: Vertice) => `translate(${xScale(d.V1 as number)}, ${yScale(d.V2 as number)})`);

  nodes
    .selectAll('circle')
    .data((d: Vertice) => [d])
    .join('circle')
    .attr('class', 'circle')
    .attr('r', 7.5)
    .on('click', function (this: any, event: any, d: any) {
      handleDrawGraphClick(d.customer_asset_id);
    });

  nodes
    .selectAll('text')
    .data((d: Vertice) => [d])
    .join('text')
    .attr(
      'class',
      (
        d: Vertice // Zoom and voltage type dependent labeling
      ) =>
        d.voltage_level_text === 'Low'
          ? 'node-label  node-text-label node-text-label-low'
          : d.voltage_level_text === 'Medium'
            ? 'node-label  node-text-label node-text-label-medium'
            : d.voltage_level_text === 'High'
              ? 'node-label  node-text-label node-text-label-high'
              : d.voltage_level_text === 'Extra high'
                ? 'node-label  node-text-label node-text-label-extra-high'
                : 'node-label  node-text-label'
    )
    .text((d: Vertice) => d.name)
    .attr('alignment-baseline', 'middle')
    .attr('y', -14)
    .attr('text-anchor', 'middle')
    .attr('pointer-events', 'none')
    .style(
      'opacity',
      (
        d: Vertice // Zoom and voltage type dependent opacity
      ) =>
        d.voltage_level_text === 'Medium' || d.voltage_level_text === 'High' || d.voltage_level_text === 'Extra high'
          ? 1
          : 0
    )
    .style(
      'font-size',
      (
        d: Vertice // Zoom and voltage type dependent font size
      ) =>
        d.voltage_level_text === 'Low'
          ? 2
          : d.voltage_level_text === 'Medium'
            ? 7
            : d.voltage_level_text === 'High'
              ? 9
              : d.voltage_level_text === 'Extra high'
                ? 10
                : 7
    );

  const links = linksGroup
    .selectAll('path')
    .data(graph.links)
    .join('path')
    .attr('class', (d: Edge) => {
      if (d.source) {
        return `link ${d.cnaim_look_up} ${
          d.source.voltage_used ? 'link-voltages-used-' + d.source.voltage_used : 'link-voltages-used-999'
        }`;
      } else {
        return 'link link-voltages-used-999';
      }
    })
    .attr('d', (d: Edge) => {
      const x1raw = xScale(d?.source?.V1 as number);
      const y1raw = yScale(d?.source?.V2 as number);
      const x2raw = xScale(d.target?.V1 as number);
      const y2raw = yScale(d.target?.V2 as number);
      const deltaMultiplied = transformIndex(d.indexUnique as number) * delta;
      const { x1: newX1, x2: newX2, y1: newY1, y2: newY2 } = getOffsetLine(x1raw, x2raw, y1raw, y2raw, deltaMultiplied);

      return `M ${newX1} ${newY1} L ${(newX1 + newX2) / 2} ${(newY1 + newY2) / 2} M ${(newX1 + newX2) / 2} ${
        (newY1 + newY2) / 2
      } L ${newX2} ${newY2}`;
    })
    .attr('marker-mid', (d: Edge) => {
      let icon;

      switch (d.cnaim_look_up) {
        case 'switch':
          if (d.in_operation) {
            if (d.normal_state === 'I') {
              icon = 'url(#switch-closed)';
            } else {
              icon = 'url(#switch-open)';
            }
          }
          break;
        case 'two_circles':
          icon = 'url(#two_circles)';
          break;
        default:
          icon = '';
      }

      return icon;
    })
    .on('click', function (this: any, event: any, d: Edge) {
      handleDrawGraphClick(d.customer_asset_id);
    });

  return { nodes, links };
}; // end drawGraph

export const transformChart = (nodes: any, links: any, currentXScale: any, currentYScale: any, delta: number) => {
  // Move dragged node
  nodes.attr('transform', (d: Vertice) => `translate(${currentXScale(d.V1)}, ${currentYScale(d.V2)})`);

  // Update links
  links.attr('d', (d: Edge) => {
    const x1raw = currentXScale(d?.source?.V1 as number);
    const y1raw = currentYScale(d?.source?.V2 as number);
    const x2raw = currentXScale(d.target?.V1 as number);
    const y2raw = currentYScale(d.target?.V2 as number);

    const deltaMultiplied = transformIndex(d.indexUnique as number) * delta;

    const { x1: newX1, x2: newX2, y1: newY1, y2: newY2 } = getOffsetLine(x1raw, x2raw, y1raw, y2raw, deltaMultiplied);

    return `M ${newX1} ${newY1} L ${(newX1 + newX2) / 2} ${(newY1 + newY2) / 2} M ${(newX1 + newX2) / 2} ${
      (newY1 + newY2) / 2
    } L ${newX2} ${newY2}`;
  });
};

export const removeHighlight = () => {
  d3.selectAll('.selected-node').classed('selected-node', false);
  d3.selectAll('.selected-link').classed('selected-link', false);
};
