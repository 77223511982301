import React, { useEffect, useCallback, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { modalAssetsCNAIMMetaSelector } from 'modules/layouts/selectors';
import { setModalAssetsCNAIMMetaAction } from 'modules/layouts';
import { uploadAssetCategoriesCNAIMFileAction } from 'modules/assets';
import { AssetsCNAIMTable } from 'components/_tables';
import { Modal, FormInput } from 'components/_common';

const AssetsCNAIMModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const meta: Layouts.ModalAssetsCNAIMMeta | null = useSelector(modalAssetsCNAIMMetaSelector);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (meta) setShow(true);
  }, [meta]);

  const closeModal = useCallback((): void => {
    setShow(!show);
    setTimeout(() => dispatch(setModalAssetsCNAIMMetaAction(null)), 500);
  }, [show, dispatch]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      dispatch(setModalAssetsCNAIMMetaAction({ ...meta, name: value }));
    },
    [meta, dispatch]
  );

  const handleSaveButtonClick = useCallback(async (): Promise<void> => {
    setIsSubmitting(true);
    dispatch(uploadAssetCategoriesCNAIMFileAction(meta!))
      .then(closeModal)
      .catch(console.error)
      .finally(() => setIsSubmitting(false));
  }, [meta, dispatch, closeModal]);

  return (
    <Modal
      dialogClassName="modal-90w"
      scrollable
      show={show}
      onHide={closeModal}
      titleKey="Edit assets"
      cancelButtonProps={{ labelKey: 'Close', onClick: closeModal }}
      submitButtonProps={{ labelKey: 'Save', loading: isSubmitting, onClick: handleSaveButtonClick }}
    >
      <>
        <Form.Group>
          <FormInput
            autoFocus
            type="string"
            size="sm"
            value={meta?.name}
            onChange={handleInputChange}
            placeholderKey="Enter assets version name"
          />
        </Form.Group>
        <AssetsCNAIMTable />
      </>
    </Modal>
  );
};

export default AssetsCNAIMModal;
