import styled from 'styled-components';
import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { FormInput, SelectSmartMeter, DateRangePicker, Button } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';

interface Props {
  assetMeta: Asset.ExtendedItem;
}

const AssetMetaForm: React.FC<Props> = ({ assetMeta }) => {
  const {
    values,
    errors,
    touched,
    dirty,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
  } = useConfiguredFormik(assetMeta);
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleDateRangePickerApply = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, picker: { startDate: Type.Moment; endDate: Type.Moment }) => {
      setFieldValue(event.target.name, picker.startDate);
    },
    [setFieldValue]
  );

  const renderDatePicker = (name: keyof Asset.Item, labelKey: string) => {
    if (!values.hasOwnProperty(name)) return null;
    return (
      <Form.Group>
        <DateRangePicker
          labelKey={labelKey}
          initialSettings={{
            singleDatePicker: true,
            startDate: (values[name] as any) || undefined,
          }}
          onApply={handleDateRangePickerApply}
          name={name}
        />
      </Form.Group>
    );
  };

  const renderInput = ({
    key,
    labelKey,
    type = 'string',
    autoFocus = false,
    disabled = false,
  }: {
    key: keyof Omit<Asset.Item, 'models'>;
    labelKey: string;
    type?: 'string' | 'number';
    autoFocus?: boolean;
    disabled?: boolean;
  }) => {
    if (!values.hasOwnProperty(key)) return null;
    return (
      <Form.Group>
        <FormInput
          labelKey={labelKey}
          autoFocus={autoFocus}
          type={type}
          name={key}
          value={(values[key] as any) || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          errorKey={Boolean(touched[key] && errors[key]) ? (errors[key] as string) : ''}
          disabled={disabled}
        />
      </Form.Group>
    );
  };

  return (
    <StyledForm onSubmit={handleFormSubmit} data-dirty={dirty}>
      {renderInput({ key: 'name', labelKey: 'Name', autoFocus: true })}
      {values.hasOwnProperty('elInTablID') && (
        <Form.Group>
          <SelectSmartMeter
            name="elInTablID"
            value={values?.elInTablID}
            onChange={(value: any) => setFieldValue('elInTablID', value?.value || null)}
            onBlur={() => setFieldTouched('elInTablID')}
            errorKey={Boolean(touched.elInTablID && errors.elInTablID) ? (errors.elInTablID as string) : ''}
            assetId={values.id}
            labelKey="Smart meter number"
          />
        </Form.Group>
      )}
      {renderInput({ key: 'type', labelKey: 'Type', autoFocus: true })}
      {renderInput({ key: 'address', labelKey: 'Address' })}
      {renderInput({ key: 'town', labelKey: 'Town' })}
      {renderInput({ key: 'serialNumber', labelKey: 'Serial number' })}
      {renderInput({ key: 'installation', labelKey: 'Installation number' })}
      {renderDatePicker('dateDeployed', 'Date deployed')}
      {renderInput({ key: 'manufacturingNumber', labelKey: 'Manufacturing number', type: 'number' })}
      {renderDatePicker('dateManufactured', 'Date manufactured')}
      {renderDatePicker('dateInspected', 'Date inspected')}
      {renderDatePicker('inspectionDate', 'Inspection date')}
      {renderDatePicker('nextInspectionDate', 'Next inspection date')}
      {renderInput({
        key: 'monthsBetweenInspections',
        labelKey: 'Months between inspections',
        type: 'number',
      })}
      {renderInput({
        key: 'monthsBetweenInspectionsCH',
        labelKey: 'Months between inspections',
        type: 'number',
      })}
      {renderInput({ key: 'risk', labelKey: 'Risk' })}
      {renderDatePicker('replacementDate', 'Replacement date')}
      {renderInput({ key: 'switchGroup', labelKey: 'Switch group' })}
      {renderInput({ key: 'shortCircuitImpedanceEk', labelKey: 'Short-circuit impedance, ek (%)' })}
      {renderInput({ key: 'meteringPoint', labelKey: 'Metering point' })}
      {renderInput({ key: 'controlUnit', labelKey: 'Control unit' })}
      {renderInput({ key: 'road', labelKey: 'Road' })}
      {renderInput({ key: 'houseNumber', labelKey: 'House number' })}
      {renderInput({ key: 'postcode', labelKey: 'Postcode', type: 'number' })}
      {renderInput({ key: 'section', labelKey: 'Section' })}
      {renderInput({ key: 'cabinet', labelKey: 'Cabinet' })}
      {renderInput({ key: 'numberOfFittings', labelKey: 'Number of fittings', type: 'number' })}
      {renderInput({ key: 'fittingComments', labelKey: 'Fitting comments' })}
      {renderDatePicker('fittingDateDeployed', 'Fitting date deployed')}
      {renderDatePicker('fittingDateManufactured', 'Fitting date manufactured')}
      {renderInput({ key: 'fittingReplacementYear', labelKey: 'Fitting replacement year', type: 'number' })}
      {renderInput({ key: 'fittingGuard', labelKey: 'Fitting guard' })}
      {renderInput({ key: 'fittingManufacturer', labelKey: 'Fitting manufacturer', disabled: true })}
      {renderInput({ key: 'fittingSupplier', labelKey: 'Fitting supplier' })}
      {renderInput({ key: 'fittingType', labelKey: 'Fitting type', disabled: true })}
      {renderInput({ key: 'fittingName', labelKey: 'Fitting name' })}
      {renderInput({ key: 'fittingLocalName', labelKey: 'Fitting local name' })}
      {renderInput({ key: 'fittingSerialNumber', labelKey: 'Fitting serial number' })}
      {renderDatePicker('bulbDateDeployed', 'Bulb date deployed')}
      {renderDatePicker('bulbDateManufactured', 'Bulb date manufactured')}
      {renderDatePicker('bulbNextReplacementDate', 'Bulb next replacement date')}
      {renderInput({ key: 'bulbManufacturer', labelKey: 'Bulb manufacturer', disabled: true })}
      {renderInput({ key: 'bulbSupplier', labelKey: 'Bulb supplier' })}
      {renderInput({ key: 'bulbType', labelKey: 'Bulb type', disabled: true })}
      {renderInput({ key: 'kva', labelKey: 'KVA', type: 'number' })}
      {renderInput({ key: 'circuitBreakerManufacturer', labelKey: 'Circuit breaker manufacturer' })}
      {renderInput({
        key: 'lowVoltageNominalCurrent',
        labelKey: 'Low voltage nominal current',
        type: 'number',
      })}
      {renderInput({ key: 'lowVoltageFuse', labelKey: 'Low voltage fuse' })}
      {renderInput({ key: 'bulbPower', labelKey: 'Bulb power' })}
      {renderInput({ key: 'bulbLifetime', labelKey: 'Bulb lifetime' })}
      {renderInput({ key: 'bulbLosses', labelKey: 'Bulb losses' })}
      {renderInput({ key: 'bulbLumens', labelKey: 'Bulb lumens' })}
      {renderInput({ key: 'bulbKelvin', labelKey: 'Bulb kelvin' })}
      {renderInput({ key: 'bulbSerialNumber', labelKey: 'Bulb serial number' })}
      {renderDatePicker('mastDateDeployed', 'Mast date deployed')}
      {renderDatePicker('mastDateManufactured', 'Mast date manufactured')}
      {renderDatePicker('mastInspectionDate', 'Mast inspection date')}
      {renderDatePicker('mastReplacementDate', 'Mast replacement date')}
      {renderInput({ key: 'mastInsulationClass', labelKey: 'Mast insulation class' })}
      {renderInput({ key: 'mastDimensions', labelKey: 'Mast dimensions' })}
      {renderInput({ key: 'mastHeight', labelKey: 'Mast height' })}
      {renderInput({ key: 'mastSupplier', labelKey: 'Mast supplier' })}
      {renderInput({ key: 'mastArea', labelKey: 'Mast area' })}
      {renderInput({ key: 'mastRiskGroup', labelKey: 'Mast risk group' })}
      {renderInput({ key: 'mastSafeguards', labelKey: 'Mast safeguards' })}
      {renderInput({ key: 'mastType', labelKey: 'Mast type', disabled: true })}
      {renderInput({ key: 'phoneNumber', labelKey: 'Phone number' })}
      {renderInput({ key: 'controlledByCab', labelKey: 'Controlled by cab' })}
      {renderInput({ key: 'multiGuardCab', labelKey: 'Multi guard cab' })}
      {values.hasOwnProperty('comments') && (
        <Form.Group className="mb-0">
          <FormInput
            labelKey="Comments"
            as="textarea"
            rows={5}
            name="comments"
            value={values.comments || ''}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>
      )}
      {dirty && (
        <StyledControlsContainer>
          <Button variant="primary-outline" labelKey="Cancel" onClick={() => resetForm()} disabled={isSubmitting} />
          <Button
            type="submit"
            variant="primary"
            labelKey="Save"
            marginLeft
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </StyledControlsContainer>
      )}
    </StyledForm>
  );
};

const StyledForm = styled(Form)<{ 'data-dirty': boolean }>`
  margin-bottom: ${props => (props['data-dirty'] ? 63 : 0)}px;
`;

const StyledControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 20px;
  background: ${props => props.theme.colors.white};
  border-top: solid 1px ${props => props.theme.colors.grey100};
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.05);

  > button {
    width: 100%;
  }
`;

export default AssetMetaForm;
