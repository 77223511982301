import styled from 'styled-components';
import theme from '@utiligize/shared/theme';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useHistory } from 'react-router';
import { useScrollPosition, useLocale } from 'hooks';
import { fetchWikiPageAction } from 'modules/app';
import { appSidebarHelpMenuPageSelectorFactory } from 'modules/app/selectors';
import { Alert, Spinner } from 'components/_common';
import { Routes } from 'constants/index';

const WikiPagesRoutesIdsMap: { [key: string]: number } = {
  [Routes.Budgeting]: 18,
  [Routes.Customers]: 17,
  [Routes.Map]: 5,
  [Routes.NetworkLoading]: 6,
  // [Routes.NewLoad]: null,
  [Routes.Investment]: 21,
  [generatePath(Routes.Tasks)]: 19,
  [generatePath(Routes.Meters)]: 8,
  [Routes.Maintenance]: 20,
  [Routes.AssetModels]: 10,
  [Routes.Forms]: 11,
  [generatePath(Routes.History)]: 16,
  [Routes.DataManuals]: 12,
  [Routes.Assets]: 13,
  [Routes.Reliability]: 14,
  [Routes.Interventions]: 15,
};

const SidebarHelpMenu: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const { location } = useHistory();
  const [loading, setLoading] = useState(false);
  const scrollPosition = useScrollPosition({ maxUpdateValue: parseInt(theme.heights.topNavigation) });
  const key = Object.keys(WikiPagesRoutesIdsMap).find((key: string) => location.pathname.includes(key));
  const pageId = key ? WikiPagesRoutesIdsMap[key] : null;
  const page = useSelector(appSidebarHelpMenuPageSelectorFactory(pageId));

  useEffect(() => {
    if (page || !pageId) return;
    setLoading(true);
    dispatch(fetchWikiPageAction(pageId)).finally(() => setLoading(false));
  }, [page, pageId, dispatch]);

  const renderPage = () => {
    if (!pageId) {
      return (
        <Alert className="mb-0 text-center" variant="light">
          {getIntl('Coming soon')}
        </Alert>
      );
    }
    return (
      <>
        <h5>{page?.title}</h5>
        <hr className="mb-2" />
        <div dangerouslySetInnerHTML={{ __html: page?.render || '' }} />
      </>
    );
  };

  return (
    <StyledAside className="control-sidebar control-sidebar-dark" scrollPosition={scrollPosition}>
      <div className="p-3">{!loading ? renderPage() : <Spinner isInFullHeightContainer />}</div>
    </StyledAside>
  );
};

const StyledAside = styled.aside<{ scrollPosition: number }>`
  background: ${props => props.theme.colors.purple800};
  && {
    top: ${props => `calc(${props.theme.heights.topNavigation} - ${props.scrollPosition}px)`};
  }

  > div {
    height: 100%;
    overflow-y: auto;
    overscroll-behavior: contain;

    h1,
    h2,
    h3 {
      font-size: 16px;
    }
  }
`;

export default SidebarHelpMenu;
