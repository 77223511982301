import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { reliabilityModelOptionSelector } from 'modules/layouts/selectors';
import { setReliabilityModelOption } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectModel: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reliabilityModelOption = useSelector(reliabilityModelOptionSelector);

  const options = useMemo(() => ['Elastic net', 'Monte carlo'].map(i => ({ value: i, label: getIntl(i) })), [getIntl]);

  useEffect(() => {
    if (!reliabilityModelOption) dispatch(setReliabilityModelOption(options[0]));
  }, [reliabilityModelOption, dispatch, options]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption) => dispatch(setReliabilityModelOption(option)),
    [dispatch]
  );

  return (
    <FormReactSelect labelKey="Model" value={reliabilityModelOption} options={options} onChange={handleSelectChange} />
  );
};

export default SelectModel;
