import React from 'react';
import { DataTableTemplate } from 'components/_common';
import ModalUsers from './ModalUsers';
import ModalOneTimePassword from './ModalOneTimePassword';
import TableUsers from './TableUsers';

const ViewUsers: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <>
      <ModalUsers />
      <ModalOneTimePassword />
    </>
    <TableUsers />
  </DataTableTemplate>
);

export default ViewUsers;
