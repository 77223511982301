import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.answers;

export const answersItemsSelector = createSelector(
  rootSelector,
  (answers: Answers.Root): Answers.Item[] => answers.rows
);

export const answersCountSelector = createSelector(rootSelector, (answers: Answers.Root): number => answers.count);
