import React from 'react';
import { useLocale } from 'hooks';

const PageUpgradeYouPlan: React.FC = () => {
  const { getIntl } = useLocale();

  return (
    <div className="error-page mt-0 pt-5">
      <div className="error-content ml-0">
        <h3>
          <i className="fas fa-exclamation-triangle text-warning"></i> {getIntl('Please upgrade your account')}.
        </h3>
        <p>
          {getIntl('You should upgrade your account to be able to work with this view. Please contact administrator')}.
        </p>
      </div>
    </div>
  );
};

export default PageUpgradeYouPlan;
