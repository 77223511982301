import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { MaintenanceMissingActions } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string | null;
}

const SelectMaintenanceMissingAction: React.FC<Props> = ({ labelKey = 'Missing action', value, ...props }) => {
  const { getIntl } = useLocale();

  const selectOptions = useMemo(
    () =>
      Object.values(MaintenanceMissingActions).map((value: Maintenance.MissingActions) => ({
        value,
        label: getIntl(value),
      })),
    [getIntl]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={value ? { value, label: getIntl(value) } : null}
      options={selectOptions}
    />
  );
};

export default SelectMaintenanceMissingAction;
