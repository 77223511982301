import i18next from 'i18next';
import EN from 'locales/en.json';
import DA from 'locales/da.json';
import DE from 'locales/de.json';
import FR from 'locales/fr.json';
import IT from 'locales/it.json';
import NO from 'locales/no.json';
import SV from 'locales/sv.json';

i18next.init({
  interpolation: { escapeValue: false },
  resources: { EN, DA, DE, FR, IT, NO, SV },
});

export const getDaysOfWeek = (getIntl: (localeKey: string) => string): string[] =>
  ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(i => getIntl(i));

export const getMonthNames = (getIntl: (localeKey: string) => string) =>
  [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].map(i => getIntl(i));

export default i18next;
