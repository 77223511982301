import { portfolioIdSelector } from 'modules/layouts/selectors';
import { scenarioCalculationHashSelector } from 'modules/setup/selectors';
import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.flexibility;

const flexibilityPlanHashSelector = createSelector(
  rootSelector,
  (flexibility: Flexibility.Root): Type.Hash<Flexibility.PlanItem> => flexibility.planHash
);

export const flexibilityPlanSelector = createSelector(
  flexibilityPlanHashSelector,
  (planHash: Type.Hash<Flexibility.PlanItem>): Flexibility.PlanItem[] => Object.values(planHash)
);

export const flexibilityPlanCountSelector = createSelector(
  rootSelector,
  (flexibility: Flexibility.Root): number => flexibility.planCount
);

export const flexibilityPlanYearsRangeHashSelector = createSelector(
  rootSelector,
  (flexibility: Flexibility.Root): Flexibility.Root['planYearsRangeHash'] => flexibility.planYearsRangeHash
);

export const flexibilityPlanYearsRangeSelector = createSelector(
  [flexibilityPlanYearsRangeHashSelector, portfolioIdSelector, scenarioCalculationHashSelector],
  (planYearsRangeHash: Flexibility.Root['planYearsRangeHash'], portfolioId, scenarioId): number[] | null =>
    planYearsRangeHash[`${portfolioId}_${scenarioId}`] || null
);
