import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMaintenancePlansAction } from 'modules/maintenance';
import {
  maintenancePlansFetchedSelector,
  maintenancePlansSelector,
  maintenancePlanSelectorFactory,
} from 'modules/maintenance/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectMaintenancePlans: React.FC<Props> = ({ labelKey = 'Plan name', value, isDisabled, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const maintenancePlansFetched = useSelector(maintenancePlansFetchedSelector);
  const maintenancePlans = useSelector(maintenancePlansSelector);
  const maintenancePlan = useSelector(maintenancePlanSelectorFactory(value));

  useEffect(() => {
    if (maintenancePlansFetched) return;
    setIsLoading(true);
    dispatch(fetchMaintenancePlansAction()).finally(() => setIsLoading(false));
  }, [maintenancePlansFetched, dispatch]);

  const selectOptions = useMemo(() => {
    return maintenancePlans.reduce((acc: any[], setting: Maintenance.Plan) => {
      if (setting.planName.includes(inputValue)) {
        acc.push({ value: setting.id, label: setting.planName, assetCategoryCode: setting.assetCategory });
      }
      return acc;
    }, []);
  }, [maintenancePlans, inputValue]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={value ? { value, label: maintenancePlan?.planName || '' } : null}
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      options={selectOptions}
      onInputChange={setInputValue}
      inputValue={inputValue}
      isClearable
    />
  );
};

export default SelectMaintenancePlans;
