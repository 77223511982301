import { maintenanceTimeByActionTypeAssetCategoriesSelector } from 'modules/layouts/selectors';
import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.maintenance;

export const maintenancePlansFetchedSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): boolean => maintenance.maintenancePlansFetched
);

export const maintenancePlansCountSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): number => maintenance.maintenancePlansCount
);

const maintenancePlansHashSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): Type.Hash<Maintenance.Plan> => maintenance.maintenancePlansHash
);

export const maintenancePlansSelector = createSelector(
  maintenancePlansHashSelector,
  (maintenancePlansHash: Type.Hash<Maintenance.Plan>): Maintenance.Plan[] => Object.values(maintenancePlansHash)
);

export const maintenancePlanSelectorFactory = (id: number | null) =>
  createSelector(
    maintenancePlansHashSelector,
    (maintenancePlansHash: Type.Hash<Maintenance.Plan>): Maintenance.Plan => maintenancePlansHash[`_${id}_`]
  );

export const maintenanceSummaryCountSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): number => maintenance.maintenanceSummaryCount
);

const maintenanceSummaryHashSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): Type.Hash<Maintenance.Summary> => maintenance.maintenanceSummaryHash
);

export const maintenanceSummarySelector = createSelector(
  maintenanceSummaryHashSelector,
  (maintenanceSummaryHash: Type.Hash<Maintenance.Summary>): Maintenance.Summary[] =>
    Object.values(maintenanceSummaryHash)
);

export const maintenanceDetailedPlanCountSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): number => maintenance.maintenanceDetailedPlanCount
);

const maintenanceDetailedPlanHashSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): Type.Hash<Maintenance.DetailedPlan> => maintenance.maintenanceDetailedPlanHash
);

export const maintenanceDetailedPlanSelector = createSelector(
  maintenanceDetailedPlanHashSelector,
  (maintenanceDetailedPlanHash: Type.Hash<Maintenance.DetailedPlan>): Maintenance.DetailedPlan[] =>
    Object.values(maintenanceDetailedPlanHash)
);

export const maintenancePlanCNAIMCountSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): number => maintenance.maintenancePlanCNAIMCount
);

const maintenancePlanCNAIMHashSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): Maintenance.Root['maintenancePlanCNAIMHash'] => maintenance.maintenancePlanCNAIMHash
);

export const maintenancePlanCNAIMSelector = createSelector(
  maintenancePlanCNAIMHashSelector,
  (maintenancePlanCNAIMHash: Maintenance.Root['maintenancePlanCNAIMHash']): Maintenance.PlanCNAIM[] =>
    Object.values(maintenancePlanCNAIMHash)
);

export const timeByActionTypeChartDataSelector = createSelector(
  rootSelector,
  (maintenance: Maintenance.Root): Maintenance.Root['timeByActionTypeChartData'] =>
    maintenance.timeByActionTypeChartData
);

export const timeByActionTypeChartFilteredDataSelector = createSelector(
  [timeByActionTypeChartDataSelector, maintenanceTimeByActionTypeAssetCategoriesSelector],
  (
    chartData: Maintenance.Root['timeByActionTypeChartData'],
    assetCategories
  ): Maintenance.Root['timeByActionTypeChartData'] => {
    if (!assetCategories?.length || !chartData) return chartData;
    const indexes: number[] = [];
    const categories = chartData.categories.filter((item, index) => {
      if (!assetCategories.includes(item)) return false;
      indexes.push(index);
      return true;
    });
    const series = chartData.series.map((s: any) => ({
      ...s,
      data: s.data.filter((item: any, index: number) => indexes.includes(index)),
    }));
    return { ...chartData, categories, series };
  }
);
