import moment from 'moment';
import { TaskTypes, TaskMonths, DateFormats } from 'constants/index';

export const calcBusinessDaysInMonths = (
  startDate: string,
  endDate: string,
  { onlyBusinessDays }: { onlyBusinessDays: boolean } = { onlyBusinessDays: false }
) => {
  const day = moment(startDate);
  const businessDays: any = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [], 9: [], 10: [], 11: [] };

  while (day.isSameOrBefore(endDate, 'day')) {
    if (!onlyBusinessDays || (day.day() !== 0 && day.day() !== 6)) businessDays[day.month()].push(day.dayOfYear());
    day.add(1, 'd');
  }
  return businessDays;
};

export const getTaskMonthsNumbers = (task: Tasks.Task, year: number): Tasks.TaskMonthsNumbers => {
  if (task.type !== TaskTypes.Autogenerated) return null;

  return task.taskMonths?.reduce((acc: any, month) => {
    const index = TaskMonths.indexOf(month);
    const firstDay = moment(new Date(year, index, 1)).format(DateFormats.SERVER);
    const lastDay = moment(new Date(year, index + 1, 0)).format(DateFormats.SERVER);
    const a = calcBusinessDaysInMonths(firstDay, lastDay);
    acc[a[index][0]] = a[index].length;
    return acc;
  }, {});
};
