import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { repeatedTasksSectionsByDepartmentNumberSelectorFactory } from 'modules/tasks/selectors';
import { fetchRepeatedTasksSectionsAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  departmentNumber: number | null;
}

const SelectRepeatedTasksSection: React.FC<Props> = ({
  labelKey = 'Repeated tasks section',
  value,
  departmentNumber,
  isDisabled,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>('');
  const repeatedTasksSections = useSelector(repeatedTasksSectionsByDepartmentNumberSelectorFactory(departmentNumber));

  useEffect(() => {
    dispatch(fetchRepeatedTasksSectionsAction({ skipPagination: true })).finally(() => setIsLoading(false));
  }, [dispatch]);

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const { options, selectValue } = useMemo(
    () =>
      repeatedTasksSections.reduce(
        (
          acc: { options: Type.SelectOption[]; selectValue: Type.SelectOption | null },
          section: Tasks.RepeatedTasksSection
        ) => {
          const option = { value: section.id, label: section.name };
          if (value && option.value === value) acc.selectValue = option;
          if (String(option.label).includes(inputValue)) acc.options.push(option);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [repeatedTasksSections, value, inputValue]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      isDisabled={!departmentNumber || isDisabled || isLoading}
      isLoading={isLoading}
      options={options}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isClearable
    />
  );
};

export default SelectRepeatedTasksSection;
