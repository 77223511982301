import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.statistics;

export const actionsFetchedSelector = createSelector(
  rootSelector,
  (statistics: Statistics.Root): boolean => statistics.isFetched
);

export const completeActionsChartDataSelector = createSelector(
  rootSelector,
  (statistics: Statistics.Root): Statistics.CompleteActionsChartData | null => statistics.completeActionsChartData
);

export const finalChecksChartData = createSelector(
  rootSelector,
  (statistics: Statistics.Root): Statistics.FinalChecksChartData | null => statistics.finalChecksChartData
);

export const completedActionsByUsersCountSelector = createSelector(
  rootSelector,
  (statistics: Statistics.Root): number => statistics.completedActionsByUsersCount
);

const completedActionsByUsersHashSelector = createSelector(
  rootSelector,
  (statistics: Statistics.Root): Record<string, Statistics.UserAction> => statistics.completedActionsByUsersHash
);

export const completedActionsByUsersSelector = createSelector(
  completedActionsByUsersHashSelector,
  (completedActionsByUsersHash: Record<string, Statistics.UserAction>): Statistics.UserAction[] =>
    Object.values(completedActionsByUsersHash)
);
