import React, { useState } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Type.SelectOption | null;
  onChange: (option: Type.SelectOption) => void;
  labelKey?: string;
}

const SelectAssetSubPortfolio: React.FC<Props> = ({ labelKey = 'Asset sub-portfolio', onChange, ...rest }) => {
  const [options] = useState<Type.SelectOption[]>([{ value: 'All', label: 'All' }]);

  return <FormReactSelect labelKey={labelKey} options={options} onChange={onChange} {...rest} />;
};

export default SelectAssetSubPortfolio;
