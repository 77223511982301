import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { IconHelpCircle } from '@utiligize/shared/resources';
import { toggleIsSidebarHelpMenuOpen } from 'modules/layouts';
import { Button } from 'components/_common';

const SidebarHelpButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleSidebarHelpButton = useCallback((): void => {
    dispatch(toggleIsSidebarHelpMenuOpen());
  }, [dispatch]);

  return (
    <Button
      variant="primary"
      marginLeft
      icon={<IconHelpCircle />}
      onClick={handleSidebarHelpButton}
      aria-label="Information about the page"
    />
  );
};

export default SidebarHelpButton;
