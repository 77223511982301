import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { PaginationType } from 'constants/index';

export const fetchTotalHoursAction: any = createAction('tasks/FETCH_TOTAL_HOURS', async () => {
  return (
    dispatch: Shared.CustomDispatch,
    getState: () => State.Root
  ): Promise<Pick<Tasks.Root, 'totalHours' | 'totalHoursAmount' | 'employeeHoursAvailable'>> => {
    const state = getState();
    const { query, filters } = paginationSelectorFactory(PaginationType.TOTAL_HOURS)(state);
    return axios
      .get('api/admin/v2/secure/tasks/hours', {
        params: {
          query,
          filters: {
            year: filters?.year,
            appAction: filters?.appAction,
            type: filters?.type,
          },
        },
      })
      .then(res => ({
        totalHours: res.data.tasks,
        totalHoursAmount: res.data.totalHours,
        employeeHoursAvailable: Number(res.data.electricianHoursAvailable),
      }));
  };
});
