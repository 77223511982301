import styled from 'styled-components';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { MapContext } from 'context/map';
import { Button } from 'components/_common';

const BearingResetButton: React.FC = () => {
  const { map } = useContext(MapContext);
  const [bearing, setBearing] = useState(map?.getBearing() || 0);

  useEffect(() => {
    map?.on('moveend', () => setBearing(map?.getBearing()));
  }, [map]);

  const handleButtonClick = useCallback(() => {
    map?.setBearing(0);
    map?.setPitch(0);
  }, [map]);

  return (
    <Button
      variant="light"
      className="mt-1"
      onClick={handleButtonClick}
      icon={<StyledIcon style={{ transform: `rotate(${bearing}deg)` }} />}
      tooltipKey="Reset map orientation"
      tooltipPlacement="left"
    />
  );
};

const StyledIcon = styled.i`
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E");
  display: block;
  width: 29px;
  height: 29px;
  background-repeat: no-repeat;
  background-position: 50%;
`;

export default BearingResetButton;
