import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Matrix } from 'components/_charts';
import { useLocale } from 'hooks';
import { InterventionsTable } from 'components/_tables';
import { InterventionDetailsModal } from 'components/_modals';
import {
  DataTableTemplate,
  ChartIframe,
  FormReactSelect,
  SelectAssetCategoriesCNAIM,
  ContentContainer,
  Button,
} from 'components/_common';
import RangeSliderImpactYear from './RangeSliderImpactYear';
import RangeSliderMaxRiskConstrains from './RangeSliderMaxRiskConstrains';
import SelectAggregationLevel from './SelectAggregationLevel';
import SelectAssetPortfolio from './SelectAssetPortfolio';
import SelectAssetSubPortfolio from './SelectAssetSubPortfolio';
import { IconPlus } from '@utiligize/shared/resources';

const Interventions: React.FC = () => {
  const { lng, getIntl } = useLocale();

  const [interventionType, setInterventionType] = useState({
    value: 'All interventions',
    label: getIntl('All interventions'),
  });
  const [aggregationLevel, setAggregationLevel] = useState<Type.SelectOption | null>(null);
  const [assetCategoryCNAIM, setAssetCategoryCNAIM] = useState<Type.SelectOption | null>({
    value: 1,
    label: '0.4 kV Board',
  });
  const [assetPortfolio, setAssetPortfolio] = useState<Type.SelectOption | null>(null);
  const [assetSubPortfolio, setAssetSubPortfolio] = useState<Type.SelectOption | null>({ value: 'All', label: 'All' });

  const handleSelectChange = useCallback((value: any) => setInterventionType(value), []);

  const handleAggregationLevelChange = useCallback(
    (option: Type.SelectOption): void => setAggregationLevel(option),
    []
  );

  const handleAssetCategoryCNAIMChange = useCallback(
    (option: Type.SelectOption): void => setAssetCategoryCNAIM(option),
    []
  );

  const handleAssetPortfolioChange = useCallback((option: Type.SelectOption): void => setAssetPortfolio(option), []);

  const handleAssetSubPortfolioChange = useCallback(
    (option: Type.SelectOption): void => setAssetSubPortfolio(option),
    []
  );

  return (
    <DataTableTemplate paddingBottom>
      <div>
        <Row>
          <RangeSliderImpactYear />
          <RangeSliderMaxRiskConstrains />
          <Col xs={3}>
            <FormReactSelect
              labelKey=""
              mutedTextLabelKey="Intervention type"
              value={interventionType}
              options={['All interventions', 'Maintenance', 'Repair', 'Refurbish', 'Replace', 'Augment'].map(i => ({
                value: i,
                label: getIntl(i),
              }))}
              isSearchable={false}
              onChange={handleSelectChange}
              variant="small"
            />
          </Col>
        </Row>
        <Row className="align-items-start mt-2">
          <Col xs={3}>
            <SelectAggregationLevel
              value={aggregationLevel}
              onChange={handleAggregationLevelChange}
              labelKey=""
              mutedTextLabelKey="Aggregation level"
              variant="small"
            />
          </Col>
          <Col xs={3}>
            <SelectAssetCategoriesCNAIM
              value={assetCategoryCNAIM}
              setValueAction={handleAssetCategoryCNAIMChange}
              labelKey=""
              mutedTextLabelKey="Asset categories"
              variant="small"
            />
          </Col>
          <Col xs={2}>
            <SelectAssetPortfolio
              value={assetPortfolio}
              onChange={handleAssetPortfolioChange}
              labelKey=""
              mutedTextLabelKey="Asset portfolio"
              variant="small"
            />
          </Col>
          <Col xs={2}>
            <SelectAssetSubPortfolio
              value={assetSubPortfolio}
              onChange={handleAssetSubPortfolioChange}
              labelKey=""
              mutedTextLabelKey="Asset sub-portfolio"
              variant="small"
            />
          </Col>
          <Col xs={2}>
            <Button icon={<IconPlus />} labelKey="Add to bundle" variant="primary" />
          </Col>
        </Row>
      </div>

      <ContentContainer>
        <Row>
          <Col>
            <InterventionDetailsModal />
            <InterventionsTable containerClassName="border-top-0 p-0" />
          </Col>
          <Col xs="auto">
            <Matrix apiUrl="risk_matrix_intervention" lng={lng} />
            <ChartIframe
              title="Plot before/after intervention"
              src="plot_before_after_intervention"
              height="385px"
              className="mt-3"
            />
            <ChartIframe title="Plot future risk" src="plot_future_risk" height="385px" className="mt-3" />
          </Col>
        </Row>
      </ContentContainer>
    </DataTableTemplate>
  );
};

export default Interventions;
