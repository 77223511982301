import styled from 'styled-components';
import React from 'react';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const Loading: React.FC<Props> = ({ className, style }) => (
  <StyledContainer className={className} style={style}>
    <i className="fas fa-spinner fa-spin" />
  </StyledContainer>
);

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 10px 0;
`;

export default Loading;
