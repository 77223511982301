import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

interface Props {
  [key: string]: any;
}

const TextOverflow: React.FC<Props> = ({ children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);

  const onMouseOver = (e: React.MouseEvent<HTMLElement>) => {
    const overflow = ref.current?.scrollWidth! > ref.current?.clientWidth!;
    const bbox = (e.target as HTMLElement).getBoundingClientRect();
    if (overflow) setPosition({ x: bbox.x, y: bbox.y });
  };

  const onMouseLeave = () => {
    if (position) setPosition(null);
  };

  return (
    <StyledTextOverflow ref={ref} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} {...props}>
      {children}
      {position &&
        props['data-text'] &&
        createPortal(
          <StyledOverflowTooltip $x={position.x} $y={position.y}>
            {props['data-text']}
          </StyledOverflowTooltip>,
          document.body
        )}
    </StyledTextOverflow>
  );
};

const StyledTextOverflow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
`;

const StyledOverflowTooltip = styled.div<{ $x: number; $y: number }>`
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  position: absolute;
  left: ${({ $x }) => $x}px;
  top: ${({ $y }) => $y}px;
  z-index: 1000;
  display: block;
  overflow: visible;
  text-overflow: inherit;
  background: #fff;
  position: fixed;
  white-space: nowrap;
  margin-left: -3px;
  padding: 0 5px;
  background: #ffffff;
  border: 0.5px solid var(--map-outline-color);
  box-shadow:
    0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06);
  border-radius: 4px;
  cursor: default;
`;

export default TextOverflow;
