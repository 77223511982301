import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { setPaginationAction } from 'modules/layouts';

interface Props {
  paginationType: Type.PaginationType;
  filters: Layouts.Filters;
}

const useConfiguredFormik = ({ paginationType, filters }: Props) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Layouts.Filters>({
    initialValues: { ...filters },

    enableReinitialize: true,

    onSubmit: values => {
      dispatch(
        setPaginationAction({
          type: paginationType,
          modifier: { filters: { ...filters, ...values }, offset: 0 },
        })
      );
      document.body.click(); // close popover
    },
  });

  return formik;
};

export default useConfiguredFormik;
