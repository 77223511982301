import { BuilderFieldsTypes } from 'constants/index';
import { _cloneDeep } from '@utiligize/shared/utils';

export const transformBlocks = (initBlocks: any[]) => {
  const blocks = _cloneDeep(initBlocks).map((item: any) => {
    const { fields, ...block } = item;

    const parentFields = item.fields.filter((field: any) => !field.parentId);
    const childFields = item.fields.filter((field: any) => field.parentId);

    const allFields = parentFields.map((parentField: any) => {
      parentField.checked = [!!parentField.optionalBlockId1, !!parentField.optionalBlockId2];

      parentField.params.forEach((itemParam: any) => {
        itemParam?.variants.forEach((itemVariant: any) => {
          const firstChildFields = childFields.filter((field: any) => itemVariant.id === field.variantId);
          if (!itemVariant.field) itemVariant.field = [];

          for (const firstChildField of firstChildFields) {
            firstChildField.checked = [!!firstChildField.optionalBlockId1, !!firstChildField.optionalBlockId2];

            firstChildField.indexBlock = firstChildField.num;
            firstChildField.index = firstChildField.num;
            firstChildField.type = firstChildField.fieldtype.name;
            // if (firstChildField.num) firstChildField.id = firstChildField.num; // TODO - to be deleted

            firstChildField.params.forEach((firstChildFieldParam: any) => {
              firstChildFieldParam.variants?.forEach((itemVariantChild: any) => {
                itemVariantChild.index = itemVariantChild.num;

                const secondChildFields = childFields.filter((field: any) => itemVariantChild.id === field.variantId);
                if (!itemVariantChild.field) itemVariantChild.field = [];

                for (const secondChildField of secondChildFields) {
                  secondChildField.params.forEach((itemParamChild: any) => {
                    itemParamChild.variants.forEach((itemVariantChild: any) => {
                      itemVariantChild.index = itemVariantChild.num;
                    });
                  });

                  secondChildField.indexBlock = secondChildField.num;
                  secondChildField.index = secondChildField.num;
                  secondChildField.type = secondChildField.fieldtype.name;
                  // if (secondChildField.num) secondChildField.id = secondChildField.num; // TODO - to be deleted
                  itemVariantChild.field.push(secondChildField);
                }
              });
            });

            itemVariant.field.push(firstChildField);
          }
        });
      });
      return parentField;
    });

    return { ...block, fields: allFields };
  });

  blocks.forEach((item: Builder.SnapshotBlock) => {
    item.selected = false;
    item.index = item.num;
    item.fields.forEach((itemField: any) => {
      itemField.indexBlock = item.num;
      itemField.index = itemField.num;
      itemField.type = itemField.fieldtype.name;
      itemField.params.forEach((itemParam: any) => {
        itemField[itemParam.name.toLowerCase()] = itemParam.values[0].value;
        if (itemParam.variants) {
          itemParam.variants.forEach((itemVariant: any, indexVariant: number) => {
            itemVariant.key = indexVariant;
            itemVariant.index = itemVariant.num;

            // child
            if (itemVariant.field.length) {
              const itemField = itemVariant.field[0];
              itemField.indexBlock = item.num;
              itemField.index = itemField.num;
              itemField.type = itemField.fieldtype.name;
              itemField.params.forEach((itemParam: any) => {
                itemField[itemParam.name.toLowerCase()] = itemParam.values[0].value;
              });
            }
          });
        }
      });
    });
  });

  return blocks;
};

export const transformFormField = (initField: Builder.Field): Builder.Field => {
  if (!initField) return initField;
  const field = _cloneDeep(initField);
  let title = field.params[0].values[0].valuealt || (field.params[0].values[0].value as string) || '';

  if ([field.type, field.fieldtype?.name].includes(BuilderFieldsTypes.LinkedForm)) {
    title = (field.params[1].values[0] as any).valuealt;
  }

  return { ...field, title };
};
