import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale, useRecalculateButton, useBudgetingResetButton } from 'hooks';
import { isDepreciationsEditableSelector } from 'modules/layouts/selectors';
import { setIsDepreciationsEditable } from 'modules/layouts';
import { recalculateBudgetingAction } from 'modules/budgeting';
import { BudgetingParametersTable } from 'components/_tables';
import { DataTableTemplate, RecalculationSpinner } from 'components/_common';
import { ButtonSwitch } from '@utiligize/shared/components';

const BudgetingParameters: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, lng } = useLocale();
  const { loading: resetLoading, ButtonReset } = useBudgetingResetButton();
  const { loading, ButtonRecalculate } = useRecalculateButton(recalculateBudgetingAction);
  const isDepreciationsEditable = useSelector(isDepreciationsEditableSelector);

  const handleSwitchButtonClick = useCallback(
    (useFilteredTasks: boolean) => {
      dispatch(setIsDepreciationsEditable(useFilteredTasks));
    },
    [dispatch]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col>
          <span className="mr-2">
            <ButtonSwitch
              key={lng}
              checked={isDepreciationsEditable}
              offlabel={getIntl('Depreciations from investments')}
              onlabel={getIntl('Depreciations from investments')}
              width={300}
              onChange={handleSwitchButtonClick}
            />
          </span>
          {ButtonReset}
          {ButtonRecalculate}
        </Col>
      </Row>
      {loading || resetLoading ? <RecalculationSpinner /> : <BudgetingParametersTable />}
    </DataTableTemplate>
  );
};

export default BudgetingParameters;
