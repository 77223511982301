import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { cablesForecastsSelector, cablesForecastsCountSelector } from 'modules/networkLoading/selectors';
import { fetchCablesForecastAction } from 'modules/networkLoading';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableCablesForecast: React.FC = () => {
  const { dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.NETWORK_LOADING_CABLES_FORECAST;

  const items = useSelector(cablesForecastsSelector);
  const count = useSelector(cablesForecastsCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchCablesForecastAction(params)).then(
        (action: Shared.ReduxAction<Type.Hash<Pick<NetworkLoading.Root, 'cablesForecastsHash'>>>) =>
          Object.values(action.payload?.cablesForecastsHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable paginationType={paginationType} totalAmount={count} sendRequest={sendRequest} isDownloadCSVEnabled>
      {items?.map((item: NetworkLoading.CableForecast) => (
        <tr key={item.id}>
          <td>{item.kabel}</td>
          <td>{numberFormat(item.length)}</td>
          <td>{item.dateDeployed ? dateFormat(item.dateDeployed) : '-'}</td>
          <td>{item.ratingKva}</td>
          <td>{item.max_24h_pc || '-'}</td>
          <td>{item.max_24h_pu || '-'}</td>
          <td>{item.max_48h_pc || '-'}</td>
          <td>{item.max_48h_pu || '-'}</td>
          <td>{item.max_7d_pc || '-'}</td>
          <td>{item.max_7d_pu || '-'}</td>
          <td>{item.overload_start ? dateFormat(item.overload_start) : '-'}</td>
          <td>{item.peak ? dateFormat(item.peak) : '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCablesForecast;
