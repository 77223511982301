import { handleActions } from 'redux-actions';
import {
  fetchInterventionsAction,
  fetchSensitivityVariablesOptionsAction,
  fetchOutputVariablesOptionsAction,
  fetchRegulatoryZoneOptionsAction,
  fetchHealthIndexOptionsAction,
  fetchAssetPortfolioOptionsAction,
  fetchAggregationLevelOptionsAction,
} from './actions';

const initialState: Interventions.Root = {
  interventionsCount: 0,
  interventionsHash: {} as Type.Hash<Interventions.Intervention>,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchInterventionsAction.toString()]: {
    next: (
      state: Interventions.Root,
      action: Shared.ReduxAction<Interventions.Root & { skipStoreUpdate: true | undefined }>
    ): Interventions.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchInterventionsAction,
  fetchSensitivityVariablesOptionsAction,
  fetchOutputVariablesOptionsAction,
  fetchRegulatoryZoneOptionsAction,
  fetchHealthIndexOptionsAction,
  fetchAssetPortfolioOptionsAction,
  fetchAggregationLevelOptionsAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
