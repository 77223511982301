import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchReplacementReasonOptionsAction } from 'modules/investment';
import { replacementReasonOptionsSelector } from 'modules/investment/selectors';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string[] | null;
}

const SelectInvestmentReason: React.FC<Props> = ({ value, onChange }) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const options = useSelector(replacementReasonOptionsSelector);

  useEffect(() => {
    if (options || !portfolioId || !scenarioId) return;
    setIsLoading(true);
    dispatch(fetchReplacementReasonOptionsAction({ portfolioId, scenarioId })).finally(() => setIsLoading(false));
  }, [options, portfolioId, scenarioId, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        const localizedOption = { ...option, label: getIntl(option.label) };
        if (value?.includes(String(option.value))) acc.selectValues.push(localizedOption);
        acc.selectOptions.push(localizedOption);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, getIntl, lng]);

  const handleSelectChange = useCallback(
    (value: Type.SelectOption[], actionMeta: { name: string }) => {
      onChange?.(value, actionMeta);
    },
    [onChange]
  );

  return (
    <FormReactSelect
      name="replacementReasons"
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey=""
      mutedTextLabelKey="Investment reason"
      value={selectValues}
      options={selectOptions}
      onChange={handleSelectChange}
      variant="small"
      isMulti
      isSearchable={false}
    />
  );
};

export default SelectInvestmentReason;
