import React from 'react';
import { DataTableTemplate } from 'components/_common';
import { PaginationType } from 'constants/index';
import TableFilters from './TableFilters';
import TableDetailedInvestments from './TableDetailedInvestments';

const ViewDetailedInvestments: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <TableFilters type={PaginationType.DETAILED_INVESTMENTS} />
    <TableDetailedInvestments />
  </DataTableTemplate>
);

export default ViewDetailedInvestments;
