import { createAction } from 'redux-actions';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';
import { flexibilityPlanYearsRangeHashSelector } from './selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchFlexibilityPlanAction: any = createAction(
  'flexibility/FETCH_FLEXIBILITY_PLAN',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Flexibility.Root, 'planCount' | 'planHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.FLEXIBILITY_PLAN
      )(state);
      return AssetLifeAPI.get('flexibility/datatable', {
        params: {
          limit,
          offset,
          sort,
          column,
          query,
          year: filters?.year,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
        },
      }).then((res: any) => ({
        planCount: res.data.count,
        planHash: _keyBy(res.data.rows, (item: Flexibility.PlanItem) => `_${item.asset}_`),
      }));
    }
);

export const fetchYearsRangeAction = createAction(
  'flexibility/FETCH_YEARS_RANGE',
  async ({
    portfolioId,
    scenarioId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.Root['scenarioId'];
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Flexibility.Root, 'planYearsRangeHash'>> => {
      return AssetLifeAPI.get('flexibility/years', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const state = getState();
        const { filters } = paginationSelectorFactory(PaginationType.FLEXIBILITY_PLAN)(state);
        if (!res.data.includes(filters?.year)) {
          dispatch(
            setPaginationAction({
              type: PaginationType.FLEXIBILITY_PLAN,
              modifier: { filters: { ...filters, year: res.data[0] }, offset: 0 },
            })
          );
        }
        return {
          planYearsRangeHash: {
            ...flexibilityPlanYearsRangeHashSelector(state),
            [`${portfolioId}_${scenarioId}`]: res.data,
          },
        };
      });
    }
);
