import moment from 'moment';
import { useLocale } from 'hooks';
import React, { useMemo } from 'react';
import Chart, { getBaseOptions, getTooltipCircle } from 'components/_charts/Chart';

export interface ChartDataProps {
  series?: Shared.SeriesOptionsType[];
  title?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
}

const ChartAggregatedMeter: React.FC<ChartDataProps & { dataMarker: string }> = ({
  dataMarker,
  title = '',
  xAxisTitle = '',
  yAxisTitle = '',
  series,
}) => {
  const { getIntl, dateFormat } = useLocale();
  const baseOptions = getBaseOptions(getIntl, { title, xAxisTitle, yAxisTitle });

  const options = useMemo(
    () => ({
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        type: 'datetime',
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        useHTML: true,
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${dateFormat(moment.unix(Number(this.x) / 1000).toISOString(), 'L LT')}<br>
          ${this.points?.map(point => `${getTooltipCircle(point.series.color as string)}${point.series.name}: <b>${point.y}</b>`).join('<br>')}`;
        },
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
      },
      series: series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [baseOptions, series, getIntl, dateFormat]
  ) as unknown as Highcharts.Options;

  return <Chart options={series ? options : null} dataMarker={dataMarker} />;
};

export default ChartAggregatedMeter;
