export const renderCnaimCorrosion = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        isAsset: legendItem.asset,
        type: 'fill',
        iconClass: 'legend-filter',
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: legendItem.name,
          hasData: true,
          type: 'fill',
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'fill-outline-color': 'rgba(0,0,0,0)',
            'fill-color': [
              'match',
              ['get', legendItem.filters?.property],
              ...(legendItem.filters?.list.map(i => [i.id, i.color]).flat() ?? []),
              // If no type mathed then it will be:
              '#ccc',
            ],
          },
        },
      ],
    },
  };
  return [style];
};
