import React, { useMemo, useState, useRef, useEffect, useContext } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import classNames from 'classnames';
import styled from 'styled-components';
import { Overlay, Popover } from 'react-bootstrap';
import { MapContext } from 'context/map';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip, Button } from 'components/_common';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { getStorageItem, setStorageItem, removeStorageItem } from 'utils';
import { StorageKeys } from 'constants/index';

const DrawAssetsButton: React.FC = () => {
  const { getIntl } = useLocale();
  const { map } = useContext(MapContext);

  const [show, setShow] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [mode, setMode] = useState<'simple_select' | 'draw_point' | 'draw_line_string'>('simple_select');

  // Initialize the Mapbox Draw instance
  const Draw = useMemo(() => new MapboxDraw({ displayControlsDefault: false, controls: {} }), []);

  useEffect(() => {
    try {
      if (Draw.getMode() !== mode) Draw.changeMode(String(mode));
    } catch {}
  }, [mode, Draw]);

  useEffect(() => {
    if (!map) return;
    // Initialize the Mapbox Draw plugin
    map.addControl(Draw);

    // Function to handle draw actions
    const handleDrawEvent = (event: any) => {
      console.log('handleDrawEvent', event);
      if (event.type === 'draw.create') alert('Modal window - work in progress');
      const { features } = Draw.getAll();
      setStorageItem({ [StorageKeys.DRAWN_FEATURES]: features });
      setMode('simple_select');
    };

    // Event listeners for draw actions
    map.on('draw.create', handleDrawEvent);
    map.on('draw.update', handleDrawEvent);
    map.on('draw.delete', handleDrawEvent);

    // Load stored data from localStorage
    const features: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | null = getStorageItem(
      StorageKeys.DRAWN_FEATURES
    );
    if (features) Draw.set({ type: 'FeatureCollection', features });
  }, [map, Draw]);

  return (
    <>
      <OverlayTriggerTooltip
        placement="left"
        container={buttonRef.current}
        overlayId="draw-assets-tooltip"
        overlayChildren={getIntl('Draw assets')}
        overlayStyle={{ display: show ? 'none' : 'block', width: 'max-content' }}
      >
        <Button
          dataMarker="main_map_draw_assets"
          variant="light"
          ref={buttonRef}
          className={classNames('mb-1', { active: show })}
          onClick={() => setShow(prev => !prev)}
          icon={<IconEdit />}
        />
      </OverlayTriggerTooltip>
      <Overlay show={show} placement="left" target={buttonRef.current}>
        <StyledPopover id="draw-assets-popover">
          <Popover.Content data-marker="map_popup_draw_assets">
            <div className="header">{getIntl('Draw assets')}</div>
            <Button
              variant="light"
              className={classNames('mb-1', { active: mode === 'draw_point' })}
              onClick={() => setMode('draw_point')}
              icon={<IconEdit />}
              labelKey="Draw point"
            />
            <Button
              variant="light"
              className={classNames('mb-1 ml-1', { active: mode === 'draw_line_string' })}
              onClick={() => setMode('draw_line_string')}
              icon={<IconEdit />}
              labelKey="Draw line"
            />
            <Button
              variant="light"
              className={classNames('mb-1 ml-1')}
              onClick={() => {
                const data = Draw.getAll();
                if (!data.features.length) return;
                Draw.deleteAll();
                removeStorageItem(StorageKeys.DRAWN_FEATURES);
              }}
              icon={<IconDelete />}
              labelKey="Delete all"
            />
          </Popover.Content>
        </StyledPopover>
      </Overlay>
    </>
  );
};

const StyledPopover = styled(Popover)`
  width: max-content;
  max-width: max-content;
  cursor: default;
  background: rgba(241, 246, 255, 0.8);
  border-radius: 8px;
  box-shadow:
    0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06);

  .popover-body {
    padding: 10px;
    color: var(--map-text-color-secondary);
    font-size: 12px;
    white-space: nowrap;
  }

  .arrow {
    display: none;
  }

  .header {
    text-align: center;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

export default DrawAssetsButton;
