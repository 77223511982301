import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import * as d3 from 'd3';
import { plumberAPI } from 'constants/index';
import RangeSlider from 'components/Map/common/RangeSlider';
import { Spinner } from 'components/_common';
import ColorLegend from './legend/icon • map block • risk • kva rating.svg';
import SizeLegend from './legend/icon • map block • risk • utilization.svg';

interface Props {
  apiUrl?: any;
  assets?: any;
  lng?: any;
  polygonSelected?: any;
}

interface MatrixSettings {
  chart: d3.Selection<d3.BaseType, any, any, any>;
  pointsContainer: d3.Selection<SVGElement, any, any, any>;
  axisLabelsContainer: d3.Selection<SVGElement, any, any, any>;
  gridContainer: d3.Selection<SVGElement, any, any, any>;
  cellsContainer: d3.Selection<SVGElement, any, any, any>;
  labelsContainer: d3.Selection<SVGElement, any, any, any>;
  sumsContainer: d3.Selection<SVGElement, any, any, any>;
  tooltip: d3.Selection<HTMLDivElement, any, any, any>;
  margin: number;
  axisLabelMargin: number;
  chartGridPos: number;
  chartGridWidth: number;
  chartGridHeight: number;
  axisLabelXPos: number;
  axisLabelYPos: number;
  chartGridCountX: number;
  chartGridCountY: number;
  chartGridCellWidth: number;
  chartGridCellHeight: number;
  chartGridCellMargin: number;
  chartPosX: number;
  chartPosY: number;
  chartCellCountX: number;
  chartCellCountY: number;
  chartCellWidth: number;
  chartCellHeight: number;
}

const Matrix: React.FC<Props> = ({ apiUrl, assets, polygonSelected, lng }) => {
  const [data, setData] = useState<any>(null);
  const [maxProp, setMaxProp] = useState(0);
  const [currentProp, setCurrentProp] = useState(0);
  const [settings, setSettings] = useState<MatrixSettings | null>(null);
  const containerRef = useRef(null);
  const settingsRef = useRef(settings);
  const dataRef = useRef(data);
  const width = useMemo(() => 490, []);
  const height = useMemo(() => 430, []);

  const getPropName = useCallback((value: number) => {
    const props = Object.keys(dataRef.current.point_coordinates.points);
    return props[value];
  }, []);

  const getCellTextPosition = useCallback((x: number, y: number) => {
    if (settingsRef.current) {
      const { chartGridPos, chartGridCellWidth, chartGridCellHeight } = settingsRef.current;
      return {
        x: settingsRef.current ? chartGridPos + x * chartGridCellWidth + chartGridCellWidth / 2 : 0,
        y: settingsRef.current ? chartGridPos + y * chartGridCellHeight + chartGridCellHeight / 2 : 0,
      };
    }
    return { x: 0, y: 0 };
  }, []);

  const getPointPosition = useCallback((x: number, y: number) => {
    if (settingsRef.current) {
      const { chartPosX, chartPosY, chartGridCellWidth, chartGridCellHeight } = settingsRef.current;
      const { chartCellCountX, chartCellCountY } = settingsRef.current;
      const chartWidth = chartGridCellWidth * chartCellCountX;
      const chartHeight = chartGridCellHeight * chartCellCountY;
      const chartOffsetX = (chartWidth / 100) * x;
      const chartOffsetY = (chartHeight / 100) * y;
      return {
        x: chartPosX + chartOffsetX,
        y: chartPosY + chartOffsetY,
      };
    }
    return { x: 0, y: 0 };
  }, []);

  const addPointTooltip = useCallback((points: d3.Selection<SVGCircleElement, any, SVGElement, any>) => {
    if (!settingsRef.current) return;
    const { tooltip } = settingsRef.current;
    points
      .on('mouseenter', function (e) {
        d3.select(this).attr('opacity', '0.6');
        tooltip.transition().duration(200).style('opacity', 1);
        const text = `Asset: ${this.dataset.name}, Utilization: ${this.dataset.utilization}`;

        tooltip
          .html(text)
          .style('left', e.pageX + 'px')
          .style('top', e.pageY - 10 + 'px');
      })
      .on('mouseleave', function () {
        d3.select(this).attr('opacity', '1');
        tooltip.transition().duration(500).style('opacity', 0);
      });
  }, []);

  const addChartPoints = useCallback(
    (prop: string) => {
      if (!settingsRef.current) return;
      const { pointsContainer } = settingsRef.current;
      const colorMap = { red: '#B92B27', green: '#8A3D9E', blue: '#1565C0' };
      const radiusScale = d3.scaleThreshold().domain([30, 70]).range([4, 6, 8]);

      pointsContainer.selectAll('.matrix-point-circle').remove();

      const points = pointsContainer
        .selectAll('.matrix-point-circle')
        .data(dataRef.current.point_coordinates.points[prop])
        .enter()
        .append('circle')
        .classed('matrix-point-circle', true)
        .attr('r', (d: any) => radiusScale(d.size))
        .attr('fill', (d: any) => (colorMap as any)[d.colour])
        .attr('cx', (d: any) => getPointPosition(d.x, d.y).x)
        .attr('cy', (d: any) => getPointPosition(d.x, d.y).y)
        .attr('stroke', 'white')
        .attr('stroke-width', 2)
        .attr('data-name', (d: any) => d.label)
        .attr('data-utilization', (d: any) => d.size)
        .style('cursor', 'pointer');

      addPointTooltip(points);
    },
    [addPointTooltip, getPointPosition]
  );

  const addAxisLables = useCallback(() => {
    if (!settingsRef.current) return;
    const { axisLabelsContainer, axisLabelXPos, axisLabelYPos, margin } = settingsRef.current;

    // x axis label
    axisLabelsContainer
      .append('text')
      .classed('matrix-chart-axis-label', true)
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('x', axisLabelXPos)
      .attr('y', margin)
      .text(dataRef.current.point_coordinates.x_label);

    // y axis label
    axisLabelsContainer
      .append('text')
      .classed('matrix-chart-axis-label', true)
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .attr('x', axisLabelYPos)
      .attr('y', margin)
      .text(dataRef.current.point_coordinates.y_label);
  }, []);

  const addChartGrid = useCallback(() => {
    if (!settingsRef.current) return;
    const { gridContainer, chartGridPos, chartGridWidth } = settingsRef.current;
    const { chartGridHeight, chartGridCellWidth, chartGridCellHeight } = settingsRef.current;
    const { chartGridCountX, chartGridCountY } = settingsRef.current;

    // outer border
    gridContainer
      .append('rect')
      .attr('x', chartGridPos)
      .attr('y', chartGridPos)
      .attr('width', chartGridWidth)
      .attr('height', chartGridHeight)
      .attr('rx', 9.5)
      .attr('stroke', '#F1F6FF')
      .attr('fill', 'transparent');

    // x axis lines
    for (const i of [...Array(chartGridCountX - 1).keys()].map(x => x + 1)) {
      gridContainer
        .append('line')
        .attr('x1', chartGridPos)
        .attr('x2', chartGridPos + chartGridWidth)
        .attr('y1', chartGridPos + i * chartGridCellHeight)
        .attr('y2', chartGridPos + i * chartGridCellHeight)
        .attr('stroke', '#F1F6FF');
    }

    // y axis lines
    for (const i of [...Array(chartGridCountY - 1).keys()].map(x => x + 1)) {
      gridContainer
        .append('line')
        .attr('x1', chartGridPos + i * chartGridCellWidth)
        .attr('x2', chartGridPos + i * chartGridCellWidth)
        .attr('y1', chartGridPos)
        .attr('y2', chartGridPos + chartGridHeight)
        .attr('stroke', '#F1F6FF');
    }
  }, []);

  const addChartCells = useCallback(() => {
    if (!settingsRef.current) return;
    const { cellsContainer, chartCellCountX, chartCellCountY } = settingsRef.current;
    const { chartPosX, chartPosY, chartGridCellMargin } = settingsRef.current;
    const { chartGridCellWidth, chartGridCellHeight, chartCellWidth, chartCellHeight } = settingsRef.current;
    const valueRange = [1, chartCellCountX + chartCellCountY];
    const valueDomain = [valueRange[0], d3.mean(valueRange)!, valueRange[1]];
    const colorScale = d3.scaleLinear<string>().domain(valueDomain).range(['#63be7b', '#ffe984', '#f8696b']);

    for (const x of [...Array(chartCellCountX).keys()]) {
      for (const y of [...Array(chartCellCountY).keys()]) {
        const textPosition = getCellTextPosition(x + 1, y + 1);
        cellsContainer
          .append('rect')
          .attr('x', chartPosX + chartGridCellMargin + x * chartGridCellWidth)
          .attr('y', chartPosY + chartGridCellMargin + y * chartGridCellHeight)
          .attr('width', chartCellWidth)
          .attr('height', chartCellHeight)
          .attr('rx', 4.5)
          .attr('stroke', '#FFF')
          .attr('fill', colorScale(x + y + 2));

        cellsContainer
          .append('text')
          .classed('matrix-chart-cell-label', true)
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'middle')
          .attr('x', textPosition.x)
          .attr('y', textPosition.y)
          .text(dataRef.current.point_coordinates.cells[y * chartCellCountX + x].sum);
      }
    }
  }, [getCellTextPosition]);

  const addChartLabels = useCallback(() => {
    if (!settingsRef.current) return;
    const { labelsContainer, chartCellCountX, chartCellCountY } = settingsRef.current;
    const labelsX = [
      dataRef.current.point_coordinates.currency,
      ...[...Array(chartCellCountX).keys()].map(i => 'HI' + i),
      'Σ',
    ];
    const labelsY = [...[...Array(chartCellCountY).keys()].map(i => 'C' + i), 'Σ'];

    for (const [i, label] of labelsX.entries()) {
      const textPosition = getCellTextPosition(i, 0);
      const isCorner = i === 0 || i === labelsX.length - 1;
      labelsContainer
        .append('text')
        .classed(isCorner ? 'matrix-chart-corner-label' : 'matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }

    for (const [i, label] of labelsY.entries()) {
      const textPosition = getCellTextPosition(0, i + 1);
      const isCorner = i === labelsY.length - 1;
      labelsContainer
        .append('text')
        .classed(isCorner ? 'matrix-chart-corner-label' : 'matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }
  }, [getCellTextPosition]);

  const addChartSums = useCallback(() => {
    if (!settingsRef.current) return;
    const { sumsContainer, chartGridCountY, chartGridCountX } = settingsRef.current;
    const groupX = Array.from(d3.group(dataRef.current.point_coordinates.cells, (d: any) => d.x));
    const groupY = Array.from(d3.group(dataRef.current.point_coordinates.cells, (d: any) => d.y));
    const sumX = groupX.map(([_, x]) => d3.sum(x.map((x: any) => x.sum)).toFixed(1));
    const sumY = groupY.map(([_, y]) => d3.sum(y.map((y: any) => y.sum)).toFixed(1));

    for (const [i, label] of sumX.entries()) {
      const textPosition = getCellTextPosition(i + 1, chartGridCountY - 1);
      sumsContainer
        .append('text')
        .classed('matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }

    for (const [i, label] of sumY.entries()) {
      const textPosition = getCellTextPosition(chartGridCountX - 1, i + 1);
      sumsContainer
        .append('text')
        .classed('matrix-chart-axis-label', true)
        .attr('dominant-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('x', textPosition.x)
        .attr('y', textPosition.y)
        .text(label);
    }
  }, [getCellTextPosition]);

  useEffect(() => {
    settingsRef.current = settings;
    dataRef.current = data;
  }, [settings, data]);

  useEffect(() => {
    if (!data) return;
    const props = Object.keys(data.point_coordinates.points);
    setCurrentProp(0);
    setMaxProp(props.length - 1);
  }, [data]);

  useEffect(() => {
    plumberAPI
      .put(apiUrl, {
        aggregation_level: 'asset_class',
        risk_type: 'All',
        y_bins: 6,
        x_bins: 5,
        lang: (lng || 'da').toLowerCase(),
        assets: assets || null,
        polygon_selected: polygonSelected || null,
      })
      .then(action => setData(action.data));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!data) return;

    const chart = d3.select(containerRef.current).select('#MatrixSVG');

    d3.selectAll('.matrix-matrix-tooltip').remove();

    chart.classed('matrix-chart-container', true).selectAll('*').remove();

    const gridContainer = chart.append<SVGElement>('g').classed('matrix-chart-grid', true);
    const cellsContainer = chart.append<SVGElement>('g').classed('matrix-chart-cells', true);
    const axisLabelsContainer = chart.append<SVGElement>('g').classed('matrix-axis-labels', true);
    const labelsContainer = chart.append<SVGElement>('g').classed('matrix-chart-labels', true);
    const sumsContainer = chart.append<SVGElement>('g').classed('matrix-chart-sums', true);
    const pointsContainer = chart.append<SVGElement>('g').classed('matrix-chart-points', true);
    const tooltip = d3.select('body').append('div').classed('matrix-matrix-tooltip', true);

    const margin = 20;
    const axisLabelMargin = 10;

    const chartGridPos = margin + axisLabelMargin + 15;
    const chartGridWidth = width - chartGridPos - margin;
    const chartGridHeight = height - chartGridPos - margin;

    const axisLabelXPos = chartGridWidth / 2 + margin + axisLabelMargin + 15;
    const axisLabelYPos = (chartGridHeight / 2 + margin + axisLabelMargin + 15) * -1;

    const chartGridCountX = 8;
    const chartGridCountY = 8;

    const chartGridCellWidth = chartGridWidth / chartGridCountX;
    const chartGridCellHeight = chartGridHeight / chartGridCountY;
    const chartGridCellMargin = 1;

    const chartPosX = chartGridPos + chartGridCellWidth;
    const chartPosY = chartGridPos + chartGridCellHeight;

    const chartCellCountX = 6;
    const chartCellCountY = 6;

    const chartCellWidth = chartGridCellWidth - chartGridCellMargin * 2;
    const chartCellHeight = chartGridCellHeight - chartGridCellMargin * 2;

    setSettings({
      chart,
      pointsContainer,
      axisLabelsContainer,
      gridContainer,
      cellsContainer,
      labelsContainer,
      sumsContainer,
      tooltip,
      margin,
      axisLabelMargin,
      chartGridPos,
      chartGridWidth,
      chartGridHeight,
      axisLabelXPos,
      axisLabelYPos,
      chartGridCountX,
      chartGridCountY,
      chartGridCellWidth,
      chartGridCellHeight,
      chartGridCellMargin,
      chartPosX,
      chartPosY,
      chartCellCountX,
      chartCellCountY,
      chartCellWidth,
      chartCellHeight,
    });
  }, [data, height, width]);

  useEffect(() => {
    if (!settings) return;
    addAxisLables();
    addChartGrid();
    addChartCells();
    addChartLabels();
    addChartSums();
  }, [settings, addAxisLables, addChartGrid, addChartCells, addChartLabels, addChartSums]);

  useEffect(() => {
    if (!settings || !maxProp) return;
    addChartPoints(getPropName(currentProp));
  }, [settings, currentProp, maxProp, addChartPoints, getPropName]);

  return (
    <StyledContainer ref={containerRef}>
      <ToolTipStyles />
      <StyledPanel>
        <svg id="MatrixSVG" width={width} height={height}></svg>
        {!data && (
          <StyledLoadingWrapper>
            <Spinner />
          </StyledLoadingWrapper>
        )}
      </StyledPanel>
      <StyledPanel>
        {data && maxProp ? (
          <>
            <RangeSlider
              initValue={currentProp}
              min={0}
              max={maxProp}
              onChange={value => setCurrentProp(+value)}
              tooltipLabel={value => getPropName(value)}
              className="w-100 mt-4 pt-2"
            />
            <div className="matrix-color-legend">
              <img src={ColorLegend} alt="" draggable={false} />
            </div>
            <div className="matrix-size-legend">
              <img src={SizeLegend} alt="" draggable={false} />
            </div>
          </>
        ) : (
          <StyledLoadingWrapper>
            <Spinner />
          </StyledLoadingWrapper>
        )}
      </StyledPanel>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  overflow: hidden;

  .matrix-chart-container {
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    user-select: none;
  }

  .matrix-chart-axis-label {
    font-weight: 500;
    fill: #353a3f;
  }

  .matrix-chart-corner-label {
    font-weight: 500;
    fill: #909090;
  }

  .matrix-chart-cell-label {
    fill: #ffffff;
    font-weight: 600;
  }

  .matrix-color-legend,
  .matrix-size-legend {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .matrix-color-legend {
    margin-top: 20px;
  }

  .matrix-size-legend img {
    display: inline-block;
    margin: 0 auto;
  }
`;

const StyledPanel = styled.div`
  padding: 10px;
  background: #ffffff;
  border: 1px solid #f6f8fb;
  box-shadow: 0px 2px 2px rgba(0, 123, 254, 0.1);
  border-radius: 10px;
  position: relative;

  &:last-child {
    flex-grow: 1;
    margin-left: 20px;
    padding: 20px;
  }
`;

const StyledLoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ToolTipStyles = createGlobalStyle`
  .matrix-matrix-tooltip {
    fill: #333;
    position: absolute;
    padding: 5px 10px;
    font-size: 13px;
    background: white;
    border-radius: 8px;
    pointer-events: none;
    z-index: 99999;
    transform: translate(-50%, -100%);
    opacity: 0;
    box-shadow: 0px 6px 10px 2px rgb(50 50 71 / 8%), 0px 4px 8px rgb(50 50 71 / 6%);
  }
`;

export default Matrix;
