import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { ignoreMessagesSelector, ignoreMessagesCountSelector } from 'modules/networkLoading/selectors';
import { fetchAssetCommentsAction } from 'modules/networkLoading';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const NetworkLoadingIgnoreMessagesTable: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(ignoreMessagesSelector);
  const count = useSelector(ignoreMessagesCountSelector);

  const sendRequest = useCallback(() => dispatch(fetchAssetCommentsAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.TRANSFORMERS_IGNORE_MESSAGES}
      totalAmount={count}
      sendRequest={sendRequest}
    >
      {items?.map((item: NetworkLoading.IgnoreMessages) => (
        <tr key={item.id}>
          <td>{dateFormat(item.time)}</td>
          <td>{item.username}</td>
          <td>{item.explanation}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default NetworkLoadingIgnoreMessagesTable;
