import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createComponentPriceScenarioAction, updateComponentPriceScenarioAction } from 'modules/setup';

const useConfiguredFormik = ({
  id,
  isDuplicate,
  toggleModal,
}: {
  id: number | null;
  isDuplicate?: boolean;
  toggleModal: () => void;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Setup.ComponentPriceScenarioActionProps>({
    initialValues: {
      description: '',
      price_evolution: null,
    },

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Field can't be empty!"),
      price_evolution: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const action = id && !isDuplicate ? updateComponentPriceScenarioAction : createComponentPriceScenarioAction;
      return dispatch(action({ component_price_scenario_id: id || undefined, ...values }))
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
