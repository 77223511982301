import { useFormik } from 'formik';
import * as Yup from 'yup';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { FIELDS, BuilderFieldsTypes } from 'constants/index';

interface Values {
  label: string;
  placeholder: string;
  commentsAllowed: boolean;
}

const useConfiguredFormik = ({
  type,
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[0]?.values?.[0]?.value || ''),
      placeholder: String(formField?.params?.[1]?.values?.[0]?.value || ''),
      commentsAllowed: Boolean(formField?.params?.[2]?.values?.[0]?.value as unknown as boolean),
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('TextInputModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[type!]); // TextInput, LargeTextInput, LargeText
      _set(field, 'params[0].values[0].value', values.label);
      if (type !== BuilderFieldsTypes.LargeText) {
        _set(field, 'params[1].values[0].value', values.placeholder);
        // Backward compatibility for earlier created forms
        if (!field.params[2]) field.params[2] = FIELDS[type!].params[2];
        _set(field, 'params[2].values[0].value', values.commentsAllowed);
      }
      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
