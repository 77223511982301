import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { getDERProfileChartData } from 'modules/newLoad';
import Chart from 'components/_charts/Chart';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  series: Shared.SeriesOptionsType[];
}

interface DataItem {
  timestamp: number;
  power: number;
}

interface Response {
  title: string;
  ts_data: DataItem[];
  xlabel: string;
  ylabel: string;
}

const transformChartData = (
  action: Shared.ReduxAction<Response>,
  getIntl: (localeKey: string) => string
): ChartDataProps | null => {
  if (!Object.keys(action.payload || {}).length) return null;

  const data = action.payload.ts_data?.map((item: DataItem) => [item.timestamp * 1000, Number(item.power)]);
  return {
    title: getIntl(action.payload.title),
    xAxisTitle: getIntl(action.payload.xlabel),
    yAxisTitle: getIntl(action.payload.ylabel),
    series: [{ name: getIntl('Power'), type: 'line', data }],
  };
};

const ChartDERProfile: React.FC<{ id: number }> = ({ id }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  useEffect(() => {
    setChartData(null);
    if (!id) return;
    dispatch(getDERProfileChartData({ id }))
      .then(action => transformChartData(action, getIntl))
      .then(setChartData)
      .catch(console.error);
  }, [dispatch, id, getIntl]);

  const options = useMemo(
    () => ({
      chart: {
        zoomType: 'x',
      },
      title: {
        text: chartData?.title,
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: chartData?.xAxisTitle,
        },
      },
      yAxis: {
        title: {
          text: chartData?.yAxisTitle,
        },
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${getIntl('Year')}: ${dateFormat(this.x as string, 'L LT')}<br/>${getIntl('Power')}: ${this.y}`;
        },
        shared: true,
      },
      series: chartData?.series || [],
      legend: {
        enabled: false,
      },
    }),
    [chartData, getIntl, dateFormat]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_der_profile" />;
};

export default ChartDERProfile;
