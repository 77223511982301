import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { manufacturersCountSelector, manufacturersItemsSelector } from 'modules/manufacturers/selectors';
import { fetchManufacturersAction, deleteManufacturerAction } from 'modules/manufacturers';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconDelete } from '@utiligize/shared/resources';

const ManufacturersTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(manufacturersCountSelector);
  const items = useSelector(manufacturersItemsSelector);

  const sendRequest = useCallback(() => dispatch(fetchManufacturersAction()), [dispatch]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteManufacturerAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.MANUFACTURERS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 234px)"
    >
      {items?.map((item: Manufacturers.Item) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td className="text-right">
            <Button
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default ManufacturersTable;
