import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchManufacturersAction: any = createAction(
  'manufacturers/FETCH_MANUFACTURERS',
  async ({ skipPagination } = { skipPagination: false }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Manufacturers.Root, 'count' | 'items'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.MANUFACTURERS)(state);
      return axios
        .get('/api/admin/v1/secure/manufacturers', {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then(res => ({ count: res.data.count, items: res.data.manufacturers }));
    }
);

export const createManufacturerAction: any = createAction(
  'manufacturers/POST_MANUFACTURER',
  async (name: string) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios
        .post('api/admin/v1/secure/assetmanufacturers', { name })
        .then(() => dispatch(fetchManufacturersAction()));
    }
);

export const deleteManufacturerAction: any = createAction('manufacturers/DELETE_MANUFACTURER', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.delete(`api/admin/v1/secure/assetmanufacturers/${id}`).then(async () => {
      await dispatch(fetchManufacturersAction());
      dispatch(setSuccessToastAction('Manufacturer has been deleted'));
    });
});
