import { handleActions } from 'redux-actions';
import { fetchManufacturersAction, createManufacturerAction, deleteManufacturerAction } from './actions';

const initialState: Manufacturers.Root = {
  loading: false,
  isFetched: false,
  count: 0,
  items: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchManufacturersAction]: {
    next: (
      state: Manufacturers.Root,
      action: Shared.ReduxAction<Pick<Manufacturers.Root, 'count' | 'items'>>
    ): Manufacturers.Root => ({
      ...state,
      ...action.payload,
      loading: false,
      isFetched: true,
    }),
    throw: (state: Manufacturers.Root): Manufacturers.Root => ({
      ...state,
      loading: false,
      isFetched: false,
    }),
  },
};

export { fetchManufacturersAction, createManufacturerAction, deleteManufacturerAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
