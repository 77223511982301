import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchTaskExpectedTimeAction } from 'modules/tasks';
import { FormInput, FormInputProps } from 'components/_common';

interface Props extends FormInputProps {
  useDynamicCalculation: boolean;
  assetCategoryCode: Type.AssetCategories | null;
  appAction: Type.TaskAppActions;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<any>;
}

const ExpectedTimeFormInput: React.FC<Props> = ({
  assetCategoryCode,
  appAction,
  useDynamicCalculation = false,
  setFieldValue,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  useEffect(() => {
    if (!useDynamicCalculation || !assetCategoryCode || !appAction) return;
    dispatch(fetchTaskExpectedTimeAction(assetCategoryCode, appAction)).then((action: Shared.ReduxAction<number>) => {
      setFieldValue(props.name as string, action.payload);
    });
  }, [props.name, useDynamicCalculation, assetCategoryCode, appAction, setFieldValue, dispatch]);

  return (
    <>
      <FormInput min={0} type="number" labelKey="Expected time (hours)" {...props} disabled={useDynamicCalculation} />
      <Form.Text className="text-muted" hidden={!useDynamicCalculation || !!props.errorKey}>
        {getIntl('Calculated automatically')}
      </Form.Text>
    </>
  );
};

export default ExpectedTimeFormInput;
