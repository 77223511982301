import React, { useCallback, useState } from 'react';
import { generatePath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { createAssetCategoryAction } from 'modules/assets';
import { isPageAssetsCategoriesCNAIMSelector } from 'modules/router/selectors';
import { AssetsCNAIMModal } from 'components/_modals';
import { CategoriesTable, CategoriesCNAIMTable } from 'components/_tables';
import { DataTableTemplate, FormInput, SelectAssetTemplate, Tabs, Tab, Button } from 'components/_common';
import { Routes, isProduction } from 'constants/index';

const AssetsCategories: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [state, setState] = useState({ name: '', code: '' });
  const isPageAssetsCategoriesCNAIM = useSelector(isPageAssetsCategoriesCNAIMSelector);

  const handleInputChange = useCallback((event: React.SyntheticEvent): void => {
    const { name, value } = event.target as HTMLInputElement;
    setState(state => ({ ...state, [name]: value }));
  }, []);

  const handleCreateButtonClick = useCallback((): void => {
    dispatch(createAssetCategoryAction(state)).then((): void => setState({ name: '', code: '' }));
  }, [state, dispatch]);

  const renderHeaderControls = () => {
    if (isProduction && !isPageAssetsCategoriesCNAIM) return <React.Fragment />;
    if (!isPageAssetsCategoriesCNAIM) {
      return (
        <Form>
          <Form.Row>
            <Col sm={3}>
              <FormInput
                name="name"
                type="text"
                placeholderKey="Type category name"
                value={state.name}
                onChange={handleInputChange}
                disabled
              />
            </Col>
            <Col sm={3}>
              <FormInput
                name="code"
                type="text"
                placeholderKey="Type code"
                value={state.code}
                onChange={handleInputChange}
                disabled
              />
            </Col>
            <Col sm={3}>
              <Button
                disabled={!state.name || !state.code}
                labelKey="Add"
                onClick={handleCreateButtonClick}
                variant="primary"
                size="large"
              />
            </Col>
          </Form.Row>
        </Form>
      );
    }
    return (
      <Row>
        <Col sm={2}>
          <SelectAssetTemplate />
        </Col>
        <AssetsCNAIMModal />
      </Row>
    );
  };

  return (
    <DataTableTemplate>
      {renderHeaderControls()}
      <Tabs mountOnEnter unmountOnExit>
        <Tab eventKey={Routes.AssetsCategories} title={getIntl('Categories')}>
          <CategoriesTable />
        </Tab>
        <Tab eventKey={generatePath(Routes.AssetsCategoriesCNAIM)} title={getIntl('Categories CNAIM')}>
          <CategoriesCNAIMTable />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default AssetsCategories;
