import React, { useMemo, useCallback } from 'react';
import { useUsers } from 'hooks';
import { FormReactSelect } from 'components/_common';

interface Props {
  disabled?: boolean;
  onlyIncludedInTask?: boolean;
  isMulti?: true | undefined;
  isClearable?: true | undefined;
  labelKey?: string;
  errorKey?: string;
  userIds: number[];
  setUserIds: (value: number[]) => void;
  onBlur?: () => void;
}

const SelectUser: React.FC<Props> = ({
  disabled = false,
  onlyIncludedInTask = false,
  isMulti,
  labelKey = 'Users',
  userIds = [],
  setUserIds,
  ...props
}) => {
  const { isLoading, users, isFetched } = useUsers();

  const { options, value } = useMemo(
    () =>
      users.reduce(
        (acc: any, user: Users.User) => {
          if (onlyIncludedInTask && !user.includedInTask) return acc;
          const value = user.id;
          const item = { value, label: `${user.fullName} - ${user.email}` };
          if (userIds.includes(value as any)) acc.value.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], value: [] }
      ),
    [userIds, users, onlyIncludedInTask]
  );

  const handleSelectChange = useCallback(
    (users: Type.SelectOption[]) => {
      setUserIds(isMulti ? users?.map(i => i.value) || [] : [(users as any)?.value]);
    },
    [isMulti, setUserIds]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      isDisabled={!isFetched || disabled}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={handleSelectChange}
      isMulti={isMulti}
      isLoading={isLoading || !isFetched}
      placeholderKey="Select user"
    />
  );
};

export default SelectUser;
