import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLabelsSelector } from 'modules/map/selectors';
import { setLabelsAction } from 'modules/map';
import { Button } from 'components/_common';
import { IconType } from '@utiligize/shared/resources';

const LabelsButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const showLabels = useSelector(showLabelsSelector);

  const toggleLabels = useCallback(() => {
    dispatch(setLabelsAction(!showLabels));
  }, [dispatch, showLabels]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: showLabels })}
      onClick={toggleLabels}
      icon={<IconType />}
      tooltipKey={showLabels ? 'Hide labels' : 'Show labels'}
      tooltipPlacement="left"
    />
  );
};

export default LabelsButton;
