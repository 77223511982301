import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useBuilderState } from 'hooks';
import { useLocale } from 'hooks';
import { setInfoToastAction } from 'modules/layouts';
import { Button } from 'components/_common';
import { BuilderFieldsTypesValues, BuilderFormTypeNames } from 'constants/index';

interface Props {
  setButtonFieldType: (fieldType: Builder.FieldsTypes | null) => void;
}

const BuilderTypesMenu: React.FC<Props> = ({ setButtonFieldType }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const {
    selectValue,
    selectValue: { formtype },
    blocks,
    selectedBlock,
  } = useBuilderState();

  const checkCountFieldsInToBlocks = useCallback(() => {
    // If form type equal to Inspection, one field per one page
    return formtype.name === BuilderFormTypeNames.Inspection && blocks[selectedBlock as number]?.fields.length;
  }, [blocks, formtype.name, selectedBlock]);

  const isFieldTypeIsInBlock = useCallback(
    (fieldType: Builder.FieldsTypes) =>
      [BuilderFormTypeNames.ToolInspection].includes(formtype.name) &&
      blocks[selectedBlock as number].fields.some((field: Builder.Field) => field.type === fieldType),
    [blocks, formtype.name, selectedBlock]
  );

  const handleTypesMenuItemClick = useCallback(
    (event: React.SyntheticEvent) => {
      if (selectedBlock === null) {
        return dispatch(setInfoToastAction('Please create and/or select block first'));
      }

      if (checkCountFieldsInToBlocks()) {
        return dispatch(setInfoToastAction('Inspection form type allows only one field per page'));
      }

      const fieldType = event.currentTarget.getAttribute('data-name') as Builder.FieldsTypes;

      if (!fieldType || isFieldTypeIsInBlock(fieldType)) {
        return dispatch(setInfoToastAction('Current field already added to selected page'));
      }

      setButtonFieldType(fieldType);
    },
    [selectedBlock, checkCountFieldsInToBlocks, isFieldTypeIsInBlock, setButtonFieldType, dispatch]
  );

  if (!selectValue) {
    console.error("FormTypesMenu component, selectValue doesn't exist");
    return null;
  }

  return (
    <div className="px-2">
      {selectValue.formtype.fgtns?.map((itemFGT: Builder.FGTN) => (
        <div key={itemFGT.fgt.id} className="d-flex flex-column">
          <p className="text-muted mt-3 mb-2">{getIntl(itemFGT.fgt.name_d)}</p>
          {itemFGT.fgt.ftns.map((item: any) => (
            <Button
              key={item.fieldtype.id}
              data-name={item.fieldtype.name}
              className="mb-2 w-100"
              labelKey={item.fieldtype.name}
              onClick={handleTypesMenuItemClick}
              disabled={!BuilderFieldsTypesValues.includes(item.fieldtype.name)}
              size="large"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default BuilderTypesMenu;
