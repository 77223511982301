import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { plumberAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchBudgetingParametersAction: any = createAction(
  'budgeting/FETCH_PARAMETERS',
  async ({ skipStoreUpdate } = { skipStoreUpdate: undefined }) =>
    (): Promise<Budgeting.Root> => {
      return plumberAPI.get('revenue_cap_parameters').then((res: any) => ({
        budgetingParametersCount: res.data.length,
        budgetingParameters: res.data,
        skipStoreUpdate,
      }));
    }
);

export const updateBudgetingParametersItemAction: any = createAction(
  'budgeting/UPDATE_PARAMETER',
  async (id: number, modifier: Omit<Budgeting.Parameter, 'id' | 'year'>) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return plumberAPI.put(`revenue_cap_parameters/${id}`, modifier).then(async () => {
        await dispatch(fetchBudgetingParametersAction());
        dispatch(setSuccessToastAction('Budgeting parameter has been saved'));
      });
    }
);

export const recalculateBudgetingAction: any = createAction(
  'budgeting/RECALCULATE',
  async () => (): Promise<void> => plumberAPI.put('revenue_cap_recalculate')
);

export const resetBudgetingAction: any = createAction(
  'budgeting/RESET',
  async () => (): Promise<void> => plumberAPI.put('revenue_cap_reset')
);
