import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { createEmployeeAction, updateEmployeeAction } from 'modules/employees';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { DateFormats, PaginationType } from 'constants/index';

interface Values {
  ssoUserId: number | null;
  startDate: Type.Moment | null;
  endDate: Type.Moment | null;
  hours: number;
}

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.EMPLOYEES));
  const firstDayInAYear = new Date(filters!.year!, 0, 1);

  const formik = useFormik<Values>({
    initialValues: {
      ssoUserId: null,
      startDate: moment(firstDayInAYear),
      endDate: moment(firstDayInAYear).endOf('year'),
      hours: 1450,
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      ssoUserId: Yup.number().nullable().required("Field can't be empty!"),
      startDate: Yup.object()
        .nullable()
        .required("Field can't be empty!")
        .when('endDate', (endDate: Type.Moment | null, schema: any) =>
          schema.test({
            test: (startDate: Type.Moment | null) => {
              return !startDate || moment(startDate).isSame(endDate, 'year');
            },
            message: 'The start and end dates must be in the same year',
          })
        ),

      hours: Yup.number().min(1, 'Must be greater than zero').required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const action = id ? updateEmployeeAction : createEmployeeAction;
      return dispatch(
        action({
          id: id || values.ssoUserId,
          startDate: values.startDate!.format(DateFormats.SERVER),
          endDate: values.endDate!.format(DateFormats.SERVER),
          minutes: values.hours * 60,
        })
      )
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
