import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTransition, animated } from 'react-spring';
import { Toast as BootstrapToast } from 'react-bootstrap';

const ToastClassNamesMap = {
  error: 'bg-danger',
  success: 'bg-success',
  info: 'bg-info',
};

const ToastHeadersMap = {
  error: 'Error',
  success: 'Success',
  info: 'Info',
};

const ToastIconsMap = {
  error: 'fas fa-exclamation-triangle',
  success: 'fas fa-check-circle',
  info: 'fas fa-info-circle',
};

interface Props {
  getIntl?: (localeKey: string, options?: {}) => string;
  toasts: Shared.Toast[];
  deleteToastAction: (id: number) => Shared.ReduxAction<void>;
}

const Toasts: React.FC<Props> = ({ getIntl = str => str, toasts, deleteToastAction }) => {
  const transitions = useTransition(toasts, item => item.id, {
    from: { transform: 'translate(360px, 0)', opacity: 1 },
    enter: { transform: 'translate(0px, 0)' },
    leave: { opacity: 0 },
  });

  return (
    <div style={{ position: 'fixed', bottom: 5, right: 5, zIndex: 10001, overflow: 'hidden' }}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Toast {...item} getIntl={getIntl} deleteToastAction={deleteToastAction} />
        </animated.div>
      ))}
    </div>
  );
};

type ToastProps = Shared.Toast & Required<Pick<Props, 'getIntl' | 'deleteToastAction'>>;

const Toast: React.FC<ToastProps> = memo(({ id, type, message, getIntl, deleteToastAction }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(deleteToastAction(id)), 4000);
  }, [id, message, dispatch]);

  return (
    <BootstrapToast className={ToastClassNamesMap[type]} style={{ minWidth: 350, marginBottom: 10 }}>
      <BootstrapToast.Header closeButton={false}>
        <i className={ToastIconsMap[type]} />
        <strong className="ml-2">{getIntl(ToastHeadersMap[type])}</strong>
      </BootstrapToast.Header>
      <BootstrapToast.Body>{getIntl(message)}</BootstrapToast.Body>
    </BootstrapToast>
  );
});

export default Toasts;
