import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MapContext } from 'context/map';
import { cnaimLegendSelector } from 'modules/map/selectors';
import GroupAssetList from 'components/Map/Legend/GroupAssetList';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { ReactComponent as GroupIcon } from 'components/Map/icons/icon • map panel section • added objects.svg';

const AuxiliaryLayersCNAIM: React.FC = () => {
  const { map } = useContext(MapContext);
  const { legend, disabled, checkedFilters, mapLayers } = useSelector(cnaimLegendSelector);

  return (
    <CollapseGroup
      id="cnaim"
      titleKey={legend.title}
      dataMarker="panel_cnaim"
      icon={<GroupIcon className="filter-group-icon" />}
      disabled={disabled}
      description={
        <GroupHiddenDescription values={(checkedFilters ?? []) as string[]} defaultValue="Nothing selected" />
      }
    >
      {Boolean(mapLayers && map) && <GroupAssetList map={map!} layers={mapLayers!} item={legend} />}
    </CollapseGroup>
  );
};

export default AuxiliaryLayersCNAIM;
