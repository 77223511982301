import styled from 'styled-components';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { tasksRegisteredHoursSelector, tasksRegisteredHoursCountSelector } from 'modules/tasks/selectors';
import { fetchTasksRegisteredHoursAction } from 'modules/tasks';
import { DataTable, Button, DataTableProps, OverlayTriggerTooltip } from 'components/_common';
import { TableHeaders, PaginationType, TasksRegisteredHoursSourceTypes, Routes } from 'constants/index';
import { IconDetails, IconAlertTriangle, IconFilter } from '@utiligize/shared/resources';

interface Props {
  source: Tasks.RegisteredHoursSourceTypes;
  handleDetailsButtonClick: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const TasksRegisteredHoursTable: React.FC<Props> = ({ source, handleDetailsButtonClick }) => {
  const { lng, getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isExternalSource = source === TasksRegisteredHoursSourceTypes.EXTERNAL;

  const items = useSelector(tasksRegisteredHoursSelector);
  const count = useSelector(tasksRegisteredHoursCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTasksRegisteredHoursAction(params)).then(
        (action: Shared.ReduxAction<{ tasksRegisteredHoursHash: Type.Hash<Tasks.TaskRegisteredHours> }>) =>
          Object.values(action.payload?.tasksRegisteredHoursHash || {})
      ),
    [dispatch]
  );

  const customHeaders = useMemo(
    () => [
      isExternalSource
        ? { titleKey: 'Case number (Σ)', sortKey: 'caseNumber' }
        : TableHeaders[PaginationType.TASKS_REGISTERED_HOURS][0],
      ...(items?.[0]?.days.map(i => ({
        title: moment(i.date, 'YYYY-MM-DD').format('D ddd'),
        className: 'text-center',
      })) || []),
      TableHeaders[PaginationType.TASKS_REGISTERED_HOURS][1],
    ],
    [items, isExternalSource, lng] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <StyledDataTable
      paginationType={PaginationType.TASKS_REGISTERED_HOURS}
      totalAmount={count}
      sendRequest={sendRequest}
      customHeaders={customHeaders}
      isDownloadCSVEnabled
      isResetFiltersButtonEnabled
    >
      {items?.map((item: Tasks.TaskRegisteredHours) => {
        const id = item.id || item.caseNumber;
        return (
          <tr key={id}>
            <td>
              <StyledDiv>
                {item.isDeleted && (
                  <OverlayTriggerTooltip
                    trigger={['hover', 'focus']}
                    overlayId={String(item.id)}
                    overlayChildren={getIntl("This task doesn't exist")}
                  >
                    <IconAlertTriangle />
                  </OverlayTriggerTooltip>
                )}
                {item.name || id} <span className="ml-auto">({numberFormat(item.registeredTime)})</span>
              </StyledDiv>
            </td>
            {item.days.map((day: Tasks.RegisteredHoursDay) => (
              <td key={day.date} className="text-center">
                {numberFormat(day.registeredTime)}
              </td>
            ))}
            <td className="text-right">
              <Button
                tooltipKey="Filter users by task"
                icon={<IconFilter />}
                data-id={id}
                onClick={handleDetailsButtonClick}
                size="small"
              />
              {!isExternalSource && !item.isDeleted && (
                <Button
                  marginLeft
                  tooltipKey="Details"
                  icon={<IconDetails />}
                  data-id={item.id}
                  linkProps={{ to: generatePath(Routes.Tasks, { id: item.id }) }}
                  size="small"
                  variant="primary"
                />
              )}
            </td>
          </tr>
        );
      })}
    </StyledDataTable>
  );
};

const StyledDataTable = styled((props: DataTableProps) => (
  <DataTable {...props} containerClassName={props.className} />
))`
  table {
    border-left: none;
    border-right: none;
  }

  thead th:first-child,
  tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    border-left: 1px solid #dee2e6;
    width: 280px;
    box-sizing: border-box;
  }

  thead th:nth-last-child(2),
  tbody td:nth-last-child(2) {
    border-right: none;
  }

  thead th:last-child,
  tbody td:last-child {
    position: sticky;
    right: 0;
    z-index: 1;
    background-color: white;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  > svg {
    width: 19px;
    height: 19px;
    fill: red;
    margin-right: 7px;
  }
`;

export default TasksRegisteredHoursTable;
