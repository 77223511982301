import React from 'react';
import TableScenarioCalculation from './TableScenarioCalculation';

const ViewCalculationSchedule: React.FC = () => (
  <>
    <TableScenarioCalculation />
  </>
);

export default ViewCalculationSchedule;
