import React from 'react';
import FilterSimulationVersion from './FilterSimulationVersion';
import GroupContainer from './GroupContainer';
import GroupTitle from './GroupTitle';
import GroupSection from './GroupSection';

const GroupDataVersion: React.FC = () => (
  <GroupContainer>
    <GroupTitle titleKey="Data version" />
    <GroupSection>
      <FilterSimulationVersion />
    </GroupSection>
  </GroupContainer>
);

export default GroupDataVersion;
