import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { getDERPhaseInChartData } from 'modules/newLoad';
import Chart from 'components/_charts/Chart';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  series: Shared.SeriesOptionsType[];
}

interface DataItem {
  year: number;
  n_ders: number;
}

interface Response {
  title: string;
  ts_data: DataItem[];
  xlabel: string;
  ylabel: string;
}

const transformChartData = (
  action: Shared.ReduxAction<Response>,
  getIntl: (localeKey: string) => string
): ChartDataProps | null => {
  if (!Object.keys(action.payload || {}).length) return null;

  const data = action.payload.ts_data?.map((item: DataItem) => [item.year, item.n_ders]);
  return {
    title: getIntl(action.payload.title),
    xAxisTitle: getIntl(action.payload.xlabel),
    yAxisTitle: getIntl(action.payload.ylabel),
    series: [{ name: getIntl('Number of DERs'), type: 'line', data }],
  };
};

const ChartDERPhaseIn: React.FC<{ id: number }> = ({ id }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);

  useEffect(() => {
    setChartData(null);
    if (!id) return;
    dispatch(getDERPhaseInChartData({ id }))
      .then(action => transformChartData(action, getIntl))
      .then(setChartData)
      .catch(console.error);
  }, [dispatch, id, getIntl]);

  const options = useMemo(
    () => ({
      chart: {
        zoomType: 'x',
      },
      title: {
        text: chartData?.title,
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
      },
      xAxis: {
        title: {
          text: chartData?.xAxisTitle,
        },
      },
      yAxis: {
        title: {
          text: chartData?.yAxisTitle,
        },
      },
      tooltip: {
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${getIntl('Year')}: ${this.x}<br/>${getIntl('Number of DERs')}: ${this.y}`;
        },
        shared: true,
      },
      series: chartData?.series || [],
      legend: {
        enabled: false,
      },
    }),
    [chartData, getIntl]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_der_phase_in" />;
};

export default ChartDERPhaseIn;
