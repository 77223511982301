import React from 'react';
import { useSelector } from 'react-redux';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { DataTableTemplate } from 'components/_common';
import TabsCables from './TabsCables';
import TableFilters from './TableFilters';
import { PaginationType, Routes } from 'constants/index';

const ViewCables: React.FC = () => {
  const isScenariosTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NetworkLoadingCables));
  const isForecastTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.Forecast}`)
  );
  const isHistoricalLoadingDistributionTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.HistoricalLoadingDistribution}`)
  );
  const isVoltageTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.Voltage}`)
  );
  const isFutureMeanTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.FutureMean}`)
  );
  const isFutureOverloadedTabActive = useSelector(
    isRouteActiveSelectorFactory(`${Routes.NetworkLoadingCables}${Routes.HASH.FutureOverloaded}`)
  );
  const type = isForecastTabActive ? PaginationType.NETWORK_LOADING_CABLES_FORECAST : PaginationType.CABLES;

  return (
    <DataTableTemplate>
      <TableFilters
        type={type}
        hideScenariosYear={isFutureMeanTabActive || isFutureOverloadedTabActive}
        showFilterSolved={isScenariosTabActive || isForecastTabActive}
        showVoltageDisplay={isScenariosTabActive}
        showPercentile={isScenariosTabActive || isHistoricalLoadingDistributionTabActive || isVoltageTabActive}
        showFlex={!isFutureMeanTabActive && !isFutureOverloadedTabActive}
      />
      <TabsCables type={type} />
    </DataTableTemplate>
  );
};

export default ViewCables;
