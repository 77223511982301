import { paintLayer, layoutLayer, getLayer, getAssetLayerFilters, filterLayer } from 'utils/map';

export const filterDataQuality = (
  map: Map.MapboxMap,
  dataQualityFilters: Map.LayerFilter,
  warning: Map.DataQualityWarning | null,
  settings: Map.MapSettings
) => {
  const f = dataQualityFilters;
  const layers = settings.dataQualityLayers?.filter(i => !/highlight$/.test(i));
  const primaryLayers = layers?.filter(i => !i.includes('additional'));
  const secondaryLayers = layers?.filter(i => i.includes('additional'));

  const themeFilter = ['==', ['get', 'in_theme'], true];
  const filter =
    f.initList.length === f.list.length ? true : f.list.length === 0 ? false : ['==', ['get', f.property], f.list[0]];
  const isWarningActive = !!warning;

  const getWarningFilter = (layer: string) => {
    const hasIds = Boolean(warning?.ids);
    const ids = (warning?.ids ?? warning?.layers)?.[layer.replace(/_voltage.*/gi, '')] ?? [];
    return isWarningActive
      ? ids.length > 0
        ? ['any', ...ids.map(i => ['==', hasIds ? ['id'] : ['get', 'name'], i])]
        : false
      : true;
  };

  primaryLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const warningFilter = getWarningFilter(i);
    const primaryFilter = ['all', themeFilter, filter, warningFilter];
    filterLayer(map, i, ['all', voltageFilter, primaryFilter, assetFilter, textFilter]);
  });

  secondaryLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const warningFilter = getWarningFilter(i);
    const secondaryFilter = ['!', ['all', themeFilter, filter, warningFilter]];
    filterLayer(map, i, ['all', voltageFilter, secondaryFilter, assetFilter, textFilter]);
  });

  primaryLayers?.forEach(i => {
    const layer = getLayer(i);
    const isBackground = i.endsWith('background');
    const paintObject = (layer?.style?.paint ?? {}) as Record<string, any>;
    const layoutObject = (layer?.style?.layout ?? {}) as Record<string, any>;
    const paintObjectKeys = Object.keys(paintObject);
    const layoutObjectKeys = Object.keys(layoutObject);
    // color key check
    const colorKey = paintObjectKeys.find(k => /(line-color|circle-color)/.test(k));
    const imageKey = layoutObjectKeys.find(k => k.includes('icon-image'));

    if (colorKey && !isBackground) {
      paintLayer(map, i, colorKey, [
        'case',
        ['to-boolean', ['feature-state', 'hover']],
        '#7345e0',
        ['all', ['has', f.property], ['==', ['get', f.property], f.initList[0].id]],
        '#007bfe',
        ['all', ['has', f.property], ['==', ['get', f.property], f.initList[1].id]],
        '#ffc107',
        '#918f86',
      ]);
    }

    if (imageKey) {
      const imageStyle = layoutObject[imageKey] as string[];
      if (Array.isArray(imageStyle)) {
        const imageName = imageStyle[imageStyle.length - 1].split('-')[1];
        layoutLayer(map, i, imageKey, [
          'case',
          ['all', ['has', f.property], ['==', ['get', f.property], f.initList[0].id]],
          `icon-${imageName}-blue`,
          ['all', ['has', f.property], ['==', ['get', f.property], f.initList[1].id]],
          `icon-${imageName}-yellow`,
          `icon-${imageName}-gray`,
        ]);
      }
    }
  });
};
