import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { reliabilityDistributionOptionSelector } from 'modules/layouts/selectors';
import { setReliabilityDistributionOption } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectDistribution: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reliabilityDistributionOption = useSelector(reliabilityDistributionOptionSelector);

  const options = useMemo(
    () =>
      ['Weibull', 'Normal', 'Log normal', 'Exponential', 'Gamma', 'Logistic', 'Log logistic', 'Gumbel'].map(i => ({
        value: i,
        label: getIntl(i),
      })),
    [getIntl]
  );

  useEffect(() => {
    if (!reliabilityDistributionOption) dispatch(setReliabilityDistributionOption(options[0]));
  }, [reliabilityDistributionOption, dispatch, options]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption) => dispatch(setReliabilityDistributionOption(option)),
    [dispatch]
  );

  return (
    <FormReactSelect
      labelKey="Distribution"
      value={reliabilityDistributionOption}
      options={options}
      onChange={handleSelectChange}
    />
  );
};

export default SelectDistribution;
