import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { newTasksCountSelector, newTasksSelector } from 'modules/tasks/selectors';
import { fetchNewTasksAction, deleteNewTaskAction } from 'modules/tasks';
import { setModalConfirmationMetaAction, setTaskModalMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconDelete, IconPlus } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const TasksNewTable: React.FC<Props> = ({ maxTableHeight }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(newTasksCountSelector);
  const items = useSelector(newTasksSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchNewTasksAction(params)).then(
        (action: Shared.ReduxAction<{ newTasksHash: Type.Hash<Tasks.NewTask> }>) =>
          Object.values(action.payload?.newTasksHash || {})
      ),
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const taskNumber = event.currentTarget.getAttribute('data-id');
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteNewTaskAction(taskNumber)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.NEW_TASKS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.NewTask) => (
        <TasksNewTableItem key={item.id} task={item} handleDeleteBtnClick={handleDeleteBtnClick} />
      ))}
    </DataTable>
  );
};

const TasksNewTableItem: React.FC<{
  task: Tasks.NewTask;
  handleDeleteBtnClick: (event: React.SyntheticEvent) => void;
}> = memo(({ task, handleDeleteBtnClick }) => {
  const { id, date, department, departmentNumber, description, mainActivity, projectNumber, taskNumber } = task;
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleCreateTaskBtnClick = useCallback((): void => {
    dispatch(setTaskModalMetaAction({ departmentNumber, taskNumber, description }));
  }, [departmentNumber, taskNumber, description, dispatch]);

  return (
    <tr>
      <td>{dateFormat(date)}</td>
      <td>{department}</td>
      <td>{description}</td>
      <td>{mainActivity || '-'}</td>
      <td>{projectNumber}</td>
      <td>{taskNumber}</td>
      <td className="text-right">
        <Button
          tooltipKey="Create task"
          icon={<IconPlus />}
          data-id={id}
          onClick={handleCreateTaskBtnClick}
          size="small"
          variant="primary"
        />
        <Button
          marginLeft
          tooltipKey="Delete"
          icon={<IconDelete />}
          data-id={taskNumber}
          onClick={handleDeleteBtnClick}
          size="small"
          variant="primary"
        />
      </td>
    </tr>
  );
});

export default TasksNewTable;
