import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Spinner } from 'components/_common';
import { AssetLifeAPI } from 'constants/index';

interface Props {
  title: string;
  src: string;
  height?: string;
}

const ChartBase64: React.FC<Props> = ({ title, src, height = '70vh' }) => {
  const [base64, setBase64] = useState(undefined);

  useEffect(() => {
    setBase64(undefined);
    if (!src) return;
    AssetLifeAPI.get(src)
      .then((res: any) => setBase64(res.data.image))
      .catch(console.error);
  }, [src]);

  return (
    <StyledContainer data-height={height}>
      {base64 ? <img src={`data:image/png;base64, ${base64}`} alt={title} /> : <Spinner isInFullHeightContainer />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ 'data-height': string }>`
  height: ${props => props['data-height']};
  display: flex;
  justify-content: center;

  > img {
    height: 100%;
  }
`;

export default ChartBase64;
