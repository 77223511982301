import { combineActions, handleActions } from 'redux-actions';
import {
  fetchAssetCategoriesAction,
  createAssetCategoryAction,
  deleteAssetCategoryAction,
  fetchAssetsAction,
  fetchAssetAction,
  createAssetAction,
  updateAssetAction,
  deleteAssetAction,
  deleteAssetPhotosAction,
  fetchModelsByAssetCategoryAction,
  fetchSubComponentsAction,
  createSubComponentAction,
  updateSubComponentAction,
  deleteSubComponentAction,
  fetchSmartMetersAction,
  fetchAssetTaskCompletionsAction,
  fetchAssetCodeAutoCompleteAction,
  fetchAssetCodeAutoCompleteNewAction,
  fetchInstallationNumberAutoCompleteAction,
  fetchMissingAgeAction,
} from './actions';
import {
  fetchAssetCategoriesCNAIMAction,
  fetchAssetCategoriesCNAIMTemplatesAction,
  parseAssetCategoriesCNAIMFileAction,
  uploadAssetCategoriesCNAIMFileAction,
  deleteAssetCategoriesCNAIMAction,
  fetchCategoriesCNAIMAssetsAction,
  fetchSelectAssetCategoriesCNAIMOptionsAction,
} from './cnaim.actions';

const initialState: Asset.Root = {
  assetsCount: 0,
  assetsHash: {} as Type.Hash<Asset.Item>,
  subComponentsCount: 0,
  subComponentsHash: {} as Type.Hash<Asset.SubComponentType>,
  smartMetersHashFetched: false,
  smartMetersHash: {} as Type.Hash<Asset.SmartMeter>,
  categoriesCount: 0,
  categoriesHash: {} as Type.Hash<Asset.Category>,
  categoriesCNAIMCount: 0,
  categoriesCNAIMHash: {} as Type.Hash<Asset.CategoryCNAIM>,
  categoriesCNAIMTemplates: null,
  categoriesCNAIMOptions: [],
  categoriesFetched: false,
  taskCompletionsCount: 0,
  taskCompletions: [],
  missingAgeHash: {} as Type.Hash<Asset.MissingAge>,
  missingAgeCount: 0,
  assetMeta: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [combineActions(
    fetchAssetCategoriesAction,
    fetchAssetsAction,
    fetchSubComponentsAction,
    fetchAssetTaskCompletionsAction,
    fetchSmartMetersAction,
    fetchAssetCategoriesCNAIMAction,
    fetchAssetCategoriesCNAIMTemplatesAction,
    fetchSelectAssetCategoriesCNAIMOptionsAction,
    fetchMissingAgeAction
  ).toString()]: {
    next: (
      state: Asset.Root,
      action: Shared.ReduxAction<Asset.Root & { skipStoreUpdate: true | undefined }>
    ): Asset.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  [fetchAssetAction.toString()]: {
    next: (state: Asset.Root, action: Shared.ReduxAction<Asset.Root['assetMeta']>): Asset.Root => ({
      ...state,
      assetMeta: action.payload,
    }),
  },
};

export {
  fetchAssetCategoriesAction,
  createAssetCategoryAction,
  deleteAssetCategoryAction,
  fetchAssetsAction,
  fetchAssetAction,
  createAssetAction,
  updateAssetAction,
  deleteAssetAction,
  deleteAssetPhotosAction,
  fetchModelsByAssetCategoryAction,
  fetchSubComponentsAction,
  createSubComponentAction,
  updateSubComponentAction,
  deleteSubComponentAction,
  fetchSmartMetersAction,
  fetchAssetTaskCompletionsAction,
  fetchAssetCodeAutoCompleteAction,
  fetchAssetCodeAutoCompleteNewAction,
  fetchInstallationNumberAutoCompleteAction,
  fetchAssetCategoriesCNAIMAction,
  fetchAssetCategoriesCNAIMTemplatesAction,
  parseAssetCategoriesCNAIMFileAction,
  uploadAssetCategoriesCNAIMFileAction,
  deleteAssetCategoriesCNAIMAction,
  fetchCategoriesCNAIMAssetsAction,
  fetchSelectAssetCategoriesCNAIMOptionsAction,
  fetchMissingAgeAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
