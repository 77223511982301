import mapboxgl from 'mapbox-gl';
import { mapColorGradient, paintLayer, filterLayer, getLayer, getAssetLayerFilters } from 'utils/map';
import { assetOpacity, additionalAssetOpacity } from 'components/Map/core/layers/assets';

const isHovered: mapboxgl.Expression = ['boolean', ['feature-state', 'hover'], false];
const isHighVoltage: mapboxgl.Expression = [
  'any',
  ['==', ['get', 'voltage_level_id'], 4],
  ['==', ['get', 'voltage_level_id'], 3],
];
const isMediumVoltage: mapboxgl.Expression = ['==', ['get', 'voltage_level_id'], 2];
const isLowVoltage: mapboxgl.Expression = ['==', ['get', 'voltage_level_id'], 1];
const defaultMinHeight: mapboxgl.Expression = ['number', ['get', 'min_height'], 18];
const defaultMaxHeight: mapboxgl.Expression = ['number', ['get', 'max_height'], 88];
const isPropertyExist = (prop: string): mapboxgl.Expression => ['==', ['typeof', ['get', prop]], 'number'];

const indexToVoltageFilter: Record<number, mapboxgl.Expression> = {
  0: isHighVoltage,
  1: isMediumVoltage,
  2: isLowVoltage,
};

export const loadColor = (
  prop: string,
  theme: Map.ColorTheme,
  type: string,
  ranges: number[][]
): mapboxgl.Expression => {
  const value = ['to-number', ['get', prop]];
  const isMultiScale = ranges.length > 1;
  const voltageColors = ranges.flatMap(([min, max], i) => [
    indexToVoltageFilter[i],
    ['interpolate', ['linear'], value, ...mapColorGradient(theme, type, min, max)],
  ]);
  const defaultColors = voltageColors[1];
  return [
    'case',
    isHovered,
    '#7345e0',
    [
      'case',
      isPropertyExist(prop),
      isMultiScale ? ['case', ...voltageColors, defaultColors] : defaultColors,
      '#918f86',
    ],
  ];
};

export const loadHeight = (prop: string, type: string, ranges: number[][]): mapboxgl.Expression => {
  const value = ['to-number', ['get', prop]];
  const isMultiScale = ranges.length > 1;
  const isCentered = type === 'centered';
  const isReversed = type === 'reversed';
  const minHeight = isReversed ? defaultMaxHeight : defaultMinHeight;
  const maxHeight = isReversed ? defaultMinHeight : defaultMaxHeight;
  const voltageHeights = ranges.flatMap(([min, max], i) => {
    const mid = min + (max - min) / 2;
    return [
      indexToVoltageFilter[i],
      [
        'interpolate',
        ['linear'],
        value,
        ...(isCentered ? [min, maxHeight, mid, minHeight, max, maxHeight] : [min, minHeight, max, maxHeight]),
      ],
    ];
  });
  const defaultHeights = voltageHeights[1];
  return [
    'case',
    isPropertyExist(prop),
    isMultiScale ? ['case', ...voltageHeights, defaultHeights] : defaultHeights,
    5,
  ];
};

export const loadOpacity = (prop: string): mapboxgl.Expression => {
  return [
    'case',
    ['==', ['typeof', ['get', 'in_theme']], 'boolean'],
    [
      'case',
      ['all', ['boolean', ['get', 'in_theme'], false], ['==', ['typeof', ['get', prop]], 'number']],
      assetOpacity,
      additionalAssetOpacity,
    ],
    assetOpacity,
  ];
};

export const updateScenarioLayer = (
  map: Map.MapboxMap,
  property: string,
  layers: string[],
  theme: Map.ColorTheme,
  type: string,
  ranges: number[][]
) => {
  layers
    ?.filter(i => !/(highlight|cluster|background)$/.test(i))
    .forEach(i => {
      const layer = getLayer(i);
      if (!layer) return null;
      const paintObjectKeys = Object.keys(layer.style?.paint ?? {});
      const lineColorKey = paintObjectKeys.find(k => k === 'line-color');
      const pointColorKey = paintObjectKeys.find(k => k === 'circle-color');
      const columnColorKey = paintObjectKeys.find(k => k === 'fill-extrusion-color');
      const columnHeightKey = paintObjectKeys.find(k => k === 'fill-extrusion-height');
      const opacityKeys = paintObjectKeys.filter(k =>
        ['circle-opacity', 'circle-stroke-opacity', 'icon-opacity', 'line-opacity'].includes(k)
      );
      if (lineColorKey) paintLayer(map, i, lineColorKey, loadColor(property, theme, type, ranges));
      if (columnColorKey) paintLayer(map, i, columnColorKey, loadColor(property, theme, type, ranges));
      if (columnHeightKey) paintLayer(map, i, columnHeightKey, loadHeight(property, type, ranges));
      if (pointColorKey) paintLayer(map, i, pointColorKey, loadColor(property, theme, type, ranges));
      opacityKeys.forEach(opacityKey => paintLayer(map, i, opacityKey, loadOpacity(property)));
    });
};

export const updateMaxLoadLayer = (
  map: Map.MapboxMap,
  scenario: Map.Scenario,
  showMaxLoadVoltage: boolean,
  settings: Map.MapSettings,
  theme: Map.ColorTheme,
  selectedInvestmentScenario?: Type.SelectOption | null
) => {
  const id = scenario.key;
  const showMaxLoadIcons = !/no investment/i.test(String(selectedInvestmentScenario?.label));
  const prop = showMaxLoadVoltage ? `v_${id}` : showMaxLoadIcons ? `l_${id}` : `r_${id}`;
  const scaleType = showMaxLoadVoltage ? 'centered' : 'default';
  const scaleRanges = showMaxLoadVoltage ? [[0.9, 1.1]] : [[0, 100]];
  updateScenarioLayer(map, prop, settings.maxLoadLayers!, theme, scaleType, scaleRanges);

  if (!selectedInvestmentScenario) return;

  const investmentFilter = [
    'any',
    ['!', ['has', 'investment_scenario_id']],
    ['==', ['get', 'investment_scenario_id'], selectedInvestmentScenario.value],
  ];

  settings.maxLoadLayers
    ?.filter(i => !/highlight$/.test(i))
    .forEach(i => {
      const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
      filterLayer(map, i, ['all', voltageFilter, investmentFilter, assetFilter, textFilter]);
    });

  settings.maxLoadIconsLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const prefix = i.endsWith('flexibility') ? 'f' : 'i';
    const investmentIconFilter = ['all', ['to-boolean', ['get', `${prefix}_${id}`]], investmentFilter];
    filterLayer(map, i, ['all', voltageFilter, investmentIconFilter, assetFilter, textFilter]);
  });
};
