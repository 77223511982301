import React, { useCallback, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import classNames from 'classnames';
import { FormikProps } from './useConfiguredFormik';
import { SelectSubComponentType } from 'components/_common';
import { AssetCategories } from 'constants/index';

interface Props {
  models: Asset.AssetModel[];
  formik: FormikProps;
}

const AddSubComponent: React.FC<Props> = ({ models, formik }) => {
  const { getIntl } = useLocale();
  const { setFieldValue } = formik;
  const [subComponentType, setSubComponentType] = useState<{ id: number | null; label: string } | null>(null);

  const handleSetFieldValue = useCallback((id: number | null, label: string) => {
    setSubComponentType({ id, label });
  }, []);

  const handleAddSubcomponentButtonClick = useCallback((): void => {
    if (!subComponentType) return;
    setFieldValue('models', [
      ...models,
      {
        manufacturerId: null,
        manufacturerName: '',
        modelId: null,
        modelName: '',
        subComponentTypeId: subComponentType.id,
        subComponentTypeName: subComponentType.label,
        subcomponentId: Date.now(),
        subcomponentPrefix: '',
      },
    ]);
    setSubComponentType(null);
  }, [models, setFieldValue, subComponentType]);

  return (
    <Form.Group as={Row} className={classNames({ 'mt-4': models?.length })}>
      <Form.Label column sm="5" className="font-weight-normal">
        {getIntl('Add sub-component type')}
      </Form.Label>
      <Col sm="7">
        <Form.Row className="align-items-end">
          <Form.Group as={Col}>
            <SelectSubComponentType
              labelKey=""
              assetCategoryCode={AssetCategories.BD}
              setFieldValue={handleSetFieldValue}
              value={subComponentType?.id || null}
            />
          </Form.Group>
          <Form.Group as={Col} xs="auto">
            <button
              type="button"
              disabled={!subComponentType?.id}
              className="btn btn-default w-100"
              onClick={handleAddSubcomponentButtonClick}
            >
              <i className="fa fa-plus" />
            </button>
          </Form.Group>
        </Form.Row>
      </Col>
    </Form.Group>
  );
};

export default AddSubComponent;
