import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useLocale } from 'hooks';

const indexToVoltage: Record<number, string> = {
  0: 'high',
  1: 'medium',
  2: 'low',
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  scales: React.ReactNode[];
}

const ScaleGroup: React.FC<Props> = ({ scales, ...rest }) => {
  const { getIntl } = useLocale();
  const showLabel = scales.length > 1;
  return (
    <StyledScaleGroup $showLabel={showLabel} {...rest}>
      {scales.map((scale, i) => (
        <React.Fragment key={i}>
          {showLabel && (
            <div className="icon-container">
              <div className={classNames('icon', [indexToVoltage[i]])} />
              <div className="icon-label">{getIntl(String(indexToVoltage[i]))}</div>
            </div>
          )}
          <div className="scale">{scale}</div>
        </React.Fragment>
      ))}
    </StyledScaleGroup>
  );
};

const StyledScaleGroup = styled.div<{ $showLabel: boolean }>`
  display: grid;
  grid-template-columns: ${({ $showLabel }) => ($showLabel ? 'auto 1fr' : '1fr')};
  gap: 20px;
  align-items: center;

  .icon-container {
    display: flex;
    align-items: center;
    position: relative;
    top: 9px;
    font-size: 10px;
  }

  .icon {
    margin-right: 10px;
    border-radius: 50%;
    background-color: #918f86;
    border: 0.5px solid #5e6064;
    height: 18px;
    width: 18px;
    scale: 0.9;

    &.low {
      background-color: #5354fd;
    }

    &.medium {
      background-color: #fd5353;
    }

    &.high {
      background-color: #fe53fd;
    }
  }

  .icon-label {
    position: relative;
    top: -1px;
  }
`;

export default ScaleGroup;
