import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { SelectTaskStatus } from 'components/_common';
import { DataTableFilterKeys, PaginationType, TaskStatus } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  values: Layouts.Filters;
  paginationType: Type.PaginationType;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Layouts.Filters>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const SelectTaskStatusFilter: React.FC<Props> = ({
  inDataTableHeaderFilters = false,
  values,
  paginationType,
  setFieldValue,
  submitForm,
}) => {
  const isMulti = paginationType === PaginationType.EMPLOYEES;

  const handleSelectChange = useCallback(
    async (value: any) => {
      const nextValue = Array.isArray(value) ? value.map((i: any) => i.value) : value?.value;
      await setFieldValue(DataTableFilterKeys.STATUS, nextValue || (isMulti ? [] : null));
      if (inDataTableHeaderFilters) submitForm();
    },
    [setFieldValue, inDataTableHeaderFilters, submitForm, isMulti]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.STATUS)) return null;

  const Select = (
    <SelectTaskStatus
      labelKey={inDataTableHeaderFilters ? '' : undefined}
      name={DataTableFilterKeys.STATUS}
      value={values.status || null}
      onChange={handleSelectChange}
      isClearable
      isMulti={isMulti}
      excludedOptions={paginationType !== PaginationType.EMPLOYEES ? [TaskStatus.Finished] : []}
    />
  );

  return inDataTableHeaderFilters ? <Col sm={4}>{Select}</Col> : <Form.Group>{Select}</Form.Group>;
};

export default SelectTaskStatusFilter;
