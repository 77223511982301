import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { newLoadEditIndividualCustomerModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { individualCustomersSelectorFactory } from 'modules/newLoad/selectors';
import {
  Modal,
  HiddenFormSubmit,
  FormInput,
  SelectDERsCategory,
  SelectDERsProfile,
  SelectDERsDescription,
  SelectDERsPointAsset,
} from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';

const ModalEditIndividualCustomer: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(newLoadEditIndividualCustomerModalIdSelector);
  const individualCustomer: NewLoad.IndividualCustomer | undefined = useSelector(
    individualCustomersSelectorFactory(id)
  );
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ newLoadEditIndividualCustomerModalId: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setValues,
    resetForm,
    setFieldValue,
  } = useConfiguredFormik({ id: id as number, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!individualCustomer) return;
    setValues({
      description: individualCustomer.description,
      der_categories_id: individualCustomer.der_categories_id,
      customer_node_from: individualCustomer.customer_node_from,
      der_profile_info_id: individualCustomer.der_profile_info_id,
      customer_id: individualCustomer.customer_id,
      customer_mw: individualCustomer.customer_mw,
      connection_year: individualCustomer.connection_year,
      power_factor: individualCustomer.power_factor,
      leading_lagging: individualCustomer.leading_lagging || '',
    });
    setShow(true);
  }, [individualCustomer, setValues]);

  return (
    <>
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Update individual customer"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: 'Update',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectDERsDescription
              name="description"
              label={values.description}
              setFieldValue={setFieldValue}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            />
          </Form.Group>
          <SelectDERsCategory
            value={values.der_categories_id}
            setFieldValue={setFieldValue}
            errorKey={
              Boolean(touched.der_categories_id && errors.der_categories_id) ? (errors.der_categories_id as string) : ''
            }
            show={show}
          />
          <Form.Group>
            <SelectDERsPointAsset
              value={values.customer_node_from}
              onChange={(value: string) => setFieldValue('customer_node_from', value)}
              errorKey={
                Boolean(touched.customer_node_from && errors.customer_node_from)
                  ? (errors.customer_node_from as string)
                  : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <SelectDERsProfile
              value={values.der_profile_info_id}
              onChange={(value: number | null) => setFieldValue('der_profile_info_id', value)}
              onBlur={handleBlur}
              errorKey={
                Boolean(touched.der_profile_info_id && errors.der_profile_info_id)
                  ? (errors.der_profile_info_id as string)
                  : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Customer ID"
              type="number"
              name="customer_id"
              value={values.customer_id || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.customer_id && errors.customer_id) ? (errors.customer_id as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Customer (MW)"
              type="number"
              name="customer_mw"
              value={values.customer_mw || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.customer_mw && errors.customer_mw) ? (errors.customer_mw as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Connection year"
              type="number"
              name="connection_year"
              value={values.connection_year || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={
                Boolean(touched.connection_year && errors.connection_year) ? (errors.connection_year as string) : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Power factor"
              type="number"
              name="power_factor"
              value={values.power_factor || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.power_factor && errors.power_factor) ? (errors.power_factor as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Leading lagging"
              name="leading_lagging"
              value={values.leading_lagging || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={
                Boolean(touched.leading_lagging && errors.leading_lagging) ? (errors.leading_lagging as string) : ''
              }
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditIndividualCustomer;
