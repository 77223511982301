import moment from 'moment';
import { createAction } from 'redux-actions';
import { setLayoutAction, setPaginationAction } from 'modules/layouts';
import { fetchComponentPriceScenariosAction, fetchInvestmentScenariosAction } from 'modules/setup';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
  investmentScenarioIdSelector,
  paginationSelectorFactory,
} from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import {
  DERsPointAssetsOptionsHashSelector,
  cnaimAssetCategoriesOptionsHashSelector,
  primarySubstationOptionsHashSelector,
  scenariosYearsOptionsHashSelector,
} from './selectors';
import { PaginationType, AssetLifeAPI, isProduction } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchPortfolioOptionsAction = createAction(
  'options/FETCH_PORTFOLIO_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'portfolioOptions' | 'portfolioOptionsFetched'>> => {
      return AssetLifeAPI.get('shared/portfolios').then(
        (res: { data: { id: number; department: string; loadflow_enabled: boolean }[] }) => {
          const options = res.data.map(item => ({
            value: item.id,
            label: item.department,
            loadflow_enabled: item.loadflow_enabled,
          }));
          const state: State.Root = getState();
          const portfolioId = portfolioIdSelector(state);
          const option = options.find((i: Type.SelectOption) => i.value === portfolioId);

          // select value automatically
          if (!portfolioId || !option) {
            dispatch(setLayoutAction({ portfolioId: options[0]?.value || null }));
          }

          return { portfolioOptions: options, portfolioOptionsFetched: true };
        }
      );
    }
);

export const fetchScenarioOptionsAction = createAction(
  'options/FETCH_SCENARIO_OPTIONS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Options.Root, 'scenarioOptions'>> => {
      const state: State.Root = getState();
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);
      return AssetLifeAPI.get('scenarios/', { params: { portfolio_id: portfolioId } }).then(res => {
        const options = res.data.map((item: { id: number; label: string; scenarios_param: number }) => ({
          value: item.id,
          label: item.label,
          scenariosParam: item.scenarios_param,
        }));
        const option = options.find((i: Options.ScenarioOption) => i.value === scenarioId);

        // select value automatically
        if (!scenarioId || !option) {
          dispatch(setLayoutAction({ scenarioId: options[0]?.value }));
        }

        return {
          scenarioOptions: options,
        };
      });
    }
);

export const fetchScenarioYearsOptionsAction = createAction(
  'options/FETCH_SCENARIO_YEARS_OPTIONS',
  async (): Promise<Pick<Options.Root, 'scenarioYearsOptions'>> =>
    AssetLifeAPI.get('scenarios/scenarios_years_possible').then(res => ({
      scenarioYearsOptions: res.data?.length ? res.data : [],
    }))
);

export const fetchSimulationOptionsAction = createAction(
  'options/FETCH_SIMULATION_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'simulationOptions' | 'simulationOptionsFetched'>> => {
      const portfolioId = portfolioIdSelector(getState());
      return AssetLifeAPI.get('shared/simulation', { params: { portfolio_id: portfolioId } }).then(res => {
        const options = res.data.map(
          (item: { id: number; label: string; version_id: number; is_map_updated: boolean }) => ({
            value: item.id,
            label: `${moment(item.label).format('L LT')} v.${item.version_id}/s.${item.id}${
              item.is_map_updated ? ' map' : ''
            }`,
            versionId: item.version_id,
          })
        );
        const state: State.Root = getState();
        const simulationId = simulationIdSelector(state);
        const option = options.find((i: Type.SelectOption) => i.value === simulationId);

        // Note: Always select first simulationId option on production
        if ((!simulationId || !option || isProduction) && options[0]?.value) {
          dispatch(setLayoutAction({ simulationId: options[0].value }));
        }

        return { simulationOptions: options, simulationOptionsFetched: true };
      });
    }
);

interface FetchPercentileOptionsActionResponse {
  id: number;
  label: number;
  default?: boolean;
}

export const fetchPercentileOptionsAction = createAction(
  'options/FETCH_PERCENTILE_OPTIONS',
  async ({
    portfolioId,
    scenarioId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.Root['scenarioId'];
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'percentilesOptions'>> => {
      return AssetLifeAPI.get('load/percentile', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const options = res.data.map((item: FetchPercentileOptionsActionResponse) => ({
          value: item.id,
          label: item.label,
        }));
        const state: State.Root = getState();

        // Automatically apply default values for necessary table filters
        [PaginationType.TRANSFORMERS, PaginationType.CABLES, PaginationType.NETWORK_LOADING_VOLTAGE_DROP].forEach(
          type => {
            const { filters } = paginationSelectorFactory(type)(state);
            const option = options.find((i: Type.SelectOption) => i.value === filters?.percentile);

            if (!filters?.percentile || !option) {
              const index = res.data.findIndex((o: FetchPercentileOptionsActionResponse) => o.default) || 0;
              const modifier = { filters: { ...filters, percentile: options[index].value }, offset: 0 };
              dispatch(setPaginationAction({ type, modifier }));
            }
          }
        );

        return { percentilesOptions: options };
      });
    }
);

export const fetchScenariosYearsOptionsAction = createAction(
  'options/FETCH_SCENARIOS_YEARS_OPTIONS',
  async ({
    portfolioId,
    scenarioId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.Root['scenarioId'];
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'scenariosYearsOptionsHash'>> => {
      return AssetLifeAPI.get('scenarios/scenarios_years_results', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const types = [PaginationType.TRANSFORMERS, PaginationType.CABLES, PaginationType.NETWORK_LOADING_VOLTAGE_DROP];
        const options = res.data.map((item: number) => ({ value: item, label: item }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.year);
          if (!filters?.year || !option) {
            const modifier = { filters: { ...filters, year: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          scenariosYearsOptionsHash: {
            ...scenariosYearsOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const fetchOutageTypeOptionsAction = createAction(
  'options/FETCH_OUTAGE_TYPE_OPTIONS',
  () =>
    async (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'outageTypeOptions'>> => {
      return AssetLifeAPI.get('n_1/outage_types').then(res => {
        const state = getState();
        const type = PaginationType.N1;
        const { filters } = paginationSelectorFactory(type)(state);

        const outageTypeOptions = [{ value: '', label: 'All' }, ...res.data];

        // Set first value by default
        if (!outageTypeOptions.some(o => o.value === filters?.outageType)) {
          const modifier = { filters: { ...filters, outageType: outageTypeOptions[0].value }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }

        return { outageTypeOptions };
      });
    }
);

export const fetchRoutesOptionsAction = createAction(
  'options/FETCH_ROUTES_OPTIONS',
  () =>
    async (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'routesOptions'>> => {
      return AssetLifeAPI.get('n_1/routes').then(res => {
        const state = getState();
        const type = PaginationType.N1;
        const { filters } = paginationSelectorFactory(type)(state);

        const routesOptions = [{ value: '', label: 'All' }, ...res.data];

        if (!routesOptions.some(o => o.value === filters?.route)) {
          const modifier = { filters: { ...filters, route: routesOptions[0].value }, offset: 0 };
          dispatch(setPaginationAction({ type, modifier }));
        }

        return { routesOptions };
      });
    }
);

export const fetchInvestmentScenariosOptionsAction = createAction(
  'options/FETCH_INVESTMENT_SCENARIOS_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'investmentScenariosOptions'>> => {
      return dispatch(
        fetchInvestmentScenariosAction({ skipPagination: true, skipStoreUpdate: true, returnAllItems: true })
      ).then((action: Shared.ReduxAction<{ investmentScenariosHash: Setup.Root['investmentScenariosHash'] }>) => {
        const items = Object.values(action.payload?.investmentScenariosHash || {});
        const options = [
          { value: 0, label: 'No investment' },
          ...items.map((item: Setup.InvestmentScenario) => ({ value: item.id, label: item.description })),
        ];
        const state: State.Root = getState();
        const investmentScenarioId = investmentScenarioIdSelector(state);
        const option = options.find((i: Type.SelectOption) => i.value === investmentScenarioId);

        // select value automatically
        if (!investmentScenarioId || !option) {
          dispatch(setLayoutAction({ investmentScenarioId: options[0].value }));
        }

        return {
          investmentScenariosOptions: options,
        };
      });
    }
);

export const fetchComponentPriceScenarioOptionsAction: any = createAction(
  'options/FETCH_COMPONENT_PRICE_SCENARIO_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'componentPriceScenarioOptionsHash'>> => {
      const state: State.Root = getState();
      const portfolioId = portfolioIdSelector(state);
      return dispatch(fetchComponentPriceScenariosAction({ skipPagination: true, skipStoreUpdate: true })).then(
        action => {
          const types = [PaginationType.WORK_PRICES];

          const options = Object.values<Setup.ComponentPriceScenario>(
            action.payload?.componentPriceScenariosHash || []
          ).map((i: { id: number; description: string }) => ({ value: i.id, label: i.description }));

          // Automatically apply default values for necessary table filters
          types.forEach(type => {
            const state = getState();
            const { filters } = paginationSelectorFactory(type)(state);
            const option = options.find((i: Type.SelectOption) => i.value === filters?.componentPriceScenarioId);
            if (!option) {
              const modifier = { filters: { ...filters, componentPriceScenarioId: options[0]?.value }, offset: 0 };
              dispatch(setPaginationAction({ type, modifier }));
            }
          });
          return {
            componentPriceScenarioOptionsHash: {
              ...getState().options.componentPriceScenarioOptionsHash,
              [String(portfolioId)]: options,
            },
          };
        }
      );
    }
);

export const fetchComponentPriceTypeOptionsAction = createAction(
  'options/FETCH_COMPONENT_PRICE_TYPE_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'componentPriceTypeOptions'>> =>
      AssetLifeAPI.get('investment/price_type').then(res => {
        const types = [PaginationType.COMPONENT_PRICES];
        const options = res.data.map((i: { price_type_id: number; price_type: string }) => ({
          value: i.price_type_id,
          label: i.price_type,
        }));

        // Automatically apply default values for necessary table filters
        types.forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.priceTypeId);
          if (!option) {
            const modifier = { filters: { ...filters, priceTypeId: options[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return { componentPriceTypeOptions: options };
      })
);

interface CnaimIDsResponse {
  cnaim_id: number;
  asset_register_category: string;
  price_unit: string;
  size_unit: string;
  co2e_unit: string;
  investment: boolean;
}

export const fetchCnaimAssetCategoriesOptionsAction = createAction(
  'options/FETCH_CNAIM_ASSET_CATEGORIES_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'cnaimAssetCategoriesOptionsHash'>> => {
      const lang = appLangSelector(getState());
      return AssetLifeAPI.get('shared/all_cnaim_ids', { params: { lang: lang.toLocaleLowerCase() } }).then(res => {
        const options = res.data.rows
          .filter((i: CnaimIDsResponse) => i.investment)
          .map((i: CnaimIDsResponse) => ({
            value: i.cnaim_id,
            label: i.asset_register_category,
            priceUnit: res.data.meta.currency + i.price_unit,
            sizeUnit: i.size_unit,
            co2eUnit: i.co2e_unit,
          }));
        return {
          cnaimAssetCategoriesOptionsHash: {
            ...(cnaimAssetCategoriesOptionsHashSelector(getState()) || {}),
            [lang]: options,
          },
        };
      });
    }
);

export const fetchDERsDescriptionOptionsAction = createAction(
  'options/FETCH_DERS_DESCRIPTION_OPTIONS',
  () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'DERsDescriptionOptionsHash'>> => {
      const state: State.Root = getState();
      // Note. We don't need to pass portfolioId, scenarioId as props,
      // since SelectDERsDescription is in use only on modal windows
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);

      return AssetLifeAPI.get('scenarios/available_der_descriptions', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => {
        const options = res.data.map((item: NewLoad.DER) => ({ value: item.id, label: item.description }));
        return {
          DERsDescriptionOptionsHash: {
            ...DERsPointAssetsOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);

export const resetDERsDescriptionOptionsAction = createAction(
  'options/RESET_DERS_DESCRIPTION_OPTIONS_HASH_ITEM',
  () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Pick<Options.Root, 'DERsDescriptionOptionsHash'> => {
      const state: State.Root = getState();
      // Note. We don't need to pass portfolioId, scenarioId as props,
      // since SelectDERsDescription is in use only on modal windows
      const portfolioId = portfolioIdSelector(state);
      const scenarioId = scenarioIdSelector(state);

      return {
        DERsDescriptionOptionsHash: {
          ...DERsPointAssetsOptionsHashSelector(getState()),
          [`${portfolioId}_${scenarioId}`]: null,
        },
      };
    }
);

export const fetchDERsPointAssetsOptionsAction = createAction(
  'options/FETCH_DERS_POINT_ASSETS_OPTIONS',
  async ({ simulationVersionId }: { simulationVersionId: number }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'DERsPointAssetsOptionsHash'>> =>
      AssetLifeAPI.get('assets/point_asset', { params: { version_id: simulationVersionId } }).then(res => ({
        DERsPointAssetsOptionsHash: {
          ...DERsPointAssetsOptionsHashSelector(getState()),
          [simulationVersionId!]: res.data.map((i: { id: number; label: string }) => ({ value: i.id, label: i.label })),
        },
      }))
);

export const fetchWorkTypeOptionsAction = createAction(
  'options/FETCH_WORK_TYPE_OPTIONS',
  async () => (): Promise<Pick<Options.Root, 'workTypeOptions'>> =>
    AssetLifeAPI.get('work_type/work_type').then(res => ({
      workTypeOptions: res.data.rows.map((i: { id: number; description: string; price_unit: string }) => ({
        value: i.id,
        label: i.description,
        priceUnit: i.price_unit,
      })),
    }))
);

export const fetchPrimarySubstationsOptionsAction = createAction(
  'options/FETCH_PRIMARY_SUBSTATIONS',
  async ({
    portfolioId,
    scenarioId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.Root['scenarioId'];
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Options.Root, 'primarySubstationOptionsHash'>> => {
      return AssetLifeAPI.get('shared/primary_substations', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: { data: { name: string; id: number }[] }) => {
        const options = res.data.map(item => ({ value: item.id, label: item.name }));
        const ids = options?.map(option => option.value);

        // Reset value on portfolio or scenario change
        [PaginationType.DETAILED_INVESTMENTS, PaginationType.SUMMARY_PLAN].forEach(type => {
          const state = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          if (filters?.primarySubstationsIds?.some(id => !ids.includes(id))) {
            const modifier = { filters: { ...filters, primarySubstationsIds: null }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        // Automatically apply default values for necessary table filters
        [PaginationType.NETWORK_LOADING_VOLTAGE_DROP].forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          const option = options.find((i: Type.SelectOption) => i.value === filters?.primarySubstationsId);
          if (!filters?.primarySubstationsId || !option) {
            const modifier = { filters: { ...filters, primarySubstationsId: options?.[0]?.value }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        // Automatically apply all values for N-1 table
        [PaginationType.N1].forEach(type => {
          const state: State.Root = getState();
          const { filters } = paginationSelectorFactory(type)(state);
          if (!filters?.primarySubstationsIds || filters?.primarySubstationsIds?.some(id => !ids.includes(id))) {
            const modifier = { filters: { ...filters, primarySubstationsIds: ids }, offset: 0 };
            dispatch(setPaginationAction({ type, modifier }));
          }
        });

        return {
          primarySubstationOptionsHash: {
            ...primarySubstationOptionsHashSelector(getState()),
            [`${portfolioId}_${scenarioId}`]: options,
          },
        };
      });
    }
);
