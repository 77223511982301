import { combineActions, handleActions } from 'redux-actions';
import {
  fetchVoltageOptionsAction,
  fetchSAIFIRangeSliderConfigAction,
  fetchCMLRangeSliderConfigAction,
  fetchSAIFIDelayRangeSliderConfigAction,
  fetchScenarioParamsOptionsAction,
  fetchPoFParamsAction,
  updatePoFParamsAction,
} from './actions';

const initialState: Reliability.Root = {
  voltageOptions: [],
  saifiConfig: null,
  cmlConfig: null,
  saifiDelayConfig: null,
  scenarioParamsOptions: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [combineActions(
    fetchVoltageOptionsAction,
    fetchSAIFIRangeSliderConfigAction,
    fetchCMLRangeSliderConfigAction,
    fetchSAIFIDelayRangeSliderConfigAction,
    fetchScenarioParamsOptionsAction
  ).toString()]: {
    next: (
      state: Reliability.Root,
      action:
        | Shared.ReduxAction<Pick<Reliability.Root, 'voltageOptions'>>
        | Shared.ReduxAction<Pick<Reliability.Root, 'saifiConfig'>>
        | Shared.ReduxAction<Pick<Reliability.Root, 'cmlConfig'>>
        | Shared.ReduxAction<Pick<Reliability.Root, 'saifiDelayConfig'>>
        | Shared.ReduxAction<Pick<Reliability.Root, 'scenarioParamsOptions'>>
    ): Reliability.Root => ({
      ...state,
      ...action.payload,
    }),
  },
};

export {
  fetchVoltageOptionsAction,
  fetchSAIFIRangeSliderConfigAction,
  fetchCMLRangeSliderConfigAction,
  fetchSAIFIDelayRangeSliderConfigAction,
  fetchScenarioParamsOptionsAction,
  fetchPoFParamsAction,
  updatePoFParamsAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
