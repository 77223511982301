import React from 'react';
import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { useLocale } from 'hooks';

export interface ControlBoxProps extends React.HTMLAttributes<HTMLInputElement> {
  id?: string;
  name?: string;
  labelKey?: string;
  hint?: string;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  dataMarker?: string;
  icon?: React.ReactNode;
  suffix?: React.ReactNode;
  type: 'checkbox' | 'radio' | 'switch';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

enum LabelsMap {
  'Individuelle varmepumper' = 'Individual heat pumps',
  'Solceller' = 'Solar batteries',
}

const ControlBox: React.FC<ControlBoxProps> = ({
  id,
  checked,
  name,
  labelKey = '',
  hint,
  disabled = false,
  type,
  icon,
  suffix,
  className = '',
  dataMarker = '',
  ...rest
}) => {
  const { getIntl } = useLocale();
  return (
    <StyledControlBox
      $hasLabel={Boolean(labelKey)}
      className={classnames('sidebar-checkbox', className, { active: checked, disabled })}
    >
      <input
        id={id}
        name={name}
        type={type === 'switch' ? 'checkbox' : type}
        checked={checked}
        disabled={disabled}
        {...rest}
      />
      {type === 'switch' ? (
        <div className="sidebar-checkbox-switch"></div>
      ) : (
        <div className="sidebar-checkbox-icon">
          <StyledCheckbox $type={type} $checked={checked} />
        </div>
      )}
      {Boolean(labelKey) && (
        <StyledLabel title={hint} data-marker={dataMarker}>
          {icon && <div className="checkbox-icon">{icon}</div>}
          <span>{getIntl((LabelsMap as any)[labelKey] || labelKey)}</span>
          {suffix}
        </StyledLabel>
      )}
    </StyledControlBox>
  );
};

const StyledControlBox = styled.label<{ $hasLabel: boolean }>`
  ${({ $hasLabel }) =>
    $hasLabel &&
    css`
      width: 100%;
    `}

  .checkbox-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;

    svg path {
      fill: var(--map-text-color-secondary-light);
    }
  }

  &.active .checkbox-icon svg path {
    fill: var(--map-active-color);
  }

  &.disabled .checkbox-icon svg path {
    fill: #a7a7a7 !important;
  }
`;

const StyledCheckbox = styled.div<{ $type: string; $checked: boolean }>`
  position: relative;
  overflow: hidden;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ $checked }) => ($checked ? 'var(--map-active-color)' : 'var(--map-text-color-secondary)')};
  border-radius: ${({ $type }) => ($type === 'radio' ? '50%' : '30%')};
  background-color: #fff;
  padding: 2px;
  font-size: 11px;

  &::after {
    content: '';
    display: block;
    background-color: ${({ $checked }) => ($checked ? 'var(--map-active-color)' : '#FFF')};
    border-radius: ${({ $type }) => ($type === 'radio' ? '50%' : '30%')};
    width: 100%;
    height: 100%;
  }
`;

const StyledLabel = styled.div`
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 0.04em;
  display: flex;
  align-items: center;
  font-size: 11px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default ControlBox;
