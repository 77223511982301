import styled from 'styled-components';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocale } from 'hooks';
import { Gallery as ReactGridGallery } from 'react-grid-gallery';
import Lightbox from 'react-image-lightbox';
import { Alert } from 'components/_common';
import { TaskAppActions } from 'constants/index';
import 'react-image-lightbox/style.css';

interface Props {
  rowHeight?: number;
  images: Type.GalleryImage[];
  enableImageSelection?: boolean;
  onSelectImage?: any;
}

const Gallery: React.FC<Props> = ({
  images,
  rowHeight = 320,
  enableImageSelection = false,
  onSelectImage,
  ...props
}) => {
  const { getIntl } = useLocale();
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const prevIndex = (index + images.length - 1) % images.length;

  const setCustomTags = useCallback(
    (i: Type.GalleryImage) =>
      i.overlayTags?.map((tag: Type.GalleryTag) =>
        tag.title ? <div key={tag.value}>{getIntl((TaskAppActions as any)[tag.title] || tag.title)}</div> : null
      ),
    [getIntl]
  );

  const customOverlay = useMemo(
    () =>
      images.map((i: Type.GalleryImage) => {
        i.customOverlay = i.overlayTags?.length ? <StyledCaption>{setCustomTags(i)}</StyledCaption> : <></>;
        return i;
      }),
    [images, setCustomTags]
  );

  if (!images?.length) {
    return (
      <Alert className="mb-0 text-center" variant="light">
        {getIntl('Photo gallery is empty')}
      </Alert>
    );
  }

  return (
    <>
      <ReactGridGallery
        rowHeight={rowHeight}
        {...props}
        images={customOverlay}
        enableImageSelection={enableImageSelection}
        onSelect={onSelectImage}
        onClick={setIndex}
      />
      {Boolean(currentImage) && (
        <Lightbox
          mainSrc={currentImage.src}
          nextSrc={(images[nextIndex] || currentImage).src}
          prevSrc={(images[prevIndex] || currentImage).src}
          onCloseRequest={() => setIndex(-1)}
          onMovePrevRequest={() => setIndex(prevIndex)}
          onMoveNextRequest={() => setIndex(nextIndex)}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
        />
      )}
    </>
  );
};

const StyledCaption = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  max-height: 240px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 2px;
  font-size: 90%;

  > div {
    word-wrap: break-word;
    display: inline-block;
    background-color: white;
    height: auto;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    padding: 0.2em 0.6em 0.3em;
    border-radius: 0.25em;
    color: black;
    vertical-align: baseline;
    margin: 2px;
  }
`;

export default Gallery;
