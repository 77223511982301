import { createAction } from 'redux-actions';
import { appLangSelector } from 'modules/app/selectors';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { isPortfolioLoadflowEnabledSelector } from 'modules/options/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { AssetLifeAPI, PaginationType } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchRightsAction: any = createAction(
  'customers/FETCH_RIGHTS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Customers.Root, 'rightsHash' | 'rightsCount'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve({ rightsCount: 0, rightsHash: {} });
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.CUSTOMERS_RIGHTS
      )(state);
      return AssetLifeAPI.get('customers/customer_rights', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? null : offset,
          sort,
          column,
          query: skipPagination ? null : query,
          lang: appLangSelector(state).toLowerCase(),
          percentile: filters?.percentile,
          load_type: filters?.loadType,
          hide_solved: filters?.solvedValue,
          outliers_removed: filters?.outliersRemoved,
          missing_type: filters?.missingType,
          existing_ders_id: filters?.existingDersId,
          portfolio_id: portfolioIdSelector(state),
        },
      }).then((res: any) => ({
        rightsCount: res.data.count,
        rightsHash: _keyBy(res.data.rows, (item: Customers.Rights) => `_${item.index}_`),
        skipStoreUpdate,
      }));
    }
);

export const getElectricityConsumptionChartData: any = createAction(
  'customers/GET_ELECTRICITY_CONSUMPTION_CHART_DATA',
  async ({
    uuid,
    outliersRemoved,
    portfolioId,
  }: {
    uuid: string;
    outliersRemoved: boolean;
    portfolioId: Layouts.Root['portfolioId'];
  }) =>
    (): Promise<any> => {
      return AssetLifeAPI.get('customers/customer_timeseries', {
        params: { id: uuid, outliers_removed: outliersRemoved, portfolio_id: portfolioId },
      }).then(res => res.data);
    }
);

export const fetchExistingDERsOptionsAction = createAction(
  'customers/FETCH_EXISTING_DERS_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Customers.Root, 'existingDERsOptions'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) return Promise.resolve({ existingDERsOptions: [] });
      return AssetLifeAPI.get('/customers/customer_existing_ders', {
        params: { portfolio_id: portfolioIdSelector(state) },
      }).then(res => ({
        existingDERsOptions: res.data.map((i: { id: number; labels: string }) => ({
          value: i.id,
          label: i.labels,
        })),
      }));
    }
);
