import React, { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useLocale } from 'hooks';
import { issuesCountSelector, issuesSelector } from 'modules/dataQuality/selectors';
import { fetchIssuesAction } from 'modules/dataQuality';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';
import { IconMapPin } from '@utiligize/shared/resources';
import DataQualityIgnoredOption from './DataQualityIgnoredOption';

const TableIssues: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(issuesSelector);
  const count = useSelector(issuesCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      return dispatch(fetchIssuesAction(params)).then(
        (action: Shared.ReduxAction<{ issues: DataQuality.Root['issues'] }>) => action.payload.issues || []
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.ISSUES}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 243px)"
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
    >
      {items?.map((item: DataQuality.Issue) => (
        <tr key={item.issue_id}>
          <td>{item.id}</td>
          <td className="text-nowrap">{item.opened_time ? dateFormat(item.opened_time, 'L LT') : '-'}</td>
          <td className={classNames({ 'bg-danger': item.code?.toString()[0] === '1' })}>{item.code}</td>
          <td className="text-center">
            <DataQualityIgnoredOption issueId={item.issue_id} checked={item.is_ignored} />
          </td>
          <td>{item.ignored_explanation || '-'}</td>
          <td className="text-break">{item.warning}</td>
          <td className="text-right">
            {item.positiononthemap ? (
              <Button
                tooltipKey="Show on map"
                icon={<IconMapPin />}
                data-id={item.issue_id}
                linkProps={{
                  to: {
                    pathname: generatePath(Routes.Map),
                    state: {
                      issue_id: item.issue_id,
                      code: item.code,
                      positiononthemap: item.positiononthemap,
                      warning: item.warning,
                    },
                  },
                }}
                size="small"
              />
            ) : (
              '—'
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableIssues;
