import { handleActions, combineActions } from 'redux-actions';
import { fetchN1ItemsAction } from './actions';

const initialState: N1.Root = {
  items: {} as N1.Root['items'],
  itemsCount: 0,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchN1ItemsAction).toString()]: {
    next: (
      state: N1.Root,
      action: Shared.ReduxAction<Partial<N1.Root> & { skipStoreUpdate: true | undefined }>
    ): N1.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export { fetchN1ItemsAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
