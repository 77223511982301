import moment from 'moment';
import { useSelector } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { PaginationType } from 'constants/index';

export default function useTableData() {
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.EMPLOYEES));

  const year = filters?.year || 2022;
  const daysFromPreviousYear = (new Date(year, 0, 1).getDay() || 7) - 1; // 0 === Sunday
  const isLeapYear = year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  const daysInAYear = isLeapYear ? 366 : 365;
  const weeksAmount = 53;
  const currentYear = new Date().getFullYear();
  const currentDayNumber = moment().dayOfYear();
  const currentWeekNumber = Math.ceil((currentDayNumber + daysFromPreviousYear) / 7);

  return {
    filters,
    year,
    daysInAYear,
    weeksAmount,
    daysFromPreviousYear,
    currentDayNumber,
    currentYear,
    isLeapYear,
    currentWeekNumber,
  };
}
