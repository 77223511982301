import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchYearsRangeAction } from 'modules/flexibility';
import { flexibilityPlanYearsRangeSelector } from 'modules/flexibility/selectors';
import { FlexibilityPlanTable } from 'components/_tables';
import { DataTableTemplate, DataTableHeaderFilters, Spinner } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { DataTableFilterKeys, PaginationType, PermissionsTypes } from 'constants/index';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';

const PageFlexibility: React.FC = () => {
  const type = PaginationType.FLEXIBILITY_PLAN;
  const dispatch: Shared.CustomDispatch = useDispatch();
  const flexibilityPlanYearsRange = useSelector(flexibilityPlanYearsRangeSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(!flexibilityPlanYearsRange));

  useEffect(() => {
    if (flexibilityPlanYearsRange || !portfolioId || !scenarioId) return;
    dispatch(fetchYearsRangeAction({ portfolioId, scenarioId })).finally(() => setIsLoading(false));
  }, [flexibilityPlanYearsRange, portfolioId, scenarioId, dispatch]);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isFlexibilityEnabled}>
      <DataTableTemplate paddingBottom>
        <Row>
          <DataTableHeaderFilters
            paginationType={type}
            showKeys={[DataTableFilterKeys.YEAR, DataTableFilterKeys.ASSET_CATEGORY_CODE]}
            yearFilterConfig={{ range: flexibilityPlanYearsRange || [], isLoading }}
          />
        </Row>
        {!isLoading ? <FlexibilityPlanTable /> : <Spinner isInFullHeightContainer />}
      </DataTableTemplate>
    </PagePermissionsProvider>
  );
};

export default PageFlexibility;
