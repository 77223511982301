import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchRepeatedTasksAction, deleteRepeatedTaskAction } from 'modules/tasks';
import { repeatedTasksSplitBySectionHashSelectorFactory } from 'modules/tasks/selectors';
import { setRepeatedTaskModalIdAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { Button, DataTable, OverlayTriggerTooltip } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconAlertTriangle, IconDelete } from '@utiligize/shared/resources';

interface Props {
  departmentNumber: number;
}

const RepeatedTasksTable: React.FC<Props> = ({ departmentNumber }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const repeatedTasksSplitBySectionHash = useSelector(repeatedTasksSplitBySectionHashSelectorFactory(departmentNumber));
  const keys = Object.keys(repeatedTasksSplitBySectionHash);

  const sendRequest = useCallback(() => dispatch(fetchRepeatedTasksAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      dispatch(setRepeatedTaskModalIdAction(Number(id)));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteRepeatedTaskAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={PaginationType.REPEATED_TASKS} totalAmount={keys.length} sendRequest={sendRequest}>
      {keys?.map((key: string) => {
        const repeatedTasks: Tasks.RepeatedTask[] = repeatedTasksSplitBySectionHash[key];
        return (
          <React.Fragment key={key}>
            <tr>
              <td colSpan={7}>
                <b>
                  {getIntl('Section')} - {key}
                </b>
              </td>
            </tr>
            {repeatedTasks.map((item: Tasks.RepeatedTask) => (
              <tr key={item.id} className={classNames({ 'text-red': Boolean(item.deleted) })}>
                <td>
                  {item.deleted ? (
                    <OverlayTriggerTooltip
                      trigger={['hover', 'focus']}
                      overlayId={String(item.id)}
                      overlayChildren={getIntl("This task doesn't exist on external resource")}
                    >
                      <span>
                        <IconAlertTriangle width={20} height={20} fill="red" /> {item.taskNumber}
                      </span>
                    </OverlayTriggerTooltip>
                  ) : (
                    item.taskNumber
                  )}
                </td>
                <td className="text-nowrap">{getIntl(item.taskStatus)}</td>
                <td>{item.description}</td>
                <td>{item.projectNumber || '-'}</td>
                <td>{getIntl(item.actionInApp)}</td>
                <td className="text-right">
                  {!item.deleted && (
                    <Button
                      tooltipKey="Edit"
                      icon={<IconEdit />}
                      data-id={item.id}
                      onClick={handleEditBtnClick}
                      size="small"
                      variant="primary"
                    />
                  )}
                  <Button
                    marginLeft
                    tooltipKey="Delete"
                    icon={<IconDelete />}
                    data-id={item.id}
                    onClick={handleDeleteBtnClick}
                    size="small"
                    variant="primary"
                  />
                </td>
              </tr>
            ))}
          </React.Fragment>
        );
      })}
    </DataTable>
  );
};

export default RepeatedTasksTable;
