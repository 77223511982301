import styled, { createGlobalStyle, css } from 'styled-components';
import React from 'react';
import IconsMap, { IconType } from '../IconsMap';

interface PopupIconProps {
  color?: string;
  icon?: IconType;
  type?: string;
}

export const PopupIcon: React.FC<PopupIconProps> = ({ color, icon, type }) => (
  <StyledPopupIcon>
    {icon && <img className="icon-img" src={IconsMap[icon] ?? IconsMap['icon-bolt-gray']} alt="" />}
    {color && type === 'line' && <div className="icon-line" style={{ backgroundColor: color }} />}
    {color && type !== 'line' && <div className="icon-circle" style={{ backgroundColor: color }} />}
  </StyledPopupIcon>
);

interface Props {
  data: Map.PopupData;
  // Note. We need these methods since Popup renders outside of react-redux <Provider>
  // Uncaught Error: could not find react-redux context value; please ensure the component is wrapped in a <Provider>
  getIntl: (key: string, options?: any) => string;
  formatProp: (key: string, options?: Map.FormatPropOptions) => any;
  formatValue: (key: string, value: string | number, options?: Map.FormatValueOptions) => any;
}

export const MapPopup: React.FC<Props> = ({ data, getIntl, formatProp, formatValue }) => (
  <>
    <PopupStyles />
    <Popup>
      <>
        <LayerTitle>{getIntl(data.meta.title)}</LayerTitle>
        <PopupTitle>
          {data.fields['name'] ?? <>&nbsp;</>}
          <PopupIcon type={data.meta.type} color={data.meta.color} icon={data.meta.icon as IconType} />
        </PopupTitle>
        <PopupBody>
          {Object.entries(data.fields)
            .filter(([k]) => k !== 'name')
            .map(([k, v], i, arr) => (
              <PopupOption key={k} $gap={k === 'voltage_level' && i !== arr.length - 1}>
                <PopupKey>{formatProp(k)}</PopupKey>
                <PopupValue>{formatValue(k, v)}</PopupValue>
              </PopupOption>
            ))}
          {!!data.theme && (
            <>
              {Object.keys(data.theme.fields).length > 0 && (
                <ThemeTitle>
                  {getIntl(data.theme.title)}
                  {data.theme.year && <>&nbsp;•&nbsp;{data.theme.year}</>}
                </ThemeTitle>
              )}
              {Object.entries(data.theme.fields).map(([k, v], i, arr) => (
                <PopupOption key={k} $gap={['Address'].includes(k) && i !== arr.length - 1}>
                  <PopupKey>{formatProp(k)}</PopupKey>
                  <PopupValue>{formatValue(k, v)}</PopupValue>
                </PopupOption>
              ))}
            </>
          )}
        </PopupBody>
      </>
    </Popup>
  </>
);

export const Popup = styled.div<{ $isSearch?: boolean }>`
  min-width: 120px;
  max-width: 400px;
  border-radius: 10px;
  overflow: hidden;
  user-select: none;
  cursor: default;
  display: flex;
  background-color: var(--map-popup-color);
  flex-direction: column;

  ${({ $isSearch }) =>
    $isSearch &&
    css`
      .os-content {
        margin: -5px 0;
      }
    `}

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    position: absolute;
    z-index: 2;

    .mapboxgl-popup-anchor-top & {
      border-bottom-color: var(--map-popup-color);
      transform: translateX(-50%);
      top: -16px;
      left: 50%;
    }

    .mapboxgl-popup-anchor-bottom & {
      border-top-color: var(--map-popup-color);
      transform: translateX(-50%);
      bottom: -16px;
      left: 50%;
    }

    .mapboxgl-popup-anchor-left & {
      border-right-color: var(--map-popup-color);
      transform: translateY(-50%);
      left: -16px;
      top: 50%;
    }

    .mapboxgl-popup-anchor-right & {
      border-left-color: var(--map-popup-color);
      transform: translateY(-50%);
      right: -16px;
      top: 50%;
    }
  }

  .mapboxgl-popup-anchor-top-right & {
    border-top-right-radius: 0;
  }

  .mapboxgl-popup-anchor-top-left & {
    border-top-left-radius: 0;
  }

  .mapboxgl-popup-anchor-bottom-right & {
    border-bottom-right-radius: 0;
  }

  .mapboxgl-popup-anchor-bottom-left & {
    border-bottom-left-radius: 0;
  }
`;

export const LayerTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #f6f8fb;
  margin: 15px;
  text-align: center;
`;

const PopupTitle = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: #f6f8fb;
  border-radius: 6px;
  margin: 0 15px 15px 15px;
  text-align: center;
  background-color: var(--map-active-color);
  text-align: center;
  padding: 4px 40px;
  position: relative;
`;

const ThemeTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: var(--map-background-color);
  margin: 10px;
  text-align: center;
`;

export const PopupBody = styled.div`
  margin: 0 15px 15px 15px;
  font-size: 10px;

  &:not(:last-child),
  &:empty {
    margin-bottom: 0;
  }
`;

const PopupOption = styled.div<{ $gap?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  margin-bottom: ${({ $gap }) => ($gap ? 10 : 0)}px;
`;

const PopupKey = styled.div`
  color: var(--map-background-color);
  margin-right: 20px;

  & + div {
    text-align: right;
  }
`;

const PopupValue = styled.div`
  color: #ffffff;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PopupStyles = createGlobalStyle`

	.mapboxgl-popup {
		max-width: 400px !important;
    &, * {
      pointer-events: none;
    }
	}

	.mapboxgl-popup-content {
		padding: 0;
    border-radius: 10px;
    border: 2px solid #fff;
		box-shadow: 0px 2px 2px #44366630;
		font-family: 'Poppins', sans-serif;
		font-size: 10px;

    .mapboxgl-popup-anchor-top-right & {
      border-top-right-radius: 0;
    }

    .mapboxgl-popup-anchor-top-left & {
      border-top-left-radius: 0;
    }

    .mapboxgl-popup-anchor-bottom-right & {
      border-bottom-right-radius: 0;
    }

    .mapboxgl-popup-anchor-bottom-left & {
      border-bottom-left-radius: 0;
    }

	}

  .mapboxgl-popup-tip {
    position: relative;

    .mapboxgl-popup-anchor-top & {
      top: 1px;
    }

    .mapboxgl-popup-anchor-bottom & {
      bottom: 1px;
    }

    .mapboxgl-popup-anchor-left & {
      left: 1px;
    }

    .mapboxgl-popup-anchor-right & {
      right: 1px;
    }

    .mapboxgl-popup-anchor-top-right &,
    .mapboxgl-popup-anchor-top-left &,
    .mapboxgl-popup-anchor-bottom-right &,
    .mapboxgl-popup-anchor-bottom-left & {
      border: none;
    }

  }

`;

const StyledPopupIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-img,
  .icon-circle,
  .icon-line {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
  }

  .icon-img,
  .icon-circle {
    width: 14px;
    height: 14px;
  }

  .icon-circle {
    border-radius: 50%;
    border: 1px solid #fff;
  }

  .icon-line {
    border: 1px solid #fff;
    border-radius: 6px;
    height: 6px;
    width: 14px;
  }
`;

export default MapPopup;
