import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { isDepreciationsEditableSelector } from 'modules/layouts/selectors';
import { budgetingParametersCountSelector, budgetingParametersSelector } from 'modules/budgeting/selectors';
import { fetchBudgetingParametersAction, updateBudgetingParametersItemAction } from 'modules/budgeting';
import { DataTable, FormInput } from 'components/_common';
import { PaginationType } from 'constants/index';

const BudgetingParametersTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(budgetingParametersSelector);
  const count = useSelector(budgetingParametersCountSelector);
  const isDepreciationsEditable = useSelector(isDepreciationsEditableSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchBudgetingParametersAction(params)).then(
        (action: Shared.ReduxAction<{ budgetingParameters: Budgeting.Parameter[] }>) =>
          action.payload?.budgetingParameters || []
      ),
    [dispatch]
  );

  return (
    <DataTable
      maxHeight="calc(100vh - 234px)"
      paginationType={PaginationType.BUDGETING_PARAMETERS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
    >
      {items?.map?.((item: Budgeting.Parameter, index: number) => (
        <BudgetingParametersTableItem
          key={item.id}
          {...item}
          isDepreciationsEditable={isDepreciationsEditable}
          index={index}
        />
      ))}
    </DataTable>
  );
};

interface Props extends Budgeting.Parameter {
  isDepreciationsEditable: boolean;
  index: number;
}

const BudgetingParametersTableItem: React.FC<Props> = ({
  id,
  year,
  meters,
  stations,
  kwh,
  depreciations,
  opex,
  revenue,
  gridlossCost,
  physicalGridLoss,
  unitPriceGridLoss,
  isDepreciationsEditable,
  index,
}) => {
  const { numberFormat } = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<Omit<Budgeting.Parameter, 'id' | 'year'>>({
    meters,
    stations,
    kwh,
    depreciations,
    opex,
    revenue,
    gridlossCost,
    physicalGridLoss,
    unitPriceGridLoss,
  });
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const nextValue = value !== '' ? Number(value) : null;
    setState(state => ({ ...state, [name]: nextValue }));
  }, []);

  const handleInputBlur = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (
        state.meters === meters &&
        state.stations === stations &&
        state.kwh === kwh &&
        state.depreciations === depreciations &&
        state.opex === opex &&
        state.revenue === revenue &&
        state.gridlossCost === gridlossCost &&
        state.physicalGridLoss === physicalGridLoss &&
        state.unitPriceGridLoss === unitPriceGridLoss
      )
        return;
      setLoading(true);
      dispatch(updateBudgetingParametersItemAction(id, state)).finally(() => setLoading(false));
    },
    [
      dispatch,
      id,
      state,
      meters,
      stations,
      kwh,
      depreciations,
      opex,
      revenue,
      gridlossCost,
      physicalGridLoss,
      unitPriceGridLoss,
    ]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      const rowIndex: number = Number(e.currentTarget.getAttribute('data-row-index'));
      const cellIndex: number = Number(e.currentTarget.getAttribute('data-cell-index'));
      const element: any = document.querySelector(`[data-row-index='${rowIndex + 1}'][data-cell-index='${cellIndex}']`);
      if (element) {
        element?.focus();
      } else {
        handleInputBlur();
      }
    },
    [handleInputBlur]
  );

  return (
    <tr>
      <td>{year}</td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={1}
          type="number"
          disabled={loading}
          size="sm"
          name="meters"
          value={state.meters === null ? '' : state.meters}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={2}
          type="number"
          disabled={loading}
          size="sm"
          name="stations"
          value={state.stations === null ? '' : state.stations}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={3}
          type="number"
          disabled={loading}
          size="sm"
          name="kwh"
          value={state.kwh === null ? '' : state.kwh}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        {isDepreciationsEditable ? (
          <FormInput
            data-row-index={index}
            data-cell-index={4}
            type="number"
            disabled={loading}
            size="sm"
            name="depreciations"
            value={state.depreciations === null ? '' : state.depreciations}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyPress={handleKeyPress}
          />
        ) : (
          numberFormat(state.depreciations, { fallback: '-' })
        )}
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={5}
          type="number"
          disabled={loading}
          size="sm"
          name="opex"
          value={state.opex === null ? '' : state.opex}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={6}
          type="number"
          disabled={loading}
          size="sm"
          name="revenue"
          value={state.revenue === null ? '' : state.revenue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={7}
          type="number"
          disabled={loading}
          size="sm"
          name="gridlossCost"
          value={state.gridlossCost === null ? '' : state.gridlossCost}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={8}
          type="number"
          disabled={loading}
          size="sm"
          name="physicalGridLoss"
          value={state.physicalGridLoss === null ? '' : state.physicalGridLoss}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={index}
          data-cell-index={9}
          type="number"
          disabled={loading}
          size="sm"
          name="unitPriceGridLoss"
          value={state.unitPriceGridLoss === null ? '' : state.unitPriceGridLoss}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
    </tr>
  );
};

export default BudgetingParametersTable;
