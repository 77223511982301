export enum NodeEnvs {
  DEVELOP = 'develop',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const isDevelopment: boolean = [NodeEnvs.DEVELOP, NodeEnvs.STAGING].includes(
  process.env.REACT_APP_NODE_ENV! as NodeEnvs
);

export const isDevelop: boolean = process.env.REACT_APP_NODE_ENV === NodeEnvs.DEVELOP;

export const isProduction: boolean = process.env.REACT_APP_NODE_ENV === NodeEnvs.PRODUCTION;

export const MaxMobileWidth = 1400;
