import React from 'react';

const ChartDefs: React.FC = () => (
  <defs>
    <marker id="two_circles" markerWidth="50" markerHeight="50" refX="8" refY="10" orient="auto">
      <circle cx="6" cy="10" r="5" style={{ strokeWidth: 1.5, fillOpacity: 0.5 }}></circle>
      <circle cx="12" cy="10" r="5" style={{ strokeWidth: 1.5, fillOpacity: 0.5 }}></circle>
    </marker>
    <marker id="switch-closed" markerWidth="20" markerHeight="20" refX="10" refY="10" orient="auto">
      <circle cx="5" cy="10" r="2"></circle>
      <circle cx="15" cy="10" r="2"></circle>
    </marker>
    <marker id="switch-open" markerWidth="20" markerHeight="20" refX="10" refY="10" orient="auto">
      <line className="background" x1="5" y1="10" x2="15" y2="10" style={{ strokeWidth: 2 }} />
      <circle cx="15" cy="10" r="2"></circle>
      <circle cx="5" cy="10" r="2"></circle>
      <line className="color" x1="3" y1="3" x2="15" y2="10"></line>
    </marker>
  </defs>
);

export default ChartDefs;
