import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { fetchCompletedActionsByUsers } from 'modules/statistics';
import { completedActionsByUsersCountSelector, completedActionsByUsersSelector } from 'modules/statistics/selectors';
import { IconDetails } from '@utiligize/shared/resources';
import { DataTable, Button } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';

const MaintenanceStatisticsUsersTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(completedActionsByUsersCountSelector);
  const items = useSelector(completedActionsByUsersSelector);

  const sendRequest = useCallback(() => dispatch(fetchCompletedActionsByUsers()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_STATISTICS_USERS}
      sendRequest={sendRequest}
      totalAmount={count}
      maxHeight="calc(100vh - 285px)"
    >
      {items.map(item => (
        <tr key={item.id}>
          <td>{item.fullName}</td>
          <td>{item.email}</td>
          <td>{item.logMaintenance}</td>
          <td>{item.repair}</td>
          <td>{item.inspection}</td>
          <td>{item.finalCheck}</td>
          <td>{item.taskRelatedForm}</td>
          <td className="text-right">
            {Boolean(
              item.logMaintenance || item.repair || item.inspection || item.finalCheck || item.taskRelatedForm
            ) && (
              <Button
                tooltipKey="Details"
                icon={<IconDetails />}
                data-id={item.id}
                linkProps={{
                  to: generatePath(Routes.MaintenanceStatisticsUsers, { id: item.id }),
                }}
                size="small"
                variant="primary"
              />
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default MaintenanceStatisticsUsersTable;
