export const layersState: Map.LayersState = {
  sources: [],
  layers: [],
};

export const ColorThemes: Map.ColorThemes = {
  background: { Standard: '#FFE000', Responsive: '#a84a73', Monochrome: '#5eaffe' },
  list: {
    5: {
      default: [
        { Standard: '#529E37', Responsive: '#4437a0', Monochrome: '#b1d6ef' },
        { Standard: '#B5CE0B', Responsive: '#8933b7', Monochrome: '#87c7ff' },
        { Standard: '#FFE000', Responsive: '#c256a2', Monochrome: '#5eaffe' },
        { Standard: '#F79F2B', Responsive: '#e6827e', Monochrome: '#3496fb' },
        { Standard: '#D03526', Responsive: '#fbd64e', Monochrome: '#007bfe' },
      ],
      reversed: [
        { Standard: '#D03526', Responsive: '#fbd64e', Monochrome: '#007bfe' },
        { Standard: '#F79F2B', Responsive: '#e6827e', Monochrome: '#3496fb' },
        { Standard: '#FFE000', Responsive: '#c256a2', Monochrome: '#5eaffe' },
        { Standard: '#B5CE0B', Responsive: '#8933b7', Monochrome: '#87c7ff' },
        { Standard: '#529E37', Responsive: '#4437a0', Monochrome: '#b1d6ef' },
      ],
      centered: [
        { Standard: '#D03526', Responsive: '#fbd64e', Monochrome: '#007bfe' },
        { Standard: '#FFE000', Responsive: '#c256a2', Monochrome: '#5eaffe' },
        { Standard: '#529E37', Responsive: '#4437a0', Monochrome: '#b1d6ef' },
        { Standard: '#FFE000', Responsive: '#c256a2', Monochrome: '#5eaffe' },
        { Standard: '#D03526', Responsive: '#fbd64e', Monochrome: '#007bfe' },
      ],
    },
    6: {
      default: [
        { Standard: '#529E37', Responsive: '#4437a0', Monochrome: '#b1d6ef' },
        { Standard: '#ADCA0E', Responsive: '#7f2cb4', Monochrome: '#8fcafc' },
        { Standard: '#E8DB04', Responsive: '#c155a5', Monochrome: '#6fb9ff' },
        { Standard: '#FBC115', Responsive: '#da6a76', Monochrome: '#4da5fd' },
        { Standard: '#EC8129', Responsive: '#fab45e', Monochrome: '#2a91fc' },
        { Standard: '#D03526', Responsive: '#fbd64e', Monochrome: '#007bfe' },
      ],
      reversed: [
        { Standard: '#D03526', Responsive: '#fbd64e', Monochrome: '#007bfe' },
        { Standard: '#EC8129', Responsive: '#fab45e', Monochrome: '#2a91fc' },
        { Standard: '#FBC115', Responsive: '#da6a76', Monochrome: '#4da5fd' },
        { Standard: '#E8DB04', Responsive: '#c155a5', Monochrome: '#6fb9ff' },
        { Standard: '#ADCA0E', Responsive: '#7f2cb4', Monochrome: '#8fcafc' },
        { Standard: '#529E37', Responsive: '#4437a0', Monochrome: '#b1d6ef' },
      ],
    },
  },
};

export enum MapThemes {
  YEARLY_MAX_LOAD = 'max_load',
  TASK = 'task',
  DATA_QUALITY = 'data_quality',
  YEARLY_CONSUMPTION = 'ec',
  YEARLY_LOSSES = 'yl',
  N1_ROUTES = 'n_1',
  N1_YEARLY_MAX_LOAD = 'n_1_ml',
  YEARLY_REPLACEMENTS = 'yr',
}

export const initLayers = [
  'asset__primary__transformers',
  'asset__secondary__transformers',
  'asset__secondary__cabinets',
  'asset__cables',
  'asset__overhead_lines',
];

export enum RouteType {
  PRIMARY = 6,
}

export const N1MaxLoadScales: Map.N1MaxLoadScaleItem[] = [
  { id: 1, name: 'percent', range: [0, 100], type: 'default' },
  { id: 2, name: 'voltage', range: [0.9, 1.1], type: 'centered' },
  { id: 5, name: 'capacity', range: [0, 10000], type: 'reversed' },
  { id: 4, name: 'capacity', range: [0, 1000], type: 'reversed' },
  { id: 3, name: 'capacity', range: [0, 250], type: 'reversed' },
];

export const ThemeGroups: Record<Map.ThemeGroup, Map.MapThemes[]> = {
  forecast_and_investment: [
    MapThemes.DATA_QUALITY,
    MapThemes.YEARLY_MAX_LOAD,
    MapThemes.YEARLY_CONSUMPTION,
    MapThemes.YEARLY_LOSSES,
    MapThemes.N1_ROUTES,
    MapThemes.N1_YEARLY_MAX_LOAD,
  ],
  maintenance_and_planning: [MapThemes.TASK],
  reinvestment: [MapThemes.YEARLY_REPLACEMENTS],
};

export const ThemeDisabledSettingsKey: Record<Map.MapThemes, keyof Map.MapSettings> = {
  [MapThemes.YEARLY_MAX_LOAD]: 'isMaxLoadThemeDisabled',
  [MapThemes.DATA_QUALITY]: 'isDataQualityThemeDisabled',
  [MapThemes.YEARLY_CONSUMPTION]: 'isConsumptionThemeDisabled',
  [MapThemes.YEARLY_LOSSES]: 'isLossesThemeDisabled',
  [MapThemes.N1_ROUTES]: 'isN1ThemeDisabled',
  [MapThemes.N1_YEARLY_MAX_LOAD]: 'isN1MaxLoadThemeDisabled',
  [MapThemes.TASK]: 'isTasksThemeDisabled',
  [MapThemes.YEARLY_REPLACEMENTS]: 'isReplacementsThemeDisabled',
};
