import React from 'react';
import { useLocale } from 'hooks';
import { Tabs, Tab, ChartIframe, RecalculationSpinner, ContentContainer } from 'components/_common';
import { Routes } from 'constants/index';

interface Props {
  loading: boolean;
  priceType: Type.PriceTypes;
}

const BudgetingRevenueCapTabs: React.FC<Props> = ({ loading, priceType }) => {
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter>
      <Tab eventKey={Routes.BudgetingRevenueCap} title={getIntl('Financing deficit')}>
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of revenue cap financing deficit" src="revenue_cap_financing_deficit" />
          </ContentContainer>
        )}
      </Tab>
      <Tab
        eventKey={`${Routes.BudgetingRevenueCap}${Routes.HASH.MaximumCollection}`}
        title={getIntl('Maximum collection')}
      >
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of revenue cap maximum collection" src="revenue_cap_maximum_collection" />
          </ContentContainer>
        )}
      </Tab>
      <Tab
        eventKey={`${Routes.BudgetingRevenueCap}${Routes.HASH.SustainedInefficiency}`}
        title={getIntl('Sustained inefficiency')}
      >
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of revenue cap sustained inefficiency" src="revenue_cap_sustained_inefficiency" />
          </ContentContainer>
        )}
      </Tab>
      <Tab eventKey={`${Routes.BudgetingRevenueCap}${Routes.HASH.Explainer}`} title={getIntl('Explainer')}>
        {loading ? (
          <RecalculationSpinner />
        ) : (
          <ContentContainer>
            <ChartIframe title="Plot of revenue cap explainer" src={`revenue_cap?price_type=${priceType}`} />
          </ContentContainer>
        )}
      </Tab>
    </Tabs>
  );
};

export default BudgetingRevenueCapTabs;
