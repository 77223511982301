import React from 'react';
import { Switch, Route } from 'react-router';
import { Interventions, InterventionsAnalyzeResults, InterventionsSensitivityAnalysis } from 'components';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageReinvestmentInterventions: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isInterventionsEnabled}>
    <Switch>
      <Route exact path={Routes.Interventions} component={Interventions} />
      <Route exact path={Routes.InterventionsAnalyzeResults} component={InterventionsAnalyzeResults} />
      <Route exact path={Routes.InterventionsSensitivityAnalysis} component={InterventionsSensitivityAnalysis} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageReinvestmentInterventions;
