import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MapContext } from 'context/map';
import { mapStateThemeSelector } from 'modules/map/selectors';
import { MapThemes } from 'constants/map';
import Assets from 'components/Map/core/themes/Assets';
import Other from 'components/Map/core/themes/Other';
import MaxLoad from 'components/Map/core/themes/MaxLoad';
import DataQuality from 'components/Map/core/themes/DataQuality';
import TasksSetupLayers from 'components/Map/_tasks/TasksSetupLayers';
import Consumption from 'components/Map/core/themes/Consumption';
import Losses from 'components/Map/core/themes/Losses';
import N1 from 'components/Map/core/themes/N1';
import N1MaxLoad from 'components/Map/core/themes/N1MaxLoad';
import Replacements from 'components/Map/core/themes/Replacements';

const SetupThemes: React.FC = () => {
  const { map } = useContext(MapContext);
  const theme = useSelector(mapStateThemeSelector);

  if (!map) return null;
  return (
    <>
      {!theme && <Assets map={map} />}
      <Other map={map} />
      {theme === MapThemes.YEARLY_MAX_LOAD && <MaxLoad map={map} />}
      {theme === MapThemes.DATA_QUALITY && <DataQuality map={map} />}
      {theme === MapThemes.YEARLY_CONSUMPTION && <Consumption map={map} />}
      {theme === MapThemes.YEARLY_LOSSES && <Losses map={map} />}
      {theme === MapThemes.N1_ROUTES && <N1 map={map} />}
      {theme === MapThemes.N1_YEARLY_MAX_LOAD && <N1MaxLoad map={map} />}
      {theme === MapThemes.YEARLY_REPLACEMENTS && <Replacements map={map} />}
      {theme === MapThemes.TASK && <TasksSetupLayers map={map} />}
    </>
  );
};

export default SetupThemes;
