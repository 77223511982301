import moment from 'moment';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateAssetAction } from 'modules/assets';
import { _pick } from '@utiligize/shared/utils';
import { AssetCategories } from 'constants/index';

const baseAssetCodeKeys: (keyof Asset.Item)[] = ['id', 'comments', 'dateDeployed', 'risk', 'replacementDate', 'models'];

const AssetsFields: Type.Hash<(keyof Asset.Item)[]> = {
  [AssetCategories.TH]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
  ],
  [AssetCategories.TR]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'switchGroup',
    'shortCircuitImpedanceEk',
    'kva',
    'manufacturingNumber',
    'dateManufactured',
    'circuitBreakerManufacturer',
    'lowVoltageFuse',
    'lowVoltageNominalCurrent',
  ],
  [AssetCategories.MV]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'serialNumber',
  ],
  [AssetCategories.LV]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'serialNumber',
  ],
  [AssetCategories.RT]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'serialNumber',
  ],
  [AssetCategories.KS]: [
    'type',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'monthsBetweenInspections',
    'address',
    'serialNumber',
  ],
  [AssetCategories.BD]: [
    'name',
    'elInTablID',
    'nextInspectionDate',
    'dateInspected',
    ...baseAssetCodeKeys,
    'dateManufactured',
    'monthsBetweenInspectionsCH',
    'serialNumber',
    'meteringPoint',
  ],
  [AssetCategories.TS]: [
    ...baseAssetCodeKeys,
    'dateManufactured',
    'address',
    'town',
    'phoneNumber',
    'serialNumber',
    'installation',
    'controlledByCab',
    'multiGuardCab',
    'inspectionDate',
  ],
  [AssetCategories.SS]: [
    'controlUnit',
    'road',
    'houseNumber',
    'postcode',
    'dateDeployed',
    'inspectionDate',
    'risk',
    'serialNumber',
    'comments',
    'models',
  ],
  [AssetCategories.GL]: [
    'section',
    'cabinet',
    'road',
    'postcode',
    'risk',
    'replacementDate',
    'numberOfFittings',
    'fittingComments',
    'fittingDateDeployed',
    'fittingReplacementYear',
    'bulbDateDeployed',
    'bulbNextReplacementDate',
    'mastDateDeployed',
    'mastInspectionDate',
    'mastReplacementDate',
    'mastDateManufactured',
    'fittingGuard',
    'mastInsulationClass',
    'fittingManufacturer',
    'fittingSupplier',
    'fittingType',
    'fittingName',
    'fittingLocalName',
    'fittingSerialNumber',
    'fittingDateManufactured',
    'bulbDateManufactured',
    'bulbManufacturer',
    'bulbSupplier',
    'bulbType',
    'bulbPower',
    'bulbLifetime',
    'bulbLosses',
    'bulbLumens',
    'bulbKelvin',
    'bulbSerialNumber',
    'mastDimensions',
    'mastHeight',
    'mastSupplier',
    'mastArea',
    'mastRiskGroup',
    'mastSafeguards',
    'mastType',
    'phoneNumber',
    'models',
  ],
};

const DateTypeFields = [
  'dateDeployed',
  'dateInspected',
  'inspectionDate',
  'nextInspectionDate',
  'replacementDate',
  'dateManufactured',
  'inspectionDate',
  'fittingDateDeployed',
  'bulbDateDeployed',
  'bulbNextReplacementDate',
  'mastDateDeployed',
  'mastInspectionDate',
  'mastReplacementDate',
  'mastDateManufactured',
  'fittingDateManufactured',
  'bulbDateManufactured',
];

const useConfiguredFormik = (assetMeta: Asset.ExtendedItem) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Asset.Item>({
    initialValues: Object.keys(_pick(assetMeta, AssetsFields[assetMeta?.category?.code])).reduce(
      (acc: any, key: string) => {
        if (!assetMeta.hasOwnProperty(key)) return acc;
        const value = (assetMeta as any)?.[key];
        if (DateTypeFields.includes(key)) {
          acc[key] = value && moment(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    ),

    onSubmit: (values, { resetForm }) => {
      const formattedValues = Object.keys(values).reduce((acc: Asset.Item, key: string) => {
        const value = (values as any)?.[key];
        if (DateTypeFields.includes(key)) {
          (acc as any)[key] = value && value.set('hour', 12).format();
        } else {
          (acc as any)[key] = value;
        }
        return acc;
      }, {} as Asset.Item);

      return dispatch(updateAssetAction({ ...assetMeta, ...formattedValues })).then(() => resetForm({ values }));
    },
  });

  return formik;
};

export default useConfiguredFormik;
