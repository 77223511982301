import React from 'react';
import { InspectionsModal } from 'components/_modals';
import { InspectionsTable } from 'components/_tables';
import { DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageInspections: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isFormsEnabled}>
    <DataTableTemplate paddingBottom>
      <InspectionsModal />
      <InspectionsTable />
    </DataTableTemplate>
  </PagePermissionsProvider>
);

export default PageInspections;
