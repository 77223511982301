import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

export interface DataTableTemplateProps {
  title?: string;
  titleKey?: string;
  titleComponent?: React.ReactNode;
  children: React.ReactNode[];
  paddingBottom?: boolean;
  className?: string;
  getIntl?: (localeKey: string, options?: {}) => string;
}

const DataTableTemplate: React.FC<DataTableTemplateProps> = ({
  title,
  titleKey,
  titleComponent,
  children,
  paddingBottom = false,
  className = '',
  getIntl = str => str,
}) => {
  const [header, body] = React.Children.toArray(children);

  return (
    <>
      <StyledSection className={className} $paddingBottom={paddingBottom}>
        {(title || titleKey || titleComponent) && (
          <Row className={classNames({ 'mb-3': Boolean((header as any).type !== React.Fragment) })}>
            {titleComponent || (
              <Col sm={8}>
                <h1>{title || (titleKey && getIntl(titleKey))}</h1>
              </Col>
            )}
          </Row>
        )}
        {header}
      </StyledSection>
      {body}
    </>
  );
};

const StyledSection = styled.section<{ $paddingBottom: boolean }>`
  padding: ${props => props.theme.paddings.primary};
  background: ${props => props.theme.colors.white};
  ${props => (props.$paddingBottom ? '' : 'padding-bottom: 0;')}

  h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`;

export default DataTableTemplate;
