import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createDERsSectionCategoryAction, updateDERsSectionCategoryAction } from 'modules/newLoad';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.CreateNewLoadSectionCategoryActionProps>({
    initialValues: {
      section: '',
      category: '',
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      section: Yup.string().required("Field can't be empty!"),
      category: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      return (
        id
          ? dispatch(updateDERsSectionCategoryAction({ ...values, id }))
          : dispatch(createDERsSectionCategoryAction({ ...values }))
      )
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
