import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import { scenarioSelectedOptionSelector } from 'modules/options/selectors';
import {
  createMapStateSelectorFactory,
  enabledLayersListSelector,
  settingsSelector,
  showCableAnimationSelector,
} from 'modules/map/selectors';
import { showTheme, hideTheme, setMapSourceField } from 'utils/map';
import { updateScenarioLayer } from 'components/Map/core/layers/load';
import { N1MaxLoadScales } from 'constants/map';

interface Props {
  map: Map.MapboxMap;
}

const MaxLoad: React.FC<Props> = ({ map }) => {
  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const selectedScenario = useSelector(scenarioSelectedOptionSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const scenario = useSelector(createMapStateSelectorFactory('n1MaxLoadScenario'));
  const type = useSelector(createMapStateSelectorFactory('n1MaxLoadValueType'));
  const enabledLayersList = useSelector(enabledLayersListSelector);

  useEffect(() => {
    setMapSourceField(map, settings.n1MaxLoadLayers!, {
      scenario_id: String(selectedScenario?.value),
    });
  }, [map, selectedScenario]); // eslint-disable-line

  useEffect(() => {
    if (!scenario) return;
    const layers = settings.n1MaxLoadLayers!;
    const prefix = type[0];
    const property = `${prefix}_${scenario.key}`;
    const scales = N1MaxLoadScales.filter(i => i.name === type);
    const scaleType = scales[0].type;
    const scaleRanges = scales.map(i => i.range);
    updateScenarioLayer(map, property, layers, selectedMapTheme, scaleType, scaleRanges);
  }, [map, scenario, type, selectedMapTheme]); // eslint-disable-line

  // show and update all layers when theme is active
  useEffect(() => {
    showTheme(map, settings.n1MaxLoadLayers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  // hide all theme layers when theme is inactive
  useEffect(() => {
    return () => {
      hideTheme(map, settings.n1MaxLoadLayers!);
    };
  }, [map]); // eslint-disable-line

  return null;
};

export default React.memo(MaxLoad);
