import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import { useLocale } from 'hooks';
import { TaskTypes } from 'constants/index';
import useTableData from './useTableData';
import TaskOverlay from './TaskOverlay';

interface Props {
  task: Tasks.Task;
  firstCell: React.ReactNode;
  secondCell: React.ReactNode;
}

const TableRow: React.FC<Props> = ({ task, firstCell, secondCell }) => {
  const { getIntl, dateFormat } = useLocale();

  const { year, daysInAYear, weeksAmount, daysFromPreviousYear, currentDayNumber, currentYear } = useTableData();

  const startDayNumber = task.startDate
    ? moment(task.startDate).year() < year
      ? 1
      : moment(task.startDate).dayOfYear()
    : 0;
  const endDayNumber = task.endDate
    ? moment(task.endDate).year() > year
      ? daysInAYear + 1
      : moment(task.endDate).dayOfYear() + 1
    : 0;
  const taskDaysAmount = endDayNumber - startDayNumber;

  let skipColumns: number | null = null;

  return (
    <tr>
      {firstCell}
      {secondCell}
      {task.type === TaskTypes.Autogenerated ? (
        <td className="sticky third-fourth" colSpan={2}>
          {task.taskMonths?.map(month => getIntl(month)).join(', ')}
        </td>
      ) : (
        <>
          <td className="sticky third">{task.startDate ? dateFormat(task.startDate) : '-'}</td>
          <td className="sticky fourth">{task.endDate ? dateFormat(task.endDate) : '-'}</td>
        </>
      )}

      {Array.from(Array(weeksAmount * 7).keys()).map((item: number) => {
        const dayNumber = item + 1 - daysFromPreviousYear;

        if (task.type === TaskTypes.Autogenerated && task.taskMonthsNumbers?.[dayNumber]) {
          skipColumns = dayNumber + task.taskMonthsNumbers[dayNumber] - 1;
          return (
            <td key={item} colSpan={task.taskMonthsNumbers[dayNumber]} className="day">
              <TaskOverlay {...task} />
            </td>
          );
        } else if (startDayNumber && startDayNumber === dayNumber) {
          return (
            <td key={item} colSpan={taskDaysAmount} className="day">
              <TaskOverlay {...task} />
            </td>
          );
        } else if (startDayNumber < dayNumber && endDayNumber > dayNumber) return null;

        if (task.type === TaskTypes.Autogenerated && typeof skipColumns === 'number' && dayNumber <= skipColumns) {
          return null;
        }

        return (
          <td
            key={item}
            className={classNames({
              'bg-light-gray': dayNumber <= 0 || dayNumber > daysInAYear,
              'bg-success': currentDayNumber === dayNumber && currentYear === year,
            })}
          />
        );
      })}
    </tr>
  );
};

export default TableRow;
