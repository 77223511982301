import React, { useCallback } from 'react';
import { ButtonGroup, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useToggle, useLocale, useEventPreventedExec } from 'hooks';
import { Button, FormInput, Modal, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { _cloneDeep } from '@utiligize/shared/utils';
import { BuilderFieldsTypes } from 'constants/index';
import { IconPlus, IconChevronDown, IconChevronUp, IconClose, IconEyeDropper } from '@utiligize/shared/resources';

const palette = [
  '#58FA90',
  '#8AFA58',
  '#BCFA58',
  '#E1FA58',
  '#FAF458',
  '#FAD558',
  '#FFA700',
  '#FF8900',
  '#FF6200',
  '#FF2C2C',
];

const SingleSelectModal: React.FC<Builder.ModalProps> = ({
  type,
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const { getIntl } = useLocale();
  const isEditMode: boolean = Boolean(formField);
  const [show, toggleModal] = useToggle(true);
  const formik = useConfiguredFormik({ type, formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue } = formik;
  const isInspectionQuestion = type === BuilderFieldsTypes.InspectionQuestion;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleAddVariantButtonClick = useCallback(() => {
    setFieldValue('variants', values.variants.concat({ index: Date.now(), value: '', color: '#ffffff', field: [] }));
  }, [values.variants, setFieldValue]);

  const handleDeleteVariantButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const index: number = Number(event.currentTarget.getAttribute('data-index'));
      const nextVariants = values.variants.filter((variant: Builder.Variant) => variant.index !== index);
      setFieldValue('variants', nextVariants);
    },
    [values.variants, setFieldValue]
  );

  const handlePaletteButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const index = Number(event.currentTarget.getAttribute('data-index'));
      const color = event.currentTarget.getAttribute('data-color');
      if (!color) return;
      const nextVariants = _cloneDeep(values.variants);
      nextVariants[index].color = color;
      setFieldValue('variants', nextVariants);
      document.body.click(); // close popover
    },
    [values.variants, setFieldValue]
  );

  const onTop = useCallback(
    (event: React.SyntheticEvent) => {
      const index = Number(event.currentTarget.getAttribute('data-index'));
      const nextVariants = _cloneDeep(values.variants);
      const element = nextVariants[index];
      nextVariants.splice(index, 1);
      nextVariants.splice(index - 1, 0, element);
      setFieldValue('variants', nextVariants);
    },
    [values.variants, setFieldValue]
  );

  const onBottom = useCallback(
    (event: React.SyntheticEvent) => {
      const index = Number(event.currentTarget.getAttribute('data-index'));
      const nextVariants = _cloneDeep(values.variants);
      const element = nextVariants[index];
      nextVariants.splice(index, 1);
      nextVariants.splice(index + 1, 0, element);
      setFieldValue('variants', nextVariants);
    },
    [values.variants, setFieldValue]
  );

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={type}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group className="mb-4">
          <FormInput
            autoFocus
            labelKey="Label (shown to user)"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
            isNew
            size="lg"
          />
        </Form.Group>
        {values.variants?.map((variant: Builder.Variant, index: number) => (
          <div className="d-flex align-items-start mb-3" key={variant.index}>
            <ButtonGroup vertical className="mr-3" style={{ position: 'relative', top: '-3.5px' }}>
              <Button
                type="button"
                size="small"
                icon={<IconChevronUp />}
                data-index={index}
                onClick={onTop}
                disabled={!index}
              />
              <Button
                type="button"
                size="small"
                icon={<IconChevronDown />}
                data-index={index}
                onClick={onBottom}
                disabled={index === values.variants.length - 1}
              />
            </ButtonGroup>
            <div className="flex-grow-1">
              <FormInput
                autoFocus={values.variants.length > 1 && values.variants.length - 1 === index}
                name={`variants[${index}].value`}
                value={variant.value}
                onChange={handleChange}
                onBlur={handleBlur}
                errorKey={
                  Boolean(touched.variants?.[index]?.value && (errors.variants as any)?.[index]?.value)
                    ? ((errors.variants as any)?.[index]?.value as string)
                    : ''
                }
                isNew
                size="lg"
              />
              {isInspectionQuestion && (
                <FormInput
                  className="mt-3"
                  as="textarea"
                  rows={2}
                  name={`variants[${index}].explanation`}
                  value={variant.explanation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isNew
                  size="lg"
                />
              )}
            </div>
            <OverlayTrigger
              trigger="click"
              rootClose
              overlay={
                <Popover id={String(variant.index)}>
                  <Popover.Title as="h4">{getIntl('Please choose color')}</Popover.Title>
                  <Popover.Content className="d-flex flex-wrap justify-content-between">
                    {palette.map((color: string) => (
                      <button
                        key={color}
                        type="button"
                        className="btn mb-1"
                        style={{ width: '44px', height: '44px', backgroundColor: color }}
                        data-index={index}
                        data-color={color}
                        onClick={handlePaletteButtonClick}
                      />
                    ))}
                  </Popover.Content>
                </Popover>
              }
            >
              <Button
                icon={<IconEyeDropper />}
                type="button"
                className="ml-2"
                style={{ backgroundColor: variant.color }}
                size="large"
              />
            </OverlayTrigger>
            <Button
              icon={<IconClose />}
              type="button"
              className="ml-2"
              data-index={variant.index}
              onClick={handleDeleteVariantButtonClick}
              disabled={values.variants.length === 1}
              size="large"
            />
          </div>
        ))}
        <div className="text-right">
          <Button type="button" icon={<IconPlus />} labelKey="Add Variant" onClick={handleAddVariantButtonClick} />
        </div>
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default SingleSelectModal;
