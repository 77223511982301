import React, { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { BuilderField, BuilderFieldProps } from 'components/_builder';
import { Checkbox } from 'components/_common';
import TwoOptionsChildField from './TwoOptionsChildField';
import AddChildFieldButton from './AddChildFieldButton';
import { _cloneDeep } from '@utiligize/shared/utils';
import { transformFormField } from 'utils';
import { TwoOptionDefaultIndexes, FIELDS, MAX_FIELDS, BuilderFieldsTypes } from 'constants/index';

const BuilderTwoOptionsField: React.FC<BuilderFieldProps> = props => {
  const { field, fieldTypes, addField, changeField } = props;
  const { getIntl } = useLocale();

  const handleAddChildClick = useCallback(
    async (idx: number) => {
      const nextField: Builder.Field = _cloneDeep(field);
      const { Child1, Child2 } = TwoOptionDefaultIndexes;

      if (!nextField.params[1].variants?.[idx]?.field) {
        throw Error("BuilderTwoOptionsField nextField.params[1].variants?.[idx].field doesn't exist");
      }

      const fields = nextField.params[1].variants[idx].field!;
      if (fields?.length === MAX_FIELDS) return alert('Max fields limit reached');

      // Use incremented index in case if array isn't empty, or 1000 and 4000 as default indexes
      const index = fields?.length ? fields[fields.length - 1].index + 1 : [Child1, Child2][idx];
      const newField: Builder.Field = {
        ...FIELDS[BuilderFieldsTypes.TwoOption],
        indexBlock: nextField.indexBlock,
        index,
        id: index,
        parentId: nextField.id,
        fieldtypeId: fieldTypes.filter(type => type.name === BuilderFieldsTypes.TwoOption)[0].id,
      };
      nextField.params[1].variants[idx].field!.push(newField);

      changeField(nextField);
    },
    [changeField, field, fieldTypes]
  );

  const handleDeleteChildField = useCallback(
    async (id: number) => {
      const nextField: Builder.Field = _cloneDeep(field);
      const { Child1, Child2 } = TwoOptionDefaultIndexes;
      const childIdx = id >= Child1 && id < Child2 ? 0 : 1;
      nextField.params?.forEach(itemParam => {
        itemParam?.variants?.forEach(itemVariant => {
          itemVariant?.field?.forEach((itemField, idx) => {
            if (Number(itemField.id) === id) {
              itemVariant?.field?.splice(idx, 1);
              if (!itemVariant?.field?.length) {
                nextField.checked[childIdx] = false;
              }
            }
          });
        });
      });
      changeField(nextField);
    },
    [field, changeField]
  );

  const handleChangeChildField = useCallback(
    (changedField: Builder.Field) => {
      const nextField: Builder.Field = _cloneDeep(field);
      if (changedField.checked.includes(true)) {
        const { Child1, Child2 } = TwoOptionDefaultIndexes;
        const childIdx = changedField.index >= Child1 && changedField.index < Child2 ? 0 : 1;
        nextField.checked[childIdx] = false;
      }

      nextField?.params?.forEach(itemParam => {
        itemParam?.variants?.forEach(itemVariant => {
          itemVariant?.field?.forEach((itemField, idx) => {
            if (itemField.id === changedField.id) {
              itemVariant.field![idx] = changedField;
            }
          });
        });
      });
      changeField(nextField);
    },
    [field, changeField]
  );

  const handleCheckBoxClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.stopPropagation();
      const { index: indexField, indexBlock } = field;
      const indexName = '-' + indexBlock + '-' + indexField + '-parent';
      const { id: targetId, checked } = event.currentTarget as HTMLInputElement;
      const id = targetId.substring(0, targetId.length - indexName.length);
      const nextField: Builder.Field = _cloneDeep(field);
      nextField.params.forEach(param =>
        (param?.variants as any)?.[id]?.field?.forEach((i: any) => {
          i.checked = [false, false];
        })
      );
      nextField.checked[Number(id)] = checked;
      changeField(nextField);
    },
    [field, changeField]
  );

  const renderCheckbox = (idx: number) => {
    if (!field.params?.[1]?.variants?.[idx]?.field?.length) return null;
    const { index: indexField, indexBlock } = field;
    return (
      <Checkbox
        id={idx + '-' + indexBlock + '-' + indexField + '-parent'}
        className="ml-2 py-2 icheck-success"
        checked={field.checked[idx]}
        label={getIntl('New page for option {{idx}} children', { idx: idx + 1 })}
        onChange={handleCheckBoxClick}
      />
    );
  };

  const renderVariants = (idx: number) =>
    field.params.map(itemParam =>
      itemParam?.variants?.[idx]?.field?.map((itemField, index: number) => {
        const number = idx ? Number(itemParam.variants?.[0]?.field?.length) + index : index;
        return (
          <TwoOptionsChildField
            key={itemField.id}
            isFirstField={!index}
            field={{ ...transformFormField(itemField), indexBlock: field.indexBlock }}
            displayedNumber={`${number + 1}`}
            delField={handleDeleteChildField}
            changeField={handleChangeChildField}
            addField={addField}
          />
        );
      })
    );

  return (
    <BuilderField {...props}>
      <Row className="mx-0">
        {[0, 1].map((index: number) => {
          const optionValue = props.field.params?.[1]?.variants?.[index]?.value;
          return (
            <Col key={optionValue} className="d-flex justify-content-center align-items-center mt-2 mb-3">
              <AddChildFieldButton
                index={index}
                optionValue={optionValue as string}
                onAddChildField={handleAddChildClick}
              />
            </Col>
          );
        })}
      </Row>
      <Row className="mx-0">
        {[0, 1].map((index: number) => (
          <Col key={index}>
            {renderVariants(index)}
            {renderCheckbox(index)}
          </Col>
        ))}
      </Row>
    </BuilderField>
  );
};

export default BuilderTwoOptionsField;
