import { createAction } from 'redux-actions';
import { appLangSelector } from 'modules/app/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, plumberAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchInterventionsAction: any = createAction(
  'interventions/FETCH',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Interventions.Root, 'interventionsCount' | 'interventionsHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.INTERVENTIONS)(
        state
      );
      return plumberAPI
        .get('interventions_table', {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? '' : query,
            lang: appLangSelector(state).toLowerCase(),
            impact_year: filters?.impactYear,
            cut_off_value: filters?.maxRiskConstraint,
            intervention_type: 'All interventions',
          },
        })
        .then((res: any) => ({
          interventionsCount: res.data.count,
          interventionsHash: _keyBy(res.data.rows, (item: Interventions.Intervention) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const fetchSensitivityVariablesOptionsAction = createAction(
  'interventions/FETCH_SENSITIVITY_VARIABLES',
  async (): Promise<{ value: number; label: string }[]> => {
    return plumberAPI
      .get('sensitivity_variable')
      .then(res => res.data.map((item: { id: number; labels: string }) => ({ value: item.id, label: item.labels })));
  }
);

export const fetchOutputVariablesOptionsAction = createAction(
  'interventions/FETCH_OUTPUT_VARIABLES',
  async (): Promise<{ value: number; label: string }[]> => {
    return plumberAPI
      .get('output_variables')
      .then(res =>
        res.data.map((item: { values: number; labels: string }) => ({ value: item.values, label: item.labels }))
      );
  }
);

export const fetchRegulatoryZoneOptionsAction = createAction(
  'interventions/FETCH_REGULATORY_ZONE',
  async (): Promise<{ value: number; label: string }[]> => {
    return plumberAPI
      .get('regulatory_zone')
      .then(res => res.data.map((item: { id: number; labels: string }) => ({ value: item.id, label: item.labels })));
  }
);

export const fetchHealthIndexOptionsAction = createAction(
  'interventions/FETCH_HEALTH_INDEX',
  async (): Promise<{ value: number; label: string }[]> => {
    return plumberAPI
      .get('health_index')
      .then(res => res.data.map((item: { id: number; labels: string }) => ({ value: item.id, label: item.labels })));
  }
);

export const fetchAssetPortfolioOptionsAction = createAction(
  'interventions/FETCH_ASSET_PORTFOLIO',
  async (): Promise<{ value: number; label: string }[]> => {
    return plumberAPI
      .get('asset_portfolio')
      .then(res => res.data.map((item: { id: number; labels: string }) => ({ value: item.id, label: item.labels })));
  }
);

export const fetchAggregationLevelOptionsAction = createAction(
  'interventions/FETCH_AGGREGATION_LEVEL',
  async (): Promise<{ value: number; label: string }[]> => {
    return plumberAPI.get('aggregation_level').then(res =>
      res.data
        .filter((item: any) => item.labels) // FixMe. Server error temporary fix
        .map((item: { id: number; labels: string }) => ({ value: item.id, label: item.labels }))
    );
  }
);
