import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  settingsSelector,
  dataQualityWarningSelector,
  showCableAnimationSelector,
  enabledLayersListSelector,
  createMapStateSelectorFactory,
} from 'modules/map/selectors';
import { setDataQualityWarningAction, mapStateAction, fetchDataQualityWarningAction } from 'modules/map';
import { showTheme, hideTheme } from 'utils/map';
import { getInitMapState, parseObject } from 'utils/map';
import { filterDataQuality } from 'components/Map/core/layers/dataQuality';

interface Props {
  map: Map.MapboxMap;
}

const DataQuality: React.FC<Props> = ({ map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const warning = useSelector(dataQualityWarningSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const layerFilters = useSelector(createMapStateSelectorFactory('layerFilters'));
  const dataQualityFilters = useSelector(createMapStateSelectorFactory('dataQualityFilters'));
  const history = useHistory<Pick<DataQuality.Issue, 'issue_id' | 'code' | 'positiononthemap' | 'warning'>>();
  const enabledLayersList = useSelector(enabledLayersListSelector);

  // filter by issue name
  useEffect(() => {
    if (!dataQualityFilters) return;
    filterDataQuality(map, dataQualityFilters, warning, settings);
  }, [map, dataQualityFilters, warning]); // eslint-disable-line

  // show and update all layers when theme is active
  useEffect(() => {
    showTheme(map, settings.dataQualityLayers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  // hide all theme layers when theme is inactive
  useEffect(() => {
    return () => {
      hideTheme(map, settings.dataQualityLayers!);
      dispatch(setDataQualityWarningAction(null));
    };
  }, [map]); // eslint-disable-line

  // handle warning state from data quality table
  useEffect(() => {
    if (!history.location.state?.warning) return;
    const { warning, code, issue_id } = history.location.state;
    const { bound, layers } = parseObject(history.location.state?.positiononthemap);
    const themeLayers = Object.keys(layers).map(i => i.replace('dq__', ''));
    dispatch(mapStateAction(getInitMapState(settings, layerFilters, true, themeLayers, dataQualityFilters)));
    if (issue_id) {
      dispatch(fetchDataQualityWarningAction({ issue_id, code }, warning));
    } else {
      dispatch(setDataQualityWarningAction({ bound, layers, description: warning }));
    }
  }, [dispatch, history]); // eslint-disable-line

  return null;
};

export default React.memo(DataQuality);
