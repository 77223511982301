import axios from 'axios';
import { createAction } from 'redux-actions';
import { externalTimeSystemSelector } from 'modules/app/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { _keyBy, _omit } from '@utiligize/shared/utils';
import { PaginationType, DateFormats, TasksRegisteredHoursSourceTypes } from 'constants/index';

export const resetReduxStateAction: any = createAction(
  'tasks/RESET_REDUX_STATE',
  (nextState: Partial<Options.Root>) => nextState
);

export const fetchUsersRegisteredHoursAction: any = createAction(
  'tasks/FETCH_USERS_REGISTERED_HOURS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'usersRegisteredHoursCount' | 'usersRegisteredHoursHash'>> => {
      if (!skipStoreUpdate) {
        dispatch(resetReduxStateAction({ usersRegisteredHoursCount: 0, usersRegisteredHoursHash: {} }));
      }

      const state: State.Root = getState();
      const externalTimeSystem = externalTimeSystemSelector(state);
      const { sort, column, query, filters } = paginationSelectorFactory(PaginationType.USERS_REGISTERED_HOURS)(state);
      return axios
        .get('api/admin/v2/secure/registered_time/users', {
          params: {
            sort,
            column,
            query: skipPagination ? '' : query,
            filters: {
              ..._omit(filters, ['startTime', 'endTime']),
              startDate: filters?.startTime!.format(DateFormats.SERVER),
              endDate: filters?.endTime!.format(DateFormats.SERVER),
              source: externalTimeSystem ? filters?.source : TasksRegisteredHoursSourceTypes.INTERNAL,
            },
          },
        })
        .then((res: any) => ({
          usersRegisteredHoursCount: res.data.users.length,
          usersRegisteredHoursHash: _keyBy(
            res.data.users,
            (item: Tasks.UserRegisteredHours) => `_${item.id || item.email}_`
          ),
          skipStoreUpdate,
        }));
    }
);

export const fetchTasksRegisteredHoursAction: any = createAction(
  'tasks/FETCH_TASKS_REGISTERED_HOURS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'tasksRegisteredHoursCount' | 'tasksRegisteredHoursHash'>> => {
      if (!skipStoreUpdate) {
        dispatch(resetReduxStateAction({ tasksRegisteredHoursCount: 0, tasksRegisteredHoursHash: {} }));
      }

      const state: State.Root = getState();
      const externalTimeSystem = externalTimeSystemSelector(state);
      const { sort, column, query, filters } = paginationSelectorFactory(PaginationType.TASKS_REGISTERED_HOURS)(state);
      return axios
        .get('api/admin/v2/secure/registered_time/tasks', {
          params: {
            sort,
            column,
            query: skipPagination ? '' : query,
            filters: {
              ..._omit(filters, ['startTime', 'endTime']),
              startDate: filters?.startTime!.format(DateFormats.SERVER),
              endDate: filters?.endTime!.format(DateFormats.SERVER),
              source: externalTimeSystem ? filters?.source : TasksRegisteredHoursSourceTypes.INTERNAL,
            },
          },
        })
        .then((res: any) => ({
          tasksRegisteredHoursCount: res.data.tasks.length,
          tasksRegisteredHoursHash: _keyBy(
            res.data.tasks,
            (item: Tasks.TaskRegisteredHours) => `_${item.id || item.caseNumber}_`
          ),
          skipStoreUpdate,
        }));
    }
);
