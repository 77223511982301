import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, generatePath } from 'react-router';
import { useLocale } from 'hooks';
import {
  fetchSmartMeterActionAction,
  fetchSmartMeterChecklistAction,
  updateSmartMeterChecklistAction,
} from 'modules/meters';
import { smartMeterActionSelector } from 'modules/meters/selectors';
import { SmartMetersActionComments, SmartMetersActionMeta, SmartMetersActionGallery } from 'components';
import { BackToTableButton, DataTableTemplate, Checklist, Spinner } from 'components/_common';
import { Routes } from 'constants/index';

const PageSmartMeterDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const id: number = Number(match.params.id);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [loading, setLoading] = useState(true);
  const item = useSelector(smartMeterActionSelector);

  useEffect(() => {
    dispatch(fetchSmartMeterActionAction(id)).finally(() => setLoading(false));
  }, [dispatch, id]);

  const renderContent = () => {
    if (loading || !item) return <Spinner isInFullHeightContainer />;
    return (
      <>
        <Row className="justify-content-md-between">
          <Col md={6}>
            <Checklist<Meters.ChecklistHash>
              id={id}
              fetchChecklistAction={fetchSmartMeterChecklistAction}
              updateChecklistAction={updateSmartMeterChecklistAction}
            />
            <SmartMetersActionComments id={id} comment={item.comment} />
          </Col>
          <Col md={5}>
            <SmartMetersActionMeta item={item} />
          </Col>
        </Row>
        <hr className="mt-0 mb-3" />
        <SmartMetersActionGallery maalernr={item?.newMaalernr || item?.oldMaalernr} />
      </>
    );
  };

  return (
    <DataTableTemplate title={`${getIntl('Smart meter action')} / ${id}`}>
      <React.Fragment />
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <div className="d-flex justify-content-between">
                <Link to={generatePath(Routes.Meters)}>
                  <BackToTableButton />
                </Link>
              </div>
            </div>
            <div className="card-body" style={{ height: 'calc(100vh - 191px)', overflow: 'auto' }}>
              {renderContent()}
            </div>
          </div>
        </Col>
      </Row>
    </DataTableTemplate>
  );
};

export default PageSmartMeterDetails;
