import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { scenarioOptionsSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectScenarioType: React.FC<Props> = ({ value, ...props }) => {
  const options = useSelector(scenarioOptionsSelector);

  const selectedOption = useMemo(() => options?.find(i => i.value === value) || null, [options, value]);

  return (
    <FormReactSelect
      {...props}
      isDisabled={!options?.length}
      labelKey="Forecasted scenario"
      value={selectedOption}
      options={options || []}
      menuPortalTarget={document.body}
      isSearchable={false}
    />
  );
};

export default SelectScenarioType;
