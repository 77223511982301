import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.budgeting;

export const budgetingParametersCountSelector = createSelector(
  rootSelector,
  (budgeting: Budgeting.Root): number => budgeting.budgetingParametersCount
);

export const budgetingParametersSelector = createSelector(
  rootSelector,
  (budgeting: Budgeting.Root): Budgeting.Parameter[] => budgeting.budgetingParameters
);
