import axios from 'axios';
import { createAction } from 'redux-actions';
import { _keyBy } from '@utiligize/shared/utils';

export const fetchTaskCommentsAction: any = createAction('tasks/FETCH_TASK_COMMENTS', async (taskId: number) => {
  return (): Promise<Type.Hash<Tasks.Comment>> =>
    axios.get(`api/admin/v2/secure/tasks/${taskId}/comments`).then(res => _keyBy(res.data, 'id'));
});

export const postTaskCommentsAction: any = createAction(
  'tasks/POST_TASK_COMMENT',
  (taskId: number, message: string) => {
    return (): Promise<Tasks.Comment> =>
      axios.post(`api/admin/v2/secure/tasks/${taskId}/comments`, { message }).then(res => res.data);
  }
);

export const deleteTaskCommentsAction: any = createAction(
  'tasks/DELETE_TASK_COMMENT',
  async (taskId: number, commentId: number) => {
    return (): Promise<void> => axios.delete(`api/admin/v2/secure/tasks/${taskId}/comments/${commentId}`);
  }
);
