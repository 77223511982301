import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import ModalComponentPriceAsset from './ModalComponentPriceAsset';
import SelectPriceType from './ModalComponentPriceAsset/SelectPriceType';
import { SelectComponentPriceScenario } from 'components/_common';
import { PaginationType } from 'constants/index';

const FiltersComponentPrices: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.COMPONENT_PRICES;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, meta: Type.SelectActionMetaBase) => {
      dispatch(
        setPaginationAction({ type, modifier: { filters: { ...filters, [meta.name]: option?.value }, offset: 0 } })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <Row>
      <Col xs={2}>
        <SelectComponentPriceScenario
          name="componentPriceScenarioId"
          value={filters?.componentPriceScenarioId || null}
          onChange={handleSelectChange}
          isClearable
          labelKey=""
          variant="small"
        />
      </Col>
      <Col xs={2}>
        <SelectPriceType
          name="priceTypeId"
          value={filters?.priceTypeId || null}
          onChange={handleSelectChange}
          isSearchable={false}
          labelKey=""
          mutedTextLabelKey="Price type"
          variant="small"
        />
      </Col>
      <Col xs="auto">
        <ModalComponentPriceAsset />
      </Col>
    </Row>
  );
};

export default FiltersComponentPrices;
