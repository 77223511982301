import styled from 'styled-components';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export const PopupItem: React.FC<Props> = ({ children, ...props }) => {
  return <StyledContainer {...props}>{children}</StyledContainer>;
};

const StyledContainer = styled.div`
  color: #f6f8fb;
  border-radius: 6px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--map-active-color);
  border: 1px solid transparent;

  position: relative;
  min-width: 200px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  ${props =>
    props.onClick
      ? `
      cursor: pointer;
      &:hover,
      &.active {
        border-color: var(--map-background-color);
      }
    `
      : ''};

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export default PopupItem;
