import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useDebouncedCallback } from 'use-debounce';

import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string;
}

const SelectMapboxGeocoder: React.FC<Props> = ({ labelKey = 'Address', value, ...props }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(value);
  const [options, setOptions] = useState<{ label: string; value: string; x: number; y: number }[]>([]);

  const debounced = useDebouncedCallback((newValue: string) => {
    setIsLoading(true);
    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(newValue)}.json?access_token=${
          process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
        }&limit=5`
      )
      .then((response: any) => {
        const options = response.data.features.map((i: any) => ({
          label: i.place_name,
          value: i.place_name,
          x: i.geometry.coordinates[0],
          y: i.geometry.coordinates[1],
        }));
        setOptions(options);
      })
      .finally(() => setIsLoading(false));
  }, 1000);

  useEffect(() => {
    return debounced.cancel;
  }, [debounced.cancel]);

  const handleInputChange = useCallback(
    (newValue: string) => {
      setInputValue(newValue);
      if (newValue) {
        debounced(newValue);
      } else {
        setOptions([]);
      }
    },
    [debounced]
  );

  const handleFocus = useCallback(() => setInputValue(value), [value]);

  return (
    <FormReactSelect
      {...props}
      placeholderKey="Type to search"
      labelKey={labelKey}
      value={value ? { value, label: value } : null}
      isLoading={isLoading}
      options={options}
      onInputChange={handleInputChange}
      // keep previous search input position
      onFocus={handleFocus}
      inputValue={inputValue}
      isClearable
    />
  );
};

export default SelectMapboxGeocoder;
