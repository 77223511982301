import React from 'react';
import { Switch, Route } from 'react-router';
import {
  ViewForecastedScenarios,
  ViewInvestment,
  ViewCO2e,
  ViewRawData,
  ViewCalculationSchedule,
} from 'components/Setup';
import PagePermissionsProvider from './PagePermissionsProvider';
import PageNotFound from './PageNotFound';
import { Routes, PermissionsTypes } from 'constants/index';

const PageSetup: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isSetupEnabled}>
    <Switch>
      <Route exact path={Routes.Setup} component={ViewForecastedScenarios} />
      <Route path={Routes.SetupInvestment} component={ViewInvestment} />
      <Route path={Routes.SetupCO2e} component={ViewCO2e} />
      <Route exact path={Routes.SetupRawData} component={ViewRawData} />
      <Route exact path={Routes.SetupCalculationSchedule} component={ViewCalculationSchedule} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageSetup;
