import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { PaginationType } from 'constants/index';

export const fetchTaskHardwareAction: any = createAction(
  'tasks/FETCH_TASK_HARDWARE',
  async (taskId: number) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'taskHardwareCount' | 'taskHardware'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.TASK_HARDWARE)(state);
      return axios
        .get(`api/admin/v2/secure/tasks/${taskId}/hardware`, {
          params: {
            limit,
            offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          taskHardwareCount: res.data.count,
          taskHardware: res.data.rows,
        }));
    }
);
