import { combineActions, handleActions } from 'redux-actions';
import {
  fetchForecastedScenariosAction,
  createForecastedScenarioAction,
  updateForecastedScenarioAction,
  deleteForecastedScenarioAction,
  fetchComponentPriceScenariosAction,
  createComponentPriceScenarioAction,
  updateComponentPriceScenarioAction,
  deleteComponentPriceScenarioAction,
  fetchInvestmentScenariosAction,
  createInvestmentScenarioAction,
  updateInvestmentScenarioAction,
  deleteInvestmentScenarioAction,
  fetchInvestmentLimitationsAction,
  updateInvestmentLimitationAction,
  fetchComponentPricesAction,
  createComponentPriceAssetAction,
  updateComponentPriceAssetAction,
  deleteComponentPriceAssetAction,
  fetchRawDataAction,
  fetchInvestmentBudgetAction,
  fetchScenarioCalculationAction,
  patchScenarioCalculationAction,
  fetchWorkPricesAction,
  createWorkPriceAction,
  updateWorkPriceAction,
  deleteWorkPriceAction,
  fetchSetupCO2eAction,
  updateSetupCO2eItemAction,
} from './actions';

const initialState: Setup.Root = {
  forecastedScenariosCount: 0,
  forecastedScenariosHash: {},
  componentPriceScenariosCount: 0,
  componentPriceScenariosHash: {},
  investmentScenariosHash: {},
  investmentScenariosMetaCurrency: '',
  componentPricesCount: 0,
  componentPricesHash: {},
  workPricesCount: 0,
  workPricesHash: {},
  CO2e: [],
  rawDataCount: 0,
  rawDataHash: {},
  rawDataMeta: null,
  investmentLimitationsByVoltageNameHash: null,
  investmentLimitationVoltageUnit: '',
  investmentBudgetCount: 0,
  investmentBudgetHash: {},
  investmentBudgetMeta: null,
  scenarioCalculationHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchForecastedScenariosAction,
    fetchComponentPriceScenariosAction,
    fetchInvestmentScenariosAction,
    fetchComponentPricesAction,
    fetchRawDataAction,
    fetchInvestmentLimitationsAction,
    fetchInvestmentBudgetAction,
    fetchScenarioCalculationAction,
    fetchWorkPricesAction,
    fetchSetupCO2eAction
  ).toString() as any]: {
    next: (
      state: Setup.Root,
      action: Shared.ReduxAction<Partial<Setup.Root> & { skipStoreUpdate: true | undefined }>
    ): Setup.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },

  [updateInvestmentScenarioAction.toString()]: {
    next: (
      state: Setup.Root,
      action: Shared.ReduxAction<{ useOptimisticUpdate: boolean; id: number; loss_optimisation: boolean }>
    ): Setup.Root => {
      const { useOptimisticUpdate, id, loss_optimisation } = action.payload;
      if (!useOptimisticUpdate) return state;
      return {
        ...state,
        investmentScenariosHash: {
          ...state.investmentScenariosHash,
          [`_${id}_`]: {
            ...state.investmentScenariosHash[`_${id}_`],
            loss_optimisation,
          },
        },
      };
    },
  },
};

export {
  fetchForecastedScenariosAction,
  createForecastedScenarioAction,
  updateForecastedScenarioAction,
  deleteForecastedScenarioAction,
  fetchComponentPriceScenariosAction,
  createComponentPriceScenarioAction,
  updateComponentPriceScenarioAction,
  deleteComponentPriceScenarioAction,
  fetchInvestmentScenariosAction,
  createInvestmentScenarioAction,
  updateInvestmentScenarioAction,
  deleteInvestmentScenarioAction,
  fetchInvestmentLimitationsAction,
  updateInvestmentLimitationAction,
  fetchComponentPricesAction,
  createComponentPriceAssetAction,
  updateComponentPriceAssetAction,
  deleteComponentPriceAssetAction,
  fetchRawDataAction,
  fetchInvestmentBudgetAction,
  fetchScenarioCalculationAction,
  patchScenarioCalculationAction,
  fetchWorkPricesAction,
  createWorkPriceAction,
  updateWorkPriceAction,
  deleteWorkPriceAction,
  fetchSetupCO2eAction,
  updateSetupCO2eItemAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
