import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { fetchDERsAction, createDERAction, updateDERAction, replaceDERProfileAction } from 'modules/newLoad';
import { setSuccessToastAction } from 'modules/layouts';
import { resetDERsDescriptionOptionsAction } from 'modules/options';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<{
    description: string;
    der_categories_id: number | null;
    max_per_installation: number | null;
    grid_zones_id: number[] | null;
    profileId: number | null;
  }>({
    initialValues: {
      description: '',
      der_categories_id: null,
      max_per_installation: null,
      grid_zones_id: null,
      profileId: null,
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Field can't be empty!"),
      grid_zones_id: Yup.string().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const payload = {
        description: values.description,
        der_categories_id: values.der_categories_id,
        max_per_installation: values.max_per_installation,
        grid_zones_id: values.grid_zones_id,
      };
      return dispatch(id ? updateDERAction({ id, ...payload }) : createDERAction(payload))
        .then(action => {
          const derId = id || action.payload;
          const promises = [];
          if (values.profileId) {
            promises.push(dispatch(replaceDERProfileAction({ id: derId, profileId: values.profileId })));
          }
          return Promise.all(promises);
        })
        .then(async () => {
          await dispatch(fetchDERsAction());
          toggleModal();
          dispatch(setSuccessToastAction(id ? 'DER has been updated' : 'DER has been created'));
          // Note. Wait for close modal transition and SelectDERsDescription unmount event
          // to be sure we won't trigger fetchDERsDescriptionOptionsAction event
          setTimeout(() => dispatch(resetDERsDescriptionOptionsAction()), 500);
        })
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
