import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode;
  marginBottom?: boolean;
  className?: string;
}

const ContentCard: React.FC<Props> = ({ children, marginBottom = false, className = '' }) => (
  <StyledCard className={className} data-margin-bottom={marginBottom}>
    {children}
  </StyledCard>
);

const StyledCard = styled.div<{ 'data-margin-bottom': boolean }>`
  ${props => `
    padding: ${props.theme.paddings.primary};
    background: ${props.theme.colors.white};
    border: 1px solid ${props.theme.colors.grey100};
    ${props['data-margin-bottom'] ? `margin-bottom: ${props.theme.paddings.primary};` : ''}
    -webkit-print-color-adjust: exact;
  `}
`;

export default ContentCard;
