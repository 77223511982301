import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchIndividualCustomersAction, deleteIndividualCustomerAction } from 'modules/newLoad';
import {
  individualCustomersCountSelector,
  individualCustomersSplitBySectionHashSelector,
} from 'modules/newLoad/selectors';
import { setModalConfirmationMetaAction, setNewLoadDERProfileModalIdAction, setLayoutAction } from 'modules/layouts';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import ModalEditIndividualCustomer from './ModalEditIndividualCustomer';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete, IconDetails } from '@utiligize/shared/resources';
import ModalProfile from './ModalProfile';

const TableIndividualCustomers: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const individualCustomersSplitBySectionHash = useSelector(individualCustomersSplitBySectionHashSelector);
  const keys = Object.keys(individualCustomersSplitBySectionHash);
  const count = useSelector(individualCustomersCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchIndividualCustomersAction()), [dispatch]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteIndividualCustomerAction(id)),
        })
      );
    },
    [dispatch]
  );

  const handleViewProfileButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setNewLoadDERProfileModalIdAction(id));
    },
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      dispatch(setLayoutAction({ newLoadEditIndividualCustomerModalId: Number(id) }));
    },
    [dispatch]
  );

  return (
    <>
      <DataTable
        paginationType={PaginationType.NEW_LOAD_INDIVIDUAL_CUSTOMERS}
        totalAmount={count}
        sendRequest={sendRequest}
        waitForDependencies={!portfolioId || !scenarioId}
        triggerTableUpdateDeps={[portfolioId, scenarioId]}
      >
        {keys?.map((key: string) => {
          const individualCustomers: NewLoad.IndividualCustomer[] = individualCustomersSplitBySectionHash[key];
          return (
            <React.Fragment key={key}>
              <tr>
                <td colSpan={8}>
                  <b>
                    {key} - {individualCustomers[0].category}
                  </b>
                </td>
              </tr>
              {individualCustomers.map((item: NewLoad.IndividualCustomer) => (
                <tr key={item.id}>
                  <td>{item.connection_year || '-'}</td>
                  <td>{item.description || '-'}</td>
                  <td>{item.customer_node_from || '-'}</td>
                  <td className="text-center">{item.customer_id || '-'}</td>
                  <td className="text-center">{item.customer_mw || '-'}</td>
                  <td>{item.power_factor ?? '-'}</td>
                  <td>{item.leading_lagging || '-'}</td>
                  <td className="text-right">
                    {item.der_profile_info_id && (
                      <Button
                        tooltipKey="Profile"
                        icon={<IconDetails />}
                        data-id={item.id}
                        onClick={handleViewProfileButtonClick}
                        size="small"
                        variant="primary"
                      />
                    )}
                    <Button
                      marginLeft={Boolean(item.der_profile_info_id)}
                      tooltipKey="Edit"
                      icon={<IconEdit />}
                      data-id={item.id}
                      onClick={handleEditBtnClick}
                      size="small"
                      variant="primary"
                    />
                    <Button
                      marginLeft
                      tooltipKey="Delete"
                      icon={<IconDelete />}
                      data-id={item.id}
                      onClick={handleDeleteBtnClick}
                      size="small"
                      variant="primary"
                    />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </DataTable>
      <ModalProfile />
      <ModalEditIndividualCustomer />
    </>
  );
};

export default TableIndividualCustomers;
