import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setLayoutAction } from 'modules/layouts';
import { singleDiagramTypeSelector } from 'modules/layouts/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import GroupTitle from './GroupTitle';
import { SingleLineDiagramTypes } from 'constants/index';

const SingleLineDiagramControls: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const singleDiagramType = useSelector(singleDiagramTypeSelector);

  const handleControlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const singleDiagramType = event.target.id as SingleLineDiagramTypes;
      dispatch(setLayoutAction({ singleDiagramType }));
    },
    [dispatch]
  );

  return (
    <StyledContainer>
      <GroupTitle titleKey="Chart types" />
      <StyledControls>
        <ControlBox
          type="radio"
          id={SingleLineDiagramTypes.Tree}
          name={SingleLineDiagramTypes.Tree}
          checked={singleDiagramType === SingleLineDiagramTypes.Tree}
          labelKey={SingleLineDiagramTypes.Tree}
          onChange={handleControlChange}
        />
        <ControlBox
          type="radio"
          id={SingleLineDiagramTypes.Geospatial}
          name={SingleLineDiagramTypes.Geospatial}
          checked={singleDiagramType === SingleLineDiagramTypes.Geospatial}
          labelKey={SingleLineDiagramTypes.Geospatial}
          onChange={handleControlChange}
        />
      </StyledControls>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  padding: 0px 18px;
`;

const StyledControls = styled.div`
  display: flex;

  .sidebar-checkbox {
    display: inline-flex;
  }
`;

export default SingleLineDiagramControls;
