import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { TaskStatus } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Tasks.TaskStatus[] | Tasks.TaskStatus | null;
  excludedOptions?: Tasks.TaskStatus[];
}

const SelectTaskStatus: React.FC<Props> = ({ labelKey = 'Status', value, excludedOptions = [], ...props }) => {
  const { getIntl } = useLocale();

  const { options, values } = useMemo(
    () =>
      Object.values(TaskStatus).reduce(
        (acc: any, status: Tasks.TaskStatus) => {
          if (excludedOptions.includes(status)) return acc;
          const item = { value: status, label: getIntl(status) };
          if ((Array.isArray(value) ? value : [value]).includes(status)) acc.values.push(item);
          acc.options.push(item);
          return acc;
        },
        { options: [], values: [] }
      ),
    [value, getIntl, excludedOptions]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={values.length ? values : null}
      options={options}
      placeholderKey="Select status"
    />
  );
};

export default SelectTaskStatus;
