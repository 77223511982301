import React, { useMemo } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Checkbox } from 'components/_common';
import { PermissionsTypesLocalesMap, PermissionsTypesGroups } from 'constants/index';

const splitPermissionsByGroups = ({ objKey, permissions, onChange }: any) =>
  (Object.keys(permissions) as Permissions.PermissionsTypes[]).reduce(
    (
      acc: { views: JSX.Element[]; taskType: JSX.Element[]; readWrite: JSX.Element[]; assetCategories: JSX.Element[] },
      key
    ) => {
      const { group, labelKey = key } = PermissionsTypesLocalesMap[key as Permissions.PermissionsTypes] || {};
      const isTaskTypeGroup = group === PermissionsTypesGroups.TaskType;
      const isReadWriteGroup = group === PermissionsTypesGroups.ReadWrite;
      const isAssetCategoriesGroup = group === PermissionsTypesGroups.AssetCategories;

      const checkbox = (
        <Form.Group as={Col} xs={6} key={key} className="mb-0">
          <Checkbox
            labelKey={labelKey}
            name={`${objKey}.${key}`}
            className="icheck-success"
            checked={permissions[key as Permissions.PermissionsTypes]}
            onChange={onChange}
          />
        </Form.Group>
      );
      if (isReadWriteGroup) acc.readWrite.push(checkbox);
      if (isTaskTypeGroup) acc.taskType.push(checkbox);
      if (isAssetCategoriesGroup) acc.assetCategories.push(checkbox);
      if (!isReadWriteGroup && !isTaskTypeGroup && !isAssetCategoriesGroup) {
        acc.views.push(checkbox);
      }
      return acc;
    },
    { views: [], taskType: [], readWrite: [], assetCategories: [] }
  );

export default function useSplitPermissionsByGroups({ objKey, permissions, onChange }: any) {
  const { views, taskType, readWrite, assetCategories } = useMemo(
    () => splitPermissionsByGroups({ objKey, permissions, onChange }),
    [objKey, permissions, onChange]
  );

  return { views, taskType, readWrite, assetCategories };
}
