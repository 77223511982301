import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.customers;

export const rightsCountSelector = createSelector(
  rootSelector,
  (customers: Customers.Root): number => customers.rightsCount
);

const rightsHashSelector = createSelector(
  rootSelector,
  (customers: Customers.Root): Type.Hash<Customers.Rights> => customers.rightsHash
);

export const rightsSelector = createSelector(
  rightsHashSelector,
  (rightsHash: Type.Hash<Customers.Rights>): Customers.Rights[] => Object.values(rightsHash)
);

export const existingDERsOptionsSelector = createSelector(
  rootSelector,
  (customers: Customers.Root): Customers.Root['existingDERsOptions'] => customers.existingDERsOptions
);
