import styled from 'styled-components';
import React, { useCallback } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
  selectedChartFlexSelector,
} from 'modules/layouts/selectors';
import { DutyFactor } from 'components/_modals/ProfileModal/ProfileTabs';
import { ChartIframe, Checkbox } from 'components/_common';
import { setLayoutAction } from 'modules/layouts';

interface Props {
  uuid: string;
  className?: string;
}

const ProbabilityOfFailureChart: React.FC<Props> = ({ uuid, className = '' }) => {
  const { lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  return (
    <>
      <StyledContainer data-marker="asset_timeseries__buttons_block">
        <Checkbox
          labelKey="Flex"
          className="icheck-primary"
          name="flex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
      </StyledContainer>
      <div className="d-flex align-items-center h-100">
        <ChartIframe
          dataMarker="asset_timeseries_chart"
          title="Probability of failure plot"
          src={
            Boolean(portfolioId && scenarioId)
              ? `load/probability_of_failure_plot?${queryString.stringify({
                  portfolio_id: portfolioId,
                  scenario_id: scenarioId,
                  simulation_id: simulationId,
                  lang: lng.toLowerCase(),
                  id: uuid,
                  flex,
                })}`
              : ''
          }
          className={className}
        />
        <DutyFactor
          uuid={uuid}
          portfolioId={portfolioId}
          scenarioId={scenarioId}
          simulationId={simulationId}
          flex={flex}
        />
      </div>
    </>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 15px;
`;

export default ProbabilityOfFailureChart;
