import axios from 'axios';
import { createAction } from 'redux-actions';
import { _omit } from '@utiligize/shared/utils';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { PaginationType } from 'constants/index';

const baseUrl: string = 'api/admin/v1/secure/instructions';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchInstructionsAction: any = createAction(
  'instructions/FETCH_INSTRUCTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Omit<Instructions.Root, 'loading' | 'isFetched'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.INSTRUCTIONS)(state);
      const lang = appLangSelector(state).toLowerCase();
      return axios
        .get(baseUrl, {
          params: { limit, offset, sort, column, query, lang },
        })
        .then((res: any) => ({
          count: res.data.count,
          rows: res.data.rows,
          builderData: _omit(res.data, ['rows', 'count']) as Builder.Data,
        }));
    }
);

export const fetchInstructionSnapshotAction: any = createAction(
  'instructions/FETCH_SNAPSHOT',
  async (formId: number) => {
    return axios.get(`${baseUrl}/snapshot?formId=${formId}`).then(res => res.data);
  }
);

export const deleteInstructionAction: any = createAction(
  'instructions/DELETE_INSTRUCTION',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.delete(`${baseUrl}/${id}`).then(async () => {
        await dispatch(fetchInstructionsAction());
        dispatch(setSuccessToastAction('Instruction has been deleted'));
      });
    }
);
