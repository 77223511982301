import React from 'react';
import { ViewFeatureToggles } from 'components/Admin';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageFeatureToggles: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isFeatureToggleAvailable}>
    <ViewFeatureToggles />
  </PagePermissionsProvider>
);

export default PageFeatureToggles;
