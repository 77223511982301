import React, { useCallback, useState } from 'react';
import download from 'downloadjs';
import { useLocale } from 'hooks';
import { Button, ButtonProps } from 'components/_common';
import { AssetLifeAPI, plumberAPI } from 'constants/index';
import { IconExcel, IconWord, IconDownload } from '@utiligize/shared/resources';

interface Props extends ButtonProps {
  fileType?: 'xlsx' | 'docx' | 'csv';
  fileNameLocaleStr: string;
  link: string;
  isAssetLifeAPI?: boolean;
  dataMarker?: string;
}

const mimeTypes = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  csv: 'text/csv',
};

const ButtonDownload: React.FC<Props> = ({
  isAssetLifeAPI,
  fileType = 'xlsx',
  fileNameLocaleStr,
  link,
  dataMarker,
  ...rest
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { getIntl, dateFormat } = useLocale();
  const fileName = getIntl(fileNameLocaleStr);

  const handleDownloadClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    (isAssetLifeAPI ? AssetLifeAPI : plumberAPI)
      .get(link, { responseType: 'blob' })
      .then(res => {
        download(
          res.data,
          `${fileName} (${dateFormat(new Date().toISOString(), 'L LT')}).${fileType}`,
          mimeTypes[fileType]
        );
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [isAssetLifeAPI, fileType, fileName, link, loading, dateFormat]);

  const renderIcon = () => {
    switch (fileType) {
      case 'xlsx':
        return <IconExcel />;
      case 'docx':
        return <IconWord />;
      default:
        return <IconDownload />;
    }
  };

  return (
    <Button dataMarker={dataMarker} icon={renderIcon()} onClick={handleDownloadClick} loading={loading} {...rest} />
  );
};

export default ButtonDownload;
