import React, { useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { useDispatch } from 'react-redux';
import { saveSmartMeterCommentAction } from 'modules/meters';
import { Button, FormInput } from 'components/_common';

const SmartMetersActionComments: React.FC<{ id: number; comment: string }> = ({ id, comment }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [value, setValue] = useState(comment);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setValue(value);
  }, []);

  const handleSaveButtonClick = useCallback(() => {
    dispatch(saveSmartMeterCommentAction(id, value));
  }, [dispatch, id, value]);

  return (
    <>
      <h5 className="mt-4">{getIntl('Comments')}</h5>
      <Form className="d-flex flex-column">
        <Form.Group>
          <FormInput as="textarea" rows={5} name="label" value={value} onChange={handleInputChange} />
        </Form.Group>
        <Button
          className="ml-auto mb-3"
          labelKey="Save"
          onClick={handleSaveButtonClick}
          disabled={comment === value}
          variant="primary"
        />
      </Form>
    </>
  );
};

export default SmartMetersActionComments;
