import React, { useMemo, useCallback } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectIssuesStatusValues } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: DataQuality.SelectIssuesStatusValues | undefined;
  onChange: (value: DataQuality.SelectIssuesStatusValues, meta: Type.SelectActionMetaBase) => void;
}

const SelectIssuesStatus: React.FC<Props> = ({ value = null, onChange, ...props }) => {
  const { getIntl } = useLocale();

  const ValuesLabelsMap = useMemo(
    () => ({
      [SelectIssuesStatusValues.open]: 'Open',
      [SelectIssuesStatusValues.solved]: 'Solved',
      [SelectIssuesStatusValues.ignored]: 'Ignored',
    }),
    []
  );

  const { options, selectValue } = useMemo(
    () =>
      Object.keys(SelectIssuesStatusValues).reduce(
        (acc: any, key: string) => {
          const item = {
            value: key,
            label: getIntl(ValuesLabelsMap[key as DataQuality.SelectIssuesStatusValues]),
          };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, ValuesLabelsMap, getIntl]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, meta: any) => onChange((value as any)?.value, meta),
    [onChange]
  );

  return (
    <FormReactSelect
      value={selectValue}
      options={options}
      onChange={handleSelectChange}
      isSearchable={false}
      {...props}
    />
  );
};

export default SelectIssuesStatus;
