import { createAction } from 'redux-actions';
import { appLangSelector, appCurrentUserIdSelector } from 'modules/app/selectors';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { scenarioCalculationHashSelector } from './selectors';
import { setSuccessToastAction } from 'modules/layouts';
import {
  fetchComponentPriceScenarioOptionsAction,
  fetchInvestmentScenariosOptionsAction,
  fetchScenarioOptionsAction,
} from 'modules/options';
import { isPortfolioLoadflowEnabledSelector } from 'modules/options/selectors';
import { _keyBy, _omit } from '@utiligize/shared/utils';
import { PaginationType, plumberAPI, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Investment Scenarios Actions
// ------------------------------------
export const fetchInvestmentScenariosAction: any = createAction(
  'setup/FETCH_INVESTMENT_SCENARIOS',
  async (
    { skipPagination, skipStoreUpdate, returnAllItems } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
      returnAllItems: false,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'investmentScenariosHash'>> => {
      const state: State.Root = getState();
      const { sort, column, query } = paginationSelectorFactory(PaginationType.INVESTMENT_SCENARIOS)(state);
      return AssetLifeAPI.get('investment/scenario_table', {
        params: {
          limit: null,
          offset: 0,
          sort,
          column,
          query: skipPagination ? '' : query,
          portfolio_id: portfolioIdSelector(state),
        },
      }).then((res: any) => {
        const rows = res.data.rows.filter((row: any) => returnAllItems || !row.read_only);
        return {
          investmentScenariosHash: _keyBy(rows, (item: Setup.InvestmentScenario) => `_${item.id}_`),
          investmentScenariosMetaCurrency: res.data.meta?.currency,
          skipStoreUpdate,
        };
      });
    }
);

export const createInvestmentScenarioAction = createAction(
  'setup/CREATE_INVESTMENT_SCENARIO',
  (data: Setup.InvestmentScenarioActionProps) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      return AssetLifeAPI.post('investment/investment_scenario', {
        ...data,
        sso_users_id: appCurrentUserIdSelector(state),
        portfolio_id: portfolioIdSelector(state),
      }).then(async () => {
        await dispatch(fetchInvestmentScenariosOptionsAction());
        await dispatch(fetchInvestmentScenariosAction());
        dispatch(setSuccessToastAction('Scenario has been created'));
      });
    };
  }
);

export const updateInvestmentScenarioAction = createAction(
  'setup/UPDATE_INVESTMENT_SCENARIO',
  (
    data: Setup.InvestmentScenarioActionProps,
    { useOptimisticUpdate }: { useOptimisticUpdate: boolean } = { useOptimisticUpdate: false }
  ) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<{ useOptimisticUpdate: boolean }> =>
      AssetLifeAPI.put('investment/investment_scenario', {
        ...data,
        portfolio_id: portfolioIdSelector(getState()),
      }).then(async () => {
        if (!useOptimisticUpdate) {
          await dispatch(fetchInvestmentScenariosOptionsAction());
          await dispatch(fetchInvestmentScenariosAction());
        }
        dispatch(setSuccessToastAction('Scenario has been updated'));

        return { useOptimisticUpdate, id: data.investment_scenario_id, loss_optimisation: data.loss_optimisation };
      });
  }
);

export const deleteInvestmentScenarioAction = createAction(
  'setup/DELETE_INVESTMENT_SCENARIO',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.delete('investment/investment_scenario', {
        params: { investment_scenario_id: id },
      }).then(async () => {
        await dispatch(fetchInvestmentScenariosOptionsAction());
        await dispatch(fetchInvestmentScenariosAction());
        dispatch(setSuccessToastAction('Scenario has been deleted'));
      });
    }
);

// ------------------------------------
// Investment Limitations Actions
// ------------------------------------
export const fetchInvestmentLimitationsAction: any = createAction(
  'setup/FETCH_INVESTMENT_LIMITATIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'investmentLimitationsByVoltageNameHash' | 'investmentLimitationVoltageUnit'>> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.INVESTMENT_LIMITATIONS)(state);
      return AssetLifeAPI.get('/investment/investment_thresholds', {
        params: {
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          voltage_pu: filters?.voltageUnit,
        },
      }).then((res: any) => {
        const investmentLimitationsByVoltageNameHash = res.data.rows
          .sort((a: Setup.InvestmentLimitation, b: Setup.InvestmentLimitation) => a.voltage_id - b.voltage_id)
          .reduce((acc: Setup.InvestmentLimitationsByVoltageNameHash, row: Setup.InvestmentLimitation) => {
            const limitation =
              row.cnaim_type === 'cables'
                ? {
                    ..._omit(row, ['id', 'current_upper_bound_pc']),
                    cables_current_upper_bound_pc: row.current_upper_bound_pc,
                    cables_id: row.id,
                  }
                : row;
            const item = { ...(acc[row.voltage_name]?.[row.grid_zone_id] || {}), ...limitation };
            acc[row.voltage_name] = { ...acc[row.voltage_name], [row.grid_zone_id]: item };
            return acc;
          }, {} as Setup.InvestmentLimitationsByVoltageNameHash);

        return {
          investmentLimitationsByVoltageNameHash,
          investmentLimitationVoltageUnit: res.data.meta.voltage_unit,
        };
      });
    }
);

export const updateInvestmentLimitationAction: any = createAction(
  'setup/UPDATE_INVESTMENT_LIMITATION',
  async (item: Setup.InvestmentLimitation, column: keyof Setup.InvestmentLimitation, value: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.INVESTMENT_LIMITATIONS)(state);
      return AssetLifeAPI.put(
        '/investment/investment_thresholds',
        {
          investment_threshold_id:
            column === 'cables_current_upper_bound_pc' ? item.cables_id : item.id || item.cables_id,
          grid_zone_id: item.grid_zone_id,
          voltage_id: item.voltage_id,
          voltage_pu: filters?.voltageUnit,
          column: column === 'cables_current_upper_bound_pc' ? 'current_upper_bound_pc' : column,
          value,
        },
        { params: { portfolio_id: portfolioIdSelector(state), scenario_id: scenarioIdSelector(state) } }
      ).then(async () => {
        await dispatch(fetchInvestmentLimitationsAction());
        dispatch(setSuccessToastAction('Investment limitation has been updated'));
      });
    }
);

// ------------------------------------
// Component Price Scenarios Actions
// ------------------------------------
export const fetchComponentPriceScenariosAction: any = createAction(
  'setup/FETCH_COMPONENT_PRICE_SCENARIOS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'componentPriceScenariosCount' | 'componentPriceScenariosHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.COMPONENT_PRICE_SCENARIOS
      )(state);
      return AssetLifeAPI.get('investment/component_price_scenario', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          lang: appLangSelector(state).toLowerCase(),
          component_price_scenario_id: filters?.componentPriceScenarioId,
          portfolio_id: portfolioIdSelector(state),
        },
      }).then(res => ({
        componentPriceScenariosCount: res.data.length,
        componentPriceScenariosHash: _keyBy(res.data, (item: Setup.ComponentPriceScenario) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

export const createComponentPriceScenarioAction = createAction(
  'setup/CREATE_COMPONENT_PRICE_SCENARIO',
  (data: Setup.ComponentPriceScenarioActionProps) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      return AssetLifeAPI.post('investment/component_price_scenario', {
        ...data,
        sso_user_id: appCurrentUserIdSelector(state),
        portfolio_id: portfolioIdSelector(state),
      }).then(async () => {
        await dispatch(fetchComponentPriceScenariosAction());
        await dispatch(fetchComponentPriceScenarioOptionsAction());
        dispatch(setSuccessToastAction('Component price scenario has been created'));
      });
    };
  }
);

export const updateComponentPriceScenarioAction = createAction(
  'setup/UPDATE_COMPONENT_PRICE_SCENARIO',
  (data: Setup.ComponentPriceScenarioActionProps) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      return AssetLifeAPI.put('investment/component_price_scenario', {
        ...data,
        sso_user_id: appCurrentUserIdSelector(state),
        portfolio_id: portfolioIdSelector(state),
      }).then(async () => {
        await dispatch(fetchComponentPriceScenariosAction());
        await dispatch(fetchComponentPriceScenarioOptionsAction());
        dispatch(setSuccessToastAction('Component price scenario has been updated'));
      });
    };
  }
);

export const deleteComponentPriceScenarioAction = createAction(
  'setup/DELETE_COMPONENT_PRICE_SCENARIO',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.delete('investment/component_price_scenario', {
        params: { component_price_scenario_id: id },
      }).then(async () => {
        await dispatch(fetchComponentPriceScenariosAction());
        await dispatch(fetchComponentPriceScenarioOptionsAction());
        dispatch(setSuccessToastAction('Component price scenario has been deleted'));
      });
    }
);

// ------------------------------------
// Component Price Actions
// ------------------------------------
export const fetchComponentPricesAction: any = createAction(
  'setup/FETCH_COMPONENT_PRICES',
  async (
    { skipPagination, skipStoreUpdate } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'componentPricesCount' | 'componentPricesHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.COMPONENT_PRICES
      )(state);
      return AssetLifeAPI.get('investment/component_prices', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? null : offset,
          sort,
          column,
          query: skipPagination ? null : query,
          lang: appLangSelector(state).toLowerCase(),
          component_price_scenario_id: filters?.componentPriceScenarioId,
          price_type_id: filters?.priceTypeId,
          portfolio_id: portfolioIdSelector(state),
        },
      }).then((res: any) => ({
        componentPricesCount: res.data.count,
        componentPricesHash: _keyBy(res.data.rows, (item: Setup.ComponentPrice) => `_${item.id}_`),
        skipStoreUpdate,
      }));
    }
);

export const createComponentPriceAssetAction = createAction(
  'setup/CREATE_COMPONENT_PRICE_ASSET',
  (data: Setup.ComponentPriceAssetActionProps) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      return AssetLifeAPI.post('investment/component_price', data).then(async () => {
        await dispatch(fetchComponentPricesAction());
        dispatch(setSuccessToastAction('Component price asset has been created'));
      });
    }
);

export const updateComponentPriceAssetAction = createAction(
  'setup/UPDATE_COMPONENT_PRICE_ASSET',
  (data: Setup.ComponentPriceAssetActionProps) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.put('investment/component_price', data).then(async () => {
        await dispatch(fetchComponentPricesAction());
        dispatch(setSuccessToastAction('Component price asset has been updated'));
      });
    }
);

export const deleteComponentPriceAssetAction = createAction(
  'setup/DELETE_COMPONENT_PRICE_ASSET',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.delete('investment/component_price', { params: { component_price_id: id } }).then(
        async () => {
          await dispatch(fetchComponentPricesAction());
          dispatch(setSuccessToastAction('Component price asset has been deleted'));
        }
      );
    }
);

// ------------------------------------
// Work Price Actions
// ------------------------------------
export const fetchWorkPricesAction: any = createAction(
  'setup/FETCH_WORK_PRICES',
  async (
    { skipPagination, skipStoreUpdate } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'workPricesCount' | 'workPricesHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.WORK_PRICES)(
        state
      );
      return AssetLifeAPI.get('work_type/work_type_price', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? null : offset,
          sort,
          column,
          query: skipPagination ? null : query,
          lang: appLangSelector(state).toLowerCase(),
          component_price_scenario_id: filters?.componentPriceScenarioId,
        },
      }).then((res: any) => ({
        workPricesCount: res.data.count,
        workPricesHash: _keyBy(res.data.rows, (item: Setup.WorkPrice) => `_${item.work_type_price_id}_`),
        skipStoreUpdate,
      }));
    }
);

export const createWorkPriceAction = createAction('setup/CREATE_WORK_PRICE', (data: Setup.WorkPriceActionProps) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
    const state: State.Root = getState();
    const { filters } = paginationSelectorFactory(PaginationType.WORK_PRICES)(state);
    return AssetLifeAPI.post('work_type/work_type_price', null, {
      params: {
        ...data,
        component_price_scenario_id: filters?.componentPriceScenarioId,
      },
    }).then(async () => {
      await dispatch(fetchWorkPricesAction());
      dispatch(setSuccessToastAction('Work price has been created'));
    });
  };
});

export const updateWorkPriceAction = createAction('setup/UPDATE_WORK_PRICE', (data: Setup.WorkPriceActionProps) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
    const state: State.Root = getState();
    const { filters } = paginationSelectorFactory(PaginationType.WORK_PRICES)(state);
    return AssetLifeAPI.put('work_type/work_type_price', null, {
      params: {
        ...data,
        component_price_scenario_id: filters?.componentPriceScenarioId,
      },
    }).then(async () => {
      await dispatch(fetchWorkPricesAction());
      dispatch(setSuccessToastAction('Work price has been updated'));
    });
  };
});

export const deleteWorkPriceAction = createAction(
  'setup/DELETE_WORK_PRICE',
  async (work_type_price_id: Setup.WorkPrice['work_type_price_id']) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.delete('work_type/work_type_price', { params: { work_type_price_id } }).then(async () => {
        await dispatch(fetchWorkPricesAction());
        dispatch(setSuccessToastAction('Work price has been deleted'));
      });
    }
);

// ------------------------------------
// Budget Actions
// ------------------------------------
export const fetchInvestmentBudgetAction: any = createAction(
  'setup/FETCH_INVESTMENT_BUDGET',
  async (
    { skipPagination, skipStoreUpdate } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'investmentBudgetCount' | 'investmentBudgetHash' | 'investmentBudgetMeta'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.SETUP_INVESTMENT_BUDGET
      )(state);
      return AssetLifeAPI.get('budget_overview/budget_overview', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? null : offset,
          sort,
          column,
          query: skipPagination ? null : query,
          component_price_scenario_id: filters?.componentPriceScenarioId,
          portfolio_id: portfolioIdSelector(state),
        },
      }).then((res: any) => ({
        investmentBudgetCount: res.data.count,
        investmentBudgetHash: res.data.rows,
        // FixMe. budget_total_meta must be object instead of array
        investmentBudgetMeta: res.data.meta?.budget_total_meta || null,
        skipStoreUpdate,
      }));
    }
);

// ------------------------------------
// Forecasted Scenario Actions
// ------------------------------------
export const fetchForecastedScenariosAction: any = createAction(
  'setup/FETCH_FORECASTED_SCENARIOS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'forecastedScenariosCount' | 'forecastedScenariosHash'>> => {
      const state: State.Root = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled)
        return Promise.resolve({ forecastedScenariosCount: 0, forecastedScenariosHash: {} });
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.FORECASTED_SCENARIOS)(
        state
      );
      return AssetLifeAPI.get('scenarios/global_details', {
        params: {
          limit: skipPagination ? 10000 : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          lang: appLangSelector(state).toLowerCase(),
          portfolio_id: portfolioIdSelector(state),
        },
      }).then(res => ({
        forecastedScenariosCount: res.data.count,
        forecastedScenariosHash: _keyBy(
          res.data.rows.map((item: Setup.ForecastedScenario) => ({
            ...item,
            years: typeof item.years === 'number' ? [item.years] : item.years,
          })),
          (item: Setup.ForecastedScenario) => `_${item.id}_`
        ),
        skipStoreUpdate,
      }));
    }
);

export const createForecastedScenarioAction = createAction(
  'setup/CREATE_FORECASTED_SCENARIO',
  (data: Setup.ForecastedScenarioActionProps) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      return AssetLifeAPI.post('scenarios/global_details', {
        ...data,
        portfolio_id: portfolioIdSelector(state),
      }).then(async () => {
        await dispatch(fetchScenarioOptionsAction());
        await dispatch(fetchForecastedScenariosAction());
        dispatch(setSuccessToastAction('Scenario has been created'));
      });
    };
  }
);

export const updateForecastedScenarioAction = createAction(
  'setup/UPDATE_FORECASTED_SCENARIO',
  (data: Setup.ForecastedScenarioActionProps) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      return AssetLifeAPI.put(`scenarios/global_details/${data.id}`, {
        id: data.id,
        description: data.description,
        years: data.years,
        portfolio_id: portfolioIdSelector(state),
      }).then(async () => {
        await dispatch(fetchScenarioOptionsAction());
        await dispatch(fetchForecastedScenariosAction());
        dispatch(setSuccessToastAction('Scenario has been updated'));
      });
    };
  }
);

export const deleteForecastedScenarioAction = createAction(
  'setup/DELETE_FORECASTED_SCENARIO',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      return AssetLifeAPI.delete(`scenarios/global_details/${id}`, {
        params: { portfolio_id: portfolioIdSelector(state) },
      }).then(async res => {
        await dispatch(fetchScenarioOptionsAction());
        await dispatch(fetchForecastedScenariosAction());
        dispatch(setSuccessToastAction('Scenario has been deleted'));
      });
    }
);

// ------------------------------------
// CO₂e Actions
// ------------------------------------
export const fetchSetupCO2eAction: any = createAction(
  'setup/FETCH_SETUP_CO2E',
  async () => (): Promise<Pick<Setup.Root, 'CO2e'>> => {
    return AssetLifeAPI.get('der_types/co2e').then(res => ({ CO2e: res.data }));
  }
);

export const updateSetupCO2eItemAction: any = createAction(
  'setup/UPDATE_SETUP_CO2E_ITEM',
  async (id: number, modifier: Omit<Setup.CO2e, 'id' | 'der_type'>) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return AssetLifeAPI.put(`der_types/co2e/${id}`, modifier).then(async () => {
        await dispatch(fetchSetupCO2eAction());
        dispatch(setSuccessToastAction('CO₂e item has been updated'));
      });
    }
);

// ------------------------------------
// Raw Data Actions
// ------------------------------------
export const fetchRawDataAction: any = createAction(
  'setup/FETCH_RAW_DATA',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'rawDataCount' | 'rawDataHash' | 'rawDataMeta'>> => {
      const state = getState();
      const isPortfolioLoadflowEnabled = isPortfolioLoadflowEnabledSelector(state);
      if (!isPortfolioLoadflowEnabled) {
        return Promise.resolve({ rawDataCount: 0, rawDataHash: {}, rawDataMeta: { version: '', timeLoaded: '' } });
      }
      return plumberAPI
        .get('/scenarios/network_loading_customer_files_list', {
          params: { portfolio_id: portfolioIdSelector(state) },
        })
        .then((res: any) => ({
          rawDataCount: res.data.rows.length,
          rawDataHash: _keyBy(res.data.rows, (item: Setup.RawData) => `_${item.id}_`),
          rawDataMeta: {
            version: res.data.version,
            timeLoaded: res.data.time_loaded,
          },
        }));
    }
);

// ------------------------------------
// Calculation Schedule Actions
// ------------------------------------
export const fetchScenarioCalculationAction = createAction(
  'setup/FETCH_SCENARIO_CALCULATION',
  () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Setup.Root, 'scenarioCalculationHash'>> => {
      const state = getState();
      return AssetLifeAPI.get('shared/schedule_scenario_calculations', {
        params: { portfolio_id: portfolioIdSelector(state) },
      }).then((res: any) => ({
        scenarioCalculationHash: _keyBy(res.data, (item: Setup.ScenarioCalculation) => `_${item.scenario_id}_`),
      }));
    }
);

export const patchScenarioCalculationAction = createAction(
  'setup/PATCH_SCENARIO_CALCULATION',
  (
    scenarioId: Setup.ScenarioCalculation['scenario_id'],
    modifier: Partial<Setup.ScenarioCalculation>,
    skipPromiseResolveActions: boolean
  ) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state: State.Root = getState();
      const hash = scenarioCalculationHashSelector(state);
      const item: Setup.ScenarioCalculation = hash[`_${scenarioId}_`];
      return AssetLifeAPI.post('shared/schedule_scenario_calculations', {
        portfolio_id: portfolioIdSelector(state),
        ...item,
        ...modifier,
      }).then(async () => {
        if (skipPromiseResolveActions) return;
        await dispatch(fetchScenarioCalculationAction());
        dispatch(setSuccessToastAction('Scenario calculation has been updated'));
      });
    };
  }
);
