import React, { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ListGroup, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useLocale } from 'hooks';
import { FormInput } from 'components/_common';

const getColor = (props: any) => {
  if (props.isDragAccept) return '#00e676';
  if (props.isDragReject) return '#ff1744';
  if (props.isDragActive) return '#2196f3';
  return `${props.theme.colors.purple800};`;
};

interface Props {
  onDrop: (files: File[]) => void;
}

const Dropzone: React.FC<Props> = ({ onDrop }) => {
  const [error, setError] = useState('');
  const { getIntl } = useLocale();
  const [files, setFiles] = useState<File[]>([]);

  const handleFilesDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      setError('');
      const nextFiles = [...files, ...acceptedFiles];
      setFiles(nextFiles);
      onDrop(nextFiles);

      fileRejections.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            setError('The file is too large!');
          }

          if (err.code === 'file-invalid-type') {
            setError('Only pdf, jpeg, png formats are allowed');
          }
        });
      });
    },
    [files, onDrop]
  );

  const handleFileRemove = React.useCallback(
    (event: React.SyntheticEvent) => {
      const index: number = Number(event.currentTarget.getAttribute('data-index'));
      const nextFiles = [...files];
      nextFiles.splice(index, 1);
      setFiles(nextFiles);
      onDrop(nextFiles);
    },
    [files, onDrop]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    onDrop: handleFilesDrop,
    onFileDialogCancel: () => setError(''),
    maxSize: 1048576 * 50, // 50Мб
  });

  useEffect(() => {
    if (isDragActive) setError('');
  }, [isDragActive]);

  const handleInputChange = useCallback(
    (event: any) => {
      if (event.target.value.length > 80) return;
      const index: number = Number(event.currentTarget.getAttribute('data-index'));
      const nextFiles: any = files.map((file: any, idx) => {
        if (idx === index) file.description = event.target.value;
        return file;
      });
      setFiles(nextFiles);
    },
    [files]
  );

  return (
    <>
      <StyledContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        {isDragActive
          ? isDragAccept
            ? getIntl('Drop the files here')
            : getIntl('Only pdf, jpeg, png formats are allowed')
          : getIntl("Drag 'n' drop some files here, or click to select files")}
        {error && <small className="text-danger p-1">{getIntl(error)}</small>}
      </StyledContainer>
      {files?.map((file: any, index: number) => (
        <ListGroup className="mt-2" key={file.path}>
          <ListGroup.Item>
            <Row className="align-items-center">
              <Col xs="5">
                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer" className="text-break">
                  {file.path}
                </a>
              </Col>
              <Col xs="6" className="ml-auto">
                <FormInput
                  data-index={index}
                  size="sm"
                  labelKey=""
                  value={file.description || ''}
                  onChange={handleInputChange}
                  placeholderKey="File description"
                />
              </Col>
              <Col xs="auto">
                <button
                  type="button"
                  className="btn btn-default btn-sm w-100"
                  data-index={index}
                  onClick={handleFileRemove}
                >
                  <i className="fa fa-times" />
                </button>
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      ))}
    </>
  );
};

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${props => getColor(props)};
  color: ${props => getColor(props)};
  font-weight: 600;
  // font-size: 18px;
  border-style: solid;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export default Dropzone;
