import React from 'react';
import { Form } from 'react-bootstrap';
import { useToggle, useEventPreventedExec } from 'hooks';
import { FormInput, Modal, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { onDigitsKeyPress } from 'utils';
import { BuilderFieldsTypes } from 'constants/index';

const PhotoCollectionModal: React.FC<Builder.ModalProps> = ({
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const isEditMode: boolean = Boolean(formField);
  const [show, toggleModal] = useToggle(true);
  const formik = useConfiguredFormik({ formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={BuilderFieldsTypes.PhotoCollection}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey="Label (shown to user)"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <FormInput
            labelKey="Min Photo"
            type="number"
            min={1}
            name="min"
            value={values.min}
            onChange={handleChange}
            onKeyPress={onDigitsKeyPress}
            onBlur={handleBlur}
            errorKey={Boolean(touched.min && errors.min) ? (errors.min as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <FormInput
            labelKey="Max Photo"
            type="number"
            min={1}
            name="max"
            value={values.max}
            onChange={handleChange}
            onKeyPress={onDigitsKeyPress}
            onBlur={handleBlur}
            errorKey={Boolean(touched.max && errors.max) ? (errors.max as string) : ''}
          />
        </Form.Group>
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default PhotoCollectionModal;
