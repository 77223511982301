import * as PDFJS from 'pdfjs-dist';

export const convertPdfToPng = (file, callback) => {
  const images = [];

  try {
    PDFJS.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.js`;
    const fileReader = new FileReader();
    let currentPage = 1;

    fileReader.onload = async e => {
      const uint8array = new Uint8Array(e.target.result);
      const pdf = await PDFJS.getDocument({ data: uint8array }).promise;

      const getPage = async () => {
        const page = await pdf.getPage(currentPage);
        const viewport = page.getViewport({ scale: 1 });

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const renderContext = { canvasContext: ctx, viewport };
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render(renderContext).promise;
        canvas.toBlob(async blob => {
          const image = new File([blob], `page-${currentPage}.jpeg`, {
            lastModified: new Date(),
            type: 'image/jpeg',
          });
          images.push(image);
          if (currentPage < pdf.numPages) {
            currentPage++;
            await getPage();
          } else {
            callback(images);
          }
        });
      };

      await getPage();
    };

    fileReader.readAsArrayBuffer(file);
  } catch (error) {
    console.error(error);
    return images;
  }
};
