import { MapParams } from 'constants/index';
import { zoomInterpolateRatio, assetOpacity } from 'components/Map/core/layers/assets';
import { highlightActiveOpacity, highlightInactiveOpacity } from 'components/Map/core/layers/assets';

export const renderOtherCustomers = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        minzoom: legendItem.minz,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        isAsset: legendItem.asset,
        type: 'circle',
        iconClass: 'legend-icon legend-filter-icon',
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: `${legendItem.name}_highlight`,
          type: 'circle',
          filter: false,
          minzoom: legendItem.minz,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'circle-color': '#7345e0',
            'circle-stroke-color': '#7345e0',
            'circle-stroke-width': 3,
            'circle-stroke-opacity': [
              'case',
              [
                'any',
                ['boolean', ['feature-state', 'select'], false],
                ['boolean', ['feature-state', 'highlight'], false],
              ],
              highlightActiveOpacity,
              highlightInactiveOpacity,
            ],
            'circle-opacity': [
              'case',
              [
                'any',
                ['boolean', ['feature-state', 'select'], false],
                ['boolean', ['feature-state', 'highlight'], false],
              ],
              highlightActiveOpacity,
              highlightInactiveOpacity,
            ],
            'circle-radius': [
              'interpolate',
              ['exponential', zoomInterpolateRatio],
              ['zoom'],
              10,
              5,
              MapParams.maxZoom,
              60,
            ],
            'circle-pitch-alignment': 'map',
          },
        },
        {
          id: legendItem.name,
          showPopup: true,
          hasData: true,
          type: 'symbol',
          minzoom: legendItem.minz,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'icon-opacity': assetOpacity,
          },
          layout: {
            'icon-image': 'icon-customer-blue',
            'icon-size': [
              'interpolate',
              ['exponential', zoomInterpolateRatio],
              ['zoom'],
              10,
              0.08,
              MapParams.maxZoom,
              1.0,
            ],
            'icon-anchor': 'center',
            'icon-pitch-alignment': 'map',
            'icon-ignore-placement': true,
            'icon-allow-overlap': true, // icon can overlap other elements
          },
        },
      ],
    },
  };
  return [style];
};
