import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { issuesLastUpdateSelector } from 'modules/dataQuality/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { ScenarioRunInfo } from 'components';
import SelectIssuesStatus from './SelectIssuesStatus';
import TableIssues from './TableIssues';
import { DataTableTemplate } from 'components/_common';
import { PaginationType } from 'constants/index';

const ViewIssues: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ISSUES;
  const dataQualityLastUpdate: string | null = useSelector(issuesLastUpdateSelector);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const handleStatusSelectChange = useCallback(
    (dataQualityIssuesStatus: DataQuality.SelectIssuesStatusValues) => {
      dispatch(
        setPaginationAction({ type, modifier: { filters: { ...filters, dataQualityIssuesStatus }, offset: 0 } })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectIssuesStatus
            mutedTextLabelKey="Status"
            value={filters?.dataQualityIssuesStatus}
            onChange={handleStatusSelectChange}
            variant="small"
          />
        </Col>
        <Col xs="auto" className="d-flex ml-auto">
          <ScenarioRunInfo>
            <span className="text-muted">{getIntl('Last update')}:</span>{' '}
            <span className="text-bold">{dataQualityLastUpdate ? dateFormat(dataQualityLastUpdate, 'L LT') : '-'}</span>
          </ScenarioRunInfo>
        </Col>
      </Row>
      <TableIssues />
    </DataTableTemplate>
  );
};

export default ViewIssues;
