import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { customersConnectedCountSelector, customersConnectedSelector } from 'modules/dataQuality/selectors';
import { fetchCustomersConnectedAction } from 'modules/dataQuality';
import { simulationIdSelector } from 'modules/layouts/selectors';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableCustomersConnected: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const simulationId = useSelector(simulationIdSelector);
  const items = useSelector(customersConnectedSelector);
  const count = useSelector(customersConnectedCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      return dispatch(fetchCustomersConnectedAction(params)).then(
        (action: Shared.ReduxAction<{ customersConnected: DataQuality.Root['customersConnected'] }>) =>
          action.payload?.customersConnected || []
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.CUSTOMERS_CONNECTED}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      containerClassName="border-top-0"
      // waitForDependencies={!simulationId} // optional field
      triggerTableUpdateDeps={[simulationId]}
      maxHeight="calc(100vh - 165px)"
    >
      {items?.map((item: DataQuality.CustomersConnected) => (
        <tr key={item.index}>
          <td>{item.station || '-'}</td>
          <td>{item.installations_connected_pc ?? '-'}</td>
          <td>{item.data_version ?? '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCustomersConnected;
