import styled from 'styled-components';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { fetchModelsByAssetCategoryAction } from 'modules/assets';
import { Button } from 'components/_common';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { AssetCategories } from 'constants/index';
import useConfiguredFormik from './useConfiguredFormik';
import SubComponentItem from './SubComponentItem';
import AddSubComponent from './AddSubComponent';

interface Props {
  assetMeta: Asset.ExtendedItem;
  assetCategoryCode?: Type.AssetCategories;
}

const FormAssetSubComponents: React.FC<Props> = ({ assetMeta, assetCategoryCode }) => {
  const formik = useConfiguredFormik(assetMeta);
  const { values, dirty, isSubmitting, setFieldValue, submitForm, resetForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const dispatch: Shared.CustomDispatch = useDispatch();
  const [assetModels, setAssetModels] = useState<AssetModels.Item[] | null>(null);

  useEffect(() => {
    if (!assetCategoryCode) return;
    dispatch(fetchModelsByAssetCategoryAction(assetCategoryCode)).then(
      (action: Shared.ReduxAction<AssetModels.Item[]>) => setAssetModels(action.payload)
    );
  }, [assetCategoryCode, dispatch]);

  const manufacturerOptions = useMemo(() => {
    if (!assetModels) return [];
    const { items } = assetModels.reduce(
      (acc: any, item: AssetModels.Item) => {
        if (acc.memo.includes(item.manufacturerId)) return acc;
        acc.memo.push(item.manufacturerId);
        acc.items.push({ value: item.manufacturerId, label: item.manufacturerName });
        return acc;
      },
      { items: [], memo: [] }
    );
    return items;
  }, [assetModels]);

  const handleManufacturerChange = useCallback(
    (value: { value: number; label: string }, actionMeta: { name: string }): void => {
      const index = Number(actionMeta.name);
      const modelsClone = _cloneDeep(values.models);
      _set(modelsClone, `[${index}].manufacturerId`, value?.value || null);
      _set(modelsClone, `[${index}].manufacturerName`, value?.label || '');
      _set(modelsClone, `[${index}].modelId`, null);
      _set(modelsClone, `[${index}].modelName`, '');

      setFieldValue('models', modelsClone);
    },
    [values.models, setFieldValue]
  );

  const handleAssetModelChange = useCallback(
    (value: { value: number; label: string }, actionMeta: { name: string }): void => {
      const index = Number(actionMeta.name);
      const modelsClone = _cloneDeep(values.models);
      _set(modelsClone, `[${index}].modelId`, value?.value || null);
      _set(modelsClone, `[${index}].modelName`, value?.label || '');
      if (!value?.value) {
        _set(modelsClone, `[${index}].manufacturerId`, null);
        _set(modelsClone, `[${index}].manufacturerName`, '');
      }
      setFieldValue('models', modelsClone);
    },
    [values.models, setFieldValue]
  );

  const handleRemoveSubcomponentButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      if (!values.models.length) return;
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      setFieldValue(
        'models',
        values.models.filter(m => m.subcomponentId !== id)
      );
    },
    [values.models, setFieldValue]
  );

  if (!assetCategoryCode) return null;
  return (
    <StyledForm onSubmit={handleFormSubmit} data-dirty={dirty}>
      {values.models.map((model: Asset.AssetModel, index: number) => (
        <SubComponentItem
          key={model.subcomponentId || index}
          index={index}
          categoryCode={assetCategoryCode}
          models={values.models}
          manufacturerOptions={manufacturerOptions}
          assetModels={assetModels}
          handleManufacturerChange={handleManufacturerChange}
          handleAssetModelChange={handleAssetModelChange}
          handleRemoveSubcomponentButtonClick={handleRemoveSubcomponentButtonClick}
          marginBottom={values.models.length - 1 !== index || assetCategoryCode === AssetCategories.BD}
        />
      ))}
      {assetCategoryCode === AssetCategories.BD && (
        <AddSubComponent models={values.models} setFieldValue={setFieldValue} />
      )}
      {dirty && (
        <StyledControlsContainer>
          <Button variant="primary-outline" labelKey="Cancel" onClick={() => resetForm()} disabled={isSubmitting} />
          <Button
            type="submit"
            variant="primary"
            labelKey="Save"
            marginLeft
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </StyledControlsContainer>
      )}
    </StyledForm>
  );
};

const StyledForm = styled(Form)<{ 'data-dirty': boolean }>`
  margin-bottom: ${props => (props['data-dirty'] ? 63 : 0)}px;
`;

const StyledControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 20px;
  background: ${props => props.theme.colors.white};
  border-top: solid 1px ${props => props.theme.colors.grey100};
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.05);

  > button {
    width: 100%;
  }
`;

export default FormAssetSubComponents;
