import React, { useState, useCallback, useMemo } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { generatePath, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { useLocale, useBuilderState } from 'hooks';
import { BuilderBlockList, BuilderTypesMenu, BuilderPublishButton, BuilderFieldsRenderer } from 'components/_builder';
import { InspectionsModal, InstructionsModal, FormsModal } from 'components/_modals';
import { BackToTableButton, DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { _cloneDeep } from '@utiligize/shared/utils';
import { Routes, PermissionsTypes } from 'constants/index';

interface Props {
  isFormBuilder?: boolean;
  isInstructionBuilder?: boolean;
  isToolInspectionBuilder?: boolean;
}

const PageBuilder: React.FC<Props> = ({
  isFormBuilder = false,
  isInstructionBuilder = false,
  isToolInspectionBuilder = false,
}) => {
  const { getIntl } = useLocale();
  const { selectValue, blocks, selectedBlock, setLocationState } = useBuilderState();

  const [buttonFieldType, setButtonFieldType] = useState<Builder.FieldsTypes | null>(null);

  const handleAddFieldClick = useCallback(
    (formField: Builder.Field) => {
      if (selectedBlock === null) return;
      const nextBlocks = _cloneDeep(blocks);
      (nextBlocks as any)[selectedBlock].fields.push(formField);
      (nextBlocks as any)[selectedBlock].fields.forEach((itemField: Builder.Field, index: number) => {
        itemField.index = index;
        itemField.indexBlock = selectedBlock;
      });
      setLocationState({ blocks: nextBlocks });
    },
    [blocks, selectedBlock, setLocationState]
  );

  const handleCloseModalClick = useCallback((): void => setButtonFieldType(null), []);

  const renderFieldModal = (): React.ReactNode => {
    if (buttonFieldType) {
      return (
        <BuilderFieldsRenderer
          type={buttonFieldType}
          onCloseModal={handleCloseModalClick}
          addField={handleAddFieldClick}
        />
      );
    }
  };

  const { titleKey, to } = useMemo(() => {
    switch (true) {
      case isInstructionBuilder:
        return { titleKey: 'Instruction builder', to: Routes.Instructions };
      case isToolInspectionBuilder:
        return { titleKey: 'Tool inspection builder', to: Routes.Inspections };
      case isFormBuilder:
        return { titleKey: 'Form builder', to: Routes.Forms };
      default:
        return { titleKey: '', to: Routes.Root };
    }
  }, [isFormBuilder, isInstructionBuilder, isToolInspectionBuilder]);

  const renderOptionModal = () => {
    switch (true) {
      case isToolInspectionBuilder:
        return <InspectionsModal isEditMode />;
      case isInstructionBuilder:
        return <InstructionsModal isEditMode />;
      case isFormBuilder:
        return <FormsModal isEditMode />;
      default:
        return null;
    }
  };

  if (!selectValue) return <Redirect to={to} />;
  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isFormsEnabled}>
      <DataTableTemplate title={`${getIntl(titleKey)} - ${selectValue.name}`}>
        <React.Fragment />
        <Row>
          <Col>
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <Link to={generatePath(to)}>
                    <BackToTableButton />
                  </Link>
                  {renderOptionModal()}
                  <BuilderPublishButton
                    isFormBuilder={isFormBuilder}
                    isInstructionBuilder={isInstructionBuilder}
                    isToolInspectionBuilder={isToolInspectionBuilder}
                  />
                </div>
              </div>
              <Container fluid style={{ height: 'calc(100vh - 191px)' }}>
                <Row className="h-100">
                  <Col md={2} className="h-100 border-right overflow-auto">
                    <BuilderTypesMenu setButtonFieldType={setButtonFieldType} />
                  </Col>
                  <Col md={10} className="h-100" style={{ overflowY: 'scroll' }}>
                    <BuilderBlockList
                      addField={handleAddFieldClick}
                      isInstructionBuilder={isInstructionBuilder}
                      isToolInspectionBuilder={isToolInspectionBuilder}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </DataTableTemplate>
      {renderFieldModal()}
    </PagePermissionsProvider>
  );
};

export default PageBuilder;
