import styled from 'styled-components';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IconChevronLeft, IconLogoWhite } from '@utiligize/shared/resources';

interface Props {
  isMobile: boolean;
  isSidebarOpen: boolean;
  DefaultRoute: string;
  toggleSidebar: () => Promise<void>;
  closeSidebar: () => Promise<void>;
  renderMenu: () => React.JSX.Element;
  topLogo: React.ReactElement;
}

const Sidebar: React.FC<Props> = ({
  isMobile,
  isSidebarOpen,
  DefaultRoute,
  toggleSidebar,
  closeSidebar,
  renderMenu,
  topLogo,
}) => {
  // Close sidebar automatically when resized to mobile
  useEffect(() => {
    if (isMobile) closeSidebar();
  }, [isMobile, closeSidebar]);

  return (
    <StyledSidebar className={classNames('main-sidebar', { closed: !isSidebarOpen })}>
      <StyledHeader>
        <StyledTopLogo to={DefaultRoute}>{topLogo}</StyledTopLogo>
        <StyledButton onClick={toggleSidebar} aria-label="toggle sidebar" data-marker="toggle_sidebar">
          <IconChevronLeft />
        </StyledButton>
      </StyledHeader>
      <StyledNavigation className="sidebar" $isSidebarOpen={isSidebarOpen}>
        <nav>
          <ul className="nav nav-pills nav-sidebar flex-column" role="menu">
            {renderMenu()}
          </ul>
        </nav>
      </StyledNavigation>
      <StyledBottomLogo to={DefaultRoute}>
        <IconLogoWhite />
      </StyledBottomLogo>
    </StyledSidebar>
  );
};

const StyledSidebar = styled.aside`
  &&& {
    background: ${props => props.theme.colors.purple800};
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    z-index: 1030;
  }

  &&&&.closed {
    width: 60px;
    margin-left: 0;
  }
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1px;
  height: 53px;
  flex-shrink: 0;

  ${StyledSidebar}.closed & {
    justify-content: center;
  }
`;

const StyledTopLogo = styled(Link)`
  height: 100%;
  
  ${StyledSidebar}.closed & {
    display: none;
  }

  > svg {
    width: 120px;
    height: 100%;
`;

const StyledBottomLogo = styled(Link)`
  margin-top: auto;

  ${StyledSidebar}.closed & {
    display: none;
  }

  > svg {
    width: 150px;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
  }
`;

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: ${props => props.theme.colors.purple800};
  border-radius: 8px;
  border: none;
  padding: 0;
  margin-right: 15px;

  ${StyledSidebar}.closed & {
    transform: rotate(0.5turn);
    margin-right: 0;
  }

  > svg {
    fill: #ffffff;
    width: 20px;
    height: 20px;
  }
`;

const StyledNavigation = styled.div<{ $isSidebarOpen: boolean }>`
  &&& {
    padding: 0 ${props => (props.$isSidebarOpen ? 5 : 10)}px;
    height: unset;
  }
`;

export default Sidebar;
