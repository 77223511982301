import React, { useEffect, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import RangeSlider from 'react-bootstrap-range-slider';
import { useDebouncedCallback } from 'use-debounce';
import { useLocale } from 'hooks';
import { reliabilityVoltageOptionSelector, reliabilitySAIFIValueSelector } from 'modules/layouts/selectors';
import { saifiConfigSelector } from 'modules/reliability/selectors';
import { fetchSAIFIRangeSliderConfigAction } from 'modules/reliability';
import { setReliabilitySAIFIValue } from 'modules/layouts';
import { FormInput } from 'components/_common';

const RangeSliderSAIFI: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reliabilityVoltageOption = useSelector(reliabilityVoltageOptionSelector);
  const reliabilitySAIFIValue = useSelector(reliabilitySAIFIValueSelector);
  const config = useSelector(saifiConfigSelector);
  const [value, setValue] = React.useState<number | null>(null);

  useEffect(() => {
    if (!reliabilityVoltageOption || config) return;
    dispatch(fetchSAIFIRangeSliderConfigAction(String(reliabilityVoltageOption.value)));
  }, [dispatch, reliabilityVoltageOption, config]);

  const debounced = useDebouncedCallback((reliabilitySAIFIValue: number) => {
    dispatch(setReliabilitySAIFIValue(reliabilitySAIFIValue));
  }, 1500);

  useEffect(() => {
    if (value === null && reliabilitySAIFIValue) {
      return setValue(reliabilitySAIFIValue);
    }
    if (value === reliabilitySAIFIValue) return;
    debounced(value as number);
  }, [debounced, value, reliabilitySAIFIValue]);

  useEffect(() => debounced.cancel, [debounced.cancel]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.currentTarget.value);
      if (!config) return;
      setValue(value > config?.max ? config?.max : value < config?.min ? config?.min : value);
    },
    [config]
  );

  const handleInputBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.currentTarget.value);
      if (!value && config) setValue(config?.min);
    },
    [config]
  );

  if (!config) return null;
  return (
    <>
      <Form.Label>{getIntl('SAIFI index')}</Form.Label>
      <Row className="justify-content-center">
        <Col sm="auto">
          <FormInput
            value={value || 0}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            style={{ width: '75px' }}
            type="number"
            min={config.min}
            max={config.max}
            size="sm"
            step={config.step}
          />
        </Col>
        <Col>
          <RangeSlider
            value={value || 0}
            tooltipPlacement="top"
            onChange={handleInputChange}
            min={config.min}
            max={config.max}
            step={config.step}
            size="sm"
            tooltipLabel={value => `${value}`}
          />
        </Col>
      </Row>
    </>
  );
};

export default RangeSliderSAIFI;
