import React from 'react';
import { useLocale } from 'hooks';

const withIntl = <P extends object>(WrappedComponent: React.FC<P>) => {
  return React.forwardRef<HTMLButtonElement, P>((props, ref) => {
    const { getIntl } = useLocale();
    return <WrappedComponent {...(props as P)} getIntl={getIntl} ref={ref} />;
  });
};

export default withIntl;
