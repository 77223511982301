import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { newLoadDERsCategoriesModalIdSelector } from 'modules/layouts/selectors';
import { setNewLoadDERsCategoriesModalIdAction } from 'modules/layouts';
import { DERsCategoryHashSelectorFactory } from 'modules/newLoad/selectors';
import { Modal, Button, FormInput, HiddenFormSubmit } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalSectionCategory: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(newLoadDERsCategoriesModalIdSelector);
  const DERCategory: NewLoad.DERCategory | undefined = useSelector(DERsCategoryHashSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && DERCategory);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setNewLoadDERsCategoriesModalIdAction(null));
    setShow(!show);
  }, [id, show, dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, setValues, resetForm } =
    useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!DERCategory) return;
    setValues({
      section: DERCategory.section,
      category: DERCategory.category || '',
    });
    setShow(true);
  }, [DERCategory, setValues]);

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Add DERs category" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit DERs category' : 'Add DERs category'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus={!isEditMode}
              labelKey="Section"
              name="section"
              value={values.section}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.section && errors.section) ? (errors.section as string) : ''}
              disabled={isEditMode}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              autoFocus={isEditMode}
              labelKey="Category"
              name="category"
              value={values.category}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.category && errors.category) ? (errors.category as string) : ''}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalSectionCategory;
