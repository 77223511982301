import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';
import { Routes } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.assets;

export const categoriesFetchedSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): boolean => assets.categoriesFetched
);

const categoriesHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.Category> => assets.categoriesHash
);

export const categoriesSelector = createSelector(
  categoriesHashSelector,
  (categoriesHash: Type.Hash<Asset.Category>): Asset.Category[] => Object.values(categoriesHash)
);

export const categoriesCountSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): number => assets.categoriesCount
);

const assetsHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.Item> => assets.assetsHash
);

export const assetsCountSelector = createSelector(rootSelector, (assets: Asset.Root): number => assets.assetsCount);

export const assetsSelector = createSelector(assetsHashSelector, (assetsHash: Type.Hash<Asset.Item>): Asset.Item[] =>
  Object.values(assetsHash)
);

const subComponentsHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.SubComponentType> => assets.subComponentsHash
);

export const subComponentsCountSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): number => assets.subComponentsCount
);

export const subComponentsSelector = createSelector(
  subComponentsHashSelector,
  (subComponentsHash: Type.Hash<Asset.SubComponentType>): Asset.SubComponentType[] => Object.values(subComponentsHash)
);

export const subComponentSelectorFactory = (id: number | null) =>
  createSelector(
    subComponentsHashSelector,
    (subComponentsHash: Type.Hash<Asset.SubComponentType>): Asset.SubComponentType => subComponentsHash[`_${id}_`]
  );

export const smartMetersHashFetchedSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): boolean => assets.smartMetersHashFetched
);

const smartMetersHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.SmartMeter> => assets.smartMetersHash
);

export const smartMetersSelector = createSelector(
  smartMetersHashSelector,
  (smartMetersHash: Type.Hash<Asset.SmartMeter>): Asset.SmartMeter[] => Object.values(smartMetersHash)
);

export const smartMetersSelectorFactory = (id: number | null) =>
  createSelector(
    smartMetersHashSelector,
    (smartMetersHash: Type.Hash<Asset.SmartMeter>): Asset.SmartMeter => smartMetersHash[`_${id}_`]
  );

export const taskCompletionsSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Tasks.TaskCompletion[] => assets.taskCompletions
);

export const taskCompletionsCountSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): number => assets.taskCompletionsCount
);

export const missingAgeCountSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): number => assets.missingAgeCount
);

const missingAgeHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.MissingAge> => assets.missingAgeHash
);

export const missingAgeItemsSelector = createSelector(
  missingAgeHashSelector,
  (missingAgeHash: Type.Hash<Asset.MissingAge>): Asset.MissingAge[] => Object.values(missingAgeHash)
);

export const assetMetaSelector = createSelector(
  [createMatchSelector({ path: Routes.Map }) as any, rootSelector],
  (match: any, assets: Asset.Root): Asset.Root['assetMeta'] => {
    // UAV, must reset asset meta immediately on uuid change event
    return match?.params?.uuid === assets.assetMeta?.id ? assets.assetMeta : null;
  }
);
