import { useFormik } from 'formik';
import * as Yup from 'yup';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { FIELDS, BuilderFieldsTypes } from 'constants/index';

interface Values {
  text: string;
  number: number;
}

const useConfiguredFormik = ({
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      text: String(formField?.params?.[0]?.values?.[0]?.value || ''),
      number: Number(formField?.params?.[1]?.values?.[0]?.value || 0),
    },

    validationSchema: Yup.object().shape({
      text: Yup.string().required("Field can't be empty!"),
      number: Yup.number().min(0, 'Must be greater than or equal to zero').required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('NumberInputInspectionModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[BuilderFieldsTypes.NumberInputInspection]);
      _set(field, 'params[0].values[0].value', values.text);
      _set(field, 'params[1].values[0].value', values.number);
      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
