import styled from 'styled-components';
import React from 'react';

interface Props {
  getIntl?: (localeKey: string, options?: {}) => string;
}

const PageError: React.FC<Props> = ({ getIntl = str => str }) => (
  <StyledContainer>
    <span>500</span>
    <div>
      <h4>
        <i className="fas fa-exclamation-triangle" /> {getIntl('Oops! Something went wrong')}.
      </h4>
      <p>
        {getIntl(
          'We will work on fixing that right away. Meanwhile, you can try to refresh the page or contact administrator'
        )}
        .
      </p>
    </div>
  </StyledContainer>
);

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  width: 600px;
  padding: 6rem 2rem;

  > span {
    font-size: 5rem; // 80px
    margin-right: ${props => props.theme.paddings.primary};
  }

  > span,
  i {
    color: ${props => props.theme.colors.brightRed};
  }

  p {
    margin-bottom: 0;
  }
`;

export default PageError;
