import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fusesCountSelector, fusesSelector } from 'modules/networkLoading/fuses.selectors';
import { fetchFusesAction } from 'modules/networkLoading';
import { setProfileModalMetaAction } from 'modules/layouts';
import { IconDetails } from '@utiligize/shared/resources';
import { DataTable, Button, SolvedOption } from 'components/_common';
import { PaginationType, ProfileModalTypes } from 'constants/index';

const NetworkLoadingFusesTable: React.FC = () => {
  const { numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.NETWORK_LOADING_FUSES;

  const items = useSelector(fusesSelector);
  const count = useSelector(fusesCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchFusesAction(params)).then(
        (action: Shared.ReduxAction<{ fusesHash: Type.Hash<NetworkLoading.Fuse> }>) =>
          Object.values(action.payload?.fusesHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(setProfileModalMetaAction({ id: assetName, type: ProfileModalTypes.Fuse }));
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={paginationType} totalAmount={count} sendRequest={sendRequest} isDownloadCSVEnabled>
      {items?.map((item: NetworkLoading.Fuse) => (
        <tr key={item.kabel}>
          <td>{item.kabel || '-'}</td>
          <td>{item.fuse || '-'}</td>
          <td className="text-center">
            <SolvedOption uuid={item.kabel} checked={item.ignore} paginationType={paginationType} />
          </td>
          <td>{item.explanation || '-'}</td>
          <td>{numberFormat(item.utilization, { fallback: '-' })}</td>
          <td className="text-right">
            <Button
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.kabel}
              onClick={handleSeeProfileBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default NetworkLoadingFusesTable;
