import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { createMapStateSelectorFactory, enabledLayersListSelector, settingsSelector } from 'modules/map/selectors';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import { showLayer } from 'utils/map';
import { updateHeatmapSource, updateHeatmapLayer } from 'components/Map/core/layers/other';
import { featureCollection } from '@turf/helpers';
import turfBboxClip from '@turf/bbox-clip';
import turfCentroid from '@turf/centroid';
import turfBbox from '@turf/bbox';
import turfBboxPolygon from '@turf/bbox-polygon';

interface Props {
  map: Map.MapboxMap;
}

const layerId = 'other__electricity_network';
const labelId = `${layerId}_label`;

const OtherLayers: React.FC<Props> = ({ map }) => {
  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const heatmapScenario = useSelector(createMapStateSelectorFactory('heatmapScenario'));
  const layerFilters = useSelector(createMapStateSelectorFactory('layerFilters'));
  const enabledLayersList = useSelector(enabledLayersListSelector);
  const heatmapLayerId = useMemo(() => settings.otherLayers!.find(i => i.includes('heatmap')), [settings.otherLayers]);
  const heatmapFilter = useMemo(() => layerFilters[heatmapLayerId!], [layerFilters, heatmapLayerId]);

  useEffect(() => {
    if (!heatmapScenario || !heatmapLayerId) return;
    updateHeatmapSource(map, heatmapLayerId, heatmapScenario);
  }, [map, heatmapScenario?.id]); // eslint-disable-line

  useEffect(() => {
    if (!heatmapLayerId || !heatmapScenario || !heatmapFilter) return;
    updateHeatmapLayer(map, heatmapLayerId, heatmapScenario, heatmapFilter, settings, selectedMapTheme);
  }, [map, selectedMapTheme, heatmapFilter, heatmapScenario?.key]); // eslint-disable-line

  useEffect(() => {
    const otherLayers = [...settings.cnaimLayers!, ...settings.otherLayers!];
    const enabledOtherLayers = otherLayers.filter(i => enabledLayersList.some(l => i.startsWith(l)));
    enabledOtherLayers.forEach(i => showLayer(map, i));
  }, [settings.cnaimLayers, settings.otherLayers]); // eslint-disable-line

  const debouncedHandler = useDebouncedCallback(() => {
    const data = featureCollection([]);
    const source = map.getSource(labelId) as mapboxgl.GeoJSONSource;
    const renderedFeatures = map.queryRenderedFeatures({ layers: [layerId] } as any);
    if (!source) return;
    if (renderedFeatures.length) {
      const mapBbox = map.getBounds().toArray().flat();
      const groupedFeatures = renderedFeatures
        .filter(f => f.properties?.name)
        .reduce(
          (result, item) => ({
            ...result,
            [item.properties?.name]: [...(result[item.properties?.name] || []), item],
          }),
          {} as Record<string, typeof renderedFeatures>
        );
      const points = Object.values(groupedFeatures).map(features => {
        const name = features[0].properties?.name;
        const bbox = turfBboxPolygon(turfBbox(featureCollection(features)));
        const clippedPolygon = turfBboxClip(bbox, mapBbox as any);
        const polygonCentroid = turfCentroid(clippedPolygon);
        polygonCentroid.properties = { name };
        return polygonCentroid;
      });
      data.features = points;
    } else {
      data.features = [];
    }
    map.moveLayer(labelId);
    source.setData(data as any);
  }, 1000);

  useEffect(() => debouncedHandler.cancel, [debouncedHandler.cancel]);

  // dynamic labels of regions for `Utiligize in the world` layer
  useEffect(() => {
    if (!enabledLayersList.includes(layerId)) return;
    if (!map.getLayer(labelId)) {
      map.addSource(labelId, { type: 'geojson', data: featureCollection([]) });
      map.addLayer({
        id: labelId,
        type: 'symbol',
        source: labelId,
        layout: {
          'text-field': ['get', 'name'],
          'text-rotation-alignment': 'viewport',
          'text-size': ['interpolate', ['linear'], ['zoom'], 4, 10, 18, 30],
          'text-max-width': Infinity,
        },
        paint: {
          'text-color': '#003166',
          'text-halo-color': '#fff',
          'text-halo-width': ['interpolate', ['linear'], ['zoom'], 4, 1, 18, 3],
        },
      });
    }

    const eventHandler = (e: mapboxgl.MapSourceDataEvent) => {
      if (e.sourceId === layerId && e.isSourceLoaded) {
        debouncedHandler();
      }
    };

    map.on('sourcedata', eventHandler);
    return () => {
      map.off('sourcedata', eventHandler);
      if (map.getStyle() && map.getLayer(layerId)) {
        map.removeLayer(labelId);
        map.removeSource(labelId);
      }
    };
  }, [map, enabledLayersList, debouncedHandler]);

  return null;
};

export default React.memo(OtherLayers);
