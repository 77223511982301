import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapStateAction } from 'modules/map';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import {
  settingsSelector,
  selectedInvestmentScenarioSelector,
  showCableAnimationSelector,
  enabledLayersListSelector,
  createMapStateSelectorFactory,
} from 'modules/map/selectors';
import { scenarioSelectedOptionSelector } from 'modules/options/selectors';
import { showLayer, hideLayer, showTheme, hideTheme, setMapSourceField } from 'utils/map';
import { updateMaxLoadLayer } from 'components/Map/core/layers/load';

interface Props {
  map: Map.MapboxMap;
}

const MaxLoad: React.FC<Props> = ({ map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const selectedScenario = useSelector(scenarioSelectedOptionSelector);
  const selectedInvestmentScenario = useSelector(selectedInvestmentScenarioSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const scenario = useSelector(createMapStateSelectorFactory('scenario'));
  const showMaxLoadVoltage = useSelector(createMapStateSelectorFactory('showMaxLoadVoltage'));
  const enabledLayersList = useSelector(enabledLayersListSelector);

  useEffect(() => {
    if (!settings.hasMaxLoadVoltage) {
      dispatch(mapStateAction({ showMaxLoadVoltage: false }));
    }
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    setMapSourceField(map, settings.maxLoadLayers!, {
      scenario_id: String(selectedScenario?.value),
    });
  }, [map, selectedScenario]); // eslint-disable-line

  useEffect(() => {
    if (!scenario) return;
    updateMaxLoadLayer(map, scenario, showMaxLoadVoltage, settings, selectedMapTheme, selectedInvestmentScenario);
  }, [map, showMaxLoadVoltage, selectedInvestmentScenario, scenario, selectedMapTheme]); // eslint-disable-line

  // show and update all layers when theme is active
  useEffect(() => {
    showTheme(map, settings.maxLoadLayers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  // hide all theme layers when theme is inactive
  useEffect(() => {
    return () => {
      hideTheme(map, [...settings.maxLoadLayers!, ...settings.maxLoadIconsLayers!]);
    };
  }, [map]); // eslint-disable-line

  // show hide icons
  useEffect(() => {
    const assets = enabledLayersList.filter(e => e.startsWith('asset__'));
    const showMaxLoadIcons = !/no investment/i.test(String(selectedInvestmentScenario?.label));
    settings.maxLoadIconsLayers!.forEach(l => {
      const action =
        showMaxLoadIcons && assets.some(e => l.includes(e.replace(/_voltage.*/gi, ''))) ? showLayer : hideLayer;
      action(map, l);
    });
  }, [map, selectedInvestmentScenario, settings.maxLoadIconsLayers, enabledLayersList]);

  return null;
};

export default React.memo(MaxLoad);
