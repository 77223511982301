import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { metersMissingInMDMSelector, metersMissingInMDMCountSelector } from 'modules/dataQuality/selectors';
import { fetchMetersMissingInMDMAction } from 'modules/dataQuality';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableMissingInMDM: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(metersMissingInMDMCountSelector);
  const items = useSelector(metersMissingInMDMSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) => {
      if (!portfolioId) return Promise.resolve();
      return dispatch(fetchMetersMissingInMDMAction({ ...params, portfolioId })).then(
        (action: Shared.ReduxAction<{ missingInMDMHash: Type.Hash<DataQuality.MissingIn> }>) =>
          Object.values(action.payload?.missingInMDMHash || {})
      );
    },
    [dispatch, portfolioId]
  );

  return (
    <DataTable
      paginationType={PaginationType.METERS_MISSING_IN_MDM}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 286px)"
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items.map((item: DataQuality.MissingIn) => (
        <tr key={`${item.installation_number}_${item.metering_point}`}>
          <td>{item.installation_number || '-'}</td>
          <td>{item.metering_point || '-'}</td>
          <td>{item.meter_number || '-'}</td>
          <td>{item.address || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableMissingInMDM;
