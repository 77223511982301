import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tasksDepartmentsSelector, tasksDepartmentsFetchedSelector } from 'modules/tasks/selectors';
import { fetchTasksDepartmentsAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectTaskDepartment: React.FC<Props> = ({ labelKey = 'Department', value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const tasksDepartmentsFetched = useSelector(tasksDepartmentsFetchedSelector);
  const shouldFetchTasksDepartments = tasksDepartmentsFetched === null;
  const tasksDepartments = useSelector(tasksDepartmentsSelector);

  useEffect(() => {
    if (!shouldFetchTasksDepartments) return;
    dispatch(fetchTasksDepartmentsAction());
  }, [shouldFetchTasksDepartments, dispatch, props.onChange]);

  const { options, selectValue } = useMemo(() => {
    return (tasksDepartments || []).reduce(
      (acc: any, department: Tasks.Department) => {
        const option = { value: department.number, label: department.name };
        if (value && option.value === value) {
          acc.selectValue = option;
        }
        acc.options.push(option);
        return acc;
      },
      { options: [], selectValue: null }
    );
  }, [tasksDepartments, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      options={options}
      isDisabled={!tasksDepartmentsFetched}
      isLoading={shouldFetchTasksDepartments}
    />
  );
};

export default SelectTaskDepartment;
