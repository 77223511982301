import { createSelector } from 'reselect';
import { rootSelector, mapStateSelector, createMapStateSelectorFactory } from 'modules/map/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { i18next } from 'utils';

// ------------------------------------
// Local Tasks Feature Selectors
// ------------------------------------
const tasksFeatureCollectionSelector = createSelector(rootSelector, map => map.tasksFeatureCollection);

export const taskDateRangeSelector = createSelector(mapStateSelector, mapState => mapState.taskDateRange);

export const tasksFilterExpressionsSelector = createSelector(
  [
    createMapStateSelectorFactory('taskDepartments'),
    createMapStateSelectorFactory('taskActions'),
    createMapStateSelectorFactory('taskTypes'),
    createMapStateSelectorFactory('taskUserEmails'),
    createMapStateSelectorFactory('taskStatuses'),
  ],
  (taskDepartments, taskActions, taskTypes, taskUserEmails, taskStatuses): any => {
    if (!taskDepartments || !taskActions || !taskTypes || !taskStatuses || !taskUserEmails) return null;
    return [
      'all',
      ['in', 'departmentName', ...taskDepartments],
      ['in', 'app_action', ...taskActions],
      ['in', 'type', ...taskTypes],
      ['in', 'status', ...taskStatuses],
      ['any', ...Array.from(Array(60).keys()).map(i => ['in', `user${i}`, ...taskUserEmails])],
    ];
  }
);

export const filteredTasksStatusesCountsSelector = createSelector(
  [
    tasksFeatureCollectionSelector,
    createMapStateSelectorFactory('taskDepartments'),
    createMapStateSelectorFactory('taskActions'),
    createMapStateSelectorFactory('taskTypes'),
    createMapStateSelectorFactory('taskUserEmails'),
    createMapStateSelectorFactory('taskStatuses'),
  ],
  (
    tasksFeatureCollection,
    taskDepartments,
    taskActions,
    taskTypes,
    taskUserEmails,
    taskStatuses
  ): Record<Map.Task['status'], { visible: number; total: number }> | null => {
    if (!taskDepartments || !taskActions || !taskTypes || !taskStatuses || !taskUserEmails) return null;
    const features = tasksFeatureCollection?.features.filter(
      ({ properties }) =>
        taskDepartments.includes(properties.departmentName) &&
        taskActions.includes(properties.app_action) &&
        taskTypes.includes(properties.type) &&
        taskUserEmails.some(email => properties.userEmailsStr.includes(email))
    );
    if (!features) return null;
    return features.reduce((acc: any, { properties, geometry }: GeoJSON.Feature<any, Map.Task>) => {
      const { status } = properties;
      const term = Number(!!geometry.coordinates.length);
      if (!acc[status]) {
        acc[status] = { visible: term, total: 1 };
      } else {
        acc[status] = { visible: acc[status].visible + term, total: acc[status].total + 1 };
      }
      return acc;
    }, {});
  }
);

const taskFiltersSelector = createSelector(rootSelector, map => map.taskFilters);

const mapTasksFiltersAvailableOptionsSelector = createSelector(
  tasksFeatureCollectionSelector,
  tasksFeatureCollection => {
    if (!tasksFeatureCollection?.features) return null;

    return tasksFeatureCollection.features.reduce<{
      departments: string[];
      actions: string[];
      types: string[];
      userEmails: string[];
      statuses: Map.Task['status'][];
    }>(
      (acc, { properties, geometry }: GeoJSON.Feature<any, Map.Task>) => {
        if (!geometry?.coordinates?.length) return acc;
        if (!acc.departments.includes(properties.departmentName)) acc.departments.push(properties.departmentName);
        if (!acc.actions.includes(properties.app_action)) acc.actions.push(properties.app_action);
        if (!acc.types.includes(properties.type)) acc.types.push(properties.type);
        // users ids array without duplicates
        acc.userEmails = [...new Set([...acc.userEmails, ...properties.userEmailsStr.split(',')])];
        if (!acc.statuses.includes(properties.status)) acc.statuses.push(properties.status);
        return acc;
      },
      { departments: [], actions: [], types: [], userEmails: [], statuses: [] }
    );
  }
);

export const mapTasksFiltersSelector = createSelector(
  [taskFiltersSelector, mapTasksFiltersAvailableOptionsSelector, filteredTasksStatusesCountsSelector, appLangSelector],
  (taskFilters, availableOptions, counts, lng) => {
    if (!taskFilters || !availableOptions) return null;
    const { departments, actions, types, userEmails, statuses } = availableOptions;
    return {
      // Note. tasks Without department synthetic item for specific needs
      departments: [
        {
          value: '',
          label: 'Without department',
          labelShort: 'Without department',
          disabled: false,
        },
      ].concat(
        taskFilters.departments?.map(i => ({
          value: i,
          label: i,
          labelShort: i,
          disabled: !departments.includes(i),
        })) || []
      ),
      actions: taskFilters.appActions.map(i => ({
        value: i,
        label: i,
        labelShort: i,
        disabled: !actions.includes(i),
      })),
      types: taskFilters.taskTypes.map(i => ({
        value: i,
        label: i,
        labelShort: i,
        disabled: !types.includes(i),
      })),
      // Note. Unassigned tasks synthetic item for specific needs
      users: [
        {
          value: '',
          label: 'Unassigned tasks',
          labelShort: 'Unassigned',
          disabled: false,
        },
      ].concat(
        taskFilters.users.map(user => ({
          value: user.email,
          label: `${user.fullName} (${user.email})`,
          labelShort: user.fullName,
          disabled: !userEmails.includes(user.email),
        }))
      ),
      statuses: taskFilters.statuses.map(i => ({
        value: i,
        label: `${i18next.t(i, { lng })} (${i18next.t('{{visible}} of {{total}} tasks has coordinates', { lng, visible: counts?.[i]?.visible || 0, total: counts?.[i]?.total || 0 })})`,
        labelShort: i,
        disabled: !statuses.includes(i),
      })),
    };
  }
);
