import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { createUserOneTimePasswordSelector } from 'modules/layouts/selectors';
import { setInfoToastAction, setLayoutAction } from 'modules/layouts';
import { Modal, Button, FormInput, Alert } from 'components/_common';
import { IconDuplicate } from '@utiligize/shared/resources';

const ModalOneTimePassword: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const password = useSelector(createUserOneTimePasswordSelector);
  const [show, setShow] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password!);
    dispatch(setInfoToastAction('Password has been copied to clipboard'));
  };

  const toggleModal = useCallback((): void => {
    setShow(show => {
      // Reset redux layouts state for isEditMode
      if (show && password) dispatch(setLayoutAction({ createUserOneTimePassword: null }));
      return !show;
    });
  }, [password, dispatch]);

  useEffect(() => {
    if (password) setShow(true);
  }, [password]);

  return (
    <>
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="User one-time password"
        cancelButtonProps={{ onClick: toggleModal, labelKey: 'Close' }}
      >
        <>
          <Alert className="text-center" variant="warning">
            {getIntl('Please ensure that you save the one-time password before closing this modal window')}.
            <br />
            {getIntl("Once closed, you won't be able to retrieve it")}.
          </Alert>
          <Form.Row>
            <Form.Group as={Col} className="flex-grow-1">
              <FormInput size="lg" isNew labelKey="" value={password || ''} readOnly />
            </Form.Group>
            <Form.Group as={Col} xs="auto">
              <Button icon={<IconDuplicate />} onClick={copyToClipboard} size="large" />
            </Form.Group>
          </Form.Row>
        </>
      </Modal>
    </>
  );
};

export default ModalOneTimePassword;
