import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useLocale } from 'hooks';
import { tasksGanttChartFetchedSelector, tasksGanttChartSelector } from 'modules/employees/selectors';
import { fetchTasksGanttChartAction } from 'modules/employees';
import { TaskTypeBadge, TaskStatusBadge, ShowUsersOverlay, ContentContainer, Spinner } from 'components/_common';
import useTableData from './useTableData';
import TableWrapper from './TableWrapper';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import { Languages } from 'constants/index';

const TasksGanttChart: React.FC = () => {
  const { lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const tasksGanttChartFetched = useSelector(tasksGanttChartFetchedSelector);
  const [loading, setLoading] = useState(false);
  const tasksGanttChart = useSelector(tasksGanttChartSelector);
  const { filters } = useTableData();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchTasksGanttChartAction()).then(() => {
      setLoading(false);
      const element = document.getElementById('current-week');
      element?.scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
    });
  }, [dispatch, filters]);

  if (!tasksGanttChartFetched || loading) {
    return (
      <ContentContainer>
        <Spinner inCard isInFullHeightContainer />
      </ContentContainer>
    );
  }

  return (
    <TableWrapper>
      <>
        <TableHeader isTasksGanttChart />
        <tbody>
          {tasksGanttChart.map((task: Tasks.Task) => (
            <TableRow
              key={task.id}
              task={task}
              firstCell={
                <td className={classNames('sticky first', { 'first-170': lng === Languages.DA })}>
                  <span className="d-flex justify-content-between align-items-center">
                    <TaskTypeBadge id={task.id} type={task.type} className="mr-2" />
                    <TaskStatusBadge status={task.status} className="mr-auto" />
                    <ShowUsersOverlay
                      popoverId={task.id}
                      userIds={task.assignedUsers.map(user => user.id)}
                      responsibleUserIds={task.responsibleUsers.map(user => user.id)}
                      placement="right"
                    />
                  </span>
                </td>
              }
              secondCell={
                <td className={classNames('sticky second', { 'second-225': lng === Languages.DA })}>{task.name}</td>
              }
            />
          ))}
        </tbody>
      </>
    </TableWrapper>
  );
};

export default TasksGanttChart;
