import { createSelector } from 'reselect';
import { generatePath, matchPath } from 'react-router-dom';
import { getLocation, createMatchSelector } from 'connected-react-router';
import { Routes } from 'constants/index';

const isPageActiveSelectorFactory = (path: string) =>
  createSelector(getLocation as any, (location: Location): boolean =>
    Boolean(matchPath(`${location.pathname}${location.hash}`, { path, exact: true }))
  );

// ------------------------------------
// Selectors
// ------------------------------------
export const isPageAssetsCategoriesCNAIMSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: Routes.AssetsCategoriesCNAIM, exact: true }))
);

export const isPageHistorySelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: [Routes.History, Routes.HistoryAnswers], exact: true }))
);

export const isPageNetworkLoadingProfileTabActiveSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(`${location.pathname}${location.hash}`, {
        path: `${Routes.NetworkLoadingTotalLoading}${Routes.HASH.Profile}`,
        exact: true,
      })
    )
);

export const isPageTasksTimeOverviewTasksHoursTabActiveSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(`${location.pathname}${location.hash}`, {
        path: `${Routes.TasksTimeOverview}${Routes.HASH.TasksHours}`,
        exact: true,
      })
    )
);

export const isPageTasksTimeOverviewUsersHoursTabActiveSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(`${location.pathname}${location.hash}`, {
        path: `${Routes.TasksTimeOverview}${Routes.HASH.UsersHours}`,
        exact: true,
      })
    )
);

// ------------------------------------
// Tasks page selectors
// ------------------------------------
export const isPageNewTasksTabActiveSelector = isPageActiveSelectorFactory(
  `${generatePath(Routes.Tasks)}${Routes.HASH.New}`
);

export const isPageTasksComplaintsTabActiveSelector = isPageActiveSelectorFactory(
  `${generatePath(Routes.Tasks)}${Routes.HASH.Complaints}`
);

export const isPageTasksTabActiveSelector = isPageActiveSelectorFactory(generatePath(Routes.Tasks));

export const isPageTaskDetailsActiveSelectorFactory = (id: number | null) =>
  isPageActiveSelectorFactory(generatePath(Routes.Tasks, { id: id! }));

export const isPageTasksMobileSubmittedTabActiveSelector = isPageActiveSelectorFactory(
  generatePath(`${generatePath(Routes.Tasks)}${Routes.HASH.MobileSubmitted}`)
);

export const isPageTasksUpcomingTabActiveSelector = isPageActiveSelectorFactory(
  generatePath(`${generatePath(Routes.Tasks)}${Routes.HASH.Upcoming}`)
);

export const isPageTasksFinishedTabActiveSelector = isPageActiveSelectorFactory(
  generatePath(`${generatePath(Routes.Tasks)}${Routes.HASH.Finished}`)
);

export const isPageTasksChecklistsTabActiveSelector = createSelector(
  getLocation as any,
  (location: Location): boolean =>
    Boolean(
      matchPath(`${location.pathname}${location.hash}`, {
        path: `${generatePath(Routes.Tasks)}${Routes.HASH.Checklists}`,
        exact: true,
      })
    )
);

export const isPageTasksEmployeesGanttChartTabActiveSelector = isPageActiveSelectorFactory(
  generatePath(Routes.TasksEmployees)
);

export const isPageTasksTasksGanttChartTabActiveSelector = isPageActiveSelectorFactory(
  generatePath(`${generatePath(Routes.TasksEmployees)}${Routes.HASH.Diagram}`)
);

export const isPageTasksEmployeesActiveSelector = isPageActiveSelectorFactory(
  generatePath(`${generatePath(Routes.TasksEmployees)}${Routes.HASH.Table}`)
);

export const isPageInterventionsSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: Routes.Interventions, exact: false }))
);

export const isPageAssetsSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: Routes.Assets, exact: false }))
);

export const isPageNewLoadSelector = createSelector(getLocation as any, (location: Location): boolean =>
  Boolean(matchPath(location.pathname, { path: Routes.NewLoad, exact: false }))
);

// Todo. New smart way to use router selectors
export const isRouteActiveSelectorFactory = (path: string, exact: boolean = true) =>
  createSelector(getLocation as any, (location: Location): boolean =>
    Boolean(matchPath(`${location.pathname}${exact ? location.hash : ''}`, { path, exact }))
  );

export const mapPanelSelectedAssetUUIDSelector = createSelector(
  createMatchSelector({ path: Routes.Map }),
  (match: any): string => match?.params?.uuid || ''
);

export const assetDetailsSelectedAssetCodeSelector = createSelector(
  createMatchSelector({ path: Routes.AssetDetails }),
  (match: any): string => match?.params?.assetCode || ''
);
