import styled from 'styled-components';
import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec, useLocale, useSplitPermissionsByGroups } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsGroupsModalIdSelector } from 'modules/layouts/selectors';
import { setPermissionsGroupsModalIdAction } from 'modules/layouts';
import { permissionsGroupSelectorFactory, permissionsTypesSelector } from 'modules/permissions/selectors';
import { fetchPermissionsTypesAction } from 'modules/permissions';
import { Modal, Button, FormInput, HiddenFormSubmit, Alert, Checkbox } from 'components/_common';
import { sortPermissions } from 'utils';
import useConfiguredFormik from './useConfiguredFormik';
import { IconPlus } from '@utiligize/shared/resources';

const ModalGroups: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(permissionsGroupsModalIdSelector);
  const permissionsTypes: Permissions.PermissionsTypes[] | null = useSelector(permissionsTypesSelector);
  const permissionsGroup: Permissions.Group | null = useSelector(permissionsGroupSelectorFactory(id));
  const isEditMode: boolean = Boolean(id);
  const [show, setShow] = useState(false);

  useEffect(() => {
    // pre-fetch permissions types, for create group modal flow
    if (!permissionsTypes) dispatch(fetchPermissionsTypesAction());
  }, [permissionsTypes, dispatch]);

  const toggleModal = useCallback((): void => {
    if (!permissionsTypes) return;
    setShow(show => {
      // Reset redux layouts state for isEditMode
      if (show && id) dispatch(setPermissionsGroupsModalIdAction(null));
      return !show;
    });
  }, [permissionsTypes, id, dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, submitForm, setValues, resetForm } =
    useConfiguredFormik({ id, permissionsTypes, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!permissionsGroup) return;
    setValues({
      name: permissionsGroup.name,
      permissionsTypes: (Object.keys(permissionsGroup.permissionsTypes) as Permissions.PermissionsTypes[])
        .sort(sortPermissions)
        .reduce((acc: Permissions.Permissions, key: string) => {
          (acc as any)[key] = permissionsGroup.permissionsTypes[key as Permissions.PermissionsTypes];
          return acc;
        }, {} as Permissions.Permissions),
      isDefault: permissionsGroup.isDefault,
    });
    setShow(true); // open modal on edit mode, once data initialized
  }, [permissionsGroup, setValues]);

  const { views, taskType, assetCategories, readWrite } = useSplitPermissionsByGroups({
    objKey: 'permissionsTypes',
    permissions: values.permissionsTypes,
    onChange: handleChange,
  });

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create group" onClick={toggleModal} size="large" variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit group' : 'Create group'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <StyledForm onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <Checkbox
              className="pl-0 icheck-primary"
              name="isDefault"
              checked={values.isDefault}
              labelKey="Default group"
              onChange={handleChange}
              disabled={values.isDefault && isEditMode}
            />
          </Form.Group>
          <hr />
          <Form.Row>{views}</Form.Row>
          {Boolean(touched.permissionsTypes && errors.permissionsTypes) && (
            <Alert show variant="warning" className="mt-3 mb-0">
              {getIntl(errors.permissionsTypes as string)}
            </Alert>
          )}
          <hr />
          <Form.Row>{taskType}</Form.Row>
          <hr />
          <Form.Row>{assetCategories}</Form.Row>
          <hr />
          <Form.Row>{readWrite}</Form.Row>
          <HiddenFormSubmit />
        </StyledForm>
      </Modal>
    </>
  );
};

const StyledForm = styled(Form)`
  .form-row > .form-group:last-child {
    margin-bottom: 0;
  }
`;

export default ModalGroups;
