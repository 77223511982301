import React, { useState, useEffect, memo, useCallback } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDERsDescriptionOptionsAction } from 'modules/options';
import { DERsDescriptionOptionsSelector } from 'modules/options/selectors';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  name: string;
  value?: number | null;
  label?: string;
  setFieldValue: (field: string, value: any) => void;
  onBlur: (event: React.SyntheticEvent) => void;
  errorKey: string;
  isDisabled?: boolean;
}

const SelectDERsDescription: React.FC<Props> = memo(({ name, value, label, setFieldValue, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const DERsDescriptionOptions = useSelector(DERsDescriptionOptionsSelector);

  useEffect(() => {
    if (DERsDescriptionOptions) return;
    setLoading(true);
    dispatch(fetchDERsDescriptionOptionsAction()).finally(() => setLoading(false));
  }, [DERsDescriptionOptions, dispatch]);

  const handleSelectChange = useCallback(
    async (option: Type.SelectOption) =>
      setFieldValue(name, value !== undefined ? option.value || null : option?.label || ''),
    [setFieldValue, name, value]
  );

  const { options, selectValue } = (DERsDescriptionOptions || []).reduce(
    (acc: { options: Type.SelectOption[]; selectValue: Type.SelectOption | null }, option: Type.SelectOption) => {
      if (value !== undefined && option.value === value) acc.selectValue = option;
      else if (label !== undefined && option.label === label) acc.selectValue = option;
      acc.options.push(option);
      return acc;
    },
    { options: [], selectValue: null }
  );

  return (
    <FormReactSelect
      labelKey="DER description"
      value={selectValue}
      options={options || []}
      onChange={handleSelectChange}
      isDisabled={loading}
      isLoading={loading}
      {...props}
    />
  );
});

export default SelectDERsDescription;
