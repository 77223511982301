import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchPoFParamsAction, updatePoFParamsAction } from 'modules/reliability';
import {
  scenarioIdSelector,
  reliabilityScenarioParamsOptionSelector,
  reliabilityAssetCategoriesCNAIMOptionSelector,
} from 'modules/layouts/selectors';
import { FormInput } from 'components/_common';

interface Props {
  distribution: string | null;
  onChange: (params: { [key: string]: number }) => void;
}

const PoFParamsInputs: React.FC<Props> = ({ distribution, onChange }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reliabilityScenarioParamsOption = useSelector(reliabilityScenarioParamsOptionSelector);
  const reliabilityAssetCategoriesCNAIMOption = useSelector(reliabilityAssetCategoriesCNAIMOptionSelector);

  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<{ [key: string]: number } | null>(null);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (
      !distribution ||
      !reliabilityAssetCategoriesCNAIMOption?.value ||
      !scenarioId ||
      !reliabilityScenarioParamsOption?.value
    )
      return;
    setLoading(true);
    dispatch(
      fetchPoFParamsAction({
        distribution,
        cnaimAssetCategoryId: reliabilityAssetCategoriesCNAIMOption?.value as number,
        scenarioGlobalId: scenarioId,
        scenarioParamId: reliabilityScenarioParamsOption?.value as number,
      })
    )
      .then((action: Shared.ReduxAction<{ [key: string]: number }>) => {
        onChange(action.payload);
        setParams(action.payload);
      })
      .finally(() => setLoading(false));
  }, [
    distribution,
    reliabilityAssetCategoriesCNAIMOption,
    scenarioId,
    reliabilityScenarioParamsOption,
    dispatch,
    onChange,
  ]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value: inputValue } = e.currentTarget;
      setParams({ ...params, [name]: Number(inputValue) });
    },
    [params]
  );

  const handleInputBlur = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (
        !distribution ||
        !reliabilityAssetCategoriesCNAIMOption ||
        !scenarioId ||
        !reliabilityScenarioParamsOption ||
        !params
      )
        return;
      setLoading(true);
      dispatch(
        updatePoFParamsAction({
          distribution,
          cnaimAssetCategoryId: reliabilityAssetCategoriesCNAIMOption.value as number,
          scenarioGlobalId: scenarioId,
          scenarioParamId: reliabilityScenarioParamsOption.value as number,
          params,
        })
      )
        .then(() => onChange(params!))
        .catch(console.error)
        .finally(() => setLoading(false));
    },
    [
      dispatch,
      distribution,
      reliabilityAssetCategoriesCNAIMOption,
      scenarioId,
      reliabilityScenarioParamsOption,
      params,
      onChange,
    ]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') handleInputBlur();
    },
    [handleInputBlur]
  );

  if (!params) return null;
  return (
    <>
      <Form.Label>{getIntl('Parameters')}</Form.Label>
      {Object.keys(params).map((key: string) => (
        <Form.Group as={Row} key={key}>
          <Form.Label column sm="auto">
            {key.toUpperCase()}
          </Form.Label>
          <Col>
            <FormInput
              type="number"
              name={key}
              value={params[key]}
              disabled={loading}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleKeyPress}
            />
          </Col>
        </Form.Group>
      ))}
    </>
  );
};

export default PoFParamsInputs;
