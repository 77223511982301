import styled from 'styled-components';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { TaskStatusVariantMap } from 'constants/index';

interface Props {
  status: Tasks.TaskStatus;
  className?: string;
}

const TaskStatusBadge: React.FC<Props> = ({ status, className = '' }) => {
  const { getIntl } = useLocale();
  return (
    <StyledBadge className={className} variant={TaskStatusVariantMap[status] || 'info'}>
      {getIntl(status)}
    </StyledBadge>
  );
};

const StyledBadge = styled(Badge)`
  font-size: 85%;
  font-weight: 500;
`;

export default TaskStatusBadge;
