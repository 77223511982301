import React from 'react';
import { useLocale } from 'hooks';
import ChartTotalLoadingProfile from './ChartTotalLoadingProfile';
import TabTotalLoadingContent from './TabTotalLoadingContent';
import { Tabs, Tab, ContentContainer, ContentCard } from 'components/_common';
import { Routes } from 'constants/index';

interface Props {
  startDateStr: string | null;
  endDateStr: string | null;
}

const TabsTotalLoading: React.FC<Props> = ({ startDateStr, endDateStr }) => {
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter>
      <Tab id="tab_network_loading_total" eventKey={Routes.NetworkLoadingTotalLoading} title={getIntl('Total loading')}>
        <TabTotalLoadingContent startDateStr={startDateStr} endDateStr={endDateStr} />
      </Tab>
      <Tab
        id="tab_network_loading_total_profile"
        eventKey={`${Routes.NetworkLoadingTotalLoading}${Routes.HASH.Profile}`}
        title={getIntl('Profile')}
      >
        <ContentContainer>
          <ContentCard>
            <ChartTotalLoadingProfile />
          </ContentCard>
        </ContentContainer>
      </Tab>
    </Tabs>
  );
};

export default TabsTotalLoading;
