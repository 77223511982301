import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getTransformersHistoricalLoadingChartData } from 'modules/networkLoading';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';

interface Props {
  type: Type.PaginationType;
}

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartTransformersHistoricalLoadingDistribution: React.FC<Props> = ({ type }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const baseOptions = getBaseOptions(getIntl, chartData);

  useEffect(() => {
    setChartData(null);
    if (!scenarioId || !simulationId) return;
    dispatch(
      getTransformersHistoricalLoadingChartData({
        portfolioId,
        scenarioId,
        simulationId,
        year: filters?.year,
        voltage: filters?.voltage,
        voltage_side: filters?.voltageSide,
        percentile: filters?.percentile,
        flex: filters?.flex,
      })
    )
      .then((action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> | null>) => {
        setChartData({
          series:
            action.payload?.series.map((i, index: number) => ({
              name: i.group || '',
              type: 'column' as any,
              data: i.data,
              color: Colors[index],
            })) || [],
          title: action.payload?.title || '',
          xAxisTitle: action.payload?.xaxis_title || '',
          yAxisTitle: action.payload?.yaxis_title || '',
        });
      })
      .catch(console.error);
  }, [
    dispatch,
    portfolioId,
    scenarioId,
    simulationId,
    filters?.voltage,
    filters?.voltageSide,
    filters?.year,
    filters?.percentile,
    filters?.flex,
  ]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${getIntl(this.series.name)}<br /><b>${getIntl('Max. Loading')}:</b> ${this.point.x} %<br><b>${getIntl('Quantity')}:</b> ${this.point.y} ${getIntl('pcs')}.`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    }),
    [baseOptions, chartData, getIntl]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_network_loading_transformers_historical_dist" />;
};

export default ChartTransformersHistoricalLoadingDistribution;
