import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createRepeatedTaskAction, editRepeatedTaskAction } from 'modules/tasks';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.CreateRepeatedTaskActionProps>({
    initialValues: {
      departmentNumber: null,
      taskNumber: null,
      workSectionId: null,
      actionInApp: '',
      // auto filled in the action
      description: '',
      taskStatus: '',
      mainActivity: '',
    },

    validationSchema: Yup.object().shape({
      departmentNumber: Yup.number().nullable().required("Field can't be empty!"),
      taskNumber: Yup.string().nullable().required("Field can't be empty!"),
      workSectionId: Yup.number().nullable().required("Field can't be empty!"),
      actionInApp: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      if (id) return dispatch(editRepeatedTaskAction(id, values)).then(toggleModal).catch(console.error);
      return dispatch(createRepeatedTaskAction(values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
