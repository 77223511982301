import { handleActions, combineActions } from 'redux-actions';
import {
  fetchSmartMeterActionsAction,
  fetchSmartMeterActionAction,
  fetchSmartMeterChecklistAction,
  updateSmartMeterChecklistAction,
  fetchSmartMeterPhotosAction,
  saveSmartMeterCommentAction,
  deleteSmartMeterPhotosAction,
  fetchMetersPopulationAction,
} from './actions';

const initialState: Meters.Root = {
  smartMeterActionsCount: 0,
  smartMeterActionsHash: {},
  smartMeterAction: null,
  populationCount: 0,
  populationHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchSmartMeterActionsAction, fetchSmartMeterActionAction, fetchMetersPopulationAction) as any]: {
    next: (
      state: Meters.Root,
      action: Shared.ReduxAction<Partial<Meters.Root> & { skipStoreUpdate: true | undefined }>
    ): Meters.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchSmartMeterActionsAction,
  fetchSmartMeterActionAction,
  fetchSmartMeterChecklistAction,
  updateSmartMeterChecklistAction,
  fetchSmartMeterPhotosAction,
  saveSmartMeterCommentAction,
  deleteSmartMeterPhotosAction,
  fetchMetersPopulationAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
