import React from 'react';
import { Switch, Route } from 'react-router';
import { ViewManageDERs, ViewGlobalDevelopment } from 'components/NewLoad';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageNewLoad: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isNewLoadEnabled}>
    <Switch>
      <Route exact path={Routes.NewLoadGlobalDevelopment} component={ViewGlobalDevelopment} />
      <Route path={Routes.NewLoad} component={ViewManageDERs} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageNewLoad;
