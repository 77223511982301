import { ThemeProvider } from 'styled-components';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import { initSentry } from 'utils';
import { UserService } from '@utiligize/shared/services';
import { store, history } from './store';
import theme from '@utiligize/shared/theme';

initSentry();

UserService.initKeycloak(() =>
  createRoot(document.getElementById('root')!).render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
);
