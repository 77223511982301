import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { tasksDepartmentsSelector } from 'modules/tasks/selectors';
import { createTaskAction, editTaskAction } from 'modules/tasks';
import {
  DateFormats,
  TaskAppActions,
  TaskTypes,
  AssetTaskAppActions,
  InstallationNumbersTaskAppActions,
} from 'constants/index';

interface Props {
  show: boolean;
  id: number | null;
  departmentNumber: number | null;
  taskNumber: string | null;
  description: string | null;
  complaint: Tasks.TaskComplaint | null;
  toggleModal: () => void;
  externalTimeSystem: boolean;
}

const useConfiguredFormik = ({
  show,
  id,
  departmentNumber,
  taskNumber,
  description,
  complaint,
  toggleModal,
  externalTimeSystem,
}: Props) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const tasksDepartments = useSelector(tasksDepartmentsSelector);
  const { getIntl } = useLocale();

  const formik = useFormik<Type.CreateTaskActionProps>({
    // TODO switch to Tasks.Task
    enableReinitialize: true,

    initialValues: {
      // 1. Department Input
      departmentNumber:
        // auto select department in create mode if only one exist
        departmentNumber || (show && !id && tasksDepartments?.length === 1 ? tasksDepartments?.[0]?.number : null),
      // 2. Type Input
      type: complaint ? TaskTypes.CustomerComplaint : TaskTypes.Immediate,
      // 3. Task number or Name Inputs
      name: description,
      taskNumber,
      // 4. Start date - End date or MonthsMultiSelect inputs
      startDate: null,
      endDate: null,
      taskMonths: null,
      // 5. Expected time (hours) Input
      expectedTime: null,

      appAction: TaskAppActions.LogMaintenance,

      planId: null,
      assetCategoryCode: null,
      assetCodes: [],

      formId: null,
      assetSubcomponentTypeId: null,

      taskCheckListId: null,

      userIds: [],

      responsibleUserIds: [],

      installationNumbers: [],

      files: null,
      deletedAttachmentIds: [],
      updatedAttachmentsHash: {},

      description: complaint
        ? `* ${getIntl('Name of road')}: ${complaint.nameOfRoad}
* ${getIntl('Number of lights')}: ${complaint.numberOfLights}
* ${getIntl('Type of fault')}: ${complaint.typeOfFault}
* ${getIntl('Post code and town')}: ${complaint.postcodeAndTown}
* ${getIntl('Place')}: ${complaint.place}
* ${getIntl('Name')}: ${complaint.name}
* ${getIntl('Telephone')}: ${complaint.telephone}
* ${getIntl('Email')}: ${complaint.email}
* ${getIntl('Comments')}: ${complaint.comments}`
        : '',

      complaintId: complaint?.id || null,
      address: null,
      loc: null,
      useFilteredTasks: !taskNumber,
      // synthetic value
      useAddressGeocoding: false,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().nullable().required("Field can't be empty!"),
      taskNumber: Yup.string()
        .nullable()
        .when(['name', 'type'], (name: string | null, type: Type.TaskTypes, schema: any) =>
          schema.test({
            test: (taskNumber: string) => type === TaskTypes.Expected || !externalTimeSystem || name || taskNumber,
            message: "Field can't be empty!",
          })
        ),
      startDate: Yup.string()
        .nullable()
        .when(['type', 'appAction'], (type: Type.TaskTypes, appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (startDate: string) =>
              type === TaskTypes.Autogenerated || appAction === TaskAppActions.ToolInspection || startDate,
            message: "Field can't be empty!",
          })
        ),
      taskMonths: Yup.array()
        .nullable()
        .when(['type', 'appAction'], (type: Type.TaskTypes, appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (taskMonths: Tasks.TaskMonthsEnum) =>
              type !== TaskTypes.Autogenerated || appAction === TaskAppActions.ToolInspection || taskMonths?.length,
            message: 'Please select one month at least!',
          })
        ),
      expectedTime: Yup.number()
        .nullable()
        .when('type', (type: Type.TaskTypes, schema: any) =>
          schema.test({
            test: (expectedTime: number) => type !== TaskTypes.Autogenerated || expectedTime !== 0,
            message: 'Please add asset service time',
          })
        )
        .when('type', (type: Type.TaskTypes, schema: any) =>
          schema.test({
            test: (expectedTime: number) => type === TaskTypes.Autogenerated || expectedTime !== 0,
            message: 'Must be greater than zero',
          })
        )
        .required("Field can't be empty!"),
      departmentNumber: Yup.number().nullable().required("Field can't be empty!"),
      planId: Yup.number()
        .nullable()
        .when('type', (type: Type.TaskTypes, schema: any) =>
          schema.test({
            test: (planId: number) => type !== TaskTypes.Autogenerated || planId,
            message: "Field can't be empty!",
          })
        ),
      assetCodes: Yup.array()
        .when(['appAction', 'type'], (appAction: Type.TaskAppActions, type: Type.TaskTypes, schema: any) =>
          schema.test({
            test: (assetCodes: string[]) => {
              return (
                type === TaskTypes.Autogenerated ||
                !AssetTaskAppActions.includes(appAction) ||
                Boolean(assetCodes.length)
              );
            },
            message: "Field can't be empty!",
          })
        )
        .test('test-category-type', 'All asset codes must be in one asset category', (assetCodes: any) => {
          if (!assetCodes.length) return true;
          const assetCategoryCode = assetCodes[0].slice(0, 2);
          return assetCodes.every((code: string) => code.includes(assetCategoryCode));
        }),
      assetSubcomponentTypeId: Yup.number()
        .nullable()
        .when('appAction', (appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (assetSubcomponentTypeId: number) =>
              ![TaskAppActions.Repair, TaskAppActions.Replacement].includes(appAction) || assetSubcomponentTypeId,
            message: "Field can't be empty!",
          })
        ),
      formId: Yup.number()
        .nullable()
        .when('appAction', (appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (formId: number) =>
              ![TaskAppActions.LogMaintenance, TaskAppActions.ToolInspection].includes(appAction) || formId,
            message: "Field can't be empty!",
          })
        ),
      taskCheckListId: Yup.number()
        .nullable()
        .when('appAction', (appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (taskCheckListId: number | null) =>
              appAction === TaskAppActions.ToolInspection || Boolean(taskCheckListId),
            message: "Field can't be empty!",
          })
        ),
      installationNumbers: Yup.array().when('appAction', (appAction: Type.TaskAppActions, schema: any) =>
        schema.test({
          test: (installationNumbers: string[]) =>
            !InstallationNumbersTaskAppActions.includes(appAction) || Boolean(installationNumbers.length),
          message: "Field can't be empty!",
        })
      ),
      userIds: Yup.array().when('responsibleUserIds', (responsibleUserIds: number[], schema: any) =>
        schema.test({
          test: (userIds: number[]) => !userIds.some(userId => responsibleUserIds.includes(userId)),
          message: 'Some of the users already assigned as a responsible user',
        })
      ),
    }),

    onSubmit: values => {
      const startDate = values.startDate && moment(values.startDate).format(DateFormats.SERVER);
      const endDate = values.endDate && moment(values.endDate).format(DateFormats.SERVER);
      const updatedAttachments = Object.keys(values.updatedAttachmentsHash).map(id => ({
        id: Number(id),
        description: values.updatedAttachmentsHash[id],
      }));
      const payload = {
        ...values,
        startDate,
        endDate,
        useAddressGeocoding: undefined,
        updatedAttachments,
        updatedAttachmentsHash: undefined,
      };
      const formData = new FormData();
      values.files?.map(file => formData.append('file', file));
      formData.append('data', JSON.stringify(payload));
      if (id) return dispatch(editTaskAction(id, formData)).then(toggleModal).catch(console.error);
      return dispatch(createTaskAction(formData)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
