import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  employeesGanttChartFetchedSelector,
  employeesGanttChartSelector,
  employeesGanttChartHoursLeftOverWeekSelector,
  employeesGanttChartHoursLeftOverMonthSelector,
} from 'modules/employees/selectors';
import { fetchEmployeesGanttChartAction } from 'modules/employees';
import { ContentContainer, TaskTypeBadge, Spinner } from 'components/_common';
import useTableData from './useTableData';
import TableWrapper from './TableWrapper';
import TableHeader from './TableHeader';
import TableRow from './TableRow';

const EmployeesGanttChart: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const employeesGanttChartFetched = useSelector(employeesGanttChartFetchedSelector);
  const [loading, setLoading] = useState(false);
  const employeesGanttChart = useSelector(employeesGanttChartSelector);
  const employeesGanttChartHoursLeftOverWeek = useSelector(employeesGanttChartHoursLeftOverWeekSelector);
  const employeesGanttChartHoursLeftOverMonth = useSelector(employeesGanttChartHoursLeftOverMonthSelector);

  const { filters, daysInAYear, weeksAmount, daysFromPreviousYear, isLeapYear } = useTableData();

  useEffect(() => {
    setLoading(true);
    dispatch(fetchEmployeesGanttChartAction()).then(() => {
      setLoading(false);
      const element = document.getElementById('current-week');
      element?.scrollIntoView({ block: 'start', inline: 'center', behavior: 'smooth' });
    });
  }, [dispatch, filters]);

  if (!employeesGanttChartFetched || loading) {
    return (
      <ContentContainer>
        <Spinner inCard isInFullHeightContainer />
      </ContentContainer>
    );
  }

  return (
    <TableWrapper>
      <>
        <TableHeader />
        <tbody>
          {employeesGanttChart.map((item: Employees.GanttChartItem) => (
            <React.Fragment key={item.id}>
              {[...(item.tasks.length ? item.tasks : [{ id: Math.random() } as Tasks.Task])].map(
                (task: Tasks.Task, index: number) => (
                  <TableRow
                    key={task.id}
                    task={task}
                    firstCell={
                      !index ? (
                        <td className="sticky first" rowSpan={item.tasks.length || 1}>
                          {item.fullName !== 'Unassigned' ? item.fullName : getIntl('Unassigned')}
                          <br />
                          {getIntl('Hours left')}: {item.hoursLeftOver.toFixed(2)}
                        </td>
                      ) : null
                    }
                    secondCell={
                      <td className="sticky second">
                        <TaskTypeBadge id={task.id} type={task.type} className="mr-2" />
                        {task.name || '-'}
                      </td>
                    }
                  />
                )
              )}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th className="sticky first-footer" colSpan={4}>
              {getIntl('Hours left over')}
            </th>
            {Array.from(Array(weeksAmount).keys()).map((item: number, index: number) => (
              <td key={index} colSpan={7} className="text-center text-bold">
                {employeesGanttChartHoursLeftOverWeek[item].toFixed(2)}
              </td>
            ))}
          </tr>
          <tr>
            <th className="sticky first-footer" colSpan={4}>
              {getIntl('Hours left over in month')}
            </th>
            {Array.from(Array(daysFromPreviousYear).keys()).map((item: number) => (
              <td key={item} className="bg-light-gray" />
            ))}
            {[31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31].map((item: number, index: number) => (
              <td key={index} colSpan={item} className="text-center text-bold">
                {employeesGanttChartHoursLeftOverMonth[index].toFixed(2)}
              </td>
            ))}
            {Array.from(Array(weeksAmount * 7 - daysInAYear - daysFromPreviousYear).keys()).map((item: number) => (
              <td key={item} className="bg-light-gray" />
            ))}
          </tr>
        </tfoot>
      </>
    </TableWrapper>
  );
};

export default EmployeesGanttChart;
