import React from 'react';
import { DataTableTemplate } from 'components/_common';
import ModalForecastedScenarios from './ModalForecastedScenarios';
import TableForecastedScenarios from './TableForecastedScenarios';

const ViewForecastedScenarios: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <ModalForecastedScenarios />
    <TableForecastedScenarios />
  </DataTableTemplate>
);

export default ViewForecastedScenarios;
