import styled from 'styled-components';
import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import { IconChevronLeft, IconChevronRight } from '@utiligize/shared/resources';

interface Props {
  forcePage: number;
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}

const TablePagination: React.FC<Props> = memo(({ forcePage, pageCount, onPageChange }) => (
  <StyledPagination
    forcePage={forcePage}
    previousLabel={<IconChevronLeft />}
    nextLabel={<IconChevronRight />}
    breakLabel="..."
    pageCount={pageCount}
    marginPagesDisplayed={2}
    pageRangeDisplayed={5}
    onPageChange={onPageChange}
    breakClassName="page-item"
    breakLinkClassName="page-link"
    containerClassName="pagination"
    pageClassName="page-item"
    pageLinkClassName="page-link"
    previousClassName="page-item"
    previousLinkClassName="page-link navigation"
    nextClassName="page-item"
    nextLinkClassName="page-link navigation"
    activeClassName="active"
  />
));

const StyledPagination = styled(ReactPaginate)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  padding-left: 10px;

  .page-link.navigation {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }

  svg {
    fill: ${props => props.theme.colors.purple800};
    width: 20px;
    height: 20px;
  }

  .page-item {
    list-style: none;
  }

  .page-link {
    color: ${props => props.theme.colors.purple800};
    background-color: ${props => props.theme.colors.grey25};
    border-radius: 8px;
    border: none;
    font-size: 14px;
    font-weight: 500;
  }

  .page-item.active .page-link {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.purple800};
  }
`;

export default TablePagination;
