import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  simulationSelectedOptionSelector,
  simulationOptionsSelector,
  simulationOptionsFetchedSelector,
} from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectSimulation: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const options = useSelector(simulationOptionsSelector);
  const value = useSelector(simulationSelectedOptionSelector);
  const isFetched = useSelector(simulationOptionsFetchedSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): Promise<void> => dispatch(setLayoutAction({ simulationId: Number(option.value) })),
    [dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!isFetched}
      isDisabled={!isFetched || !options?.length}
      labelKey="Simulation"
      value={value}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
    />
  );
};

export default SelectSimulation;
