import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createUserAction, editUserAction } from 'modules/permissions';

interface Props {
  id: number | null;
  initialValues: Users.CreateEditPayload;
  toggleModal: () => void;
}

const useConfiguredFormik = ({ id, initialValues, toggleModal }: Props) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Users.CreateEditPayload>({
    initialValues,

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Field can't be empty!"),
      lastName: Yup.string().required("Field can't be empty!"),
      email: Yup.string().required("Field can't be empty!").email('Please add valid email address'),
      initials: Yup.string().required("Field can't be empty!"),
      userPermissions: Yup.object().when('groupPermissions', (groupPermissions: number[], schema: any) =>
        schema.test({
          test: (userPermissions: Permissions.Permissions) => {
            return (
              !!groupPermissions.length ||
              Object.keys(userPermissions).some((key: string) =>
                Boolean(
                  userPermissions[key as Permissions.PermissionsTypes] &&
                    !key.includes('ReadOnly') &&
                    !key.includes('isTaskType')
                )
              )
            );
          },
          message: 'You must select at least one view permission or group!',
        })
      ),
    }),

    onSubmit: values => {
      if (id) return dispatch(editUserAction(id, values)).then(toggleModal).catch(console.error);
      return dispatch(createUserAction(values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
