import React, { useEffect, useCallback } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import RangeSlider from 'react-bootstrap-range-slider';
import { useDebouncedCallback } from 'use-debounce';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { FormInput } from 'components/_common';
import { PaginationType } from 'constants/index';

const RangeSliderMaxRiskConstraint: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.INTERVENTIONS));
  const minValue = 1500;
  const maxValue = 50000;
  const [value, setValue] = React.useState(filters?.maxRiskConstraint || minValue);

  const debounced = useDebouncedCallback((maxRiskConstraint: number) => {
    dispatch(
      setPaginationAction({
        type: PaginationType.INTERVENTIONS,
        modifier: { filters: { ...filters, maxRiskConstraint } },
      })
    );
  }, 1500);

  useEffect(() => {
    if (value === filters?.maxRiskConstraint) return;
    debounced(value);
  }, [debounced, value, filters]);

  useEffect(() => debounced.cancel, [debounced.cancel]);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value);
    setValue(value > maxValue ? maxValue : value < minValue ? minValue : value);
  }, []);

  const handleInputBlur = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.currentTarget.value);
    if (!value) setValue(minValue);
  }, []);

  return (
    <>
      <Col sm="auto">
        <FormInput
          value={value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          style={{ width: '70px' }}
          type="number"
          min={minValue}
          max={maxValue}
          size="sm"
        />
      </Col>
      <Col lg={3} sm={3} xs={4}>
        <RangeSlider
          value={value}
          tooltipPlacement="top"
          onChange={handleInputChange}
          min={minValue}
          max={maxValue}
          size="sm"
        />
        <Form.Text className="text-muted">{getIntl('Maximum risk constraint')}</Form.Text>
      </Col>
    </>
  );
};

export default RangeSliderMaxRiskConstraint;
