import React from 'react';
import { useSelector } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { DataTableTemplate, ContentContainer, ContentCard } from 'components/_common';
import { PaginationType } from 'constants/index';
import TableSummary from './TableSummary';
import ChartSummary from './ChartSummary';
import TableFilters from './TableFilters';

const ViewSummary: React.FC = () => {
  const type = PaginationType.SUMMARY_PLAN;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  return (
    <DataTableTemplate paddingBottom>
      <TableFilters type={type} />
      <>
        <TableSummary />
        <ContentContainer className="border-top-0 pt-0">
          <ContentCard>
            <ChartSummary summaryGroupIds={filters?.summaryGroupIds} />
          </ContentCard>
        </ContentContainer>
      </>
    </DataTableTemplate>
  );
};

export default ViewSummary;
