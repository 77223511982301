import { Row, Col } from 'react-bootstrap';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchSmartMeterPhotosAction, deleteSmartMeterPhotosAction } from 'modules/meters';
import { setModalConfirmationMetaAction, setInfoToastAction } from 'modules/layouts';
import { Gallery, Button } from 'components/_common';
import { IconDelete } from '@utiligize/shared/resources';

const SmartMetersActionGallery: React.FC<{ maalernr?: string }> = ({ maalernr }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isFetched, setIsFetched] = useState<boolean>(false);
  const [images, setImages] = useState<Type.GalleryImage[]>([]);

  useEffect(() => {
    if (!maalernr) return;
    dispatch(fetchSmartMeterPhotosAction(maalernr))
      .then((action: Shared.ReduxAction<Type.GalleryImage[]>) => {
        setImages(action.payload);
      })
      .finally(() => setIsFetched(true));
  }, [maalernr, dispatch]);

  const handleSelectImage = useCallback(
    (index: number, image: Type.GalleryImage) => {
      const nextImages = images.slice();
      nextImages[index].isSelected = !image.isSelected;
      setImages(nextImages);
    },
    [images]
  );

  const handleDeleteButtonClick = useCallback(() => {
    const { ids, nextImages } = images.reduce(
      (acc: { ids: number[]; nextImages: Type.GalleryImage[] }, image: Type.GalleryImage) => {
        if (image.isSelected) {
          acc.ids.push(image.id);
        } else {
          acc.nextImages.push(image);
        }
        return acc;
      },
      { ids: [], nextImages: [] }
    );

    if (!ids.length) return dispatch(setInfoToastAction('Please select at least one photo'));

    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Do you want to delete selected photos?',
        onConfirm: async () => dispatch(deleteSmartMeterPhotosAction(maalernr, ids)).then(() => setImages(nextImages)),
      })
    );
  }, [maalernr, images, dispatch]);

  return (
    <Row>
      {isFetched && (
        <Col xs={12}>
          <Gallery images={images} enableImageSelection onSelectImage={handleSelectImage} />
        </Col>
      )}
      {isFetched && Boolean(images.length) && (
        <Col xs={12} className="text-right">
          <Button
            className="mt-3"
            labelKey="Delete photos"
            icon={<IconDelete />}
            onClick={handleDeleteButtonClick}
            variant="danger"
          />
        </Col>
      )}
    </Row>
  );
};

export default SmartMetersActionGallery;
