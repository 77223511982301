import * as d3 from 'd3';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import { MockDataType, MockGraphType, Edge } from './NetworkChartData';
import ChartDefs from '../common/NetworkChartDefs';
import {
  useResizeObserver,
  setupGraph,
  setupScales,
  drawGraph,
  removeHighlight,
  transformChart,
} from '../common/utils';
import { generatePath } from 'react-router';
import { Routes } from 'constants/index';

interface Props {
  data: MockDataType;
  type: Type.SingleLineDiagramTypes;
  mapFilterPrimarySubstationsId: number | null;
}

const NetworkChart: React.FC<Props> = ({ data, type, mapFilterPrimarySubstationsId }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const svgRef = useRef<SVGSVGElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(wrapperRef);

  const uuid = useSelector(mapPanelSelectedAssetUUIDSelector);
  useEffect(() => {
    removeHighlight();

    // Select and filter circles, then add class to their parent nodes
    d3.selectAll<SVGCircleElement, Edge>('.circle')
      .filter((d: Edge) => d.customer_asset_id === uuid)
      .each(function (this: SVGCircleElement, d: Edge) {
        d3.select<SVGElement, unknown>(this.parentNode as SVGElement).classed('selected-node', true);
      });

    // Select and filter links, then add class to them
    d3.selectAll<SVGElement, Edge>('.link')
      .filter((d: Edge) => d.customer_asset_id === uuid)
      .each(function (this: SVGElement, d: Edge) {
        d3.select(this).classed('selected-link', true);
      });
  }, [uuid]);

  const handleGraphClick = useCallback(
    (uuid: string) => {
      if (!uuid) return dispatch(push(generatePath(Routes.Map)));
      dispatch(push(generatePath(Routes.Map, { uuid }), { keepMapState: true }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!wrapperRef.current || !svgRef.current || !dimensions) return;

    // Set the dimensions and margins of the graph
    const margin = { top: 20, right: 50, bottom: 50, left: 50 };
    const width = dimensions.width;
    const height = dimensions.height;
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const delta = 2;
    let graph: MockGraphType, xScale: any, yScale: any, currentXScale: any, currentYScale: any;

    // Append the svg object to the chart div
    const svg = d3.select(svgRef.current);
    svg.attr('width', width).attr('height', height);

    // Append groups for nodes and links
    const linksGroup = svg.select('.links-group').attr('transform', `translate(${margin.left},${margin.top})`);
    const nodesGroup = svg.select('.nodes-group').attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Prepare graph data
    graph = setupGraph(data, type, innerWidth, innerHeight, mapFilterPrimarySubstationsId, margin);

    // Prepare scales
    const invert = false;
    const scales = setupScales(data.vertices, innerWidth, innerHeight, margin, invert);
    currentXScale = xScale = scales.xScale;
    currentYScale = yScale = scales.yScale;

    // Draw the nodes, links and markers
    const { nodes, links } = drawGraph(graph, linksGroup, nodesGroup, delta, xScale, yScale, handleGraphClick);

    /**
     * Dragging
     */
    nodes.call(
      d3
        .drag()
        .on('start', function (event: any) {
          event.sourceEvent.stopPropagation();
          svg.style('cursor', 'grab');
          nodes.style('cursor', 'grab');
        })
        .on('drag', function (this: any, event: any, d: any) {
          svg.style('cursor', 'grabbing');
          nodes.style('cursor', 'grabbing');

          const ratio = type === 'Tree' ? 5 : 50000;
          d.V1 += event.dx / ratio;
          d.V2 -= event.dy / ratio;

          transformChart(d3.select(this), links, currentXScale, currentYScale, delta);
        })
        .on('end', function () {
          svg.style('cursor', 'initial');
          nodes.style('cursor', 'pointer');
        })
    );

    // Configure zoom
    const zoom = d3.zoom<SVGSVGElement, unknown>().on('zoom', (event: React.SyntheticEvent) => zoomed(event));
    const zoomed = (event: any) => {
      // update scales
      currentXScale = event.transform.rescaleX(xScale);
      currentYScale = event.transform.rescaleY(yScale);

      // update node positions
      transformChart(nodes, links, currentXScale, currentYScale, delta);

      const scale = event.transform.k;
      /**
       * Scale node radius by zoom
       */
      const nodeRadius = scale < 1.5 ? 7.5 : scale < 2 ? 9 : 11;
      const circles = d3.selectAll('.circle');
      circles.attr('r', nodeRadius);
      /**
       * Scale labels font size by zoom
       */

      const labelsOther = d3.selectAll('.node-text-label');
      labelsOther.style('font-size', scale < 1.5 ? '7px' : scale < 2 ? '8px' : '10px');
      labelsOther.style('opacity', 1);

      const labelsLow = d3.selectAll('.node-text-label-low');
      labelsLow.style('font-size', scale < 1.5 ? '2px' : scale < 2 ? '4px' : '8px');
      labelsLow.style('opacity', scale > 1.5 ? 1 : 0);

      const labelsMedium = d3.selectAll('.node-text-label-medium');
      labelsMedium.style('font-size', scale < 1.5 ? '7px' : scale < 2 ? '8px' : '10px');

      const labelsHigh = d3.selectAll('.node-text-label-high');
      labelsHigh.style('font-size', scale < 1.5 ? '9px' : scale < 2 ? '10px' : '12px');

      const labelsExtraHigh = d3.selectAll('.node-text-label-extra-high');
      labelsExtraHigh.style('font-size', scale < 1.5 ? '10px' : scale < 2 ? '11px' : '13px');
    };
    svg.call(zoom);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, type, dimensions]);

  return (
    <div className="chart" ref={wrapperRef} style={{ position: 'relative', height: '100%' }}>
      <svg ref={svgRef} style={{ width: '100%' }}>
        <ChartDefs />
        <g className="links-group" />
        <g className="nodes-group" />
      </svg>
    </div>
  );
};

export default NetworkChart;
