import React from 'react';
import { useLocale } from 'hooks';
import { Alert } from 'components/_common';

const TaskDeleteAlert: React.FC<{ name: string }> = ({ name }) => {
  const { getIntl } = useLocale();
  return (
    <Alert className="mb-0 text-center" variant="warning">
      {getIntl('Task with name - {{name}} - will be deleted', { name })}
    </Alert>
  );
};

export default TaskDeleteAlert;
