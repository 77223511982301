export const renderOtherCars = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        isAsset: legendItem.asset,
        type: 'fill',
        iconClass: 'legend-icon car-stats-nk',
        group: legendItem.group,
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: legendItem.name,
          showPopup: true,
          hasData: true,
          type: 'fill',
          source: legendItem.name,
          order: legendItem.order,
          'source-layer': legendItem.table,
          paint: {
            'fill-opacity': 0.2,
            'fill-color': 'rgb(61, 89, 248)',
          },
        },
        {
          id: `${legendItem.name}__outline`,
          type: 'line',
          maxzoom: 16,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'line-width': ['interpolate', ['linear'], ['zoom'], 14, 1, 20, 5],
            'line-color': 'rgb(147, 164, 255)',
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
        },
        {
          id: `${legendItem.name}__outline_2`,
          type: 'line',
          minzoom: 16,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'line-width': ['interpolate', ['linear'], ['zoom'], 14, 1, 20, 3],
            'line-color': '#FFF',
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
        },
      ],
    },
  };
  return [style];
};
