import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  reliabilityModelOptionSelector,
  reliabilityVoltageOptionSelector,
  reliabilitySAIFIValueSelector,
  reliabilityCMLValueSelector,
  reliabilitySAIFIDelayValueSelector,
} from 'modules/layouts/selectors';
import { ContentContainer, ChartIframe, ContentCard } from 'components/_common';
import SelectModel from './SelectModel';
import SelectVoltage from './SelectVoltage';
import RangeSliderSAIFI from './RangeSliderSAIFI';
import RangeSliderCML from './RangeSliderCML';
import RangeSliderSAIFIDelay from './RangeSliderSAIFIDelay';

const ViewConstraints: React.FC = () => {
  const reliabilityModelOption = useSelector(reliabilityModelOptionSelector);
  const reliabilityVoltageOption = useSelector(reliabilityVoltageOptionSelector);
  const reliabilitySAIFIValue = useSelector(reliabilitySAIFIValueSelector);
  const reliabilityCMLValue = useSelector(reliabilityCMLValueSelector);
  const reliabilitySAIFIDelayValue = useSelector(reliabilitySAIFIDelayValueSelector);

  const canRenderCharts =
    reliabilityModelOption?.value &&
    reliabilityVoltageOption?.value &&
    reliabilitySAIFIValue &&
    reliabilitySAIFIDelayValue &&
    reliabilityCMLValue;

  return (
    <ContentContainer className="border-top-0">
      <ContentCard>
        <Row>
          <Col xs={3}>
            <Form.Group>
              <SelectModel />
            </Form.Group>
            <Form.Group>
              <SelectVoltage />
            </Form.Group>
            <Form.Group>
              <RangeSliderSAIFI />
            </Form.Group>
            <Form.Group>
              <RangeSliderSAIFIDelay />
            </Form.Group>
            <Form.Group>
              <RangeSliderCML />
            </Form.Group>
          </Col>
          <Col xs={9}>
            <ChartIframe
              title="Plot SAIFI"
              src={
                canRenderCharts
                  ? `plot_saifi?saifi_target=${reliabilitySAIFIValue}&saifi_delay=${reliabilitySAIFIDelayValue}&voltage_selected=${reliabilityVoltageOption?.value}&reliability_model=${reliabilityModelOption?.value}`
                  : ''
              }
              height="calc((100vh - 184px)/2)"
            />
            <ChartIframe
              title="Plot CML"
              src={
                canRenderCharts
                  ? `plot_cml?cml_target=${reliabilityCMLValue}&saifi_delay=${reliabilitySAIFIDelayValue}&voltage_selected=${reliabilityVoltageOption?.value}&reliability_model=${reliabilityModelOption?.value}`
                  : ''
              }
              height="calc((100vh - 184px)/2)"
              className="mt-3"
            />
          </Col>
        </Row>
      </ContentCard>
    </ContentContainer>
  );
};

export default ViewConstraints;
