import axios from 'axios';
import { generatePath } from 'react-router';
import { isProduction } from '@utiligize/shared/constants';
import Routes from './routes';
export * from './tasks';
export * from './storage';
export { MaxMobileWidth, isProduction, isDevelopment, isDevelop } from '@utiligize/shared/constants';

export enum Languages {
  EN = 'EN',
  DA = 'DA',
  DE = 'DE',
  FR = 'FR',
  IT = 'IT',
  NO = 'NO',
  SV = 'SV',
}

export const DateFormats = {
  CLIENT: 'DD/MM/YYYY',
  SERVER: 'YYYY-MM-DD',
};

// Each table pagination and controls meta stored to redux separately
export enum PaginationType {
  FORMS = 'forms',
  MANUALS = 'manuals',
  ANSWERS = 'answers',
  HISTORY = 'history',
  INSTRUCTIONS = 'instructions',
  UPCOMING_TASKS = 'upcomingTasks',
  TASKS = 'tasks',
  TASKS_FINISHED = 'tasksFinished',
  NEW_TASKS = 'newTasks',
  TASKS_COMPLAINTS = 'tasksComplaints',
  ASSET_MODELS = 'assetModels',
  CATEGORIES = 'categories',
  CATEGORIES_CNAIM = 'categoriesCNAIM',
  ASSETS_CNAIM = 'assetsCNAIM',
  INSPECTIONS = 'inspections',
  MANUFACTURERS = 'manufacturers',
  SMART_METER_ACTIONS = 'smartMeterActions',
  METERS_MISSING_IN_MDM = 'metersMissingInMDM',
  METERS_MISSING_IN_GIS = 'metersMissingInGIS',
  METERS_POPULATION = 'metersPopulation',
  ASSETS = 'assets',
  SUB_COMPONENTS = 'subComponents',
  EMPLOYEES = 'EMPLOYEES',
  ADMIN_ACCESS_LOGS = 'ADMIN_ACCESS_LOGS',
  REPEATED_TASKS_SECTIONS = 'repeatedTasksSections',
  ISSUES = 'ISSUES',
  TRANSFORMERS = 'TRANSFORMERS',
  CABLES = 'CABLES',
  ASSETS_MISSING_AGE = 'ASSETS_MISSING_AGE',
  COMPONENT_PRICES = 'COMPONENT_PRICES',
  WORK_PRICES = 'WORK_PRICES',
  DETAILED_INVESTMENTS = 'DETAILED_INVESTMENTS',
  CHECKLISTS = 'checklists',
  MAINTENANCE_CREATE_PLANS = 'MAINTENANCE_CREATE_PLANS',
  MAINTENANCE_SUMMARY = 'maintenanceSummary',
  MAINTENANCE_PLAN = 'maintenancePlan',
  TOTAL_HOURS = 'totalHours',
  BUDGETING_PARAMETERS = 'budgetingParameters',
  FLEXIBILITY_PLAN = 'flexibilityPlan',
  TASK_HARDWARE = 'taskHardware',
  MAINTENANCE_STATISTICS_USERS = 'MAINTENANCE_STATISTICS_USERS',
  PERMISSIONS_GROUPS = 'permissionsGroups',
  PERMISSIONS_USERS = 'permissionsUsers',
  CUSTOMERS_RIGHTS = 'CUSTOMERS_RIGHTS',
  TRANSFORMERS_IGNORE_MESSAGES = 'transformersIgnoreMessages',
  ASSET_TASK_COMPLETIONS = 'assetTaskCompletions',
  USERS_REGISTERED_HOURS = 'usersRegisteredHours',
  TASKS_REGISTERED_HOURS = 'tasksRegisteredHours',
  FORECASTED_SCENARIOS = 'FORECASTED_SCENARIOS',
  NETWORK_LOADING_CUSTOMERS_TYPES = 'NETWORK_LOADING_CUSTOMERS_TYPES',
  INTERVENTIONS = 'interventions',
  NETWORK_LOADING_FUSES = 'networkLoadingFuses',
  TASKS_MOBILE_SUBMITTED = 'TASKS_MOBILE_SUBMITTED',
  REPEATED_TASKS = 'REPEATED_TASKS',
  NEW_LOAD_DERS = 'NEW_LOAD_DERS',
  NEW_LOAD_DERS_CATEGORIES = 'NEW_LOAD_DERS_CATEGORIES',
  NEW_LOAD_DERS_PROFILES = 'NEW_LOAD_DERS_PROFILES',
  NEW_LOAD_DERS_PHASE_IN = 'NEW_LOAD_DERS_PHASE_IN',

  NEW_LOAD_INDIVIDUAL_CUSTOMERS = 'NEW_LOAD_INDIVIDUAL_CUSTOMERS',
  NETWORK_LOADING_TRANSFORMERS_FORECAST = 'NETWORK_LOADING_TRANSFORMERS_FORECAST',
  NETWORK_LOADING_CABLES_FORECAST = 'NETWORK_LOADING_CABLES_FORECAST',
  NETWORK_LOADING_FUSES_FORECAST = 'NETWORK_LOADING_FUSES_FORECAST',
  NETWORK_LOADING_VOLTAGE_DROP = 'NETWORK_LOADING_VOLTAGE_DROP',
  PERMISSIONS_TENANTS = 'PERMISSIONS_TENANTS',
  PERMISSIONS_FEATURES = 'PERMISSIONS_FEATURES',
  REINVESTMENT_CNAIM = 'REINVESTMENT_CNAIM',
  MAINTENANCE_PLAN_CNAIM = 'MAINTENANCE_PLAN_CNAIM',
  UNIFIED_ASSET_VIEW_TASKS = 'UNIFIED_ASSET_VIEW_TASKS',
  N1 = 'N1',
  INVESTMENT_SCENARIOS = 'INVESTMENT_SCENARIOS',
  CUSTOMERS_CONNECTED = 'CUSTOMERS_CONNECTED',
  SUMMARY_PLAN = 'SUMMARY_PLAN',
  COMPONENT_PRICE_SCENARIOS = 'COMPONENT_PRICE_SCENARIOS',
  INVESTMENT_LIMITATIONS = 'INVESTMENT_LIMITATIONS',
  SETUP_INVESTMENT_BUDGET = 'SETUP_INVESTMENT_BUDGET',
  SETUP_RAW_DATA = 'SETUP_RAW_DATA',
  SETUP_SCENARIO_CALCULATION = 'SETUP_SCENARIO_CALCULATION',
  SETUP_CO2E = 'SETUP_CO2E',
}

export const TableHeadersKeys: {
  [PaginationType.DETAILED_INVESTMENTS]: Omit<
    Record<keyof Investment.DetailedInvestmentsItem, keyof Investment.DetailedInvestmentsItem>,
    'id' | 'cnaim_id'
  >;
} = {
  [PaginationType.DETAILED_INVESTMENTS]: {
    asset_name: 'asset_name',
    asset_category: 'asset_category',
    installation_year: 'installation_year',
    current_type: 'current_type',
    future_type: 'future_type',
    current_capacity: 'current_capacity',
    future_capacity: 'future_capacity',
    replacement_reason: 'replacement_reason',
    replacement_cost: 'replacement_cost',
    suggested_replacement_year: 'suggested_replacement_year',
    latest_replacement_year: 'latest_replacement_year',
    savings_over_remaining_lifetime_current_asset: 'savings_over_remaining_lifetime_current_asset',
    primary_substation_name: 'primary_substation_name',
    grid_zone: 'grid_zone',
    voltage_level: 'voltage_level',
    department: 'department',
    co2e_t_per_year: 'co2e_t_per_year',
  },
};

export const TableHeaders: { [key in PaginationType]: Type.DataTableHeader[] } = {
  [PaginationType.FORMS]: [
    { sortKey: 'name', titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date' },
    { titleKey: 'Type' },
    { titleKey: 'Assigned to' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MANUALS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Date', sortKey: 'createdAt' },
    { titleKey: 'Asset category' },
    { titleKey: 'Asset code' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ANSWERS]: [
    { sortKey: 'formName', titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date of answer' },
    { titleKey: 'Type' },
    { titleKey: 'Asset category' },
    { titleKey: 'Asset code' },
    { titleKey: 'Username' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.HISTORY]: [
    { titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date of answer' },
    { titleKey: 'Type' },
    { titleKey: 'Asset category' },
    { titleKey: 'Asset code' },
    { titleKey: 'Username' },
    { titleKey: 'Tasks' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INSTRUCTIONS]: [
    { sortKey: 'name', titleKey: 'Form name' },
    { sortKey: 'createdAt', titleKey: 'Date' },
    { titleKey: 'Type' },
    { titleKey: 'Assigned to' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.UPCOMING_TASKS]: [
    { sortKey: 'name', titleKey: 'Name' },
    { sortKey: 'startDate', titleKey: 'Start date' },
    { sortKey: 'endDate', titleKey: 'End date' },
    {
      titleKey: 'Expected time',
      className: 'text-center',
      subTitles: [
        { titleKey: '-', className: 'text-center' },
        { titleKey: 'Total', className: 'text-center' },
      ],
    },
    { sortKey: 'status', titleKey: 'Status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS]: [
    { sortKey: 'name', titleKey: 'Name' },
    { sortKey: 'startDate', titleKey: 'Start date' },
    { sortKey: 'endDate', titleKey: 'End date' },
    { sortKey: 'createdBy', titleKey: 'Created by' },
    { sortKey: 'responsibleUsers', titleKey: 'Responsible users' },
    {
      titleKey: 'Registered time',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Internal', className: 'text-center' },
        { titleKey: 'External', className: 'text-center' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_FINISHED]: [
    { sortKey: 'name', titleKey: 'Name' },
    { sortKey: 'startDate', titleKey: 'Start date' },
    { sortKey: 'endDate', titleKey: 'End date' },
    { sortKey: 'createdBy', titleKey: 'Created by' },
    { sortKey: 'finishDate', titleKey: 'Finish date' },
    { sortKey: 'finishedBy', titleKey: 'Finished by' },
    {
      titleKey: 'Registered time',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Internal', className: 'text-center' },
        { titleKey: 'External', className: 'text-center' },
      ],
    },
    { titleKey: 'Checklist status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_TASKS]: [
    { titleKey: 'Date', sortKey: 'date' },
    { titleKey: 'Department', sortKey: 'department' },
    { titleKey: 'Description' },
    { titleKey: 'Main activity', sortKey: 'mainActivity' },
    { titleKey: 'Project number', sortKey: 'projectNumber' },
    { titleKey: 'Task number', sortKey: 'taskNumber' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_COMPLAINTS]: [
    { titleKey: 'Date', sortKey: 'createdAt' },
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Name of road', sortKey: 'nameOfRoad' },
    { titleKey: 'Type of fault', sortKey: 'typeOfFault' },
    { titleKey: 'Post code and town', sortKey: 'postcodeAndTown' },
    { titleKey: 'Telephone', sortKey: 'telephone' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ASSET_MODELS]: [
    { titleKey: 'Date' },
    { titleKey: 'Manufacturer' },
    { titleKey: 'Model' },
    {
      titleKey: 'Counts',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Assets', className: 'text-center' },
        { titleKey: 'Instructions', className: 'text-center' },
        { titleKey: 'Data manuals', className: 'text-center' },
      ],
    },
    {
      titleKey: 'Replacement',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Date', className: 'text-center' },
        { titleKey: 'Option', className: 'text-center' },
        { titleKey: 'Priority', className: 'text-center' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.CATEGORIES]: [
    { sortKey: 'name', titleKey: 'Category name' },
    { sortKey: 'code', titleKey: 'Code' },
    !isProduction && ({ titleKey: 'Actions', className: 'text-right' } as any),
  ].filter(Boolean),
  [PaginationType.CATEGORIES_CNAIM]: [
    { titleKey: 'Class', sortKey: 'assetClass' },
    { titleKey: 'Component', sortKey: 'assetComponent' },
    { titleKey: 'Version' },
    { titleKey: 'Number of assets' },
    { titleKey: 'Email' },
    { titleKey: 'Date' },
    { titleKey: 'Subcomponent', sortKey: 'subcomponent' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ASSETS_CNAIM]: [
    /* this table uses custom headers */
  ],
  [PaginationType.INSPECTIONS]: [
    { sortKey: 'name', titleKey: 'Inspection name' },
    { sortKey: 'createdAt', titleKey: 'Date' },
    { titleKey: 'Type' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MANUFACTURERS]: [{ sortKey: 'name', titleKey: 'Manufacturer' }, { titleKey: 'Actions' }],
  [PaginationType.SMART_METER_ACTIONS]: [
    { titleKey: 'Old meter number' },
    { titleKey: 'Logging date', sortKey: 'timestamp' },
    { titleKey: 'New meter number' },
    { titleKey: 'Activity', className: 'text-center', sortKey: 'action' },
    { titleKey: 'User' },
    { titleKey: 'Installation number' },
    { titleKey: 'Date of work', className: 'text-center' },
    { titleKey: 'Checklist status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ASSETS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Asset code', sortKey: 'code' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SUB_COMPONENTS]: [
    { titleKey: 'Sub-component type name', sortKey: 'name' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.EMPLOYEES]: [
    { titleKey: 'Name', sortKey: 'fullName' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Start date', sortKey: 'startDate' },
    { titleKey: 'End date', sortKey: 'endDate' },
    { titleKey: 'Billable hours', sortKey: 'minutes' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ADMIN_ACCESS_LOGS]: [
    { titleKey: 'User', sortKey: 'user' },
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Last access', sortKey: 'lastAccess' },
    { titleKey: 'Operating system', sortKey: 'operatingSystem' },
    { titleKey: 'App opened', sortKey: 'appOpened' },
  ],
  [PaginationType.REPEATED_TASKS_SECTIONS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Department' },
    { titleKey: 'Asset category' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ISSUES]: [
    { titleKey: 'ID', sortKey: 'id' },
    { titleKey: 'Date of discovery', sortKey: 'opened_time' },
    { titleKey: 'Code', sortKey: 'code' },
    { titleKey: 'Ignore', className: 'text-center' },
    { titleKey: 'Explanation' },
    { titleKey: 'Description' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TRANSFORMERS]: [
    { titleKey: 'Name', sortKey: 'customer_asset_name' },
    { titleKey: 'Date deployed', sortKey: 'date_commissioned' },
    { titleKey: 'Rating (kVA)', sortKey: 'kva' },
    { titleKey: 'Solved', className: 'text-center', sortKey: 'ignore' },
    { titleKey: 'Explanation', sortKey: 'explanation' },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Loading (kW)', sortKey: 'maxLoading' },
        { titleKey: 'Reactive (kVAR)', sortKey: 'maxReactive' },
        { titleKey: 'Losses (kVA)', sortKey: 'gridLosses' },
        { titleKey: 'Loading time', sortKey: 'maxLoadingTime' },
        { titleKey: 'Utilization', sortKey: 'utilization' },
      ],
    },
    { titleKey: 'Annual consumption', sortKey: 'annualConsumption' },
    {
      titleKey: 'Voltage',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Min', sortKey: 'voltage_min' },
        { titleKey: 'Max', sortKey: 'voltage_max' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TRANSFORMERS_IGNORE_MESSAGES]: [
    { titleKey: 'Time', sortKey: 'time' },
    { titleKey: 'Name', sortKey: 'username' },
    { titleKey: 'Explanation' },
  ],
  [PaginationType.CABLES]: [
    { titleKey: 'Name', sortKey: 'customer_asset_name' },
    { titleKey: 'Type', sortKey: 'type' },
    { titleKey: 'Distribution feeder', sortKey: 'distribution_feeder' },
    { titleKey: 'Length (m)', sortKey: 'length' },
    { titleKey: 'Date deployed', sortKey: 'date_commissioned' },
    { titleKey: 'Current (A)', sortKey: 'max_i_ka' },
    { titleKey: 'Solved', className: 'text-center', sortKey: 'ignore' },
    { titleKey: 'Explanation', sortKey: 'explanation' },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Loading (kW)', sortKey: 'maxLoading' },
        { titleKey: 'Reactive (kVAR)', sortKey: 'maxReactive' },
        { titleKey: 'Losses (kVA)', sortKey: 'gridLosses' },
        { titleKey: 'Loading time', sortKey: 'maxLoadingTime' },
        { titleKey: 'Utilization', sortKey: 'utilization' },
      ],
    },
    { titleKey: 'Annual consumption', sortKey: 'annualConsumption' },
    {
      titleKey: 'Voltage',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Min', sortKey: 'voltage_min' },
        { titleKey: 'Max', sortKey: 'voltage_max' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.ASSETS_MISSING_AGE]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Type', sortKey: 'type' },
    { titleKey: 'Manufacturer', sortKey: 'manufacturer' },
    { titleKey: 'Comments', sortKey: 'comments' },
  ],
  [PaginationType.COMPONENT_PRICES]: [
    { titleKey: 'Asset category', sortKey: 'asset_register_category' },
    { titleKey: 'Size', sortKey: 'size' },
    { titleKey: 'Size unit', sortKey: 'size_unit' },
    { titleKey: 'Price', sortKey: 'price' },
    { titleKey: 'Price unit', sortKey: 'price_unit' },
    { titleKey: 'Price type', sortKey: 'price_type' },
    { titleKey: 'Component price scenario', sortKey: 'component_price_scenario' },
    {
      titleKey: 'CO₂e',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Scope 1', sortKey: 'co2e_scope_1', className: 'text-center text-nowrap' },
        { titleKey: 'Scope 2', sortKey: 'co2e_scope_2', className: 'text-center text-nowrap' },
        { titleKey: 'Scope 3', sortKey: 'co2e_scope_3', className: 'text-center text-nowrap' },
        { titleKey: 'Unit', sortKey: 'co2e_unit', className: 'text-center text-nowrap' },
      ],
    },
    { titleKey: 'Grid zone', sortKey: 'grid_zone' },
    { titleKey: 'Lifetime', sortKey: 'lifetime' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.WORK_PRICES]: [
    { titleKey: 'Work type', sortKey: 'description' },
    { titleKey: 'Grid zone', sortKey: 'grid_zone_description' },
    { titleKey: 'Price', sortKey: 'price' },
    { titleKey: 'Price unit', sortKey: 'price_per_unit' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SETUP_INVESTMENT_BUDGET]: [
    { titleKey: 'Asset category', sortKey: 'asset_register_category' },
    { titleKey: 'Size', sortKey: 'size_asset' },
    { titleKey: 'Size unit', sortKey: 'size_unit' },
    { titleKey: 'Lifetime', sortKey: 'lifetime' },
    { titleKey: 'Grid zone', sortKey: 'grid_zone' },
    { titleKey: 'Price (DKK)', sortKey: 'price' },
    { titleKey: 'Count', sortKey: 'count_asset' },
    { titleKey: 'Length (km)', sortKey: 'total_length_km' },
    { titleKey: 'Total asset value', sortKey: 'total_assets_value' },
    { titleKey: 'Yearly budget', sortKey: 'yearly_budget' },
  ],
  [PaginationType.DETAILED_INVESTMENTS]: [
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_name,
      titleKey: 'Customer asset name',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_name,
    },
    { key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].asset_category, titleKey: 'Asset category' },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].installation_year,
      titleKey: 'Installation year',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].installation_year,
    },
    {
      titleKey: 'Type',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_type,
          titleKey: 'Current',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_type,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_type,
          titleKey: 'Future',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_type,
        },
      ],
    },
    {
      titleKey: 'Capacity',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_capacity,
          titleKey: 'Current',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].current_capacity,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_capacity,
          titleKey: 'Future',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].future_capacity,
        },
      ],
    },
    {
      titleKey: 'Investment',
      className: 'text-center',
      subTitles: [
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_reason,
          titleKey: 'Reason',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_reason,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_cost,
          titleKey: 'Cost ({{currency}})',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].replacement_cost,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].suggested_replacement_year,
          titleKey: 'Suggested year',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].suggested_replacement_year,
        },
        {
          key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].latest_replacement_year,
          titleKey: 'Latest year',
          sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].latest_replacement_year,
        },
      ],
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].savings_over_remaining_lifetime_current_asset,
      titleKey: 'Savings over the remaining lifetime ({{currency}})',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].savings_over_remaining_lifetime_current_asset,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].primary_substation_name,
      titleKey: 'Primary substation',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].primary_substation_name,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].grid_zone,
      titleKey: 'Grid zone',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].grid_zone,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].voltage_level,
      titleKey: 'Voltage level',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].voltage_level,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].department,
      titleKey: 'Portfolio',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].department,
    },
    {
      key: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].co2e_t_per_year,
      titleKey: 'CO₂e (t/year)',
      sortKey: TableHeadersKeys[PaginationType.DETAILED_INVESTMENTS].co2e_t_per_year,
    },
  ],
  [PaginationType.SUMMARY_PLAN]: [
    { titleKey: 'Asset category' },
    { titleKey: 'Investment year', sortKey: 'suggested_replacement_year' },
    { titleKey: 'Investment reason', sortKey: 'replacement_reason' },
    { titleKey: 'Grid zone', sortKey: 'grid_zone_id' },
    { titleKey: 'Voltage level', sortKey: 'voltage_level_text' },
    { titleKey: 'Portfolio', sortKey: 'department' },
    { titleKey: 'Primary substation', sortKey: 'name' },
    { titleKey: 'Investment cost ({{currency}})', sortKey: 'replacement_cost' },
    { titleKey: 'CO₂e (t/year)', sortKey: 'co2e_t_per_year' },
  ],
  [PaginationType.CHECKLISTS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Asset category', sortKey: 'assetCategoryCode' },
    { titleKey: 'App action', sortKey: 'appAction' },
    { titleKey: 'Questions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.METERS_MISSING_IN_MDM]: [
    { titleKey: 'Installation number', sortKey: 'installation_number' },
    { titleKey: 'Metering point', sortKey: 'metering_point' },
    { titleKey: 'Meter number', sortKey: 'meter_number' },
    { titleKey: 'Address', sortKey: 'address' },
  ],
  [PaginationType.METERS_MISSING_IN_GIS]: [
    { titleKey: 'Last measurement', sortKey: 'last_measurement' },
    { titleKey: 'Installation number', sortKey: 'installation_number' },
    { titleKey: 'Metering point', sortKey: 'metering_point' },
    { titleKey: 'Meter number', sortKey: 'meter_number' },
    { titleKey: 'Address', sortKey: 'address' },
  ],
  [PaginationType.METERS_POPULATION]: [
    { titleKey: 'Meter number', sortKey: 'meterNumber' },
    { titleKey: 'Batch number', sortKey: 'batchNumber' },
    { titleKey: 'Type', sortKey: 'type' },
    { titleKey: 'Installation number', sortKey: 'installation' },
    { titleKey: 'Address', sortKey: 'address' },
    { titleKey: 'Connection', sortKey: 'connection' },
    { titleKey: 'Serial number', sortKey: 'serialNumber' },
    {
      titleKey: 'Date',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Activated', sortKey: 'dateActivated', className: 'text-center' },
        { titleKey: 'Discarded', sortKey: 'dateDiscarded', className: 'text-center' },
        { titleKey: 'Removed', sortKey: 'dateRemoved', className: 'text-center' },
        { titleKey: 'Control', sortKey: 'controlDate', className: 'text-center' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MAINTENANCE_CREATE_PLANS]: [
    { titleKey: 'Asset category' },
    { titleKey: 'Date select' },
    { titleKey: 'Date explicit' },
    { titleKey: 'Plan name' },
    { titleKey: 'Missing action' },
    { titleKey: 'Intervals (years)' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.MAINTENANCE_SUMMARY]: [
    { titleKey: 'Asset category' },
    { titleKey: 'Year' },
    { titleKey: 'Number' },
    { titleKey: 'Plan name' },
  ],
  [PaginationType.MAINTENANCE_PLAN]: [
    { titleKey: 'Plan name' },
    { titleKey: 'Name' },
    { titleKey: 'Maintenance year' },
    { titleKey: 'Override year' },
  ],
  [PaginationType.TOTAL_HOURS]: [
    { titleKey: 'Name' },
    { titleKey: 'App action' },
    { titleKey: 'Type' },
    { titleKey: 'Expected time' },
  ],
  [PaginationType.BUDGETING_PARAMETERS]: [
    { titleKey: 'Year' },
    { titleKey: 'Meters' },
    { titleKey: 'Stations' },
    { titleKey: 'kWh' },
    { titleKey: 'Depreciations' },
    { titleKey: 'Opex' },
    { titleKey: 'Revenue' },
    {
      titleKey: 'Grid loss',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Grid loss cost', className: 'text-center' },
        { titleKey: 'Physical', className: 'text-center' },
        { titleKey: 'Unit price', className: 'text-center' },
      ],
    },
  ],
  [PaginationType.FLEXIBILITY_PLAN]: [
    { titleKey: 'Primary Substation', sortKey: 'primary_substation' },
    { titleKey: 'Asset', sortKey: 'asset' },
    { titleKey: 'Energy (MWh)', sortKey: 'energy_mwh' },
    { titleKey: 'Number of hours', sortKey: 'number_of_hours' },
    { titleKey: 'Value (DKK)', sortKey: 'value_dkk' },
  ],
  [PaginationType.TASK_HARDWARE]: [
    { titleKey: 'Date' },
    { titleKey: 'Quantity' },
    { titleKey: 'Cost', className: 'text-right' },
    { titleKey: 'Type' },
    { titleKey: 'Unit' },
    { titleKey: 'Description' },
  ],
  [PaginationType.MAINTENANCE_STATISTICS_USERS]: [
    { titleKey: 'User', sortKey: 'fullName' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Maintenance' },
    { titleKey: 'Repair' },
    { titleKey: 'Inspections' },
    { titleKey: 'Final check' },
    { titleKey: 'Task related forms' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.PERMISSIONS_GROUPS]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Permissions' },
    { titleKey: 'Default', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.PERMISSIONS_USERS]: [
    { titleKey: 'Name', sortKey: 'fullName' },
    { titleKey: 'Email', sortKey: 'email' },
    { titleKey: 'Groups' },
    { titleKey: 'Permissions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.CUSTOMERS_RIGHTS]: [
    { titleKey: 'Installation ID', sortKey: 'installation_number' },
    { titleKey: 'Address', sortKey: 'address' },
    { titleKey: 'Substation', sortKey: 'substation' },
    { titleKey: 'Solved', className: 'text-center' },
    { titleKey: 'Explanation', sortKey: 'explanation' },
    { titleKey: 'Current rights (A)', sortKey: 'amps_rights' },
    !isProduction && ({ titleKey: 'Current rights production (A)', sortKey: 'amps_rights_prod' } as any),
    {
      titleKey: 'Max measured',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Current (A)', sortKey: 'amps' },
        { titleKey: 'Consumption (kVA)', sortKey: 'kva' },
        !isProduction && ({ titleKey: 'Production (kVA)', sortKey: 'kva_rights' } as any),
        { titleKey: 'Time', sortKey: 'time' },
        { titleKey: 'Utilization (%)', sortKey: 'utilization' },
      ].filter(Boolean),
    },
    { titleKey: 'Actions', className: 'text-right' },
  ].filter(Boolean),
  [PaginationType.ASSET_TASK_COMPLETIONS]: [
    { titleKey: 'Date' },
    { titleKey: 'Name' },
    { titleKey: 'Email' },
    { titleKey: 'Task' },
    { titleKey: 'Answer' },
  ],
  [PaginationType.USERS_REGISTERED_HOURS]: [
    { titleKey: 'User (Σ)', sortKey: 'fullName' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_REGISTERED_HOURS]: [
    { titleKey: 'Task (Σ)', sortKey: 'name' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NETWORK_LOADING_CUSTOMERS_TYPES]: [{ titleKey: 'Type' }, { titleKey: 'Number' }],
  [PaginationType.FORECASTED_SCENARIOS]: [
    { titleKey: 'Scenario', sortKey: 'description' },
    { titleKey: 'User', sortKey: 'user' },
    { titleKey: 'Date created', sortKey: 'created_at' },
    { titleKey: 'Date updated', sortKey: 'updated_at' },
    { titleKey: 'Years', sortKey: 'years' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INTERVENTIONS]: [
    { titleKey: 'Asset', sortKey: 'asset' },
    { titleKey: 'Category', sortKey: 'category' },
    { titleKey: 'Installation year', sortKey: 'year_installed' },
    { titleKey: 'MVA', sortKey: 'MVA' },
    { titleKey: 'Monetary risk reduction (M EUR)', sortKey: 'Monetary risk reduction (M EUR)' },
    { titleKey: 'Zone' },
    { titleKey: 'Manufacturer' },
    { titleKey: 'Model' },
    { titleKey: 'Tag' },
    { titleKey: 'Bundle', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NETWORK_LOADING_FUSES]: [
    { titleKey: 'Cable', sortKey: 'kabel' },
    { titleKey: 'Fuse (A)', sortKey: 'fuse' },
    { titleKey: 'Solved', className: 'text-center' },
    { titleKey: 'Explanation' },
    { titleKey: 'Utilization', sortKey: 'utilization' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.TASKS_MOBILE_SUBMITTED]: [
    { titleKey: 'Name', sortKey: 'name' },
    { titleKey: 'Date created', sortKey: 'createdAt' },
    { titleKey: 'Created by', sortKey: 'createdBy' },
    { titleKey: 'Department' },
    { titleKey: 'Assets' },
    { titleKey: 'App action', sortKey: 'appAction' },
    { titleKey: 'Status', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.REPEATED_TASKS]: [
    { titleKey: 'Task number' },
    { titleKey: 'Status' },
    { titleKey: 'Description' },
    { titleKey: 'Project number' },
    { titleKey: 'App action' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS]: [
    { titleKey: 'Description' },
    { titleKey: 'Category' },
    { titleKey: 'Population' },
    { titleKey: 'Baseload' },
    { titleKey: 'Max number per installation' },
    { titleKey: 'Grid zones' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS_CATEGORIES]: [
    { titleKey: 'Category' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS_PROFILES]: [
    { titleKey: 'Date' },
    { titleKey: 'Company' },
    { titleKey: 'Description' },
    { titleKey: 'User' },
    { titleKey: 'Shared', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_DERS_PHASE_IN]: [
    { titleKey: 'Date' },
    { titleKey: 'Company' },
    { titleKey: 'Description' },
    { titleKey: 'User' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NEW_LOAD_INDIVIDUAL_CUSTOMERS]: [
    { titleKey: 'Connection year' },
    { titleKey: 'Description' },
    { titleKey: 'Point asset' },
    {
      titleKey: 'Customer',
      className: 'text-center',
      subTitles: [
        { titleKey: 'ID', className: 'text-center' },
        { titleKey: 'MW', className: 'text-center' },
      ],
    },
    { titleKey: 'Power factor' },
    { titleKey: 'Leading lagging' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.SETUP_RAW_DATA]: [{ titleKey: 'Name' }, { titleKey: 'Actions', className: 'text-right' }],
  [PaginationType.NETWORK_LOADING_TRANSFORMERS_FORECAST]: [
    { titleKey: 'Name' },
    { titleKey: 'Date deployed' },
    { titleKey: 'Rating (kVA)' },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        { titleKey: '24h (%)' },
        { titleKey: '24h (p.u.)' },
        { titleKey: '48h (%)' },
        { titleKey: '48h (p.u.)' },
        { titleKey: '7d (%)' },
        { titleKey: '7d (p.u.)' },
        { titleKey: 'Overload start' },
        { titleKey: 'Peak' },
      ],
    },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.NETWORK_LOADING_CABLES_FORECAST]: [
    { titleKey: 'Name' },
    { titleKey: 'Length (m)' },
    { titleKey: 'Date deployed' },
    { titleKey: 'Rating (kVA)' },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        { titleKey: '24h (%)' },
        { titleKey: '24h (p.u.)' },
        { titleKey: '48h (%)' },
        { titleKey: '48h (p.u.)' },
        { titleKey: '7d (%)' },
        { titleKey: '7d (p.u.)' },
        { titleKey: 'Overload start' },
        { titleKey: 'Peak' },
      ],
    },
  ],
  [PaginationType.NETWORK_LOADING_FUSES_FORECAST]: [
    { titleKey: 'Name' },
    { titleKey: 'Fuse (A)' },
    {
      titleKey: 'Max',
      className: 'text-center',
      subTitles: [
        { titleKey: '24h (%)' },
        { titleKey: '48h (%)' },
        { titleKey: '7d (%)' },
        { titleKey: 'Overload start' },
        { titleKey: 'Peak' },
      ],
    },
  ],
  [PaginationType.NETWORK_LOADING_VOLTAGE_DROP]: [
    /* this view has filters for chart */
  ],
  [PaginationType.PERMISSIONS_TENANTS]: [
    { titleKey: 'Name', sortKey: 'tenantName' },
    { titleKey: 'Permissions' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.PERMISSIONS_FEATURES]: [
    { titleKey: 'Feature' },
    { titleKey: 'Description' },
    { titleKey: 'Tenants' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.REINVESTMENT_CNAIM]: [
    { titleKey: 'Name', sortKey: 'customer_id' },
    { titleKey: 'Max Loading Percentage', sortKey: 'loading_percent_max' },
    { titleKey: 'Sum of probability of failure', sortKey: 'pof_sum' },
    { titleKey: 'Critical probability of failure reach', sortKey: 'critical_pof_reach' },
  ],
  [PaginationType.MAINTENANCE_PLAN_CNAIM]: [
    { titleKey: 'Name', sortKey: 'customer_id' },
    { titleKey: 'Probability of failure', sortKey: 'pof' },
    { titleKey: 'Consequence of failure', sortKey: 'cof' },
    { titleKey: 'Monetary risk', sortKey: 'monetary_risk' },
    { titleKey: 'Inspection interval', sortKey: 'inspection' },
  ],
  [PaginationType.UNIFIED_ASSET_VIEW_TASKS]: [
    /* this view has custom headers */
  ],
  [PaginationType.N1]: [
    {
      titleKey: 'Outage',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Asset', sortKey: 'outage_asset' },
        { titleKey: 'Asset type', sortKey: 'outage_asset_type' },
        { titleKey: 'Type', sortKey: 'outage_type_text' },
      ],
    },
    { titleKey: 'Supply primary substation', sortKey: 'primary_substation' },
    { titleKey: 'Route' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INVESTMENT_SCENARIOS]: [
    { titleKey: 'Scenario', sortKey: 'description' },
    { titleKey: 'User', sortKey: 'sso_user' },
    { titleKey: 'Date created', sortKey: 'created_at' },
    { titleKey: 'Date updated', sortKey: 'updated_at' },
    { titleKey: 'Forecasted scenario', sortKey: 'forecasted_scenario' },
    { titleKey: 'Component price scenario', sortKey: 'component_price_scenario' },
    { titleKey: 'Interest rate (%)', sortKey: 'interest_rate' },
    { titleKey: 'Electricity price ({{currency}}/MWh)' },
    { titleKey: 'Loss optimisation', sortKey: 'loss_optimisation', className: 'text-center' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.CUSTOMERS_CONNECTED]: [
    { titleKey: 'Station', sortKey: 'station' },
    { titleKey: 'Installations', sortKey: 'installations' },
    { titleKey: 'Version', sortKey: 'version' },
  ],
  [PaginationType.COMPONENT_PRICE_SCENARIOS]: [
    { titleKey: 'Scenario', sortKey: 'description' },
    { titleKey: 'Price evolution (% per year)', sortKey: 'price_evolution_percent' },
    { titleKey: 'User', sortKey: 'sso_user' },
    { titleKey: 'Date created', sortKey: 'created_at' },
    { titleKey: 'Date updated', sortKey: 'updated_at' },
    { titleKey: 'Actions', className: 'text-right' },
  ],
  [PaginationType.INVESTMENT_LIMITATIONS]: [
    { titleKey: '' },
    { titleKey: '' },
    { titleKey: 'Cables max loading (%)' },
    { titleKey: 'Transformers max loading (%)' },
    { titleKey: 'Min voltage ({{unit}})' },
    { titleKey: 'Max voltage ({{unit}})' },
  ],
  [PaginationType.SETUP_SCENARIO_CALCULATION]: [
    { titleKey: 'Name' },
    { titleKey: 'Monday', className: 'text-center' },
    { titleKey: 'Tuesday', className: 'text-center' },
    { titleKey: 'Wednesday', className: 'text-center' },
    { titleKey: 'Thursday', className: 'text-center' },
    { titleKey: 'Friday', className: 'text-center' },
    { titleKey: 'Saturday', className: 'text-center' },
    { titleKey: 'Sunday', className: 'text-center' },
  ],
  [PaginationType.SETUP_CO2E]: [
    { titleKey: 'DER' },
    {
      titleKey: 'CO₂e reduction (t/unit/year)',
      className: 'text-center',
      subTitles: [
        { titleKey: 'Scope 1', className: 'text-center' },
        { titleKey: 'Scope 2', className: 'text-center' },
        { titleKey: 'Scope 3', className: 'text-center' },
      ],
    },
    { titleKey: 'Climate benefit (t/unit/year)', className: 'text-center' },
  ],
};

export const MAX_FIELDS = 10;

export const TwoOptionDefaultIndexes = {
  Child1: 1000,
  Child2: 4000,
};

export enum BuilderFieldsTypes {
  Date = 'Date',
  PhotoCollection = 'Photo collection',
  SingleSelect = 'Single select',
  MultipleSelect = 'Multiple select',
  TextInput = 'Text input',
  NumberInput = 'Number input',
  LargeTextInput = 'Large text input',
  TwoOption = 'Two-option',
  InspectionQuestion = 'Inspection question',
  Text = 'Text',
  Photo = 'Photo',
  NumberInputInspection = 'Number input inspection',
  AssetPhoto = 'Asset photo',
  BinarySelect = 'Binary select',
  LargeText = 'Large Text',
  YouTubeVideo = 'YouTube Video',
  URL = 'URL',
  Image = 'Image',
  LinkedForm = 'Linked Form',
  LinkedFunctionality = 'Linked Functionality',
  Signature = 'Signature',
}

export const LegacyBuilderFieldsTypesMap = {
  [BuilderFieldsTypes.InspectionQuestion]: 'Inspectionquestion',
  [BuilderFieldsTypes.AssetPhoto]: 'Assetphoto',
  [BuilderFieldsTypes.BinarySelect]: 'Binaryselect',
  [BuilderFieldsTypes.TwoOption]: BuilderFieldsTypes.TwoOption,
  [BuilderFieldsTypes.LargeTextInput]: 'Largetextinput',
  [BuilderFieldsTypes.Text]: BuilderFieldsTypes.Text,
  [BuilderFieldsTypes.PhotoCollection]: 'Photocollection',
  [BuilderFieldsTypes.LinkedForm]: 'LinkedForm',
  [BuilderFieldsTypes.NumberInput]: 'Numberinput',
  [BuilderFieldsTypes.MultipleSelect]: 'Multipleselect',
  [BuilderFieldsTypes.Photo]: BuilderFieldsTypes.Photo,
  [BuilderFieldsTypes.Date]: BuilderFieldsTypes.Date,
  [BuilderFieldsTypes.TextInput]: 'Textinput',
  [BuilderFieldsTypes.LinkedFunctionality]: 'LinkedFunctionality',
  [BuilderFieldsTypes.SingleSelect]: 'Singleselect',
  [BuilderFieldsTypes.NumberInputInspection]: 'Numberinputinspection',
  [BuilderFieldsTypes.Signature]: BuilderFieldsTypes.Signature,
};

export const BuilderFieldsTypesWithoutAnswers: BuilderFieldsTypes[] = [
  BuilderFieldsTypes.Text,
  BuilderFieldsTypes.LargeText,
  BuilderFieldsTypes.YouTubeVideo,
  BuilderFieldsTypes.URL,
  BuilderFieldsTypes.Photo,
  BuilderFieldsTypes.Image,
  BuilderFieldsTypes.AssetPhoto,
  BuilderFieldsTypes.NumberInputInspection,
];

export const BuilderFieldsTypesValues = Object.values(BuilderFieldsTypes);

export enum FunctionalityTypes {
  ReplaceAsset = 'Replace Asset',
  EditMetaData = 'Edit Meta Data',
}

const baseProps: Pick<Builder.BaseField, 'id' | 'indexBlock' | 'index' | 'checked' | 'title'> = {
  id: 0,
  indexBlock: 0,
  index: 0,
  checked: false,
  title: '',
};

export const FIELDS: Type.Hash<Builder.BaseField> = {
  [BuilderFieldsTypes.Text]: {
    ...baseProps,
    type: BuilderFieldsTypes.Text,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.Signature]: {
    ...baseProps,
    type: BuilderFieldsTypes.Signature,
    params: [{ name: 'Label', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.TextInput]: {
    ...baseProps,
    type: BuilderFieldsTypes.TextInput,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Placeholder', type: 'string', num: 1, values: [{ value: '' }] },
      { name: 'commentsAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
    ],
  },
  [BuilderFieldsTypes.LargeTextInput]: {
    ...baseProps,
    type: BuilderFieldsTypes.LargeTextInput,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Placeholder', type: 'string', num: 1, values: [{ value: '' }] },
      { name: 'commentsAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
    ],
  },
  [BuilderFieldsTypes.LargeText]: {
    ...baseProps,
    type: BuilderFieldsTypes.LargeText,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.NumberInput]: {
    ...baseProps,
    type: BuilderFieldsTypes.NumberInput,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Min Value', type: 'number', num: 1, values: [{ value: 0 }] },
      { name: 'Max Value', type: 'number', num: 2, values: [{ value: 0 }] },
      { name: 'inputName', type: 'string', num: 3, values: [{ value: '' }] },
      { name: 'Unit', type: 'string', num: 4, values: [{ value: '' }] },
      { name: 'Placeholder', type: 'string', num: 5, values: [{ value: '' }] },
    ],
  },
  [BuilderFieldsTypes.NumberInputInspection]: {
    ...baseProps,
    type: BuilderFieldsTypes.NumberInputInspection,
    params: [
      { name: 'Text', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Number', type: 'number', num: 1, values: [{ value: 0 }] },
    ],
  },
  [BuilderFieldsTypes.Date]: {
    ...baseProps,
    type: BuilderFieldsTypes.Date,
    params: [{ name: 'Label', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.URL]: {
    ...baseProps,
    type: BuilderFieldsTypes.URL,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.YouTubeVideo]: {
    ...baseProps,
    type: BuilderFieldsTypes.YouTubeVideo,
    params: [{ name: 'Title', type: 'string', num: 0, values: [{ value: '' }] }],
  },
  [BuilderFieldsTypes.Photo]: {
    ...baseProps,
    type: BuilderFieldsTypes.Photo,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Photo', type: 'file', num: 1, values: [{ value: '', valuealt: '' }] },
    ],
  },
  [BuilderFieldsTypes.AssetPhoto]: {
    ...baseProps,
    type: BuilderFieldsTypes.AssetPhoto,
    params: [
      { name: 'Description', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Photo', type: 'file', num: 1, values: [{ value: '', valuealt: '' }] },
    ],
  },
  [BuilderFieldsTypes.Image]: {
    ...baseProps,
    type: BuilderFieldsTypes.Image,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Photo', type: 'file', num: 1, values: [{ value: '', valuealt: '' }] },
    ],
  },
  [BuilderFieldsTypes.PhotoCollection]: {
    ...baseProps,
    type: BuilderFieldsTypes.PhotoCollection,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      { name: 'Min Photo', type: 'number', num: 1, values: [{ value: 1 }] },
      { name: 'Max Photo', type: 'number', num: 2, values: [{ value: 2 }] },
    ],
  },
  [BuilderFieldsTypes.SingleSelect]: {
    ...baseProps,
    type: BuilderFieldsTypes.SingleSelect,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [{ index: 0, value: '', color: '#ffffff', field: [] }],
      },
    ],
  },
  [BuilderFieldsTypes.MultipleSelect]: {
    ...baseProps,
    type: BuilderFieldsTypes.MultipleSelect,
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [{ index: 0, value: '', color: '#ffffff', field: [] }],
      },
    ],
  },
  [BuilderFieldsTypes.InspectionQuestion]: {
    ...baseProps,
    type: BuilderFieldsTypes.InspectionQuestion,
    params: [
      { name: 'Title', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [{ index: 0, value: '', explanation: '', color: '#ffffff', field: [] }],
      },
    ],
  },
  [BuilderFieldsTypes.BinarySelect]: {
    ...baseProps,
    type: BuilderFieldsTypes.BinarySelect,
    params: [
      {
        name: 'Variants',
        type: 'string',
        num: 0,
        values: [{ value: '' }],
        variants: [
          { index: 0, value: '', field: [] },
          { index: 1, value: '', field: [] },
        ],
      },
    ],
  },
  [BuilderFieldsTypes.TwoOption]: {
    ...baseProps,
    type: BuilderFieldsTypes.TwoOption,
    fieldtype: { name: BuilderFieldsTypes.TwoOption }, // FixMe.
    checked: [false, false],
    params: [
      { name: 'Label', type: 'string', num: 0, values: [{ value: '' }] },
      {
        name: 'Variants',
        type: 'string',
        num: 1,
        values: [{ value: '' }],
        variants: [
          { index: 0, value: '', field: [] },
          { index: 1, value: '', field: [] },
        ],
      },
    ],
  },
  [BuilderFieldsTypes.LinkedForm]: {
    ...baseProps,
    type: BuilderFieldsTypes.LinkedForm,
    params: [
      { name: 'Form type', type: 'number', num: 0, values: [{ value: null as any, valuealt: '' }] },
      { name: 'Selected form', type: 'number', num: 1, values: [{ value: null as any, valuealt: '' }] },
      { name: 'scheduleAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
      { name: 'Label', type: 'string', num: 3, values: [{ value: '' }] },
    ],
  },
  [BuilderFieldsTypes.LinkedFunctionality]: {
    ...baseProps,
    type: BuilderFieldsTypes.LinkedFunctionality,
    params: [
      { name: 'Functionality type', type: 'number', num: 0, values: [{ value: null as any, valuealt: '' }] },
      { name: 'Label', type: 'string', num: 1, values: [{ value: '' }] },
      { name: 'scheduleAllowed', type: 'bool', num: 2, values: [{ value: false as any }] },
      { name: 'subComponentTypeId', type: 'number', num: 3, values: [{ value: null }] },
    ],
  },
};

export enum AssetCategories {
  TH = 'TH', // TH === ST
  ST = 'ST',
  TR = 'TR',
  MV = 'MV',
  LV = 'LV',
  RT = 'RT',
  KS = 'KS',
  BD = 'BD',
  TS = 'TS',
  SS = 'SS',
  GL = 'GL',
  LE = 'LE',
  LL = 'LL',
  LH = 'LH',
  SM = 'SM',
}

export const SyntheticAssetCategories = [AssetCategories.LL, AssetCategories.LH, AssetCategories.SM];

export enum PageAssetTabs {
  ROOT = '',
  PHOTO = 'photo',
  SUB_COMPONENTS = 'sub-components',
  HISTORY = 'history',
  ANSWERS = 'answers',
  TASK_COMPLETIONS = 'task-completions',
  DATA_MANUALS = 'data-manuals',
}

export const MapParams = {
  minZoom: 2,
  maxZoom: 22,
  maxClusterZoom: 14,
};

export enum TransformerVoltages {
  '10/0.4kV transformer' = '10/0.4kV transformer',
  '60/10kV transformer' = '60/10kV transformer',
  '150/60kV transformer' = '150/60kV transformer',
}

export enum CableVoltages {
  '0,4kV' = '0,4kV',
  '10kV' = '10kV',
  '60kV' = '60kV',
}

export enum VoltageSide {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export enum VoltageDisplay {
  'Per-unit' = 'Per-unit',
  Volts = 'Volts',
}

export enum BICalculations {
  Off = 'Off',
  On = 'On',
}

export enum BuilderFormTypeNames {
  LogMaintenance = 'Log Maintenance',
  Repair = 'Repair',
  Inspection = 'Inspection',
  FinalCheck = 'Final Check',
  TaskRelatedForm = 'Task related form',
  // custom values
  ToolInspection = 'Tool inspection',
  Instructions = 'Instructions',
}

export enum SmartMeterActionNames {
  NEW = 'new',
  REPLACE = 'replace',
  REMOVE = 'remove',
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const plumberAPI = axios.create({
  baseURL: `${process.env.REACT_APP_PLUMBER_API_URL}/`,
});

export const AssetLifeAPI = axios.create({
  baseURL: `${process.env.REACT_APP_ASSETLIFE_API_URL}/`,
});

export enum DataTableFilterKeys {
  YEAR = 'year',
  TYPE = 'type',
  APP_ACTION = 'appAction',
  STATUS = 'status',
  USER_IDS = 'userIds',
  EMPLOYEES_IDS = 'electricianIds',
  ASSET_CODE = 'assetCode',
  ASSET_CATEGORY_CODE = 'assetCategoryCode',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  TASK_ID_OR_CASE_NUMBER = 'taskIdOrCaseNumber',
}

export enum MaintenanceMissingActions {
  START = 'Start',
  END = 'End',
  DISTRIBUTE = 'Distribute',
}

export enum PriceTypes {
  FIXED = 'fixed',
  YEARLY = 'yearly',
}

export enum PermissionsTypes {
  // GENERAL
  isOverviewEnabled = 'isOverviewEnabled',
  isBudgetingEnabled = 'isBudgetingEnabled',
  isMapEnabled = 'isMapEnabled',
  isCustomersEnabled = 'isCustomersEnabled',

  // Forecast & Investment
  isNetworkLoadingEnabled = 'isNetworkLoadingEnabled',
  isN1Available = 'isN1Available',
  isDataQualityEnabled = 'isDataQualityEnabled',
  isSetupEnabled = 'isSetupEnabled',
  isNewLoadEnabled = 'isNewLoadEnabled',
  isInvestmentEnabled = 'isInvestmentEnabled',

  // Maintenance & planning
  isTasksEnabled = 'isTasksEnabled',
  isSmartMetersEnabled = 'isSmartMetersEnabled',
  isMaintenanceEnabled = 'isMaintenanceEnabled',
  isAssetModelsEnabled = 'isAssetModelsEnabled',
  isFormsEnabled = 'isFormsEnabled',
  isHistoryEnabled = 'isHistoryEnabled',
  isDataManualsEnabled = 'isDataManualsEnabled',

  // REINVESTMENT
  isAssetsEnabled = 'isAssetsEnabled',
  isReliabilityEnabled = 'isReliabilityEnabled',
  isInterventionsEnabled = 'isInterventionsEnabled',
  isFlexibilityEnabled = 'isFlexibilityEnabled',

  isFeatureToggleAvailable = 'isFeatureToggleAvailable',

  isTaskTypeImmediate = 'isTaskTypeImmediate',
  isTaskTypeExpected = 'isTaskTypeExpected',
  isTaskTypeAutogenerated = 'isTaskTypeAutogenerated',
  isTaskTypeCustomerComplaint = 'isTaskTypeCustomerComplaint',
  isAssignedTasksOnly = 'isAssignedTasksOnly',

  isAdminPanelReadOnly = 'isAdminPanelReadOnly',
  isMobileApplicationsReadOnly = 'isMobileApplicationsReadOnly',

  isAssetcategoryCodeBD = 'isAssetcategoryCodeBD',
  isAssetcategoryCodeKS = 'isAssetcategoryCodeKS',
  isAssetcategoryCodeLE = 'isAssetcategoryCodeLE',
  isAssetcategoryCodeLV = 'isAssetcategoryCodeLV',
  isAssetcategoryCodeMV = 'isAssetcategoryCodeMV',
  isAssetcategoryCodeRT = 'isAssetcategoryCodeRT',
  isAssetcategoryCodeSM = 'isAssetcategoryCodeSM',
  isAssetcategoryCodeTH = 'isAssetcategoryCodeTH',
  isAssetcategoryCodeTS = 'isAssetcategoryCodeTS',
  isAssetcategoryCodeGL = 'isAssetcategoryCodeGL',
  isAssetcategoryCodeSS = 'isAssetcategoryCodeSS',
  isAssetcategoryCodeTR = 'isAssetcategoryCodeTR',
}

export enum PermissionsTypesGroups {
  Views = 'Views',
  TaskType = 'TaskType',
  AssetCategories = 'AssetCategories',
  ReadWrite = 'ReadWrite',
}

export const PermissionsTypesLocalesMap: {
  [key in PermissionsTypes]: { labelKey: string; sort: number; group: PermissionsTypesGroups };
} = {
  // GENERAL
  [PermissionsTypes.isOverviewEnabled]: {
    labelKey: 'Enable overview menu',
    sort: 1,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isBudgetingEnabled]: {
    labelKey: 'Enable budgeting menu',
    sort: 2,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isMapEnabled]: { labelKey: 'Enable map menu', sort: 3, group: PermissionsTypesGroups.Views },
  [PermissionsTypes.isCustomersEnabled]: {
    labelKey: 'Enable customers menu',
    sort: 4,
    group: PermissionsTypesGroups.Views,
  },

  // Forecast & Investment
  [PermissionsTypes.isNetworkLoadingEnabled]: {
    labelKey: 'Enable network loading menu',
    sort: 5,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isN1Available]: {
    labelKey: 'Enable N-1 menu',
    sort: 6,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isDataQualityEnabled]: {
    labelKey: 'Enable data quality menu',
    sort: 7,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isSetupEnabled]: { labelKey: 'Enable setup menu', sort: 8, group: PermissionsTypesGroups.Views },
  [PermissionsTypes.isNewLoadEnabled]: {
    labelKey: 'Enable new load menu',
    sort: 9,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isInvestmentEnabled]: {
    labelKey: 'Enable investment menu',
    sort: 10,
    group: PermissionsTypesGroups.Views,
  },

  // Maintenance & planning
  [PermissionsTypes.isTasksEnabled]: { labelKey: 'Enable tasks menu', sort: 11, group: PermissionsTypesGroups.Views },
  [PermissionsTypes.isSmartMetersEnabled]: {
    labelKey: 'Enable meters menu',
    sort: 12,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isMaintenanceEnabled]: {
    labelKey: 'Enable maintenance menu',
    sort: 13,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isAssetModelsEnabled]: {
    labelKey: 'Enable asset models menu',
    sort: 14,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isFormsEnabled]: { labelKey: 'Enable forms menu', sort: 15, group: PermissionsTypesGroups.Views },
  [PermissionsTypes.isHistoryEnabled]: {
    labelKey: 'Enable history menu',
    sort: 16,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isDataManualsEnabled]: {
    labelKey: 'Enable data manuals menu',
    sort: 17,
    group: PermissionsTypesGroups.Views,
  },

  // REINVESTMENT
  [PermissionsTypes.isAssetsEnabled]: { labelKey: 'Enable assets menu', sort: 18, group: PermissionsTypesGroups.Views },
  [PermissionsTypes.isReliabilityEnabled]: {
    labelKey: 'Enable reliability menu',
    sort: 19,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isInterventionsEnabled]: {
    labelKey: 'Enable interventions menu',
    sort: 20,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isFlexibilityEnabled]: {
    labelKey: 'Enable flexibility menu',
    sort: 21,
    group: PermissionsTypesGroups.Views,
  },
  [PermissionsTypes.isFeatureToggleAvailable]: {
    labelKey: 'Enable feature toggles menu',
    sort: 22,
    group: PermissionsTypesGroups.Views,
  },

  // TASK TYPES
  [PermissionsTypes.isTaskTypeImmediate]: {
    labelKey: 'Enable immediate tasks',
    sort: 23,
    group: PermissionsTypesGroups.TaskType,
  },
  [PermissionsTypes.isTaskTypeExpected]: {
    labelKey: 'Enable expected tasks',
    sort: 24,
    group: PermissionsTypesGroups.TaskType,
  },
  [PermissionsTypes.isTaskTypeAutogenerated]: {
    labelKey: 'Enable autogenerated tasks',
    sort: 25,
    group: PermissionsTypesGroups.TaskType,
  },
  [PermissionsTypes.isTaskTypeCustomerComplaint]: {
    labelKey: 'Enable customer complaint tasks',
    sort: 26,
    group: PermissionsTypesGroups.TaskType,
  },
  [PermissionsTypes.isAssignedTasksOnly]: {
    labelKey: 'Show only assigned tasks',
    sort: 27,
    group: PermissionsTypesGroups.TaskType,
  },

  // CATEGORIES
  [PermissionsTypes.isAssetcategoryCodeBD]: {
    labelKey: 'Enable buildings category',
    sort: 28,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeKS]: {
    labelKey: 'Enable cabinets category',
    sort: 29,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeLE]: {
    labelKey: 'Enable cables category',
    sort: 30,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeLV]: {
    labelKey: 'Enable LV board category',
    sort: 31,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeMV]: {
    labelKey: 'Enable MV board category',
    sort: 32,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeRT]: {
    labelKey: 'Enable RTU category',
    sort: 33,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeSM]: {
    labelKey: 'Enable smart meter category',
    sort: 34,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeTH]: {
    labelKey: 'Enable stations category',
    sort: 35,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeTS]: {
    labelKey: 'Enable street light cabinets category',
    sort: 36,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeGL]: {
    labelKey: 'Enable street lights category',
    sort: 37,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeSS]: {
    labelKey: 'Enable traffic light control box category',
    sort: 38,
    group: PermissionsTypesGroups.AssetCategories,
  },
  [PermissionsTypes.isAssetcategoryCodeTR]: {
    labelKey: 'Enable transformers category',
    sort: 39,
    group: PermissionsTypesGroups.AssetCategories,
  },

  // READ / WRITE
  [PermissionsTypes.isAdminPanelReadOnly]: {
    labelKey: 'Admin panel read only access',
    sort: 40,
    group: PermissionsTypesGroups.ReadWrite,
  },
  [PermissionsTypes.isMobileApplicationsReadOnly]: {
    labelKey: 'Mobile application read only access',
    sort: 41,
    group: PermissionsTypesGroups.ReadWrite,
  },
};

export enum SelectCustomersRightsLoadTypes {
  BOTH = 'both',
  CONSUMPTION = 'consumption',
  PRODUCTION = 'production',
}

export enum SelectCustomersRightsMissingTypes {
  ALL = 'All',
  CONSUMPTION = 'Consumption',
  PRODUCTION = 'Production',
}

export enum SelectSolvedValues {
  False = 'False',
  True = 'True',
  Solved = 'Solved',
}

export enum SelectVoltageUnits {
  False = 'False',
  True = 'True',
}

export enum SelectIssuesStatusValues {
  open = 'open',
  ignored = 'ignored',
  solved = 'solved',
}

export enum ProfileModalTypes {
  Transformer = 'Transformer',
  TransformerForecast = 'TransformerForecast',
  Cable = 'Cable',
  Customer = 'Customer',
  Fuse = 'Fuse',
}

export enum TasksRegisteredHoursSourceTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export const DefaultRoute = generatePath(Routes.Map);

export enum AppErrorsTypes {
  FetchUserError = 'FETCH_USER_ERROR',
  TenantNotFound = 'TENANT_NOT_FOUND',
  NoAssignedTenants = 'NO_ASSIGNED_TENANTS',
}

export enum MapTheme {
  Standard = 'Standard',
  Responsive = 'Responsive',
  Monochrome = 'Monochrome',
}

export enum SingleLineDiagramTypes {
  Tree = 'Tree',
  GEM = 'GEM',
  Geospatial = 'Geospatial',
}

export const GriZoneNamesLocalesMap: { [key: string]: string } = {
  'Høj bebyggelse': 'High buildings',
  'Lav bebyggelse': 'Low buildings',
  Opland: 'Catchment area',
  Sommerhuse: 'Summer houses',
  Industri: 'Industry',
};

export enum MaintenanceAverageTimeSpentByAssetCategories {
  Transformers = 'Transformers',
  Cabinets = 'Cabinets',
}

export { Routes };
