import React from 'react';
import { useLocale } from 'hooks';
import AssetDetails from 'components/Map/common/AssetDetails';
import { Alert } from 'components/_common';
import ScrollableTabs, { StyledTabScreen } from '../ScrollableTabs';
import FormAssetDetails from './FormAssetDetails';
import FormAssetSubComponents from './FormAssetSubComponents';
import TabComments from './TabComments';
import TabTaskCompletions from './TabTaskCompletions';
import TabDataManuals from './TabDataManuals';
import TabPhoto from './TabPhoto';
import TabHistory from './TabHistory';
import TabAnswers from './TabAnswers';
import TabCustomers from './TabCustomers';
import CustomerAssetTypeSupportAlert from './CustomerAssetTypeSupportAlert';
import { isNKTenant } from 'utils';
import { StorageKeys } from 'constants/index';
import { TopTabsIndexesMap } from './constants';

interface Props {
  uuid: string;
  assetMeta: Asset.AssetMeta;
  assetCategoryCode?: Type.AssetCategories;
  isCustomerAssetType: boolean;
}

const TopPanelTabs: React.FC<Props> = ({ assetMeta, uuid, assetCategoryCode, isCustomerAssetType }) => {
  const { getIntl } = useLocale();

  const renderSupportAlert = () => (
    <Alert className="mb-0 text-center" variant="warning">
      {getIntl("This asset doesn't support live data")}
    </Alert>
  );

  return (
    <ScrollableTabs
      tabsLabelKeys={[isNKTenant ? 'Live data' : '', 'Asset details']
        .filter(Boolean)
        .concat(isNKTenant ? ['Sub-components', 'Photo', 'History', 'Answers', 'Task completions', 'Data manuals'] : [])
        .concat(['Customers', 'Comments'])}
      storageKey={StorageKeys.MAP_PANEL_TOP_TAB_ACTIVE_INDEX}
      className="h-100 overflow-hidden"
    >
      {activeTab => (
        <>
          {isNKTenant && (
            <StyledTabScreen key="Live data" activeTab={activeTab} index={TopTabsIndexesMap.LiveData}>
              {Boolean(assetMeta.liveData) ? <FormAssetDetails assetMeta={assetMeta.liveData} /> : renderSupportAlert()}
            </StyledTabScreen>
          )}
          <StyledTabScreen key="Asset details" activeTab={activeTab} index={TopTabsIndexesMap.AssetDetails}>
            <AssetDetails assetMeta={assetMeta} />
          </StyledTabScreen>
          {isNKTenant && (
            <StyledTabScreen key="Sub-components" activeTab={activeTab} index={TopTabsIndexesMap.SubComponents}>
              {Boolean(assetMeta.liveData) ? (
                <FormAssetSubComponents assetMeta={assetMeta.liveData} assetCategoryCode={assetCategoryCode} />
              ) : (
                renderSupportAlert()
              )}
            </StyledTabScreen>
          )}
          {isNKTenant && (
            <StyledTabScreen key="Photo" activeTab={activeTab} index={TopTabsIndexesMap.Photo}>
              {Boolean(assetMeta.liveData) ? (
                <TabPhoto assetMeta={assetMeta.liveData} uuid={uuid} />
              ) : (
                renderSupportAlert()
              )}
            </StyledTabScreen>
          )}
          {isNKTenant && (
            <StyledTabScreen key="History" activeTab={activeTab} index={TopTabsIndexesMap.History}>
              <TabHistory uuid={uuid} />
            </StyledTabScreen>
          )}
          {isNKTenant && (
            <StyledTabScreen key="Answers" activeTab={activeTab} index={TopTabsIndexesMap.Answers}>
              <TabAnswers uuid={uuid} />
            </StyledTabScreen>
          )}
          {isNKTenant && (
            <StyledTabScreen key="Task completions" activeTab={activeTab} index={TopTabsIndexesMap.TaskCompletions}>
              <TabTaskCompletions uuid={uuid} />
            </StyledTabScreen>
          )}
          {isNKTenant && (
            <StyledTabScreen key="Data manuals" activeTab={activeTab} index={TopTabsIndexesMap.DataManuals}>
              <TabDataManuals uuid={uuid} />
            </StyledTabScreen>
          )}
          <StyledTabScreen key="Customers" activeTab={activeTab} index={TopTabsIndexesMap.Customers}>
            {!isCustomerAssetType ? <TabCustomers uuid={uuid} /> : <CustomerAssetTypeSupportAlert />}
          </StyledTabScreen>
          <StyledTabScreen key="Comments" activeTab={activeTab} index={TopTabsIndexesMap.Comments}>
            <TabComments uuid={uuid} />
          </StyledTabScreen>
        </>
      )}
    </ScrollableTabs>
  );
};

export default TopPanelTabs;
