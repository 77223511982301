import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  extRepeatedTasksSelectorFactory,
  tasksDepartmentsFetchedSelector,
  repeatedTasksFetchedSelector,
  repeatedTasksLoadingSelector,
  expandedRepeatedTasksSelectorFactory,
} from 'modules/tasks/selectors';
import { fetchRepeatedTasksAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { sortAlphabeticallyFactory } from 'utils';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string | null;
  departmentNumber: number | null;
  useFilteredTasks?: boolean;
  excludedAppActions?: Type.TaskAppActions[];
}

const SelectExtRepeatedTaskNumber: React.FC<Props> = ({
  labelKey = 'Task number',
  value,
  departmentNumber,
  isDisabled,
  useFilteredTasks = false,
  excludedAppActions = [],
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [inputValue, setInputValue] = useState<string>('');

  const tasksDepartmentsFetched = useSelector(tasksDepartmentsFetchedSelector);
  const tasksDepartmentsLoading = tasksDepartmentsFetched === null;
  const extRepeatedTasks = useSelector(extRepeatedTasksSelectorFactory(departmentNumber));

  const repeatedTasksLoading = useSelector(repeatedTasksLoadingSelector);
  const repeatedTasksFetched = useSelector(repeatedTasksFetchedSelector);
  const expandedRepeatedTasks = useSelector(expandedRepeatedTasksSelectorFactory(departmentNumber));

  useEffect(() => {
    if (!repeatedTasksFetched && !repeatedTasksLoading) dispatch(fetchRepeatedTasksAction());
  }, [repeatedTasksFetched, repeatedTasksLoading, dispatch]);

  const handleInputChange = useCallback((newValue: string) => setInputValue(newValue), []);

  const getLabel = useCallback(
    (taskNumber: string, description: string) => `${taskNumber}${description ? `, ${description}` : ''}`,
    []
  );

  const { options, selectValue } = useMemo(() => {
    const { options, selectValue } = (useFilteredTasks ? expandedRepeatedTasks : extRepeatedTasks).reduce(
      (acc: any, task: Tasks.RepeatedTask | Tasks.ExtRepeatedTask) => {
        const appAction = (task as Tasks.RepeatedTask).actionInApp || null;
        if (useFilteredTasks && excludedAppActions.includes(appAction)) return acc;

        const label = getLabel(task.taskNumber, task.description);
        if (value && task.taskNumber === value) {
          acc.selectValue = { value: task.taskNumber, label };
        }
        if (label.toLowerCase().includes(inputValue.toLowerCase())) {
          acc.options.push({
            value: task.taskNumber,
            label,
            description: task.description,
            appAction,
          });
        }
        return acc;
      },
      { options: [], selectValue: null }
    );

    const sortedOptions = options.sort(sortAlphabeticallyFactory<Type.SelectOption>('label'));
    return { options: sortedOptions, selectValue };
  }, [value, useFilteredTasks, excludedAppActions, expandedRepeatedTasks, extRepeatedTasks, inputValue, getLabel]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      isDisabled={!departmentNumber || isDisabled || !tasksDepartmentsFetched || !repeatedTasksFetched}
      isLoading={tasksDepartmentsLoading || repeatedTasksLoading}
      options={options}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isClearable
    />
  );
};

export default SelectExtRepeatedTaskNumber;
