import styled from 'styled-components';
import React, { useMemo } from 'react';
import { Alert as BootstrapAlert, AlertProps } from 'react-bootstrap';

const Alert: React.FC<AlertProps> = ({ children, ...props }) => {
  const style = useMemo(() => {
    switch (props.variant) {
      case 'warning':
        return { color: '#856404', backgroundColor: '#fff3cd', borderColor: '#ffeeba' };
      case 'danger':
        return { color: '#721c24', backgroundColor: '#f8d7da', borderColor: '#f5c6cb' };
      default:
        return {};
    }
  }, [props.variant]);

  return (
    <StyledBootstrapAlert {...props} style={style}>
      {children}
    </StyledBootstrapAlert>
  );
};

const StyledBootstrapAlert: any = styled(BootstrapAlert)`
  a {
    color: #007bff;
    text-decoration: unset;
  }
`;

export default Alert;
