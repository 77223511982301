import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import { createMapStateSelectorFactory, settingsSelector } from 'modules/map/selectors';
import RangeSlider from 'components/Map/common/RangeSlider';
import Loading from 'components/Map/common/Loading';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import MultiSwitch from 'components/Map/common/MultiSwitch';
import ScaleContainer from 'components/Map/common/ScaleContainer';
import ScaleGroup from 'components/Map/common/ScaleGroup';
import { Scale } from 'components/Map/common';
import { mapColorGradient } from 'utils/map';
import SectionHint from './SectionHint';

const ForecastInvestmentYearlyConsumption: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const settings = useSelector(settingsSelector);
  const scenario = useSelector(createMapStateSelectorFactory('consumptionScenario'));
  const showPercents = useSelector(createMapStateSelectorFactory('showConsumptionPercents'));
  const scaleItems = useMemo<Map.MultiSwitchItem[]>(
    () => [
      { id: 0, value: 0, label: `${getIntl('Absolute')} • GWh` },
      { id: 1, value: 1, label: `${getIntl('Relative')} • %` },
    ],
    [getIntl]
  );
  const [selectedItem, setSelectedItem] = useState(scaleItems.find(i => i.value === Number(showPercents))!);

  const disabled =
    settings.isConsumptionThemeDisabled ||
    !(settings.consumptionMaxValues?.maxPct && settings.consumptionMaxValues?.maxVal);

  useEffect(() => {
    dispatch(mapStateAction({ showConsumptionPercents: Boolean(selectedItem.value) }));
  }, [dispatch, selectedItem]);

  const onScaleModeChange = useCallback((item: Map.MultiSwitchItem) => {
    setSelectedItem(item);
  }, []);

  const onScenarioYearChange = (year: number) => {
    const id = scenario?.years0.includes(year) ? 0 : scenario?.selectedId;
    const key = `${id}_${year}`;
    const s = { ...scenario, id, year, key } as Map.Scenario;
    dispatch(mapStateAction({ consumptionScenario: s }));
  };

  const ThemeScale = useMemo(() => {
    const max = settings.consumptionMaxValues;
    const scaleRanges = showPercents
      ? [[0, max?.maxPct ?? 1]]
      : [
          [0, 250],
          [0, 50],
          [0, 10],
        ];
    const scales = scaleRanges.map(([min, max]) => {
      const colors = mapColorGradient(selectedMapTheme, 'default', min, max);
      const values = colors.filter(c => typeof c === 'number') as number[];
      return <Scale theme={selectedMapTheme} values={values} />;
    });
    return <ScaleGroup scales={scales} />;
  }, [selectedMapTheme, showPercents]); // eslint-disable-line

  return (
    <CollapseGroup
      id="ec"
      titleKey={settings.themeTitles?.ec!}
      stateLoc="theme"
      disabled={disabled}
      dataMarker="panel_yearly_consumption"
    >
      {!disabled ? (
        <>
          <ScaleContainer
            title={
              <div>
                {getIntl(
                  (!scenario?.years0.includes(scenario.year) ? 'Forecasted ' : 'Historical ') +
                    (showPercents ? 'consumption' : 'consumption')
                )}
                {showPercents ? ' • %' : ' • GWh'}
              </div>
            }
            scale={ThemeScale}
            controls={
              <MultiSwitch name="consumption" items={scaleItems} selected={selectedItem} onChange={onScaleModeChange} />
            }
          />
          {scenario ? (
            <div className="d-flex flex-column">
              {scenario.years.length > 1 ? (
                <>
                  <RangeSlider
                    min={0}
                    max={scenario.years.length - 1}
                    value={scenario.years.findIndex(y => y === scenario.year)}
                    onChange={value => onScenarioYearChange(scenario.years[+value])}
                    tooltipLabel={i => `${scenario.years[i] ?? getIntl('Unknown')}`}
                    resetOn={scenario.selectedId}
                    className="mt-1"
                  />
                  <SectionHint>
                    *{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: getIntl('99.9<sup>th</sup> percentile results are displayed'),
                      }}
                    />
                  </SectionHint>
                </>
              ) : (
                scenario.years.length > 0 && (
                  <div className="my-2 map-text text-center">
                    {getIntl('Data on the map is only available for the year')} {scenario.years[0]}
                  </div>
                )
              )}
            </div>
          ) : (
            <Loading style={{ padding: '50px 0' }} />
          )}
        </>
      ) : (
        <div className="summary-no-data">{getIntl('No data available')}</div>
      )}
    </CollapseGroup>
  );
};

export default ForecastInvestmentYearlyConsumption;
