import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { mapStateAction } from 'modules/map';
import { createMapStateSelectorFactory, settingsSelector } from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import RangeSlider from 'components/Map/common/RangeSlider';
import Section from 'components/Map/common/Section';
import ScaleContainer from 'components/Map/common/ScaleContainer';

const ReinvestmentYearlyReplacements: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const replacementsFilters = useSelector(createMapStateSelectorFactory('replacementsFilters'));
  const scenario = useSelector(createMapStateSelectorFactory('replacementsScenario'));

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string | number) => {
      if (!replacementsFilters) return;
      const checked = e.target.checked;
      const prev = replacementsFilters.list;
      const newValue = checked ? [...prev, value] : prev.filter(i => i !== value);
      dispatch(mapStateAction({ replacementsFilters: { ...replacementsFilters, list: newValue } }));
    },
    [dispatch, replacementsFilters]
  );

  const onScenarioYearChange = useCallback(
    (min: number, max: number) => {
      if (!scenario) return;
      const yearRange = [scenario.years[min], scenario.years[max]];
      const id = scenario?.years0.includes(yearRange[0]) ? 0 : scenario?.selectedId;
      const key = `${id}_${yearRange[0]}`;
      const s = { ...scenario, id, year: yearRange[0], yearRange, key } as Map.Scenario;
      dispatch(mapStateAction({ replacementsScenario: s }));
    },
    [dispatch, scenario]
  );

  return (
    <CollapseGroup
      id="yr"
      dataMarker="panel_yearly_replacements"
      titleKey={settings.themeTitles?.yr!}
      disabled={settings.isReplacementsThemeDisabled}
      stateLoc="theme"
    >
      {scenario &&
        (scenario.years.length > 1 ? (
          <ScaleContainer
            title={getIntl('Suggested replacement year')}
            scale={
              <RangeSlider
                min={0}
                max={scenario.years.length - 1}
                value={scenario.years.findIndex(y => y === scenario.yearRange[0])}
                valueEnd={scenario.years.findIndex(y => y === scenario.yearRange[1])}
                onChange={onScenarioYearChange}
                tooltipLabel={i => String(scenario.years[i]) ?? getIntl('Unknown')}
                resetOn={scenario.selectedId}
                range
              />
            }
          />
        ) : (
          scenario.years.length > 0 && (
            <div className="my-2 map-text text-center">
              {getIntl('Data on the map is only available for the year')} {scenario.years[0]}
            </div>
          )
        ))}
      {replacementsFilters && (
        <Section title={getIntl('Replacement reason')} className="mt-1">
          {replacementsFilters?.initList.map(i => (
            <ControlBox
              key={i.id}
              type="checkbox"
              id={String(i.id)}
              name={i.name}
              checked={replacementsFilters.list.includes(i.id)}
              labelKey={i.name}
              onChange={e => onFilterChange(e, i.id)}
              disabled={replacementsFilters.initList.length === 1}
            />
          ))}
        </Section>
      )}
    </CollapseGroup>
  );
};

export default ReinvestmentYearlyReplacements;
