import moment from 'moment';
import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { DateRangePicker } from 'components/_common';
import { DataTableFilterKeys } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  values: Layouts.Filters;
  setValues: (values: any, shouldValidate?: boolean | undefined) => Promise<FormikErrors<any>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const DateRangeFilter: React.FC<Props> = ({ inDataTableHeaderFilters = false, values, setValues, submitForm }) => {
  const { getIntl } = useLocale();

  const handleDateRangePickerApply = useCallback(
    async (event: React.SyntheticEvent, picker: { startDate: Type.Moment; endDate: Type.Moment }) => {
      const nextValues = { ...values, startTime: picker.startDate || null, endTime: picker.endDate };
      await setValues(nextValues);
      submitForm();
    },
    [setValues, values, submitForm]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.START_TIME) || !values.hasOwnProperty(DataTableFilterKeys.END_TIME)) {
    return null;
  }

  const today = moment();
  const DateRangePickerElement = (
    <DateRangePicker
      label=""
      initialSettings={{
        maxDate: today,
        maxSpan: { months: 6 },
        ranges: {
          [getIntl('Today')]: [today, today],
          [getIntl('Yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          [getIntl('Last 7 Days')]: [moment().subtract(6, 'days'), today],
          [getIntl('Last 30 Days')]: [moment().subtract(29, 'days'), today],
          [getIntl('This Month')]: [moment().startOf('month'), moment().endOf('month')],
          [getIntl('Last Month')]: [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
        },
      }}
      startDate={values.startTime as Type.Moment}
      endDate={values.endTime as Type.Moment}
      onApply={handleDateRangePickerApply}
    />
  );

  return inDataTableHeaderFilters ? (
    <Col sm={3}>{DateRangePickerElement}</Col>
  ) : (
    <Form.Group>{DateRangePickerElement}</Form.Group>
  );
};

export default DateRangeFilter;
