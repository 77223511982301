import styled from 'styled-components';
import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { FormInput, FormReactSelect } from 'components/_common';
import { PaginationType } from 'constants/index';
import ContainerTopControls from './ContainerTopControls';

interface Props {
  loading: boolean;
  paginationType: Type.PaginationType;
  children: React.ReactNode;
}

const TabFilters: React.FC<Props> = ({ loading, paginationType, children }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { query, column, sort }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));
  const [searchInput, setSearchInput] = useState<string>(query);

  const options = useMemo(
    () => [
      ...(paginationType === PaginationType.UNIFIED_ASSET_VIEW_TASKS
        ? [
            { value: 'name|ASC', label: 'Name (A-Z)' },
            { value: 'name|DESC', label: 'Name (Z-A)' },
          ]
        : []),
      ...(paginationType === PaginationType.ANSWERS
        ? [
            { value: 'formName|ASC', label: 'Form name (A-Z)' },
            { value: 'formName|DESC', label: 'Form name (Z-A)' },
          ]
        : []),
      ...([PaginationType.ANSWERS, PaginationType.HISTORY].includes(paginationType)
        ? [
            { value: 'createdAt|DESC', label: 'Date (newest first)' },
            { value: 'createdAt|ASC', label: 'Date (oldest first)' },
          ]
        : []),
    ],
    [paginationType]
  );

  const value = useMemo(
    () => options.find(({ value }) => value === `${column}|${sort}`) || null,
    [options, column, sort]
  );

  const handleSelectChange = useCallback(
    (option: { value: string }): Promise<void> => {
      const [column, sort] = option.value.split('|');
      return dispatch(setPaginationAction({ type: paginationType, modifier: { column, sort, offset: 0 } }));
    },
    [dispatch, paginationType]
  );

  const debounced = useDebouncedCallback(
    () => dispatch(setPaginationAction({ type: paginationType, modifier: { query: searchInput, offset: 0 } })),
    1000
  );

  const handleSearchInputChange = useCallback(
    (event: React.SyntheticEvent) => {
      const value: string = (event.target as HTMLInputElement).value;
      setSearchInput(value);
      debounced();
    },
    [debounced]
  );

  return (
    <>
      <ContainerTopControls>
        <StyledFormReactSelect
          isLoading={loading}
          labelKey=""
          value={value}
          options={options}
          onChange={handleSelectChange}
          isSearchable={false}
          blurInputOnSelect
          variant="small"
        />
        <StyledFormInput
          name="search"
          value={searchInput || ''}
          onChange={handleSearchInputChange}
          placeholderKey="Type to search"
          size="sm"
        />
      </ContainerTopControls>
      {children}
    </>
  );
};

const StyledFormReactSelect = styled(FormReactSelect)`
  width: 200px;
`;

const StyledFormInput = styled(FormInput)`
  width: 200px;
  border-radius: 8px;
`;

export default TabFilters;
