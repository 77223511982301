import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayTrigger, Popover, ListGroup, Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { historyItemsSelector } from 'modules/history/selectors';
import { fetchHistoryAction } from 'modules/history';
import { Alert, Button, Spinner } from 'components/_common';
import { Routes, PaginationType } from 'constants/index';
import Card from './Card';
import TabFilters from './TabFilters';
import { IconAnswer, IconReplace } from '@utiligize/shared/resources';

interface Props {
  uuid: string;
}

const TabHistory: React.FC<Props> = ({ uuid }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const items = useSelector(historyItemsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const simulationVersionId = useSelector(simulationSelectedOptionVersionIdSelector);

  const paginationType = PaginationType.HISTORY;
  const { query, column, sort }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));

  useEffect(() => {
    if (!portfolioId || !simulationVersionId) return;
    setLoading(true);
    dispatch(
      fetchHistoryAction({ uuid, portfolioId, simulationVersionId, skipPagination: true, queryInput: query })
    ).finally(() => setLoading(false));
  }, [dispatch, uuid, portfolioId, simulationVersionId, query, column, sort]);

  const renderContent = () => {
    if (loading) return <Spinner isInFullHeightContainer />;
    if (!items.length) {
      return (
        <Alert className="mb-0 text-center">
          {getIntl(query ? 'There is no data for current search query' : 'There is no data for selected asset')}
        </Alert>
      );
    }
    return (
      <>
        {items.map((item: History.Item, index: number) => (
          <Card key={item.id} marginBottom={items.length - 1 !== index}>
            <Row className="mb-1">
              <Col>
                <label>{getIntl('Form name')}:</label>
                <span>{item.formName || (item.action && getIntl(item.action)) || '-'}</span>
              </Col>
              <Col>
                <label>{getIntl('Date of answer')}:</label>
                <span>{dateFormat(item.createdAt)}</span>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col>
                <label>{getIntl('Type')}:</label>
                <span>{getIntl(item.type)}</span>
              </Col>
              <Col>
                <label>{getIntl('Asset category')}:</label>
                <span>{getIntl(item.assetCategoryName)}</span>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col>
                <label>{getIntl('Username')}:</label>
                <span>{item.userName}</span>
              </Col>
              <Col>
                <label>{getIntl('Tasks')}:</label>
                <span>
                  {item.completedTaskIds?.map((id: number, index: number) => (
                    <React.Fragment key={id}>
                      <Link to={generatePath(Routes.Tasks, { id })}>{id}</Link>
                      {item.completedTaskIds.length - 1 !== index ? ', ' : ''}
                    </React.Fragment>
                  )) || '-'}
                </span>
              </Col>
            </Row>
            <StyledBottomControls>
              {item.replaceHistory && (
                <OverlayTrigger
                  placement="left"
                  trigger={['hover', 'hover']}
                  rootClose
                  overlay={
                    <Popover id={String(item.id)} style={{ width: '300px' }}>
                      <Popover.Title as="h4">{getIntl('Replace history')}</Popover.Title>
                      <Popover.Content>
                        <ListGroup variant="flush">
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Installation date')}: <span>{dateFormat(item.replaceHistory.idriftsatdato)}</span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Type')}: <span>{item.replaceHistory.type}</span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Manufacturer')}: <span>{item.replaceHistory.fabrikat}</span>
                          </ListGroup.Item>
                          <ListGroup.Item className="d-flex justify-content-between align-items-center py-2 px-0">
                            {getIntl('Serial number')}: <span>{item.replaceHistory.fabrikat_nummer || '-'}</span>
                          </ListGroup.Item>
                        </ListGroup>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button icon={<IconReplace />} data-id={item.id} size="small" variant="primary" />
                </OverlayTrigger>
              )}
              {item.answerId && (
                <Button
                  marginLeft={Boolean(item.replaceHistory)}
                  tooltipKey="Answer details"
                  icon={<IconAnswer />}
                  linkProps={{
                    to: {
                      pathname: generatePath(Routes.HistoryAnswers, { id: item.answerId! }),
                      state: { prevRoute: window.location.pathname },
                    },
                  }}
                  size="small"
                  variant="primary"
                />
              )}
            </StyledBottomControls>
          </Card>
        ))}
      </>
    );
  };

  return (
    <TabFilters paginationType={paginationType} loading={loading}>
      {renderContent()}
    </TabFilters>
  );
};

const StyledBottomControls = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export default TabHistory;
