import React, { useMemo } from 'react';
import { FormReactSelect, Button } from 'components/_common';
import { AssetCategories } from 'constants/index';
import Card from '../Card';

interface Props {
  index: number;
  marginBottom: boolean;
  categoryCode: Type.AssetCategories | null;
  models: Asset.AssetModel[];
  manufacturerOptions: { value: number; label: string }[];
  assetModels: AssetModels.Item[] | null;
  handleManufacturerChange: (value: { value: number; label: string }, actionMeta: any) => void;
  handleAssetModelChange: (value: { value: number; label: string }, actionMeta: any) => void;
  handleRemoveSubcomponentButtonClick: (event: React.SyntheticEvent) => void;
}

const SubComponentItem: React.FC<Props> = ({
  index,
  marginBottom,
  categoryCode,
  models,
  manufacturerOptions,
  assetModels,
  handleManufacturerChange,
  handleAssetModelChange,
  handleRemoveSubcomponentButtonClick,
}) => {
  const {
    subcomponentId,
    subComponentTypeId,
    subComponentTypeName,
    manufacturerId,
    manufacturerName,
    modelId,
    modelName,
  } = models[index];
  const formikModels = models.filter(i => i.subcomponentId !== subcomponentId);

  const assetModelsOptions = useMemo(() => {
    if (!assetModels) return [];
    return assetModels.reduce((acc: any, item: AssetModels.Item) => {
      if (item.manufacturerId !== manufacturerId) return acc;
      // Several identical sub-components can't be created
      if (formikModels.some(i => i.subComponentTypeId === subComponentTypeId && i.modelId === item.modelId)) {
        return acc;
      }
      acc.push({ value: item.modelId, label: item.modelName });
      return acc;
    }, []);
  }, [manufacturerId, assetModels, formikModels, subComponentTypeId]);

  return (
    <Card marginBottom={marginBottom}>
      <FormReactSelect
        labelKey="Sub-component type"
        name="subcomponentId"
        value={{ value: subcomponentId as number, label: subComponentTypeName }}
        options={[{ value: subcomponentId as number, label: subComponentTypeName }]}
        onChange={() => null}
        isDisabled
        labelClassName="font-weight-normal"
      />
      <FormReactSelect
        isClearable={Boolean(manufacturerId && manufacturerName)}
        labelKey="Manufacturer"
        name={`${index}`}
        options={manufacturerOptions}
        value={{ value: (manufacturerId || '') as number, label: manufacturerName }}
        onChange={handleManufacturerChange as any}
        labelClassName="font-weight-normal"
      />
      <FormReactSelect
        isClearable={Boolean(modelId && modelName)}
        labelKey="Model"
        name={`${index}`}
        options={assetModelsOptions}
        value={{ value: (modelId || '') as number, label: modelName }}
        onChange={handleAssetModelChange as any}
        labelClassName="font-weight-normal"
      />
      {categoryCode === AssetCategories.BD && (
        <Button
          data-id={subcomponentId as number}
          variant="primary-outline"
          onClick={handleRemoveSubcomponentButtonClick}
          labelKey="Delete"
        />
      )}
    </Card>
  );
};

export default SubComponentItem;
