import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchTasksFeatureCollection } from 'modules/map';
import { enabledLayersListSelector } from 'modules/map/selectors';
import { taskDateRangeSelector, tasksFilterExpressionsSelector } from './selectors';
import TasksMapPopup, { Property } from './TasksMapPopup';
import { TaskStatus, TaskStatusColorMap, Routes } from 'constants/index';

interface Props {
  map: Map.MapboxMap;
}

const TasksSetupLayers: React.FC<Props> = ({ map }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { startYear, endYear } = useSelector(taskDateRangeSelector);
  const tasksFilterExpressions = useSelector(tasksFilterExpressionsSelector);
  const enabledLayersListLength = useSelector(enabledLayersListSelector)?.length;
  const tasksSourceId = 'tasks-source';
  const tasksLayerId = 'tasks-layer';

  // Note. Keep tasks layer always on top
  useEffect(() => {
    if (map.getLayer(tasksLayerId)) map.moveLayer(tasksLayerId);
  }, [enabledLayersListLength, map]);

  const openUnifiedAssetPanel = useCallback(
    (uuid: string) => {
      dispatch(push(generatePath(Routes.Map, { uuid }), { keepMapState: true }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!map.getLayer(tasksLayerId) || !tasksFilterExpressions) return;
    map.setFilter(tasksLayerId, tasksFilterExpressions);
  }, [tasksFilterExpressions, map]);

  const renderPopup = useCallback(
    (features: mapboxgl.MapboxGeoJSONFeature[]) => (
      <TasksMapPopup
        properties={features.map(feature => ({ ...feature.properties, featureId: Number(feature.id) })) as Property[]}
        getIntl={getIntl}
        openUnifiedAssetPanel={openUnifiedAssetPanel}
      />
    ),
    [openUnifiedAssetPanel, getIntl]
  );

  const initHandlers = useCallback(() => {
    const hoverPopup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false });
    const hoverContainer = document.createElement('div');
    const hoverRoot = createRoot(hoverContainer);
    let hoverIds: number[] = [];

    const clickPopup = new mapboxgl.Popup({ closeButton: false, closeOnClick: true, className: 'hoverable' });
    const clickContainer = document.createElement('div');
    const clickRoot = createRoot(clickContainer);
    let clickIds: number[] = [];

    const mouseenter = (e: any) => {
      const features = map.queryRenderedFeatures(e.point, { layers: [tasksLayerId] });
      if (!features.length || features[0].geometry.type !== 'Point') return;

      const nextHoverIds = features.map((item: any) => item.id);
      const isHoveredEqualToClicked = clickPopup.isOpen() && clickIds.join() === nextHoverIds.join();
      if (isHoveredEqualToClicked) return;

      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';
      hoverIds = nextHoverIds;
      hoverRoot.render(renderPopup(features));
      const coordinates = features[0].geometry.coordinates.slice() as [number, number];
      hoverPopup.setLngLat(coordinates).setDOMContent(hoverContainer).addTo(map);
    };

    const mousemove = (e: mapboxgl.MapMouseEvent) => {
      const features = map.queryRenderedFeatures(e.point, { layers: [tasksLayerId] });
      if (!features.length || features[0].geometry.type !== 'Point') return;

      const nextHoverIds = features.map((item: any) => item.id);
      const isMoveEqualToClickedOrHover =
        (clickPopup.isOpen() && clickIds.join() === nextHoverIds.join()) || hoverIds.join() === nextHoverIds.join();
      if (isMoveEqualToClickedOrHover) return;

      // Change the cursor style as a UI indicator.
      map.getCanvas().style.cursor = 'pointer';
      hoverIds = nextHoverIds;
      hoverRoot.render(renderPopup(features));
      const coordinates = features[0].geometry.coordinates.slice() as [number, number];
      hoverPopup.setLngLat(coordinates).setDOMContent(hoverContainer).addTo(map);
    };

    const mouseleave = () => {
      map.getCanvas().style.cursor = '';
      hoverIds = [];
      hoverPopup.remove();
    };

    const click = (e: mapboxgl.MapMouseEvent) => {
      const features = map.queryRenderedFeatures(e.point, { layers: [tasksLayerId] });
      if (!features.length || features[0].geometry.type !== 'Point') return;

      const nextClickIds = features.map((item: any) => item.id);
      clickIds = nextClickIds;
      hoverPopup.remove();
      clickRoot.render(renderPopup(features));
      const coordinates = features[0].geometry.coordinates.slice() as [number, number];
      clickPopup.setLngLat(coordinates).setDOMContent(clickContainer).addTo(map);
    };

    const clickPopupClose = () => {
      clickIds = [];
    };

    return { mouseenter, mousemove, mouseleave, click, clickPopup, clickPopupClose };
  }, [map, renderPopup]);

  useEffect(() => {
    const { mouseenter, mousemove, mouseleave, click, clickPopup, clickPopupClose } = initHandlers();

    dispatch(fetchTasksFeatureCollection(startYear, endYear)).then(res => {
      map.addSource(tasksSourceId, { type: 'geojson', data: res.payload });
      map.addLayer({
        id: tasksLayerId,
        type: 'circle',
        source: tasksSourceId,
        paint: {
          'circle-radius': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 4, 22, 60],
          'circle-color': [
            'match',
            ['get', 'status'],
            ...Object.values(TaskStatus)
              .map(status => [status, TaskStatusColorMap[status]])
              .flat(),
            TaskStatusColorMap['Not started'], // default color
          ],
          'circle-opacity': 0.8,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#fff',
          'circle-stroke-opacity': 0.8,
          'circle-pitch-alignment': 'map',
        },
      });

      // Once source re-created we have to set filters
      map.setFilter(tasksLayerId, tasksFilterExpressions);
      map.on('mouseenter', tasksLayerId, mouseenter);
      map.on('mousemove', tasksLayerId, mousemove);
      map.on('mouseleave', tasksLayerId, mouseleave);
      map.on('click', click);
      clickPopup.on('close', clickPopupClose);
    });

    return () => {
      // map exist but getLayer through error protection
      try {
        if (!map || !map.getLayer(tasksLayerId)) return;
        map.removeLayer(tasksLayerId);
        map.removeSource(tasksSourceId);
        clickPopup.remove();
        map.off('mouseenter', mouseenter);
        map.off('mousemove', mousemove);
        map.off('mouseleave', mouseleave);
        map.off('click', click);
        clickPopup.off('close', clickPopupClose);
      } catch (e) {}
    };
  }, [dispatch, startYear, endYear, map, getIntl, initHandlers]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default TasksSetupLayers;
