import { createSelector } from 'reselect';

// ------------------------------------
// SMART_METER_ACTIONS Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.meters;

const smartMeterActionsHashSelector = createSelector(
  rootSelector,
  (meters: Meters.Root): Meters.Root['smartMeterActionsHash'] => meters.smartMeterActionsHash
);

export const smartMeterActionsSelector = createSelector(
  smartMeterActionsHashSelector,
  (smartMeterActionsHash: Type.Hash<Meters.SmartMeterAction>): Meters.SmartMeterAction[] =>
    Object.values(smartMeterActionsHash)
);

export const smartMeterActionsCountSelector = createSelector(
  rootSelector,
  (meters: Meters.Root): Meters.Root['smartMeterActionsCount'] => meters.smartMeterActionsCount
);

// ------------------------------------
// METERS_POPULATION Selectors
// ------------------------------------
const metersPopulationHashSelector = createSelector(
  rootSelector,
  (meters: Meters.Root): Meters.Root['populationHash'] => meters.populationHash
);

export const metersPopulationSelector = createSelector(
  metersPopulationHashSelector,
  (populationHash: Meters.Root['populationHash']): Meters.Population[] => Object.values(populationHash)
);

export const metersPopulationCountSelector = createSelector(
  rootSelector,
  (meters: Meters.Root): Meters.Root['populationCount'] => meters.populationCount
);

export const metersPopulationItemSelectorFactory = (id: number | null) =>
  createSelector(
    metersPopulationHashSelector,
    (populationHash: Meters.Root['populationHash']): Meters.Population | undefined => populationHash[`_${id}_`]
  );

export const smartMeterActionSelector = createSelector(
  rootSelector,
  (meters: Meters.Root): Meters.Root['smartMeterAction'] => meters.smartMeterAction
);
