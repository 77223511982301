import moment from 'moment';
import Highcharts from 'highcharts';
import 'moment/locale/da';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nb';
import 'moment/locale/se';
import { Action, handleActions } from 'redux-actions';
import {
  setLangAction,
  setTimezoneAction,
  fetchCurrentUserInfoAction,
  fetchWikiPageAction,
  setAppErrorAction,
} from './actions';
import { getStorageItem, setStorageItem } from 'utils';
import { Languages, StorageKeys } from 'constants/index';

const selectedTimezone: string = getStorageItem(StorageKeys.SELECTED_TIME_ZONE) || moment.tz.guess();

const initialState: App.Root = {
  lang: getStorageItem<App.Languages>(StorageKeys.LANGUAGE) || Languages.DA,
  fullName: '',
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  isAdmin: false,
  isSupervisor: false,
  groupPermissions: [],
  userPermissions: {} as Permissions.Permissions,
  appCurrentUserFetched: null,
  externalTimeSystem: false,
  sidebarHelpMenuPagesHash: null,
  error: null,
  selectedTimezone,
};

const setMomentLocale = (locale: Languages) => {
  switch (locale) {
    case Languages.EN:
      return moment.locale('en-gb');
    case Languages.NO:
      return moment.locale('nb');
    default:
      return moment.locale(locale.toLowerCase());
  }
};

const setHighchartsLocale = () => {
  Highcharts.setOptions({
    lang: {
      months: moment.months(),
      weekdays: moment.weekdays(),
      shortMonths: moment.monthsShort(),
    },
  });
};

setMomentLocale(initialState.lang);
moment.tz.setDefault(selectedTimezone);
setHighchartsLocale();

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [setLangAction.toString()]: (state: App.Root, action: Shared.ReduxAction<App.Languages>): App.Root => {
    setStorageItem({ [StorageKeys.LANGUAGE]: action.payload });
    setMomentLocale(action.payload);
    setHighchartsLocale();
    return { ...state, lang: action.payload };
  },

  [setTimezoneAction.toString()]: (
    state: App.Root,
    action: Shared.ReduxAction<App.Root['selectedTimezone']>
  ): App.Root => {
    setStorageItem({ [StorageKeys.SELECTED_TIME_ZONE]: action.payload });
    moment.tz.setDefault(action.payload);
    return { ...state, selectedTimezone: action.payload };
  },

  [fetchCurrentUserInfoAction]: {
    next: (state: App.Root, action: Action<Omit<App.Root, 'lang'>>): App.Root => ({
      ...state,
      ...action.payload,
      appCurrentUserFetched: true,
    }),
    throw: (state: App.Root): App.Root => ({ ...state, appCurrentUserFetched: false }),
  },
  [fetchWikiPageAction]: {
    next: (state: App.Root, action: Action<Type.Hash<App.SidebarHelpMenuPage>>): App.Root => ({
      ...state,
      sidebarHelpMenuPagesHash: { ...state.sidebarHelpMenuPagesHash, ...action.payload },
    }),
  },
  [setAppErrorAction.toString()]: (state: App.Root, action: Action<App.AppErrorsTypes>): App.Root => ({
    ...state,
    error: action.payload,
  }),
};

export { setLangAction, setTimezoneAction, fetchCurrentUserInfoAction, fetchWikiPageAction, setAppErrorAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
