import React from 'react';
import styled from 'styled-components';

interface Props {
  title: string | React.ReactElement;
  className?: string;
  children: React.ReactNode | React.ReactNode[];
}

const Section: React.FC<Props> = ({ title, className = '', children }) => (
  <StyledSection className={className}>
    <div>{title}</div>
    {children}
  </StyledSection>
);

const StyledSection = styled.div`
  position: relative;
  background: #ffffff;
  border: 1px solid var(--map-outline-color-light);
  border-radius: 8px;
  padding: 15px;

  > div:first-child {
    position: absolute;
    left: 50%;
    top: 0;
    padding: 0 5px;
    transform: translate(-50%, -50%);
    color: var(--map-active-color);
    background: #fff;
    font-size: 10px;
    white-space: nowrap;
  }
`;

export default Section;
