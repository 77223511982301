import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactElement;
}

const GroupSection: React.FC<Props> = ({ children }) => <StyledContainer>{children}</StyledContainer>;

const StyledContainer = styled.div`
  background: #ffffff;
  border: 0.5px solid var(--map-outline-color);
  box-shadow: 0px 2px 2px #44366630;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export default GroupSection;
