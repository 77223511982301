import styled from 'styled-components';
import React from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

interface Props {
  /**
   * Function to call when the SwitchButton is changed
   */
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  onlabel?: string;
  offlabel?: string;
  style?: string;
  width?: number;
  height?: number;
}

const ButtonSwitch: React.FC<Props> = props => (
  <StyledContainer>
    <BootstrapSwitchButton {...props} />
  </StyledContainer>
);

const StyledContainer = styled.span`
  > .switch.btn {
    min-height: 32px;
    border-radius: 8px;

    > .switch-group > .btn {
      line-height: 1.25;
      font-size: 0.95rem;
    }
  }
`;

export default ButtonSwitch;
