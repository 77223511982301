import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { investmentScenariosOptionsSelector, investmentScenariosOptionSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectInvestmentScenario: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const options = useSelector(investmentScenariosOptionsSelector);
  const value = useSelector(investmentScenariosOptionSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): Promise<void> =>
      dispatch(setLayoutAction({ investmentScenarioId: Number(option.value) })),
    [dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options || !options?.length}
      labelKey="Investment scenario"
      value={value || null}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
    />
  );
};

export default SelectInvestmentScenario;
