import React, { useMemo, useCallback } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { TaskTypes } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  isMulti?: true | undefined;
  types: string | string[] | null;
  excludedTaskTypes?: Type.TaskTypes[];
  onChange: (value: any, meta: any) => void;
}

const SelectTaskType: React.FC<Props> = ({
  isMulti,
  labelKey = 'Type',
  types,
  excludedTaskTypes = [],
  onChange,
  ...props
}) => {
  const { getIntl } = useLocale();

  const { options, value } = useMemo(
    () =>
      Object.values(TaskTypes)
        .filter(value => !excludedTaskTypes.includes(value))
        .reduce(
          (acc: any, type: Type.TaskTypes) => {
            const item = { value: type, label: getIntl(type) };
            if (types?.includes(item.value)) acc.value.push(item);
            acc.options.push(item);
            return acc;
          },
          { options: [], value: [] }
        ),
    [getIntl, excludedTaskTypes, types]
  );

  const handleSelectChange = useCallback(
    (types: Type.SelectOption[], meta: any) => {
      onChange(
        {
          value: isMulti ? types?.map(i => i.value) || [] : (types as any)?.value,
        },
        meta
      );
    },
    [isMulti, onChange]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={isMulti ? value : value?.[0] || null}
      options={options}
      onChange={handleSelectChange}
      isMulti={isMulti}
      placeholderKey="Select type"
    />
  );
};

export default SelectTaskType;
