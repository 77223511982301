import React from 'react';
import { useLocale } from 'hooks';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { ProfileModal } from 'components/_modals';
import { Tabs, Tab, ContentContainer, ContentCard } from 'components/_common';
import { Routes, StorageKeys } from 'constants/index';
import TableCables from './TableCables';
import TableCablesForecast from './TableCablesForecast';
import ChartCablesHistoricalLoadingDistribution from './ChartCablesHistoricalLoadingDistribution';
import ChartFuture from './ChartFuture';
import ChartCablesVoltage from './ChartCablesVoltage';

interface Props {
  type: Type.PaginationType;
}

const TabsCables: React.FC<Props> = ({ type }) => {
  const featureToggles = useSelector(featureTogglesSelector);
  const { getIntl } = useLocale();

  return (
    <Tabs mountOnEnter>
      <Tab
        id="tab_network_loading_cables_scenarios"
        unmountOnExit
        eventKey={Routes.NetworkLoadingCables}
        title={getIntl('Scenarios')}
      >
        <TableCables />
        <ProfileModal />
      </Tab>
      {featureToggles[StorageKeys.FEATURE_TOGGLE_NETWORK_LOADING_FORECAST] && (
        <Tab
          id="tab_network_loading_cables_forecast"
          unmountOnExit
          eventKey={`${Routes.NetworkLoadingCables}${Routes.HASH.Forecast}`}
          title={getIntl('Forecast')}
        >
          <TableCablesForecast />
          <ProfileModal />
        </Tab>
      )}
      <Tab
        id="tab_network_loading_cables_historical_dist"
        eventKey={`${Routes.NetworkLoadingCables}${Routes.HASH.HistoricalLoadingDistribution}`}
        title={getIntl('Historical loading distribution')}
      >
        <ContentContainer>
          <ContentCard>
            <ChartCablesHistoricalLoadingDistribution type={type} />
          </ContentCard>
        </ContentContainer>
      </Tab>
      <Tab
        id="tab_network_loading_voltage"
        eventKey={`${Routes.NetworkLoadingCables}${Routes.HASH.Voltage}`}
        title={getIntl('Voltage')}
      >
        <ContentContainer>
          <ContentCard>
            <ChartCablesVoltage type={type} />
          </ContentCard>
        </ContentContainer>
      </Tab>
      <Tab
        id="tab_network_loading_cables_future_mean"
        eventKey={`${Routes.NetworkLoadingCables}${Routes.HASH.FutureMean}`}
        title={getIntl('Future (mean)')}
        unmountOnExit
      >
        <ContentContainer>
          <ContentCard>
            <ChartFuture
              type={type}
              asset_class="Cable"
              index={1}
              dataMarker="chart_network_loading_cables_future_mean"
            />
          </ContentCard>
        </ContentContainer>
      </Tab>
      <Tab
        id="tab_network_loading_cables_future_overloaded"
        eventKey={`${Routes.NetworkLoadingCables}${Routes.HASH.FutureOverloaded}`}
        title={getIntl('Future (overloaded)')}
        unmountOnExit
      >
        <ContentContainer>
          <ContentCard>
            <ChartFuture
              type={type}
              asset_class="Cable"
              index={0}
              dataMarker="chart_network_loading_cables_future_overloaded"
            />
          </ContentCard>
        </ContentContainer>
      </Tab>
    </Tabs>
  );
};

export default TabsCables;
