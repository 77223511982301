import React, { useMemo, useState, useEffect } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOutageTypeOptionsAction } from 'modules/options';
import { outageTypeOptionsSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectOutageType: React.FC<Props> = ({ value, ...props }) => {
  const { getIntl } = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const options = useSelector(outageTypeOptionsSelector);

  useEffect(() => {
    if (options) return;
    setLoading(true);
    dispatch(fetchOutageTypeOptionsAction()).finally(() => setLoading(false));
  }, [options, dispatch]);

  const { selectValue, selectOptions } = useMemo(
    () =>
      (options || ([] as any)).reduce(
        (acc: { selectValue: Type.SelectOption; selectOptions: Type.SelectOption[] }, i: Type.SelectOption) => {
          const item = { value: i.value, label: getIntl(i.label) };
          if (value === item.value) acc.selectValue = item;
          acc.selectOptions.push(item);
          return acc;
        },
        { selectValue: null, selectOptions: [] }
      ),
    [getIntl, options, value]
  );

  return (
    <FormReactSelect
      {...props}
      isLoading={loading}
      isDisabled={!options?.length}
      mutedTextLabelKey="Outage type"
      value={selectValue}
      options={selectOptions}
      variant="small"
    />
  );
};

export default SelectOutageType;
