import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateIndividualCustomerAction } from 'modules/newLoad';

const useConfiguredFormik = ({ id, toggleModal }: { id: number; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Omit<NewLoad.IndividualCustomer, 'id' | 'section' | 'category'>>({
    initialValues: {
      description: '',
      der_categories_id: null,
      customer_node_from: '',
      der_profile_info_id: null,
      customer_id: null,
      customer_mw: null,
      connection_year: null,
      power_factor: null,
      leading_lagging: '',
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Field can't be empty!"),
      der_categories_id: Yup.number().nullable().required("Field can't be empty!"),
      customer_node_from: Yup.string().required("Field can't be empty!"),
      der_profile_info_id: Yup.number().nullable().required("Field can't be empty!"),
      customer_id: Yup.number().nullable().required("Field can't be empty!"),
      customer_mw: Yup.number().nullable().required("Field can't be empty!"),
      connection_year: Yup.number().nullable().required("Field can't be empty!"),
      power_factor: Yup.number().required("Field can't be empty!"),
      leading_lagging: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      return dispatch(updateIndividualCustomerAction(id, values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
