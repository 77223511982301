import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { FormInput, FormInputProps } from 'components/_common';

interface Props extends FormInputProps {
  units: string | null;
}

const UnitsInputGroup: React.FC<Props> = ({ labelKey, units, errorKey, ...props }) => {
  const { getIntl } = useLocale();

  return (
    <>
      <Form.Label>{getIntl(labelKey || '')}</Form.Label>
      <InputGroup>
        <FormInput labelKey="" type="number" min={0} {...props} />
        {units && <InputGroup.Text>{units}</InputGroup.Text>}
      </InputGroup>
      {Boolean(errorKey) && (
        <Form.Control.Feedback style={{ display: 'block' }} type="invalid">
          {getIntl(errorKey!)}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default UnitsInputGroup;
