import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { createComponentPriceAssetAction, updateComponentPriceAssetAction } from 'modules/setup';
import { PaginationType } from 'constants/index';

const useConfiguredFormik = ({
  id,
  isDuplicate,
  toggleModal,
}: {
  id: number | null;
  isDuplicate?: boolean;
  toggleModal: () => void;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.COMPONENT_PRICES));

  const formik = useFormik<Setup.ComponentPriceAssetActionProps>({
    initialValues: {
      cnaim_id: null,
      size: null,
      size_unit: null,
      price: null,
      price_unit: null,
      lifetime: null,
      co2e_scope_1: null,
      co2e_scope_2: null,
      co2e_scope_3: null,
      co2e_unit: null,
      component_price_scenario_id: filters?.componentPriceScenarioId || null,
      grid_zone_id: null,
      price_type_id: filters?.priceTypeId || null,
    } as Setup.ComponentPriceAssetActionProps,

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      cnaim_id: Yup.number().nullable().required("Field can't be empty!"),
      size: Yup.number().nullable().required("Field can't be empty!"),
      price: Yup.number().nullable().required("Field can't be empty!"),
      lifetime: Yup.number().nullable().required("Field can't be empty!"),
      co2e_scope_1: Yup.number().nullable().required("Field can't be empty!"),
      co2e_scope_2: Yup.number().nullable().required("Field can't be empty!"),
      co2e_scope_3: Yup.number().nullable().required("Field can't be empty!"),
      component_price_scenario_id: Yup.number().nullable().required("Field can't be empty!"),
      grid_zone_id: Yup.array().nullable().required("Field can't be empty!"),
      price_type_id: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      return (
        id && !isDuplicate
          ? dispatch(updateComponentPriceAssetAction({ ...values, component_price_id: id }))
          : dispatch(createComponentPriceAssetAction(values))
      )
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
