import React from 'react';
import { workTypeOptionsSelector } from 'modules/options/selectors';
import { fetchWorkTypeOptionsAction } from 'modules/options';
import { SelectRedux, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Setup.WorkPriceActionProps['work_type'];
}

const SelectWorkType: React.FC<Props> = props => (
  <SelectRedux {...props} optionsSelector={workTypeOptionsSelector} optionsAction={fetchWorkTypeOptionsAction} />
);

export default SelectWorkType;
