import React, { useEffect } from 'react';
import moment from 'moment';
import { FormikErrors } from 'formik';

export default function useSetValuesEffect({
  id,
  task,
  setValues,
  setShow,
}: {
  id: number | null;
  task: Tasks.Task | undefined;
  setValues: (
    values: React.SetStateAction<Type.CreateTaskActionProps>,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Type.CreateTaskActionProps>> | Promise<void>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  useEffect(() => {
    if (!task) return;
    const {
      name,
      taskNumber,
      departmentNumber,
      type,
      appAction,
      assetCategoryCode,
      assetCodes,
      taskCheckListId,
      formId,
      assetSubcomponentTypeId,
      startDate,
      endDate,
      expectedTime,
      assignedUsers,
      responsibleUsers,
      description,
      installationNumbers,
      planId,
      taskMonths,
      complaintId,
      address,
      loc,
      useFilteredTasks,
    } = task;
    setValues({
      name,
      taskNumber,
      departmentNumber,
      type,
      appAction,
      // TODO - assetCategoryCode picked from assetCodes temporary to migrate data correctly
      assetCategoryCode: assetCategoryCode || (assetCodes?.[0]?.slice(0, 2) as Type.AssetCategories) || null,
      assetCodes,
      taskCheckListId,
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null,
      expectedTime,
      formId,
      assetSubcomponentTypeId,
      userIds: assignedUsers.map(i => i.id),
      responsibleUserIds: responsibleUsers.map(i => i.id),
      description,
      installationNumbers,
      files: null,
      deletedAttachmentIds: [],
      updatedAttachmentsHash: {},
      planId,
      taskMonths,
      complaintId,
      address,
      loc,
      useAddressGeocoding: Boolean(address),
      useFilteredTasks,
    });
    setShow(true);
  }, [id, task, setValues, setShow]);
}
