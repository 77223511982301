import { createAction } from 'redux-actions';
import { portfolioIdSelector, scenarioIdSelector, selectedChartYearSelector } from './selectors';
import { DERsPreviewYearsHashSelector } from 'modules/newLoad/selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const setPaginationAction = createAction('layouts/SET_PAGINATION');

export const setModalConfirmationMetaAction = createAction('layouts/SET_MODAL_DELETE_CONFIRMATION_META');

export const toggleSidebarAction = createAction('layouts/TOGGLE_SIDEBAR');

export const closeSidebarAction = createAction('layouts/CLOSE_SIDEBAR');

export const setFormsModalIdAction = createAction('layouts/SET_FORMS_MODAL_ID');

export const setTaskModalMetaAction = createAction('layouts/SET_TASK_MODAL_META');

export const setErrorToastAction = createAction('layouts/SET_ERROR_TOAST');

export const setSuccessToastAction = createAction('layouts/SET_SUCCESS_TOAST');

export const setInfoToastAction = createAction('layouts/SET_INFO_TOAST');

export const deleteToastAction = createAction('layouts/DELETE_TOAST');

export const setAssetModelsModalIdAction = createAction('layouts/SET_ASSET_MODELS_MODAL_ID');

export const setAssetSubComponentModalIdAction = createAction('layouts/SET_ASSET_SUB_COMPONENT_MODAL_ID');

export const setEmployeeModalIdSelectorAction = createAction('layouts/SET_EMPLOYEE_MODAL_ID');

export const setRepeatedTasksSectionModalIdAction = createAction('layouts/SET_REPEATED_TASKS_SECTION_MODAL_ID');

export const setRepeatedTaskModalIdAction = createAction('layouts/SET_REPEATED_TASK_MODAL_ID');

export const setProfileModalMetaAction = createAction('layouts/SET_PROFILE_MODAL_META');

export const setChecklistsModalIdAction = createAction('layouts/SET_CHECKLISTS_MODAL_ID');

export const setMaintenancePlansModalIdAction = createAction('layouts/SET_MAINTENANCE_PLAN_MODAL_ID');

export const setIsDepreciationsEditable = createAction('layouts/SET_IS_DEPRECIATIONS_EDITABLE');

export const setMetersPopulationModalId = createAction('layouts/SET_METERS_POPULATION_MODAL_ID');

export const setPermissionsGroupsModalIdAction = createAction('layouts/SET_PERMISSIONS_GROUPS_MODAL_ID');

export const toggleIsSidebarHelpMenuOpen = createAction('layouts/TOGGLE_IS_SIDEBAR_HELP_MENU_OPEN');

export const setModalAssetsCNAIMMetaAction = createAction('layouts/SET_MODAL_ASSETS_CNAIM_META');

export const setInterventionDetailsModalId = createAction('layouts/SET_INTERVENTION_DETAILS_MODAL_ID');

export const setReliabilityModelOption = createAction('layouts/SET_RELIABILITY_MODEL_OPTION');

export const setReliabilityVoltageOption = createAction('layouts/SET_RELIABILITY_VOLTAGE_OPTION');

export const setReliabilitySAIFIValue = createAction('layouts/SET_RELIABILITY_SAIFI_VALUE');

export const setReliabilityCMLValue = createAction('layouts/SET_RELIABILITY_CML_VALUE');

export const setReliabilitySAIFIDelayValue = createAction('layouts/SET_RELIABILITY_SAIFI_DELAY_VALUE');

export const setReliabilityDistributionOption = createAction('layouts/SET_RELIABILITY_DISTRIBUTION_OPTION');

export const setReliabilityScenarioParamsOption = createAction('layouts/SET_RELIABILITY_SCENARIO_PARAMS_OPTION');

export const setReliabilityAssetCategoriesCNAIMOption = createAction(
  'layouts/SET_RELIABILITY_ASSET_CATEGORIES_CNAIM_OPTION'
);

export const setDERModalIdAction = createAction('layouts/SET_DER_MODAL_ID');

export const setNewLoadDERsCategoriesModalIdAction = createAction('layouts/SET_NEW_LOAD_DERS_CATEGORIES_MODAL_ID');

export const setNewLoadDERProfileModalIdAction = createAction('layouts/SET_NEW_LOAD_DER_PROFILE_MODAL_ID');

export const setLayoutAction = createAction(
  'layouts/SET_LAYOUT_MODIFIER',
  (payload: Partial<Layouts.Root>) => (dispatch: Shared.CustomDispatch, getState: () => State.Root) => {
    if (!payload.portfolioId && !payload.scenarioId) return payload;
    const state = getState();
    const portfolioId = portfolioIdSelector(state);
    const scenarioId = scenarioIdSelector(state);
    const value = selectedChartYearSelector(state);
    const DERsPreviewYearsHash = DERsPreviewYearsHashSelector(state);
    const years = DERsPreviewYearsHash[`${payload.portfolioId || portfolioId}_${payload.scenarioId || scenarioId}`];
    if (!years || years.includes(value)) return payload;
    // Note. (DERsPreviewYears) Automatically apply default 2030 value or first available value if doesn't exist
    return { ...payload, selectedChartYear: years.includes(2030) ? 2030 : years[0] };
  }
);

export const setFeatureToggleAction =
  createAction<Partial<Layouts.Root['featureToggles']>>('layouts/SET_FEATURE_TOGGLE');
