import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { repeatedTaskModalIdSelector } from 'modules/layouts/selectors';
import { setRepeatedTaskModalIdAction } from 'modules/layouts';
import { repeatedTaskSelectorFactory } from 'modules/tasks/selectors';
import {
  Modal,
  Button,
  HiddenFormSubmit,
  SelectTaskDepartment,
  SelectExtRepeatedTaskNumber,
  SelectRepeatedTasksSection,
  SelectTaskAppAction,
} from 'components/_common';
import { getSelectTaskAppActionExcludedOptions } from 'utils';
import { TaskTypes } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const RepeatedTasksModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(repeatedTaskModalIdSelector);
  const repeatedTask: Tasks.RepeatedTask | undefined = useSelector(repeatedTaskSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && repeatedTask);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setRepeatedTaskModalIdAction(null));
    setShow(!show);
  }, [id, show, dispatch]);

  const { values, touched, errors, isSubmitting, submitForm, setFieldValue, setFieldTouched, setValues, resetForm } =
    useConfiguredFormik({ id, toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!repeatedTask) return;
    setValues({
      departmentNumber: repeatedTask.departmentNumber,
      taskNumber: repeatedTask.taskNumber,
      workSectionId: repeatedTask.workSectionId,
      actionInApp: repeatedTask.actionInApp,
      description: repeatedTask.description,
      taskStatus: repeatedTask.taskStatus,
      mainActivity: repeatedTask.mainActivity,
    });
    setShow(true);
  }, [repeatedTask, setValues]);

  const handleDepartmentNumberSelectChange = useCallback(
    (value: Type.SelectOption) => {
      setFieldValue('departmentNumber', value?.value || null);
      setFieldValue('taskNumber', null);
      setFieldValue('workSectionId', null);
    },
    [setFieldValue]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, meta: any) => setFieldValue(meta.name, value?.value || null),
    [setFieldValue]
  );

  return (
    <>
      <Button
        icon={<IconPlus />}
        labelKey="Create repeated task"
        onClick={toggleModal}
        size="large"
        variant="primary"
      />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? 'Edit repeated task' : 'Create repeated task'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectTaskDepartment
              value={values.departmentNumber}
              isDisabled={isEditMode}
              onChange={handleDepartmentNumberSelectChange}
              onBlur={() => setFieldTouched('departmentNumber')}
              errorKey={
                Boolean(touched.departmentNumber && errors.departmentNumber) ? (errors.departmentNumber as string) : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <SelectExtRepeatedTaskNumber
              name="taskNumber"
              value={values.taskNumber}
              isDisabled={isEditMode}
              departmentNumber={values.departmentNumber}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('taskNumber')}
              errorKey={Boolean(touched.taskNumber && errors.taskNumber) ? (errors.taskNumber as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectRepeatedTasksSection
              name="workSectionId"
              value={values.workSectionId}
              isDisabled={isEditMode}
              departmentNumber={values.departmentNumber}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('workSectionId')}
              errorKey={Boolean(touched.workSectionId && errors.workSectionId) ? (errors.workSectionId as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectTaskAppAction
              name="actionInApp"
              actions={values.actionInApp}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('actionInApp')}
              errorKey={Boolean(touched.actionInApp && errors.actionInApp) ? (errors.actionInApp as string) : ''}
              excludedOptions={getSelectTaskAppActionExcludedOptions(TaskTypes.Immediate)}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default RepeatedTasksModal;
