import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalAssetsCNAIMMetaSelector } from 'modules/layouts/selectors';
import { setModalAssetsCNAIMMetaAction } from 'modules/layouts';
import { DataTable, FormInput } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  items?: Asset.AssetCNAIM[];
}

const AssetsCNAIMTable: React.FC<Props> = ({ items }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const meta: Layouts.ModalAssetsCNAIMMeta | null = useSelector(modalAssetsCNAIMMetaSelector);
  const rows = items || meta!.rows;

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isNumber = e.currentTarget.type === 'number';
      const rowIndex: number = Number(e.currentTarget.getAttribute('data-row-index'));
      const { name, value } = e.currentTarget;
      const nextMetaRows = meta!.rows.map((i, index) => {
        if (index === rowIndex) {
          return {
            ...i,
            [name]: isNumber ? Number(value) : value,
          };
        }
        return i;
      });
      dispatch(setModalAssetsCNAIMMetaAction({ ...meta, rows: nextMetaRows }));
    },
    [meta, dispatch]
  );

  const customHeaders = useMemo(
    () =>
      Object.keys(rows[0])
        .filter(i => i !== 'ID')
        .map(i => ({ title: i })),
    [rows]
  );

  return (
    <DataTable
      paginationType={PaginationType.ASSETS_CNAIM}
      totalAmount={rows.length}
      sendRequest={async () => undefined}
      customHeaders={customHeaders}
    >
      {rows.map((item: Asset.AssetCNAIM, index: number) => (
        <AssetsCNAIMTableItem
          key={item.ID}
          {...item}
          index={index}
          handleInputChange={handleInputChange}
          isEditDisabled={Boolean(items)}
        />
      ))}
    </DataTable>
  );
};

interface ItemProps extends Asset.AssetCNAIM {
  index: number;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditDisabled: boolean;
}

const AssetsCNAIMTableItem: React.FC<ItemProps> = ({ index, ID, isEditDisabled, handleInputChange, ...rest }) => {
  const handleKeyPress = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return;
    const rowIndex: number = Number(e.currentTarget.getAttribute('data-row-index'));
    const cellIndex: number = Number(e.currentTarget.getAttribute('data-cell-index'));
    const element: any = document.querySelector(`[data-row-index='${rowIndex + 1}'][data-cell-index='${cellIndex}']`);
    if (element) element?.focus();
  }, []);

  return (
    <tr>
      {Object.keys(rest).map((key, cellIndex: number) => (
        <td key={key + index} style={{ minWidth: 50 }}>
          {isEditDisabled ? (
            (rest as any)[key]
          ) : (
            <FormInput
              data-row-index={index}
              data-cell-index={cellIndex}
              type={typeof (rest as any)[key]}
              size="sm"
              name={key}
              value={(rest as any)[key]}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
          )}
        </td>
      ))}
    </tr>
  );
};

export default AssetsCNAIMTable;
