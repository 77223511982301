import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CloseButton, OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { useLocale, useUsers } from 'hooks';
import { setTaskModalMetaAction } from 'modules/layouts';
import { Button } from 'components/_common';
import { stopPropagationFunc } from 'utils';
import { IconUsers2 } from '@utiligize/shared/resources';

interface Props {
  popoverId: number;
  placement?: Placement;
  userIds?: number[];
  responsibleUserIds?: number[];
  showEmptyPopup?: boolean;
}

const ShowUsersOverlay: React.FC<Props> = ({
  popoverId,
  placement = 'left',
  userIds,
  responsibleUserIds,
  showEmptyPopup = false,
}) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { usersHash } = useUsers();
  const [show, setShow] = useState(false);
  const buttonVariant = show ? undefined : 'primary';
  const isCombinedPopup = Array.isArray(userIds) && Array.isArray(responsibleUserIds);
  const isEmptyPopup = Boolean(!userIds?.length && !responsibleUserIds?.length);
  const isResponsibleUsersOnly = Array.isArray(responsibleUserIds) && !isCombinedPopup;
  const usersLength = [...(userIds || []), ...(responsibleUserIds || [])]?.length;

  const closeOverlay = useCallback(
    (e?: React.MouseEvent<HTMLButtonElement>): void => {
      e?.stopPropagation?.(); // avoid unexpected table row clicks
      setShow(prev => !prev);
    },
    [setShow]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      document.body.click(); // close popover
      dispatch(setTaskModalMetaAction({ id }));
    },
    [dispatch]
  );

  const renderTitle = (labelKey: string, showCloseButton: boolean = false) => (
    <Popover.Title as="h4">
      {getIntl(labelKey)}
      {showCloseButton && <CloseButton onClick={closeOverlay} />}
    </Popover.Title>
  );

  const renderEmptyPopup = () => (
    <>
      {renderTitle(isResponsibleUsersOnly ? 'Responsible users' : 'Assigned users', true)}
      <Popover.Content>
        <span>
          {getIntl(isResponsibleUsersOnly ? 'There are no responsible users' : 'There are no assigned users')}.
        </span>
      </Popover.Content>
      <Popover.Content>
        <span>
          {getIntl(
            isResponsibleUsersOnly ? 'You could assign a responsible user in the' : 'You could assign the user in the'
          )}
        </span>{' '}
        <Button
          variant="primary-link"
          data-id={popoverId}
          onClick={handleEditBtnClick}
          size="small"
          aria-label="Edit task"
          labelKey="Edit task"
        />
      </Popover.Content>
    </>
  );

  const renderPopupContent = () => {
    const isResponsibleUsersExist = Boolean(responsibleUserIds?.length);

    return (
      <>
        {isResponsibleUsersExist && (
          <>
            {renderTitle('Responsible users', true)}
            <Popover.Content>
              {responsibleUserIds?.map((id: number) => (
                <div key={id} className="d-flex justify-content-between mb-3">
                  <span>{`${usersHash[`_${id}_`]?.fullName}`}</span>
                  <StyledSpan>{`${usersHash[`_${id}_`]?.email}`}</StyledSpan>
                </div>
              ))}
            </Popover.Content>
          </>
        )}
        {Boolean(userIds?.length) && (
          <>
            {renderTitle('Assigned users', !isResponsibleUsersExist)}
            <Popover.Content>
              {userIds?.map((id: number) => (
                <div key={id} className="d-flex justify-content-between mb-3">
                  <span>{`${usersHash[`_${id}_`]?.fullName}`}</span>
                  <StyledSpan>{`${usersHash[`_${id}_`]?.email}`}</StyledSpan>
                </div>
              ))}
            </Popover.Content>
          </>
        )}
      </>
    );
  };

  return (
    <OverlayTrigger
      trigger={['click']}
      placement={placement}
      rootClose
      onToggle={setShow}
      show={show}
      overlay={
        <StyledPopover id={String(popoverId)}>{isEmptyPopup ? renderEmptyPopup() : renderPopupContent()}</StyledPopover>
      }
    >
      {isResponsibleUsersOnly && responsibleUserIds?.length ? (
        <StyledShowMoreButton
          variant="primary-link"
          icon={
            <>
              <div>{`${usersHash[`_${responsibleUserIds[0]}_`]?.fullName}`}</div>
              {responsibleUserIds.length > 1 && (
                <span>
                  {' '}
                  +{responsibleUserIds.length - 1} {getIntl('more')}
                </span>
              )}
            </>
          }
          data-id={popoverId}
          onClick={stopPropagationFunc}
          size="small"
        />
      ) : (
        <Button
          variant={buttonVariant}
          icon={
            <>
              <StyledNumber className="text-sm font-weight-bold">{usersLength || '-'}</StyledNumber>
              <IconUsers2 />
            </>
          }
          data-id={popoverId}
          onClick={stopPropagationFunc}
          size="small"
          disabled={!usersLength && !showEmptyPopup}
        />
      )}
    </OverlayTrigger>
  );
};

const StyledPopover = styled(Popover)`
  min-width: 400px;
  max-width: 50%;
  border: 1px solid ${({ theme }) => theme.colors.purple50};

  .popover-header {
    margin-top: 5px;
    border-bottom: none;
    background-color: white;
    font-weight: bold;
    margin-left: 5px;
  }

  .arrow {
    display: none;
  }
`;

const StyledShowMoreButton = styled(Button)`
  display: block;
  border: none;
  color: ${({ theme }) => theme.colors.purple800};
  background: none;
  height: auto;
  padding: 0;
  text-align: left;

  span {
    display: inline-block;
    font-size: 11px;
    background-color: ${({ theme }) => theme.colors.grey50};
    border-radius: 15px;
    font-weight: normal;
    padding: 2px 5px;
    margin-top: 5px;
  }
`;

const StyledNumber = styled.small`
  margin-right: 3px;
  color: inherit;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.purple800};
`;

export default ShowUsersOverlay;
