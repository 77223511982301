import React from 'react';
import { Map } from 'components';
import { useSelector } from 'react-redux';
import MapContextProvider from 'context/map';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import MapPanels from 'components/Panels/MapPanels';
import NetworkChartContainer from 'components/_charts/NetworkChart';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';
import 'overlayscrollbars/overlayscrollbars.css';

const PageMap: React.FC = () => {
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isMapEnabled}>
      <MapContextProvider>
        <MapPanels>
          {singleDiagramEnabled && <NetworkChartContainer />}
          {/* // FixMe. MapPanel must work without map component */}
          <div style={{ display: singleDiagramEnabled ? 'none' : 'initial' }}>
            <Map />
          </div>
        </MapPanels>
      </MapContextProvider>
    </PagePermissionsProvider>
  );
};

export default PageMap;
