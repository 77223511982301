import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useEventPreventedExec, useLocale } from 'hooks';
import { Modal, Button, Checkbox } from 'components/_common';
import { IconUpload } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalUploadIndividualCustomers: React.FC = () => {
  const profileInputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const { getIntl } = useLocale();

  const toggleModal = useCallback((): void => setShow(show => !show), []);

  const { values, errors, touched, isSubmitting, submitForm, resetForm, setFieldValue } = useConfiguredFormik({
    toggleModal,
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { name, checked: append } = event.target as HTMLInputElement;
      setFieldValue(name, append);
    },
    [setFieldValue]
  );

  const handleUploadButtonClick = useCallback(() => {
    profileInputRef.current!.value = '';
    profileInputRef.current?.click();
  }, []);

  const handleUploadInputChange = useCallback(() => {
    const files = profileInputRef.current?.files;
    if (files?.[0]) {
      setFieldValue('file', files?.[0]);
    }
  }, [setFieldValue]);

  return (
    <>
      <Button icon={<IconUpload />} labelKey="Upload individual customers" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Upload individual customers"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: 'Upload',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Row>
            <Form.Group as={Col} xs="auto">
              <Button icon={<IconUpload />} labelKey="Upload excel" onClick={handleUploadButtonClick} />
              <input ref={profileInputRef} onChange={handleUploadInputChange} className="d-none" type="file" />
            </Form.Group>
            <Form.Group as={Col} xs="auto">
              <Checkbox
                className="pl-0 icheck-success"
                name="append"
                checked={values.append}
                labelKey="Append"
                onChange={handleCheckboxChange}
              />
            </Form.Group>
          </Row>
          {values.file && (
            <Form.Group>
              <Form.Text className="text-muted">{values.file.name}</Form.Text>
            </Form.Group>
          )}
          {Boolean(touched.file && errors.file) && (
            <Form.Group>
              <Form.Control.Feedback className="d-block" type="invalid">
                {getIntl(errors.file as string)}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalUploadIndividualCustomers;
