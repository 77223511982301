import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createInvestmentScenarioAction, updateInvestmentScenarioAction } from 'modules/setup';

const useConfiguredFormik = ({
  id,
  isDuplicate,
  toggleModal,
}: {
  id: number | null;
  isDuplicate?: boolean;
  toggleModal: () => void;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Setup.InvestmentScenarioActionProps>({
    initialValues: {
      description: '',
      forecasted_scenario_id: null,
      component_price_scenario_id: null,
      interest_rate: null,
      sso_users_id: null,
      loss_optimisation: false,
    },

    validationSchema: Yup.object().shape({
      description: Yup.string()
        .required("Field can't be empty!")
        .notOneOf(['No investment'], "'No investment' is a reserved scenario name"),
      forecasted_scenario_id: Yup.number().nullable().required("Field can't be empty!"),
      component_price_scenario_id: Yup.number().nullable().required("Field can't be empty!"),
      interest_rate: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: async values => {
      const action = id && !isDuplicate ? updateInvestmentScenarioAction : createInvestmentScenarioAction;
      return dispatch(action({ investment_scenario_id: id || undefined, ...values }))
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
