import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { SelectUser } from 'components/_common';
import { DataTableFilterKeys, PaginationType } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  paginationType: Type.PaginationType;
  values: Layouts.Filters;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Layouts.Filters>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const SelectUsersFilter: React.FC<Props> = ({
  inDataTableHeaderFilters = false,
  paginationType,
  values,
  setFieldValue,
  submitForm,
  ...props
}) => {
  const handleSelectChange = useCallback(
    async (userIds: number[]) => {
      await setFieldValue(DataTableFilterKeys.USER_IDS, userIds?.length ? userIds : null);
      if (inDataTableHeaderFilters) submitForm();
    },
    [setFieldValue, inDataTableHeaderFilters, submitForm]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.USER_IDS)) return null;

  const Select = (
    <SelectUser
      labelKey={inDataTableHeaderFilters ? '' : undefined}
      {...props}
      userIds={values[DataTableFilterKeys.USER_IDS] || []}
      setUserIds={handleSelectChange}
      isMulti
      onlyIncludedInTask={
        inDataTableHeaderFilters &&
        [PaginationType.UPCOMING_TASKS, PaginationType.TASKS, PaginationType.TASKS_FINISHED].includes(paginationType)
      }
    />
  );

  return inDataTableHeaderFilters ? <Col sm={4}>{Select}</Col> : <Form.Group>{Select}</Form.Group>;
};

export default SelectUsersFilter;
