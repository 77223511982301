import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode[];
}

const GroupContainer: React.FC<Props> = ({ children }) => <StyledContainer>{children}</StyledContainer>;

const StyledContainer = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export default GroupContainer;
