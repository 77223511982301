import { handleActions } from 'redux-actions';
import { fetchHistoryAction } from './actions';

const initialState: History.Root = {
  loading: false,
  isFetched: false,
  count: 0,
  rows: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchHistoryAction]: {
    next: (state: History.Root, action: Shared.ReduxAction<Pick<History.Root, 'count' | 'rows'>>): History.Root => ({
      ...state,
      ...action.payload,
      loading: false,
      isFetched: true,
    }),
    throw: (state: History.Root): History.Root => ({
      ...state,
      loading: false,
      isFetched: false,
    }),
  },
};

export { fetchHistoryAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
