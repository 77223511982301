import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { fetchSimulationsAction, fetchInvestmentScenariosAction, fetchConfigAction } from 'modules/map';
import { isMapConfigFetchedSelector, selectedSimulationSelector, simulationsSelector } from 'modules/map/selectors';
import MapContainer from 'components/Map/core/MapContainer';
import { ErrorBoundary, LoadingNotification, Spinner } from 'components/_common';

const Map: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulations = useSelector(simulationsSelector);
  const selectedSimulation = useSelector(selectedSimulationSelector);
  const isMapConfigFetched = useSelector(isMapConfigFetchedSelector);

  useEffect(() => {
    if (!portfolioId || simulations) return;
    dispatch(fetchSimulationsAction(portfolioId));
  }, [dispatch, portfolioId, simulations]);

  useEffect(() => {
    if (!portfolioId || !selectedSimulation) return;
    dispatch(
      fetchConfigAction({
        portfolioId,
        versionId: selectedSimulation?.version_value,
        simulationId: selectedSimulation?.value,
      })
    );
  }, [dispatch, portfolioId, selectedSimulation]);

  useEffect(() => {
    if (!scenarioId) return;
    dispatch(fetchInvestmentScenariosAction(scenarioId));
  }, [dispatch, scenarioId]);

  return (
    <StyledSection>
      <ErrorBoundary>
        <LoadingNotification />
        {!isMapConfigFetched ? <Spinner isInFullHeightContainer /> : <MapContainer />}
      </ErrorBoundary>
    </StyledSection>
  );
};

const StyledSection = styled.section`
  position: relative;
  height: calc(100vh - ${props => props.theme.heights.topNavigation});
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Map;
