import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsTenantsCountSelector, permissionsTenantsSelector } from 'modules/permissions/selectors';
import { fetchPermissionsTenantsAction } from 'modules/permissions';
import { setLayoutAction } from 'modules/layouts';
import { DataTable, PermissionsTypesTableCell, Button } from 'components/_common';
import { substringTenantName } from 'utils';
import { PaginationType } from 'constants/index';
import { IconEdit } from '@utiligize/shared/resources';

const TableTenants: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(permissionsTenantsCountSelector);
  const items = useSelector(permissionsTenantsSelector);

  const sendRequest = useCallback(() => dispatch(fetchPermissionsTenantsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const permissionsTenantsModalId: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (permissionsTenantsModalId) dispatch(setLayoutAction({ permissionsTenantsModalId }));
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={PaginationType.PERMISSIONS_TENANTS} totalAmount={count} sendRequest={sendRequest}>
      {items?.map((item: Permissions.Tenant) => (
        <tr key={item.id}>
          <td>{substringTenantName(item.tenantName)}</td>
          <PermissionsTypesTableCell permissionsTypes={item.permissionsTypes} />
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableTenants;
