import { handleActions } from 'redux-actions';
import { fetchAnswersAction, fetchAnswerAction, deleteAnswerAction } from './actions';

const initialState: Answers.Root = {
  count: 0,
  rows: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchAnswersAction.toString()]: {
    next: (
      state: Answers.Root,
      action: Shared.ReduxAction<{ data: Answers.Root; skipStoreUpdate: true | undefined }>
    ): Answers.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload.data,
      };
    },
  },
};

export { fetchAnswersAction, fetchAnswerAction, deleteAnswerAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
