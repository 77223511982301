import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsGroupsCountSelector, permissionsGroupsSelector } from 'modules/permissions/selectors';
import {
  fetchPermissionsGroupsAction,
  deletePermissionsGroupAction,
  patchPermissionsGroupAction,
} from 'modules/permissions';
import { setModalConfirmationMetaAction, setPermissionsGroupsModalIdAction } from 'modules/layouts';
import { DataTable, Button, PermissionsTypesTableCell, Checkbox } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const TableGroups: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const count = useSelector(permissionsGroupsCountSelector);
  const items = useSelector(permissionsGroupsSelector);

  const sendRequest = useCallback(() => dispatch(fetchPermissionsGroupsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setPermissionsGroupsModalIdAction(id));
    },
    [dispatch]
  );

  const handleIsDefaultCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      const id: number = Number(event.currentTarget.getAttribute('data-index'));
      const { checked: isDefault } = event.currentTarget;
      dispatch(patchPermissionsGroupAction(id, isDefault)).finally(() => setLoading(false));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deletePermissionsGroupAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.PERMISSIONS_GROUPS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 235px)"
    >
      {items?.map((item: Permissions.Group) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <PermissionsTypesTableCell permissionsTypes={item.permissionsTypes} />
          <td className="text-center">
            <Checkbox
              dataIndex={item.id}
              className="icheck-primary d-inline ml-2 position-relative"
              checked={item.isDefault}
              onChange={handleIsDefaultCheckboxClick}
              disabled={loading || item.isDefault}
            />
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            {!item.isDefault && (
              <Button
                marginLeft
                tooltipKey="Delete"
                icon={<IconDelete />}
                data-id={item.id}
                onClick={handleDeleteBtnClick}
                size="small"
                variant="primary"
              />
            )}
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableGroups;
