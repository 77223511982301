import { useFormik } from 'formik';
import * as Yup from 'yup';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { BuilderFieldsTypes, FIELDS } from 'constants/index';

interface Values {
  label: string;
  formType: {
    value: number;
    label: Builder.FormTypeNames;
  } | null;
  selectedForm: {
    value: number;
    label: string;
  } | null;
  scheduleAllowed: boolean;
}

const useConfiguredFormik = ({
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const firstValue = Number(formField?.params?.[0]?.values?.[0]?.value);
  const secondValue = Number(formField?.params?.[1]?.values?.[0]?.value);

  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[3]?.values?.[0]?.value || ''),
      formType: firstValue
        ? {
            value: firstValue,
            label: (formField?.params?.[0]?.values?.[0]?.valuealt as Builder.FormTypeNames) || '',
          }
        : null,
      selectedForm: secondValue
        ? {
            value: secondValue,
            label: formField?.params?.[1]?.values?.[0]?.valuealt || '',
          }
        : null,
      scheduleAllowed: Boolean(formField?.params?.[2]?.values?.[0]?.value as unknown as boolean),
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Field can't be empty!"),
      formType: Yup.object().nullable().required("Field can't be empty!"),
      selectedForm: Yup.object().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('LinkedFormModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[BuilderFieldsTypes.LinkedForm]);
      _set(field, 'params[0].values[0].value', values.formType?.value);
      _set(field, 'params[0].values[0].valuealt', values.formType?.label);

      _set(field, 'params[1].values[0].value', values.selectedForm?.value);
      _set(field, 'params[1].values[0].valuealt', values.selectedForm?.label);

      _set(field, 'params[2].values[0].value', values.scheduleAllowed);

      _set(field, 'params[3].values[0].value', values.label);

      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
