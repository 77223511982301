import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { RouteType } from 'constants/map';
import { settingsSelector, n1RouteSelector, selectedN1RoutesSelector } from 'modules/map/selectors';
import { setSelectedN1RoutesAction, setSelectedN1RouteIdAction, setN1RouteAction } from 'modules/map';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import Section from 'components/Map/common/Section';
import ControlBox from 'components/Map/common/ControlBox';
import { Button } from 'components/_common';

const ForecastInvestmentN1Routes: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const settings = useSelector(settingsSelector);
  const n1Route = useSelector(n1RouteSelector);
  const selectedN1Routes = useSelector(selectedN1RoutesSelector);

  const routes = useMemo(
    () =>
      n1Route?.routes.map(route => ({
        ...route,
        checked: selectedN1Routes?.some(p => route.id === p.id) ?? false,
      })),
    [n1Route, selectedN1Routes]
  );

  const handleRouteChangeClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = Number(e.currentTarget.getAttribute('data-id'));
      const route = routes?.find(r => r.id === id);
      if (!route) return;
      const checked = e.target.checked;
      const prev = selectedN1Routes ?? [];
      const next = checked ? [...prev, route] : prev.filter(i => i.id !== route.id);
      dispatch(setSelectedN1RoutesAction(next));
    },
    [dispatch, routes, selectedN1Routes]
  );

  const handleRouteMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const id = Number(e.currentTarget.getAttribute('data-id'));
      dispatch(setSelectedN1RouteIdAction(id));
    },
    [dispatch]
  );

  const handleRouteMouseLeave = useCallback(() => dispatch(setSelectedN1RouteIdAction(null)), [dispatch]);

  const handleShowAllRoutesButtonClick = useCallback(() => dispatch(setN1RouteAction(null)), [dispatch]);

  return (
    <CollapseGroup
      id="n_1"
      dataMarker="panel_n_1_routes"
      titleKey={settings.themeTitles?.n_1!}
      stateLoc="theme"
      disabled={settings.isN1ThemeDisabled}
    >
      <Section
        className="mt-2"
        title={(n1Route ? getIntl('Routes for') : getIntl('Routes')) + (n1Route ? ' ' + n1Route?.target : '')}
      >
        {Boolean(routes) ? (
          routes?.map(route => (
            <div
              key={route.id}
              data-id={route.id}
              className="position-relative"
              onMouseOver={handleRouteMouseOver}
              onMouseLeave={handleRouteMouseLeave}
            >
              <ControlBox
                key={route.id}
                data-id={route.id}
                id={String(route.id)}
                type="checkbox"
                name={String(route.id)}
                checked={route.checked}
                labelKey={route.name}
                onChange={handleRouteChangeClick}
              />
              <i
                className={classNames(
                  'legend-icon',
                  route.type === RouteType.PRIMARY ? 'island_networks' : 'meshed_structure'
                )}
              />
            </div>
          ))
        ) : (
          <StyledInfo>
            {getIntl('Select an asset to get its routes displayed')}
            .&nbsp;
            {getIntl('Right click on asset will draw its routes')}
          </StyledInfo>
        )}
      </Section>
      {Boolean(n1Route) && (
        <StyledWarning>
          <p>{getIntl('Currently, only selected objects are shown')}</p>
          <Button labelKey="Show all objects" onClick={handleShowAllRoutesButtonClick} size="small" />
        </StyledWarning>
      )}
    </CollapseGroup>
  );
};

const StyledInfo = styled.div`
  font-weight: 400;
  letter-spacing: 0.04em;
  font-size: 11px;
  color: var(--map-active-color);
  padding: 3px;
`;

const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  > p {
    color: var(--map-text-color-secondary-light);
    margin: 0 10px 0 0;
  }
`;

export default ForecastInvestmentN1Routes;
