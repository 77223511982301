import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { AssetsMissingAgeTable } from 'components/_tables';
import { DataTableTemplate, SelectAssetCategories, Tabs, Tab, ChartIframe, ContentContainer } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';

const AssetsAgeStatistics: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.ASSETS_MISSING_AGE));

  const handleChangeCategorySelect = useCallback(
    (value: any) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.ASSETS_MISSING_AGE,
          modifier: { filters: { ...filters, assetcategoryCode: value.value }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate>
      <Row>
        <Col sm={3}>
          <SelectAssetCategories
            labelKey=""
            value={filters?.assetcategoryCode || ''}
            onChange={handleChangeCategorySelect}
          />
        </Col>
      </Row>
      <Tabs mountOnEnter>
        <Tab eventKey={Routes.AssetsAgeStatistics} title={getIntl('Age distribution')}>
          <ContentContainer>
            <ChartIframe title="Plot of age distribution" src={`age_histogram_plot/${filters?.assetcategoryCode}`} />
          </ContentContainer>
        </Tab>
        <Tab
          unmountOnExit
          eventKey={`${Routes.AssetsAgeStatistics}${Routes.HASH.MissingAge}`}
          title={getIntl('Missing age')}
        >
          <AssetsMissingAgeTable />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default AssetsAgeStatistics;
