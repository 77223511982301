import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { selectedTimezoneSelector } from 'modules/app/selectors';
import { setTimezoneAction } from 'modules/app';
import { FormReactSelect } from 'components/_common';

const SelectTimezone: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const selectedTimezone = useSelector(selectedTimezoneSelector);

  const getZoneOffset = useCallback((zone: string) => moment.tz(zone).utcOffset() / 60, []);

  const getTimeLabel = useCallback(
    (zone: string) => {
      const zoneOffset = getZoneOffset(zone);
      return `${zone.replace('_', ' ')} (GMT${zoneOffset >= 0 ? '+' : ''}${zoneOffset})`;
    },
    [getZoneOffset]
  );

  const sortedTimezones = useMemo(
    () => moment.tz.names().sort((a, b) => getZoneOffset(a) - getZoneOffset(b)),
    [getZoneOffset]
  );

  const options = useMemo(
    (): Type.SelectOption[] => sortedTimezones.map((zone: string) => ({ value: zone, label: getTimeLabel(zone) })),
    [sortedTimezones, getTimeLabel]
  );

  const handleSelectChange = useCallback(
    (option: { value: Layouts.MapTheme }): void => {
      dispatch(setTimezoneAction(option.value));
    },
    [dispatch]
  );

  const guessZone = useMemo(() => moment.tz.guess(), []);

  return (
    <>
      <FormReactSelect
        labelKey="Select timezone"
        value={{ value: selectedTimezone, label: getTimeLabel(selectedTimezone) }}
        options={options}
        onChange={handleSelectChange}
        isSearchable
        blurInputOnSelect
        variant="small"
      />
      {getZoneOffset(guessZone) !== getZoneOffset(selectedTimezone) && (
        <small className="text-muted">{getIntl('Looks like you are in {{zone}} timezone', { zone: guessZone })}</small>
      )}
    </>
  );
};

export default SelectTimezone;
