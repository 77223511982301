import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { updateServiceTimeAction } from 'modules/tasks';

type Values = {
  [key in Tasks.ServiceType]: { [key in Type.AssetCategories]: { hours: number; mins: number } };
};

const useConfiguredFormik = (year: number) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Values>({
    initialValues: { serviceAndInspection: {}, serviceOnly: {}, repairOnly: {} } as {
      [key in Tasks.ServiceType]: { [key in Type.AssetCategories]: { hours: number; mins: number } };
    },

    onSubmit: values => {
      const serviceTimes = Object.keys(values).reduce(
        (acc: Omit<Tasks.ServiceTime, 'calculatedTime' | 'numberOfRepairs'>[], key: string) => {
          const arr = Object.entries((values as any)[key]).map(i => ({
            year: String(year), // TODO - must be integer
            assetCategoryCode: i[0] as Type.AssetCategories,
            serviceType: key as Tasks.ServiceType,
            scheduledTime: ((i[1] as any).hours || 0) * 60 + ((i[1] as any).mins || 0),
            co2e_scope_1_kg: (i[1] as any).co2e_scope_1_kg || 0,
            co2e_scope_2_kg: (i[1] as any).co2e_scope_2_kg || 0,
            co2e_scope_3_kg: (i[1] as any).co2e_scope_3_kg || 0,
          }));
          acc.push(...arr);
          return acc;
        },
        []
      );
      return dispatch(updateServiceTimeAction(serviceTimes));
    },
  });

  return formik;
};

export default useConfiguredFormik;
