import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useDispatch } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useLocale } from 'hooks';
import { setAddressSearchAction } from 'modules/map';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

interface Props {
  map: Map.MapboxMap | null;
}

const AddressSearch: React.FC<Props> = ({ map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, lng } = useLocale();

  useEffect(() => {
    if (!map) return;

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      countries: 'dk',
      language: lng,
      placeholder: getIntl('Search for address'),
      marker: false,
      flyTo: true,
      zoom: 19,
    });

    geocoder.on('result', () => {
      dispatch(setAddressSearchAction(false));
    });

    geocoder.on('results', () => {
      const noResults = document.querySelector('.mapbox-gl-geocoder--error.mapbox-gl-geocoder--no-results');
      if (noResults) {
        noResults.innerHTML = getIntl('Address is not found');
      }
    });

    map.addControl(geocoder);
    (document.querySelector('.mapboxgl-ctrl-geocoder--input') as HTMLInputElement | null)?.focus();

    return () => {
      map.removeControl(geocoder);
    };
  }, [dispatch, map, lng, getIntl]);

  return <StyledGeocoder />;
};

const StyledGeocoder = createGlobalStyle`

  .mapboxgl-ctrl-top-right {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
  }

  .mapboxgl-ctrl-geocoder {
  	font-family: 'Poppins', sans-serif;
    margin: 0 !important;
    width: 400px;
		background-color: transparent;
		border: none;
		box-shadow: none;

		svg {
			width: 18px;
			height: 18px;
			path {
				fill: #495057;
			}
    }

		.mapboxgl-ctrl-geocoder--input {
			padding-left: 40px !important;
			background: #ffffff;
			border: 1px solid var(--map-outline-color);
			box-shadow: 0px 0px 6px 0px #44366630;
			border-radius: 8px;
			padding: 15px;
      outline: none;

			&, &::placeholder {
				font-size: 12px;
        font-weight: 400;
				letter-spacing: 0.02em;
				color: #495057 !important;
			}

			&[aria-expanded='true'] {
				cursor: default;
			}

			&:hover {
				border-color: var(--map-active-color);
			}

			&:focus {
				border-color: var(--map-active-color);
			}
		}

		.mapboxgl-ctrl-geocoder--icon-close,
		.mapboxgl-ctrl-geocoder--icon-loading,
		.mapboxgl-ctrl-geocoder--button,
		.mapboxgl-ctrl-geocoder--powered-by {
      display: none !important;
		}

    .mapboxgl-ctrl-geocoder--icon-search {
			left: 14px;
			top: 9px;
    }

		.mapbox-gl-geocoder--no-results {
			padding: 10px 10px;
			color: var(--map-text-color-secondary);
			font-size: 12px;
			font-weight: 400;
			letter-spacing: 0.04em;
		}

    .suggestions {
			border: 1px solid var(--map-outline-color);
			border-radius: 8px;
			background-color: #f7fafd;
			user-select: none;
			box-shadow: 0px 2px 2px #44366630;

      .mapboxgl-ctrl-geocoder--suggestion-title {
        font-weight: 700;
				color: var(--map-text-color-primary);
      }

      .mapboxgl-ctrl-geocoder--suggestion-address {
        font-weight: 400;
				color: var(--map-text-color-secondary);
      }

			a {
				padding: 5px 10px;
				color: var(--map-text-color-primary);
				font-size: 12px;
				font-weight: 400;
				letter-spacing: 0.04em;
			}

			li.active > a {
				background-color: var(--map-active-color);
				
				.mapboxgl-ctrl-geocoder--suggestion-title {
					font-weight: 700;
					color: #fff;
				}

				.mapboxgl-ctrl-geocoder--suggestion-address {
					font-weight: 400;
					color: #fff;
				}
			}

			li:hover:not(.active) > a {
				background-color: var(--map-background-hover);
			}

    }
	}

`;

export default AddressSearch;
