import React, { useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useToggle, useEventPreventedExec } from 'hooks';
import {
  FormReactSelect,
  Modal,
  HiddenFormSubmit,
  FormInput,
  Checkbox,
  SelectSubComponentType,
} from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes, FunctionalityTypes, isProduction } from 'constants/index';

const LinkedFunctionalityModal: React.FC<Builder.ModalProps> = ({
  formField,
  assetCategoryCode,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const { getIntl } = useLocale();
  const isEditMode: boolean = Boolean(formField);

  const [show, toggleModal] = useToggle(true);

  const formik = useConfiguredFormik({ formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm, setFieldValue } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const functionalityTypesOptions = useMemo(
    () => [
      { value: 1, valuealt: FunctionalityTypes.ReplaceAsset, label: getIntl(FunctionalityTypes.ReplaceAsset) },
      { value: 2, valuealt: FunctionalityTypes.EditMetaData, label: getIntl(FunctionalityTypes.EditMetaData) },
    ],
    [getIntl]
  );

  const handleSelectChange = useCallback(
    (value: any, actionMeta: any) => {
      setFieldValue(actionMeta.name, value);
      setFieldValue('subComponentTypeId', null);
    },
    [setFieldValue]
  );

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={BuilderFieldsTypes.LinkedFunctionality}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey="Label (shown to user)"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <FormReactSelect
            labelKey="Functionality types"
            name="functionalityType"
            value={values.functionalityType}
            options={functionalityTypesOptions}
            onChange={handleSelectChange}
            onBlur={handleBlur}
            errorKey={
              Boolean(touched.functionalityType && errors.functionalityType) ? (errors.functionalityType as string) : ''
            }
          />
        </Form.Group>
        {values.functionalityType?.valuealt === FunctionalityTypes.ReplaceAsset && (
          <Form.Group>
            <SelectSubComponentType
              assetCategoryCode={assetCategoryCode || null}
              setFieldValue={(id: number | null) => {
                setFieldValue('subComponentTypeId', id);
              }}
              value={values.subComponentTypeId}
              errorKey={
                Boolean(touched.subComponentTypeId && errors.subComponentTypeId)
                  ? (errors.subComponentTypeId as string)
                  : ''
              }
            />
          </Form.Group>
        )}
        {!isProduction && (
          <Checkbox
            className="pl-0 icheck-success mt-4"
            name="scheduleAllowed"
            checked={values.scheduleAllowed}
            labelKey="Ask as to-do question"
            onChange={handleChange}
          />
        )}
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default LinkedFunctionalityModal;
