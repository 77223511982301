import React, { useCallback, useContext } from 'react';
import { MapContext } from 'context/map';
import { Button } from 'components/_common';
import { MapParams } from 'constants/index';
import { IconMinus } from '@utiligize/shared/resources';

const ZoomOutButton: React.FC = () => {
  const { map } = useContext(MapContext);

  const handleButtonClick = useCallback(() => map?.zoomTo(Math.round(map?.getZoom() - 1)), [map]);

  return (
    <Button
      variant="light"
      className="mt-1"
      disabled={(map?.getZoom() || 0) - MapParams.minZoom < 1}
      onClick={handleButtonClick}
      data-marker="main_map_zoom_minus"
      icon={<IconMinus />}
      tooltipKey="Zoom out"
      tooltipPlacement="left"
    />
  );
};

export default ZoomOutButton;
