import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  portfolioOptionsSelector,
  portfolioOptionSelector,
  portfolioOptionsFetchedSelector,
} from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectPortfolio: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const options = useSelector(portfolioOptionsSelector);
  const value = useSelector(portfolioOptionSelector);
  const isFetched = useSelector(portfolioOptionsFetchedSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): Promise<void> =>
      dispatch(setLayoutAction({ portfolioId: Number(option.value), scenarioId: null })),
    [dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!isFetched}
      isDisabled={!isFetched || !options?.length}
      labelKey="Portfolio"
      value={value || null}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
    />
  );
};

export default SelectPortfolio;
