import styled from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchPrimarySubstationsOptionsAction } from 'modules/options';
import { primarySubstationOptionsSelector } from 'modules/options/selectors';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['primarySubstationsIds'];
}

const SelectPrimarySubstations: React.FC<Props> = ({ name, value, onChange, isMulti, ...props }) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const options = useSelector(primarySubstationOptionsSelector);

  useEffect(() => {
    if (options || !portfolioId || !scenarioId) return;
    setIsLoading(true);
    //
    dispatch(fetchPrimarySubstationsOptionsAction({ portfolioId, scenarioId })).finally(() => setIsLoading(false));
  }, [options, portfolioId, scenarioId, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        const localizedOption = { ...option, label: getIntl(option.label) };
        if (value?.includes(Number(option.value))) acc.selectValues.push(localizedOption);
        acc.selectOptions.push(localizedOption);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options, getIntl, lng]);

  const primarySubstationValues = useMemo(
    () => (options || []).filter((o: Type.SelectOption) => value?.some(i => o.value === i)),
    [options, value]
  );

  const handleMultiSelectChange = useCallback(
    (inputOptions: Type.SelectOption[]) => {
      const value = inputOptions?.map(o => o.value) || null;
      onChange?.({ value }, { name });
    },
    [name, onChange]
  );

  if (isMulti) {
    return (
      <>
        <StyledMultiSelect
          options={(options as unknown as Option[]) || []}
          value={primarySubstationValues}
          onChange={handleMultiSelectChange}
          labelledBy="Select"
          ClearSelectedIcon={null}
          isLoading={isLoading}
        />
        <Form.Text className="text-muted">{getIntl('Primary substations')}</Form.Text>
      </>
    );
  }

  return (
    <FormReactSelect
      name={name}
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey=""
      mutedTextLabelKey="Primary substation"
      value={selectValues}
      options={selectOptions}
      onChange={onChange}
      variant="small"
      isClearable={false}
      {...props}
    />
  );
};

const StyledMultiSelect = styled(MultiSelect)`
  font-size: 0.9rem;
  --rmsc-border: ${props => props.theme?.colors?.grey100} !important;
  --rmsc-gray: #ccc !important;
  --rmsc-radius: 8px !important;
  --rmsc-h: 30px !important;

  .dropdown-content {
    z-index: 3; // Table head has z-index: 2 due to scroll on tasks table
  }
`;

export default SelectPrimarySubstations;
