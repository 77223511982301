import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.instructions;

export const instructionsCountSelector = createSelector(
  rootSelector,
  (instructions: Instructions.Root): number => instructions.count
);

export const instructionsItemsSelector = createSelector(
  rootSelector,
  (instructions: Instructions.Root): Instructions.Item[] => instructions.rows
);

export const instructionsBuilderDataSelector = createSelector(
  rootSelector,
  (instructions: Instructions.Root): Builder.Data => instructions.builderData
);
