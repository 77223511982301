import { createSelector } from 'reselect';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { sortAlphabeticallyFactory } from 'utils';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.newLoad;

export const DERsCountSelector = createSelector(rootSelector, (newLoad: NewLoad.Root): number => newLoad.DERsCount);

const DERsHashSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): NewLoad.Root['DERsHash'] => newLoad.DERsHash
);

export const DERsSplitBySectionHashSelector = createSelector(
  DERsHashSelector,
  (DERsHash: NewLoad.Root['DERsHash']): Record<NewLoad.DER['section'], NewLoad.DER[]> => {
    return Object.values(DERsHash)
      .sort(sortAlphabeticallyFactory<NewLoad.DER>('description'))
      .sort(sortAlphabeticallyFactory<NewLoad.DER>('section'))
      .reduce((acc: Record<NewLoad.DER['section'], NewLoad.DER[]>, der: NewLoad.DER) => {
        if (acc[der.section]) {
          acc[der.section].push(der);
        } else {
          acc[der.section] = [der];
        }
        return acc;
      }, {});
  }
);

export const DERsPreviewYearsHashSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): Type.Hash<number[]> => newLoad.DERsPreviewYearsHash
);

export const DERsPreviewYearsSelector = createSelector(
  [DERsPreviewYearsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (DERsPreviewYearsHash: Type.Hash<number[]>, portfolioId, scenarioId): number[] | null => {
    return DERsPreviewYearsHash[`${portfolioId}_${scenarioId}`] || null;
  }
);

export const DERSelectorFactory = (id: number | null) =>
  createSelector(DERsHashSelector, (DERsHash: Type.Hash<NewLoad.DER>): NewLoad.DER => DERsHash[`_${id}_`]);

export const DERsCategoriesHFetchedSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): boolean => newLoad.DERsCategoriesFetched
);

export const DERsCategoriesCountSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): number => newLoad.DERsCategoriesCount
);

const DERsCategoriesHashSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): Type.Hash<NewLoad.DERCategory> => newLoad.DERsCategoriesHash
);

export const DERsCategoriesSplitBySectionHashSelector = createSelector(
  DERsCategoriesHashSelector,
  (DERsCategoriesHash: Type.Hash<NewLoad.DERCategory>): Type.Hash<NewLoad.DERCategory[]> => {
    return Object.values(DERsCategoriesHash).reduce(
      (acc: Type.Hash<NewLoad.DERCategory[]>, der: NewLoad.DERCategory) => {
        if (acc[der.section]) {
          acc[der.section].push(der);
        } else {
          acc[der.section] = [der];
        }
        return acc;
      },
      {}
    );
  }
);

export const DERsCategoryHashSelectorFactory = (id: number | null) =>
  createSelector(
    DERsCategoriesHashSelector,
    (DERsCategoriesHash: Type.Hash<NewLoad.DERCategory>): NewLoad.DERCategory => DERsCategoriesHash[`_${id}_`]
  );

export const DERsProfilesCountSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): number => newLoad.DERsProfilesCount
);

export const DERsProfilesHashSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): NewLoad.Root['DERsProfilesHash'] => newLoad.DERsProfilesHash
);

export const DERsProfilesSelector = createSelector(
  [DERsProfilesHashSelector, portfolioIdSelector],
  (
    DERsProfilesHash: NewLoad.Root['DERsProfilesHash'],
    portfolioId: Layouts.Root['portfolioId']
  ): NewLoad.DERProfile[] | null => DERsProfilesHash[String(portfolioId)] || null
);

export const individualCustomersCountSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): number => newLoad.individualCustomersCount
);

const individualCustomersHashSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): Type.Hash<NewLoad.IndividualCustomer> => newLoad.individualCustomersHash
);

export const individualCustomersSelector = createSelector(
  individualCustomersHashSelector,
  (individualCustomersHash: Type.Hash<NewLoad.IndividualCustomer>): NewLoad.IndividualCustomer[] =>
    Object.values(individualCustomersHash)
);

export const individualCustomersSplitBySectionHashSelector = createSelector(
  individualCustomersHashSelector,
  (individualCustomersHash: Type.Hash<NewLoad.IndividualCustomer>): Type.Hash<NewLoad.IndividualCustomer[]> => {
    return Object.values(individualCustomersHash)
      .sort(sortAlphabeticallyFactory<NewLoad.IndividualCustomer>('connection_year'))
      .sort(sortAlphabeticallyFactory<NewLoad.IndividualCustomer>('section'))
      .reduce((acc: Type.Hash<NewLoad.IndividualCustomer[]>, customer: NewLoad.IndividualCustomer) => {
        if (acc[customer.section]) {
          acc[customer.section].push(customer);
        } else {
          acc[customer.section] = [customer];
        }
        return acc;
      }, {});
  }
);

export const individualCustomersSelectorFactory = (id: number | null) =>
  createSelector(
    individualCustomersHashSelector,
    (individualCustomersHash: Type.Hash<NewLoad.IndividualCustomer>): NewLoad.IndividualCustomer =>
      individualCustomersHash[`_${id}_`]
  );

export const DERsPhaseInCountSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): number => newLoad.DERsPhaseInCount
);

export const DERsPhaseInHashSelector = createSelector(
  rootSelector,
  (newLoad: NewLoad.Root): NewLoad.Root['DERsPhaseInHash'] => newLoad.DERsPhaseInHash
);

export const DERsPhaseInSelector = createSelector(
  [DERsPhaseInHashSelector, portfolioIdSelector],
  (
    DERsPhaseInHash: NewLoad.Root['DERsPhaseInHash'],
    portfolioId: Layouts.Root['portfolioId']
  ): NewLoad.DERPhaseIn[] | null => DERsPhaseInHash[String(portfolioId)] || null
);
