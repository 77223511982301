import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { voltageOptionsSelector } from 'modules/reliability/selectors';
import { fetchVoltageOptionsAction } from 'modules/reliability';
import { reliabilityVoltageOptionSelector } from 'modules/layouts/selectors';
import { setReliabilityVoltageOption } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectVoltage: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reliabilityVoltageOption = useSelector(reliabilityVoltageOptionSelector);
  const voltageOptions = useSelector(voltageOptionsSelector);

  useEffect(() => {
    if (voltageOptions.length) return;
    dispatch(fetchVoltageOptionsAction());
  }, [dispatch, voltageOptions.length]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption) => dispatch(setReliabilityVoltageOption(option)),
    [dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!voltageOptions.length}
      isDisabled={!voltageOptions.length}
      labelKey="Voltage"
      value={reliabilityVoltageOption}
      options={voltageOptions}
      onChange={handleSelectChange}
    />
  );
};

export default SelectVoltage;
