import React, { useMemo } from 'react';
import { useBuilderState } from 'hooks';
import {
  NumberInputInspectionModal,
  LinkedFormModal,
  LinkedFunctionalityModal,
  PhotoModal,
  TwoOptionModal,
  TextModal,
  TextInputModal,
  UrlModal,
  YouTubeVideoModal,
  PhotoCollectionModal,
  BinarySelectModal,
  DateModal,
  NumberInputModal,
  SingleSelectModal,
} from 'components/_builder';
import { BuilderFieldsTypes } from 'constants/index';

const BuilderFieldsRenderer: React.FC<Omit<Builder.ModalProps, 'assetCategoryCode'>> = ({ type, ...props }) => {
  const { selectValue } = useBuilderState();

  // Builder.ModalProps
  const FieldsComponentsMap: { [key in Builder.FieldsTypes]: React.FC<any> } = useMemo(
    () => ({
      [BuilderFieldsTypes.Date]: DateModal,
      [BuilderFieldsTypes.PhotoCollection]: PhotoCollectionModal,
      [BuilderFieldsTypes.SingleSelect]: SingleSelectModal,
      [BuilderFieldsTypes.MultipleSelect]: SingleSelectModal,
      [BuilderFieldsTypes.TextInput]: TextInputModal,
      [BuilderFieldsTypes.NumberInput]: NumberInputModal,
      [BuilderFieldsTypes.LargeTextInput]: TextInputModal,
      [BuilderFieldsTypes.TwoOption]: TwoOptionModal,
      [BuilderFieldsTypes.InspectionQuestion]: SingleSelectModal,
      [BuilderFieldsTypes.Text]: TextModal,
      [BuilderFieldsTypes.Photo]: PhotoModal,
      [BuilderFieldsTypes.NumberInputInspection]: NumberInputInspectionModal,
      [BuilderFieldsTypes.AssetPhoto]: PhotoModal,
      [BuilderFieldsTypes.BinarySelect]: BinarySelectModal,
      [BuilderFieldsTypes.LargeText]: TextInputModal,
      [BuilderFieldsTypes.YouTubeVideo]: YouTubeVideoModal,
      [BuilderFieldsTypes.URL]: UrlModal,
      [BuilderFieldsTypes.Image]: PhotoModal,
      [BuilderFieldsTypes.LinkedForm]: LinkedFormModal,
      [BuilderFieldsTypes.LinkedFunctionality]: LinkedFunctionalityModal,
      [BuilderFieldsTypes.Signature]: TextModal,
    }),
    []
  );
  const Component = FieldsComponentsMap[type as BuilderFieldsTypes] || null;
  return (
    Component && <Component {...props} type={type} assetCategoryCode={selectValue?.assetCategories?.code || null} />
  );
};

export default BuilderFieldsRenderer;
