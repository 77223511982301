import { useFormik } from 'formik';
import * as Yup from 'yup';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { FIELDS, BuilderFieldsTypes } from 'constants/index';

interface Values {
  label: string;
  min: number | string;
  max: number | string;
}

const useConfiguredFormik = ({
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[0]?.values?.[0]?.value || ''),
      min: Number(formField?.params?.[1]?.values?.[0]?.value) || '',
      max: Number(formField?.params?.[2]?.values?.[0]?.value) || '',
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Field can't be empty!"),
      min: Yup.number().min(1, 'Must be greater than zero'),
      max: Yup.number()
        .min(1, 'Must be greater than zero')
        .when('min', (min: number, schema: any) =>
          schema.test({
            test: (max: number) => {
              if (!min && !max) return true;
              return !!min && max > min;
            },
            message: 'The max value must be larger than the min value!',
          })
        ),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('PhotoCollectionModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[BuilderFieldsTypes.PhotoCollection]);
      _set(field, 'params[0].values[0].value', values.label);
      _set(field, 'params[1].values[0].value', values.min);
      _set(field, 'params[2].values[0].value', values.max);
      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
