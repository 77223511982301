import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { workPricesCountSelector, workPricesSelector } from 'modules/setup/selectors';
import { fetchWorkPricesAction, deleteWorkPriceAction } from 'modules/setup';
import { setLayoutAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDuplicate, IconDelete } from '@utiligize/shared/resources';

const TableWorkPrices: React.FC<{ inContentContainer?: boolean }> = ({ inContentContainer = false }) => {
  const { numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(workPricesCountSelector);
  const items = useSelector(workPricesSelector);

  const portfolioId = useSelector(portfolioIdSelector);

  const paginationType = PaginationType.WORK_PRICES;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchWorkPricesAction(params)).then(
        (action: Shared.ReduxAction<{ workPricesHash: Setup.Root['workPricesHash'] }>) =>
          Object.values(action.payload?.workPricesHash || {})
      ),
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteWorkPriceAction(id)),
        })
      );
    },
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ investmentWorkPriceModalMeta: { id } }));
    },
    [dispatch]
  );

  const handleDuplicateBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (id) dispatch(setLayoutAction({ investmentWorkPriceModalMeta: { id, isDuplicate: true } }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      containerClassName={classNames({ 'border-top-0 px-0': inContentContainer })}
      isDownloadCSVEnabled
      // maxHeight="calc(100vh - 220px)"
      waitForDependencies={!Boolean(filters?.componentPriceScenarioId) || !portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map?.((item: Setup.WorkPrice) => (
        <tr key={item.work_type_price_id}>
          <td>{item.description}</td>
          <td>{item.grid_zone_description}</td>
          <td>{numberFormat(item.price, { fallback: '-' })}</td>
          <td>{item.price_per_unit || ''}</td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.work_type_price_id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              tooltipKey="Duplicate"
              icon={<IconDuplicate />}
              data-id={item.work_type_price_id}
              marginLeft
              onClick={handleDuplicateBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.work_type_price_id}
              marginLeft
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableWorkPrices;
