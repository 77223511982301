import styled from 'styled-components';
import React from 'react';
import classNames from 'classnames';
import { OverlayTriggerTooltip } from 'components/_common';
import { useLocale } from 'hooks';
import useTableData from './useTableData';
import { Languages } from 'constants/index';

interface Props {
  isTasksGanttChart?: boolean;
}

const TableHeader: React.FC<Props> = ({ isTasksGanttChart = false }) => {
  const { getIntl, lng } = useLocale();

  const { weeksAmount, daysFromPreviousYear, currentWeekNumber, currentYear, year, currentDayNumber } = useTableData();

  return (
    <thead>
      <tr>
        <th
          rowSpan={2}
          className={classNames('sticky first', { 'first-170': isTasksGanttChart && lng === Languages.DA })}
        >
          {getIntl(isTasksGanttChart ? 'Task' : 'Employee')}
        </th>
        <th
          rowSpan={2}
          className={classNames('sticky second', { 'second-225': isTasksGanttChart && lng === Languages.DA })}
        >
          {getIntl(isTasksGanttChart ? 'Name' : 'Task')}
        </th>
        <th rowSpan={2} className="sticky third">
          {getIntl('Start date')}
        </th>
        <th rowSpan={2} className="sticky fourth">
          {getIntl('End date')}
        </th>
        {Array.from(Array(weeksAmount).keys()).map((item: number) => {
          const currentWeek = currentWeekNumber === item + 1 && currentYear === year;
          return (
            <th
              key={item}
              id={currentWeek ? 'current-week' : undefined}
              className={classNames('week', { 'bg-success': currentWeek })}
              colSpan={7}
            >
              {getIntl('Week')} {item + Number(Boolean(!daysFromPreviousYear))}
            </th>
          );
        })}
      </tr>
      <tr>
        {Array.from(Array(weeksAmount).keys()).map((item: number) => {
          return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
            (dayStr: string, dayIndex: number) => {
              const dayNumber = item * 7 + dayIndex + 1 - daysFromPreviousYear;
              return (
                <OverlayTriggerTooltip
                  key={dayNumber}
                  placement="bottom"
                  overlayId={String(dayNumber)}
                  overlayChildren={getIntl(dayStr)}
                >
                  <StyledWeekDay
                    className={classNames({
                      'bg-success': currentDayNumber === dayNumber && currentYear === year,
                    })}
                  >
                    {getIntl(dayStr).slice(0, 1)}
                  </StyledWeekDay>
                </OverlayTriggerTooltip>
              );
            }
          );
        })}
      </tr>
    </thead>
  );
};

const StyledWeekDay = styled.th`
  && {
    padding: 5px 0 !important;
    font-weight: 100;
    text-align: center;
    font-size: 0.9em;
    color: darkgrey;
    border-bottom-width: 0 !important;
    cursor: pointer;
  }
`;

export default TableHeader;
