import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { manualsItemsSelector } from 'modules/manuals/selectors';
import { fetchManualsAction, deleteManualByIdAction } from 'modules/manuals';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { ManualsModal } from 'components/_modals';
import { Alert, Button, Spinner } from 'components/_common';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';
import Card from './Card';

interface Props {
  uuid: string;
}

const TabDataManuals: React.FC<Props> = ({ uuid }) => {
  const [loading, setLoading] = useState(true);
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const items = useSelector(manualsItemsSelector);

  useEffect(() => {
    dispatch(fetchManualsAction({ uuid })).finally(() => setLoading(false));
  }, [dispatch, uuid]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setLayoutAction({ manualsModalId: id }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteManualByIdAction(id)) }));
    },
    [dispatch]
  );

  if (loading) return <Spinner isInFullHeightContainer />;
  if (!items.length)
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;

  return (
    <>
      {items.map((item: Manuals.Item, index: number) => (
        <Card key={item.id} marginBottom={items.length - 1 !== index}>
          <Row className="mb-1">
            <Col>
              <label>{getIntl('Name')}:</label>
              <span>{item.name}</span>
            </Col>
            <Col>
              <label>{getIntl('Date')}:</label>
              <span>{dateFormat(item.createdAt)}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <label>{getIntl('Asset category')}:</label>
              <span>
                {[...new Set([item.categoryName, ...item.assignedModels.map(i => i.assetcategoryName)].filter(Boolean))]
                  .map((name: any) => getIntl(name))
                  .join(', ') || '-'}
              </span>
            </Col>
            <Col>
              <label>{getIntl('Asset code')}:</label>
              <span>{item.assetCode || '-'}</span>
            </Col>
          </Row>

          <StyledBottomControls>
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              data-name={item.name}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </StyledBottomControls>
        </Card>
      ))}
      <ManualsModal hideButton />
    </>
  );
};

const StyledBottomControls = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export default TabDataManuals;
