import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useLocale } from 'hooks';
import { tasksCountSelector, tasksSelector } from 'modules/tasks/selectors';
import { fetchTasksAction, deleteTaskAction } from 'modules/tasks';
import { setTaskModalMetaAction } from 'modules/layouts';
import { DataTable, ShowUsersOverlay, TaskTypeBadge, IconDeleteTableAction, Button } from 'components/_common';
import { Routes, TaskTypes, PaginationType } from 'constants/index';
import { IconEdit } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const TasksTable: React.FC<Props> = ({ maxTableHeight }) => {
  const { getIntl, dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(tasksCountSelector);
  const items = useSelector(tasksSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTasksAction({ ...params, includeExternalTime: true, includeExpectedTimeFields: true })).then(
        (action: Shared.ReduxAction<{ tasksHash: Type.Hash<Tasks.Task> }>) =>
          Object.values(action.payload?.tasksHash || {}).map(item => {
            if (!params.skipStoreUpdate) return item;
            return {
              [getIntl('Name')]: item.name,
              [getIntl('Start date')]: item.startDate ? dateFormat(item.startDate) : '-',
              [getIntl('End date')]: item.endDate ? dateFormat(item.endDate) : '-',
              [getIntl('Months')]: item.taskMonths?.length
                ? item.taskMonths.map((month: string) => getIntl(month)).join(', ')
                : '-',
              [getIntl('Responsible users')]: item.responsibleUsers?.length
                ? item.responsibleUsers.map((user: Users.User) => user.fullName).join(', ')
                : '-',
              [getIntl('Created by')]: item.createdBy?.fullName ? item.createdBy?.fullName : '-',
              [getIntl('Internal registered time')]: `${
                typeof item.registeredTime === 'number'
                  ? `${numberFormat(item.registeredTime)} ${getIntl('Hours')}`
                  : '-'
              }`,
              [getIntl('External registered time')]: `${
                typeof item.navisionRegisteredTime === 'number'
                  ? `${numberFormat(item.navisionRegisteredTime)} ${getIntl('Hours')}`
                  : '-'
              }`,
            };
          })
      ),
    [dispatch, getIntl, dateFormat, numberFormat]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setTaskModalMetaAction({ id }));
    },
    [dispatch]
  );

  const handleDetailsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      dispatch(push(generatePath(Routes.Tasks, { id })));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.TASKS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      hover
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.Task) => (
        <tr key={item.id} data-id={item.id} onClick={handleDetailsBtnClick}>
          <td>
            <TaskTypeBadge id={item.id} type={item.type} className="mr-2" />
            {item.name}
          </td>
          {item.type === TaskTypes.Autogenerated ? (
            <td colSpan={2} className="text-center">
              {item.taskMonths?.map(month => getIntl(month)).join(', ')}
            </td>
          ) : (
            <>
              <td className="text-nowrap">{item.startDate ? dateFormat(item.startDate) : '-'}</td>
              <td className="text-nowrap">{item.endDate ? dateFormat(item.endDate) : '-'}</td>
            </>
          )}
          <td className="text-nowrap">{item.createdBy?.fullName || '-'}</td>
          <td className="text-nowrap">
            <ShowUsersOverlay
              popoverId={item.id}
              responsibleUserIds={item.responsibleUsers?.map(user => user.id)}
              showEmptyPopup
            />
          </td>
          <td className="text-center">
            {item.registeredTime ? `${numberFormat(item.registeredTime)} ${getIntl('Hours')}` : '-'}
          </td>
          <td className="text-center">
            {item.navisionRegisteredTime ? `${numberFormat(item.navisionRegisteredTime)} ${getIntl('Hours')}` : '-'}
          </td>
          <td className="text-nowrap text-right">
            <ShowUsersOverlay popoverId={item.id} userIds={item.assignedUsers.map(user => user.id)} showEmptyPopup />
            <Button
              variant="primary"
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              marginLeft
              onClick={handleEditBtnClick}
              size="small"
              aria-label="Edit task"
            />
            <IconDeleteTableAction id={item.id} name={item.name || ''} marginLeft action={deleteTaskAction} />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TasksTable;
