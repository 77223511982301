import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { uploadIndividualCustomersExcelAction } from 'modules/newLoad';

const useConfiguredFormik = ({ toggleModal }: { toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<{
    append: boolean;
    file: File | null;
  }>({
    initialValues: {
      append: true,
      file: null,
    },

    validationSchema: Yup.object().shape({
      file: Yup.mixed().required('Please upload individual customers excel first'),
    }),

    onSubmit: values => {
      const formData: FormData = new FormData();
      formData.append('f', values.file as Blob);
      formData.append('append', values.append as unknown as string);

      return dispatch(uploadIndividualCustomersExcelAction(formData)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
