import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocale } from 'hooks';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { BuilderFieldsTypes, FIELDS, FunctionalityTypes } from 'constants/index';

interface FunctionalityType {
  value: number;
  valuealt: Builder.FunctionalityTypes;
  label: Builder.FunctionalityTypes;
}

interface Values {
  label: string;
  functionalityType: FunctionalityType | null;
  scheduleAllowed: boolean;
  subComponentTypeId: number | null;
}

const useConfiguredFormik = ({
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const { getIntl } = useLocale();
  const firstValue = Number(formField?.params?.[0]?.values?.[0]?.value);

  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[1]?.values?.[0]?.value || ''),
      functionalityType: firstValue
        ? {
            value: firstValue,
            valuealt: (formField?.params?.[0]?.values?.[0]?.valuealt || '') as Builder.FunctionalityTypes,
            label: getIntl(formField?.params?.[0]?.values?.[0]?.valuealt || '') as Builder.FunctionalityTypes,
          }
        : null,
      scheduleAllowed: Boolean(formField?.params?.[2]?.values?.[0]?.value as unknown as boolean),
      subComponentTypeId: (formField?.params?.[3]?.values?.[0]?.value as number) || null,
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Field can't be empty!"),
      functionalityType: Yup.object().nullable().required("Field can't be empty!"),
      subComponentTypeId: Yup.number()
        .nullable()
        .when('functionalityType', (functionalityType: FunctionalityType | null, schema: any) =>
          schema.test({
            test: (subComponentTypeId: number) => {
              return !!subComponentTypeId || functionalityType?.valuealt !== FunctionalityTypes.ReplaceAsset;
            },
            message: "Field can't be empty!",
          })
        ),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('LinkedFunctionalityModal create handler must be passed');
      // subComponentTypeId support for legacy forms
      if (formField && !formField.params[3]) {
        formField.params.push(FIELDS[BuilderFieldsTypes.LinkedFunctionality].params[3]);
      }
      const field = _cloneDeep(formField || FIELDS[BuilderFieldsTypes.LinkedFunctionality]);
      _set(field, 'params[0].values[0].value', values.functionalityType?.value);
      _set(field, 'params[0].values[0].valuealt', values.functionalityType?.valuealt);
      _set(field, 'params[1].values[0].value', values.label);
      _set(field, 'params[2].values[0].value', values.scheduleAllowed);
      _set(field, 'params[3].values[0].value', values.subComponentTypeId);

      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
