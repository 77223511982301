import { withIntl } from 'hoc';
import {
  default as SharedChart,
  Colors,
  getBaseOptions,
  ChartDataProps,
  transformAction,
  getIntlExporting,
  getTooltipCircle,
} from '@utiligize/shared/components/Chart';

const Chart = withIntl(SharedChart);

export default Chart;

export { Colors, getBaseOptions, transformAction, getIntlExporting, getTooltipCircle };
export type { ChartDataProps };
