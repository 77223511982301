import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { fetchAssetCodeAutoCompleteAction } from 'modules/assets';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  autoFocus?: boolean;
  labelKey?: string;
  values: string[];
  onChange: (values: string[] | null) => void;
  action?: (query: string) => Promise<Shared.ReduxAction<string[]>>;
}

const StringsInputAutocomplete: React.FC<Props> = ({
  values,
  onChange,
  action = fetchAssetCodeAutoCompleteAction,
  isMulti = false,
  placeholderKey = 'Type to search',
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Type.SelectOption[] | null>(null);

  const selectValues = useMemo(() => values.map((value: string) => ({ label: value, value })), [values]);

  const debounced = useDebouncedCallback((query: string) => {
    setIsLoading(true);
    dispatch(action(query))
      .then((action: Shared.ReduxAction<string[]>) => {
        setOptions(
          action.payload
            .filter((code: string) => !values.includes(code))
            .map((code: string) => ({ value: code, label: code }))
        );
      })
      .finally(() => setIsLoading(false));
  }, 1000);

  useEffect(() => debounced.cancel, [debounced.cancel]);

  const handleInputChange = useCallback(
    (newValue: string) => {
      setInputValue(newValue);
      // 'GL-' check must be clarified
      if (newValue.length < 3 || newValue === 'GL-') {
        setOptions(null);
        setIsLoading(false);
        debounced.cancel();
      } else {
        debounced(newValue);
      }
    },
    [debounced]
  );

  const handleSelectChange = useCallback(
    (input: Type.SelectOption[] | Type.SelectOption) => {
      if (Array.isArray(input)) {
        onChange(input.map((i: Type.SelectOption) => String(i.value)));
      } else {
        onChange(input?.value ? [String(input.value)] : null);
      }
    },
    [onChange]
  );

  return (
    <FormReactSelect
      {...props}
      value={selectValues}
      options={options || []}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isClearable
      isMulti={isMulti}
      isLoading={isLoading}
      placeholderKey={placeholderKey}
      menuIsOpen={Boolean(options)}
      onChange={handleSelectChange}
    />
  );
};

export default StringsInputAutocomplete;
