import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { generatePath, Link } from 'react-router-dom';
import {
  SelectAssetCategories,
  SelectSubComponentType,
  SelectLogMaintenanceForms,
  TaskStatusBadge,
} from 'components/_common';
import { TaskTypes, Routes } from 'constants/index';

const TaskDetailsMeta: React.FC<{ task: Tasks.Task }> = ({ task }) => {
  const { getIntl, dateFormat } = useLocale();
  const isTaskTypeAutogenerated: boolean = task.type === TaskTypes.Autogenerated;
  const assetCategoryCode = task.assetCategoryCode || task.assetCodes?.[0]?.slice(0, 2) || null;

  const metaData = useMemo(
    () =>
      [
        { labelKey: 'Name', value: task.name || '-' },
        !isTaskTypeAutogenerated && {
          labelKey: 'Start date',
          value: task.startDate ? dateFormat(task.startDate) : '-',
        },
        !isTaskTypeAutogenerated && {
          labelKey: 'End date',
          value: task.endDate ? dateFormat(task.endDate) : '-',
        },
        {
          labelKey: 'Created by',
          value: task.createdBy?.fullName || '-',
        },
        isTaskTypeAutogenerated && {
          labelKey: 'Months',
          value: task.taskMonths?.map(month => getIntl(month)).join(', '),
        },
        {
          labelKey: 'Finish date',
          value: task.finishDate ? dateFormat(task.finishDate!) : '-',
        },
        {
          labelKey: 'Expected time',
          value: task.expectedTime ? `${task.expectedTime} ${getIntl(task.expectedTime <= 1 ? 'Hour' : 'Hours')}` : '-',
        },
        {
          labelKey: 'Registered time',
          value: task.registeredTime
            ? `${task.registeredTime} ${getIntl(task.registeredTime <= 1 ? 'Hour' : 'Hours')}`
            : '-',
        },
        {
          labelKey: 'ERP registered time',
          value: task.navisionRegisteredTime
            ? `${task.navisionRegisteredTime} ${getIntl(task.navisionRegisteredTime <= 1 ? 'Hour' : 'Hours')}`
            : '-',
        },
        { labelKey: 'Finished by', value: task.finishedBy?.fullName || '-' },
        { labelKey: 'Email', value: task.finishedBy?.email || '-' },
        {
          labelKey: 'Status',
          value: <TaskStatusBadge status={task.status} />,
        },
        { labelKey: 'Department', value: task.departmentName || '-' },
        { labelKey: 'App action', value: task.appAction ? getIntl(task.appAction) : '-' },
        { labelKey: 'Type', value: task.type ? getIntl(task.type) : '-' },
        {
          labelKey: 'Asset category',
          value: task.assetCategoryCode ? (
            <SelectAssetCategories labelKey="" value={task.assetCategoryCode} isDisabled />
          ) : (
            '-'
          ),
        },
        {
          labelKey: 'Asset codes',
          value: task.assetData.length
            ? task.assetData.map(({ assetCode, assetUuid }, index: number) => {
                const Elem = (
                  <>
                    {assetCode}
                    {task.assetData.length - 1 === index ? '' : ', '}
                  </>
                );
                return assetUuid ? <Link to={generatePath(Routes.Map, { uuid: assetUuid })}>{Elem}</Link> : Elem;
              })
            : '-',
        },
        {
          labelKey: 'Installation numbers',
          value: task.installationNumbers.length ? task.installationNumbers.join(', ') : '-',
        },
        {
          labelKey: 'Sub-component type',
          value:
            task.assetSubcomponentTypeId && assetCategoryCode ? (
              <SelectSubComponentType
                disabled
                labelKey=""
                assetCategoryCode={assetCategoryCode}
                value={task.assetSubcomponentTypeId || null}
              />
            ) : (
              '-'
            ),
        },
        {
          labelKey: 'Form',
          value:
            task.formId && assetCategoryCode ? (
              <SelectLogMaintenanceForms
                labelKey=""
                assetCategoryCode={assetCategoryCode}
                value={task.formId}
                disabled
              />
            ) : (
              '-'
            ),
        },
        { labelKey: 'Description', value: task.description || '-' },
        { labelKey: 'Source', value: task.source ? getIntl(task.source) : '-' },
        { labelKey: 'Task number', value: task.taskNumber || '-' },
      ].filter(Boolean),
    [isTaskTypeAutogenerated, task, assetCategoryCode, dateFormat, getIntl]
  );

  return (
    <dl className="row">
      {metaData.map((i: any) => (
        <React.Fragment key={i.labelKey}>
          <dd className="col-sm-5 d-flex align-items-center">{getIntl(i.labelKey)}:</dd>
          <dd className="col-sm-7">{i.value}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

export default TaskDetailsMeta;
