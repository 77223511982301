import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { DERsPreviewYearsSelector } from 'modules/newLoad/selectors';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import ModalDER from './ModalDER';
import ModalSectionCategory from './ModalSectionCategory';
import ModalUploadIndividualCustomers from './ModalUploadIndividualCustomers';
import ModalUploadProfile from './ModalUploadProfile';
import ModalUploadPhaseIn from './ModalUploadPhaseIn';
import TableDERsCategories from './TableDERsCategories';
import TableManageDERs from './TableManageDERs';
import TableIndividualCustomers from './TableIndividualCustomers';
import TableDERsProfiles from './TableDERsProfiles';
import TableDERsPhaseIn from './TableDERsPhaseIn';
import { DataTableTemplate, Tabs, Tab, SelectDERsPreviewYear, ButtonDownload, Spinner } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';

const NewLoadManageDERsTabs: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.NEW_LOAD_DERS));
  const portfolioId = useSelector(portfolioIdSelector);
  const years = useSelector(DERsPreviewYearsSelector);
  const isCategoriesTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadCategories));
  const isManageDERsTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoad));
  const isIndividualCustomersTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadIndividualCustomers));
  const isProfilesTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadProfiles));
  const isPhaseInTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadPhaseIn));

  const handleChangeDERsPreviewYearSelect = useCallback(
    (value: number) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.NEW_LOAD_DERS,
          modifier: { filters: { ...filters, year: value } },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate>
      {isCategoriesTabActive && <ModalSectionCategory />}
      {isManageDERsTabActive && (
        <Row>
          <Col xs={3} xl={2}>
            <SelectDERsPreviewYear
              labelKey=""
              isSearchable={false}
              value={filters?.year || null}
              onChange={handleChangeDERsPreviewYearSelect}
              variant="small"
            />
          </Col>
          <Col xs="auto">
            <ModalDER />
          </Col>
        </Row>
      )}
      {isIndividualCustomersTabActive && (
        <>
          <ModalUploadIndividualCustomers />
          <ButtonDownload
            labelKey="Download template"
            fileNameLocaleStr="Individual customers excel template"
            link={`scenarios/individual_customers_excel?portfolio_id=${portfolioId}`}
            isAssetLifeAPI
            marginLeft
          />
        </>
      )}
      {isProfilesTabActive && (
        <>
          <ModalUploadProfile />

          <ButtonDownload
            labelKey="Download template"
            fileNameLocaleStr="Profile template"
            link="scenarios/der_profile_excel"
            isAssetLifeAPI
            marginLeft
          />
        </>
      )}
      {isPhaseInTabActive && (
        <>
          <ModalUploadPhaseIn />

          <ButtonDownload
            labelKey="Download template"
            fileNameLocaleStr="Phase-in template"
            link="scenarios/der_phase_in_excel"
            isAssetLifeAPI
            marginLeft
          />
        </>
      )}

      <Tabs mountOnEnter unmountOnExit>
        <Tab id="tab_new_load_categories" eventKey={Routes.NewLoadCategories} title={getIntl('Categories')}>
          <TableDERsCategories />
        </Tab>
        <Tab id="tab_new_load_ders" eventKey={Routes.NewLoad} title={getIntl('DERs')}>
          {!years ? <Spinner inCard isInFullHeightContainer /> : <TableManageDERs year={filters?.year as number} />}
        </Tab>
        <Tab
          id="tab_new_load_individual_customers"
          eventKey={Routes.NewLoadIndividualCustomers}
          title={getIntl('Individual customers')}
        >
          <TableIndividualCustomers />
        </Tab>
        <Tab id="tab_new_load_profiles" eventKey={Routes.NewLoadProfiles} title={getIntl('Profiles')}>
          <TableDERsProfiles />
        </Tab>
        <Tab id="tab_new_load_phase_in" eventKey={Routes.NewLoadPhaseIn} title={getIntl('Phase-in')}>
          <TableDERsPhaseIn />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default NewLoadManageDERsTabs;
