import styled from 'styled-components';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { SmartMeterActionNames } from 'constants/index';

interface Props {
  action: Meters.SmartMeterActionNames;
}

const TaskStatusVariantMap: { [key in Meters.SmartMeterActionNames]: string } = {
  [SmartMeterActionNames.NEW]: 'info',
  [SmartMeterActionNames.REMOVE]: 'warning',
  [SmartMeterActionNames.REPLACE]: 'success',
};

const SmartMeterActionBadge: React.FC<Props> = ({ action }) => {
  const { getIntl } = useLocale();
  return <StyledBadge variant={TaskStatusVariantMap[action] || 'info'}>{getIntl(action)}</StyledBadge>;
};

const StyledBadge = styled(Badge)`
  font-size: 85%;
  font-weight: 500;
`;

export default SmartMeterActionBadge;
