import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { sendFileAction } from 'modules/forms';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { BuilderFieldsTypes, FIELDS } from 'constants/index';

interface Values {
  label: string;
  name: string;
  link: string;
}

interface Props extends Builder.ModalFormikProps {
  file: File | undefined;
}

const useConfiguredFormik = ({ type, file, formField, addField, onChangeModal, isEditMode, toggleModal }: Props) => {
  const dispatch: any = useDispatch();

  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[0]?.values?.[0]?.value || ''),
      name: String(formField?.params?.[1]?.values?.[0]?.value || ''),
      link: String(formField?.params?.[1]?.values?.[0]?.valuealt || ''),
    },

    validationSchema: Yup.object().shape({
      ...(type === BuilderFieldsTypes.AssetPhoto && { label: Yup.string().required("Field can't be empty!") }),
      link: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('PhotoModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[type!]); // AssetPhoto or Photo or Image
      const formData = new FormData();
      if (file) {
        formData.append('file', file as File);
        formData.append('name', values.name as string);
        return dispatch(sendFileAction(formData)).then((action: any) => {
          const { link, name } = action.payload;
          _set(field, 'params[0].values[0].value', values.label);
          _set(field, 'params[1].values[0].value', name);
          _set(field, 'params[1].values[0].valuealt', link);
          handler(field);
          toggleModal();
        });
      } else {
        _set(field, 'params[0].values[0].value', values.label);
        handler(field);
        toggleModal();
      }
    },
  });

  return formik;
};

export default useConfiguredFormik;
