import { useFormik } from 'formik';
import * as Yup from 'yup';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { FIELDS } from 'constants/index';

interface Values {
  label: string;
  variants: Builder.Variant[];
}

const useConfiguredFormik = ({
  type,
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[0]?.values?.[0]?.value || ''),
      variants: formField?.params?.[1]?.variants || (FIELDS[type!].params[1].variants as Builder.Variant[]),
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Field can't be empty!"),
      variants: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required("Field can't be empty!"),
        })
      ),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('SingleSelectModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[type!]); // SingleSelect or MultiSelect
      _set(field, 'params[0].values[0].value', values.label);
      const variants = values.variants.map((variant: Builder.Variant, index: number) => ({
        ...variant,
        index,
        num: index,
      }));
      _set(field, 'params[1].variants', variants);
      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
