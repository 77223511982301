import { createSelector } from 'reselect';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { PaginationType } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.investment;

export const summaryPlanItemsSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['summaryPlanItems'] => investment.summaryPlanItems
);

export const summaryPlanPaginatedItemsSelector = createSelector(
  [rootSelector, paginationSelectorFactory(PaginationType.SUMMARY_PLAN)],
  (investment: Investment.Root, { limit, offset }): Investment.Root['summaryPlanItems'] =>
    investment.summaryPlanItems.slice(offset, offset + limit)
);

export const summaryPlanCountSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): number => investment.summaryPlanCount
);

export const summaryPlanMetaCurrencySelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['summaryPlanMetaCurrency'] =>
    investment.summaryPlanMetaCurrency || 'DKK'
);

export const detailedPlanItemsSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['detailedPlanItems'] => investment.detailedPlanItems
);

export const detailedPlanCountSelector = createSelector(
  rootSelector,
  (investment: Investment.Root): number => investment.detailedPlanCount
);

export const detailedPlanMetaCurrencySelector = createSelector(
  rootSelector,
  (investment: Investment.Root): Investment.Root['detailedPlanMetaCurrency'] =>
    investment.detailedPlanMetaCurrency || 'DKK'
);

export const summaryPlanGroupOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.summaryPlanGroupOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const assetCategoriesOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.assetCategoriesOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const replacementReasonOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.replacementReasonOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const gridZonesOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.gridZonesOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const voltageLevelsOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector, scenarioIdSelector],
  (investment: Investment.Root, portfolioId, scenarioId): Type.SelectOption[] | null =>
    investment.voltageLevelsOptionsHash[`${portfolioId}_${scenarioId}`] || null
);
