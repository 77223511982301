import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioParamsOptionsAction } from 'modules/reliability';
import { scenarioParamsOptionsSelector } from 'modules/reliability/selectors';
import { reliabilityScenarioParamsOptionSelector } from 'modules/layouts/selectors';
import { setReliabilityScenarioParamsOption } from 'modules/layouts';
import { FormReactSelect } from 'components/_common';

const SelectScenarioParams: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reliabilityScenarioParamsOption = useSelector(reliabilityScenarioParamsOptionSelector);
  const options = useSelector(scenarioParamsOptionsSelector);

  useEffect(() => {
    if (options.length) return;
    dispatch(fetchScenarioParamsOptionsAction());
  }, [dispatch, options]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption) => dispatch(setReliabilityScenarioParamsOption(option)),
    [dispatch]
  );

  return (
    <FormReactSelect
      isLoading={!options.length}
      isDisabled={!options.length}
      labelKey="Scenario param"
      value={reliabilityScenarioParamsOption}
      options={options}
      onChange={handleSelectChange}
    />
  );
};

export default SelectScenarioParams;
