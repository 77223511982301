import styled from 'styled-components';
import { PanelResizeHandle } from 'react-resizable-panels';
import React from 'react';
import { IconSplit } from '@utiligize/shared/resources';

const ResizeHandle = ({ id, direction = 'col-resize' }: { id: string; direction?: 'col-resize' | 'row-resize' }) => (
  <StyledPanelResizeHandle id={id} direction={direction}>
    <div>
      <IconSplit />
    </div>
  </StyledPanelResizeHandle>
);

const StyledPanelResizeHandle = styled(PanelResizeHandle as any)<{ direction: 'col-resize' | 'row-resize' }>`
  width: 100%;
  flex: 0 0 2px;
  position: relative;
  outline: none;
  background-color: #dee2e6;
  cursor: ${props => props.direction}!important;
  transition: background-color 0.3s ease-in;

  &:hover {
    background-color: ${props => props.theme.colors.purple800};
  }

  > div {
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px;
    z-index: 1001;

    > svg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: calc(50% - 15px);
      left: -15px;
      fill: ${props => props.theme.colors.purple800};
    }

    ${props =>
      props.direction === 'row-resize' &&
      `
        width: 100%;
        height: 20px;
        top: -10px;
        left: 0;

        > svg {
          transform: rotate(90deg);
          left: calc(50% - 15px);
          top: -15px;
        }
      `}
  }
`;

export default ResizeHandle;
