import styled from 'styled-components';
import React, { useCallback, memo } from 'react';
import { useLocale } from 'hooks';
import { Tabs, Tab, TabScreen } from 'react-tabs-scrollable';
import { getStorageItem, setStorageItem } from 'utils';
import { StorageKeys } from 'constants/index';
import { IconChevronLeft, IconChevronRight } from '@utiligize/shared/resources';
import 'react-tabs-scrollable/dist/rts.css';

interface Props {
  tabsLabelKeys: string[];
  storageKey: StorageKeys;
  children: (activeTab: number) => React.ReactElement;
  className?: string;
}

const ScrollableTabs: React.FC<Props> = memo(({ tabsLabelKeys, storageKey, children, className }) => {
  const { getIntl } = useLocale();
  const initialIndex = Number(getStorageItem(storageKey) || 0);
  const [activeTab, setActiveTab] = React.useState(tabsLabelKeys.length > initialIndex ? initialIndex : 0);

  const onTabClick = useCallback(
    (e: React.BaseSyntheticEvent, index: number) => {
      setStorageItem({ [storageKey]: index });
      setActiveTab(index);
    },
    [storageKey]
  );

  return (
    <StylesScrollableTabsContainer className={className}>
      <Tabs
        activeTab={tabsLabelKeys.length > activeTab ? activeTab : 0}
        onTabClick={onTabClick}
        leftBtnIcon={(<IconChevronLeft />) as any}
        rightBtnIcon={(<IconChevronRight />) as any}
      >
        {tabsLabelKeys.map(labelKey => (
          <Tab key={labelKey}>{getIntl(labelKey)}</Tab>
        ))}
      </Tabs>
      {children(activeTab)}
    </StylesScrollableTabsContainer>
  );
});

const StylesScrollableTabsContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .rts___tabs___container {
    display: inline-grid;
    grid-template-columns: auto auto auto;
    background: ${props => props.theme.colors.white};
    border-bottom: solid 1px ${props => props.theme.colors.grey100};
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
  }

  .rts___tabs {
    padding: 5px;
  }

  .rts___nav___btn___container + .rts___tabs {
    padding-left: 0;
    padding-right: 0;
  }

  .rts___nav___btn {
    border: none;
    background-color: ${props => props.theme.colors.white}!important;

    & > svg {
      width: 25px;
      height: 25px;
      fill: ${props => props.theme.colors.grey200};
    }

    &:hover > svg {
      fill: ${props => props.theme.colors.purple800};
    }
  }

  .rts___tab.rts___btn {
    border: none;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    padding: 5px 10px;
    margin: 0;
    color: ${props => props.theme.colors.grey900};

    &.rts___tab___selected {
      color: ${props => props.theme.colors.purple800};
      cursor: default;
      background-color: ${props => props.theme.colors.purple50};
      box-shadow: none;
    }

    &:not(.rts___tab___selected):hover {
      color: ${props => props.theme.colors.purple800};
    }
  }
`;

export const StyledTabScreen = styled(TabScreen)<{ $absolute?: boolean; $paddings?: boolean }>`
  ${props =>
    props.$absolute
      ? ` 
        position: absolute;
        top: 42px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: ${props.$paddings ? '0.5rem 0.5rem 20px' : 0};
        `
      : ` 
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0.5rem 0.5rem 20px;
        `}
`;

export default ScrollableTabs;
