import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserService } from '@utiligize/shared/services';
import { getStorageItem } from 'utils';
import { StorageKeys, DefaultRoute } from 'constants/index';

const Callback: React.FC = () =>
  UserService.isLoggedIn() ? <Redirect to={getStorageItem(StorageKeys.LAST_ROUTE) || DefaultRoute} /> : <></>;

export default Callback;
