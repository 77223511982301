import React from 'react';
import './styles.scss';

interface Props {
  type?: 'radio' | 'checkbox';
  dataIndex?: number | string;
  id?: string;
  name?: string;
  className: string;
  checked?: boolean;
  labelKey?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  getIntl?: (localeKey: string, options?: {}) => string;
}

const Checkbox: React.FC<Props> = ({
  type = 'checkbox',
  dataIndex,
  id,
  name,
  className,
  checked,
  labelKey = '',
  label = '',
  disabled = false,
  onChange,
  onClick,
  getIntl = str => str,
  ...props
}) => (
  <div className={className}>
    <input
      data-index={dataIndex}
      id={id}
      name={name}
      onChange={onChange}
      onClick={onClick}
      type={type}
      checked={checked}
      disabled={disabled}
      {...props}
    />
    <label style={{ marginBottom: 0, fontSize: 13, whiteSpace: 'nowrap', cursor: 'default' }}>
      {label || (labelKey && getIntl(labelKey))}
    </label>
  </div>
);

export default Checkbox;
