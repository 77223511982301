import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchManualAction } from 'modules/manuals';
import { setLayoutAction } from 'modules/layouts';
import { manualsModalIdSelector } from 'modules/layouts/selectors';
import ManualsModalForm from './ManualsModalForm';
import { Button, Modal } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik, { Errors } from './useConfiguredFormik';

interface Props {
  hideButton?: boolean;
}

const ManualsModal: React.FC<Props> = ({ hideButton, ...props }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);

  const id: number | null = useSelector(manualsModalIdSelector);
  const isEditMode: boolean = Boolean(id);
  const formik = useConfiguredFormik(id);
  const { isSubmitting, validateForm, setFieldTouched, submitForm, resetForm } = formik;

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ manualsModalId: null }));
    if (show) resetForm();
    setShow(!show);
  }, [id, show, dispatch, resetForm]);

  const handleFormSubmit = useCallback(async (): Promise<void> => {
    const errors: Errors = await validateForm();
    const errorsKeys: string[] = Object.keys(errors);
    if (errorsKeys.length) {
      errorsKeys.map(key => setFieldTouched(key));
      return;
    }
    submitForm().then(toggleModal);
  }, [submitForm, toggleModal, validateForm, setFieldTouched]);

  // fetch full manual object for isEditMode
  useEffect(() => {
    if (!id) return;
    dispatch(fetchManualAction(id)).then(() => setShow(true));
  }, [id, dispatch]);

  return (
    <>
      {!hideButton && (
        <Button icon={<IconPlus />} labelKey="Create Data manual" onClick={toggleModal} variant="primary" />
      )}
      <Modal
        {...props}
        show={show}
        onHide={toggleModal}
        titleKey={getIntl(isEditMode ? 'Edit Data manual' : 'Create Data manual')}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          loading: isSubmitting,
          labelKey: isEditMode ? 'Update' : 'Create',
          onClick: handleFormSubmit,
        }}
      >
        <ManualsModalForm formik={formik} id={id} />
      </Modal>
    </>
  );
};

export default ManualsModal;
