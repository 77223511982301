import React, { useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useLocale } from 'hooks';
import { categoriesCNAIMCountSelector, categoriesCNAIMSelector } from 'modules/assets/cnaim.selectors';
import {
  fetchAssetCategoriesCNAIMAction,
  parseAssetCategoriesCNAIMFileAction,
  deleteAssetCategoriesCNAIMAction,
} from 'modules/assets';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { IconDownload, IconDelete, IconDetails, IconUpload } from '@utiligize/shared/resources';
import { DataTable, Button } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';

const CategoriesCNAIMTable: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const count = useSelector(categoriesCNAIMCountSelector);
  const items = useSelector(categoriesCNAIMSelector);
  const { meta }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.CATEGORIES_CNAIM));

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchAssetCategoriesCNAIMAction(params)).then(
        (action: Shared.ReduxAction<{ categoriesCNAIMHash: Type.Hash<Asset.CategoryCNAIM> }>) =>
          Object.values(action.payload?.categoriesCNAIMHash || {})
      ),
    [dispatch]
  );

  const handleUploadButtonClick = useCallback((event: React.SyntheticEvent) => {
    const id: number = Number(event.currentTarget.getAttribute('data-id'));
    inputRef.current!.id = String(id);
    inputRef.current!.value = '';
    inputRef.current?.click();
  }, []);

  const handleFileInputChange = useCallback(() => {
    const assetCategoryId: number = Number(inputRef.current?.id);
    const files = inputRef.current?.files;
    if (!files?.[0] || !assetCategoryId) return;
    const formData = new FormData();
    formData.append('file', files?.[0]);
    dispatch(parseAssetCategoriesCNAIMFileAction(formData, assetCategoryId));
  }, [dispatch]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteAssetCategoriesCNAIMAction(id)) }));
    },
    [dispatch]
  );

  return (
    <>
      <DataTable
        paginationType={PaginationType.CATEGORIES_CNAIM}
        totalAmount={count}
        sendRequest={sendRequest}
        isDownloadCSVEnabled
        maxHeight="calc(100vh - 287px)"
      >
        {items?.map((item: Asset.CategoryCNAIM) => (
          <tr key={item.id}>
            <td>{item.assetClass}</td>
            <td>{item.assetComponent}</td>
            <td>{item.latestVersionName || '-'}</td>
            <td>{item.latestVersionAssetsCount || '-'}</td>
            <td>{item.latestVersionAuthorEmail || '-'}</td>
            <td>{item.latestVersionCreatedAt ? dateFormat(item.latestVersionCreatedAt) : '-'}</td>
            <td>{item.subcomponent.length ? item.subcomponent.join(', ') : '-'}</td>
            <td className="text-right">
              <Button
                tooltipKey="Details"
                icon={<IconDetails />}
                data-id={item.id}
                linkProps={{ to: generatePath(Routes.AssetsCategoriesCNAIM, { id: item.id }) }}
                size="small"
                variant="primary"
              />
              <a href={meta?.link} download>
                <Button
                  marginLeft
                  tooltipKey="Download template"
                  icon={<IconDownload />}
                  data-id={item.id}
                  disabled={!meta?.link}
                  size="small"
                />
              </a>
              <Button
                marginLeft
                tooltipKey="Upload file"
                icon={<IconUpload />}
                data-id={item.id}
                onClick={handleUploadButtonClick}
                size="small"
              />
              <Button
                marginLeft
                tooltipKey="Delete"
                icon={<IconDelete />}
                data-id={item.id}
                onClick={handleDeleteBtnClick}
                size="small"
                variant="primary"
              />
            </td>
          </tr>
        ))}
      </DataTable>
      <input ref={inputRef} onChange={handleFileInputChange} className="d-none" type="file" />
    </>
  );
};

export default CategoriesCNAIMTable;
