import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  name: string;
  items: Map.MultiSwitchItem[];
  selected: Map.MultiSwitchItem;
  onChange: (item: Map.MultiSwitchItem) => void;
}

const MultiSwitch: React.FC<Props> = ({ name, selected, items, onChange, ...rest }) => {
  const onElementChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const id = Number(e.currentTarget.getAttribute('data-id'));
      const item = items.find(i => i.id === id);
      if (item) onChange(item);
    },
    [items, onChange]
  );

  return (
    <StyledMultiSwitch $count={items.length} {...rest}>
      <div className="switch-container">
        {items.map(item => (
          <React.Fragment key={item.value}>
            <input
              type="radio"
              id={name + '-' + item.id}
              data-id={item.id}
              value={item.value}
              checked={item.id === selected.id}
              onChange={onElementChange}
            />
            <label htmlFor={name + '-' + item.id} data-marker={`${name.replaceAll('-', '_')}_value_${item.value}`}>
              <span>{item.label}</span>
            </label>
          </React.Fragment>
        ))}
        <div className="slider"></div>
      </div>
    </StyledMultiSwitch>
  );
};

const StyledMultiSwitch = styled.div<{ $count: number }>`
  user-select: none;

  .switch-container {
    display: inline-grid;
    grid-template-columns: ${({ $count }) => `repeat(${$count || 1}, 1fr)`};
    align-items: center;
    border: 1px solid var(--map-outline-color);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background-color: var(--map-outline-color-light);

    input {
      display: none;
    }

    input:checked + label span {
      color: #fff;
    }

    ${({ $count }) => {
      return [...Array($count).keys()].map(i => {
        return css`
          input:nth-of-type(${i + 1}):checked ~ .slider {
            left: calc(100% / ${$count || 1} * ${i});
          }
        `;
      });
    }}

    .slider {
      display: block;
      height: 100%;
      width: ${({ $count }) => `calc(100% / ${$count || 1})`};
      background-color: var(--map-active-color);
      transition: left 0.2s linear;
      position: absolute;
      top: 0;
      left: 0;
    }

    label {
      margin: 0;
      padding: 1px 6px;
      white-space: nowrap;
      text-align: center;
      color: var(--map-active-color);
      transition: color 0.2s linear;
      z-index: 1;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      span {
        font-size: 10px;
        width: 100%;
      }
    }
  }
`;

export default React.memo(MultiSwitch);
