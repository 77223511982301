import moment from 'moment';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Col, Row, Nav, Navbar } from 'react-bootstrap';
import { StaticContext } from 'react-router';
import { generatePath, useParams, NavLink, Link, RouteComponentProps } from 'react-router-dom';
import { useLocale } from 'hooks';
import { fetchAssetAction, deleteAssetPhotosAction } from 'modules/assets';
import { setModalConfirmationMetaAction, setInfoToastAction } from 'modules/layouts';
import { HistoryTable, AnswersTable, AssetTaskCompletionsTable, ManualsTable } from 'components/_tables';
import { ManualsModal } from 'components/_modals';
import AssetMetaForm, { useConfiguredFormik, AssetsFields, DateTypeFields } from './AssetMetaForm';
import {
  Alert,
  BackToTableButton,
  DataTableTemplate,
  Gallery,
  StringsInputAutocomplete,
  Button,
  ContentContainer,
  ContentCard,
  Spinner,
} from 'components/_common';
import { _pick } from '@utiligize/shared/utils';
import { AssetCategories, Routes, PageAssetTabs } from 'constants/index';
import { IconDelete } from '@utiligize/shared/resources';

interface Props extends RouteComponentProps<{}, StaticContext, unknown> {
  isCreateMode?: boolean;
}

const AssetDetails: React.FC<Props> = ({ isCreateMode = false, location }) => {
  const state = location.state as { prevRoute?: string } | undefined;
  const { assetCode = '', tab = PageAssetTabs.ROOT } = useParams<{ assetCode: string; tab: Type.PageAssetTabs }>();
  const assetCategoryCode = assetCode.slice(0, 2) as AssetCategories;

  const [loading, setLoading] = useState<boolean>(Boolean(assetCode));
  const [assetMeta, setAssetMeta] = useState<Asset.ExtendedItem | null>(null);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const formik = useConfiguredFormik(assetMeta, isCreateMode);

  const { isValid, submitCount, dirty, isSubmitting, resetForm, submitForm } = formik;
  const isAssetNotFound = Boolean(assetCode && !loading && !assetMeta);
  const isAssetFound = Boolean(assetCode && !loading && assetMeta);

  const handleSubmitForm = useCallback(() => {
    if (dirty) submitForm();
  }, [dirty, submitForm]);

  useEffect(() => {
    if (!isValid) dispatch(push(generatePath(isCreateMode ? Routes.AssetCreate : Routes.AssetDetails, { assetCode })));
  }, [dispatch, submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFetchAssetMeta = useCallback(async () => {
    setLoading(true);
    const action: Shared.ReduxAction<Asset.ExtendedItem | null> = await dispatch(fetchAssetAction({ assetCode }));
    if (action.payload) setAssetMeta(action.payload);
    setLoading(false);
    if (!action.payload) return;

    const values = Object.keys(_pick(action.payload, AssetsFields[assetCategoryCode])).reduce(
      (acc: any, key: string) => {
        if (!action.payload!.hasOwnProperty(key)) return acc;
        const value = (action.payload as any)?.[key];
        if (DateTypeFields.includes(key)) {
          acc[key] = value && moment(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    resetForm({ values });
  }, [assetCode, assetCategoryCode, dispatch, resetForm]);

  useEffect(() => {
    if (!assetCode) return;
    handleFetchAssetMeta();
  }, [assetCode, handleFetchAssetMeta]);

  const handleSelectImage = useCallback(
    (index: number, image: Type.GalleryImage) => {
      const nextImages = assetMeta!.images.slice();
      nextImages[index].isSelected = !image.isSelected;
      if (assetMeta) setAssetMeta({ ...assetMeta, images: nextImages });
    },
    [assetMeta]
  );

  const handleDeleteButtonClick = useCallback(() => {
    if (!assetMeta) return;
    const { attachmentKeys, nextImages } = assetMeta.images.reduce(
      (acc: { attachmentKeys: string[]; nextImages: Type.GalleryImage[] }, image: Type.GalleryImage) => {
        if (image.isSelected) {
          acc.attachmentKeys.push(image.gcsFileName);
        } else {
          acc.nextImages.push(image);
        }
        return acc;
      },
      { attachmentKeys: [], nextImages: [] }
    );

    if (!attachmentKeys.length) return dispatch(setInfoToastAction('Please select at least one photo'));

    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Do you want to delete selected photos?',
        onConfirm: async () =>
          dispatch(deleteAssetPhotosAction({ assetCode, attachmentKeys })).then(() =>
            setAssetMeta({ ...assetMeta, images: nextImages })
          ),
      })
    );
  }, [assetCode, assetMeta, dispatch]);

  const handleStringsInputAutocompleteChange = useCallback(
    (assetCodes: string[] | null) => {
      setLoading(true);
      setAssetMeta(null);
      dispatch(push(generatePath(Routes.AssetDetails, assetCodes?.[0] ? { assetCode: assetCodes?.[0] } : {})));
    },
    [dispatch]
  );

  const GalleryMemo = useMemo(
    () => <Gallery images={assetMeta?.images || []} enableImageSelection onSelectImage={handleSelectImage} />,
    [assetMeta, handleSelectImage]
  );

  const renderCardBody = () => {
    if (!assetCode && !isCreateMode) {
      return (
        <ContentContainer>
          <Alert className="mb-0 text-center" variant="light">
            {getIntl('Please search for asset code')}
          </Alert>
        </ContentContainer>
      );
    }

    if (isAssetNotFound && !isCreateMode) {
      return (
        <ContentContainer>
          <Alert className="mb-0 text-center" variant="danger">
            {getIntl('Asset information by provided asset code not found')}
          </Alert>
        </ContentContainer>
      );
    }

    if (assetCode && loading) {
      return (
        <ContentContainer>
          <Spinner inline />
        </ContentContainer>
      );
    }

    if (isAssetFound && tab === PageAssetTabs.PHOTO) {
      return <ContentContainer>{GalleryMemo}</ContentContainer>;
    }

    if (isAssetFound && tab === PageAssetTabs.HISTORY) {
      return <HistoryTable assetCode={assetCode} />;
    }

    if (isAssetFound && tab === PageAssetTabs.ANSWERS) {
      return <AnswersTable assetCode={assetCode} />;
    }

    if (isAssetFound && tab === PageAssetTabs.TASK_COMPLETIONS) {
      return <AssetTaskCompletionsTable assetCode={assetCode} />;
    }

    if (isAssetFound && tab === PageAssetTabs.DATA_MANUALS) {
      return (
        <>
          <ManualsModal hideButton />
          <ManualsTable assetCode={assetCode} />
        </>
      );
    }

    if (isAssetFound || isCreateMode) {
      return (
        <ContentContainer>
          <ContentCard>
            <AssetMetaForm
              formik={formik}
              categoryCode={isCreateMode ? AssetCategories.BD : assetMeta?.category?.code || null}
              tab={tab}
            />
          </ContentCard>
        </ContentContainer>
      );
    }
  };

  const renderTitle = (): string => {
    if (isCreateMode) return getIntl('Create asset');
    return `${getIntl('Asset details')}${assetCode ? ` - ${assetCode}` : ''}`;
  };

  return (
    <DataTableTemplate title={renderTitle()} paddingBottom>
      <Row>
        <Col xs="auto">
          <Link to={state?.prevRoute || generatePath(Routes.Assets)}>
            <BackToTableButton />
          </Link>
        </Col>
        {!isCreateMode && (
          <Col sm={3}>
            <StringsInputAutocomplete
              labelKey=""
              values={[assetCode].filter(Boolean) as string[]}
              onChange={handleStringsInputAutocompleteChange}
              isSearchable={!assetCode}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          {(isAssetFound || isCreateMode) && (
            <Navbar variant="light">
              <Nav className="mr-auto">
                <>
                  <NavLink
                    className="nav-link nav-link-default"
                    to={{
                      pathname: generatePath(isCreateMode ? Routes.AssetCreate : Routes.AssetDetails, { assetCode }),
                      state,
                    }}
                    exact
                  >
                    {getIntl('Asset details')}
                  </NavLink>
                  <NavLink
                    className="nav-link"
                    to={{
                      pathname: generatePath(isCreateMode ? Routes.AssetCreate : Routes.AssetDetails, {
                        assetCode,
                        tab: PageAssetTabs.SUB_COMPONENTS,
                      }),
                      state,
                    }}
                  >
                    {getIntl('Sub-components')}
                  </NavLink>
                  {isAssetFound && (
                    <>
                      <NavLink
                        className="nav-link"
                        to={{
                          pathname: `${generatePath(Routes.AssetDetails, { assetCode })}/${PageAssetTabs.PHOTO}`,
                          state,
                        }}
                      >
                        {getIntl('Photo')}
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to={{
                          pathname: `${generatePath(Routes.AssetDetails, { assetCode })}/${PageAssetTabs.HISTORY}`,
                          state,
                        }}
                      >
                        {getIntl('History')}
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to={{
                          pathname: `${generatePath(Routes.AssetDetails, { assetCode })}/${PageAssetTabs.ANSWERS}`,
                          state,
                        }}
                      >
                        {getIntl('Answers')}
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to={{
                          pathname: `${generatePath(Routes.AssetDetails, { assetCode })}/${
                            PageAssetTabs.TASK_COMPLETIONS
                          }`,
                          state,
                        }}
                      >
                        {getIntl('Task completions')}
                      </NavLink>
                      <NavLink
                        className="nav-link"
                        to={{
                          pathname: `${generatePath(Routes.AssetDetails, { assetCode })}/${PageAssetTabs.DATA_MANUALS}`,
                          state,
                        }}
                      >
                        {getIntl('Data manuals')}
                      </NavLink>
                    </>
                  )}
                </>
              </Nav>

              <div>
                {tab === PageAssetTabs.PHOTO && (
                  <Button
                    labelKey="Delete photos"
                    icon={<IconDelete />}
                    onClick={handleDeleteButtonClick}
                    disabled={!assetMeta?.images.length}
                    variant="danger"
                    size="large"
                  />
                )}
                {[PageAssetTabs.ROOT, PageAssetTabs.SUB_COMPONENTS].includes(tab) && (
                  <Button
                    labelKey={isCreateMode ? 'Create' : 'Save'}
                    onClick={handleSubmitForm}
                    disabled={!dirty || isSubmitting}
                    variant="primary"
                    size="large"
                  />
                )}
              </div>
            </Navbar>
          )}
          {renderCardBody()}
        </Col>
      </Row>
    </DataTableTemplate>
  );
};

export default AssetDetails;
