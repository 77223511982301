import React, { useCallback, useMemo } from 'react';
import { useBuilderState } from 'hooks';
import { BuilderBlock } from 'components/_builder';
import { Button } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { IconPlus } from '@utiligize/shared/resources';

interface Props {
  isInstructionBuilder: boolean;
  isToolInspectionBuilder: boolean;
  addField: (formField: Builder.Field) => void;
}

const BuilderBlockList: React.FC<Props> = ({ isInstructionBuilder, isToolInspectionBuilder, addField }) => {
  const { blocks, setLocationState, selectedBlock } = useBuilderState();
  const nextBlocks = useMemo(() => _cloneDeep(blocks), [blocks]);

  const changeField = useCallback(
    (indexBlock: number, formFields: Builder.Field[]) => {
      (nextBlocks as any)[indexBlock].fields = formFields;
      setLocationState({ blocks: nextBlocks });
    },
    [nextBlocks, setLocationState]
  );

  const selectBlock = useCallback(
    (index: number) => {
      if (selectedBlock === index) return;
      const nextBlocks = blocks.map((item: Builder.SnapshotBlock) => ({
        ...item,
        selected: item.index === index,
      }));
      setLocationState({ blocks: nextBlocks });
    },
    [selectedBlock, blocks, setLocationState]
  );

  const moveBlock = useCallback(
    (index: number, offset: number) => {
      if ((index === 0 && offset === -1) || (index === nextBlocks.length - 1 && offset === 1)) {
        return;
      }
      nextBlocks.forEach((item: Builder.SnapshotBlock, i: number) => {
        if (i === index) {
          item.index = item.index + offset;
        } else if (i === index + offset) {
          item.index = item.index - offset;
        }
      });
      nextBlocks.sort((a: Builder.SnapshotBlock, b: Builder.SnapshotBlock) => {
        return a.index - b.index;
      });
      nextBlocks.forEach((item: Builder.SnapshotBlock) => {
        item.fields.forEach(itemField => {
          itemField.indexBlock = item.index;
        });
      });
      setLocationState({ blocks: nextBlocks });
    },
    [nextBlocks, setLocationState]
  );

  const addBlock = useCallback(() => {
    nextBlocks.push({
      key: nextBlocks.length,
      checked: false,
      fields: [],
    } as any);
    nextBlocks.forEach((item: Builder.SnapshotBlock, index: number) => {
      item.index = index;
      item.selected = item.index === nextBlocks.length - 1;
    });
    setLocationState({ blocks: nextBlocks });
  }, [nextBlocks, setLocationState]);

  const delBlock = useCallback(
    (index: number) => {
      nextBlocks.splice(index, 1);
      nextBlocks.forEach((item: Builder.SnapshotBlock, i: number) => {
        item.index = i;
        item.selected = false;
        item.fields.forEach((field: any) => {
          field.indexBlock = item.index;
        });
      });
      setLocationState({ blocks: nextBlocks });
    },
    [nextBlocks, setLocationState]
  );

  const handleBlockCheckboxChange = useCallback(
    (index: number, checked: boolean) => {
      nextBlocks[index].checked = checked;
      setLocationState({ blocks: nextBlocks });
    },
    [nextBlocks, setLocationState]
  );

  return (
    <>
      {blocks?.map((block: Builder.SnapshotBlock) => (
        <BuilderBlock
          isInstructionBuilder={isInstructionBuilder}
          isToolInspectionBuilder={isToolInspectionBuilder}
          key={block.id || block.index}
          blockIndex={block.index}
          blockSelected={block.selected}
          checked={block.checked}
          fields={block.fields}
          selectBlock={selectBlock}
          moveBlock={moveBlock}
          delBlock={delBlock}
          addField={addField}
          changeField={changeField}
          onBlockCheckboxChange={handleBlockCheckboxChange}
        />
      ))}
      <div className="d-flex justify-content-center p-4">
        <Button icon={<IconPlus />} labelKey="Add page" onClick={addBlock} />
      </div>
    </>
  );
};

export default BuilderBlockList;
