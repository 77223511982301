import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRegulatoryZoneOptionsAction } from 'modules/interventions';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Type.SelectOption | null;
  onChange: (option: Type.SelectOption) => void;
}

const SelectRegulatoryZone: React.FC<Props> = ({ value, onChange, ...rest }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Type.SelectOption[]>([]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchRegulatoryZoneOptionsAction())
      .then((action: Shared.ReduxAction<Type.SelectOption[]>) => {
        onChange(action.payload[0]);
        setOptions(action.payload);
      })
      .finally(() => setIsLoading(false));
  }, [dispatch, onChange]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey="Regulatory zone"
      value={value}
      options={options}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SelectRegulatoryZone;
