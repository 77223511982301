import React from 'react';
import { useSelector } from 'react-redux';
import {
  enabledLayersListSelector,
  legendSelector,
  mapStateThemeSelector,
  settingsSelector,
} from 'modules/map/selectors';
import FilterAssetsList from './FilterAssetsList';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import GroupHiddenDescription from 'components/Map/common/GroupHiddenDescription';
import { ReactComponent as GroupIcon } from 'components/Map/icons/icon • map panel section • added objects.svg';
import { MapThemes } from 'constants/map';

interface Props {
  map: Map.MapboxMap;
  layers: Map.StyleLayer[];
}

const FilterAssets: React.FC<Props> = ({ map, layers: mapLayers }) => {
  const legendData = useSelector(legendSelector);
  const settings = useSelector(settingsSelector);
  const enabledLayersList = useSelector(enabledLayersListSelector);
  const theme = useSelector(mapStateThemeSelector);

  const checkedFilters = [
    ...new Set(
      enabledLayersList
        .filter(id => id.startsWith('asset__'))
        .map(id => id.replace(/_voltage.*/gi, ''))
        .map(id => settings.layerTitle?.[id])
        .filter(Boolean)
    ),
  ];

  return (
    <CollapseGroup
      id="assets"
      titleKey="Assets"
      stateLoc="global"
      icon={<GroupIcon className="filter-group-icon" />}
      disabled={settings.isAssetGroupDisabled || theme === MapThemes.TASK}
      dataMarker="panel_assets"
      description={
        <GroupHiddenDescription values={(checkedFilters ?? []) as string[]} defaultValue="Nothing selected" />
      }
    >
      <FilterAssetsList map={map} layers={mapLayers} item={legendData.assets} />
    </CollapseGroup>
  );
};

export default FilterAssets;
