import React from 'react';
import { useSelector } from 'react-redux';
import { Toasts as SharedToasts } from '@utiligize/shared/components';
import { globalToastsSelector } from 'modules/layouts/selectors';
import { deleteToastAction } from 'modules/layouts';
import { useLocale } from 'hooks';

const Toasts: React.FC = () => {
  const { getIntl } = useLocale();
  const globalToasts = useSelector(globalToastsSelector);
  return <SharedToasts getIntl={getIntl} toasts={globalToasts} deleteToastAction={deleteToastAction} />;
};

export default Toasts;
