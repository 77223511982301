import styled from 'styled-components';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip } from 'components/_common';

interface Props {
  id: number;
  type: Type.TaskTypes;
  className?: string;
}

const TaskTypeBadge: React.FC<Props> = ({ id, type, className = '' }) => {
  const { getIntl } = useLocale();
  const locale = getIntl(type);

  if (!locale) return null;
  return (
    <OverlayTriggerTooltip
      placement="right"
      trigger={['hover', 'focus']}
      overlayId={String(id)}
      overlayChildren={locale}
    >
      <StyledBadge variant="success" className={className}>
        {locale[0]}
      </StyledBadge>
    </OverlayTriggerTooltip>
  );
};

const StyledBadge = styled(Badge)`
  font-size: 90%;
  font-weight: 500;
  width: 20px;
  opacity: 0.8;
  transition: opacity 0.3s ease-in;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export default TaskTypeBadge;
