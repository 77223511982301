import { useState, useEffect, useCallback } from 'react';

const useScrollPosition = ({ maxUpdateValue }: { maxUpdateValue: number }) => {
  const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);

  const handleScrollEvent = useCallback(() => {
    setScrollPosition(maxUpdateValue < window.pageYOffset ? maxUpdateValue : window.pageYOffset);
  }, [maxUpdateValue]);

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent, { passive: true });
    return () => window.removeEventListener('scroll', handleScrollEvent);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return scrollPosition;
};

export default useScrollPosition;
