import { handleActions } from 'redux-actions';
import {
  fetchAssetModelsAction,
  fetchAllAssetModelsAction,
  createAssetModelAction,
  updateAssetModelAction,
  deleteAssetModelAction,
  patchAssetModelAction,
} from './actions';

const initialState: AssetModels.Root = {
  loading: false,
  isFetched: false,
  count: 0,
  itemsHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchAssetModelsAction]: {
    next: (
      state: AssetModels.Root,
      action: Shared.ReduxAction<Pick<AssetModels.Root, 'count' | 'itemsHash'>>
    ): AssetModels.Root => ({
      ...state,
      ...action.payload,
      loading: false,
      isFetched: true,
    }),
    throw: (state: AssetModels.Root): AssetModels.Root => ({
      ...state,
      loading: false,
      isFetched: false,
    }),
  },
};

export {
  fetchAssetModelsAction,
  fetchAllAssetModelsAction,
  createAssetModelAction,
  updateAssetModelAction,
  deleteAssetModelAction,
  patchAssetModelAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
