import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec, useLocale } from 'hooks';
import { Modal, Button, FormInput, SelectDERsDescription } from 'components/_common';
import { IconUpload } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalUploadPhaseIn: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [show, setShow] = useState(false);
  const { getIntl } = useLocale();

  const toggleModal = useCallback((): void => setShow(show => !show), []);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setFieldValue,
    setFieldTouched,
  } = useConfiguredFormik({ toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  const handleUploadButtonClick = useCallback(() => {
    inputRef.current!.value = '';
    inputRef.current?.click();
  }, []);

  const handleUploadInputChange = useCallback(() => {
    const files = inputRef.current?.files;
    if (files?.[0]) setFieldValue('file', files?.[0]);
  }, [setFieldValue]);

  return (
    <>
      <Button icon={<IconUpload />} labelKey="Upload phase-in" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Upload phase-in"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: 'Upload',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              labelKey="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectDERsDescription
              name="der_description_id"
              value={values.der_description_id}
              setFieldValue={setFieldValue}
              onBlur={() => setFieldTouched('der_description_id')}
              errorKey={
                Boolean(touched.der_description_id && errors.der_description_id)
                  ? (errors.der_description_id as string)
                  : ''
              }
            />
          </Form.Group>

          <Button icon={<IconUpload />} labelKey="Upload phase-in" onClick={handleUploadButtonClick} />
          <input ref={inputRef} onChange={handleUploadInputChange} className="d-none" type="file" />

          {values.file && <Form.Text className="text-muted">{values.file.name}</Form.Text>}
          {Boolean(touched.file && errors.file) && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {getIntl(errors.file as string)}
            </Form.Control.Feedback>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ModalUploadPhaseIn;
