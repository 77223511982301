import React from 'react';
import queryString from 'query-string';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocale, usePaginationHandlers } from 'hooks';
import { scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { ScenarioRunInfo } from 'components';
import {
  ChartBase64,
  Checkbox,
  ContentCard,
  ContentContainer,
  DataTableTemplate,
  SelectPrimarySubstations,
  SelectScenariosYear,
} from 'components/_common';
import SelectPercentile from './SelectPercentile';
import { PaginationType } from 'constants/index';
import { ButtonSwitch } from '@utiligize/shared/components';

const ViewVoltageDrop: React.FC = () => {
  const { getIntl } = useLocale();
  const type = PaginationType.NETWORK_LOADING_VOLTAGE_DROP;
  const { filters, handleSelectChange, handleCheckboxClick, handleSwitchClick } = usePaginationHandlers({
    type,
  });
  const simulationId = useSelector(simulationIdSelector);
  const simulationVersionId = useSelector(simulationSelectedOptionVersionIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col sm={9}>
          <Row style={{ rowGap: '0.5rem' }}>
            <Col xs={4}>
              <SelectPrimarySubstations
                name="primarySubstationsId"
                value={filters?.primarySubstationsId ? [filters?.primarySubstationsId] : null}
                onChange={handleSelectChange}
              />
            </Col>
            <Col xs={4}>
              <SelectScenariosYear
                name="year"
                value={filters?.year as number}
                onChange={handleSelectChange}
                labelKey=""
                mutedTextLabelKey="Scenarios year"
                variant="small"
              />
            </Col>
            <Col xs={4}>
              <SelectPercentile
                name="percentile"
                labelKey=""
                value={filters?.percentile || null}
                onChange={handleSelectChange}
                isSearchable={false}
                variant="small"
                mutedTextLabelKey="Percentile"
              />
            </Col>
            <Col xs="auto" className="d-flex align-items-center">
              <ButtonSwitch
                checked={!filters?.min_or_max}
                width={160}
                offlabel={getIntl('Min')}
                onlabel={getIntl('Max')}
                onChange={value => handleSwitchClick('min_or_max', !value)}
              />
            </Col>
            <Col xs="auto">
              <Checkbox
                labelKey="Investment"
                className="icheck-primary"
                name="investment"
                checked={filters?.investment}
                onChange={handleCheckboxClick}
              />
            </Col>
            <Col xs="auto">
              <Checkbox
                labelKey="Flex"
                className="icheck-primary"
                name="flex"
                checked={filters?.flex}
                onChange={handleCheckboxClick}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={3} className="d-flex flex-column align-items-end">
          <ScenarioRunInfo />
        </Col>
      </Row>
      <ContentContainer>
        <ContentCard>
          <ChartBase64
            title="Plot voltage drop"
            src={
              Boolean(
                simulationId &&
                  simulationVersionId &&
                  scenarioId &&
                  filters?.year &&
                  filters?.percentile &&
                  filters?.primarySubstationsId
              )
                ? `plots/voltage_drop_plot?${queryString.stringify({
                    simulation_id: simulationId,
                    version_id: simulationVersionId,
                    scenario_id: scenarioId,
                    year: filters?.year,
                    percentile: filters?.percentile,
                    primary_substation_id: filters?.primarySubstationsId,
                    min_or_max: filters?.min_or_max,
                    investment: filters?.investment,
                    flexible: filters?.flex,
                  })}`
                : ''
            }
          />
        </ContentCard>
      </ContentContainer>
    </DataTableTemplate>
  );
};

export default ViewVoltageDrop;
