import React, { useState, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router-dom';
import FiltersComponentPrices from 'components/Setup/FiltersComponentPrices';
import TableComponentPrices from 'components/Setup/TableComponentPrices';
import {
  ChartIframe,
  SelectAssetCategoriesCNAIM,
  ContentContainer,
  ContentCard,
  Button,
  DataTableTemplate,
  SelectScenariosYear,
} from 'components/_common';
import { Routes } from 'constants/index';
import { IconMap } from '@utiligize/shared/resources';

import SelectOutputVariables from './SelectOutputVariables';
import SelectRegulatoryZone from './SelectRegulatoryZone';
import SelectHealthIndex from './SelectHealthIndex';
import SelectAssetPortfolio from './SelectAssetPortfolio';
import SelectAggregationLevel from './SelectAggregationLevel';

const InterventionsAnalyzeResults: React.FC = () => {
  const [outputVariable, setOutputVariable] = useState<Type.SelectOption | null>(null);
  const [yearsRange, setYearsRange] = useState<Type.SelectOption | null>(null);
  const [regulatoryZone, setRegulatoryZone] = useState<Type.SelectOption | null>(null);
  const [healthIndex, setHealthIndex] = useState<Type.SelectOption | null>(null);
  const [assetPortfolio, setAssetPortfolio] = useState<Type.SelectOption | null>(null);
  const [aggregationLevel, setAggregationLevel] = useState<Type.SelectOption | null>(null);
  const [assetCategoryCNAIM, setAssetCategoryCNAIM] = useState<Type.SelectOption | null>({
    value: 1,
    label: '0.4 kV Board',
  });

  const handleOutputVariablesChange = useCallback((option: Type.SelectOption): void => setOutputVariable(option), []);

  const handleYearsRangeChange = useCallback((option: Type.SelectOption): void => setYearsRange(option), []);

  const handleRegulatoryZoneChange = useCallback((option: Type.SelectOption): void => setRegulatoryZone(option), []);

  const handleHealthIndexChange = useCallback((option: Type.SelectOption): void => setHealthIndex(option), []);

  const handleAssetPortfolioChange = useCallback((option: Type.SelectOption): void => setAssetPortfolio(option), []);

  const handleAggregationLevelChange = useCallback(
    (option: Type.SelectOption): void => setAggregationLevel(option),
    []
  );

  const handleAssetCategoryCNAIMChange = useCallback(
    (option: Type.SelectOption): void => setAssetCategoryCNAIM(option),
    []
  );

  return (
    <ContentContainer className="border-top-0">
      <Row>
        <Col xs={3}>
          <ContentCard marginBottom>
            <Form.Group>
              <SelectOutputVariables variant="small" value={outputVariable} onChange={handleOutputVariablesChange} />
            </Form.Group>
            <Form.Group>
              <SelectScenariosYear
                variant="small"
                value={(yearsRange?.value as number) || null}
                onChange={handleYearsRangeChange}
                setInitialValue
              />
            </Form.Group>
            <Form.Group>
              <SelectRegulatoryZone variant="small" value={regulatoryZone} onChange={handleRegulatoryZoneChange} />
            </Form.Group>
            <Form.Group>
              <SelectHealthIndex variant="small" value={healthIndex} onChange={handleHealthIndexChange} />
            </Form.Group>
            <Form.Group>
              <SelectAssetPortfolio variant="small" value={assetPortfolio} onChange={handleAssetPortfolioChange} />
            </Form.Group>
            <Form.Group>
              <SelectAggregationLevel
                variant="small"
                value={aggregationLevel}
                onChange={handleAggregationLevelChange}
              />
            </Form.Group>
            <Form.Group>
              <SelectAssetCategoriesCNAIM
                variant="small"
                value={assetCategoryCNAIM}
                setValueAction={handleAssetCategoryCNAIMChange}
              />
            </Form.Group>
            <Button
              linkProps={{ to: generatePath(Routes.Map) }}
              className="w-100"
              icon={<IconMap />}
              labelKey="Select area from map"
            />
          </ContentCard>
        </Col>
        <Col xs={9}>
          <ChartIframe
            title="Plot analyze results"
            src={aggregationLevel?.value ? `plot_compare_results?aggregation_level=${aggregationLevel?.value}` : ''}
            height="582px"
          />
        </Col>
      </Row>
      <DataTableTemplate paddingBottom>
        <FiltersComponentPrices />
        <TableComponentPrices inContentContainer />
      </DataTableTemplate>
    </ContentContainer>
  );
};

export default InterventionsAnalyzeResults;
