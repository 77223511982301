import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { maintenanceSummaryCountSelector, maintenanceSummarySelector } from 'modules/maintenance/selectors';
import { fetchMaintenanceSummaryAction } from 'modules/maintenance';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const MaintenanceSummaryTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(maintenanceSummaryCountSelector);
  const items = useSelector(maintenanceSummarySelector);

  const sendRequest = useCallback(() => dispatch(fetchMaintenanceSummaryAction()), [dispatch]);

  return (
    <DataTable paginationType={PaginationType.MAINTENANCE_SUMMARY} totalAmount={count} sendRequest={sendRequest}>
      {items.map((item: Maintenance.Summary) => (
        <tr key={item.id}>
          <td>{item.assetCategory || '-'}</td>
          <td>{item.maintenanceYear || '-'}</td>
          <td>{item.number || '-'}</td>
          <td>{item.planName || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default MaintenanceSummaryTable;
