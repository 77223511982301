import React, { useState, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { ChartIframe, SelectAssetCategoriesCNAIM, ContentContainer, ContentCard } from 'components/_common';
import SelectOutputVariables from './SelectOutputVariables';
import SelectSensitivityVariable from './SelectSensitivityVariable';

const InterventionsSensitivityAnalysis: React.FC = () => {
  const [assetCategoryCNAIM, setAssetCategoryCNAIM] = useState<Type.SelectOption | null>({
    value: 1,
    label: '0.4 kV Board',
  });
  const [outputVariable, setOutputVariable] = useState<Type.SelectOption | null>(null);
  const [sensitivityVariableX, setSensitivityVariableX] = useState<Type.SelectOption | null>(null);
  const [sensitivityVariableY, setSensitivityVariableY] = useState<Type.SelectOption | null>(null);

  const handleAssetCategoryCNAIMChange = useCallback(
    (option: Type.SelectOption): void => setAssetCategoryCNAIM(option),
    []
  );

  const handleOutputVariablesChange = useCallback((option: Type.SelectOption): void => setOutputVariable(option), []);

  const handleSensitivityVariableXChange = useCallback(
    (option: Type.SelectOption): void => setSensitivityVariableX(option),
    []
  );

  const handleSensitivityVariableYChange = useCallback(
    (option: Type.SelectOption): void => setSensitivityVariableY(option),
    []
  );

  return (
    <ContentContainer>
      <Row>
        <Col xs={3}>
          <ContentCard>
            <Form.Group>
              <SelectAssetCategoriesCNAIM
                variant="small"
                value={assetCategoryCNAIM}
                setValueAction={handleAssetCategoryCNAIMChange}
              />
            </Form.Group>
            <Form.Group>
              <SelectOutputVariables variant="small" value={outputVariable} onChange={handleOutputVariablesChange} />
            </Form.Group>
            <Form.Group>
              <SelectSensitivityVariable
                variant="small"
                labelKey="Sensitivity X variable"
                value={sensitivityVariableX}
                onChange={handleSensitivityVariableXChange}
              />
            </Form.Group>
            <Form.Group>
              <SelectSensitivityVariable
                variant="small"
                labelKey="Sensitivity Y variable"
                value={sensitivityVariableY}
                onChange={handleSensitivityVariableYChange}
              />
            </Form.Group>
          </ContentCard>
        </Col>
        <Col xs={9}>
          <ChartIframe
            title="Cumulative probability of failure"
            src={
              assetCategoryCNAIM?.value &&
              outputVariable?.value &&
              sensitivityVariableX?.value &&
              sensitivityVariableY?.value
                ? `plot_sensitivity_analysis?asset_category=${assetCategoryCNAIM?.value}&output_variable=${outputVariable?.value}&sensitivity_variable_x=${sensitivityVariableX?.value}&sensitivity_variable_y=${sensitivityVariableY?.value}`
                : ''
            }
          />
        </Col>
      </Row>
    </ContentContainer>
  );
};

export default InterventionsSensitivityAnalysis;
