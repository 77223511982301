import React, { memo } from 'react';
import classNames from 'classnames';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip } from 'components/_common';

interface Props {
  id: number;
  isChecklistDone: boolean | null;
}

const ChecklistStatus: React.FC<Props> = memo(({ id, isChecklistDone }) => {
  const { getIntl } = useLocale();

  return (
    <OverlayTriggerTooltip
      trigger={['hover', 'focus']}
      overlayId={String(id)}
      overlayChildren={
        <>
          {isChecklistDone === null && getIntl('Not started')}
          {isChecklistDone === false && getIntl('In progress')}
          {isChecklistDone && getIntl('Completed')}
        </>
      }
    >
      <i
        className={classNames('fa-circle nav-icon', {
          'far text-danger': isChecklistDone === null,
          'fas text-warning': isChecklistDone === false,
          'fas text-success': isChecklistDone,
        })}
      />
    </OverlayTriggerTooltip>
  );
});

export default ChecklistStatus;
