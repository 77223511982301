import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import { cablesSelector, cablesCountSelector } from 'modules/networkLoading/selectors';
import { fetchCablesAction } from 'modules/networkLoading';
import { setProfileModalMetaAction } from 'modules/layouts';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button, SolvedOption } from 'components/_common';
import { PaginationType, Routes, ProfileModalTypes } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const TableCables: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.CABLES;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const items = useSelector(cablesSelector);
  const count = useSelector(cablesCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchCablesAction(params)).then(
        (action: Shared.ReduxAction<{ cablesHash: Type.Hash<NetworkLoading.Cable> }>) =>
          Object.values(action.payload?.cablesHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(setProfileModalMetaAction({ id: assetName, type: ProfileModalTypes.Cable }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId || !scenarioId || !Boolean(filters?.percentile && filters?.year)}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
      maxHeight="calc(100vh - 369px)"
    >
      {items?.map((item: NetworkLoading.Cable) => (
        <tr key={item.id}>
          <td>
            <Link to={generatePath(Routes.Map, { uuid: item.id })}>{item.customer_asset_name}</Link>
          </td>
          <td>{item.type}</td>
          <td>{item.distribution_feeder ?? '-'}</td>
          <td>{numberFormat(Number(item.length))}</td>
          <td>{item.date_commissioned ? dateFormat(item.date_commissioned) : '-'}</td>
          <td>{numberFormat(item.max_i_ka)}</td>
          <td className="text-center">
            <SolvedOption uuid={item.id} checked={item.ignore} paginationType={paginationType} />
          </td>
          <td>{item.explanation || '-'}</td>
          <td>{numberFormat(item.maxLoading)}</td>
          <td>{numberFormat(item.maxReactive)}</td>
          <td>{numberFormat(item.gridLosses)}</td>
          <td>{dateFormat(item.maxLoadingTime, 'L LT')}</td>
          <td>{numberFormat(item.utilization)}</td>
          <td>{numberFormat(item.annualConsumption)}</td>
          <td className="text-nowrap">{numberFormat(item.voltage_min, { fallback: '-' })}</td>
          <td className="text-nowrap">{numberFormat(item.voltage_max, { fallback: '-' })}</td>
          <td className="text-right">
            <Button
              dataMarker="actions_profile"
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCables;
