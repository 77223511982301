import React from 'react';
import { InstructionsModal } from 'components/_modals';
import { InstructionsTable } from 'components/_tables';
import { DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageInstructions: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isFormsEnabled}>
    <DataTableTemplate paddingBottom>
      <InstructionsModal />
      <InstructionsTable />
    </DataTableTemplate>
  </PagePermissionsProvider>
);

export default PageInstructions;
