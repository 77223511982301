import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { useLocale } from 'hooks';
import {
  fetchPortfolioOptionsAction,
  fetchScenarioOptionsAction,
  fetchSimulationOptionsAction,
  fetchInvestmentScenariosOptionsAction,
} from 'modules/options';
import {
  portfolioOptionSelector,
  scenarioSelectedOptionSelector,
  simulationSelectedOptionSelector,
  investmentScenariosOptionSelector,
} from 'modules/options/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { Button } from 'components/_common';
import SelectPortfolio from './SelectPortfolio';
import SelectScenarioType from './SelectScenarioType';
import SelectSimulation from './SelectSimulation';
import SelectInvestmentScenario from './SelectInvestmentScenario';
import { isDevelop } from 'constants/index';
import { IconSliders } from '@utiligize/shared/resources';

const PopoverAppSettings: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioOption = useSelector(portfolioOptionSelector);
  const scenarioOption = useSelector(scenarioSelectedOptionSelector);
  const simulationSelectedOption = useSelector(simulationSelectedOptionSelector);

  const investmentScenariosOption = useSelector(investmentScenariosOptionSelector);
  const portfolioId = useSelector(portfolioIdSelector);

  useEffect(() => {
    dispatch(fetchPortfolioOptionsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!portfolioId) return;
    dispatch(fetchScenarioOptionsAction());
    dispatch(fetchSimulationOptionsAction());
    dispatch(fetchInvestmentScenariosOptionsAction());
  }, [portfolioId, dispatch]);

  return (
    <>
      <StyledInfo>
        <div>
          {getIntl('Portfolio')}: <b>{portfolioOption?.label}</b>
        </div>
        <div>
          {getIntl('Scenario')}: <b>{scenarioOption?.label || '-'}</b>
        </div>
        {isDevelop && (
          <>
            <div>
              {getIntl('Simulation')}: <b>{simulationSelectedOption?.label}</b>
            </div>
            <div>
              {getIntl('Investment scenario')}: <b>{investmentScenariosOption?.label}</b>
            </div>
          </>
        )}
      </StyledInfo>
      <OverlayTrigger
        trigger="click"
        placement="bottom"
        rootClose
        overlay={
          <StyledPopover id="popover-app-settings">
            <Popover.Content>
              <Form.Group data-marker="select_portfolio">
                <SelectPortfolio />
              </Form.Group>
              <Form.Group data-marker="select_scenario">
                <SelectScenarioType />
              </Form.Group>
              {isDevelop && (
                <>
                  <Form.Group>
                    <SelectSimulation />
                  </Form.Group>
                  <Form.Group>
                    <SelectInvestmentScenario />
                  </Form.Group>
                </>
              )}
            </Popover.Content>
          </StyledPopover>
        }
      >
        <Button
          marginLeft
          variant="primary"
          icon={<IconSliders />}
          aria-label="App settings"
          data-marker="header__app_settings"
        />
      </OverlayTrigger>
    </>
  );
};

const StyledPopover = styled(Popover)`
  width: 325px;
  max-width: 325px;
  z-index: 10001;
`;

const StyledInfo = styled.div`
  font-family: Arial;
  font-size: 10px;
  line-height: 0.9;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default PopoverAppSettings;
