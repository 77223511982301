import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Tabs as BootstrapTabs, TabsProps } from 'react-bootstrap';

const Tabs: React.FC<TabsProps & { className?: string; inModal?: boolean }> = ({
  children,
  inModal = false,
  ...props
}) => {
  const isControlledByRouter = !props.defaultActiveKey && !props.activeKey;
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { pathname, hash } = useLocation();

  const handleTabSelect = useCallback(
    (key: string): void => {
      dispatch(push(key));
    },
    [dispatch]
  );

  return (
    <StyledTabs
      {...props}
      activeKey={props.activeKey || (isControlledByRouter ? `${pathname}${hash}` : undefined)}
      onSelect={props.onSelect || (isControlledByRouter ? (handleTabSelect as any) : undefined)}
      variant="pills"
      $inModal={inModal}
    >
      {children}
    </StyledTabs>
  );
};

const StyledTabs = styled(BootstrapTabs)<{ $inModal: boolean }>`
  background: white;
  padding: ${props => (props.$inModal ? `0 0 ${props.theme.paddings.primary} 0` : props.theme.paddings.primary)};

  .nav-link {
    padding: 8.5px 12px;
    border-radius: 8px;
    transition: color 0.2s ease-in;
    color: ${props => props.theme.colors.grey900};
    font-weight: 500;
    font-size: 14px;
    margin-right: 12px;

    &:not(.active):hover {
      color: ${props => props.theme.colors.purple800};
    }
    &.active {
      color: ${props => props.theme.colors.purple800};
      cursor: default;
      background-color: ${props => props.theme.colors.purple50};
    }
  }

  + div .card {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;

export default Tabs;
