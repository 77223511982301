import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetBudgetingAction } from 'modules/budgeting';
import { Button } from 'components/_common';
import { IconReopen } from '@utiligize/shared/resources';

export default function useBudgetingResetButton() {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleResetButtonClick = useCallback(() => {
    setLoading(true);
    dispatch(resetBudgetingAction()).finally(() => setLoading(false));
  }, [dispatch]);

  const ButtonReset = (
    <Button
      className="mr-2"
      icon={<IconReopen />}
      labelKey="Reset"
      onClick={handleResetButtonClick}
      loading={loading}
      variant="primary-outline"
    />
  );

  return { loading, ButtonReset };
}
