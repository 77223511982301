import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { maintenancePlanCNAIMCountSelector, maintenancePlanCNAIMSelector } from 'modules/maintenance/selectors';
import { fetchPlanCNAIMAction } from 'modules/maintenance';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TablePlanCNAIM: React.FC = () => {
  const dispatch = useDispatch();
  const { numberFormat } = useLocale();

  const items = useSelector(maintenancePlanCNAIMSelector);
  const count = useSelector(maintenancePlanCNAIMCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchPlanCNAIMAction(params)).then(
        (action: Shared.ReduxAction<{ maintenancePlanCNAIMHash: Maintenance.Root['maintenancePlanCNAIMHash'] }>) =>
          Object.values(action.payload?.maintenancePlanCNAIMHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_PLAN_CNAIM}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
    >
      {items?.map?.((item: Maintenance.PlanCNAIM) => (
        <tr key={item.customer_id}>
          <td>{item.customer_id}</td>
          <td>{numberFormat(item.pof, { fallback: '-' })}</td>
          <td>{numberFormat(item.cof, { fallback: '-' })}</td>
          <td>{numberFormat(item.monetary_risk, { fallback: '-' })}</td>
          <td>{item.inspection}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TablePlanCNAIM;
