import styled from 'styled-components';
import React, { useMemo } from 'react';
import { PopupStyles, Popup, LayerTitle, PopupBody, PopupItem } from 'components/Map/common/Popup';
import { TaskStatusColorMap } from 'constants/index';

export interface Property extends Map.Task {
  featureId: number;
}

interface Props {
  properties: Property[];
  getIntl: (key: string) => string;
  openUnifiedAssetPanel: (uuid: string) => void;
}

const TasksMapPopup: React.FC<Props> = ({ properties, getIntl, openUnifiedAssetPanel }) => {
  const propertiesYearHash = useMemo(
    () =>
      properties.reduce((acc: Record<Map.Task['year'], Property[]>, property) => {
        if (!acc[property.year]) {
          acc[property.year] = [property];
        } else {
          acc[property.year].push(property);
        }
        return acc;
      }, {}),
    [properties]
  );

  return (
    <>
      <PopupStyles />
      <Popup>
        <>
          <LayerTitle className="mb-0">{getIntl('Tasks')}</LayerTitle>
          <StyledPopupBody>
            {Object.keys(propertiesYearHash).map(year => (
              <React.Fragment key={year}>
                <StyledYear>{year}</StyledYear>
                {properties.map(property => (
                  <StyledPopupItem
                    key={property.featureId}
                    onClick={property.assetUUID ? () => openUnifiedAssetPanel(property.assetUUID!) : undefined}
                    $status={property.status}
                  >
                    <StyledName title={property.name}>{property.name}</StyledName>
                    <StyledAssetCode $isLink={Boolean(property.assetUUID)}>{property.assetCode || '-'}</StyledAssetCode>
                  </StyledPopupItem>
                ))}
              </React.Fragment>
            ))}
          </StyledPopupBody>
        </>
      </Popup>
    </>
  );
};

const StyledPopupBody = styled(PopupBody)`
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: none;
`;

const StyledYear = styled.div`
  margin-left: 5px;
  color: #f6f8fb;
`;

const StyledPopupItem = styled(PopupItem)<{ $status: Map.Task['status'] }>`
  padding-right: 30px;

  ::before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${props => TaskStatusColorMap[props.$status]};
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(-50%, -50%);
    border: 1px solid rgb(255, 255, 255);
  }
`;

const StyledName = styled.span`
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
`;

const StyledAssetCode = styled.span<{ $isLink: boolean }>`
  color: white;
  opacity: ${props => (props.$isLink ? 1 : 0.5)};
`;

export default TasksMapPopup;
