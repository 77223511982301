import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { useLocale } from 'hooks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/highcharts-more')(Highcharts);

interface Props {
  height: string;
  className?: string;
}

const SpeedometerChart: React.FC<Props> = ({ className }) => {
  const [speed, setSpeed] = useState(150);
  const { getIntl } = useLocale();

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (speed) return;
      const inc = Math.round((Math.random() - 0.5) * 20);

      let newVal = speed + inc;
      if (newVal < 0 || newVal > 200) {
        newVal = speed - inc;
      }

      setSpeed(newVal);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [speed]);

  const config = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: '80%',
    },

    title: {
      text: 'Speedometer',
    },

    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', '75%'],
      size: '110%',
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 200,
      tickPixelInterval: 72,
      tickPosition: 'inside',
      tickColor: '#FFFFFF',
      tickLength: 20,
      tickWidth: 2,
      minorTickInterval: null,
      labels: {
        distance: 20,
        style: {
          fontSize: '14px',
        },
      },
      plotBands: [
        {
          from: 0,
          to: 120,
          color: '#55BF3B', // green
          thickness: 20,
        },
        {
          from: 120,
          to: 160,
          color: '#DDDF0D', // yellow
          thickness: 20,
        },
        {
          from: 160,
          to: 200,
          color: '#DF5353', // red
          thickness: 20,
        },
      ],
    },

    series: [
      {
        name: getIntl('Voltage'),
        data: [speed],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={config} containerProps={{ className }} />;
};

const StyledSpeedometerChart = styled(SpeedometerChart)`
  ${({ height }) => `height: ${height};`};

  .highcharts-credits {
    display: none;
  }
`;

export default StyledSpeedometerChart;
