import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createWorkPriceAction, updateWorkPriceAction } from 'modules/setup';

const useConfiguredFormik = ({
  id,
  isDuplicate,
  toggleModal,
}: {
  id: number | null;
  isDuplicate?: boolean;
  toggleModal: () => void;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Setup.WorkPriceActionProps>({
    initialValues: {
      work_type: null,
      grid_zone_id: null,
      price: null,
      price_per_unit: null,
    },

    validationSchema: Yup.object().shape({
      work_type: Yup.number().nullable().required("Field can't be empty!"),
      grid_zone_id: Yup.number().nullable().required("Field can't be empty!"),
      price: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const action = id && !isDuplicate ? updateWorkPriceAction : createWorkPriceAction;
      return dispatch(action({ work_type_price_id: id || undefined, ...values }))
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
