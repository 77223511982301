import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { createAssetModelAction, updateAssetModelAction } from 'modules/assetModels';
import { DateFormats } from 'constants/index';

interface Values {
  name: string;
  assetCategoryId: number | null;
  manufacturerId: number | null;
  replacementOption: boolean;
  replacementPriority: number | null;
  lastReplacementDate: Type.Moment | null;
}

const useConfiguredFormik = ({
  id,
  toggleModal,
  isReplacementPriorityDisabled,
}: {
  id: number | null;
  toggleModal: () => void;
  isReplacementPriorityDisabled: boolean;
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Values>({
    initialValues: {
      name: '',
      assetCategoryId: null,
      manufacturerId: null,
      replacementOption: false,
      replacementPriority: null,
      lastReplacementDate: null,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      assetCategoryId: Yup.number().nullable().required("Field can't be empty!"),
      manufacturerId: Yup.number().nullable().required("Field can't be empty!"),
      ...(!isReplacementPriorityDisabled && {
        replacementPriority: Yup.number().nullable().required("Field can't be empty!"),
      }),
    }),

    onSubmit: values => {
      const action = id ? updateAssetModelAction : createAssetModelAction;
      const lastReplacementDate =
        values.lastReplacementDate && moment(values.lastReplacementDate).format(DateFormats.SERVER);
      return dispatch(
        action({
          id: id || undefined,
          name: values.name,
          assetcategoryId: Number(values.assetCategoryId),
          manufacturerId: Number(values.manufacturerId),
          replacementOption: Boolean(values.replacementOption),
          replacementPriority: values.replacementPriority,
          lastReplacementDate,
        })
      ).then(toggleModal);
    },
  });

  return formik;
};

export default useConfiguredFormik;
