import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { categoriesCNAIMOptionsSelector } from 'modules/assets/cnaim.selectors';
import { fetchSelectAssetCategoriesCNAIMOptionsAction } from 'modules/assets';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Type.SelectOption | null;
  setValueAction: (option: Type.SelectOption) => void;
  labelKey?: '';
}

const SelectAssetCategoriesCNAIM: React.FC<Props> = ({ labelKey = 'Asset categories', setValueAction, ...rest }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const options = useSelector(categoriesCNAIMOptionsSelector);

  useEffect(() => {
    if (options.length) return;
    dispatch(fetchSelectAssetCategoriesCNAIMOptionsAction());
  }, [dispatch, options]);

  return (
    <FormReactSelect
      isLoading={!options.length}
      isDisabled={!options.length}
      labelKey={labelKey}
      options={options}
      onChange={setValueAction}
      {...rest}
    />
  );
};

export default SelectAssetCategoriesCNAIM;
