import * as Yup from 'yup';
import { useFormik, FormikProps as Props, FormikErrors } from 'formik';
import { useDispatch } from 'react-redux';
import { createManualAction, updateManualAction } from 'modules/manuals';

interface Values {
  file: string;
  filePreview: string;
  name: string;
  categoryId: number | null;
  assetCode: string;
  modelIds: number[];
}

export type FormikProps = Props<Values>;
export type Errors = FormikErrors<Values>;

const useConfiguredFormik = (manualId: number | null) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Values>({
    initialValues: {
      file: '',
      filePreview: '',
      name: '',
      categoryId: null,
      assetCode: '',
      modelIds: [],
    },

    validationSchema: Yup.object().shape({
      file: Yup.string().required('Please upload PDF file first!'),
      name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const categoryId: number | null = values.categoryId ? Number(values.categoryId) : null;
      const assetCode: string | null = values.assetCode || null;

      if (!manualId) {
        const formData: FormData = new FormData();
        formData.append('datamanual', values.file);
        formData.append('preview', values.filePreview);
        formData.append('name', values.name);
        return dispatch(createManualAction({ formData, modelIds: values.modelIds, categoryId, assetCode }));
      }
      return dispatch(
        updateManualAction({ manualId, nextName: values.name, modelIds: values.modelIds, categoryId, assetCode })
      );
    },
  });

  return formik;
};

export default useConfiguredFormik;
