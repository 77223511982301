import styled from 'styled-components';
import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { deleteAssetPhotosAction } from 'modules/assets';
import { Alert, Gallery, Button } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { IconDelete } from '@utiligize/shared/resources';

interface Props {
  assetMeta: Asset.ExtendedItem;
  uuid: string;
}

const TabPhoto: React.FC<Props> = ({ uuid, assetMeta }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [images, setImages] = useState<Type.GalleryImage[]>(_cloneDeep(assetMeta.images || []));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isDeletePhotosButtonVisible = useMemo(() => images.some(image => image.isSelected), [images]);

  const handleSelectImage = useCallback((index: number, image: Type.GalleryImage) => {
    setImages(prevImages => {
      const nextImages = prevImages.slice();
      nextImages[index].isSelected = !image.isSelected;
      return nextImages;
    });
  }, []);

  const handleCancelButtonClick = useCallback(() => setImages(_cloneDeep(assetMeta.images || [])), [assetMeta.images]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsSubmitting(true);
    const { attachmentKeys, nextImages } = images.reduce(
      (acc: { attachmentKeys: string[]; nextImages: Type.GalleryImage[] }, image: Type.GalleryImage) => {
        if (image.isSelected) {
          acc.attachmentKeys.push(image.gcsFileName);
        } else {
          acc.nextImages.push(image);
        }
        return acc;
      },
      { attachmentKeys: [], nextImages: [] }
    );

    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Do you want to delete selected photos?',
        onConfirm: async () =>
          dispatch(deleteAssetPhotosAction({ uuid, attachmentKeys }))
            .then(() => setImages(nextImages))
            .finally(() => setIsSubmitting(false)),
      })
    );
  }, [uuid, images, dispatch]);

  if (!images.length)
    return <Alert className="mb-0 text-center">{getIntl('There is no data for selected asset')}</Alert>;

  return (
    <StyledContainer data-button-visible={isDeletePhotosButtonVisible}>
      <Gallery rowHeight={220} images={images} enableImageSelection onSelectImage={handleSelectImage} />
      {isDeletePhotosButtonVisible && (
        <StyledControlsContainer>
          <Button
            variant="primary-outline"
            labelKey="Cancel"
            onClick={handleCancelButtonClick}
            disabled={isSubmitting}
          />
          <Button
            labelKey="Delete photos"
            marginLeft
            icon={<IconDelete />}
            onClick={handleDeleteButtonClick}
            disabled={!assetMeta?.images.length}
            variant="danger"
          />
        </StyledControlsContainer>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<{ 'data-button-visible': boolean }>`
  margin-bottom: ${props => (props['data-button-visible'] ? 63 : 0)}px;
`;

const StyledControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 20px;
  background: ${props => props.theme.colors.white};
  border-top: solid 1px ${props => props.theme.colors.grey100};
  box-shadow: 0px -4px 9px rgba(0, 0, 0, 0.05);

  > button {
    width: 100%;
  }
`;

export default TabPhoto;
