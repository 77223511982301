import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { setInfoToastAction } from 'modules/layouts';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string | string[] | number | number[] | null;
  optionsSelector: (state: State.Root) => Type.SelectOption[] | null;
  optionsAction: () => any;
}

const SelectRedux: React.FC<Props> = ({
  value,
  onChange,
  optionsSelector,
  optionsAction,
  isClearable,
  isMulti,
  ...rest
}) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const options = useSelector(optionsSelector);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option: any) => {
        const localizedOption = { ...option, label: getIntl(option.label) };
        if (isMulti ? (value as any)?.includes(option.value) : option.value === value) {
          acc.selectValues.push(localizedOption);
        }
        acc.selectOptions.push(localizedOption);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMulti, value, options, getIntl, lng]);

  useEffect(() => {
    if (options) return;
    setIsLoading(true);
    dispatch(optionsAction()).finally(() => setIsLoading(false));
  }, [options, dispatch, optionsAction]);

  const handleSelectChange = useCallback(
    (value: Type.SelectOption | Type.SelectOption[], actionMeta: { name: string }) => {
      if (isMulti && !isClearable && !(value as Type.SelectOption[]).length) {
        return dispatch(setInfoToastAction('This is required field, please keep one option at least'));
      }
      onChange?.(value, actionMeta);
    },
    [isMulti, isClearable, onChange, dispatch]
  );

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey=""
      value={selectValues}
      options={selectOptions}
      onChange={handleSelectChange as any}
      {...rest}
      isMulti={isMulti}
      isClearable={isClearable}
    />
  );
};

export default SelectRedux;
