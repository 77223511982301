import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';

const baseUrl = 'api/admin/v2/secure/tasks/external/unassigned';

export const fetchNewTasksAction: any = createAction(
  'tasks/FETCH_NEW_TASKS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'newTasksCount' | 'newTasksHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.NEW_TASKS)(state);
      return axios
        .get(baseUrl, {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? '' : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          newTasksCount: res.data.count,
          newTasksHash: _keyBy(res.data.rows, (item: Tasks.NewTask) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const deleteNewTaskAction: any = createAction(
  'tasks/DELETE_NEW_TASK',
  (taskNumber: string) => (dispatch: Shared.CustomDispatch) => {
    return axios.delete(`${baseUrl}/${taskNumber}`).then(async () => {
      await dispatch(fetchNewTasksAction());
      dispatch(setSuccessToastAction('New task has been deleted'));
    });
  }
);
