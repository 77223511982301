import React from 'react';
import styled from 'styled-components';
import { OverlayTriggerTooltip } from 'components/_common';
import { useLocale } from 'hooks';

interface Props {
  text: string;
  type?: 'dot' | 'question-mark';
}

const LayerHint: React.FC<Props> = ({ text, type = 'question-mark' }) => {
  const { getIntl } = useLocale();
  return (
    <OverlayTriggerTooltip placement="top" overlayId="stationHint" overlayChildren={getIntl(text)}>
      <span className="map-legend-hint">
        {type === 'question-mark' ? <i className="far fa-question-circle" /> : <StyledDot />}
      </span>
    </OverlayTriggerTooltip>
  );
};

const StyledDot = styled.div`
  background-color: var(--map-active-color);
  border-radius: 50%;
  width: 6px;
  height: 6px;
`;

export default LayerHint;
