import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setLayoutAction } from 'modules/layouts';
import { investmentScenarioModalMetaSelector } from 'modules/layouts/selectors';
import { investmentScenarioSelectorFactory } from 'modules/setup/selectors';
import { Modal, Button, FormInput, HiddenFormSubmit, SelectComponentPriceScenario, Checkbox } from 'components/_common';
import SelectScenarioType from './SelectScenarioType';
import ElectricityPriceScenarioInfo from './ElectricityPriceScenarioInfo';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalInvestmentScenarios: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { id, isDuplicate }: Layouts.ModalMeta = useSelector(investmentScenarioModalMetaSelector) || {
    id: null,
    isDuplicate: false,
  };
  const scenario: Setup.InvestmentScenario | undefined = useSelector(investmentScenarioSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && scenario);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ investmentScenarioModalMeta: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useConfiguredFormik({ id, toggleModal, isDuplicate });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!scenario) return;
    setValues({
      description: isDuplicate ? `${scenario.description} (${getIntl('copy')})` : scenario.description,
      forecasted_scenario_id: scenario.forecasted_scenario_id,
      component_price_scenario_id: scenario.component_price_scenario_id,
      interest_rate: scenario.interest_rate,
      sso_users_id: scenario.sso_users_id,
      loss_optimisation: Boolean(scenario.loss_optimisation),
    });
    setShow(true);
  }, [scenario, isDuplicate, getIntl, setValues]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, { name }: Type.SelectActionMetaBase) => setFieldValue(name, option?.value || null),
    [setFieldValue]
  );

  const handleLossOptimisationCheckboxClick = useCallback(
    (event: React.SyntheticEvent) => {
      const { name, checked: loss_optimisation } = event.target as HTMLInputElement;
      setFieldValue(name, loss_optimisation);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create scenario" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? (isDuplicate ? 'Duplicate scenario' : 'Edit scenario') : 'Create scenario'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              labelKey="Scenario name"
              name="description"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectScenarioType
              name="forecasted_scenario_id"
              value={values.forecasted_scenario_id}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('forecasted_scenario_id')}
              errorKey={
                Boolean(touched.forecasted_scenario_id && errors.forecasted_scenario_id)
                  ? (errors.forecasted_scenario_id as string)
                  : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <SelectComponentPriceScenario
              name="component_price_scenario_id"
              value={values.component_price_scenario_id}
              mutedTextLabelKey=""
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('component_price_scenario_id')}
              errorKey={
                Boolean(touched.component_price_scenario_id && errors.component_price_scenario_id)
                  ? (errors.component_price_scenario_id as string)
                  : ''
              }
            />
          </Form.Group>
          <ElectricityPriceScenarioInfo />
          <Form.Group>
            <FormInput
              labelKey="Interest rate (%)"
              type="number"
              min={0}
              name="interest_rate"
              value={values.interest_rate ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.interest_rate && errors.interest_rate) ? (errors.interest_rate as string) : ''}
            />
          </Form.Group>
          <Form.Group className="mb-0">
            <Checkbox
              className="pl-0 icheck-primary"
              name="loss_optimisation"
              checked={values.loss_optimisation}
              labelKey="Loss optimisation"
              onChange={handleLossOptimisationCheckboxClick}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalInvestmentScenarios;
