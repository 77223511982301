import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { MaxMobileWidth } from 'constants/index';

const useWindowSize = () => {
  const [isMobile, setIsMobile] = React.useState<boolean>(window.innerWidth <= MaxMobileWidth);

  const debounced = useDebouncedCallback(
    () => {
      setIsMobile(() => window.innerWidth <= MaxMobileWidth);
    },
    500,
    { maxWait: 2000 }
  );

  React.useEffect(() => {
    window.addEventListener('resize', debounced, { passive: true });

    return () => {
      window.removeEventListener('resize', debounced);
      debounced.cancel();
    };
  }, [debounced]);

  return isMobile;
};

export default useWindowSize;
