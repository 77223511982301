import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { mapLoadingSelector } from 'modules/map/selectors';

const LoadingNotification: React.FC = () => {
  const { getIntl } = useLocale();
  const loading = useSelector(mapLoadingSelector);

  if (!loading) return null;
  return (
    <StyledContainer>
      <span className="mr-2">{getIntl('Loading, please wait')}</span>
      <i className="fas fa-spinner fa-pulse" />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: inline-flex;
  position: absolute;
  top: 15px;
  left: 15px;
  align-items: center;
  padding: 7px;
  z-index: 999;
  user-select: none;
  opacity: 0.9;
  box-shadow:
    0px 4px 4px rgb(50 50 71 / 8%),
    0px 4px 8px rgb(50 50 71 / 6%);
  border: 0.5px solid #d4d4d4;
  border-radius: 4px;
  background: #fff;
  color: var(--map-text-color-primary);
  font-size: 12px;
`;

export default LoadingNotification;
