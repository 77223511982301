import { paintLayer, layoutLayer, getLayer, getAssetLayerFilters, filterLayer } from 'utils/map';
import { assetOpacity } from 'components/Map/core/layers/assets';
import { RouteType } from 'constants/map';

export const filterN1 = (
  map: Map.MapboxMap,
  n1Routes: Map.N1RouteItem[] | null,
  selectedN1RouteId: number | null,
  settings: Map.MapSettings
) => {
  const layers = settings.n1Layers?.filter(i => !/highlight$/.test(i));
  const primaryLayers = layers?.filter(i => !i.includes('additional'));
  const secondaryLayers = layers?.filter(i => i.includes('additional'));

  const themeFilter = ['==', ['get', 'in_theme'], true];

  const hasRoutes = Boolean(n1Routes?.length);

  const routes = n1Routes?.reduce(
    (acc, route) => {
      const primary = route.type === RouteType.PRIMARY;
      for (const [layer, ids] of Object.entries(route.ids)) {
        if (!acc[layer]) acc[layer] = [];
        for (const id of ids) {
          const found = acc[layer].find(a => a.id === id);
          if (found) found.primary = found.primary === true ? found.primary : primary;
          else acc[layer].push({ id, primary });
        }
      }
      return acc;
    },
    {} as Record<string, { id: number; primary: boolean }[]>
  );

  const idsToFilter = (ids: number[]) => (ids.length > 0 ? ['any', ...ids.map(i => ['==', ['id'], i])] : false);

  const getRouteFilter = (layer: string) => {
    const ids = routes?.[layer.replace(/_voltage.*/gi, '')]?.map(i => i.id) ?? [];
    return hasRoutes ? idsToFilter(ids) : true;
  };

  primaryLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const routeFilter = getRouteFilter(i);
    const primaryFilter = hasRoutes ? routeFilter : themeFilter;
    filterLayer(map, i, ['all', voltageFilter, primaryFilter, assetFilter, textFilter]);
  });

  secondaryLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const routeFilter = getRouteFilter(i.replace(/_voltage.*/gi, ''));
    const secondaryFilter = ['!', hasRoutes ? routeFilter : themeFilter];
    filterLayer(map, i, ['all', voltageFilter, secondaryFilter, assetFilter, textFilter]);
  });

  primaryLayers
    ?.filter(i => !i.endsWith('background'))
    .forEach(i => {
      const layer = getLayer(i);
      const paintObject = (layer?.style?.paint ?? {}) as Record<string, any>;
      const layoutObject = (layer?.style?.layout ?? {}) as Record<string, any>;
      const paintObjectKeys = Object.keys(paintObject);
      const layoutObjectKeys = Object.keys(layoutObject);
      const colorKeys = paintObjectKeys.filter(k => /(line-color|circle-color)/.test(k));
      const opacityKeys = paintObjectKeys.filter(k => k.endsWith('opacity'));
      const imageKey = layoutObjectKeys.find(k => k.includes('icon-image'));
      const routeLayer = routes?.[i.replace(/_voltage.*/gi, '')];
      const isRouteExist = Boolean(routeLayer);
      const primaryIds = routeLayer?.filter(i => i.primary).map(i => i.id) ?? [];
      const secondaryIds = routeLayer?.filter(i => !i.primary).map(i => i.id) ?? [];
      const primaryFilter = idsToFilter(primaryIds);
      const secondaryFilter = idsToFilter(secondaryIds);

      opacityKeys.forEach(opacityKey => paintLayer(map, i, opacityKey, assetOpacity));

      colorKeys.forEach(colorKey => {
        const defaultColor = layer?.style?.paint?.[colorKey as keyof mapboxgl.AnyPaint];
        const routeColor = [
          'case',
          ['to-boolean', ['feature-state', 'hover']],
          '#7345e0',
          primaryFilter,
          '#007bfe',
          secondaryFilter,
          '#ffc107',
          '#918f86',
        ];
        paintLayer(map, i, colorKey, isRouteExist ? routeColor : defaultColor);
      });

      if (imageKey) {
        const imageStyle = layoutObject[imageKey] as string[];
        if (Array.isArray(imageStyle)) {
          const imageName = imageStyle[imageStyle.length - 1].split('-')[1];
          const defaultImage = layer?.style?.layout?.[imageKey as keyof mapboxgl.AnyPaint];
          const routeImage = [
            'case',
            primaryFilter,
            `icon-${imageName}-blue`,
            secondaryFilter,
            `icon-${imageName}-yellow`,
            `icon-${imageName}-gray`,
          ];
          layoutLayer(map, i, imageKey, isRouteExist ? routeImage : defaultImage);
        }
      }
    });

  if (n1Routes) {
    settings.highlightLayers?.forEach(i => {
      const { voltageFilter } = getAssetLayerFilters(i);
      filterLayer(map, i, ['all', voltageFilter, false]);
    });
    const route = n1Routes.find(p => p.id === selectedN1RouteId)?.ids;

    if (!route) return;

    for (const [layer, ids] of Object.entries(route)) {
      const highlightLayer = layer.replace(/_voltage.*/gi, '');
      const filters = ids.map(id => ['==', ['id'], id]);
      settings
        .highlightLayers!.filter(i => i.includes(highlightLayer))
        .forEach(i => {
          const { voltageFilter } = getAssetLayerFilters(i);
          filterLayer(map, i, ['all', voltageFilter, ['any', ...filters]]);
        });
    }
  }
};
