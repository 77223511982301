import React from 'react';
import { useLocale } from 'hooks';
import { useSelector } from 'react-redux';
import { featureTogglesSelector, paginationSelectorFactory } from 'modules/layouts/selectors';
import { NetworkLoadingFusesTable, NetworkLoadingFusesForecastTable } from 'components/_tables';
import { ProfileModal } from 'components/_modals';
import { Tabs, Tab, ChartIframe, ContentContainer } from 'components/_common';
import { Routes, StorageKeys } from 'constants/index';

interface Props {
  type: Type.PaginationType;
}

const TabsFuses: React.FC<Props> = ({ type }) => {
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const featureToggles = useSelector(featureTogglesSelector);
  const { getIntl, lng } = useLocale();

  return (
    <Tabs mountOnEnter>
      <Tab unmountOnExit eventKey={Routes.NetworkLoadingFuses} title={getIntl('Scenarios')}>
        <NetworkLoadingFusesTable />
        <ProfileModal />
      </Tab>
      {featureToggles[StorageKeys.FEATURE_TOGGLE_NETWORK_LOADING_FORECAST] && (
        <Tab
          unmountOnExit
          eventKey={`${Routes.NetworkLoadingFuses}${Routes.HASH.Forecast}`}
          title={getIntl('Forecast')}
        >
          <NetworkLoadingFusesForecastTable />
          <ProfileModal />
        </Tab>
      )}
      <Tab eventKey={`${Routes.NetworkLoadingFuses}${Routes.HASH.Chart}`} title={getIntl('Chart')}>
        <ContentContainer>
          <ChartIframe
            title="Plot fuses loading histogram"
            src={`load/load_fuses_hist_plot?voltage=${filters?.voltage}&year=${filters?.year}&lang=${lng.toLowerCase()}`}
          />
        </ContentContainer>
      </Tab>
    </Tabs>
  );
};

export default TabsFuses;
