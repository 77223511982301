import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { editPermissionsFeatureAction } from 'modules/permissions';

type InitialValues = {
  description: string;
  tenantsIds: number[];
};

interface Props {
  id: number;
  initialValues: InitialValues;
  closeModal: () => void;
}

const useConfiguredFormik = ({ id, initialValues, closeModal }: Props) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<InitialValues>({
    initialValues,

    enableReinitialize: true,

    onSubmit: values => {
      return dispatch(editPermissionsFeatureAction(id, values)).then(closeModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
