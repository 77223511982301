import React from 'react';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'modules/map/selectors';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import AuxiliaryLayersOther from './AuxiliaryLayersOther';
import AuxiliaryLayersCNAIM from './AuxiliaryLayersCNAIM';
import GroupContainer from './GroupContainer';
import GroupTitle from './GroupTitle';
import GroupSection from './GroupSection';

const GroupAuxiliaryLayers: React.FC = () => {
  const settings = useSelector(settingsSelector);
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  if ((!settings.hasOther && !settings.hasCnaim) || singleDiagramEnabled) return null;
  return (
    <GroupContainer>
      <GroupTitle titleKey="Auxiliary layers" />
      {settings.hasOther && (
        <GroupSection>
          <AuxiliaryLayersOther />
        </GroupSection>
      )}
      {settings.hasCnaim && (
        <GroupSection>
          <AuxiliaryLayersCNAIM />
        </GroupSection>
      )}
    </GroupContainer>
  );
};

export default GroupAuxiliaryLayers;
