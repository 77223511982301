import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createMaintenancePlanAction, updateMaintenancePlanAction } from 'modules/maintenance';
import { AssetCategories, MaintenanceMissingActions } from 'constants/index';

type Values = Omit<Maintenance.Plan, 'id'>;

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Values>({
    initialValues: {
      planName: '',
      assetCategory: AssetCategories.BD,
      dateSelect: '',
      date_explicit: false,
      missingAction: MaintenanceMissingActions.START,
      intervalYears: 1,
    },

    validationSchema: Yup.object().shape({
      planName: Yup.string().required("Field can't be empty!"),
      intervalYears: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const action = id ? updateMaintenancePlanAction : createMaintenancePlanAction;
      return dispatch(id ? action(id, values) : action(values))
        .then(toggleModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
