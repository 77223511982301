import { getPointStyle, getColumnStyle, getHighlightStyle } from 'components/Map/core/layers/assets';
import { needColumnRegex, hasAdditionalRegex } from 'components/Map/core/layers/assets';
import { voltageColors, getVoltageIcons, voltageRatio, columnZoomThreshold } from 'components/Map/core/layers/assets';
import { MapParams } from 'constants/index';

const renderPoint =
  (minZ: number, size: [number, number], highlightSize?: object | number[]) =>
  (legendItem: Map.LegendItem, settings: Map.MapSettings) => {
    const minZoom = hasAdditionalRegex.test(legendItem.name) ? MapParams.minZoom : minZ;
    const needColumn = Boolean(highlightSize) && needColumnRegex.test(legendItem.name);
    const hasAdditional = hasAdditionalRegex.test(legendItem.name);
    const highlightSizeRatio = 2;
    const circleSize = [10, ['*', size[0], voltageRatio], MapParams.maxZoom, ['*', size[1], voltageRatio]];
    const highlightCircleSize = needColumn
      ? highlightSize
      : [
          10,
          ['*', size[0] * highlightSizeRatio, voltageRatio],
          MapParams.maxZoom,
          ['*', size[1] * highlightSizeRatio, voltageRatio],
        ];
    const style = getPointStyle({
      legendItem,
      color: ['case', ...voltageColors],
      iconClass: 'legend-filter-circle',
      maxZoom: needColumn ? columnZoomThreshold : MapParams.maxZoom,
      minZoom,
      circleSize,
      settings,
      hasAdditional,
    });
    const columnStyle = getColumnStyle({
      legendItem,
      color: ['case', ...voltageColors],
      transparencyThreshold: columnZoomThreshold,
      settings,
    });
    const highlightStyle = getHighlightStyle({
      legendItem,
      circleSize: highlightCircleSize,
      settings,
    });
    return [...style, needColumn && columnStyle, ...highlightStyle].filter(Boolean) as Map.Style[];
  };

const renderIcon =
  (minZ: number, size: [number, number], highlightSize: [number, number], icons = getVoltageIcons('bolt')) =>
  (legendItem: Map.LegendItem, settings: Map.MapSettings) => {
    const minZoom = hasAdditionalRegex.test(legendItem.name) ? MapParams.minZoom : minZ;
    const hasAdditional = hasAdditionalRegex.test(legendItem.name);
    const defaultIcon = ['case', ...icons] as any[];
    const greyIcon = defaultIcon[defaultIcon.length - 1] as string;
    const themeIcon = ['case', ['boolean', ['get', 'in_theme'], false], defaultIcon, greyIcon] as any[];
    const iconSize = [10, ['*', size[0], voltageRatio], MapParams.maxZoom, ['*', size[1], voltageRatio]];
    const highlightCircleSize = [
      10,
      ['*', highlightSize[0], voltageRatio],
      MapParams.maxZoom,
      ['*', highlightSize[1], voltageRatio],
    ];
    const style = getPointStyle({
      legendItem,
      icon: !legendItem.name.startsWith('asset') ? themeIcon : defaultIcon,
      iconAdditional: greyIcon,
      iconSize,
      iconClass: 'legend-filter-icon',
      minZoom,
      settings,
      hasAdditional,
    });
    const highlightStyle = getHighlightStyle({
      legendItem,
      minZoom,
      hasAdditional,
      circleSize: highlightCircleSize,
      settings,
    });
    return [...style, ...highlightStyle].filter(Boolean) as Map.Style[];
  };

const renderDefaultPoint = (minZ: number) => renderPoint(minZ, [2, 30]);

const renderDefaultIcon = (minZ: number) => renderIcon(minZ, [0.05, 0.5], [5, 45]);

export const renderTransformers = renderPoint(MapParams.minZoom, [2, 50], {
  stops: [
    [0, 0],
    [25, 15000],
  ],
  base: 2,
});

export const renderCabinets = renderPoint(12, [2, 40]);

export const renderLoadingSwitches = renderDefaultIcon(14);

export const renderSwitches = renderDefaultIcon(16);

export const renderStreetLightCabinets = renderDefaultPoint(8);

export const renderStreetLights = renderIcon(12, [0.08, 1.0], [5, 60], getVoltageIcons('light'));

export const renderCableConnectors = renderIcon(16, [0.05, 0.5], [5, 45], getVoltageIcons('link'));

export const renderRTU = renderPoint(12, [2, 20]);
