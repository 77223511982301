import styled from 'styled-components';
import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec, useLocale, useSplitPermissionsByGroups } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsTenantsModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { permissionsTenantSelectorFactory } from 'modules/permissions/selectors';
import { Modal, Alert } from 'components/_common';
import { sortPermissions } from 'utils';
import useConfiguredFormik from './useConfiguredFormik';

const ModalTenants: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id: number | null = useSelector(permissionsTenantsModalIdSelector);
  const tenant: Permissions.Tenant | null = useSelector(permissionsTenantSelectorFactory(id));
  const [show, setShow] = useState(false);

  const closeModal = useCallback((): void => {
    dispatch(setLayoutAction({ permissionsTenantsModalId: null }));
    setShow(false);
  }, [dispatch]);

  const { values, touched, errors, isSubmitting, handleChange, submitForm, resetForm } = useConfiguredFormik({
    id,
    tenantPermissions: tenant
      ? (Object.keys(tenant.permissionsTypes!) as Permissions.PermissionsTypes[])
          .sort(sortPermissions)
          .reduce((acc: Permissions.Permissions, key: string) => {
            (acc as any)[key] = tenant.permissionsTypes![key as Permissions.PermissionsTypes];
            return acc;
          }, {} as Permissions.Permissions)
      : ({} as Permissions.Permissions),
    closeModal,
    tenantName: tenant?.tenantName || '',
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (id && tenant) setShow(true);
    if (!show) resetForm();
  }, [id, tenant, show, resetForm]);

  const { views, taskType, assetCategories, readWrite } = useSplitPermissionsByGroups({
    objKey: 'tenantPermissions',
    permissions: values.tenantPermissions,
    onChange: handleChange,
  });

  return (
    <Modal
      show={show}
      onHide={closeModal}
      titleKey="Edit tenant"
      cancelButtonProps={{
        disabled: isSubmitting,
        onClick: closeModal,
      }}
      submitButtonProps={{
        labelKey: 'Update',
        loading: isSubmitting,
        onClick: handleFormSubmit,
      }}
    >
      <StyledForm onSubmit={handleFormSubmit}>
        <Form.Row>{views}</Form.Row>
        {Boolean(touched.tenantPermissions && errors.tenantPermissions) && (
          <Alert show variant="warning" className="mt-3 mb-0">
            {getIntl(errors.tenantPermissions as string)}
          </Alert>
        )}
        <hr />
        <Form.Row>{taskType}</Form.Row>
        <hr />
        <Form.Row>{assetCategories}</Form.Row>
        <hr />
        <Form.Row>{readWrite}</Form.Row>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled(Form)`
  .form-row > .form-group:last-child {
    margin-bottom: 0;
  }
`;

export default ModalTenants;
