import { createAction } from 'redux-actions';
import { AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const getCO2EmissionChartData: any = createAction(
  'co2e/GET_SAVING_OVER_TIME_CHART_DATA',
  async ({
    scenarioId,
    isCO2eCumulativeOverview,
  }: {
    scenarioId: Layouts.Root['scenarioId'];
    isCO2eCumulativeOverview: Layouts.Root['isCO2eCumulativeOverview'];
  }) =>
    (): Promise<any> => {
      return AssetLifeAPI.get('scenarios/co2e_dashboard_data', {
        params: {
          scenario_id: scenarioId,
          cumulative: isCO2eCumulativeOverview,
        },
      }).then(res => res.data);
    }
);
