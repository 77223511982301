import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { MapContext } from 'context/map';
import { mapStateAction } from 'modules/map';
import { createMapStateSelectorFactory } from 'modules/map/selectors';
import { Button } from 'components/_common';
import { showLayer, hideLayer } from 'utils/map';
import { IconHome } from '@utiligize/shared/resources';

const BuildingsButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { map } = useContext(MapContext);

  const buildingsVisibility = useSelector(createMapStateSelectorFactory('buildingsVisibility'));

  const handleButtonClick = useCallback(() => {
    if (!map) return;
    const visibility = map.getLayoutProperty('mapbox-buildings', 'visibility');
    const state = visibility === 'visible' ? 'none' : 'visible';
    const action = state === 'visible' ? showLayer : hideLayer;
    action(map, 'mapbox-buildings');
    dispatch(mapStateAction({ buildingsVisibility: !buildingsVisibility }));
  }, [map, dispatch, buildingsVisibility]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: buildingsVisibility })}
      onClick={handleButtonClick}
      icon={<IconHome />}
      tooltipKey={buildingsVisibility ? 'Hide buildings' : 'Show buildings'}
      tooltipPlacement="left"
    />
  );
};

export default BuildingsButton;
