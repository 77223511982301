export const getSyncedFilters = ({
  types,
  action,
  state,
}: {
  types: Type.PaginationType[];
  action: Shared.ReduxAction<{ type: Type.PaginationType; modifier: Partial<Layouts.Pagination> }>;
  state: Layouts.Root;
}) => {
  if (!types.includes(action.payload.type) || !action.payload.modifier.hasOwnProperty('filters')) return {};
  return types.reduce(
    (acc, type) => ({
      ...acc,
      [type]: { ...state.pagination[type], ...action.payload.modifier },
    }),
    {}
  );
};
