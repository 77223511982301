import { MapParams } from 'constants/index';
import {
  zoomInterpolateRatio,
  highlightActiveOpacity,
  highlightInactiveOpacity,
} from 'components/Map/core/layers/assets';

export const renderOtherCustomersDER = (legendItem: Map.LegendItem) => {
  const style: Map.Style = {
    source: {
      id: legendItem.name,
      src: {
        type: 'vector',
        bounds: legendItem.bounds,
        minzoom: legendItem.minz,
        maxzoom: legendItem.maxz,
        tiles: [
          `${process.env.REACT_APP_API_URL}/api/admin/v1/secure/map/tile/${legendItem.name}/${legendItem.type}/{z}/{x}/{y}`,
        ],
      },
    },
    layer: {
      legend: {
        id: legendItem.name,
        title: legendItem.title,
        isAsset: legendItem.asset,
        type: 'circle',
        iconClass: `legend-icon legend-filter-icon`,
        filters: legendItem.filters,
      },
      sublayers: [
        {
          id: `${legendItem.name}_highlight`,
          type: 'circle',
          filter: false,
          minzoom: legendItem.minz,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          paint: {
            'circle-color': '#7345e0',
            'circle-stroke-color': '#7345e0',
            'circle-stroke-width': 3,
            'circle-stroke-opacity': [
              'case',
              [
                'any',
                ['boolean', ['feature-state', 'select'], false],
                ['boolean', ['feature-state', 'highlight'], false],
              ],
              highlightActiveOpacity,
              highlightInactiveOpacity,
            ],
            'circle-opacity': [
              'case',
              [
                'any',
                ['boolean', ['feature-state', 'select'], false],
                ['boolean', ['feature-state', 'highlight'], false],
              ],
              highlightActiveOpacity,
              highlightInactiveOpacity,
            ],
            'circle-radius': [
              'interpolate',
              ['exponential', zoomInterpolateRatio],
              ['zoom'],
              10,
              5,
              MapParams.maxZoom,
              60,
            ],
            'circle-pitch-alignment': 'map',
          },
        },
        {
          id: legendItem.name,
          showPopup: true,
          hasData: true,
          type: 'symbol',
          minzoom: legendItem.minz,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          layout: {
            'icon-image': [
              'match',
              ['get', legendItem.filters?.property], // 'der_id'
              ...(legendItem.filters?.list.map(i => [i.id, i.icon]).flat() ?? []),
              'icon-customer-electric-vehicle', // default icon
            ],
            'icon-size': [
              'interpolate',
              ['exponential', zoomInterpolateRatio],
              ['zoom'],
              10,
              0.18,
              MapParams.maxZoom,
              1.0,
            ],
            'icon-anchor': 'center', // center of the icon is anchored to the coordinate
            'icon-pitch-alignment': 'map',
            'icon-ignore-placement': true,
            'icon-allow-overlap': true, // icon can overlap other elements
          },
        },
        {
          id: `${legendItem.name}_text`,
          type: 'symbol',
          minzoom: 15,
          source: legendItem.name,
          'source-layer': legendItem.table,
          order: legendItem.order,
          layout: {
            'text-field': ['get', 'der_value'],
            'text-size': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 10, MapParams.maxZoom, 30],
            'text-rotation-alignment': 'viewport',
            'text-max-width': Infinity,
            'text-offset': [
              'interpolate',
              ['exponential', 0.75],
              ['zoom'],
              10,
              ['literal', [0, 1]],
              MapParams.maxZoom,
              ['literal', [0, 1.2]],
            ],
            'text-allow-overlap': true,
            'text-ignore-placement': true,
          },
          paint: {
            'text-color': '#003166',
            'text-halo-color': '#fff',
            'text-halo-width': ['interpolate', ['exponential', 1.5], ['zoom'], 10, 0.6, MapParams.maxZoom, 2],
          },
        },
      ],
    },
  };
  return [style];
};
