import { isNKTenant } from 'utils';

export enum TopTabsIndexesMap {
  LiveData = 0,
  AssetDetails = isNKTenant ? 1 : 0,
  SubComponents = 2,
  Photo = 3,
  History = 4,
  Answers = 5,
  TaskCompletions = 6,
  DataManuals = 7,
  Customers = isNKTenant ? 8 : 1,
  Comments = isNKTenant ? 9 : 2,
}

export enum BottomTabsIndexesMap {
  Power = 0,
  Voltage = 1,
  Losses = 2,
  Profile = 3,
  Duration = 4,
  Reliability = 5,
  Tasks = 6,
}
