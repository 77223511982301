import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { HistoryAnswer } from 'components';
import { AnswersTable, HistoryTable } from 'components/_tables';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageHistory: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isHistoryEnabled}>
    <Switch>
      <Route
        exact
        path={Routes.HistoryAnswers}
        render={
          ((props: RouteComponentProps<{ id: string }>) => {
            return props.match.params.id ? (
              <HistoryAnswer {...props} />
            ) : (
              <AnswersTable containerClassName="border-top-0" />
            );
          }) as any
        }
      />
      <Route exact path={Routes.History}>
        <HistoryTable containerClassName="border-top-0" />
      </Route>
    </Switch>
  </PagePermissionsProvider>
);

export default PageHistory;
