import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { isPageTasksChecklistsTabActiveSelector } from 'modules/router/selectors';
import { tasksActiveTypeSelector } from 'modules/tasks/selectors';
import { TasksModal, ChecklistsModal } from 'components/_modals';
import { DataTableTemplate, DataTableHeaderFilters } from 'components/_common';
import { DataTableFilterKeys } from 'constants/index';
import TasksTabs from './TasksTabs';

const ViewTasks: React.FC = () => {
  const isPageTasksChecklistsTabActive = useSelector(isPageTasksChecklistsTabActiveSelector);
  const paginationType = useSelector(tasksActiveTypeSelector);

  return (
    <DataTableTemplate>
      <Row>
        {paginationType && (
          <DataTableHeaderFilters paginationType={paginationType} showKeys={[DataTableFilterKeys.USER_IDS]} />
        )}
        <Col>{isPageTasksChecklistsTabActive ? <ChecklistsModal /> : <TasksModal />}</Col>
      </Row>
      <TasksTabs />
    </DataTableTemplate>
  );
};

export default ViewTasks;
