import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import reduxThunkFsa from 'redux-thunk-fsa';
import reduxPromise from 'redux-promise';
import createRootReducer from './rootReducer';
import promiseCatch from '@utiligize/shared/middlewares/promiseCatch';
import { isDevelopment } from 'constants/index';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: () => void;
  }
}

export const history = createBrowserHistory();

const sentryReduxEnhancer = !isDevelopment && Sentry.createReduxEnhancer({});

const enhancers = [isDevelopment ? window.__REDUX_DEVTOOLS_EXTENSION__?.() : sentryReduxEnhancer].filter(Boolean);

const composedEnhancers = compose(
  applyMiddleware(promiseCatch, routerMiddleware(history), reduxThunkFsa, reduxPromise),
  ...enhancers
);

export const store = createStore(createRootReducer(history), composedEnhancers);
