import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { FormSelect, FormSelectProps, FormSelectOption } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFormTypesAction } from 'modules/forms';

interface Props extends Omit<FormSelectProps, 'options' | 'labelKey'> {
  fullObject?: boolean;
  onChange: (e: React.SyntheticEvent | Type.FormType) => void;
  formTypesSelector?: (state: State.Root) => Type.FormType[];
}

const SelectFormFormsTypes: React.FC<Props> = ({
  fullObject = false,
  onChange,
  formTypesSelector = () => [],
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const reduxFormTypes = useSelector(formTypesSelector);
  const [formTypes, setFormTypes] = useState<Type.FormType[]>(reduxFormTypes);

  useEffect(() => {
    if (formTypes?.length) return;
    dispatch(fetchFormTypesAction() as any).then((action: any) => setFormTypes(action.payload));
  }, [formTypes, dispatch]);

  const formTypesSelectOptions = useMemo(() => {
    return (formTypes || []).map(
      (formType: Type.FormType): FormSelectOption => ({ id: formType.name, name: formType.name }),
      [formTypes]
    );
  }, [formTypes]);

  const handleSelectChange = useCallback(
    (e: React.SyntheticEvent) => {
      if (!fullObject) return onChange(e);
      const formType = formTypes.find(formType => formType.id === Number((e.target as HTMLSelectElement).value));
      if (!formType) throw Error("FormFormsTypesSelect can't find formType object");
      return onChange(formType);
    },
    [fullObject, formTypes, onChange]
  );

  return <FormSelect {...props} labelKey="Form Type" options={formTypesSelectOptions} onChange={handleSelectChange} />;
};

export default SelectFormFormsTypes;
