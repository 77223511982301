import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import DrawAssetsButton from './DrawAssetsButton';
import SatelliteButton from './SatelliteButton';
import BuildingsButton from './BuildingsButton';
import ZoomInButton from './ZoomInButton';
import ZoomOutButton from './ZoomOutButton';
import BearingResetButton from './BearingResetButton';
import MapModeButton from './MapModeButton';
import LastUpdateButton from './LastUpdateButton';
import AddressSearchButton from './AddressSearchButton';
import CableAnimationButton from './CableAnimationButton';
import LabelsButton from './LabelsButton';
import { isDevelop, StorageKeys } from 'constants/index';

const Controls: React.FC = () => {
  const featureToggles = useSelector(featureTogglesSelector);
  return (
    <StyledContainer>
      {featureToggles[StorageKeys.FEATURE_TOGGLE_DRAW_ASSETS] && <DrawAssetsButton />}
      <ZoomInButton />
      <ZoomOutButton />
      <BearingResetButton />
      <AddressSearchButton />
      <SatelliteButton />
      <BuildingsButton />
      <MapModeButton />
      {isDevelop && <CableAnimationButton />}
      <LabelsButton />
      <LastUpdateButton />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  bottom: 45px;
  right: 10px;
  z-index: 1000;
  width: 32px;
`;

export default Controls;
