import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { getElectricityConsumptionChartData } from 'modules/customers';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import Chart, { transformAction, ChartDataProps, Colors } from './Chart';

interface Props {
  uuid: string | null;
  outliersRemoved: boolean;
}

const ProfileElectricityConsumptionChart: React.FC<Props> = ({ uuid, outliersRemoved }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);

  useEffect(() => {
    dispatch(getElectricityConsumptionChartData({ uuid, outliersRemoved, portfolioId }))
      .then(transformAction)
      .then(setChartData);
  }, [dispatch, uuid, outliersRemoved, portfolioId]);

  const options = useMemo(
    () => ({
      chart: {
        zoomType: 'x',
        type: 'line',
      },
      title: {
        text: chartData?.title,
      },
      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M',
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
      },
      yAxis: {
        title: {
          text: chartData?.yAxisTitle,
        },
        plotLines: [
          {
            color: '#c77cff',
            width: 2,
            zIndex: 5,
            value: chartData?.rating,
            label: {
              text: getIntl('Rating'),
              style: {
                color: '#c77cff',
                fontWeight: 'bold',
              },
            },
          },
        ],
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: '',
        },
      },
      series: Object.keys(chartData?.seriesHash || {}).map((key: string, index: number) => ({
        name: key,
        data: chartData?.seriesHash[key],
        lineWidth: 0.5,
        color: Colors[index],
        zIndex: chartData?.zIndex?.[key] || index + 1,
      })),
    }),
    [chartData, getIntl]
  ) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} />;
};

export default ProfileElectricityConsumptionChart;
