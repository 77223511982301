import React from 'react';
import { useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { investmentScenarioPriceSelector, investmentScenariosMetaCurrencySelector } from 'modules/setup/selectors';
import { Alert } from 'components/_common';

const ElectricityPriceScenarioInfo: React.FC = () => {
  const { getIntl } = useLocale();
  const price = useSelector(investmentScenarioPriceSelector);
  const currency = useSelector(investmentScenariosMetaCurrencySelector);

  if (!price || !currency) return null;
  return (
    <Alert variant="light">
      <small>
        {getIntl(
          'The electricity price used for this simulation is {{price_per_mwh}} {{currency}}/MWh, the average day-ahead base price of 2021 and 2022',
          { price_per_mwh: price, currency }
        )}
        .
      </small>
    </Alert>
  );
};

export default ElectricityPriceScenarioInfo;
