import React, { useState, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  reliabilityDistributionOptionSelector,
  reliabilityAssetCategoriesCNAIMOptionSelector,
} from 'modules/layouts/selectors';
import { setReliabilityAssetCategoriesCNAIMOption } from 'modules/layouts';
import { ChartIframe, SelectAssetCategoriesCNAIM, ContentContainer, ContentCard } from 'components/_common';
import SelectDistribution from './SelectDistribution';
import SelectScenarioParams from './SelectScenarioParams';
import PoFParamsInputs from './PoFParamsInputs';

const ViewPoFModels: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { lng } = useLocale();
  const reliabilityDistributionOption = useSelector(reliabilityDistributionOptionSelector);
  const reliabilityAssetCategoriesCNAIMOption = useSelector(reliabilityAssetCategoriesCNAIMOptionSelector);

  const [pofParams, setPofParams] = useState<{ [key: string]: number } | null>(null);

  const handlePoFParamsChange = useCallback((params: { [key: string]: number }): void => setPofParams(params), []);

  const handleAssetCategoryCNAIMChange = useCallback(
    (option: Type.SelectOption): void => {
      dispatch(setReliabilityAssetCategoriesCNAIMOption(option));
    },
    [dispatch]
  );

  return (
    <ContentContainer className="border-top-0">
      <ContentCard>
        <Row>
          <Col xs={3}>
            <Form.Group>
              <SelectDistribution />
            </Form.Group>
            <Form.Group>
              <SelectScenarioParams />
            </Form.Group>
            <Form.Group>
              <SelectAssetCategoriesCNAIM
                value={reliabilityAssetCategoriesCNAIMOption}
                setValueAction={handleAssetCategoryCNAIMChange}
              />
            </Form.Group>
            <PoFParamsInputs
              distribution={reliabilityDistributionOption?.value as string}
              onChange={handlePoFParamsChange}
            />
          </Col>
          <Col xs={9}>
            <ChartIframe
              title="Cumulative probability of failure"
              src={
                reliabilityDistributionOption?.value && pofParams
                  ? `plot_pof_cum?params=${JSON.stringify(
                      pofParams
                    )}&distribution=${reliabilityDistributionOption?.value}&lang=${lng}`
                  : ''
              }
              height="calc((100vh - 184px)/2)"
            />
            <ChartIframe
              title="Probability distribution function"
              src={
                reliabilityDistributionOption?.value && pofParams
                  ? `plot_pof_dist?params=${JSON.stringify(
                      pofParams
                    )}&distribution=${reliabilityDistributionOption?.value}&lang=${lng}`
                  : ''
              }
              height="calc((100vh - 184px)/2)"
              className="mt-3"
            />
          </Col>
        </Row>
      </ContentCard>
    </ContentContainer>
  );
};

export default ViewPoFModels;
