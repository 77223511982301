import React, { useCallback } from 'react';
import { useLocale } from 'hooks';
import { Button } from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';

const AddChildFieldButton: React.FC<{
  optionValue: string;
  index: number;
  onAddChildField: (index: number) => void;
}> = ({ optionValue, index, onAddChildField }) => {
  const { getIntl } = useLocale();

  const handleAddChildClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      onAddChildField(index);
    },
    [index, onAddChildField]
  );

  return (
    <Button
      icon={<IconPlus />}
      labelKey={`${getIntl('Add')} - ${optionValue}`}
      data-option-index={index}
      onClick={handleAddChildClick}
    />
  );
};

export default AddChildFieldButton;
