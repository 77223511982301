import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Plausible from 'plausible-tracker';
import { PageMain } from 'pages';
import { UserService } from '@utiligize/shared/services';
import { SetupAxios, Callback, Toasts } from 'components';
import { Routes, isProduction } from 'constants/index';
import '@utiligize/shared/index.scss';

const { enableAutoPageviews } = Plausible({ domain: 'assetlife.utiligize.com', hashMode: true });
if (isProduction) enableAutoPageviews();

const App: React.FC = () => (
  <SetupAxios>
    <Switch>
      <Route path={Routes.Callback} component={Callback} />
      <Route path={Routes.Root} render={props => (UserService.isLoggedIn() ? <PageMain {...props} /> : null)} />
    </Switch>
    <Toasts />
  </SetupAxios>
);

export default App;
