import React, { useState, useEffect, useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import { FormInput } from 'components/_common';
import { DataTableFilterKeys, TasksRegisteredHoursSourceTypes } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  values: Layouts.Filters;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Layouts.Filters>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const TaskIdOrCaseNumberInputFilter: React.FC<Props> = ({
  inDataTableHeaderFilters = false,
  values,
  setFieldValue,
  submitForm,
}) => {
  const [inputValue, setInputValue] = useState<number | null>(values.taskIdOrCaseNumber!);

  const debounced = useDebouncedCallback(async (newValue: number | null) => {
    await setFieldValue(DataTableFilterKeys.TASK_ID_OR_CASE_NUMBER, newValue);
    if (inDataTableHeaderFilters) submitForm();
  }, 1000);

  useEffect(() => {
    setInputValue(values.taskIdOrCaseNumber!);
  }, [values.taskIdOrCaseNumber]);

  useEffect(() => {
    return debounced.cancel;
  }, [debounced.cancel]);

  const handleInputChange = useCallback(
    (event: any) => {
      const newValue = Number(event.target.value) || null;
      setInputValue(newValue);
      debounced(newValue);
    },
    [debounced]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.TASK_ID_OR_CASE_NUMBER)) return null;

  const Input = (
    <FormInput
      type="text"
      placeholderKey={values.source === TasksRegisteredHoursSourceTypes.EXTERNAL ? 'External task id' : 'Task id'}
      value={String(inputValue || '')}
      onChange={handleInputChange}
    />
  );

  return inDataTableHeaderFilters ? <Col sm={2}>{Input}</Col> : <Form.Group>{Input}</Form.Group>;
};

export default TaskIdOrCaseNumberInputFilter;
