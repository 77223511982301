import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.users;

export const usersIsFetchedSelector = createSelector(rootSelector, (users: Users.Root): boolean => users.isFetched);

export const usersHashSelector = createSelector(
  rootSelector,
  (users: Users.Root): Type.Hash<Users.User> => users.usersHash
);
