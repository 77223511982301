import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.manuals;

const manualsItemsHashSelector = createSelector(
  rootSelector,
  (manuals: Manuals.Root): Type.Hash<Manuals.Item> => manuals.itemsHash
);

export const manualsItemsSelector = createSelector(
  manualsItemsHashSelector,
  (itemsHash: Type.Hash<Manuals.Item>): Manuals.Item[] => Object.values(itemsHash)
);

export const manualsItemByIdSelectorFactory = (id: number | null) =>
  createSelector(
    manualsItemsHashSelector,
    (itemsHash: Type.Hash<Manuals.Item>): Manuals.Item | null => itemsHash[`_${id}_`] || null
  );

export const manualsCountSelector = createSelector(rootSelector, (manuals: Manuals.Root): number => manuals.count);
