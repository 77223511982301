import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
import { taskCompletionsSelector, taskCompletionsCountSelector } from 'modules/assets/selectors';
import { fetchAssetTaskCompletionsAction } from 'modules/assets';
import { DataTable } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';

interface Props {
  assetCode: string;
}

const AssetTaskCompletionsTable: React.FC<Props> = ({ assetCode }) => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(taskCompletionsCountSelector);
  const items = useSelector(taskCompletionsSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchAssetTaskCompletionsAction({ assetCode })),
    [dispatch, assetCode]
  );

  const renderAnswerLink = (item: Tasks.TaskCompletion) => {
    if (!item.answer?.id) return '';
    return (
      <Link
        to={{
          pathname: generatePath(Routes.HistoryAnswers, { id: item.answer.id }),
          state: { prevRoute: window.location.pathname },
        }}
      >
        {item.answer.id}
      </Link>
    );
  };

  return (
    <DataTable paginationType={PaginationType.ASSET_TASK_COMPLETIONS} totalAmount={count} sendRequest={sendRequest}>
      {items.map((item: Tasks.TaskCompletion) => (
        <tr key={item.answer?.id + item.task?.id}>
          <td>{dateFormat(item.date)}</td>
          <td>{item.user.fullName}</td>
          <td>{item.user.email}</td>
          <td>
            <Link to={generatePath(Routes.Tasks, { id: item.task?.id })}>{item.task?.name}</Link>
          </td>
          <td>{renderAnswerLink(item)}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default AssetTaskCompletionsTable;
