import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.permissions;

const permissionsGroupsHashSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): Type.Hash<Permissions.Group> => permissions.groupsHash
);

export const permissionsGroupsSelector = createSelector(
  permissionsGroupsHashSelector,
  (groupsHash: Type.Hash<Permissions.Group>): Permissions.Group[] => Object.values(groupsHash)
);

export const permissionsGroupsCountSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): number => permissions.groupsCount
);

export const permissionsGroupSelectorFactory = (id: number | null) =>
  createSelector(
    permissionsGroupsHashSelector,
    (groupsHash: Type.Hash<Permissions.Group>): Permissions.Group | null => groupsHash[`_${id}_`] || null
  );

const permissionsUsersHashSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): Type.Hash<Users.User> => permissions.usersHash
);

export const permissionsUsersSelector = createSelector(
  permissionsUsersHashSelector,
  (usersHash: Type.Hash<Users.User>): Users.User[] => Object.values(usersHash)
);

export const permissionsUsersCountSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): number => permissions.usersCount
);

export const permissionsUserSelectorFactory = (id: number | null) =>
  createSelector(
    permissionsUsersHashSelector,
    (usersHash: Type.Hash<Users.User>): Users.User | null => usersHash[`_${id}_`] || null
  );

export const permissionsTypesSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): Permissions.PermissionsTypes[] | null => permissions.permissionsTypes
);

export const accessLogsCountSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): number => permissions.accessLogsCount
);

export const accessLogsSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): Permissions.AccessLogs[] => permissions.accessLogs
);

export const permissionsTenantsHashSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): Permissions.Root['tenantsHash'] => permissions.tenantsHash
);

export const permissionsTenantsSelector = createSelector(
  permissionsTenantsHashSelector,
  (tenantsHash: Permissions.Root['tenantsHash']): Permissions.Tenant[] => Object.values(tenantsHash)
);

export const permissionsTenantsCountSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): number => permissions.tenantsCount
);

export const permissionsTenantSelectorFactory = (id: number | null) =>
  createSelector(
    permissionsTenantsHashSelector,
    (tenantsHash: Permissions.Root['tenantsHash']): Permissions.Tenant | null => tenantsHash[`_${id}_`] || null
  );

const permissionsFeaturesHashSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): Permissions.Root['featuresHash'] => permissions.featuresHash
);

export const permissionsFeaturesSelector = createSelector(
  permissionsFeaturesHashSelector,
  (featuresHash: Permissions.Root['featuresHash']): Permissions.Feature[] => Object.values(featuresHash)
);

export const permissionsFeaturesCountSelector = createSelector(
  rootSelector,
  (permissions: Permissions.Root): number => permissions.featuresCount
);

export const permissionsFeatureSelectorFactory = (id: number | null) =>
  createSelector(
    permissionsFeaturesHashSelector,
    (featuresHash: Permissions.Root['featuresHash']): Permissions.Feature | null => featuresHash[`_${id}_`] || null
  );
