import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useLocale } from 'hooks';
import { fetchCategoriesCNAIMAssetsAction } from 'modules/assets';
import { AssetsCNAIMTable } from 'components/_tables';
import { Tabs, Tab, Alert, ContentContainer, Spinner } from 'components/_common';
import { Routes } from 'constants/index';

interface Props {
  id: number;
}

const AssetsCategoriesCNAIMDetails: React.FC<Props> = ({ id }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState<any[] | null>(null);

  useEffect(() => {
    dispatch(fetchCategoriesCNAIMAssetsAction(id))
      .then((action: Shared.ReduxAction<any[]>) => setItems(action.payload))
      .finally(() => setIsLoading(false));
  }, [id, dispatch]);

  if (isLoading) return <Spinner isInFullHeightContainer />;
  if (!items?.length) {
    return (
      <ContentContainer className="border-top-0">
        <Alert className="mb-0 text-center h-100" variant="light">
          {getIntl("Uploaded assets for selected category don't exist")}
        </Alert>
      </ContentContainer>
    );
  }
  return (
    <Tabs>
      {items.map((item, index: number) => (
        <Tab
          key={item.id}
          eventKey={generatePath(Routes.AssetsCategoriesCNAIM, !index ? { id } : { id, versionId: item.id })}
          title={item.name}
        >
          <AssetsCNAIMTable items={item.rows} />
        </Tab>
      ))}
    </Tabs>
  );
};

export default AssetsCategoriesCNAIMDetails;
