import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { maintenanceDetailedPlanCountSelector, maintenanceDetailedPlanSelector } from 'modules/maintenance/selectors';
import { fetchMaintenanceDetailedPlanAction, updateMaintenanceDetailedPlanItemAction } from 'modules/maintenance';
import { DataTable, SelectYear } from 'components/_common';
import { PaginationType } from 'constants/index';

const MaintenanceDetailedPlanTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(maintenanceDetailedPlanCountSelector);
  const items = useSelector(maintenanceDetailedPlanSelector);

  const sendRequest = useCallback(() => dispatch(fetchMaintenanceDetailedPlanAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_PLAN}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 239px)"
    >
      {items.map((item: Maintenance.DetailedPlan) => (
        <MaintenanceDetailedPlanTableItem key={item.id} {...item} />
      ))}
    </DataTable>
  );
};

const MaintenanceDetailedPlanTableItem: React.FC<Maintenance.DetailedPlan> = ({
  id,
  planName,
  name,
  maintenanceYear,
  overrideYear,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [yearInput, setYearInput] = useState<number | null>(overrideYear);
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleSelectChange = useCallback((value: { value: number }) => setYearInput(value.value || null), []);

  const handleSelectBlur = useCallback(
    (e: React.SyntheticEvent) => {
      if (yearInput === overrideYear) return;
      setLoading(true);
      dispatch(updateMaintenanceDetailedPlanItemAction(id, yearInput)).finally(() => setLoading(false));
    },
    [dispatch, id, yearInput, overrideYear]
  );

  return (
    <tr>
      <td>{planName || '-'}</td>
      <td>{name || '-'}</td>
      <td>{maintenanceYear || '-'}</td>
      <td>
        <SelectYear
          labelKey=""
          value={yearInput}
          onChange={handleSelectChange}
          onBlur={handleSelectBlur}
          isDisabled={loading}
        />
      </td>
    </tr>
  );
};

export default MaintenanceDetailedPlanTable;
