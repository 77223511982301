import styled from 'styled-components';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const ContentContainer: React.FC<Props> = ({ children, className = '' }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

const StyledContainer = styled.div`
  position: relative;
  padding: ${props => props.theme.paddings.primary};
  background: ${props => props.theme.colors.grey25};
  border-top: 1px solid ${props => props.theme.colors.grey100};
`;

export default ContentContainer;
