import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.n1;

export const itemsCountSelector = createSelector(rootSelector, (n1: N1.Root): N1.Root['itemsCount'] => n1.itemsCount);

export const itemsSelector = createSelector(rootSelector, (n1: N1.Root): N1.Root['items'] => n1.items);
