import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  smartMetersHashFetchedSelector,
  smartMetersSelector,
  smartMetersSelectorFactory,
} from 'modules/assets/selectors';
import { fetchSmartMetersAction } from 'modules/assets';
import { FormReactSelect, InputActionMeta } from 'components/_common';

const SelectSmartMeter: React.FC<any> = ({ value, assetId, isDisabled, onChange, ...props }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const limit = 3;
  const smartMetersHashFetched = useSelector(smartMetersHashFetchedSelector);
  const smartMeters = useSelector(smartMetersSelector);
  const smartMeter = useSelector(smartMetersSelectorFactory(value));

  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    if (!smartMetersHashFetched) dispatch(fetchSmartMetersAction());
  }, [smartMetersHashFetched, dispatch]);

  const selectOptions = useMemo(() => {
    if (inputValue.length < limit) return [];
    return smartMeters.reduce((acc: any[], smartMeter: Asset.SmartMeter) => {
      if (smartMeter.NK_BD_ID && smartMeter.NK_BD_ID !== assetId) return acc;
      if (smartMeter.installationNumber.includes(inputValue)) {
        acc.push({
          value: smartMeter.elInTablID,
          label: smartMeter.installationNumber,
        });
      }
      return acc;
    }, []);
  }, [smartMeters, inputValue, assetId]);

  const handleInputChange = useCallback(
    (value: string, meta: InputActionMeta) => {
      if (meta.action === 'input-change') {
        setInputValue(value);
        if (!value) onChange(value);
      }
    },
    [onChange]
  );

  // Note. keep previous search input position
  const handleFocus = useCallback(
    () => setInputValue(smartMeter?.installationNumber || ''),
    [smartMeter?.installationNumber]
  );

  // Note. reset search input value
  const handleBlur = useCallback(() => setInputValue(''), []);

  return (
    <FormReactSelect
      {...props}
      value={value && { value, label: smartMeter?.installationNumber || '' }}
      isLoading={!smartMetersHashFetched}
      isDisabled={!smartMetersHashFetched || isDisabled}
      options={selectOptions}
      onChange={onChange}
      onInputChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputValue={inputValue}
      placeholderKey="Type to search"
      noOptionsMessage={() =>
        inputValue.length < limit ? getIntl('Please type {{limit}} symbols', { limit }) : getIntl('No options')
      }
      blurInputOnSelect
      isClearable
    />
  );
};

export default SelectSmartMeter;
