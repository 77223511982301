import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { generatePath } from 'react-router';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { copyFromPreviousYearAction } from 'modules/employees';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { isPageTasksEmployeesActiveSelector } from 'modules/router/selectors';
import { EmployeesGanttChart, TasksGanttChart, NewTasksGanttChart } from 'components';
import { EmployeesModal } from 'components/_modals';
import { EmployeesTable } from 'components/_tables';
import { Button, DataTableTemplate, DataTableHeaderFilters, Tabs, Tab } from 'components/_common';
import { PaginationType, DataTableFilterKeys, Routes, isProduction } from 'constants/index';

const ViewEmployees: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const isPageTasksEmployeesActive = useSelector(isPageTasksEmployeesActiveSelector);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.EMPLOYEES));
  const year = filters?.year!;

  const handleCopyFromPrevYearClick = useCallback((): void => {
    dispatch(
      setModalConfirmationMetaAction({
        title: getIntl('Are you sure to copy data from {{prevYear}} to {{year}}?', {
          prevYear: year - 1,
          year,
        }),
        submitButtonProps: {
          labelKey: 'Copy',
        },
        onConfirm: () => dispatch(copyFromPreviousYearAction(year)),
      })
    );
  }, [dispatch, getIntl, year]);

  return (
    <DataTableTemplate>
      <Row>
        <DataTableHeaderFilters
          paginationType={PaginationType.EMPLOYEES}
          showKeys={
            isPageTasksEmployeesActive
              ? [DataTableFilterKeys.YEAR]
              : [
                  DataTableFilterKeys.YEAR,
                  DataTableFilterKeys.TYPE,
                  DataTableFilterKeys.EMPLOYEES_IDS,
                  DataTableFilterKeys.STATUS,
                ]
          }
        />
        {isPageTasksEmployeesActive && (
          <>
            <Col>
              <EmployeesModal />
            </Col>
            <Col xs="auto">
              <Button
                labelKey={getIntl('Copy from {{year}} year', { year: year - 1 })}
                onClick={handleCopyFromPrevYearClick}
                size="large"
                variant="primary"
              />
            </Col>
          </>
        )}
      </Row>
      <Tabs mountOnEnter unmountOnExit>
        <Tab
          id="tab_employees_employees_gantt_diagram"
          eventKey={generatePath(Routes.TasksEmployees)}
          title={getIntl('Employees gantt diagram')}
        >
          <EmployeesGanttChart />
        </Tab>
        <Tab
          id="tab_employees_tasks_gantt_diagram"
          eventKey={`${generatePath(Routes.TasksEmployees)}${Routes.HASH.Diagram}`}
          title={getIntl('Tasks gantt diagram')}
        >
          <TasksGanttChart />
        </Tab>
        {!isProduction && (
          <Tab
            id="tab_employees_highcharts_gantt"
            eventKey={`${generatePath(Routes.TasksEmployees)}${Routes.HASH.Chart}`}
            title={getIntl('highcharts gantt (beta)')}
          >
            <NewTasksGanttChart />
          </Tab>
        )}
        <Tab
          id="tab_employees_employees"
          eventKey={`${generatePath(Routes.TasksEmployees)}${Routes.HASH.Table}`}
          title={getIntl('Employees')}
        >
          <EmployeesTable />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewEmployees;
