import axios from 'axios';
import { createAction } from 'redux-actions';
import {
  setModalAssetsCNAIMMetaAction,
  setSuccessToastAction,
  setReliabilityAssetCategoriesCNAIMOption,
} from 'modules/layouts';
import { paginationSelectorFactory, reliabilityAssetCategoriesCNAIMOptionSelector } from 'modules/layouts/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, plumberAPI } from 'constants/index';

const baseUrl: string = 'api/admin/v1/secure/';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssetCategoriesCNAIMAction: any = createAction(
  'assets/FETCH_CATEGORIES_CNAIM',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'categoriesCNAIMCount' | 'categoriesCNAIMHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.CATEGORIES_CNAIM)(state);
      return axios
        .get(`${baseUrl}assetcategories/demo`, {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          categoriesCNAIMCount: res.data.countAssetCategories,
          categoriesCNAIMHash: _keyBy(res.data.assetCategories, (item: Asset.CategoryCNAIM) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const fetchAssetCategoriesCNAIMTemplatesAction = createAction(
  'assets/FETCH_CATEGORIES_CNAIM_TEMPLATES',
  async () => (): Promise<Pick<Asset.Root, 'categoriesCNAIMTemplates'>> => {
    return axios.get(`${baseUrl}assets/demo/templates`).then((res: any) => ({ categoriesCNAIMTemplates: res.data }));
  }
);

export const parseAssetCategoriesCNAIMFileAction = createAction(
  'assets/PARSE_CATEGORY_CNAIM_FILE',
  async (formData: FormData, assetCategoryId: number) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post(`${baseUrl}assets/demo/parse`, formData).then(res => {
        dispatch(setModalAssetsCNAIMMetaAction({ rows: res.data, assetCategoryId, name: '' }));
      });
    }
);

export const uploadAssetCategoriesCNAIMFileAction = createAction(
  'assets/UPLOAD_CATEGORY_CNAIM_FILE',
  (meta: Layouts.ModalAssetsCNAIMMeta) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post(`${baseUrl}assets/demo/create`, meta).then(async () => {
        await dispatch(fetchAssetCategoriesCNAIMAction());
        dispatch(setSuccessToastAction('File has been uploaded'));
      });
    }
);

export const deleteAssetCategoriesCNAIMAction = createAction('assets/DELETE_CATEGORY_CNAIM', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return axios.delete(`${baseUrl}assetcategories/demo/${id}`).then(async () => {
      await dispatch(fetchAssetCategoriesCNAIMAction());
      dispatch(setSuccessToastAction('Asset category has been deleted'));
    });
  };
});

export const fetchCategoriesCNAIMAssetsAction = createAction(
  'assets/FETCH_CATEGORIES_CNAIM_ASSETS',
  async (id: number) => (): Promise<any> => {
    return axios.get(`${baseUrl}assets/demo/asset_category/${id}`).then((res: any) => res.data);
  }
);

export const fetchSelectAssetCategoriesCNAIMOptionsAction = createAction(
  'assets/FETCH_CATEGORIES_CNAIM_OPTIONS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'categoriesCNAIMOptions'>> => {
      return plumberAPI.get('cnaim_asset_category').then(res => {
        const reliabilityAssetCategoriesCNAIMOption = reliabilityAssetCategoriesCNAIMOptionSelector(getState());
        const categoriesCNAIMOptions = res.data
          .filter((item: any) => item.labels) // FixMe. Server error temporary fix
          .map((item: { id: number; labels: string }) => ({
            value: item.id,
            label: item.labels,
          }));
        if (!reliabilityAssetCategoriesCNAIMOption && categoriesCNAIMOptions?.length) {
          dispatch(setReliabilityAssetCategoriesCNAIMOption(categoriesCNAIMOptions[0]));
        }
        return { categoriesCNAIMOptions };
      });
    }
);
