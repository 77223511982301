import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { generatePath } from 'react-router-dom';
import { smartMeterActionsSelector, smartMeterActionsCountSelector } from 'modules/meters/selectors';
import { fetchSmartMeterActionsAction } from 'modules/meters';
import { DataTable, Button, ChecklistStatus, SmartMeterActionBadge } from 'components/_common';
import { Routes, PaginationType } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const SmartMeterActionsTable: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(smartMeterActionsCountSelector);
  const items = useSelector(smartMeterActionsSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchSmartMeterActionsAction(params)).then(
        (action: Shared.ReduxAction<{ smartMeterActionsHash: Type.Hash<Meters.SmartMeterAction> }>) =>
          Object.values(action.payload?.smartMeterActionsHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.SMART_METER_ACTIONS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      containerClassName="border-top-0"
      maxHeight="calc(100vh - 170px)"
    >
      {items.map((item: Meters.SmartMeterAction) => (
        <tr key={item.id}>
          <td>{item.oldMaalernr}</td>
          <td>{dateFormat(item.timestamp)}</td>
          <td>{item.newMaalernr}</td>
          <td className="text-center">
            <SmartMeterActionBadge action={item.action} />
          </td>
          <td>{item.user}</td>
          <td>{item.installationNumber}</td>
          <td className="text-center">{dateFormat(item.actionPerformed)}</td>
          <td className="text-center">
            <ChecklistStatus id={item.id} isChecklistDone={item.isChecklistDone} />
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Details"
              icon={<IconDetails />}
              data-id={item.id}
              linkProps={{ to: generatePath(Routes.Meters, { id: item.id }) }}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default SmartMeterActionsTable;
