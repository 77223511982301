import React from 'react';
import styled from 'styled-components';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange' | 'title'> {
  title?: React.ReactNode;
  scale?: React.ReactNode;
  controls?: React.ReactNode;
}

const ScaleContainer: React.FC<Props> = ({ title, scale, controls, ...rest }) => {
  return (
    <StyledScaleContainer {...rest}>
      {Boolean(title) && <StyledScaleContainerTitle>{title}</StyledScaleContainerTitle>}
      {Boolean(scale) && scale}
      {Boolean(controls) && <StyledScaleContainerControls>{controls}</StyledScaleContainerControls>}
    </StyledScaleContainer>
  );
};

const StyledScaleContainer = styled.div`
  padding-bottom: 10px;
`;

const StyledScaleContainerTitle = styled.div`
  font-size: 10px;
  color: #909090;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const StyledScaleContainerControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export default ScaleContainer;
