import { handleActions, combineActions } from 'redux-actions';
import {
  fetchDERsAction,
  fetchDERsPreviewYearsAction,
  createDERAction,
  updateDERAction,
  fetchDERsCategoriesAction,
  createDERsSectionCategoryAction,
  updateDERsSectionCategoryAction,
  deleteDERsSectionCategoryAction,
  deleteDERAction,
  uploadDERPhaseInAction,
  fetchDERsProfilesAction,
  deleteDERsProfileAction,
  uploadDERProfileAction,
  replaceDERProfileAction,
  fetchIndividualCustomersAction,
  deleteIndividualCustomerAction,
  uploadIndividualCustomersExcelAction,
  updateIndividualCustomerAction,
  getGlobalDevelopmentChartData,
  getDERProfileChartData,
  getDERPhaseInChartData,
  fetchDERsPhaseInAction,
  deleteDERsPhaseInAction,
} from './actions';

const initialState: NewLoad.Root = {
  DERsHash: {} as Type.Hash<NewLoad.DER>,
  DERsCount: 0,
  DERsPreviewYearsHash: {} as Type.Hash<number[]>,
  DERsCategoriesHash: {} as Type.Hash<NewLoad.DERCategory>,
  DERsCategoriesCount: 0,
  DERsCategoriesFetched: false,
  DERsProfilesHash: {},
  DERsProfilesCount: 0,
  DERsPhaseInHash: {},
  DERsPhaseInCount: 0,
  individualCustomersCount: 0,
  individualCustomersHash: {} as Type.Hash<NewLoad.IndividualCustomer>,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    fetchDERsAction,
    fetchDERsPreviewYearsAction,
    fetchDERsCategoriesAction,
    fetchDERsProfilesAction,
    fetchIndividualCustomersAction,
    fetchDERsPhaseInAction
  ).toString()]: {
    next: (
      state: NewLoad.Root,
      action: Shared.ReduxAction<Partial<NewLoad.Root> & { skipStoreUpdate: true | undefined }>
    ): NewLoad.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchDERsAction,
  fetchDERsPreviewYearsAction,
  createDERAction,
  updateDERAction,
  fetchDERsCategoriesAction,
  createDERsSectionCategoryAction,
  updateDERsSectionCategoryAction,
  deleteDERsSectionCategoryAction,
  deleteDERAction,
  uploadDERPhaseInAction,
  fetchDERsProfilesAction,
  deleteDERsProfileAction,
  uploadDERProfileAction,
  replaceDERProfileAction,
  fetchIndividualCustomersAction,
  deleteIndividualCustomerAction,
  uploadIndividualCustomersExcelAction,
  updateIndividualCustomerAction,
  getGlobalDevelopmentChartData,
  getDERProfileChartData,
  getDERPhaseInChartData,
  fetchDERsPhaseInAction,
  deleteDERsPhaseInAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
