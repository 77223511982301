import React, { useMemo, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchInvestmentLimitationsAction, updateInvestmentLimitationAction } from 'modules/setup';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import {
  investmentLimitationsByVoltageNameHashSelector,
  investmentLimitationVoltageUnitSelector,
} from 'modules/setup/selectors';
import { DataTable, FormInput } from 'components/_common';
import { PaginationType, TableHeaders, GriZoneNamesLocalesMap } from 'constants/index';

const TableInvestmentLimitations: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();

  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const paginationType = PaginationType.INVESTMENT_LIMITATIONS;
  const itemsHash = useSelector(investmentLimitationsByVoltageNameHashSelector);
  const unit = useSelector(investmentLimitationVoltageUnitSelector);

  const sendRequest = useCallback(() => dispatch(fetchInvestmentLimitationsAction()), [dispatch]);

  const customHeaders = useMemo(
    () => [
      ...TableHeaders[paginationType].map(header => {
        if (!header.titleKey?.includes('{{unit}}')) return header;
        return {
          ...header,
          title: getIntl(header.titleKey, { unit }),
        };
      }),
    ],
    [paginationType, unit, getIntl]
  );

  let counter = 0;
  return (
    <DataTable
      paginationType={PaginationType.INVESTMENT_LIMITATIONS}
      totalAmount={0}
      sendRequest={sendRequest}
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
      customHeaders={customHeaders}
      maxHeight="calc(100vh - 223px)"
    >
      {Object.keys(itemsHash || {})?.map?.((key: string) =>
        Object.values<Setup.InvestmentLimitation>((itemsHash as any)[key]).map((item, index: number, arr) => (
          <TableItem
            key={item.id || item.cables_id}
            item={item}
            counter={counter++}
            index={index}
            length={arr.length}
          />
        ))
      )}
    </DataTable>
  );
};

const TableItem: React.FC<{ length: number; counter: number; index: number; item: Setup.InvestmentLimitation }> = ({
  length,
  item,
  counter,
  index,
}) => {
  const { getIntl } = useLocale();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<{ [column: string]: number | null } | null>(null);
  const dispatch: Shared.CustomDispatch = useDispatch();

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const nextValue = value !== '' ? Number(value) : null;
    setState({ [name]: nextValue });
  }, []);

  const handleInputBlur = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      const key = Object.keys(state || {})[0] as keyof Setup.InvestmentLimitation;
      if (!key || !state?.[key] || state[key] === item[key]) return;
      setLoading(true);
      dispatch(updateInvestmentLimitationAction(item, key, state[key])).finally(() => setLoading(false));
    },
    [dispatch, item, state]
  );

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;
      const rowIndex: number = Number(e.currentTarget.getAttribute('data-row-index'));
      const cellIndex: number = Number(e.currentTarget.getAttribute('data-cell-index'));
      const nextRowElement: any = document.querySelector(
        `[data-row-index='${rowIndex + 1}'][data-cell-index='${cellIndex}']`
      );
      if (nextRowElement) return nextRowElement?.focus();
      const nextCellElement: any = document.querySelector(
        `[data-row-index='${0}'][data-cell-index='${cellIndex + 1}']`
      );
      if (nextCellElement) return nextCellElement?.focus();
      handleInputBlur();
    },
    [handleInputBlur]
  );

  return (
    <tr>
      {!index && (
        <td rowSpan={length} className="text-center">
          {item.voltage_name}
        </td>
      )}
      <td>{getIntl(GriZoneNamesLocalesMap[item.grid_zone_name] || item.grid_zone_name)}</td>
      <td>
        {item.cables_current_upper_bound_pc ? (
          <FormInput
            data-row-index={counter}
            data-cell-index={1}
            min={0}
            type="number"
            disabled={loading}
            size="sm"
            name="cables_current_upper_bound_pc"
            value={
              state?.cables_current_upper_bound_pc === undefined
                ? item.cables_current_upper_bound_pc
                : state.cables_current_upper_bound_pc ?? ''
            }
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyPress={handleKeyPress}
          />
        ) : (
          '-'
        )}
      </td>
      <td>
        {item.current_upper_bound_pc ? (
          <FormInput
            data-row-index={counter}
            data-cell-index={2}
            min={0}
            type="number"
            disabled={loading}
            size="sm"
            name="current_upper_bound_pc"
            value={
              state?.current_upper_bound_pc === undefined
                ? item.current_upper_bound_pc
                : state.current_upper_bound_pc ?? ''
            }
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyPress={handleKeyPress}
          />
        ) : (
          '-'
        )}
      </td>
      <td>
        <FormInput
          data-row-index={counter}
          data-cell-index={3}
          min={0}
          type="number"
          disabled={loading}
          size="sm"
          name="voltage_lower_bound"
          value={state?.voltage_lower_bound === undefined ? item.voltage_lower_bound : state.voltage_lower_bound ?? ''}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
      <td>
        <FormInput
          data-row-index={counter}
          data-cell-index={4}
          min={0}
          type="number"
          disabled={loading}
          size="sm"
          name="voltage_upper_bound"
          value={state?.voltage_upper_bound === undefined ? item.voltage_upper_bound : state.voltage_upper_bound ?? ''}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleKeyPress}
        />
      </td>
    </tr>
  );
};

export default TableInvestmentLimitations;
