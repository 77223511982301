import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.employees;

const employeesHashSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): Type.Hash<Employees.Item> => employees.employeesHash
);

export const employeesSelector = createSelector(
  employeesHashSelector,
  (employeesHash: Type.Hash<Employees.Item>): Employees.Item[] => Object.values(employeesHash)
);

export const employeesCountSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): number => employees.employeesCount
);

export const employeeSelectorFactory = (id: number | null) =>
  createSelector(
    employeesHashSelector,
    (employeesHash: Type.Hash<Employees.Item>): Employees.Item => employeesHash[`_${id}_`]
  );

// EMPLOYEES GANTT CHART SELECTORS

export const employeesGanttChartFetchedSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): boolean => employees.employeesGanttChartFetched
);

const employeesGanttChartHashSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): Type.Hash<Employees.GanttChartItem> => employees.employeesGanttChartHash
);

export const employeesGanttChartSelector = createSelector(
  employeesGanttChartHashSelector,
  (employeesGanttChartHash: Type.Hash<Employees.GanttChartItem>): Employees.GanttChartItem[] =>
    Object.values(employeesGanttChartHash)
);

export const employeesGanttChartHoursLeftOverWeekSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): number[] => employees.employeesGanttChartHoursLeftOverWeek
);

export const employeesGanttChartHoursLeftOverMonthSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): number[] => employees.employeesGanttChartHoursLeftOverMonth
);

export const employeesGanttChartTasksHashSelector = createSelector(
  employeesGanttChartSelector,
  (employeesGanttChartItems: Employees.GanttChartItem[]): Type.Hash<Tasks.Task> => {
    return employeesGanttChartItems.reduce((acc, item) => {
      item.tasks.forEach(task => {
        (acc as any)[`_${task.id}_`] = task;
      });
      return acc;
    }, {});
  }
);

// TASKS GANTT CHART SELECTORS

export const tasksGanttChartFetchedSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): boolean => employees.tasksGanttChartFetched
);

export const tasksGanttChartHashSelector = createSelector(
  rootSelector,
  (employees: Employees.Root): Type.Hash<Tasks.Task> => employees.tasksGanttChartHash
);

export const tasksGanttChartSelector = createSelector(
  tasksGanttChartHashSelector,
  (tasksGanttChartHash: Type.Hash<Tasks.Task>): Tasks.Task[] => Object.values(tasksGanttChartHash)
);
