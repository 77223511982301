import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { settingsSelector } from 'modules/map/selectors';
import { Overlay, Popover } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { OverlayTriggerTooltip, Button } from 'components/_common';

const LastUpdateButton: React.FC = () => {
  const settings = useSelector(settingsSelector);
  const { getIntl } = useLocale();
  const [show, setShow] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <OverlayTriggerTooltip
        placement="left"
        container={buttonRef.current}
        overlayId="latest-update-tooltip"
        overlayChildren={getIntl('Data relevance')}
        overlayStyle={{ display: show ? 'none' : 'block', width: 'max-content' }}
      >
        <Button
          dataMarker="main_map_data_relevance"
          variant="light"
          ref={buttonRef}
          className={classNames('mt-1', { active: show })}
          onClick={() => setShow(prev => !prev)}
          icon={<i className="fa fa-info" />}
        />
      </OverlayTriggerTooltip>
      <Overlay show={show} placement="left-end" target={buttonRef.current}>
        <StyledPopover id="latest-update-popover">
          <Popover.Content data-marker="map_popup__data_relevance">
            <div className="header">{getIntl('Assets and simulations data relevance')}</div>
            <StyledPanel>
              <FlexGroup>
                <div className="title">{getIntl('Latest update')}</div>
                <FlexGroup>
                  <div>{moment(settings.layerUpdate?.map).format('YYYY-MM-DD')}</div>
                  <div className="time">{moment(settings.layerUpdate?.map).format('HH:mm')}</div>
                </FlexGroup>
              </FlexGroup>
            </StyledPanel>
            {(Boolean(settings.layerUpdate?.version) || Boolean(settings.layerUpdate?.simulation)) && (
              <StyledPanel>
                {settings.layerUpdate?.version && (
                  <FlexGroup>
                    <div className="title">{getIntl('Asset data version ID')}</div>
                    <div>{settings.layerUpdate?.version}</div>
                  </FlexGroup>
                )}
                {settings.layerUpdate?.simulation && (
                  <FlexGroup>
                    <div className="title">{getIntl('Simulation version ID')}</div>
                    <div>{settings.layerUpdate?.simulation}</div>
                  </FlexGroup>
                )}
              </StyledPanel>
            )}
            {(settings.layerUpdate?.layers.length ?? 0) > 0 && (
              <StyledPanel>
                {settings.layerUpdate?.layers.map(u => (
                  <FlexGroup key={u.title}>
                    <div className="title" data-marker="data_relevance_layer_title">
                      {getIntl(u.title)}
                    </div>
                    <FlexGroup>
                      <div>{moment(u.last_update).format('YYYY-MM-DD')}</div>
                      <div className="time">{moment(u.last_update).format('HH:mm')}</div>
                    </FlexGroup>
                  </FlexGroup>
                ))}
              </StyledPanel>
            )}
          </Popover.Content>
        </StyledPopover>
      </Overlay>
    </>
  );
};

const StyledPopover = styled(Popover)`
  width: max-content;
  max-width: max-content;
  cursor: default;
  background: rgba(241, 246, 255, 0.8);
  border-radius: 8px;
  box-shadow:
    0px 4px 4px rgba(50, 50, 71, 0.08),
    0px 4px 8px rgba(50, 50, 71, 0.06);

  .popover-body {
    padding: 10px;
    color: var(--map-text-color-secondary);
    font-size: 12px;
    white-space: nowrap;
  }

  .arrow {
    display: none;
  }

  .header {
    text-align: center;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const FlexGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPanel = styled.div`
  background: #ffffff;
  border: 1px solid var(--map-outline-color);
  box-shadow: 0px 2px 2px rgb(0 123 254 / 10%);
  border-radius: 8px;
  padding: 10px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .title {
    margin-right: 30px;
  }

  .time {
    width: 50px;
    text-align: end;
  }
`;

export default LastUpdateButton;
