import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fusesForecastsSelector, fusesForecastsCountSelector } from 'modules/networkLoading/fuses.selectors';
import { fetchFusesForecastAction } from 'modules/networkLoading';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const NetworkLoadingFusesForecastTable: React.FC = () => {
  const { dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.NETWORK_LOADING_FUSES_FORECAST;

  const items = useSelector(fusesForecastsSelector);
  const count = useSelector(fusesForecastsCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchFusesForecastAction(params)).then(
        (action: Shared.ReduxAction<Type.Hash<Pick<NetworkLoading.Root, 'fusesForecastsHash'>>>) =>
          Object.values(action.payload?.fusesForecastsHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable paginationType={paginationType} totalAmount={count} sendRequest={sendRequest} isDownloadCSVEnabled>
      {items?.map((item: NetworkLoading.FuseForecast) => (
        <tr key={item.id}>
          <td>{item.kabel}</td>
          <td>{item.fuse}</td>
          <td>{item.max_24h_pc || '-'}</td>
          <td>{item.max_48h_pc || '-'}</td>
          <td>{item.max_7d_pc || '-'}</td>
          <td>{item.overload_start ? dateFormat(item.overload_start) : '-'}</td>
          <td>{item.peak ? dateFormat(item.peak) : '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default NetworkLoadingFusesForecastTable;
