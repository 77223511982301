import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DataTableTemplate } from 'components/_common';
import { SelectAssetCategories, TableCNAIM } from 'components/ReinvestmentCNAIM';

const ViewReinvestments: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <Row>
      <Col xs={3}>
        <SelectAssetCategories />
      </Col>
    </Row>
    <TableCNAIM />
  </DataTableTemplate>
);

export default ViewReinvestments;
