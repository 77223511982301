import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioYearsOptionsAction } from 'modules/options';
import { scenarioYearsOptionsSelector } from 'modules/options/selectors';
import { SelectYear, FormReactSelectProps, Checkbox } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number[] | null;
}

const SelectOverviewScenarioYears: React.FC<Props> = ({ onChange, ...props }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const scenarioYearsOptions = useSelector(scenarioYearsOptionsSelector);

  useEffect(() => {
    if (scenarioYearsOptions) return;
    setLoading(true);
    dispatch(fetchScenarioYearsOptionsAction()).finally(() => setLoading(false));
  }, [scenarioYearsOptions, dispatch]);

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { checked } = event.target as HTMLInputElement;
      onChange?.(checked ? scenarioYearsOptions?.map(i => ({ value: i })) : [], {} as any);
    },
    [onChange, scenarioYearsOptions]
  );

  return (
    <>
      <SelectYear
        {...props}
        range={scenarioYearsOptions || []}
        isDisabled={!scenarioYearsOptions?.length}
        isLoading={loading}
        labelKey="Select years"
        onChange={onChange}
      />
      <Checkbox
        className="pl-0 icheck-success pt-2"
        checked={Boolean(scenarioYearsOptions?.length) && props.value?.length === scenarioYearsOptions?.length}
        labelKey="All available years"
        onChange={handleCheckboxChange}
      />
    </>
  );
};

export default SelectOverviewScenarioYears;
