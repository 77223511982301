import React from 'react';
import { sortPermissions } from 'utils';
import { PermissionsTypesLocalesMap } from 'constants/index';

const PermissionsTypesTableCell: React.FC<{ permissionsTypes: Permissions.Permissions }> = ({ permissionsTypes }) => (
  <td>
    {(Object.keys(permissionsTypes) as Permissions.PermissionsTypes[]).sort(sortPermissions).map((key: string) => {
      // Filter out falsy permission from Groups table
      if (!permissionsTypes[key as Permissions.PermissionsTypes]) return null;

      return (
        <React.Fragment key={key}>
          - {PermissionsTypesLocalesMap[key as Permissions.PermissionsTypes]?.labelKey || key}
          <br />
        </React.Fragment>
      );
    })}
  </td>
);

export default PermissionsTypesTableCell;
