import Select, { CSSObjectWithLabel, InputActionMeta, SelectComponentsConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React from 'react';
import { Form } from 'react-bootstrap';

export type { InputActionMeta };
export interface FormReactSelectProps {
  className?: string;
  labelClassName?: string;
  label?: React.ReactElement | string;
  labelKey?: string;
  mutedTextLabelKey?: string;
  placeholder?: string;
  placeholderKey?: string;
  errorKey?: string;
  name?: string;
  value: { value: number | string; label: string | JSX.Element } | { value: number | string; label: string }[] | null;
  inputValue?: string;
  options: any[];
  isCreatableSelect?: boolean;
  blurInputOnSelect?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  menuIsOpen?: boolean;
  isSearchable?: boolean;
  variant?: 'small';
  components?: SelectComponentsConfig<{}, boolean, any>;
  menuPortalTarget?: HTMLElement;
  onChange?: (value: any, actionMeta: Type.SelectActionMetaBase) => void;
  onInputChange?: (value: string, actionMeta: InputActionMeta) => void;
  onFocus?: (event: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
  onKeyDown?: (event: React.SyntheticEvent) => void;
  noOptionsMessage?: () => string;
  getIntl?: (localeKey: string, options?: {}) => string;
}

const FormReactSelect: React.FC<FormReactSelectProps> = ({
  className = '',
  labelClassName = '',
  label,
  labelKey = '',
  mutedTextLabelKey = '',
  placeholder,
  placeholderKey = 'Select',
  errorKey = '',
  isCreatableSelect,
  variant,
  getIntl = str => str,
  ...props
}) => {
  const smallSelectStyles = {
    container: (base: any) => ({ ...base, fontSize: '0.90rem' }),
    control: (base: any) => ({
      ...base,
      minHeight: 32,
      borderRadius: 8,
      borderColor: '#e1e6ec',
    }),
    valueContainer: (base: any) => ({ ...base, padding: '0 0 0 8px' }),
    dropdownIndicator: (base: any) => ({ ...base, padding: '5px' }),
    clearIndicator: (base: any) => ({ ...base, padding: '5px 0 5px 5px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    input: (base: any) => ({ ...base, margin: 0 }),
    option: (base: any) => ({ ...base, padding: '6px 12px' }),
  };

  const selectStyles = {
    container: (base: any) => ({ ...base, fontSize: '0.95rem' }),
    control: (base: any) => ({
      ...base,
      minHeight: 38,
      borderRadius: 8,
      borderColor: '#e1e6ec',
    }),
    clearIndicator: (base: any) => ({ ...base, padding: '8px 0 8px 8px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    input: (base: any) => ({ ...base, margin: 0 }),
    singleValue: (base: any) => ({ ...base, marginLeft: 0 }),
    option: (base: any) => ({ ...base, padding: '8px 12px' }),
  };

  const selectProps = {
    noOptionsMessage: () => getIntl('No options'),
    placeholder: placeholder || getIntl(placeholderKey),
    ...props,
    className: `is-invalid ${className}`,
    styles: {
      menu: (base: CSSObjectWithLabel) => ({
        ...base,
        zIndex: 3,
        marginTop: 1,
        borderRadius: 8,
      }),
      ...(props.menuPortalTarget ? { menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 10001 }) } : {}),
      ...(variant === 'small' ? smallSelectStyles : selectStyles),
    },
  };

  const field_name = mutedTextLabelKey.replaceAll(' ', '_').toLowerCase();
  let data_marker_value_label = null;
  if (Array.isArray(props.value)) {
    data_marker_value_label = props.value[0] && props.value[0].label;
  } else {
    data_marker_value_label = props.value && props.value.label;
  }
  return (
    <>
      {(label || labelKey) && (
        <Form.Label data-marker-label={data_marker_value_label} className={labelClassName}>
          {label || getIntl(labelKey)}
        </Form.Label>
      )}
      {!isCreatableSelect ? (
        <Select id={field_name && `select_block_${field_name}`} {...selectProps} />
      ) : (
        <CreatableSelect {...selectProps} />
      )}
      {Boolean(errorKey) && <Form.Control.Feedback type="invalid">{getIntl(errorKey || '')}</Form.Control.Feedback>}
      {mutedTextLabelKey && <Form.Text className="text-muted">{getIntl(mutedTextLabelKey)}</Form.Text>}
    </>
  );
};

export default FormReactSelect;
