import React from 'react';
import * as Sentry from '@sentry/react';
import PageError from '@utiligize/shared/components/PageError';
import { isProduction } from '@utiligize/shared/constants/index';

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    if (isProduction) {
      Sentry.captureException(error);
    } else {
      console.error(`ErrorBoundary error: ${error.message}.`);
    }
  }

  render() {
    return (this.state as any).hasError ? <PageError /> : (this.props as any).children;
  }
}

export default ErrorBoundary;
