import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { setLayoutAction } from 'modules/layouts';
import { investmentComponentPriceAssetModalMetaSelector } from 'modules/layouts/selectors';
import { componentPriceSelectorFactory } from 'modules/setup/selectors';
import SelectCNAIMAssetCategory from './SelectCNAIMAssetCategory';
import UnitsInputGroup from '../UnitsInputGroup';
import SelectPriceType from './SelectPriceType';
import {
  Modal,
  Button,
  FormInput,
  HiddenFormSubmit,
  SelectComponentPriceScenario,
  SelectGridZone,
} from 'components/_common';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalComponentPriceAsset: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { id, isDuplicate }: Layouts.ModalMeta = useSelector(investmentComponentPriceAssetModalMetaSelector) || {
    id: null,
    isDuplicate: false,
  };
  const componentPrice: Setup.ComponentPrice | undefined = useSelector(componentPriceSelectorFactory(id));
  const isEditMode: boolean = Boolean(id && componentPrice);
  const [show, setShow] = useState(false);

  const toggleModal = useCallback((): void => {
    // Reset redux layouts state for isEditMode
    if (show && id) dispatch(setLayoutAction({ investmentComponentPriceAssetModalMeta: null }));
    setShow(!show);
  }, [id, show, dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setValues,
    setFieldValue,
    setFieldTouched,
  } = useConfiguredFormik({ id, toggleModal, isDuplicate });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  useEffect(() => {
    if (!componentPrice) return;
    setValues({
      cnaim_id: componentPrice.cnaim_id,
      size: componentPrice.size,
      size_unit: componentPrice.size_unit,
      price: componentPrice.price,
      price_unit: componentPrice.price_unit,
      lifetime: componentPrice.lifetime,
      co2e_scope_1: componentPrice.co2e_scope_1,
      co2e_scope_2: componentPrice.co2e_scope_2,
      co2e_scope_3: componentPrice.co2e_scope_3,
      co2e_unit: componentPrice.co2e_unit,
      component_price_scenario_id: componentPrice.component_price_scenario_id,
      grid_zone_id: componentPrice.grid_zone_id,
      price_type_id: componentPrice.price_type_id,
    });
    setShow(true);
  }, [componentPrice, isDuplicate, getIntl, setValues]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption, { name }: Type.SelectActionMetaBase) => {
      if (name === 'cnaim_id') {
        setFieldValue('size_unit', option.sizeUnit);
        setFieldValue('price_unit', option.priceUnit);
        setFieldValue('co2e_unit', option.co2eUnit);
      }
      const value = Array.isArray(option) ? option.map(i => i.value) : option?.value;
      setFieldValue(name, value ?? null);
    },
    [setFieldValue]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Create asset" onClick={toggleModal} variant="primary" />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey={isEditMode ? (isDuplicate ? 'Duplicate asset' : 'Edit asset') : 'Create asset'}
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectCNAIMAssetCategory
              name="cnaim_id"
              value={values?.cnaim_id || null}
              onChange={handleSelectChange}
              labelKey="Asset category"
              onBlur={() => setFieldTouched('cnaim_id')}
              errorKey={Boolean(touched.cnaim_id && errors.cnaim_id) ? (errors.cnaim_id as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <UnitsInputGroup
              labelKey="Size"
              name="size"
              value={values.size ?? ''}
              units={values.size_unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.size && errors.size) ? (errors.size as string) : ''}
              disabled={!values?.cnaim_id}
            />
          </Form.Group>
          <Form.Group>
            <UnitsInputGroup
              labelKey="Price"
              name="price"
              value={values.price ?? ''}
              units={values.price_unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.price && errors.price) ? (errors.price as string) : ''}
              disabled={!values?.cnaim_id}
            />
          </Form.Group>
          <Form.Group>
            <UnitsInputGroup
              labelKey="CO₂e (scope 1)"
              name="co2e_scope_1"
              value={values.co2e_scope_1 ?? ''}
              units={values.co2e_unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.co2e_scope_1 && errors.co2e_scope_1) ? (errors.co2e_scope_1 as string) : ''}
              disabled={!values?.cnaim_id}
            />
          </Form.Group>
          <Form.Group>
            <UnitsInputGroup
              labelKey="CO₂e (scope 2)"
              name="co2e_scope_2"
              value={values.co2e_scope_2 ?? ''}
              units={values.co2e_unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.co2e_scope_2 && errors.co2e_scope_2) ? (errors.co2e_scope_2 as string) : ''}
              disabled={!values?.cnaim_id}
            />
          </Form.Group>
          <Form.Group>
            <UnitsInputGroup
              labelKey="CO₂e (scope 3)"
              name="co2e_scope_3"
              value={values.co2e_scope_3 ?? ''}
              units={values.co2e_unit}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.co2e_scope_3 && errors.co2e_scope_3) ? (errors.co2e_scope_3 as string) : ''}
              disabled={!values?.cnaim_id}
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Lifetime"
              type="number"
              min={0}
              name="lifetime"
              value={values.lifetime ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.lifetime && errors.lifetime) ? (errors.lifetime as string) : ''}
            />
          </Form.Group>
          <Form.Group>
            <SelectComponentPriceScenario
              name="component_price_scenario_id"
              value={values.component_price_scenario_id}
              mutedTextLabelKey=""
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('component_price_scenario_id')}
              errorKey={
                Boolean(touched.component_price_scenario_id && errors.component_price_scenario_id)
                  ? (errors.component_price_scenario_id as string)
                  : ''
              }
            />
          </Form.Group>
          <Form.Group>
            <SelectGridZone
              name="grid_zone_id"
              value={values?.grid_zone_id}
              onChange={handleSelectChange}
              onBlur={() => setFieldTouched('grid_zone_id')}
              errorKey={Boolean(touched.grid_zone_id && errors.grid_zone_id) ? (errors.grid_zone_id as string) : ''}
              isMulti
            />
          </Form.Group>
          <Form.Group>
            <SelectPriceType
              name="price_type_id"
              value={values?.price_type_id || null}
              onChange={handleSelectChange}
              isSearchable={false}
              labelKey="Price type"
              onBlur={() => setFieldTouched('price_type_id')}
              errorKey={Boolean(touched.price_type_id && errors.price_type_id) ? (errors.price_type_id as string) : ''}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalComponentPriceAsset;
