import React from 'react';
import { useSelector } from 'react-redux';
import { appCurrentUserPermissionsSelector } from 'modules/app/selectors';
import { PageUpgradeYouPlan } from 'pages';

const PagePermissionsProvider: React.FC<{
  children: React.ReactNode;
  permissionKey: Permissions.PermissionsTypes;
  disabled?: boolean;
}> = ({ permissionKey, children, disabled }) => {
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);
  return appCurrentUserPermissions[permissionKey] && !disabled ? <>{children}</> : <PageUpgradeYouPlan />;
};

export default PagePermissionsProvider;
