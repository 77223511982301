import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { interventionDetailsModalIdSelector } from 'modules/layouts/selectors';
import { setInterventionDetailsModalId } from 'modules/layouts';
import { Modal, ChartIframe, Tabs, Tab } from 'components/_common';

const InterventionDetailsModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const id: number | null = useSelector(interventionDetailsModalIdSelector);

  const handleCancelBtnClick = useCallback(() => {
    dispatch(setInterventionDetailsModalId(null));
  }, [dispatch]);

  return (
    <Modal
      size="xl"
      show={Boolean(id)}
      backdrop
      onHide={handleCancelBtnClick}
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCancelBtnClick }}
    >
      <>
        {Boolean(id) && (
          <>
            <Tabs mountOnEnter defaultActiveKey="Intervention">
              <Tab eventKey="Intervention" title={getIntl('Intervention')}>
                <ChartIframe title="Plot value of intervention" src="plot_value_of_intervention" />
              </Tab>
              <Tab eventKey="LCC analysis" title={getIntl('LCC analysis')}>
                <ChartIframe title="Plot LCC analysis" src="plot_LCC_analysis" />
              </Tab>
            </Tabs>
          </>
        )}
      </>
    </Modal>
  );
};

export default InterventionDetailsModal;
