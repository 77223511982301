import styled from 'styled-components';
import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchMaxLoadSummaryAction } from 'modules/map';
import { createMapStateSelectorFactory, mapStateThemeSelector } from 'modules/map/selectors';
import { Section, Loading } from 'components/Map/common';
import { MapThemes } from 'constants/map';
import { scenarioIdSelector } from 'modules/layouts/selectors';
import SectionHint from './SectionHint';

const ForecastInvestmentMaxLoadSummary: React.FC<any> = ({ NoSectionData }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, numberFormat } = useLocale();

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<Map.MaxLoadSummary>({ der_description: [], co2e_summary: [] });

  const scenarioId = useSelector(scenarioIdSelector);
  const scenarioYear = useSelector(createMapStateSelectorFactory('scenario'))?.year;
  const theme = useSelector(mapStateThemeSelector);

  const derSummaries = useMemo(
    () =>
      summary.der_description
        .reduce((acc: Map.MaxLoadDERSummaryItem[], item: Map.MaxLoadDERSummaryItem) => {
          if (item.year === scenarioYear) acc.push(item);
          return acc;
        }, [])
        .sort((a, b) => a.description?.localeCompare(b.description)),
    [summary.der_description, scenarioYear]
  );

  const climateBenefit = useMemo(
    () => summary.co2e_summary.find(item => item.year === scenarioYear)?.climate_benefit_t_co2e || null,
    [summary.co2e_summary, scenarioYear]
  );

  useEffect(() => {
    if (theme !== MapThemes.YEARLY_MAX_LOAD || !scenarioId) return;
    setLoading(true);
    dispatch(fetchMaxLoadSummaryAction(scenarioId))
      .then(action => setSummary(action.payload))
      .finally(() => setLoading(false));
  }, [dispatch, theme]); // eslint-disable-line

  const Loader = <Loading style={{ padding: '5px 0' }} />;
  return (
    <>
      <StyledSection title={getIntl('CO₂ summary')} className="mt-2">
        {!loading ? (
          <>
            {climateBenefit ? (
              <StyledSummaryItem>
                <span title={getIntl('Climate benefit (t CO₂)')}>{getIntl('Climate benefit (t CO₂)')}</span>
                <span>{numberFormat(climateBenefit, { maximumFractionDigits: 0, fallback: '-' })}</span>
              </StyledSummaryItem>
            ) : (
              NoSectionData
            )}
          </>
        ) : (
          Loader
        )}
      </StyledSection>
      <StyledSection title={getIntl('DER summary')} className="mt-2">
        {!loading ? (
          <>
            {derSummaries.length
              ? derSummaries?.map((s: any) => {
                  const title = s.description ? getIntl(s.description) : '-';
                  return (
                    <StyledSummaryItem key={title}>
                      <span title={title}>{title}</span>
                      <span>
                        {numberFormat(s.population, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) ??
                          getIntl('Unknown')}
                      </span>
                    </StyledSummaryItem>
                  );
                })
              : NoSectionData}
          </>
        ) : (
          Loader
        )}
      </StyledSection>
      <SectionHint marginTop>
        * {getIntl('DER — Distributed Energy Resources')}
        <br />*{' '}
        <span
          dangerouslySetInnerHTML={{
            __html: getIntl('99.9<sup>th</sup> percentile results are displayed'),
          }}
        />
      </SectionHint>
    </>
  );
};

const StyledSection = styled(Section)`
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: var(--map-text-color-secondary);
`;

const StyledSummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 80%;
  }
`;

export default ForecastInvestmentMaxLoadSummary;
