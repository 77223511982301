import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchDERsPointAssetsOptionsAction } from 'modules/options';
import { DERsPointAssetsOptionsSelector, simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps, InputActionMeta } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: string | null;
  onChange: (value: string) => void;
}

const SelectDERsPointAsset: React.FC<Props> = ({ value = null, onChange, ...props }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const limit = 3;
  const simulationVersionId = useSelector(simulationSelectedOptionVersionIdSelector);
  const options = useSelector(DERsPointAssetsOptionsSelector);

  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const selectValue = useMemo(() => options?.find(option => option.value === value) || null, [options, value]);

  const filteredOptions = useMemo(() => {
    if (inputValue.length < limit || !options) return [];
    return options.filter(option => String(option.value).includes(inputValue));
  }, [options, inputValue]);

  useEffect(() => {
    if (!simulationVersionId || options?.length) return;
    setLoading(true);
    dispatch(fetchDERsPointAssetsOptionsAction({ simulationVersionId })).finally(() => setLoading(false));
  }, [dispatch, simulationVersionId, options]);

  const handleSelectChange = useCallback((value: Type.SelectOption) => onChange((value as any)?.value), [onChange]);

  const handleInputChange = useCallback(
    (value: string, meta: InputActionMeta) => {
      if (meta.action === 'input-change') {
        setInputValue(value);
        if (!value) onChange(value);
      }
    },
    [onChange]
  );

  // Note. keep previous search input position
  const handleFocus = useCallback(() => setInputValue(selectValue?.label || ''), [selectValue]);

  // Note. reset search input value
  const handleBlur = useCallback(() => setInputValue(''), []);

  return (
    <FormReactSelect
      labelKey="Point asset"
      value={selectValue}
      options={filteredOptions}
      onChange={handleSelectChange}
      isDisabled={!options?.length}
      isLoading={loading}
      inputValue={inputValue}
      placeholderKey="Type to search"
      onInputChange={handleInputChange}
      // keep previous search input position
      onFocus={handleFocus}
      onBlur={handleBlur}
      noOptionsMessage={() =>
        inputValue.length < limit ? getIntl('Please type {{limit}} symbols', { limit }) : getIntl('No options')
      }
      blurInputOnSelect
      isClearable
      {...props}
    />
  );
};

export default SelectDERsPointAsset;
