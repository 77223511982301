import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.history;

export const historyItemsSelector = createSelector(
  rootSelector,
  (history: History.Root): History.Item[] => history.rows
);

export const historyCountSelector = createSelector(rootSelector, (history: History.Root): number => history.count);
