import { useCallback, useMemo } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function useBuilderState() {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { pathname, state } = useLocation<Builder.LocationState>();

  const selectedBlock: number | null = useMemo(() => {
    const index: number = state?.blocks?.findIndex((item: Builder.SnapshotBlock) => item.selected);
    return index !== -1 ? index : null;
  }, [state]);

  const setLocationState = useCallback(
    (modifier: Partial<Builder.LocationState>): void => {
      dispatch(push(pathname, { ...state, ...modifier }));
    },
    [pathname, state, dispatch]
  );

  return { ...state, selectedBlock, setLocationState };
}
