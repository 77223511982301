import { combineActions, handleActions } from 'redux-actions';
import { fetchFlexibilityPlanAction, fetchYearsRangeAction } from './actions';

const initialState: Flexibility.Root = {
  planCount: 0,
  planHash: {},
  planYearsRangeHash: {},
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchFlexibilityPlanAction, fetchYearsRangeAction) as any]: {
    next: (state: Flexibility.Root, action: Shared.ReduxAction<Partial<Flexibility.Root>>): Flexibility.Root => ({
      ...state,
      ...action.payload,
    }),
  },
};

export { fetchFlexibilityPlanAction, fetchYearsRangeAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
