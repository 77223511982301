import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { appLangSelector } from 'modules/app/selectors';
import { i18next } from 'utils';
import { dateFormat, numberFormat } from '@utiligize/shared/utils';

export default function useLocale() {
  const lng = useSelector(appLangSelector);
  const getIntl = useCallback(
    (localeKey: string, options = {}) => localeKey && i18next.t(localeKey, { lng, ...options }),
    [lng]
  );

  return { lng, getIntl, numberFormat, dateFormat };
}
