import React from 'react';
import styled from 'styled-components';
import { useLocale } from 'hooks';

const GroupTitle: React.FC<{ titleKey: string }> = ({ titleKey }) => {
  const { getIntl } = useLocale();
  return <StyledTitle>{getIntl(titleKey)}</StyledTitle>;
};

const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--map-text-color-secondary);
  padding: 10px 0;
  user-select: none;
`;

export default GroupTitle;
