import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserChartActions, resetModule } from 'modules/statistics';
import { StatisticsCompleteActionsChart, StatisticsFinalChecksChart } from 'components/_charts';
import { ContentContainer } from 'components/_common';

const StatisticsUserDetails: React.FC<{ id: string; filters: Layouts.Filters }> = ({ id, filters }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (id && filters) dispatch(fetchUserChartActions(id));
    return () => dispatch(resetModule());
  }, [dispatch, id, filters]);

  return (
    <ContentContainer>
      <StatisticsCompleteActionsChart height="calc((100vh - 172px) / 2);" />
      <StatisticsFinalChecksChart height="calc((100vh - 172px) / 2);" />
    </ContentContainer>
  );
};

export default StatisticsUserDetails;
