import { handleActions } from 'redux-actions';
import {
  fetchBudgetingParametersAction,
  updateBudgetingParametersItemAction,
  recalculateBudgetingAction,
  resetBudgetingAction,
} from './actions';

const initialState: Budgeting.Root = {
  budgetingParametersCount: 0,
  budgetingParameters: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchBudgetingParametersAction]: {
    next: (
      state: Budgeting.Root,
      action: Shared.ReduxAction<Budgeting.Root & { skipStoreUpdate: true | undefined }>
    ): Budgeting.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchBudgetingParametersAction,
  updateBudgetingParametersItemAction,
  recalculateBudgetingAction,
  resetBudgetingAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
