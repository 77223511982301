import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createChecklistAction, editChecklistAction } from 'modules/tasks';
import { NonAssetTaskAppActions } from 'constants/index';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.CreateChecklistActionProps>({
    initialValues: {
      name: '',
      appAction: null,
      assetCategoryCode: null,
      questionInput: '',
      questions: [],
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      appAction: Yup.string().nullable().required("Field can't be empty!"),
      assetCategoryCode: Yup.string()
        .nullable()
        .when('appAction', (appAction: Type.TaskAppActions, schema: any) =>
          schema.test({
            test: (assetCategoryCode: number) => {
              return !appAction || !!assetCategoryCode || NonAssetTaskAppActions.includes(appAction);
            },
            message: "Field can't be empty!",
          })
        ),
      questions: Yup.array().min(1, 'Please add at least one question'),
    }),

    onSubmit: values => {
      const data = { ...values, questionInput: undefined };
      if (id) return dispatch(editChecklistAction(id, data)).then(toggleModal);
      return dispatch(createChecklistAction(data)).then(toggleModal);
    },
  });

  return formik;
};

export default useConfiguredFormik;
