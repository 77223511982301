import React, { useCallback, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { patchAssetModelAction } from 'modules/assetModels';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconChevronUp, IconChevronDown } from '@utiligize/shared/resources';

interface Props {
  modelId: number;
  replacementPriority: number;
}

const ReplacementPriority: React.FC<Props> = memo(({ modelId, replacementPriority }) => {
  const { filters } = useSelector(paginationSelectorFactory(PaginationType.ASSET_MODELS));
  const [loadingValue, setLoadingValue] = useState<null | number>(null);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isReplacementPriorityDisabled = !filters?.subComponentTypeId || filters?.subComponentTypeId === 'unassigned';

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const replacementPriority: number = Number(event.currentTarget.getAttribute('data-index'));
      setLoadingValue(replacementPriority);
      dispatch(patchAssetModelAction(modelId, { replacementPriority })).finally(() => setLoadingValue(null));
    },
    [modelId, dispatch]
  );

  return (
    <>
      {typeof replacementPriority === 'number' ? (
        <>
          {!isReplacementPriorityDisabled && (
            <Button
              type="button"
              variant="primary-link"
              icon={<IconChevronDown />}
              data-index={replacementPriority + 1}
              onClick={handleButtonClick}
              loading={loadingValue === replacementPriority + 1}
              disabled={Boolean(loadingValue && loadingValue !== replacementPriority + 1)}
            />
          )}
          {replacementPriority}
          {!isReplacementPriorityDisabled && (
            <Button
              type="button"
              variant="primary-link"
              icon={<IconChevronUp />}
              data-index={replacementPriority - 1}
              onClick={handleButtonClick}
              loading={loadingValue === replacementPriority - 1}
              disabled={Boolean(loadingValue && loadingValue !== replacementPriority - 1) || replacementPriority === 1}
            />
          )}
        </>
      ) : (
        '-'
      )}
    </>
  );
});

export default ReplacementPriority;
