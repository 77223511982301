import React from 'react';
import { FormsModal } from 'components/_modals';
import { FormsTable } from 'components/_tables';
import { DataTableTemplate } from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import { PermissionsTypes } from 'constants/index';

const PageForms: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isFormsEnabled}>
    <DataTableTemplate paddingBottom>
      <FormsModal />
      <FormsTable />
    </DataTableTemplate>
  </PagePermissionsProvider>
);

export default PageForms;
