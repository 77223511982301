import React from 'react';
import { MaintenancePlansTable } from 'components/_tables';
import { MaintenancePlanModal } from 'components/_modals';
import { DataTableTemplate } from 'components/_common';

const CreatePlans: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <MaintenancePlanModal />
    <MaintenancePlansTable />
  </DataTableTemplate>
);

export default CreatePlans;
