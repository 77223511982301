import { createSelector } from 'reselect';
import { appLangSelector } from 'modules/app/selectors';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
  investmentScenarioIdSelector,
} from 'modules/layouts/selectors';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.options;

export const portfolioOptionsFetchedSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['portfolioOptionsFetched'] => options.portfolioOptionsFetched
);

export const portfolioOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['portfolioOptions'] => options.portfolioOptions
);

export const portfolioOptionSelector = createSelector(
  [portfolioOptionsSelector, portfolioIdSelector],
  (
    portfolioOptions: Options.Root['portfolioOptions'],
    portfolioId: Layouts.Root['portfolioId']
  ): Type.SelectOption | null => portfolioOptions?.find(option => option.value === portfolioId) || null
);

export const isPortfolioLoadflowEnabledSelector = createSelector(
  portfolioOptionSelector,
  (portfolioOption: Type.SelectOption | null): boolean => Boolean(portfolioOption?.loadflow_enabled)
);

export const scenarioOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['scenarioOptions'] => options.scenarioOptions
);

export const scenarioSelectedOptionSelector = createSelector(
  [scenarioOptionsSelector, scenarioIdSelector],
  (
    scenarioOptions: Options.Root['scenarioOptions'],
    scenarioId: Layouts.Root['scenarioId']
  ): Options.ScenarioOption | null => scenarioOptions?.find(option => option.value === scenarioId) || null
);

export const scenariosParamSelector = createSelector(
  scenarioSelectedOptionSelector,
  (scenarioOption: Options.ScenarioOption | null): number | null => scenarioOption?.scenariosParam || null
);

export const scenarioYearsOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['scenarioYearsOptions'] => options.scenarioYearsOptions
);

export const simulationOptionsFetchedSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['simulationOptionsFetched'] => options.simulationOptionsFetched
);

export const simulationOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['simulationOptions'] => options.simulationOptions
);

export const simulationSelectedOptionSelector = createSelector(
  [simulationOptionsSelector, simulationIdSelector],
  (
    simulationOptions: Options.Root['simulationOptions'],
    simulationId: Layouts.Root['simulationId']
  ): Options.SimulationOption | null => simulationOptions?.find(option => option.value === simulationId) || null
);

export const simulationSelectedOptionVersionIdSelector = createSelector(
  simulationSelectedOptionSelector,
  (option: Options.SimulationOption | null): number | null => option?.versionId || null
);

export const percentilesOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['percentilesOptions'] => options.percentilesOptions
);

export const scenariosYearsOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['scenariosYearsOptionsHash'] => options.scenariosYearsOptionsHash
);

export const scenariosYearsOptionsSelector = createSelector(
  [scenariosYearsOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (
    scenariosYearsOptionsHash: Options.Root['scenariosYearsOptionsHash'],
    portfolioId: Layouts.Root['portfolioId'],
    scenarioId: Layouts.Root['scenarioId']
  ): Type.SelectOption[] | null => scenariosYearsOptionsHash[`${portfolioId}_${scenarioId}`] || null
);

export const outageTypeOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['outageTypeOptions'] => options.outageTypeOptions
);

export const routesOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['routesOptions'] => options.routesOptions
);

export const investmentScenariosOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['investmentScenariosOptions'] => options.investmentScenariosOptions
);

export const investmentScenariosOptionSelector = createSelector(
  [investmentScenariosOptionsSelector, investmentScenarioIdSelector],
  (
    investmentScenariosOptions: Options.Root['investmentScenariosOptions'],
    investmentScenarioId: Layouts.Root['investmentScenarioId']
  ): Type.SelectOption | null =>
    investmentScenariosOptions?.find(option => option.value === investmentScenarioId) || null
);

export const componentPriceScenarioOptionsSelector = createSelector(
  [rootSelector, portfolioIdSelector],
  (options: Options.Root, portfolioId: Layouts.Root['portfolioId']): Type.SelectOption[] =>
    options.componentPriceScenarioOptionsHash[String(portfolioId)]
);

export const componentPriceTypeOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['componentPriceTypeOptions'] => options.componentPriceTypeOptions
);

export const cnaimAssetCategoriesOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['cnaimAssetCategoriesOptionsHash'] => options.cnaimAssetCategoriesOptionsHash
);

export const cnaimAssetCategoriesOptionsSelector = createSelector(
  [cnaimAssetCategoriesOptionsHashSelector, appLangSelector],
  (hash: Options.Root['cnaimAssetCategoriesOptionsHash'], lang: App.Languages): Type.SelectOption[] | null =>
    hash[lang] || null
);

export const DERsDescriptionOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['DERsDescriptionOptionsHash'] => options.DERsDescriptionOptionsHash
);

export const DERsDescriptionOptionsSelector = createSelector(
  [DERsDescriptionOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (
    DERsDescriptionOptionsHash: Options.Root['DERsDescriptionOptionsHash'],
    portfolioId: Layouts.Root['portfolioId'],
    scenarioId: Layouts.Root['scenarioId']
  ): Type.SelectOption[] | null => DERsDescriptionOptionsHash?.[`${portfolioId}_${scenarioId}`] || null
);

export const DERsPointAssetsOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['DERsPointAssetsOptionsHash'] => options.DERsPointAssetsOptionsHash
);

export const DERsPointAssetsOptionsSelector = createSelector(
  [rootSelector, simulationSelectedOptionVersionIdSelector],
  (options: Options.Root, simulationVersionId: number | null): Type.SelectOption[] | null =>
    (simulationVersionId && options.DERsPointAssetsOptionsHash[simulationVersionId]) || null
);

export const workTypeOptionsSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['workTypeOptions'] => options.workTypeOptions
);

export const primarySubstationOptionsHashSelector = createSelector(
  rootSelector,
  (options: Options.Root): Options.Root['primarySubstationOptionsHash'] => options.primarySubstationOptionsHash
);

export const primarySubstationOptionsSelector = createSelector(
  [primarySubstationOptionsHashSelector, portfolioIdSelector, scenarioIdSelector],
  (hash: Options.Root['primarySubstationOptionsHash'], portfolioId, scenarioId): Type.SelectOption[] | null =>
    hash[`${portfolioId}_${scenarioId}`] || null
);

export const allTableN1OptionsFetchedSelector = createSelector(
  [outageTypeOptionsSelector, routesOptionsSelector, primarySubstationOptionsSelector],
  (
    outageTypeOptions: Options.Root['outageTypeOptions'],
    routesOptions: Options.Root['routesOptions'],
    primarySubstationOptions: Type.SelectOption[] | null
  ): boolean => Boolean(outageTypeOptions?.length && routesOptions?.length && primarySubstationOptions?.length)
);
