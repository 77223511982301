import classNames from 'classnames';
import styled from 'styled-components';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

export interface FormInputProps extends FormControlProps {
  autoFocus?: boolean;
  name?: string;
  label?: string | React.ReactElement;
  placeholderKey?: string;
  labelKey?: string;
  errorKey?: string;
  rows?: number;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  min?: number;
  max?: number;
  step?: number;
  accept?: string;
  isNew?: boolean;
  mutedTextLabelKey?: string;
  getIntl?: (localeKey: string, options?: {}) => string;
}

const FormInput: React.FC<FormInputProps> = ({
  autoFocus = false,
  label = '',
  labelKey = '',
  mutedTextLabelKey = '',
  placeholderKey = '',
  errorKey = '',
  onChange,
  isNew = false,
  getIntl = str => str,
  ...props
}) => {
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  useEffect(() => {
    if (autoFocus) inputRef?.current?.focus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      if (value === ' ' || !onChange) return;

      // Automatically apply min or max allowed values
      const minValue = typeof props.min === 'number' && props.min > Number(value) ? props.min : null;
      const maxValue = typeof props.max === 'number' && props.max < Number(value) ? props.max : null;
      if (event.target.value !== '' && (minValue !== null || maxValue !== null)) {
        event.target.value = String(minValue !== null ? minValue : maxValue);
      }

      onChange(event);
    },
    [props.min, props.max, onChange]
  );

  return (
    <>
      {(label || labelKey) && <Form.Label>{label || getIntl(labelKey)}</Form.Label>}
      <StyledFormControl
        data-new={isNew}
        {...props}
        placeholder={placeholderKey && getIntl(placeholderKey)}
        isInvalid={Boolean(errorKey)}
        ref={inputRef}
        onChange={handleInputChange}
        className={classNames('form-control', props.className || '')}
      />
      {Boolean(errorKey) && <Form.Control.Feedback type="invalid">{getIntl(errorKey)}</Form.Control.Feedback>}
      {mutedTextLabelKey && <Form.Text className="text-muted">{getIntl(mutedTextLabelKey)}</Form.Text>}
    </>
  );
};

const StyledFormControl = styled(Form.Control)<{ 'data-new': boolean }>`
  font-size: 14px;

  ${props =>
    props['data-new'] &&
    `
    padding: 0px 10px;
    border-radius: 8px;
    height: ${props.size === 'lg' ? 38 : 32}px;
  `}

  &:disabled {
    resize: none;
  }
`;

export default FormInput;
