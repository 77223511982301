import { handleActions } from 'redux-actions';
import { fetchInspectionsAction, fetchInspectionSnapshotAction, deleteInspectionAction } from './actions';

const initialState: Inspections.Root = {
  loading: false,
  isFetched: false,
  count: 0,
  rows: [],
  builderData: {} as Builder.Data,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchInspectionsAction]: {
    next: (
      state: Inspections.Root,
      action: Shared.ReduxAction<Omit<Inspections.Root, 'loading' | 'isFetched'>>
    ): Inspections.Root => ({
      ...state,
      ...action.payload,
      loading: false,
      isFetched: true,
    }),
    throw: (state: Inspections.Root): Inspections.Root => ({
      ...state,
      loading: false,
      isFetched: false,
    }),
  },
};

export { fetchInspectionsAction, fetchInspectionSnapshotAction, deleteInspectionAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
