import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.reinvestment;

export const reinvestmentCNAIMCountSelector = createSelector(
  rootSelector,
  (reinvestment: Reinvestment.Root): number => reinvestment.reinvestmentCNAIMCount
);

const reinvestmentCNAIMHashSelector = createSelector(
  rootSelector,
  (reinvestment: Reinvestment.Root): Reinvestment.Root['reinvestmentCNAIMHash'] => reinvestment.reinvestmentCNAIMHash
);

export const reinvestmentCNAIMSelector = createSelector(
  reinvestmentCNAIMHashSelector,
  (reinvestmentCNAIMHash: Reinvestment.Root['reinvestmentCNAIMHash']): Reinvestment.CNAIM[] =>
    Object.values(reinvestmentCNAIMHash)
);
