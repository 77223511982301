import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import {
  Assets,
  AssetsAgeStatistics,
  AssetsCategoriesCNAIMDetails,
  AssetsCategories,
  AssetsSubComponents,
  AssetDetails,
} from 'components/Assets';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageReinvestmentAssets: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isAssetsEnabled}>
    <Switch>
      <Route exact path={Routes.Assets} component={Assets} />
      <Route path={Routes.AssetsAgeStatistics} component={AssetsAgeStatistics} />
      <Route
        exact
        path={[Routes.AssetsCategories, Routes.AssetsCategoriesCNAIM]}
        render={(props: RouteComponentProps) =>
          (props.match.params as { id?: string }).id ? (
            <AssetsCategoriesCNAIMDetails id={Number((props.match.params as { id?: string }).id)} />
          ) : (
            <AssetsCategories />
          )
        }
      />
      <Route exact path={Routes.AssetsSubComponents} component={AssetsSubComponents} />
      <Route
        exact
        path={Routes.AssetCreate}
        render={(props: RouteComponentProps) => <AssetDetails isCreateMode {...props} />}
      />
      <Route exact path={Routes.AssetDetails} component={AssetDetails} />
    </Switch>
  </PagePermissionsProvider>
);

export default PageReinvestmentAssets;
