import React from 'react';
import { useLocale } from 'hooks';
import { AppErrorsTypes } from 'constants/index';

const PageTenantNotFound: React.FC<{ appError: App.AppErrorsTypes }> = ({ appError }) => {
  const { getIntl } = useLocale();
  const isTenantNotFoundRoute = appError === AppErrorsTypes.TenantNotFound;

  return (
    <div className="error-page mt-0 pt-5">
      <h2 className="headline text-warning"> 404</h2>
      <div className="error-content">
        <h3>
          <i className="fas fa-exclamation-triangle text-warning"></i>{' '}
          {getIntl(isTenantNotFoundRoute ? 'Oops! Tenant not found' : 'Oops! No assigned tenants')}.
        </h3>
        <p>
          {getIntl(
            isTenantNotFoundRoute
              ? 'We could not find the tenant you were looking for. Meanwhile, you may use the user menu to select another tenant'
              : 'We could not find any assigned tenants to your account. Please contact administrator'
          )}
          .
        </p>
      </div>
    </div>
  );
};

export default PageTenantNotFound;
