import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDERsProfilesAction } from 'modules/newLoad';
import { DERsProfilesSelector } from 'modules/newLoad/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  onChange: (value: number | null) => void;
  disabled?: boolean;
}

const SelectDERsProfile: React.FC<Props> = ({ value = null, onChange, disabled, ...rest }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const DERsProfiles = useSelector(DERsProfilesSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!portfolioId || DERsProfiles) return;
    setLoading(true);
    dispatch(fetchDERsProfilesAction(portfolioId)).finally(() => setLoading(false));
  }, [dispatch, portfolioId, DERsProfiles]);

  const { options, selectValue } = useMemo(
    () =>
      (DERsProfiles || []).reduce(
        (acc: any, profile: NewLoad.DERProfile) => {
          const item = { value: profile.id, label: profile.description };
          if (item.value === value) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, DERsProfiles]
  );

  const handleSelectChange = useCallback((value: Type.SelectOption) => onChange(value.value as number), [onChange]);

  return (
    <FormReactSelect
      labelKey="Profile"
      value={selectValue}
      options={options}
      onChange={handleSelectChange}
      isDisabled={!DERsProfiles || disabled}
      isLoading={loading}
      {...rest}
    />
  );
};

export default SelectDERsProfile;
