import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  inspectionsCountSelector,
  inspectionsItemsSelector,
  inspectionsBuilderDataSelector,
} from 'modules/inspections/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { fetchInspectionsAction, fetchInspectionSnapshotAction, deleteInspectionAction } from 'modules/inspections';
import { DataTable, Button, ShowUsersOverlay } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { transformBlocks, getFieldTypesArray } from 'utils';
import { Routes, PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const InspectionsTable: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(inspectionsCountSelector);
  const items = useSelector(inspectionsItemsSelector);
  const builderData: Builder.Data = useSelector(inspectionsBuilderDataSelector);

  const sendRequest = useCallback(() => dispatch(fetchInspectionsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const builderDataCopy: Builder.Data = _cloneDeep(builderData);

      const inspectionItem = items.find((elem: Inspections.Item) => elem.id === id);
      if (!inspectionItem) throw Error('inspectionItem not found');

      const { formtypeId } = inspectionItem;
      const fieldTypes = getFieldTypesArray(builderDataCopy, formtypeId);

      const formtype = builderDataCopy.formTypes.find((elem: Type.FormType) => elem.id === formtypeId);
      if (!formtype) throw Error('formtype not found');

      const selectValue: Builder.SelectValue = {
        id: inspectionItem.id,
        name: inspectionItem.name,
        formtype,
        userIds: inspectionItem.userIds,
        assetCategories: null as any,
        formSubcomponents: null,
      };

      dispatch(fetchInspectionSnapshotAction(inspectionItem.id)).then((action: any) => {
        dispatch(
          push(Routes.InspectionBuilder, {
            isNew: false,
            isDuplicate: false,
            selectValue,
            blocks: transformBlocks(action.payload.lastSnapshot.blocks),
            snapshots: action.payload.snapshots,
            fieldTypes,
            formTypes: builderDataCopy.formTypes,
          } as Builder.LocationState)
        );
      });
    },
    [items, builderData, dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteInspectionAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={PaginationType.INSPECTIONS} totalAmount={count} sendRequest={sendRequest}>
      {items?.map((item: Inspections.Item) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{dateFormat(item.createdAt)}</td>
          <td>{getIntl(item.formtype.name)}</td>
          <td className="text-right">
            <ShowUsersOverlay popoverId={item.id} userIds={item.userIds} />
            <Button
              marginLeft
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default InspectionsTable;
