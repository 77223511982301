import styled from 'styled-components';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PanelGroup, Panel } from 'react-resizable-panels';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import { mapPanelEnabledSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Button } from 'components/_common';
import { IconChevronLeft, IconChevronRight } from '@utiligize/shared/resources';
import UnifiedAssetPanel from './UnifiedAssetPanel';

const MapPanels: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const mapPanelEnabled = useSelector(mapPanelEnabledSelector);
  const uuid = useSelector(mapPanelSelectedAssetUUIDSelector);

  const handleMapPanelEnabledButtonClick = useCallback(() => {
    dispatch(setLayoutAction({ mapPanelEnabled: !mapPanelEnabled }));
  }, [dispatch, mapPanelEnabled]);

  return (
    <PanelGroup autoSaveId="horizontal-map-panel-group" direction="horizontal">
      <Panel defaultSize={75} id="main" order={1} className="position-relative">
        {children}
        <StyledPanelControlButton>
          <Button
            data-marker="main_map_panel_toggle"
            variant="light"
            onClick={handleMapPanelEnabledButtonClick}
            icon={mapPanelEnabled ? <IconChevronRight /> : <IconChevronLeft />}
            tooltipKey={mapPanelEnabled ? 'Hide panel' : 'Show panel'}
            tooltipPlacement="left"
          />
        </StyledPanelControlButton>
      </Panel>
      {mapPanelEnabled && <UnifiedAssetPanel uuid={uuid} />}
    </PanelGroup>
  );
};

const StyledPanelControlButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export default MapPanels;
