import React from 'react';
import { Form } from 'react-bootstrap';
import { useToggle, useEventPreventedExec } from 'hooks';
import { FormInput, Modal, HiddenFormSubmit } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFieldsTypes } from 'constants/index';

const TwoOptionModal: React.FC<Builder.ModalProps> = ({
  formField,
  addField,
  onChangeModal,
  onCloseModal: closeModal,
}) => {
  const isEditMode: boolean = Boolean(formField);
  const [show, toggleModal] = useToggle(true);
  const formik = useConfiguredFormik({ formField, addField, onChangeModal, isEditMode, toggleModal });
  const { values, errors, touched, handleChange, handleBlur, submitForm } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onExited={closeModal}
      titleKey={BuilderFieldsTypes.TwoOption}
      cancelButtonProps={{
        onClick: toggleModal,
      }}
      submitButtonProps={{
        labelKey: isEditMode ? 'Update' : 'Create',
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            autoFocus
            labelKey="Label (shown to user)"
            name="label"
            value={values.label}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.label && errors.label) ? (errors.label as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <FormInput
            labelKey="Add select variants"
            name="variant1"
            value={values.variant1}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.variant1 && errors.variant1) ? (errors.variant1 as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <FormInput
            name="variant2"
            value={values.variant2}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.variant2 && errors.variant2) ? (errors.variant2 as string) : ''}
          />
        </Form.Group>
        <HiddenFormSubmit />
      </Form>
    </Modal>
  );
};

export default TwoOptionModal;
