import React from 'react';

interface Props {
  colSpan: number;
  children: React.ReactNode;
  dataMarker?: string;
}

const EmptyTable: React.FC<Props> = ({ colSpan, children, dataMarker }) => (
  <tr data-marker={dataMarker}>
    <td style={{ height: 90, verticalAlign: 'middle' }} rowSpan={5} colSpan={colSpan} className="text-center">
      {children}
    </td>
  </tr>
);

export default EmptyTable;
