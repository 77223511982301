import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { reinvestmentCNAIMCountSelector, reinvestmentCNAIMSelector } from 'modules/reinvestment/selectors';
import { fetchReinvestmentCNAIMAction } from 'modules/reinvestment';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableCNAIM: React.FC = () => {
  const dispatch = useDispatch();
  const { numberFormat } = useLocale();

  const items = useSelector(reinvestmentCNAIMSelector);
  const count = useSelector(reinvestmentCNAIMCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchReinvestmentCNAIMAction(params)).then(
        (action: Shared.ReduxAction<{ reinvestmentCNAIMHash: Reinvestment.Root['reinvestmentCNAIMHash'] }>) =>
          Object.values(action.payload?.reinvestmentCNAIMHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.REINVESTMENT_CNAIM}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
    >
      {items?.map?.((item: Reinvestment.CNAIM) => (
        <tr key={item.customer_id}>
          <td>{item.customer_id}</td>
          <td>{numberFormat(item.loading_percent_max, { fallback: '-' })}</td>
          <td>{numberFormat(item.pof_sum, { fallback: '-' })}</td>
          <td>{item.critical_pof_reach}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCNAIM;
