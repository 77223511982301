import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { maintenancePlansCountSelector, maintenancePlansSelector } from 'modules/maintenance/selectors';
import { fetchMaintenancePlansAction, deleteMaintenancePlanAction } from 'modules/maintenance';
import { setModalConfirmationMetaAction, setMaintenancePlansModalIdAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const MaintenancePlansTable: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(maintenancePlansCountSelector);
  const items = useSelector(maintenancePlansSelector);

  const sendRequest = useCallback(() => dispatch(fetchMaintenancePlansAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      dispatch(setMaintenancePlansModalIdAction(id));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteMaintenancePlanAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.MAINTENANCE_CREATE_PLANS}
      totalAmount={count}
      sendRequest={sendRequest}
      maxHeight="calc(100vh - 154px)"
    >
      {items.map((item: Maintenance.Plan) => (
        <tr key={item.id}>
          <td>{item.assetCategory || '-'}</td>
          <td>{item.dateSelect || '-'}</td>
          <td>{getIntl(item.date_explicit ? 'Yes' : 'No')}</td>
          <td>{item.planName || '-'}</td>
          <td>{item.missingAction ? getIntl(item.missingAction) : '-'}</td>
          <td>{item.intervalYears || '-'}</td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default MaintenancePlansTable;
