import React from 'react';
import { cnaimAssetCategoriesOptionsSelector } from 'modules/options/selectors';
import { fetchCnaimAssetCategoriesOptionsAction } from 'modules/options';
import { SelectRedux, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectCNAIMAssetCategory: React.FC<Props> = props => (
  <SelectRedux
    {...props}
    optionsSelector={cnaimAssetCategoriesOptionsSelector}
    optionsAction={fetchCnaimAssetCategoriesOptionsAction}
  />
);

export default SelectCNAIMAssetCategory;
