import * as Sentry from '@sentry/react';
import { isDevelopment } from 'constants/index';

export const initSentry = () => {
  if (isDevelopment) return;
  const version: string = process.env.REACT_APP_VERSION || '';
  const release: string = `utiligize_admin_panel@${version.slice(0, version.lastIndexOf('.'))}`;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release,
    normalizeDepth: 5,
    environment: process.env.REACT_APP_NODE_ENV,
  });
};
