import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectedMapThemeSelector } from 'modules/layouts/selectors';
import { scenarioSelectedOptionSelector } from 'modules/options/selectors';
import {
  createMapStateSelectorFactory,
  enabledLayersListSelector,
  settingsSelector,
  showCableAnimationSelector,
} from 'modules/map/selectors';
import { setMapSourceField, showTheme, hideTheme } from 'utils/map';
import { updateScenarioLayer } from 'components/Map/core/layers/load';

interface Props {
  map: Map.MapboxMap;
}

const YearlyEnergyConsumption: React.FC<Props> = ({ map }) => {
  const settings = useSelector(settingsSelector);
  const selectedMapTheme = useSelector(selectedMapThemeSelector);
  const selectedScenario = useSelector(scenarioSelectedOptionSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const scenario = useSelector(createMapStateSelectorFactory('consumptionScenario'));
  const showPercents = useSelector(createMapStateSelectorFactory('showConsumptionPercents'));
  const enabledLayersList = useSelector(enabledLayersListSelector);

  useEffect(() => {
    setMapSourceField(map, settings.consumptionLayers!, {
      scenario_id: String(selectedScenario?.value),
    });
  }, [map, selectedScenario]); // eslint-disable-line

  useEffect(() => {
    if (!scenario) return;
    const layers = settings.consumptionLayers!;
    const max = settings.consumptionMaxValues;
    const property = showPercents ? `p_${scenario.key}` : `e_${scenario.key}`;
    const scaleType = 'default';
    const scaleRanges = showPercents
      ? [[0, max?.maxPct ?? 1]]
      : [
          [0, 250],
          [0, 50],
          [0, 10],
        ];
    updateScenarioLayer(map, property, layers, selectedMapTheme, scaleType, scaleRanges);
  }, [map, showPercents, scenario, selectedMapTheme]); // eslint-disable-line

  // show and update all layers when theme is active
  useEffect(() => {
    showTheme(map, settings.consumptionLayers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  // hide all theme layers when theme is inactive
  useEffect(() => {
    return () => {
      hideTheme(map, settings.consumptionLayers!);
    };
  }, [map]); // eslint-disable-line

  return null;
};

export default React.memo(YearlyEnergyConsumption);
