import React, { useEffect, useCallback } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import RangeSlider from 'react-bootstrap-range-slider';
import { useDebouncedCallback } from 'use-debounce';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { FormInput } from 'components/_common';
import { PaginationType } from 'constants/index';

const RangeSliderImpactYear: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.INTERVENTIONS));
  const minValue = new Date().getFullYear();
  const maxValue = 2050;
  const [value, setValue] = React.useState(filters?.impactYear || minValue);

  const debounced = useDebouncedCallback((impactYear: number) => {
    dispatch(
      setPaginationAction({ type: PaginationType.INTERVENTIONS, modifier: { filters: { ...filters, impactYear } } })
    );
  }, 1500);

  useEffect(() => {
    if (value === filters?.impactYear) return;
    debounced(value);
  }, [debounced, value, filters]);

  useEffect(() => debounced.cancel, [debounced.cancel]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.currentTarget.value);
      setValue(value > maxValue ? maxValue : value < minValue ? minValue : value);
    },
    [minValue]
  );

  const handleInputBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(event.currentTarget.value);
      if (!value) setValue(minValue);
    },
    [minValue]
  );

  return (
    <>
      <Col sm="auto">
        <FormInput
          value={value}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          style={{ width: '70px' }}
          type="number"
          min={minValue}
          max={maxValue}
          size="sm"
        />
      </Col>
      <Col lg={3} sm={3} xs={4}>
        <RangeSlider
          value={value}
          tooltipPlacement="top"
          onChange={handleInputChange}
          min={minValue}
          max={maxValue}
          size="sm"
        />
        <Form.Text className="text-muted">{getIntl('Impact year')}</Form.Text>
      </Col>
    </>
  );
};

export default RangeSliderImpactYear;
