import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DataTableTemplate } from 'components/_common';
import { RepeatedTasksSectionsModal, RepeatedTasksModal } from 'components/_modals';
import RepeatedTasksTabs from './RepeatedTasksTabs';

const ViewRepeatedTasks: React.FC = () => (
  <DataTableTemplate>
    <Row>
      <Col xs="auto">
        <RepeatedTasksSectionsModal />
      </Col>
      <Col xs="auto">
        <RepeatedTasksModal />
      </Col>
    </Row>
    <RepeatedTasksTabs />
  </DataTableTemplate>
);

export default ViewRepeatedTasks;
