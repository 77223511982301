import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { permissionsFeaturesModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { permissionsFeatureSelectorFactory } from 'modules/permissions/selectors';
import { Modal, FormInput, SelectTenants } from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';

const PermissionsFeaturesModal: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const id = useSelector(permissionsFeaturesModalIdSelector);
  const feature: Permissions.Feature | null = useSelector(permissionsFeatureSelectorFactory(id));
  const [show, setShow] = useState(false);

  const closeModal = useCallback((): void => {
    dispatch(setLayoutAction({ permissionsFeaturesModalId: null }));
    setShow(false);
  }, [dispatch]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
  } = useConfiguredFormik({
    id: id as number,
    initialValues: {
      description: feature?.description || '',
      tenantsIds: feature?.tenants.map(tenant => tenant.id) || [],
    },
    closeModal,
  });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (id && feature) setShow(true);
    if (!show) resetForm();
  }, [id, feature, show, resetForm]);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      titleKey="Edit feature"
      cancelButtonProps={{
        disabled: isSubmitting,
        onClick: closeModal,
      }}
      submitButtonProps={{
        labelKey: 'Update',
        loading: isSubmitting,
        onClick: handleFormSubmit,
      }}
    >
      <Form onSubmit={handleFormSubmit}>
        <Form.Group>
          <FormInput
            labelKey="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            errorKey={Boolean(touched.description && errors.description) ? (errors.description as string) : ''}
          />
        </Form.Group>
        <Form.Group>
          <SelectTenants
            values={values.tenantsIds}
            onChange={tenantsIds => setFieldValue('tenantsIds', tenantsIds)}
            onBlur={() => setFieldTouched('tenantsIds')}
            errorKey={Boolean(touched.tenantsIds && errors.tenantsIds) ? (errors.tenantsIds as string) : ''}
          />
        </Form.Group>
      </Form>
    </Modal>
  );
};

export default PermissionsFeaturesModal;
