import styled from 'styled-components';
import React, { CSSProperties } from 'react';
import { OverlayTrigger, Tooltip, OverlayTriggerProps } from 'react-bootstrap';

interface Props extends Omit<OverlayTriggerProps, 'overlay'> {
  overlayId: string;
  overlayChildren: React.ReactElement | string;
  overlayStyle?: CSSProperties;
  children: React.ReactElement;
}

export const OverlayTriggerTooltip: React.FC<Props> = ({
  overlayId,
  overlayChildren,
  overlayStyle,
  children,
  ...props
}) => (
  <OverlayTrigger
    rootClose
    {...props}
    overlay={
      <StyledTooltip id={overlayId} style={overlayStyle}>
        {overlayChildren}
      </StyledTooltip>
    }
  >
    {children}
  </OverlayTrigger>
);

const StyledTooltip = styled(Tooltip)`
  pointer-events: none;

  .tooltip-inner {
    border-radius: 4px;
    background: ${props => props.theme?.colors?.grey900 || '#1c2533'};
    color: ${props => props.theme?.colors?.grey100 || '#e1e6ec'};
    padding: 4px 8px;
    font-size: 0.8rem;
  }

  .arrow {
    z-index: 1;
  }

  &.bs-tooltip-left .arrow::before {
    border-left-color: ${props => props.theme?.colors?.grey900 || '#1c2533'};
  }

  &.bs-tooltip-right .arrow::before {
    border-right-color: ${props => props.theme?.colors?.grey900 || '#1c2533'};
  }

  &.bs-tooltip-top .arrow::before {
    border-top-color: ${props => props.theme?.colors?.grey900 || '#1c2533'};
  }

  &.bs-tooltip-bottom .arrow::before {
    border-bottom-color: ${props => props.theme?.colors?.grey900 || '#1c2533'};
  }
`;

export default OverlayTriggerTooltip;
