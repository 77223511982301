import { Handler } from 'components/Map/core/layers/handlers';

export const renderLayers = (legendData: Map.LegendData, settings: Map.MapSettings) => {
  const styles: Map.Style[][] = [];

  const walkThrough = (legendItem: Map.LegendItem) => {
    if (legendItem.layers) {
      legendItem.layers.forEach(k => walkThrough(k));
    } else {
      if (legendItem.fn_render) {
        if (Handler[legendItem.fn_render]) {
          styles.push(Handler[legendItem.fn_render](legendItem, settings));
        } else {
          console.warn(`${legendItem.fn_render} does not exist`);
        }
      }
    }
  };

  Object.keys(legendData).forEach(key => walkThrough(legendData[key]));

  return styles.flat();
};
