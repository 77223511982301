import { useState, useCallback } from 'react';

export default function useToggle(initialValue: boolean = false): any[] {
  const [value, setValue] = useState<boolean>(initialValue);

  const handleToggle = useCallback(
    (event: React.SyntheticEvent) => {
      event?.preventDefault?.();
      event?.stopPropagation?.();
      setValue(!value);
    },
    [value]
  );

  return [value, handleToggle];
}
