import styled from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { generatePath } from 'react-router';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { tasksByUUIDCodeSelector } from 'modules/tasks/selectors';
import { fetchTasksByUUIDCodeAction, deleteTaskAction } from 'modules/tasks';
import { TaskDeleteAlert } from 'components';
import { Alert, Button, TaskStatusBadge, Spinner } from 'components/_common';
import { Routes, PaginationType } from 'constants/index';
import Card from './Card';
import TabFilters from './TabFilters';
import { IconDelete, IconDetails } from '@utiligize/shared/resources';

interface Props {
  uuid: string;
}

const TabTasks: React.FC<Props> = ({ uuid }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const items = useSelector(tasksByUUIDCodeSelector);

  const paginationType = PaginationType.UNIFIED_ASSET_VIEW_TASKS;
  const { query, column, sort }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));

  const sendRequest = useCallback(() => {
    setLoading(true);
    dispatch(fetchTasksByUUIDCodeAction({ uuid, queryInput: query })).finally(() => setLoading(false));
  }, [dispatch, uuid, query]);

  useEffect(() => {
    sendRequest();
  }, [sendRequest, column, sort]);

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const name: string = event.currentTarget.getAttribute('data-name') || '';
      dispatch(
        setModalConfirmationMetaAction({
          bodyComponent: <TaskDeleteAlert name={name} />,
          onConfirm: () => dispatch(deleteTaskAction(id)).then(sendRequest),
        })
      );
    },
    [dispatch, sendRequest]
  );

  const renderContent = () => {
    if (loading) return <Spinner isInFullHeightContainer />;
    if (!items.length) {
      return (
        <Alert className="mb-0 text-center">
          {getIntl(query ? 'There is no data for current search query' : 'There is no data for selected asset')}
        </Alert>
      );
    }

    return (
      <>
        {items.map((item: Tasks.UAVTask, index: number) => (
          <Card key={item.id} marginBottom={items.length - 1 !== index}>
            <Row className="mb-1">
              <Col>
                <label>{getIntl('Name')}:</label>
                <span>{item.name || '-'}</span>
              </Col>
              <Col>
                <label>{getIntl('Status')}:</label>
                <span>
                  <TaskStatusBadge status={item.status} />
                </span>
              </Col>
            </Row>
            <Row className="mb-1">
              <Col>
                <label>{getIntl('Type')}:</label>
                <span>{getIntl(item.type)}</span>
              </Col>
              <Col>
                <label>{getIntl('App action')}:</label>
                <span>{getIntl(item.appAction)}</span>
              </Col>
            </Row>

            <StyledBottomControls>
              <Button
                tooltipKey="Details"
                icon={<IconDetails />}
                linkProps={{
                  to: {
                    pathname: generatePath(Routes.Tasks, { id: item.id }),
                    state: { prevRoute: window.location.pathname },
                  },
                }}
                size="small"
                variant="primary"
              />
              <Button
                marginLeft
                tooltipKey="Delete"
                icon={<IconDelete />}
                data-id={item.id}
                data-name={item.name}
                onClick={handleDeleteBtnClick}
                size="small"
                variant="primary"
              />
            </StyledBottomControls>
          </Card>
        ))}
      </>
    );
  };

  return (
    <TabFilters paginationType={paginationType} loading={loading}>
      {renderContent()}
    </TabFilters>
  );
};

const StyledBottomControls = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

export default TabTasks;
