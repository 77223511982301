import { getAssetLayerFilters, filterLayer } from 'utils/map';

const filterReplacements = (
  map: Map.MapboxMap,
  scenario: Map.Scenario,
  filters: Map.LayerFilter,
  settings: Map.MapSettings
) => {
  const layers = settings.replacementsLayers?.filter(i => !/highlight$/.test(i));
  const primaryLayers = layers?.filter(i => !i.includes('additional'));
  const secondaryLayers = layers?.filter(i => i.includes('additional'));

  const allActive = filters.initList.length === filters.list.length;
  const noneActive = filters.list.length === 0;
  const yearGetter = ['get', 'replacement_year'];
  const themeFilter = ['==', ['get', 'in_theme'], true];
  const reasonFilter = allActive
    ? true
    : noneActive
      ? false
      : ['any', ...filters.list.map(i => ['==', ['get', filters.property], +i])];
  const yearFilter = ['all', ['>=', yearGetter, scenario.yearRange[0]], ['<=', yearGetter, scenario.yearRange[1]]];

  primaryLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const primaryFilter = ['all', themeFilter, reasonFilter, yearFilter];
    filterLayer(map, i, ['all', voltageFilter, primaryFilter, assetFilter, textFilter]);
  });

  secondaryLayers?.forEach(i => {
    const { voltageFilter, assetFilter, textFilter } = getAssetLayerFilters(i);
    const secondaryFilter = ['!', ['all', themeFilter, reasonFilter, yearFilter]];
    filterLayer(map, i, ['all', voltageFilter, secondaryFilter, assetFilter, textFilter]);
  });
};

export default filterReplacements;
