import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCableAnimationSelector } from 'modules/map/selectors';
import { setCableAnimationAction } from 'modules/map';
import { Button } from 'components/_common';

const CableAnimationButton: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const showCableAnimation = useSelector(showCableAnimationSelector);

  const toggleCableAnimationState = useCallback(() => {
    dispatch(setCableAnimationAction({ showCableAnimation: !showCableAnimation }));
  }, [dispatch, showCableAnimation]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: showCableAnimation })}
      onClick={toggleCableAnimationState}
      icon={<i className="fa fa-wave-square fa-stack-1x" />}
      tooltipKey={showCableAnimation ? 'Disable cable animation' : 'Enable cable animation'}
      tooltipPlacement="left"
    />
  );
};

export default CableAnimationButton;
