import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useLocale } from 'hooks';
import { fetchTasksDepartmentsAction } from 'modules/tasks';
import { tasksDepartmentsFetchedSelector, tasksDepartmentsSelector } from 'modules/tasks/selectors';
import { RepeatedTasksSectionsTable, RepeatedTasksTable } from 'components/_tables';
import { Tabs, Tab, Spinner } from 'components/_common';
import { slugify } from 'utils';
import { Routes } from 'constants/index';

const RepeatedTasksTabs: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { hash } = useLocation();
  const tasksDepartmentsFetched = useSelector(tasksDepartmentsFetchedSelector);
  const tasksDepartmentsLoading = tasksDepartmentsFetched === null;
  const tasksDepartments = useSelector(tasksDepartmentsSelector);

  useEffect(() => {
    if (!tasksDepartmentsFetched) dispatch(fetchTasksDepartmentsAction());
  }, [tasksDepartmentsFetched, dispatch]);

  if (hash && !tasksDepartmentsFetched) return <Redirect to={Routes.TasksRepeatedTasks} />;
  return (
    <Tabs mountOnEnter unmountOnExit>
      <Tab
        id="tab_repeated_tasks_sections"
        eventKey={Routes.TasksRepeatedTasks}
        title={getIntl('Repeated tasks sections')}
      >
        <RepeatedTasksSectionsTable />
      </Tab>
      {tasksDepartmentsLoading && <Tab id="tab_repeated_tasks_departments_loader" title={<Spinner size="sm" />} />}
      {tasksDepartmentsFetched &&
        tasksDepartments.map((department: Tasks.Department) => (
          <Tab
            id={`tab_repeated_tasks_sections_${slugify(department.name)}`}
            key={department.number}
            eventKey={`${Routes.TasksRepeatedTasks}#${slugify(department.name)}`}
            title={department.name}
          >
            <RepeatedTasksTable departmentNumber={department.number} />
          </Tab>
        ))}
    </Tabs>
  );
};

export default RepeatedTasksTabs;
