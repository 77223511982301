import { handleActions, combineActions } from 'redux-actions';
import {
  fetchEmployeesAction,
  createEmployeeAction,
  updateEmployeeAction,
  deleteEmployeeAction,
  copyFromPreviousYearAction,
  fetchEmployeesGanttChartAction,
  fetchTasksGanttChartAction,
} from './actions';

const initialState: Employees.Root = {
  employeesCount: 0,
  employeesHash: {} as Type.Hash<Employees.Item>,
  employeesGanttChartFetched: false,
  employeesGanttChartHash: {} as Type.Hash<Employees.GanttChartItem>,
  employeesGanttChartHoursLeftOverWeek: [],
  employeesGanttChartHoursLeftOverMonth: [],
  tasksGanttChartFetched: false,
  tasksGanttChartHash: {} as Type.Hash<Tasks.Task>,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchEmployeesAction, fetchEmployeesGanttChartAction, fetchTasksGanttChartAction) as any]: {
    next: (
      state: Employees.Root,
      action: Shared.ReduxAction<Partial<Employees.Root> & { skipStoreUpdate: true | undefined }>
    ): Employees.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  fetchEmployeesAction,
  createEmployeeAction,
  updateEmployeeAction,
  deleteEmployeeAction,
  copyFromPreviousYearAction,
  fetchEmployeesGanttChartAction,
  fetchTasksGanttChartAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
