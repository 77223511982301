import React from 'react';
import { DataTableTemplate, ContentContainer, ContentCard } from 'components/_common';
import ChartCO2Emission, { DataItem } from './ChartCO2Emission';
import Filters from './Filters';

const ResponseUsedKeysLocalesMap: { [key in keyof DataItem]?: string } = {
  total_emissions_t_co2e: 'Emissions',
  climate_benefit_t_co2e: 'Climate benefit',
  total_overview_t_co2e: 'Total',
};

const ViewEmissionsForecast: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <Filters />
    <ContentContainer>
      <ContentCard>
        <ChartCO2Emission titleKey="Emissions forecast" ResponseUsedKeysLocalesMap={ResponseUsedKeysLocalesMap} />
      </ContentCard>
    </ContentContainer>
  </DataTableTemplate>
);

export default ViewEmissionsForecast;
