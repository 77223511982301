import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { AssetSubComponentsModal } from 'components/_modals';
import { SubComponentsTypesTable } from 'components/_tables';
import { DataTableTemplate, SelectAssetCategories } from 'components/_common';
import { PaginationType } from 'constants/index';

const AssetsSubComponents: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.SUB_COMPONENTS));

  const handleChangeCategorySelect = useCallback(
    (value: any) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.SUB_COMPONENTS,
          modifier: { filters: { ...filters, assetcategoryCode: value.value }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col sm={3}>
          <SelectAssetCategories
            labelKey=""
            value={filters?.assetcategoryCode || ''}
            onChange={handleChangeCategorySelect}
            excludeSyntheticOptions
          />
        </Col>
        <Col>
          <AssetSubComponentsModal />
        </Col>
      </Row>
      <SubComponentsTypesTable assetcategoryCode={filters?.assetcategoryCode} />
    </DataTableTemplate>
  );
};

export default AssetsSubComponents;
