import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { modalConfirmationMetaSelector } from 'modules/layouts/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { Modal } from 'components/_common';

const ConfirmationModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const inputMeta = useSelector(modalConfirmationMetaSelector);
  const [meta, setMeta] = useState<Layouts.ModalConfirmationMeta | null>(null);
  const {
    title = '',
    titleKey = 'Do you want to delete',
    bodyComponent = null,
    submitButtonProps,
    onConfirm,
  } = meta || ({} as any);

  useEffect(() => {
    if (inputMeta) return setMeta(inputMeta);
    setTimeout(() => setMeta(null), 500);
  }, [inputMeta]);

  const handleCancelBtnClick = useCallback(() => {
    dispatch(setModalConfirmationMetaAction(null));
  }, [dispatch]);

  const handleSubmitBtnClick = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if (!onConfirm) return;
      setIsLoading(true);
      onConfirm()
        .then(handleCancelBtnClick)
        .finally(() => setIsLoading(false));
    },
    [onConfirm, handleCancelBtnClick]
  );

  return (
    <Modal
      show={Boolean(inputMeta)}
      onHide={handleCancelBtnClick}
      title={title}
      titleKey={titleKey}
      cancelButtonProps={{
        disabled: isLoading,
        onClick: handleCancelBtnClick,
      }}
      submitButtonProps={{
        loading: isLoading,
        labelKey: submitButtonProps?.labelKey || 'Delete',
        onClick: handleSubmitBtnClick,
        variant: submitButtonProps?.variant || 'danger',
      }}
    >
      {bodyComponent}
    </Modal>
  );
};

export default ConfirmationModal;
