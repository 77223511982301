import { useFormik } from 'formik';
import * as Yup from 'yup';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { FIELDS } from 'constants/index';

interface Values {
  label: string;
}

const useConfiguredFormik = ({
  type,
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      label: String(formField?.params?.[0]?.values?.[0]?.value || ''),
    },

    validationSchema: Yup.object().shape({
      label: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('TextModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[type!]);
      _set(field, 'params[0].values[0].value', values.label);
      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
