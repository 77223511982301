import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';

const baseUrl = 'api/admin/v1/secure/complaints';

export const fetchTasksComplaintsAction: any = createAction(
  'tasks/FETCH_TASKS_COMPLAINTS',
  async (
    { skipPagination, skipStoreUpdate }: { skipPagination: boolean; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Tasks.Root, 'tasksComplaintsCount' | 'tasksComplaintsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.TASKS_COMPLAINTS)(state);
      return axios
        .get(baseUrl, {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? '' : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          tasksComplaintsCount: res.data.count,
          tasksComplaintsHash: _keyBy(res.data.rows, (item: Tasks.TaskComplaint) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);

export const deleteTasksComplaintAction: any = createAction(
  'forms/DELETE_TASKS_COMPLAINT',
  (id: number) => (dispatch: Shared.CustomDispatch) => {
    return axios.delete(`${baseUrl}/${id}`).then(async res => {
      await dispatch(fetchTasksComplaintsAction());
      dispatch(setSuccessToastAction('Task complaint has been deleted'));
    });
  }
);
