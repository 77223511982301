import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { DataTableTemplate, SelectYear } from 'components/_common';
import SelectAssetCategories from './SelectAssetCategories';
import TablePlanCNAIM from './TablePlanCNAIM';
import { getSelectYearRange } from 'utils';
import { PaginationType } from 'constants/index';

const PlanCNAIM: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.MAINTENANCE_PLAN_CNAIM;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, meta: any) => {
      const modifier = { filters: { ...filters, [meta.name]: value.value }, offset: 0 };
      dispatch(setPaginationAction({ type, modifier }));
    },
    [dispatch, filters, type]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={3}>
          <SelectAssetCategories />
        </Col>
        <Col xs={2}>
          <SelectYear
            name="year"
            value={filters?.year || null}
            onChange={handleSelectChange}
            labelKey=""
            range={getSelectYearRange(2023, 2072)}
            isSearchable={false}
          />
        </Col>
      </Row>
      <TablePlanCNAIM />
    </DataTableTemplate>
  );
};

export default PlanCNAIM;
