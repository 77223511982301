import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { investmentBudgetSelector, investmentBudgetCountSelector } from 'modules/setup/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { fetchInvestmentBudgetAction } from 'modules/setup';
import { DataTable } from 'components/_common';
import { GriZoneNamesLocalesMap, PaginationType } from 'constants/index';

const TableInvestmentBudget: React.FC = () => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const portfolioId = useSelector(portfolioIdSelector);
  const items = useSelector(investmentBudgetSelector);
  const count = useSelector(investmentBudgetCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchInvestmentBudgetAction(params)).then(
        (action: Shared.ReduxAction<{ investmentBudgetHash: Setup.Root['investmentBudgetHash'] }>) =>
          Object.values(action.payload?.investmentBudgetHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.SETUP_INVESTMENT_BUDGET}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 296px)"
      waitForDependencies={!portfolioId}
      triggerTableUpdateDeps={[portfolioId]}
    >
      {items?.map?.((item: Setup.InvestmentBudget) => (
        <tr
          key={
            item.asset_register_category + item.grid_zone + item.count_asset + item.size_asset + item.total_assets_value
          }
        >
          <td>{item.asset_register_category}</td>
          <td className="text-right">{numberFormat(item.size_asset, { fallback: '-' })}</td>
          <td>{item.size_unit}</td>
          <td>{item.lifetime ?? '-'}</td>
          <td>{getIntl(GriZoneNamesLocalesMap[item.grid_zone] || item.grid_zone) || '-'}</td>
          <td className="text-right">{numberFormat(item.price, { fallback: '-' })}</td>
          <td className="text-right">{numberFormat(item.count_asset, { fallback: '-' })}</td>
          <td className="text-right">{numberFormat(item.total_length_km, { fallback: '-' })}</td>
          <td className="text-right">{numberFormat(item.total_assets_value, { fallback: '-' })}</td>
          <td className="text-right">{numberFormat(item.yearly_budget, { fallback: '-' })}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableInvestmentBudget;
