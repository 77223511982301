import React from 'react';
import { useLocale } from 'hooks';
import { useSelector } from 'react-redux';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { Tabs, Tab, DataTableTemplate } from 'components/_common';
import TableInvestmentLimitations from './TableInvestmentLimitations';
import ModalInvestmentScenarios from './ModalInvestmentScenarios';
import FiltersInvestmentLimitations from './FiltersInvestmentLimitations';
import FiltersComponentPriceScenarios from './FiltersComponentPriceScenarios';
import FiltersComponentPrices from './FiltersComponentPrices';
import FiltersWorkPrices from './FiltersWorkPrices';
import FiltersInvestmentBudget from './FiltersInvestmentBudget';
import TableInvestmentScenarios from './TableInvestmentScenarios';
import TableComponentPriceScenarios from './TableComponentPriceScenarios';
import TableComponentPrices from './TableComponentPrices';
import TableWorkPrices from './TableWorkPrices';
import TableInvestmentBudget from './TableInvestmentBudget';
import { Routes, StorageKeys } from 'constants/index';

const ViewInvestment: React.FC = () => {
  const { getIntl } = useLocale();
  const isTabSetupInvestment = useSelector(isRouteActiveSelectorFactory(Routes.SetupInvestment));
  const isTabSetupInvestmentLimitations = useSelector(isRouteActiveSelectorFactory(Routes.SetupInvestmentLimitations));
  const isTabSetupInvestmentComponentPriceScenarios = useSelector(
    isRouteActiveSelectorFactory(Routes.SetupInvestmentComponentPriceScenarios)
  );
  const isTabSetupInvestmentWorkPrices = useSelector(isRouteActiveSelectorFactory(Routes.SetupInvestmentWorkPrices));
  const isTabSetupInvestmentComponentPrices = useSelector(
    isRouteActiveSelectorFactory(Routes.SetupInvestmentComponentPrices)
  );
  const isTabSetupInvestmentBudget = useSelector(isRouteActiveSelectorFactory(Routes.SetupInvestmentBudget));

  const featureToggles = useSelector(featureTogglesSelector);
  const isViewWorkPricesEnabled = featureToggles[StorageKeys.FEATURE_TOGGLE_WORK_PRICES];

  return (
    <DataTableTemplate>
      {isTabSetupInvestment && <ModalInvestmentScenarios />}
      {isTabSetupInvestmentLimitations && <FiltersInvestmentLimitations />}
      {isTabSetupInvestmentComponentPriceScenarios && <FiltersComponentPriceScenarios />}
      {isTabSetupInvestmentComponentPrices && <FiltersComponentPrices />}
      {isTabSetupInvestmentWorkPrices && <FiltersWorkPrices />}
      {isTabSetupInvestmentBudget && <FiltersInvestmentBudget />}
      <Tabs mountOnEnter unmountOnExit>
        <Tab id="tab__investment_scenarios" eventKey={Routes.SetupInvestment} title={getIntl('Investment scenarios')}>
          <TableInvestmentScenarios />
        </Tab>
        <Tab
          id="tab__investment_limitations"
          eventKey={Routes.SetupInvestmentLimitations}
          title={getIntl('Investment Limitations')}
        >
          <TableInvestmentLimitations />
        </Tab>
        <Tab
          id="tab__component_price_scenarios"
          eventKey={Routes.SetupInvestmentComponentPriceScenarios}
          title={getIntl('Component price scenarios')}
        >
          <TableComponentPriceScenarios />
        </Tab>
        <Tab
          id="tab__component_prices"
          eventKey={Routes.SetupInvestmentComponentPrices}
          title={getIntl('Component prices')}
        >
          <TableComponentPrices />
        </Tab>
        {isViewWorkPricesEnabled && (
          <Tab
            // FixMe. All Tab id attributes must be renamed to data-test-id
            data-test-id="tab__work_prices"
            eventKey={Routes.SetupInvestmentWorkPrices}
            title={getIntl('Work prices')}
          >
            <TableWorkPrices />
          </Tab>
        )}
        <Tab id="tab__investment_budget" eventKey={Routes.SetupInvestmentBudget} title={getIntl('Budget')}>
          <TableInvestmentBudget />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewInvestment;
