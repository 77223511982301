import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { flexibilityPlanCountSelector, flexibilityPlanSelector } from 'modules/flexibility/selectors';
import { fetchFlexibilityPlanAction } from 'modules/flexibility';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';

const FlexibilityPlanTable: React.FC = () => {
  const dispatch = useDispatch();
  const { numberFormat } = useLocale();

  const items = useSelector(flexibilityPlanSelector);
  const count = useSelector(flexibilityPlanCountSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const sendRequest = useCallback(() => dispatch(fetchFlexibilityPlanAction()), [dispatch]);

  return (
    <DataTable
      paginationType={PaginationType.FLEXIBILITY_PLAN}
      totalAmount={count}
      sendRequest={sendRequest}
      waitForDependencies={!portfolioId || !scenarioId}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
    >
      {items?.map?.((item: Flexibility.PlanItem) => (
        <tr key={item.asset}>
          <td>{item.primary_substation}</td>
          <td>{item.asset}</td>
          <td>{numberFormat(item.energy_mwh, { fallback: '-' })}</td>
          <td>{numberFormat(item.number_of_hours, { fallback: '-' })}</td>
          <td>{numberFormat(item.value_dkk, { fallback: '-' })}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default FlexibilityPlanTable;
