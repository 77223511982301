import Highcharts from 'highcharts';
import styled from 'styled-components';
import mixpanel from 'mixpanel-browser';
import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useWindowSize } from 'hooks';
import { fetchCurrentUserInfoAction } from 'modules/app';
import {
  appCurrentUserFetchedSelector,
  appCurrentUserPermissionsSelector,
  appIsAdminUserSelector,
  appErrorSelector,
} from 'modules/app/selectors';
import { isSidebarOpenSelector, isSidebarHelpMenuOpenSelector } from 'modules/layouts/selectors';
import {
  PageBuilder,
  PageForms,
  PageHistory,
  PageManuals,
  PageInstructions,
  PageTasks,
  PageAssetModels,
  PageInspections,
  PageMeters,
  PageMap,
  PageNetworkLoading,
  PageBudgeting,
  PageInvestment,
  PageFlexibility,
  PageMaintenance,
  PageAdmin,
  PageCustomers,
  PageNotFound,
  PageTenantNotFound,
  PageError,
  PageNewLoad,
  PageDataQuality,
  PageSetup,
  PageN1,
  // Reinvestment
  PageReinvestmentAssets,
  PageReinvestmentReliability,
  PageReinvestmentInterventions,
  PageFeatureToggles,
  PageCO2e,
} from 'pages';
import { Navigation, Sidebar, SidebarHelpMenu } from 'components';
import { ConfirmationModal, TableSettingsModal } from 'components/_modals';
import { Spinner } from 'components/_common';
import { getStorageItem, setStorageItem } from 'utils';
import { Routes, StorageKeys, AppErrorsTypes, isProduction } from 'constants/index';

if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string, {
    debug: !isProduction,
    api_host: 'https://cheese.utiligize.com',
    ignore_dnt: true,
  });
}

const PageMain: React.FC<RouteComponentProps> = ({ location }) => {
  const claims: Users.Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);
  const history = useHistory();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isMobile = useWindowSize();

  const isSidebarOpen = useSelector(isSidebarOpenSelector);
  const appCurrentUserFetched = useSelector(appCurrentUserFetchedSelector);
  const appIsAdminUser = useSelector(appIsAdminUserSelector);
  const appCurrentUserPermissions = useSelector(appCurrentUserPermissionsSelector);
  const isSidebarHelpMenuOpen = useSelector(isSidebarHelpMenuOpenSelector);
  const appError = useSelector(appErrorSelector);
  const isGlobalAppDataLoading = Boolean(appCurrentUserFetched === null);

  const trackPageView = useCallback(
    () => mixpanel.track('Dashboard page view event', { distinct_id: claims?.email }),
    [claims?.email]
  );

  useEffect(() => {
    if (!process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) return;
    trackPageView(); // To track the first page view upon load
    history.listen(trackPageView); // To track the subsequent page views
  }, [history, trackPageView]);

  useEffect(() => {
    // Automatic high charts reflow trigger
    setTimeout(() => Highcharts.charts.forEach(c => c?.reflow()), 500);
  }, [isSidebarOpen]);

  useEffect(() => {
    // Update last route on routing
    setStorageItem({ [StorageKeys.LAST_ROUTE]: `${location.pathname}${location.hash}` });
  }, [location.pathname, location.hash]);

  useEffect(() => {
    dispatch(fetchCurrentUserInfoAction());
  }, [dispatch]);

  const renderContent = () => {
    if (isGlobalAppDataLoading) return <Spinner isInFullHeightContainer />;

    if (appError === AppErrorsTypes.FetchUserError) return <PageError />;
    if (appError && [AppErrorsTypes.TenantNotFound, AppErrorsTypes.NoAssignedTenants].includes(appError)) {
      return <PageTenantNotFound appError={appError} />;
    }

    return (
      <Switch>
        {appIsAdminUser && <Route path={Routes.Admin} component={PageAdmin} />}
        <Route path={Routes.FeatureToggles} component={PageFeatureToggles} />

        {/* GENERAL */}
        <Route path={Routes.Budgeting} component={PageBudgeting} />
        <Route exact path={Routes.Map} component={PageMap} />
        <Route path={Routes.CO2e} component={PageCO2e} />

        {/* Forecast & Investment */}
        <Route path={Routes.NetworkLoading} component={PageNetworkLoading} />
        <Route path={Routes.N1} component={PageN1} />
        <Route path={Routes.Customers} component={PageCustomers} />
        <Route path={Routes.Investment} component={PageInvestment} />
        <Route path={Routes.DataQuality} component={PageDataQuality} />
        <Route path={Routes.Setup} component={PageSetup} />
        <Route path={Routes.NewLoad} component={PageNewLoad} />
        <Route path={Routes.Flexibility} component={PageFlexibility} exact />

        {/* Maintenance & planning */}
        <Route path={Routes.Tasks} component={PageTasks} />
        <Route path={Routes.Meters} component={PageMeters} exact />
        <Route path={Routes.Maintenance} component={PageMaintenance} />
        <Route path={Routes.AssetModels} component={PageAssetModels} />
        <Route path={Routes.Forms} component={PageForms} exact />
        <Route
          exact
          path={Routes.FormBuilder}
          render={(props: RouteComponentProps) => <PageBuilder {...props} isFormBuilder />}
        />
        <Route exact path={Routes.Instructions} component={PageInstructions} />
        <Route
          exact
          path={Routes.InstructionBuilder}
          render={(props: RouteComponentProps) => <PageBuilder {...props} isInstructionBuilder />}
        />
        <Route exact path={Routes.Inspections} component={PageInspections} />
        <Route
          exact
          path={Routes.InspectionBuilder}
          render={(props: RouteComponentProps) => <PageBuilder {...props} isToolInspectionBuilder />}
        />
        <Route path={Routes.History} component={PageHistory} />
        <Route exact path={Routes.DataManuals} component={PageManuals} />
        <Route path={Routes.Assets} component={PageReinvestmentAssets} />
        <Route path={Routes.Reliability} component={PageReinvestmentReliability} />
        <Route path={Routes.Interventions} component={PageReinvestmentInterventions} />

        <Route path="*" component={PageNotFound} />
      </Switch>
    );
  };

  return (
    <div
      className={classNames('sidebar-mini', 'layout-fixed', {
        'sidebar-collapse': !isSidebarOpen,
        'sidebar-open': isSidebarOpen && isMobile,
        'control-sidebar-slide-open': isSidebarHelpMenuOpen,
      })}
    >
      <div className="wrapper overflow-hidden">
        <Navigation appCurrentUserPermissions={appCurrentUserPermissions} />
        <Sidebar isMobile={isMobile} appCurrentUserFetched={!isGlobalAppDataLoading} />
        <StyledContentWrapper className="content-wrapper position-relative" $isSidebarOpen={isSidebarOpen}>
          {renderContent()}
          {!isProduction && isSidebarHelpMenuOpen && <SidebarHelpMenu />}
        </StyledContentWrapper>
        <ConfirmationModal />
        <TableSettingsModal />
      </div>
    </div>
  );
};

const StyledContentWrapper = styled.div<{ $isSidebarOpen: boolean }>`
  &&&& {
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.colors.grey25};
    min-height: calc(100vh - ${props => props.theme.heights.topNavigation});
    margin-top: ${props => props.theme.heights.topNavigation};

    ${({ $isSidebarOpen }) => !$isSidebarOpen && `margin-left: 60px!important`};
  }
`;

export default PageMain;
