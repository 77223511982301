import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scenarioOptionsSelector, scenarioSelectedOptionSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { simulationsSelector } from 'modules/map/selectors';
import { setSelectedSimulationAction } from 'modules/map';
import { FormReactSelect } from 'components/_common';

const SelectScenarioType: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const options = useSelector(scenarioOptionsSelector);
  const value = useSelector(scenarioSelectedOptionSelector);
  const simulations = useSelector(simulationsSelector);

  const handleSelectChange = useCallback(
    (option: { value: string }): void => {
      dispatch(setLayoutAction({ scenarioId: Number(option.value) }));
      if (simulations?.[0]) dispatch(setSelectedSimulationAction(simulations[0]));
    },
    [dispatch, simulations]
  );

  return (
    <FormReactSelect
      isLoading={!options}
      isDisabled={!options || !options?.length}
      labelKey="Scenario"
      value={value || null}
      options={options || []}
      onChange={handleSelectChange}
      isSearchable={false}
      blurInputOnSelect
      variant="small"
    />
  );
};

export default SelectScenarioType;
