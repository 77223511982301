import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDERsProfilesAction, uploadDERProfileAction } from 'modules/newLoad';
import { setSuccessToastAction } from 'modules/layouts';
import { portfolioIdSelector } from 'modules/layouts/selectors';

const useConfiguredFormik = ({ toggleModal }: { toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);

  const formik = useFormik<{
    description: string;
    shared: boolean;
    file: File | null;
  }>({
    initialValues: {
      description: '',
      shared: false,
      file: null,
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Field can't be empty!"),
      file: Yup.mixed().required('Please upload DERs profile excel first'),
    }),

    onSubmit: values => {
      const formData: FormData = new FormData();
      formData.append('f', values.file as Blob);

      return dispatch(uploadDERProfileAction(values.description, values.shared, formData))
        .then(async () => {
          await dispatch(fetchDERsProfilesAction(portfolioId));
          dispatch(setSuccessToastAction('DERs profile has been uploaded'));
          toggleModal();
        })
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
