import * as Yup from 'yup';
import { useFormik } from 'formik';
import { _set, _cloneDeep } from '@utiligize/shared/utils';
import { FIELDS, BuilderFieldsTypes } from 'constants/index';

interface Values {
  youTubeVideo: string;
}

const useConfiguredFormik = ({
  formField,
  addField,
  onChangeModal,
  isEditMode,
  toggleModal,
}: Builder.ModalFormikProps) => {
  const formik = useFormik<Values>({
    initialValues: {
      youTubeVideo: String(formField?.params?.[0]?.values?.[0]?.value || ''),
    },

    validationSchema: Yup.object().shape({
      youTubeVideo: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const handler = isEditMode ? onChangeModal : addField;
      if (!handler) throw Error('YouTubeVideoModal create handler must be passed');
      const field = _cloneDeep(formField || FIELDS[BuilderFieldsTypes.YouTubeVideo]);
      _set(field, 'params[0].values[0].value', values.youTubeVideo);
      handler(field);
      toggleModal();
    },
  });

  return formik;
};

export default useConfiguredFormik;
