import { DefaultProps, UpdateParameters, LayerContext, AccessorContext } from '@deck.gl/core/typed';
import { PathLayer, PathLayerProps } from '@deck.gl/layers/typed';
import { lineString, length } from '@turf/turf';

interface _AnimatedPathLayerProps {
  fadeTrail?: boolean;
  trailLength?: number;
  animationSpeed?: number;
  enableAnimation?: boolean;
}

interface _AnimatedPathLayerState {
  model?: any;
  pathTesselator: any;
  animationId: number;
  currentTime: number;
  timestamp: number;
}

export type AnimatedPathLayerProps<DataT = any> = _AnimatedPathLayerProps & PathLayerProps<DataT>;

const defaultProps: DefaultProps<AnimatedPathLayerProps> = {
  fadeTrail: { type: 'boolean', value: true },
  trailLength: { type: 'number', value: 100, min: 0 },
  animationSpeed: { type: 'number', value: 0 },
  enableAnimation: { type: 'boolean', value: false },
};

export default class AnimatedPathLayer<DataT = any, ExtraProps extends {} = {}> extends PathLayer<
  DataT,
  Required<_AnimatedPathLayerProps> & ExtraProps
> {
  static layerName = 'AnimatedPathLayer';
  static defaultProps = defaultProps;

  protected timestamps: number[][] = [];
  protected maxTimestamps: number[] = [];

  state: _AnimatedPathLayerState = {
    ...this.state,
  };

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'vs:#decl': `\
        uniform float currentTime;
        attribute float instanceTimestamps;
        attribute float instanceNextTimestamps;
        attribute float pathMaxTimestamp;
        varying float vTime;
        float shiftedTimestamps;
        float shiftedNextTimestamps;
        float timeShift;
      `,
      // Timestamp of the vertex
      'vs:#main-end': `\
        timeShift = floor(currentTime / pathMaxTimestamp) * pathMaxTimestamp;
        shiftedTimestamps = timeShift + instanceTimestamps;
        shiftedNextTimestamps = timeShift + instanceNextTimestamps;
        vTime = shiftedTimestamps + (shiftedNextTimestamps - shiftedTimestamps) * vPathPosition.y / vPathLength;
      `,
      'fs:#decl': `\
        uniform bool fadeTrail;
        uniform bool enableAnimation;
        uniform float trailLength;
        uniform float currentTime;
        varying float vTime;
      `,
      // Drop the segments outside of the time window
      'fs:#main-start': `\
        if(enableAnimation && (vTime > currentTime || (fadeTrail && (vTime < currentTime - trailLength)))) {
          discard;
        }
      `,
      // Fade the color (currentTime - 100%, end of trail - 0%)
      'fs:DECKGL_FILTER_COLOR': `\
        if(enableAnimation && fadeTrail) {
          color.a *= 1.0 - (currentTime - vTime) / trailLength;
        }
      `,
    };
    return shaders;
  }

  initializeState() {
    super.initializeState();
    const attributeManager = this.getAttributeManager();
    attributeManager?.addInstanced({
      pathMaxTimestamp: {
        size: 1,
        update: this.getMaxTimestamp,
      },
      timestamps: {
        size: 1,
        update: this.getTimestamps,
        shaderAttributes: {
          instanceTimestamps: {
            vertexOffset: 0,
          },
          instanceNextTimestamps: {
            vertexOffset: 1,
          },
        },
      },
    });
  }

  updateState(state: UpdateParameters<this>) {
    super.updateState(state);
    const { props, oldProps, changeFlags } = state;
    const { data, getPath } = this.props;
    const isAnimationStateChanged = props.enableAnimation !== oldProps.enableAnimation;

    if (isAnimationStateChanged && !props.enableAnimation) this.stopAnimation();
    if (isAnimationStateChanged && props.enableAnimation) this.startAnimation();

    if (changeFlags.dataChanged) {
      this.timestamps = (data as DataT[]).map(d => {
        const context = null as unknown as AccessorContext<DataT>;
        const path = getPath(d, context);
        const timestamps = path.map((_, i, arr) => {
          if (i === 0) return 0;
          const offsetCoords = arr.slice(0, i + 1) as number[][];
          const offsetLine = lineString(offsetCoords);
          const offsetLengthInMeters = length(offsetLine) * 1000;
          return offsetLengthInMeters;
        });
        return timestamps as number[];
      });
      this.maxTimestamps = this.timestamps.map(timestamps => {
        return timestamps[timestamps.length - 1];
      });
    }
  }

  finalizeState(context: LayerContext) {
    super.finalizeState(context);
    this.stopAnimation();
  }

  draw(params: any) {
    const { fadeTrail, trailLength, enableAnimation } = this.props;
    const {
      state: { currentTime },
    } = this;
    params.uniforms = {
      ...params.uniforms,
      fadeTrail,
      trailLength,
      currentTime,
      enableAnimation,
    };
    super.draw(params);
  }

  protected startAnimation() {
    this.stopAnimation();
    this.animate();
  }

  protected stopAnimation() {
    cancelAnimationFrame(this.state.animationId);
    this.setState({ currentTime: 0, timestamp: 0 });
  }

  protected animate(timestamp = 0) {
    const prevTimestamp = this.state.timestamp || timestamp;
    const deltaTime = timestamp - prevTimestamp;
    const timeFraction = deltaTime / 1000;
    const step = this.props.animationSpeed * timeFraction;
    const prevTime = this.state.currentTime ?? 0;
    const currentTime = (prevTime + step) % Number.MAX_VALUE;
    this.setState({ currentTime });
    this.state.animationId = requestAnimationFrame(this.animate.bind(this));
    if (timestamp) this.state.timestamp = timestamp;
  }

  protected getTimestamps(attribute: any) {
    const { timestamps } = this;
    const { value } = attribute;
    timestamps.flat().forEach((val, i) => {
      value[i] = val;
    });
  }

  protected getMaxTimestamp(attribute: any) {
    const { timestamps, maxTimestamps } = this;
    const { value } = attribute;
    let i = 0;
    timestamps.forEach((t, index) => {
      t.forEach(() => {
        value[i++] = maxTimestamps[index];
      });
    });
  }
}
