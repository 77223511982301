import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { editPermissionsTenantAction } from 'modules/permissions';

interface Props {
  id: number | null;
  tenantName: string;
  tenantPermissions: Permissions.Permissions;
  closeModal: () => void;
}

const useConfiguredFormik = ({ id, tenantName, tenantPermissions, closeModal }: Props) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<{ tenantPermissions: Permissions.Permissions }>({
    initialValues: { tenantPermissions },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      tenantPermissions: Yup.object().test(
        'test-tenant-permissions',
        'You must select at least one view permission!',
        (tenantPermissions: any) => {
          return Object.keys(tenantPermissions).some((key: string) =>
            Boolean(
              tenantPermissions[key as Permissions.PermissionsTypes] &&
                !key.includes('ReadOnly') &&
                !key.includes('isTaskType')
            )
          );
        }
      ),
    }),

    onSubmit: values => {
      return dispatch(editPermissionsTenantAction(id as number, tenantName as string, values.tenantPermissions))
        .then(closeModal)
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
