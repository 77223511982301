import React from 'react';
import { Row } from 'react-bootstrap';
import { DataTableTemplate, DataTableHeaderFilters } from 'components/_common';
import TableAccessLogs from './TableAccessLogs';
import { PaginationType, DataTableFilterKeys } from 'constants/index';

const ViewAccessLogs: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <Row>
      <DataTableHeaderFilters
        paginationType={PaginationType.ADMIN_ACCESS_LOGS}
        showKeys={[DataTableFilterKeys.START_TIME, DataTableFilterKeys.END_TIME]}
      />
    </Row>
    <TableAccessLogs />
  </DataTableTemplate>
);

export default ViewAccessLogs;
