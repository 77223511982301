import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createRepeatedTasksSectionAction, editRepeatedTasksSectionAction } from 'modules/tasks';

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Type.CreateRepeatedTasksSectionActionProps>({
    initialValues: {
      name: '',
      departmentNumber: null,
      assetCategories: [],
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      departmentNumber: Yup.number().nullable().required("Field can't be empty!"),
      assetCategories: Yup.array().min(1, "Field can't be empty!").required(),
    }),

    onSubmit: values => {
      if (id) return dispatch(editRepeatedTasksSectionAction(id, values)).then(toggleModal).catch(console.error);
      return dispatch(createRepeatedTasksSectionAction(values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
