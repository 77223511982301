import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createSubComponentAction, updateSubComponentAction } from 'modules/assets';
import { AssetCategories } from 'constants/index';

interface Values {
  name: string;
  assetCategoryCode: Type.AssetCategories;
}

const useConfiguredFormik = ({ id, toggleModal }: { id: number | null; toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Values>({
    initialValues: {
      name: '',
      assetCategoryCode: AssetCategories.BD,
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      const action = id ? updateSubComponentAction : createSubComponentAction;
      return dispatch(
        action({
          id: (id || undefined) as number,
          name: values.name,
          assetCategoryCode: values.assetCategoryCode,
        })
      ).then(toggleModal);
    },
  });

  return formik;
};

export default useConfiguredFormik;
