import React, { useCallback } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import {
  instructionsItemsSelector,
  instructionsBuilderDataSelector,
  instructionsCountSelector,
} from 'modules/instructions/selectors';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { fetchInstructionsAction, fetchInstructionSnapshotAction, deleteInstructionAction } from 'modules/instructions';
import { DataTable, Button } from 'components/_common';
import { _cloneDeep } from '@utiligize/shared/utils';
import { transformBlocks, getFieldTypesArray } from 'utils';
import { Routes, PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

const InstructionsTable: React.FC = () => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(instructionsCountSelector);
  const items = useSelector(instructionsItemsSelector);
  const builderData: Builder.Data = useSelector(instructionsBuilderDataSelector);

  const sendRequest = useCallback(() => dispatch(fetchInstructionsAction()), [dispatch]);

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      const builderDataCopy = _cloneDeep(builderData);

      const instructionItem = items.find((elem: Instructions.Item) => elem.id === id);
      if (!instructionItem) throw Error('instructionItem not found');

      const { formtypeId } = instructionItem;
      const fieldTypes = getFieldTypesArray(builderDataCopy, formtypeId);

      const formtype = builderDataCopy.formTypes.find((elem: Type.FormType) => elem.id === formtypeId);
      if (!formtype) throw Error('formtype not found');

      const selectValue: Builder.SelectValue = {
        id: instructionItem.id,
        name: instructionItem.name,
        assetCategories: instructionItem.assetcategory,
        formSubcomponents: instructionItem.formsubcomponent,
        formtype,
        assetCodes: instructionItem.assetCodes,
        modelIds: instructionItem.assignedModels.map(i => i.modelId),
      };

      dispatch(fetchInstructionSnapshotAction(instructionItem.id)).then((action: any) => {
        dispatch(
          push(Routes.InstructionBuilder, {
            isNew: false,
            isDuplicate: false,
            selectValue,
            blocks: transformBlocks(action.payload.lastSnapshot.blocks),
            snapshots: action.payload.snapshots,
            fieldTypes,
            formTypes: builderDataCopy.formTypes,
          } as Builder.LocationState)
        );
      });
    },
    [items, builderData, dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      dispatch(
        setModalConfirmationMetaAction({
          onConfirm: async () => dispatch(deleteInstructionAction(id)),
        })
      );
    },
    [dispatch]
  );

  return (
    <DataTable paginationType={PaginationType.INSTRUCTIONS} totalAmount={count} sendRequest={sendRequest}>
      {items?.map((item: Instructions.Item) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{dateFormat(item.createdAt)}</td>
          <td>{getIntl(item.formtype.name)}</td>
          <td>
            {[
              item.assetcategory?.name && getIntl(item.assetcategory?.name),
              ...item.assetCodes,
              ...item.assignedModels.map(i => i.modelName),
            ]
              .filter(Boolean)
              .join(', ') || '-'}
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default InstructionsTable;
