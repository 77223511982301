import styled from 'styled-components';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { MapContext } from 'context/map';
import { mapLayersSelector } from 'modules/map/selectors';
import { singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import SingleLineDiagramControls from './SingleLineDiagramControls';
import AssetSearch from 'components/Map/common/AssetSearch';
import GroupDataThemes from './GroupDataThemes';
import GroupAssetsFilters from './GroupAssetsFilters';
import GroupAuxiliaryLayers from './GroupAuxiliaryLayers';
import GroupDataVersion from './GroupDataVersion';

const Legend: React.FC = () => {
  const { map } = useContext(MapContext);
  const mapLayers = useSelector(mapLayersSelector);
  const singleDiagramEnabled = useSelector(singleDiagramEnabledSelector);

  const renderLegendBody = () => {
    if (!map || !mapLayers) return null;
    return (
      <StyledScrollContainer>
        <OverlayScrollbarsComponent style={{ height: '100%' }} options={{ scrollbars: { autoHide: 'scroll' } }}>
          <StyledContentContainer>
            <GroupDataThemes />
            <GroupAssetsFilters />
            <GroupAuxiliaryLayers />
            <GroupDataVersion />
          </StyledContentContainer>
        </OverlayScrollbarsComponent>
      </StyledScrollContainer>
    );
  };

  return (
    <StyledContainer>
      {mapLayers && <AssetSearch singleDiagramEnabled={singleDiagramEnabled} />}
      {singleDiagramEnabled && <SingleLineDiagramControls />}
      {renderLegendBody()}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: var(--map-panel-color);
`;

const StyledScrollContainer = styled.div`
  flex-grow: 1;
  height: 1px;
`;

const StyledContentContainer = styled.div`
  padding: 0 18px 15px 18px;
  font-size: 12px;
`;

export default Legend;
