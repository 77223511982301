import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { push } from 'connected-react-router';
import { useLocale } from 'hooks';
import { upcomingTasksCountSelector, upcomingTasksSelector } from 'modules/tasks/selectors';
import { fetchTasksAction, deleteTaskAction } from 'modules/tasks';
import { setTaskModalMetaAction } from 'modules/layouts';
import { DataTable, TaskStatusBadge, TaskTypeBadge, Button, IconDeleteTableAction } from 'components/_common';
import { Routes, TaskTypes, PaginationType } from 'constants/index';
import { IconEdit } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight: string;
}

const TasksUpcomingTable: React.FC<Props> = ({ maxTableHeight }) => {
  const { getIntl, dateFormat, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(upcomingTasksCountSelector);
  const items = useSelector(upcomingTasksSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTasksAction({ ...params, includeExternalTime: false, includeExpectedTimeFields: true })).then(
        (action: Shared.ReduxAction<{ upcomingTasksHash: Type.Hash<Tasks.Task> }>) =>
          Object.values(action.payload?.upcomingTasksHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      event.stopPropagation();
      const id: number | null = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setTaskModalMetaAction({ id }));
    },
    [dispatch]
  );

  const handleDetailsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      dispatch(push(generatePath(Routes.Tasks, { id })));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.UPCOMING_TASKS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      hover
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.Task) => (
        <tr key={item.id} data-id={item.id} onClick={handleDetailsBtnClick}>
          <td>
            <TaskTypeBadge id={item.id} type={item.type} className="mr-2" />
            {item.name}
          </td>
          {item.type === TaskTypes.Autogenerated ? (
            <td colSpan={2} className="text-center">
              {item.taskMonths?.map(month => getIntl(month)).join(', ')}
            </td>
          ) : (
            <>
              <td className="text-nowrap">{item.startDate ? dateFormat(item.startDate) : '-'}</td>
              <td className="text-nowrap">{item.endDate ? dateFormat(item.endDate) : '-'}</td>
            </>
          )}
          <td className="text-center">
            {numberFormat(item.expectedTime)} {getIntl(item.expectedTime <= 1 ? 'Hour' : 'Hours')}
          </td>
          <td className="text-center">
            {numberFormat(item.totalExpectedTime)} {getIntl(item.totalExpectedTime <= 1 ? 'Hour' : 'Hours')}
          </td>
          <td className="text-center">
            <TaskStatusBadge status={item.status} />
          </td>
          <td className="text-right">
            <Button
              marginLeft
              tooltipKey="Edit"
              icon={<IconEdit />}
              data-id={item.id}
              onClick={handleEditBtnClick}
              size="small"
              variant="primary"
            />
            <IconDeleteTableAction id={item.id} name={item.name || ''} marginLeft action={deleteTaskAction} />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TasksUpcomingTable;
