import { createAction } from 'redux-actions';
import {
  reliabilityVoltageOptionSelector,
  reliabilitySAIFIValueSelector,
  reliabilityCMLValueSelector,
  reliabilitySAIFIDelayValueSelector,
  reliabilityScenarioParamsOptionSelector,
} from 'modules/layouts/selectors';
import {
  setSuccessToastAction,
  setReliabilityVoltageOption,
  setReliabilitySAIFIValue,
  setReliabilityCMLValue,
  setReliabilitySAIFIDelayValue,
  setReliabilityScenarioParamsOption,
} from 'modules/layouts';
import { plumberAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchVoltageOptionsAction = createAction(
  'reliability/FETCH_VOLTAGE',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Reliability.Root, 'voltageOptions'>> => {
      return plumberAPI.get('voltage_dropdown').then(res => {
        const reliabilityVoltageOption = reliabilityVoltageOptionSelector(getState());
        const voltageOptions = res.data.map((item: { values: string; labels: string }) => ({
          value: item.values,
          label: item.labels,
        }));
        if (!reliabilityVoltageOption && voltageOptions?.length) {
          dispatch(setReliabilityVoltageOption(voltageOptions[0]));
        }
        return { voltageOptions };
      });
    }
);

export const fetchSAIFIRangeSliderConfigAction = createAction(
  'reliability/FETCH_SAIFI_RANGE_SLIDER_CONFIG',
  async (voltage: string) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Reliability.Root, 'saifiConfig'>> => {
      return plumberAPI.get('saifi_slider', { params: { voltage_selected: voltage } }).then(res => {
        const reliabilitySAIFIValue = reliabilitySAIFIValueSelector(getState());
        if (!reliabilitySAIFIValue) {
          dispatch(setReliabilitySAIFIValue(Number(res.data.default.toFixed(3))));
        }
        return { saifiConfig: res.data };
      });
    }
);

export const fetchCMLRangeSliderConfigAction = createAction(
  'reliability/FETCH_CML_RANGE_SLIDER_CONFIG',
  async (voltage: string) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Pick<Reliability.Root, 'cmlConfig'>> => {
      return plumberAPI.get('cml_slider', { params: { voltage_selected: voltage } }).then(res => {
        const reliabilityCMLValue = reliabilityCMLValueSelector(getState());
        if (!reliabilityCMLValue) {
          dispatch(setReliabilityCMLValue(res.data.default.toFixed(3)));
        }
        return { cmlConfig: res.data };
      });
    }
);

export const fetchSAIFIDelayRangeSliderConfigAction = createAction(
  'reliability/FETCH_SAIFI_DELAY_RANGE_SLIDER_CONFIG',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Reliability.Root, 'saifiDelayConfig'>> => {
      return plumberAPI.get('saifi_delay_slider').then(res => {
        const reliabilitySAIFIDelayValue = reliabilitySAIFIDelayValueSelector(getState());
        if (!reliabilitySAIFIDelayValue) {
          dispatch(setReliabilitySAIFIDelayValue(Number(res.data.default.toFixed(3))));
        }
        return { saifiDelayConfig: res.data };
      });
    }
);

export const fetchScenarioParamsOptionsAction = createAction(
  'reliability/FETCH_SCENARIO_PARAMS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Reliability.Root, 'scenarioParamsOptions'>> => {
      return plumberAPI.get('scenarios/scenarios_param').then(res => {
        const reliabilityScenarioParamsOption = reliabilityScenarioParamsOptionSelector(getState());
        const scenarioParamsOptions = res.data.map((item: { id: number; label: string }) => ({
          value: item.id,
          label: item.label,
        }));
        if (!reliabilityScenarioParamsOption && scenarioParamsOptions?.length) {
          dispatch(setReliabilityScenarioParamsOption(scenarioParamsOptions[0]));
        }
        return { scenarioParamsOptions };
      });
    }
);

export const fetchPoFParamsAction = createAction(
  'reliability/FETCH_POF_PARAMS',
  async ({
    distribution,
    cnaimAssetCategoryId,
    scenarioGlobalId,
    scenarioParamId,
  }: {
    distribution: string;
    cnaimAssetCategoryId: number;
    scenarioGlobalId: number;
    scenarioParamId: number;
  }): Promise<any> => {
    return plumberAPI
      .get('pof_params', {
        params: {
          distribution,
          cnaim_asset_category: cnaimAssetCategoryId,
          scenario_global: scenarioGlobalId,
          scenario_param: scenarioParamId,
        },
      })
      .then(res => res.data);
  }
);

export const updatePoFParamsAction = createAction(
  'reliability/UPDATE_POF_PARAMS',
  ({
    distribution,
    cnaimAssetCategoryId,
    scenarioGlobalId,
    scenarioParamId,
    params,
  }: {
    distribution: string;
    cnaimAssetCategoryId: number;
    scenarioGlobalId: number;
    scenarioParamId: number;
    params: { [key: string]: number };
  }) =>
    async (dispatch: Shared.CustomDispatch): Promise<void> => {
      return plumberAPI
        .put('pof_params', {
          distribution,
          cnaim_asset_category: cnaimAssetCategoryId,
          scenario_global: scenarioGlobalId,
          scenario_param: scenarioParamId,
          params,
        })
        .then(() => {
          dispatch(setSuccessToastAction('PoF params has been saved'));
        });
    }
);
