import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highcharts-gantt';
import { tasksGanttChartFetchedSelector, tasksGanttChartSelector } from 'modules/employees/selectors';
import { fetchTasksGanttChartAction } from 'modules/employees';
import useTableData from './useTableData';
import { ContentCard, ContentContainer, Spinner } from 'components/_common';

const NewTasksGanttChart: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const tasksGanttChartFetched = useSelector(tasksGanttChartFetchedSelector);
  const [loading, setLoading] = useState(false);
  const tasksGanttChart = useSelector(tasksGanttChartSelector);
  const { filters } = useTableData();

  const [chartOptions, setChartOptions] = useState({
    navigator: {
      enabled: true,
      liveRedraw: true,
      xAxis: { min: Date.UTC(2022, 0, 1), max: Date.UTC(2022, 11, 31) },
      yAxis: { min: 0, max: 3 },
    },
    rangeSelector: { enabled: true, selected: 0 },
    xAxis: [
      {
        currentDateIndicator: true,
        // Set the second axis
        grid: { cellHeight: 25 },
      },
      {
        // Set the first axis
        grid: { cellHeight: 30 },
      },
    ],
    yAxis: {
      scrollbar: {
        enabled: true,
        liveRedraw: true,
        showFull: false,
      },
      max: 9,
      // table row height
      staticScale: 40,
    },
    series: [{ data: [] }],
  });

  useEffect(() => {
    setLoading(true);
    dispatch(fetchTasksGanttChartAction()).then(() => setLoading(false));
  }, [dispatch, filters]);

  useEffect(() => {
    if (!tasksGanttChart?.length) return;
    const data = tasksGanttChart.reduce((acc: any, task) => {
      const startDate = moment(task.startDate, 'YYYY-MM-DD').valueOf();
      const endDate = moment(task.endDate, 'YYYY-MM-DD').valueOf();
      if (isNaN(startDate) || isNaN(endDate)) return acc;

      acc.push({
        id: task.id,
        name: task.name,
        start: startDate,
        end: endDate,
        completed: Math.round(task.registeredTime / task.expectedTime),
      });

      return acc;
    }, []);

    setChartOptions(state => ({ ...state, series: [{ data }] }));
  }, [tasksGanttChart]);

  if (!tasksGanttChartFetched || loading) {
    return (
      <ContentContainer>
        <Spinner inCard isInFullHeightContainer />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <ContentCard>
        <HighchartsReact
          options={chartOptions}
          highcharts={Highcharts}
          constructorType="ganttChart"
          containerProps={{ 'data-marker': 'chart_tasks_employees_gantt' }}
        />
      </ContentCard>
    </ContentContainer>
  );
};

export default NewTasksGanttChart;
