import styled from 'styled-components';
import queryString from 'query-string';
import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getTimeSeriesChartData } from 'modules/networkLoading';
import {
  portfolioIdSelector,
  scenarioIdSelector,
  selectedChartFlexSelector,
  selectedChartYearSelector,
} from 'modules/layouts/selectors';
import { SelectDERsPreviewYear, ButtonDownload, Checkbox } from 'components/_common';
import Chart, { Colors, getBaseOptions } from './Chart';
import { setLayoutAction } from 'modules/layouts';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  rating?: number;
  series: Shared.SeriesOptionsType[];
}

const medianLineID = 'median-line';

const transformChartData = (action: Shared.ReduxAction<any>): ChartDataProps | null => {
  if (!Object.keys(action.payload || {}).length) return null;

  const filterKeys = (keys: string[]) => keys.filter(key => !['time', 'tenant_id'].includes(key));

  const seriesHash = action.payload.ts_data?.reduce((acc: any, item: any) => {
    const keys = filterKeys(Object.keys(action.payload.ts_data[0] || {}));
    const isStacked = action.payload.chart_type === 'column';
    keys.forEach((key, index) => {
      const elem = [item.time * 1000, Number(item[key])];
      // Add Median line for stacked chart
      if (isStacked) {
        const medianLineElem = [item.time * 1000, Number(keys.reduce((acc, key) => acc + item[key], 0))];
        if (!acc[medianLineID]) {
          acc[medianLineID] = {
            id: medianLineID,
            name: 'Sum',
            type: 'line',
            data: [medianLineElem],
            color: '#000',
            index: 0,
            lineWidth: 0.5,
            visible: false,
          };
        } else {
          acc[medianLineID].data.push(medianLineElem);
        }
      }
      if (!acc[key]) {
        acc[key] = {
          name: key,
          type: action.payload.chart_type || 'line',
          data: [elem],
          color: Colors[index],
          index: action.payload.z_index?.[key] || index + 1,
          lineWidth: 0.5,
        };
      } else {
        acc[key].data.push(elem);
      }
    });
    return acc;
  }, {});

  const seriesLineHash = action.payload.ts_data_line?.reduce((acc: any, item: any) => {
    const keys = filterKeys(Object.keys(action.payload.ts_data_line[0] || {}));
    keys.forEach(key => {
      const elem = [item.time * 1000, item[key]];
      if (!acc[key]) {
        acc[key] = {
          name: key,
          type: 'line',
          data: [elem],
          color: Colors[4],
          index: 0,
          lineWidth: 0.5,
        };
      } else {
        acc[key].data.push(elem);
      }
    });
    return acc;
  }, {});

  return {
    title: action.payload.title,
    xAxisTitle: action.payload.xlabel,
    yAxisTitle: action.payload.ylabel,
    rating: action.payload.horizontal_line,
    series: Object.values<Shared.SeriesOptionsType>(seriesHash || {}).concat(
      Object.values<Shared.SeriesOptionsType>(seriesLineHash || {})
    ),
  };
};

interface Props {
  uuid: string;
  type: 'power' | 'voltage' | 'losses';
  height?: '100%';
}

const AssetTimeSeriesChart: React.FC<Props> = ({ uuid, type, height }) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const year = useSelector(selectedChartYearSelector);
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const baseOptions = getBaseOptions(getIntl, chartData);

  const handleSelectYear = useCallback(
    (selectedChartYear: number) => dispatch(setLayoutAction({ selectedChartYear })),
    [dispatch]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId) return;
    dispatch(getTimeSeriesChartData({ portfolioId, scenarioId, uuid, type, year, flex }))
      .then(transformChartData)
      .then(setChartData)
      .catch(() => setChartData({ series: [] } as any));
  }, [dispatch, portfolioId, scenarioId, uuid, type, year, flex]);

  const options = useMemo(
    () => ({
      ...baseOptions,
      plotOptions: {
        series: {
          boostThreshold: 2000,
        },
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M',
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        outside: true,
      },
      yAxis: {
        ...baseOptions.yAxis,
        plotLines: [
          {
            color: '#c77cff',
            width: 2,
            zIndex: 5,
            value: chartData?.rating,
            label: {
              text: getIntl('Rating'),
              style: {
                color: '#c77cff',
                fontWeight: 'bold',
              },
            },
          },
        ],
        reversedStacks: false,
      },
      xAxis: {
        ...baseOptions.xAxis,
        type: 'datetime',
      },
      series:
        chartData?.series.map(s => ({
          ...s,
          name: getIntl(s.name || ''),
          events: {
            legendItemClick: function (this: Highcharts.Series) {
              const medianLineSeries = this.chart.series.find(i => i.options.id === medianLineID);
              if (!medianLineSeries) return;

              const visibleSeries = this.chart.series.filter(
                i =>
                  medianLineID !== i.options.id &&
                  ((i.name === this.name && !this.visible) || (i.name !== this.name && i.visible))
              );
              const visibleSeriesData = visibleSeries.map(i => (i.options as any).data);
              const newMedianLineData = visibleSeriesData[0].map(([time]: [number], index: number) => [
                time,
                visibleSeriesData.reduce((acc, i) => acc + i[index][1], 0),
              ]);
              medianLineSeries.setData(newMedianLineData);
            },
          },
        })) || [],
    }),
    [baseOptions, chartData, getIntl]
  ) as unknown as Highcharts.Options;

  return (
    <>
      <StyledContainer data-marker="asset_timeseries__buttons_block">
        <SelectDERsPreviewYear
          labelKey=""
          value={year}
          onChange={handleSelectYear}
          variant="small"
          isSearchable={false}
        />
        <ButtonDownload
          dataMarker="download_timeseries_excel"
          tooltipKey="Download time series"
          fileNameLocaleStr="Time series"
          link={`load/asset_class_timeseries_excel?${queryString.stringify({
            portfolio_id: portfolioId,
            scenario_id: scenarioId,
            lang: lng.toLowerCase(),
            id: uuid,
            type,
            year,
          })}`}
          className="mx-2"
          isAssetLifeAPI
        />
        <Checkbox
          labelKey="Flex"
          className="icheck-primary"
          name="flex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
      </StyledContainer>

      <Chart dataMarker="asset_timeseries_chart" options={chartData ? options : null} height={height} />
    </>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 50%;
  transform: translate(50%);
  display: inline-flex;
  justify-content: space-between;
`;

export default AssetTimeSeriesChart;
