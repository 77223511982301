import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { externalTimeSystemSelector } from 'modules/app/selectors';
import {
  isPageTasksTimeOverviewUsersHoursTabActiveSelector,
  isPageTasksTimeOverviewTasksHoursTabActiveSelector,
} from 'modules/router/selectors';
import { setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { TotalHoursTable, UsersRegisteredHoursTable, TasksRegisteredHoursTable } from 'components/_tables';
import { Tabs, Tab, DataTableTemplate, DataTableHeaderFilters, BootstrapSwitchButton } from 'components/_common';
import {
  Routes,
  PaginationType,
  DataTableFilterKeys,
  TasksRegisteredHoursSourceTypes,
  TableHeaders,
} from 'constants/index';
import TabAssetServiceTime from './TabAssetServiceTime';

const ViewTimeOverview: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl, lng } = useLocale();
  const externalTimeSystem = useSelector(externalTimeSystemSelector);
  const isPageTasksTimeOverviewUsersHoursTabActive = useSelector(isPageTasksTimeOverviewUsersHoursTabActiveSelector);
  const isPageTasksTimeOverviewTasksHoursTabActive = useSelector(isPageTasksTimeOverviewTasksHoursTabActiveSelector);

  const isRegisteredHoursTabsActive =
    isPageTasksTimeOverviewUsersHoursTabActive || isPageTasksTimeOverviewTasksHoursTabActive;

  const type = isPageTasksTimeOverviewTasksHoursTabActive
    ? PaginationType.TASKS_REGISTERED_HOURS
    : PaginationType.USERS_REGISTERED_HOURS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const showKeys = isPageTasksTimeOverviewTasksHoursTabActive
    ? [DataTableFilterKeys.START_TIME, DataTableFilterKeys.END_TIME, DataTableFilterKeys.USER_IDS]
    : [
        DataTableFilterKeys.START_TIME,
        DataTableFilterKeys.END_TIME,
        DataTableFilterKeys.USER_IDS,
        DataTableFilterKeys.TASK_ID_OR_CASE_NUMBER,
      ];

  const handleSwitchButtonClick = useCallback(
    (source: boolean) => {
      const modifier = {
        filters: {
          ...filters,
          source: source ? TasksRegisteredHoursSourceTypes.EXTERNAL : TasksRegisteredHoursSourceTypes.INTERNAL,
          // reset task filter on source change
          ...(type === PaginationType.USERS_REGISTERED_HOURS && {
            [DataTableFilterKeys.TASK_ID_OR_CASE_NUMBER]: null,
          }),
        },
        // force column name change for external request
        ...(type === PaginationType.TASKS_REGISTERED_HOURS && {
          column: source ? 'caseNumber' : TableHeaders[PaginationType.TASKS_REGISTERED_HOURS][0].sortKey,
        }),
        offset: 0,
      };
      dispatch(setPaginationAction({ type, modifier }));
    },
    [dispatch, filters, type]
  );

  const handleDetailsButtonClick = useCallback(
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const id = Number(event.currentTarget.getAttribute('data-id'));

      const modifier = {
        filters: {
          ...filters,
          taskIdOrCaseNumber: isPageTasksTimeOverviewTasksHoursTabActive ? id : null,
          userIds: isPageTasksTimeOverviewTasksHoursTabActive ? null : [id],
        },
        offset: 0,
        query: '',
        ...(!isPageTasksTimeOverviewTasksHoursTabActive && {
          column:
            filters?.source === TasksRegisteredHoursSourceTypes.EXTERNAL
              ? 'caseNumber'
              : TableHeaders[PaginationType.TASKS_REGISTERED_HOURS][0].sortKey,
        }),
      };

      dispatch(
        setPaginationAction({
          type: isPageTasksTimeOverviewTasksHoursTabActive
            ? PaginationType.USERS_REGISTERED_HOURS
            : PaginationType.TASKS_REGISTERED_HOURS,
          modifier,
        })
      );

      dispatch(
        push(
          isPageTasksTimeOverviewTasksHoursTabActive
            ? `${Routes.TasksTimeOverview}${Routes.HASH.UsersHours}`
            : `${Routes.TasksTimeOverview}${Routes.HASH.TasksHours}`
        )
      );
    },
    [dispatch, filters, isPageTasksTimeOverviewTasksHoursTabActive]
  );

  return (
    <DataTableTemplate>
      {isRegisteredHoursTabsActive ? (
        <Row>
          <DataTableHeaderFilters key={type} paginationType={type} showKeys={showKeys} />
          {externalTimeSystem && (
            <Col>
              <BootstrapSwitchButton
                key={lng}
                checked={filters?.source === TasksRegisteredHoursSourceTypes.EXTERNAL}
                offstyle="secondary"
                onstyle="primary"
                offlabel={getIntl('External')}
                onlabel={getIntl('External')}
                width={150}
                onChange={handleSwitchButtonClick}
              />
            </Col>
          )}
        </Row>
      ) : (
        <Row>
          <DataTableHeaderFilters paginationType={PaginationType.TOTAL_HOURS} showKeys={[DataTableFilterKeys.YEAR]} />
        </Row>
      )}
      <Tabs mountOnEnter unmountOnExit>
        <Tab id="tab_time_overview_asset_service_time" eventKey={Routes.TasksTimeOverview} title={getIntl('Asset service time')}>
          <TabAssetServiceTime />
        </Tab>
        <Tab id="tab_time_overview_total_hours" eventKey={`${Routes.TasksTimeOverview}${Routes.HASH.TotalHours}`} title={getIntl('Total hours')}>
          <TotalHoursTable />
        </Tab>
        <Tab id="tab_time_overview_users_hours" eventKey={`${Routes.TasksTimeOverview}${Routes.HASH.UsersHours}`} title={getIntl('Users hours')}>
          <UsersRegisteredHoursTable handleDetailsButtonClick={handleDetailsButtonClick} />
        </Tab>
        <Tab id="tab_time_overview_tasks_hours" eventKey={`${Routes.TasksTimeOverview}${Routes.HASH.TasksHours}`} title={getIntl('Tasks hours')}>
          <TasksRegisteredHoursTable
            source={filters?.source as Tasks.RegisteredHoursSourceTypes}
            handleDetailsButtonClick={handleDetailsButtonClick}
          />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default ViewTimeOverview;
