import React, { useCallback, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { MapContext } from 'context/map';
import { Button } from 'components/_common';
import { IconCodepen } from '@utiligize/shared/resources';

const MapModeButton: React.FC = () => {
  const { map } = useContext(MapContext);
  const [pitch, setPitch] = useState(map?.getPitch() || 0);

  useEffect(() => {
    map?.on('moveend', () => setPitch(map?.getPitch()));
  }, [map]);

  const handleButtonClick = useCallback(() => {
    map?.easeTo({ pitch: !pitch ? 76 : 0 });
  }, [map, pitch]);

  return (
    <Button
      variant="light"
      className={classNames('mt-1', { active: Boolean(pitch) })}
      onClick={handleButtonClick}
      icon={<IconCodepen />}
      tooltipKey="Switch 2D/3D"
      tooltipPlacement="left"
    />
  );
};

export default MapModeButton;
