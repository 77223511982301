import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { maintenanceAverageTimeSpentByAssetCategorySelector } from 'modules/layouts/selectors';
import { FormReactSelect } from 'components/_common';
import { MaintenanceAverageTimeSpentByAssetCategories } from 'constants/index';
import { setLayoutAction } from 'modules/layouts';

const SelectManufacturerComparisonAssetCategories: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const maintenanceAverageTimeSpentByAssetCategory = useSelector(maintenanceAverageTimeSpentByAssetCategorySelector);

  const handleAssetsSelectChange = useCallback(
    (option: Type.SelectOption) => {
      dispatch(
        setLayoutAction({
          maintenanceAverageTimeSpentByAssetCategory: option?.value as Maintenance.AverageTimeSpentByAssetCategories,
        })
      );
    },
    [dispatch]
  );

  const { options, value } = useMemo(
    () =>
      Object.keys(MaintenanceAverageTimeSpentByAssetCategories).reduce(
        (acc: any, asset: any) => {
          const item = { value: asset, label: getIntl(asset) };
          if (item.value === maintenanceAverageTimeSpentByAssetCategory) acc.value = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], value: null }
      ),
    [maintenanceAverageTimeSpentByAssetCategory, getIntl]
  );

  return (
    <FormReactSelect
      labelKey=""
      mutedTextLabelKey="Asset categories"
      value={value}
      options={options}
      onChange={handleAssetsSelectChange}
      variant="small"
      isSearchable={false}
    />
  );
};

export default SelectManufacturerComparisonAssetCategories;
