import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { missingAgeItemsSelector, missingAgeCountSelector } from 'modules/assets/selectors';
import { fetchMissingAgeAction } from 'modules/assets';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const AssetsMissingAgeTable: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const items = useSelector(missingAgeItemsSelector);
  const count = useSelector(missingAgeCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchMissingAgeAction(params)).then(
        (action: Shared.ReduxAction<{ missingAgeHash: Type.Hash<Asset.MissingAge> }>) =>
          Object.values(action.payload?.missingAgeHash || {})
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.ASSETS_MISSING_AGE}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 292px)"
    >
      {items?.map((item: Asset.MissingAge) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{item.type}</td>
          <td>{item.manufacturer}</td>
          <td>{item.comments || '-'}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default AssetsMissingAgeTable;
