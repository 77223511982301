import React from 'react';
import { StatisticsCompleteActionsChart, StatisticsFinalChecksChart } from 'components/_charts';
import { ContentContainer } from 'components/_common';

const StatisticsCompletedForms: React.FC = () => (
  <ContentContainer>
    <StatisticsCompleteActionsChart height="calc((100vh - 228px) / 2);" />
    <StatisticsFinalChecksChart height="calc((100vh - 228px) / 2);" />
  </ContentContainer>
);

export default StatisticsCompletedForms;
