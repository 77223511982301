import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
export const rootSelector = (state: State.Root) => state.reliability;

export const voltageOptionsSelector = createSelector(
  (state: State.Root) => state.reliability,
  (reliability: Reliability.Root): Reliability.Root['voltageOptions'] => reliability.voltageOptions
);

export const saifiConfigSelector = createSelector(
  (state: State.Root) => state.reliability,
  (reliability: Reliability.Root): Reliability.Root['saifiConfig'] => reliability.saifiConfig
);

export const cmlConfigSelector = createSelector(
  (state: State.Root) => state.reliability,
  (reliability: Reliability.Root): Reliability.Root['cmlConfig'] => reliability.cmlConfig
);

export const saifiDelayConfigSelector = createSelector(
  (state: State.Root) => state.reliability,
  (reliability: Reliability.Root): Reliability.Root['saifiDelayConfig'] => reliability.saifiDelayConfig
);

export const scenarioParamsOptionsSelector = createSelector(
  (state: State.Root) => state.reliability,
  (reliability: Reliability.Root): Reliability.Root['scenarioParamsOptions'] => reliability.scenarioParamsOptions
);
