import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTotalLoadingAggregatedMeterChartsDataAction } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import ChartAggregatedMeter, { ChartDataProps } from './ChartAggregatedMeter';
import { ContentContainer, ContentCard } from 'components/_common';
import { Colors } from 'components/_charts/Chart';

interface Props {
  startDateStr: string | null;
  endDateStr: string | null;
}

const TabTotalLoadingContent: React.FC<Props> = ({ startDateStr, endDateStr }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const [chartData, setChartData] = useState<ChartDataProps[] | null>(null);

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId) return;
    dispatch(
      getTotalLoadingAggregatedMeterChartsDataAction({
        portfolioId,
        scenarioId,
        startDate: startDateStr,
        endDate: endDateStr,
      })
    )
      .then((action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data'>[] | null>) => {
        setChartData(
          action.payload?.map(chart => ({
            series: chart.series.map((i, index: number) => ({
              name: i.group || '',
              type: 'line' as any,
              data: i.data.map((d, index) => [chart.datetime_x[index] * 1000, d]),
              color: Colors[index],
            })),
            title: chart.title,
            xAxisTitle: chart.xaxis_title,
            yAxisTitle: chart.yaxis_title,
          })) || []
        );
      })
      .catch(() => setChartData([]));
  }, [dispatch, portfolioId, scenarioId, startDateStr, endDateStr]);

  return (
    <ContentContainer>
      <ContentCard marginBottom>
        <ChartAggregatedMeter
          dataMarker="chart_network_loading_total_consumption"
          {...(chartData?.[0] || (chartData ? { series: [] } : {}))}
        />
      </ContentCard>
      <ContentCard>
        <ChartAggregatedMeter
          dataMarker="chart_network_number_of_remote_readings"
          {...(chartData?.[1] || (chartData ? { series: [] } : {}))}
        />
      </ContentCard>
    </ContentContainer>
  );
};

export default TabTotalLoadingContent;
