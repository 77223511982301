import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import ReactDateRangePicker from 'react-bootstrap-daterangepicker';
import { useLocale } from 'hooks';
import { getDaysOfWeek, getMonthNames } from 'utils';
import { DateFormats } from 'constants/index';

interface Props {
  name?: string;
  disabled?: boolean;
  startDate?: Type.Moment | null; // only for date range
  endDate?: Type.Moment | null; // only for date range
  labelKey?: string;
  label?: string;
  errorKey?: string;
  initialSettings?: {
    singleDatePicker?: boolean;
    minDate?: Type.Moment;
    startDate?: Type.Moment; // only for single date select
    maxDate?: Type.Moment;
    ranges?: { [key: string]: Type.Moment[] };
    maxSpan?: { months: number };
  };
  onApply: (
    event: React.ChangeEvent<HTMLInputElement>,
    picker: { startDate: Type.Moment; endDate: Type.Moment }
  ) => void;
}

const DateRangePicker: React.FC<Props> = ({
  name = '',
  disabled = false,
  startDate,
  endDate,
  labelKey = '',
  label = '',
  errorKey = '',
  initialSettings = { singleDatePicker: false },
  onApply,
}) => {
  const { singleDatePicker } = initialSettings;
  const { getIntl } = useLocale();

  const getPickerValue = useCallback(
    (start: Type.Moment, end: Type.Moment) =>
      `${start?.format(DateFormats.CLIENT)} - ${end?.format(DateFormats.CLIENT)}`,
    []
  );

  const handleApplyClick = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      picker: { element: any; startDate: Type.Moment; endDate: Type.Moment }
    ) => {
      const isSame = picker.startDate.isSame(startDate) && picker.endDate.isSame(endDate);
      if (isSame) return;

      picker.element.val(getPickerValue(picker.startDate, picker.endDate));
      onApply(event, picker);
    },
    [getPickerValue, onApply, startDate, endDate]
  );

  return (
    <>
      {(label || labelKey) && <Form.Label>{label || getIntl(labelKey)}</Form.Label>}
      <ReactDateRangePicker
        //FixMe. reset filters feature, find proper wait to update picker
        key={Math.random()} // key={lng} force re-render on lng change to update locales
        initialSettings={{
          ...initialSettings,
          autoUpdateInput: false,
          autoApply: true,
          locale: {
            firstDay: 1,
            format: DateFormats.CLIENT,
            daysOfWeek: getDaysOfWeek(getIntl),
            monthNames: getMonthNames(getIntl),
            customRangeLabel: getIntl('Custom range'),
          },
        }}
        onApply={singleDatePicker ? onApply : undefined}
        onHide={!singleDatePicker ? handleApplyClick : undefined}
      >
        <input
          type="text"
          className={classNames('form-control', { 'is-invalid': Boolean(errorKey) })}
          placeholder={getIntl(singleDatePicker ? 'Select date' : 'Select dates')}
          value={singleDatePicker ? initialSettings.startDate?.format(DateFormats.CLIENT) || '' : undefined}
          defaultValue={
            !singleDatePicker ? (startDate && endDate ? getPickerValue(startDate, endDate) : '') : undefined
          }
          onChange={() => null}
          disabled={disabled}
          name={name}
        />
      </ReactDateRangePicker>
      {Boolean(errorKey) && <Form.Control.Feedback type="invalid">{getIntl(errorKey)}</Form.Control.Feedback>}
    </>
  );
};

export default DateRangePicker;
