import classNames from 'classnames';
import styled from 'styled-components';
import React, { useEffect, useState, useMemo } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { getCO2EmissionChartData } from 'modules/co2e';
import {
  CO2eScenariosYearSelector,
  isCO2eCumulativeOverviewSelector,
  scenarioIdSelector,
} from 'modules/layouts/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import Chart from 'components/_charts/Chart';
import { DataTableTemplate, ContentContainer, ContentCard, Alert, Spinner } from 'components/_common';
import Filters from './Filters';
import { DataItem } from './ChartCO2Emission';

const ViewOverview: React.FC = () => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const isCO2eCumulativeOverview = useSelector(isCO2eCumulativeOverviewSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const [data, setData] = useState<Record<number, DataItem> | null>(null);
  const CO2eScenariosYear = useSelector(CO2eScenariosYearSelector);
  const CO2Emission = data?.[CO2eScenariosYear];

  useEffect(() => {
    if (!scenarioId) return;
    dispatch(getCO2EmissionChartData({ scenarioId, isCO2eCumulativeOverview })).then(action =>
      setData(_keyBy(action.payload, (item: DataItem) => item.year))
    );
  }, [dispatch, scenarioId, isCO2eCumulativeOverview]);

  const renderCard = ({
    propKey,
    titleKey,
    className = '',
  }: {
    propKey: keyof DataItem;
    titleKey: string;
    className?: string;
  }) => (
    <ContentCard className={classNames('d-flex flex-column justify-content-center align-items-center', className)}>
      <h5 className="font-weight-normal mb-3" dangerouslySetInnerHTML={{ __html: getIntl(titleKey) }} />
      <h1
        className={classNames({
          'text-danger': CO2Emission![propKey] > 0,
          'text-success': CO2Emission![propKey] <= 0,
        })}
      >
        {numberFormat(CO2Emission![propKey], { maximumFractionDigits: 0, fallback: '-' })}
      </h1>
    </ContentCard>
  );

  const config = useMemo(
    () => ({
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${this.point.name}<br><b>${numberFormat(this.point.y)}</b>`;
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderRadius: 5,
          dataLabels: {
            enabled: true,
            format: '{point.name}<br><b>{point.percentage:.1f}%</b>',
            style: {
              fontSize: '13px',
              fontWeight: 400,
            },
          },
        },
      },
    }),
    [numberFormat]
  );

  const pie1Options = useMemo(
    () => ({
      ...config,
      series: [
        {
          data: [
            {
              name: getIntl('Reinvestments (t&nbspCO₂e)'),
              y: CO2Emission?.existing_assets_t_co2e,
            },
            {
              name: getIntl('Investments (t&nbspCO₂e)'),
              y: CO2Emission?.investments_t_co2e,
            },
            {
              name: getIntl('Maintenance (t&nbspCO₂e)'),
              y: CO2Emission?.maintenance_t_co2e,
            },
            {
              name: getIntl('Grid losses (t&nbspCO₂e)'),
              y: CO2Emission?.losses_t_co2e_investment,
            },
          ],
        },
      ],
    }),
    [CO2Emission, config, getIntl]
  ) as unknown as Highcharts.Options;

  const pie2Options = useMemo(
    () => ({
      ...config,
      series: [
        {
          data: [
            {
              name: getIntl('Scope 1 (t&nbspCO₂e)'),
              y: CO2Emission?.scope_1_t_co2e,
            },
            {
              name: getIntl('Scope 2 (t&nbspCO₂e)'),
              y: CO2Emission?.scope_2_t_co2e,
            },
            {
              name: getIntl('Scope 3 (t&nbspCO₂e)'),
              y: CO2Emission?.scope_3_t_co2e,
            },
          ],
        },
      ],
    }),
    [CO2Emission, config, getIntl]
  ) as unknown as Highcharts.Options;

  const chartHeight = 'calc(50vh - 243px / 2)';

  if (!data && !CO2Emission) return <Spinner isInFullHeightContainer />;
  if (data && !CO2Emission) {
    return (
      <ContentContainer className="border-top-0">
        <Alert className="mb-0 text-center" variant="light" data-marker="there_is_no_data">
          {getIntl('There is no data for selected scenario')}.
        </Alert>
      </ContentContainer>
    );
  }
  return (
    <DataTableTemplate paddingBottom>
      <Filters />
      <ContentContainer>
        <StyledContainer>
          {renderCard({ propKey: 'total_overview_t_co2e', titleKey: 'Total (t&nbspCO₂e)' })}
          {renderCard({
            propKey: 'total_emissions_t_co2e',
            titleKey: 'Total emissions (t&nbspCO₂e)',
          })}
          <ContentCard className="grid-row-span-2">
            <Chart height={chartHeight} options={pie1Options} />
          </ContentCard>
          {renderCard({ propKey: 'existing_assets_t_co2e', titleKey: 'Reinvestments (t&nbspCO₂e)' })}
          {renderCard({ propKey: 'maintenance_t_co2e', titleKey: 'Maintenance (t&nbspCO₂e)' })}
          {renderCard({ propKey: 'investments_t_co2e', titleKey: 'Investments (t&nbspCO₂e)' })}
          {renderCard({
            propKey: 'climate_benefit_t_co2e',
            titleKey: 'Climate benefit (t&nbspCO₂e)',
          })}
          <ContentCard className="grid-row-span-2">
            <Chart height={chartHeight} options={pie2Options} />
          </ContentCard>
          {renderCard({
            propKey: 'losses_t_co2e_investment',
            titleKey: 'Grid losses (t&nbspCO₂e)',
            className: 'grid-column-span-2',
          })}
        </StyledContainer>
      </ContentContainer>
    </DataTableTemplate>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 32fr 32fr 36fr;
  grid-template-rows: auto auto;
  gap: 10px;

  .grid-row-span-2 {
    grid-row: span 2;
  }

  .grid-column-span-2 {
    grid-column: span 2;
  }
`;

export default ViewOverview;
