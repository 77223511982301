import React from 'react';
import { Form } from 'react-bootstrap';
import { useLocale } from 'hooks';

export interface FormSelectOption {
  id: number | Builder.FormTypeNames;
  name: string;
}

export interface FormSelectProps {
  labelKey?: string;
  label?: string | React.ReactElement;
  errorKey?: string;
  name: string;
  value: string | number | null;
  options: FormSelectOption[];
  disabled?: boolean;
  onChange: (e: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
}

const FormSelect: React.FC<FormSelectProps> = ({
  labelKey = '',
  label = '',
  errorKey = '',
  name,
  value,
  options,
  disabled = false,
  onChange,
  onBlur,
}) => {
  const { getIntl } = useLocale();

  return (
    <>
      <Form.Label>{label || (labelKey && getIntl(labelKey))}</Form.Label>
      <Form.Control
        as="select"
        disabled={disabled || !options.length}
        custom
        name={name}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        isInvalid={Boolean(errorKey)}
      >
        {options.length && !value && <option>{getIntl('Choose')}...</option>}
        {!options.length && <option>{getIntl('No options')}</option>}
        {options.map(item => (
          <option key={item.id} value={item.id}>
            {getIntl(item.name)}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{getIntl(errorKey)}</Form.Control.Feedback>
    </>
  );
};

export default FormSelect;
