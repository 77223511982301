import styled from 'styled-components';
import React from 'react';
import { Spinner as BootstrapSpinner } from 'react-bootstrap';
import ContentCard from './ContentCard';

interface Props {
  isInFullHeightContainer?: boolean;
  text?: string;
  inCard?: boolean;
  inline?: boolean;
  containerClassName?: string;
  size?: 'sm';
}

const Spinner: React.FC<Props> = ({
  isInFullHeightContainer = false,
  text = '',
  inCard = false,
  inline = false,
  containerClassName,
  size,
}) => {
  let SpinnerElem = <BootstrapSpinner animation="border" variant="success" size={size} />;

  if (text) {
    SpinnerElem = (
      <div className="d-inline-flex flex-column align-items-center">
        {SpinnerElem}
        <span>{text}</span>
      </div>
    );
  }

  if (inline) {
    SpinnerElem = <StyledInlineContainer className={containerClassName}>{SpinnerElem}</StyledInlineContainer>;
  } else if (isInFullHeightContainer) {
    SpinnerElem = <StyledFullHeightContainer>{SpinnerElem}</StyledFullHeightContainer>;
  }

  if (inCard) {
    SpinnerElem = <StyledContentCard>{SpinnerElem}</StyledContentCard>;
  }

  return SpinnerElem;
};

const StyledContentCard = styled(ContentCard)`
  height: 70vh;
`;

const StyledInlineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
`;

const StyledFullHeightContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default Spinner;
