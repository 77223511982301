import { combineActions, handleActions } from 'redux-actions';
import { fetchRightsAction, getElectricityConsumptionChartData, fetchExistingDERsOptionsAction } from './actions';

const initialState: Customers.Root = {
  rightsHash: {} as Type.Hash<Customers.Rights>,
  rightsCount: 0,
  existingDERsOptions: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(fetchRightsAction, fetchExistingDERsOptionsAction) as any]: {
    next: (
      state: Customers.Root,
      action: Shared.ReduxAction<Partial<Customers.Root> & { skipStoreUpdate: true | undefined }>
    ): Customers.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export { fetchRightsAction, getElectricityConsumptionChartData, fetchExistingDERsOptionsAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
