import React from 'react';
import { ContentContainer, ContentCard } from 'components/_common';
import ChartGlobalDevelopment from './ChartGlobalDevelopment';

const ViewGlobalDevelopment: React.FC = () => (
  <ContentContainer className="border-top-0">
    <ContentCard>
      <ChartGlobalDevelopment />
    </ContentCard>
  </ContentContainer>
);

export default ViewGlobalDevelopment;
