import { createSelector } from 'reselect';
import { rootSelector } from './selectors';

// ------------------------------------
// Selectors
// ------------------------------------
export const categoriesCNAIMCountSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): number => assets.categoriesCNAIMCount
);

const categoriesCNAIMHashSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Type.Hash<Asset.CategoryCNAIM> => assets.categoriesCNAIMHash
);

export const categoriesCNAIMSelector = createSelector(
  categoriesCNAIMHashSelector,
  (categoriesCNAIMHash: Type.Hash<Asset.CategoryCNAIM>): Asset.CategoryCNAIM[] => Object.values(categoriesCNAIMHash)
);

export const categoriesCNAIMTemplatesSelector = createSelector(
  rootSelector,
  (assets: Asset.Root): Asset.CategoriesCNAIMTemplates | null => assets.categoriesCNAIMTemplates
);

export const categoriesCNAIMOptionsSelector = createSelector(
  (state: State.Root) => state.assets,
  (assets: Asset.Root): Asset.Root['categoriesCNAIMOptions'] => assets.categoriesCNAIMOptions
);
