import { createAction } from 'redux-actions';
import { appLangSelector } from 'modules/app/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType, AssetLifeAPI } from 'constants/index';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchReinvestmentCNAIMAction: any = createAction(
  'interventions/FETCH',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Reinvestment.Root, 'reinvestmentCNAIMCount' | 'reinvestmentCNAIMHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.REINVESTMENT_CNAIM
      )(state);
      return AssetLifeAPI.get('reinvestment/reinvestment_table', {
        params: {
          limit: skipPagination ? null : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query: skipPagination ? '' : query,
          lang: appLangSelector(state).toLowerCase(),
          asset_category: filters?.reinvestmentAssetCategory,
        },
      }).then((res: any) => ({
        reinvestmentCNAIMCount: res.data.count,
        reinvestmentCNAIMHash: _keyBy(res.data.rows, (item: Reinvestment.CNAIM) => item.customer_id),
        skipStoreUpdate,
      }));
    }
);
