import React from 'react';
import { useSelector } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import useConfiguredFormik from './useConfiguredFormik';
import YearFilter from './YearFilter';
import DateRangeFilter from './DateRangeFilter';
import SelectUsersFilter from './SelectUsersFilter';
import SelectTypeFilter from './SelectTypeFilter';
import SelectEmployeesFilter from './SelectEmployeesFilter';
import SelectTaskStatusFilter from './SelectTaskStatusFilter';
import TaskIdOrCaseNumberInputFilter from './TaskIdOrCaseNumberInputFilter';
import { PaginationType, DataTableFilterKeys } from 'constants/index';

interface Props {
  paginationType: PaginationType;
  showKeys: DataTableFilterKeys[];
  yearFilterConfig?: { range: number[]; isLoading: boolean };
}

const DataTableHeaderFilters: React.FC<Props> = ({ paginationType, showKeys = [], yearFilterConfig }) => {
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));
  const formik = useConfiguredFormik({ paginationType, filters: filters as Layouts.Filters });
  const { values, setFieldValue, setValues, submitForm } = formik;

  return (
    <>
      {showKeys.includes(DataTableFilterKeys.START_TIME) && showKeys.includes(DataTableFilterKeys.END_TIME) && (
        <DateRangeFilter inDataTableHeaderFilters values={values} setValues={setValues} submitForm={submitForm} />
      )}
      {showKeys.includes(DataTableFilterKeys.YEAR) && (
        <YearFilter
          inDataTableHeaderFilters
          values={values}
          setValues={setValues}
          submitForm={submitForm}
          paginationType={paginationType}
          yearFilterConfig={yearFilterConfig}
        />
      )}
      {showKeys.includes(DataTableFilterKeys.TYPE) && (
        <SelectTypeFilter
          inDataTableHeaderFilters
          values={values}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
        />
      )}
      {showKeys.includes(DataTableFilterKeys.USER_IDS) && (
        <SelectUsersFilter
          inDataTableHeaderFilters
          values={values}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
          paginationType={paginationType}
        />
      )}
      {showKeys.includes(DataTableFilterKeys.EMPLOYEES_IDS) && (
        <SelectEmployeesFilter
          inDataTableHeaderFilters
          values={values}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
        />
      )}
      {showKeys.includes(DataTableFilterKeys.STATUS) && (
        <SelectTaskStatusFilter
          inDataTableHeaderFilters
          values={values}
          paginationType={paginationType}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
        />
      )}
      {showKeys.includes(DataTableFilterKeys.TASK_ID_OR_CASE_NUMBER) && (
        <TaskIdOrCaseNumberInputFilter
          inDataTableHeaderFilters
          values={values}
          setFieldValue={setFieldValue}
          submitForm={submitForm}
        />
      )}
    </>
  );
};

export default DataTableHeaderFilters;
