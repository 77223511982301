import axios from 'axios';
import queryString from 'query-string';
import { createAction } from 'redux-actions';
import {
  firstAvailableThemeInTheGroupSelectorFactory,
  mapStateCollapseGroupStateSelector,
  rootSelector,
  selectedSimulationSelector,
} from 'modules/map/selectors';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import setUnifiedAssetActiveTabs from 'components/Panels/UnifiedAssetPanel/utils';
import { _mergeWith } from '@utiligize/shared/utils';
import { AssetLifeAPI, TaskStatus, TaskTypes } from 'constants/index';
import { mapDefaultState } from './index';
import { MapThemes } from 'constants/map';

// ------------------------------------
// Actions
// ------------------------------------
export const setMapLoading = createAction<boolean>('map/LOADING');

export const setSettingsAction = createAction<Partial<Map.MapSettings>>('map/SET_SETTINGS');

export const fetchConfigAction = createAction(
  'map/FETCH_CONFIG',
  async ({
    portfolioId,
    versionId,
    simulationId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    versionId: number;
    simulationId: number;
  }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Map.LegendData> => {
      dispatch(setConfigFetchedAction(false));
      return axios
        .get<Map.LegendResponse>('api/admin/v1/secure/map/config', {
          params: {
            portfolio_id: portfolioId,
            version_id: versionId,
            simulation_id: simulationId,
          },
        })
        .then(res => res.data)
        .finally(() => dispatch(setConfigFetchedAction(true)));
    }
);

export const mapStateAction = createAction('map/MAP_STATE', async (modifier: Partial<Map.MapState>) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Map.MapState => {
    const state = getState();
    const collapseGroupState = mapStateCollapseGroupStateSelector(state);
    const map = rootSelector(state);
    const prev = map.mapState || {};
    const defaultState = Object.fromEntries(
      Object.keys(modifier).map(k => [k, mapDefaultState[k as keyof Map.MapState]?.(map)])
    );
    // Automatically open/close first available theme
    if (modifier.hasOwnProperty('themeGroup') && !modifier.hasOwnProperty('theme')) {
      modifier.theme = firstAvailableThemeInTheGroupSelectorFactory(modifier.themeGroup!)(state);
    }
    // Merge collapseGroupState with redux state
    if (modifier.hasOwnProperty('collapseGroupState')) {
      modifier.collapseGroupState = { ...collapseGroupState, ...modifier.collapseGroupState };
    }
    // Configure UAV default tabs on map theme changed
    if (modifier.theme) setUnifiedAssetActiveTabs(modifier.theme);

    // Close filters on task theme enabled event, since they are disabled
    if (modifier.theme === MapThemes.TASK) {
      modifier.collapseGroupState = { ...collapseGroupState, voltage: false, stations: false, assets: false };
    }
    return _mergeWith({}, defaultState, prev, modifier, (_, b) =>
      !Array.isArray(b) && typeof b === 'object' ? undefined : b
    );
  };
});

export const fetchMaxLoadSummaryAction = createAction(
  'map/FETCH_MAX_LOAD_SUMMARY',
  (scenarioId: Layouts.Root['scenarioId']) => (): Promise<Map.MaxLoadSummary> => {
    return AssetLifeAPI.get('scenarios/der_description_map', { params: { scenario_id: scenarioId } }).then(
      res => res.data
    );
  }
);

export const fetchTasksFeatureCollection = createAction(
  'map/FETCH_TASKS_FEATURE_COLLECTION',
  async (yearFrom: number, yearTo: number) =>
    (dispatch: Function, getState: () => State.Root): Promise<Map.Root['tasksFeatureCollection']> => {
      dispatch(setMapLoading(true));
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const selectedSimulation = selectedSimulationSelector(state);
      return axios
        .get('api/admin/v3/secure/map/tasks', {
          params: { yearFrom, yearTo, portfolioId: portfolioId, versionId: selectedSimulation?.version_value },
        })
        .then(res => ({
          ...res.data,
          features: res.data.features.map((feature: any) => ({
            ...feature,
            properties: {
              ...feature.properties,
              ...Object.assign(
                {},
                ...feature.properties.userEmailsStr
                  .split(',')
                  .map((i: string, index: number) => ({ [`user${index}`]: i }))
              ),
              status:
                feature.properties.type === TaskTypes.Autogenerated
                  ? feature.properties.assetCompleted
                    ? TaskStatus.Finished
                    : TaskStatus.NotStarted
                  : feature.properties.status,
            },
          })),
        }));
    }
);

export const fetchTaskFiltersAction = createAction(
  'map/FETCH_TASK_FILTERS',
  async () => (): Promise<Map.TaskFilters | null> =>
    axios.get<Map.TaskFilters>('api/admin/v1/secure/map/filters').then(res => res.data)
);

export const setConfigFetchedAction = createAction<boolean>('map/SET_CONFIG_FETCHED');

export const setMapLayersAction = createAction<Map.StyleLayer[] | null>('map/SET_MAP_LAYERS');

export const setDataQualityWarningAction = createAction<Map.DataQualityWarning | null>('map/SET_DATA_QUALITY_WARNING');

export const fetchNearestAssetLocationAction = createAction(
  'map/FETCH_NEAREST_ASSET_LOCATION',
  (data: Map.FetchNearestAssetLocation) => {
    return async (dispatch: Function, getState: () => State.Root): Promise<Map.NearestAssetLocationData> => {
      const { layer, lngLat, zoom } = data;
      const state = getState();
      const map = state.map;
      const selectedSimulation = selectedSimulationSelector(state);
      const substationKey = 'filter_primary_substations';
      const s = map.settings.globalFilters?.[substationKey];
      const stations = { ...s, initList: s?.list, list: s?.list.map(i => i.id) };
      const assetLayer = layer.match(/asset.*/)?.[0] ?? '';
      return axios
        .post<[number, number]>(
          'api/admin/v1/secure/map/nearest_asset_location',
          {
            layer: {
              name: layer,
              filter: map.mapState.layerFilters?.[assetLayer],
            },
            stations: map.mapState?.globalFilters?.[substationKey] ?? stations,
          },
          {
            params: {
              x: lngLat.lng,
              y: lngLat.lat,
              version_id: selectedSimulation?.version_value,
              simulation_id: selectedSimulation?.value,
            },
          }
        )
        .then(res => ({ zoom, center: res.data }));
    };
  }
);

export const fetchPopupAssetInfoAction = createAction(
  'map/FETCH_POPUP_ASSET_INFO',
  (uuid: string) =>
    (dispatch: Function, getState: () => State.Root): Promise<Map.FetchFindAssetData> => {
      const selectedSimulation = selectedSimulationSelector(getState());
      return (
        axios
          .get<Map.FetchFindAssetData>(`api/admin/v1/secure/map/find_asset/${uuid}`, {
            params: {
              version_id: selectedSimulation?.version_value,
              simulation_id: selectedSimulation?.value,
            },
          })
          // check layersState later for found layer to allow not only assets
          .then(res => res.data)
      );
    }
);

export const fetchN1RouteAction = createAction('map/FETCH_N1_ROUTE', (params: Map.FetchN1Route) => {
  return (dispatch: Function, getState: () => State.Root): Promise<Map.N1Route | null> => {
    const selectedSimulation = selectedSimulationSelector(getState());
    return axios
      .get<Map.N1Route>(`api/admin/v1/secure/map/n-1/route`, {
        params: {
          ...params,
          version_id: selectedSimulation?.version_value,
          simulation_id: selectedSimulation?.value,
        },
      })
      .then(res => res.data || null)
      .catch(() => null);
  };
});

export const fetchDataQualityWarningAction = createAction(
  'map/FETCH_DATA_QUALITY_WARNING',
  (params: Pick<DataQuality.Issue, 'issue_id' | 'code'>, warning: DataQuality.Issue['warning']) => {
    return (dispatch: Function, getState: () => State.Root): Promise<Map.DataQualityWarning | null> => {
      const selectedSimulation = selectedSimulationSelector(getState());
      return axios
        .get<Map.DataQualityWarning>(`api/admin/v1/secure/map/data_quality_warning`, {
          params: {
            md5sum: params.issue_id,
            code: params.code,
            version_id: selectedSimulation?.version_value,
            simulation_id: selectedSimulation?.value,
          },
        })
        .then(res => (res.data ? { ...res.data, description: warning } : null))
        .catch(() => null);
    };
  }
);

export const fetchSimulationsAction = createAction(
  'map/FETCH_SIMULATIONS',
  (portfolioId: Layouts.Root['portfolioId']) =>
    async (
      dispatch: Function,
      getState: () => State.Root
    ): Promise<Pick<Map.Root, 'simulationsHash' | 'selectedSimulation'>> => {
      return axios
        .get<Map.SimulationFilter[]>(`api/admin/v1/secure/map/simulations`, { params: { portfolio_id: portfolioId } })
        .then(res => {
          const state = getState();
          const selectedSimulation = selectedSimulationSelector(state);
          const { version_value, value } = selectedSimulation || {};
          const isOptionExist =
            version_value && value && res.data.some(o => o.version_value === version_value && o.value === value);
          return {
            simulationsHash: { ...getState().map.simulationsHash, [String(portfolioId)]: res.data },
            selectedSimulation: isOptionExist ? selectedSimulation : res.data[0],
          };
        });
    }
);

export const fetchInvestmentScenariosAction = createAction(
  'map/FETCH_INVESTMENT_SCENARIOS',
  async (scenarioId: Layouts.Root['scenarioId']) => (): Promise<Type.SelectOption[]> =>
    axios
      .get<Type.SelectOption[]>('api/admin/v1/secure/map/investment_scenarios', {
        params: { scenario_id: scenarioId },
      })
      // FixMe. No data must be excluded from the BE response
      .then(res => res.data.filter(i => i.label !== 'No data'))
);

export const setSelectedSimulationAction = createAction<Map.SimulationFilter | null>('map/SET_SELECTED_SIMULATION');

export const setSelectedInvestmentScenarioAction = createAction<Type.SelectOption | null>(
  'map/SET_INVESTMENT_SCENARIO'
);

export const setN1RouteAction = createAction<Map.N1Route | null>('map/SET_N1_ROUTE');

export const setSelectedN1RoutesAction = createAction<Map.N1RouteItem[] | null>('map/SET_SELECTED_N1_ROUTES');

export const setSelectedN1RouteIdAction = createAction<number | null>('map/SET_SELECTED_N1_ROUTE_ID');

export const setAddressSearchAction = createAction('map/SET_ADDRESS_SEARCH');

export const setCableAnimationAction = createAction<Pick<Map.Root, 'showCableAnimation'>>('map/SET_CABLE_ANIMATION');

export const setLabelsAction = createAction<Map.Root['showLabels']>('map/SET_LABELS');

export const fetchSingleLineDiagramAction = createAction(
  'map/FETCH_SINGLE_LINE_DIAGRAM',
  ({
    type,
    primary_substation_id,
    simulationVersionId,
    mapFilterVoltageCheckedFiltersIds,
  }: {
    type: Type.SingleLineDiagramTypes;
    primary_substation_id: number;
    simulationVersionId: number;
    mapFilterVoltageCheckedFiltersIds: string;
  }) =>
    (): Promise<any> => {
      return AssetLifeAPI.get('single_line_diagram/topology', {
        params: {
          type,
          primary_substation_id,
          search: '',
          version_id: simulationVersionId,
          voltage_id: mapFilterVoltageCheckedFiltersIds?.split(','),
          distance_constraint: 10,
          in_operation_check: false,
          simplified_graph: false,
          filter_by_station_id: false,
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then(res => res.data);
    }
);
