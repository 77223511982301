import { handleActions } from 'redux-actions';
import { fetchReinvestmentCNAIMAction } from './actions';

const initialState: Reinvestment.Root = {
  reinvestmentCNAIMCount: 0,
  reinvestmentCNAIMHash: {} as Record<Reinvestment.CNAIM['customer_id'], Reinvestment.CNAIM>,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [fetchReinvestmentCNAIMAction.toString()]: {
    next: (
      state: Reinvestment.Root,
      action: Shared.ReduxAction<Reinvestment.Root & { skipStoreUpdate: true | undefined }>
    ): Reinvestment.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export { fetchReinvestmentCNAIMAction };

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
