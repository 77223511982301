import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { interventionsCountSelector, interventionsSelector } from 'modules/interventions/selectors';
import { fetchInterventionsAction } from 'modules/interventions';
import { setInterventionDetailsModalId } from 'modules/layouts';
import { IconDetails } from '@utiligize/shared/resources';
import { DataTable, Button, Checkbox } from 'components/_common';
import { PaginationType, TableHeaders } from 'constants/index';

interface Props {
  containerClassName?: string;
}

const InterventionsTable: React.FC<Props> = ({ containerClassName }) => {
  const { numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [all, setAll] = useState<any[]>([...Array(100).fill(false)]);

  const count = useSelector(interventionsCountSelector);
  const items = useSelector(interventionsSelector);

  useEffect(() => {
    if (items) setAll([...items.map(i => i.Bundle)]);
  }, [items]);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchInterventionsAction(params)).then(
        (action: Shared.ReduxAction<{ interventionsHash: Type.Hash<Interventions.Intervention> }>) =>
          Object.values(action.payload?.interventionsHash || {})
      ),
    [dispatch]
  );

  const handleDetailsBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (!id) return;
      dispatch(setInterventionDetailsModalId(Number(id)));
    },
    [dispatch]
  );

  const customHeaders = useMemo(
    () => [
      ...TableHeaders[PaginationType.INTERVENTIONS].map(header => {
        if (header.titleKey !== 'Bundle') return header;
        return {
          ...header,
          title: (
            <Checkbox
              className="icheck-success d-inline ml-2 position-relative"
              checked={all.every(Boolean)}
              onChange={event => setAll([...Array(100).fill(event.currentTarget.checked)])}
            />
          ),
        };
      }),
    ],
    [all]
  );

  return (
    <DataTable
      customHeaders={customHeaders}
      paginationType={PaginationType.INTERVENTIONS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      containerClassName={containerClassName}
    >
      {items.map((item: Interventions.Intervention, index: number) => (
        <tr key={item.id}>
          <td>{item.asset || '-'}</td>
          <td>{item.category || '-'}</td>
          <td>{item.year_installed || '-'}</td>
          <td>{numberFormat(item.MVA, { fallback: '-' })}</td>
          <td>{numberFormat(item['Monetary risk reduction (M EUR)'], { fallback: '-' })}</td>
          <td>{item.Zone || '-'}</td>
          <td>{item.Manufacturer || '-'}</td>
          <td>{item.Model || '-'}</td>
          <td>{item.Tag || '-'}</td>
          <td className="text-center">
            <Checkbox
              className="icheck-success ml-2 position-relative"
              checked={all[index]}
              onChange={() =>
                setAll(all => {
                  const nextAll = [...all];
                  nextAll[index] = !nextAll[index];
                  return nextAll;
                })
              }
            />
          </td>
          <td className="text-right">
            <Button
              tooltipKey="Details"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleDetailsBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default InterventionsTable;
