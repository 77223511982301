import axios from 'axios';
import { createAction } from 'redux-actions';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';

const baseUrl: string = 'api/admin/v1/secure/assetmodels';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssetModelsAction: any = createAction(
  'assetModels/FETCH_ASSET_MODELS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<AssetModels.Root, 'count' | 'itemsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ASSET_MODELS)(
        state
      );
      return axios
        .get(baseUrl, {
          params: {
            limit,
            offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
            assetCategoryCode: filters?.assetcategoryCode,
            subComponentTypeId: filters?.subComponentTypeId,
          },
        })
        .then((res: any) => ({
          count: res.data.count,
          itemsHash: _keyBy(res.data.rows, (item: AssetModels.Item) => `_${item.modelId}_`),
        }));
    }
);

export const fetchAllAssetModelsAction: any = createAction(
  'assetModels/FETCH_ALL_ASSET_MODELS',
  async () =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<AssetModels.Item[]> => {
      const state = getState();
      const { sort, column } = paginationSelectorFactory(PaginationType.ASSET_MODELS)(state);
      return axios.get(`${baseUrl}/all`, { params: { sort, column } }).then((res: any) => res.data.rows);
    }
);

interface Data {
  id?: number;
  name: string;
  assetcategoryId: number;
  manufacturerId: number;
  replacementOption: boolean;
  replacementPriority: number;
  lastReplacementDate: string;
}

export const createAssetModelAction: any = createAction(
  'assetModels/CREATE_ASSET_MODEL',
  async (data: Data) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post(baseUrl, data).then(async () => {
        await dispatch(fetchAssetModelsAction());
        dispatch(setSuccessToastAction('Asset model has been created'));
      });
    }
);

export const updateAssetModelAction: any = createAction(
  'assetModels/UPDATE_ASSET_MODEL',
  async (data: Data) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios
        .put(`${baseUrl}/${data.id}`, {
          name: data.name,
          assetcategoryId: data.assetcategoryId,
          manufacturerId: data.manufacturerId,
          replacementOption: data.replacementOption,
          replacementPriority: data.replacementPriority,
          lastReplacementDate: data.lastReplacementDate,
        })
        .then(async () => {
          await dispatch(fetchAssetModelsAction());
          dispatch(setSuccessToastAction('Asset model has been updated'));
        });
    }
);

export const deleteAssetModelAction: any = createAction('assetModels/DELETE_ASSET_MODEL', async (id: string) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> =>
    axios.delete(`${baseUrl}/${id}`).then(async () => {
      await dispatch(fetchAssetModelsAction());
      dispatch(setSuccessToastAction('Asset model has been deleted'));
    });
});

export const patchAssetModelAction: any = createAction(
  'assetModels/PATCH_ASSET_MODEL',
  async (id: string, data: Partial<{ replacementOption: boolean; replacementPriority: number }>) => {
    return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const state = getState();
      const { filters } = paginationSelectorFactory(PaginationType.ASSET_MODELS)(state);
      return axios
        .patch(`${baseUrl}/${id}`, data, {
          params: {
            assetCategoryCode: filters?.assetcategoryCode,
            subComponentTypeId: filters?.subComponentTypeId,
          },
        })
        .then(async () => dispatch(fetchAssetModelsAction()));
    };
  }
);
