import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocale } from 'hooks';
import { portfolioIdSelector, profileModalMetaSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { setProfileModalMetaAction } from 'modules/layouts';
import { NetworkLoadingIgnoreMessagesTable } from 'components/_tables';
import { ProfileElectricityConsumptionChart } from 'components/_charts';
import { Modal, Tabs, Tab, ButtonDownload } from 'components/_common';
import { ProfileModalTypes } from 'constants/index';

interface Props {
  outliersRemoved: boolean;
}

const CustomerRightsProfileModal: React.FC<Props> = ({ outliersRemoved }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { lng, getIntl } = useLocale();
  const { id } = useSelector(profileModalMetaSelector) || { id: null };
  const [uuid, setUUID] = useState<string | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  useEffect(() => {
    if (id) return setUUID(id);
    setTimeout(() => setUUID(null), 500);
  }, [id]);

  const handleCancelBtnClick = useCallback(() => dispatch(setProfileModalMetaAction(null)), [dispatch]);

  return (
    <Modal
      size="xl"
      show={Boolean(id)}
      backdrop
      onHide={handleCancelBtnClick}
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCancelBtnClick }}
      customButton={
        <ButtonDownload
          labelKey="Download electricity consumption"
          fileNameLocaleStr="Electricity consumption"
          link={`load/asset_class_timeseries_excel?${queryString.stringify({
            portfolio_id: portfolioId,
            scenario_id: scenarioId,
            lang: lng.toLowerCase(),
            id: uuid,
            type: ProfileModalTypes.Customer,
          })}`}
          marginRight
          size="large"
          isAssetLifeAPI
        />
      }
    >
      <>
        {Boolean(uuid) && (
          <Tabs mountOnEnter defaultActiveKey="electricityConsumption">
            <Tab eventKey="electricityConsumption" title={getIntl('Electricity consumption')}>
              <ProfileElectricityConsumptionChart uuid={uuid} outliersRemoved={outliersRemoved} />
            </Tab>
            <Tab eventKey="ignoreMessages" title={getIntl('Comments')}>
              <NetworkLoadingIgnoreMessagesTable />
            </Tab>
          </Tabs>
        )}
      </>
    </Modal>
  );
};

export default CustomerRightsProfileModal;
