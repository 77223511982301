import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import classnames from 'classnames';
import { Range, getTrackBackground } from 'react-range';
import { _omit } from '@utiligize/shared/utils';

interface Props {
  initValue?: number;
  initValueEnd?: number;
  value?: number;
  valueEnd?: number;
  min?: number;
  max?: number;
  step?: number;
  range?: boolean;
  className?: string;
  resetOn?: any;
  stopOn?: boolean;
  tooltipLabel?: (value: number) => string;
  onChange?: (value: number, valueEnd: number) => void;
  dataMarker?: string;
  disabled?: boolean;
}

const RangeSlider: React.FC<Props> = ({
  initValue,
  initValueEnd,
  value,
  valueEnd,
  min = 0,
  max = 100,
  step = 1,
  range,
  className,
  resetOn,
  stopOn,
  tooltipLabel,
  onChange,
  dataMarker = 'track',
  disabled = false,
}) => {
  const [sliderValue, setSliderValue] = useState([initValue, initValueEnd]);
  const [sliderRunning, setSliderRunning] = useState(false);
  const rangeValue = useMemo(() => sliderValue.map(i => +(i ?? 0)).slice(0, range ? 2 : 1), [sliderValue, range]);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMountedRef = useRef(false);

  // save visibility state by rangeId to avoid label flickering on range update
  const rangeId = `${min}_${max}`;
  const [[canShowMarksId, canShowMarks], setCanShowMarks] = useState([rangeId, false]);
  const [[canShowMarkLabelsId, canShowMarkLabels], setCanShowMarkLabels] = useState([rangeId, false]);

  const isOverlap = useCallback((a: Element, b: Element, padding = 2) => {
    const rect1 = a.getBoundingClientRect();
    const rect2 = b.getBoundingClientRect();
    return !(
      rect1.right + padding < rect2.left - padding ||
      rect1.left - padding > rect2.right + padding ||
      rect1.bottom + padding < rect2.top - padding ||
      rect1.top - padding > rect2.bottom + padding
    );
  }, []);

  const debouncedResizeHandler = useDebouncedCallback(() => {
    if (!containerRef.current) return;
    const marks = [...containerRef.current.querySelectorAll('.slider-mark')];
    const labels = [...containerRef.current.querySelectorAll('.slider-mark-label')];
    const areMarksOverlap = marks.some(m1 => marks.some(m2 => m1 !== m2 && isOverlap(m1, m2)));
    const areLabelsOverlap = labels.some(l1 => labels.some(l2 => l1 !== l2 && isOverlap(l1, l2)));
    setCanShowMarks([rangeId, !areMarksOverlap]);
    setCanShowMarkLabels([rangeId, !areLabelsOverlap]);
  }, 100);

  useEffect(() => {
    return debouncedResizeHandler.cancel;
  }, [debouncedResizeHandler.cancel]);

  const resizeHandler = useCallback(() => {
    const hide = [rangeId, false] as [string, boolean];
    setCanShowMarks(hide);
    setCanShowMarkLabels(hide);
    debouncedResizeHandler();
  }, [rangeId, debouncedResizeHandler]);

  useEffect(() => {
    if (typeof value === 'number') setSliderValue([value, valueEnd]);
  }, [value, valueEnd]);

  useEffect(() => {
    if (resetOn && isMountedRef.current) setSliderValue([0, 0]);
  }, [resetOn]);

  useEffect(() => {
    if (stopOn && isMountedRef.current) setSliderRunning(false);
  }, [stopOn]);

  useEffect(() => {
    if (range || !sliderRunning) return;
    const timer = setTimeout(() => {
      const v = rangeValue[0];
      const newValue = v < max ? v + step : 0;
      setSliderValue([newValue, 0]);
      onChange?.(newValue, 0);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  // re-calculate labels visibility on resize
  useEffect(() => {
    if (!containerRef.current) return;
    const observer = new ResizeObserver(resizeHandler);
    observer.observe(containerRef.current);
    return () => {
      observer.disconnect();
    };
  }, [resizeHandler]);

  // re-calculate labels visibility on range update
  useEffect(() => {
    resizeHandler();
  }, [min, max, resizeHandler]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onPlay = () => {
    setSliderRunning(v => !v);
  };

  const onSliderChange = (values: number[]) => {
    setSliderValue(values);
    setSliderRunning(false);
  };

  const onAfterChange = (values: number[]) => {
    const [value, valueEnd] = values.sort((a, b) => a - b);
    onChange?.(value, valueEnd);
  };

  return (
    <StyledSlider ref={containerRef} className={classnames('d-flex align-items-center', className)}>
      {!range && (
        <Button variant="light" size="sm" className="map-slider-play" onClick={onPlay}>
          <i className={classnames('fas', { 'fa-pause': sliderRunning, 'fa-play': !sliderRunning })} />
        </Button>
      )}
      <div className={classnames('w-100', { 'map-slider-disabled': sliderRunning })}>
        <Range
          min={min}
          max={max}
          step={step}
          allowOverlap
          values={rangeValue}
          onChange={onSliderChange}
          onFinalChange={onAfterChange}
          disabled={disabled}
          renderTrack={({ props, children }) => (
            <StyledTrack $values={rangeValue} $min={min} $max={max}>
              {/* Fix. A props object containing a "key" prop is being spread into JSX */}
              <div key={(props as any).key} {..._omit(props, ['key'])} className="track" data-marker={dataMarker}>
                <div className="track-wrapper">
                  <div className="track-inner" />
                </div>
                {children}
              </div>
            </StyledTrack>
          )}
          renderThumb={({ props, value }) => (
            // Fix. A props object containing a "key" prop is being spread into JSX
            <StyledThumb key={(props as any).key} {..._omit(props, ['key'])} $disabled={disabled}>
              <div className="label">
                <div className="label-inner" data-marker="track__current_value">
                  {tooltipLabel?.(value) ?? value}
                </div>
                <div className="arrow" />
              </div>
            </StyledThumb>
          )}
          renderMark={({ props, index }) => (
            <StyledMark
              // Fix. A props object containing a "key" prop is being spread into JSX
              key={(props as any).key}
              {..._omit(props, ['key'])}
              className={classnames('slider-mark', { show: rangeId === canShowMarksId && canShowMarks })}
              data-marker="track__mark"
            >
              <div
                className={classnames('slider-mark-label', {
                  show: rangeId === canShowMarkLabelsId && canShowMarkLabels,
                })}
              >
                {tooltipLabel?.(index) ?? index}
              </div>
            </StyledMark>
          )}
        />
      </div>
    </StyledSlider>
  );
};

const StyledSlider = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
`;

const StyledTrack = styled.div<{ $values: number[]; $min: number; $max: number }>`
  height: 18px;
  display: flex;
  align-items: center;

  .track {
    height: 30px;
    width: calc(100% - 16px);
    margin: 0 8px;
    position: relative;
  }

  .track-wrapper {
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    background-color: var(--map-background-hover);
    border-radius: 4px;
    width: calc(100% + 16px);
  }

  .track-inner {
    height: 4px;
    width: calc(100% - 16px);
    margin: 0 8px;
    background: ${({ $values, $min, $max }) =>
      $values.length === 2
        ? getTrackBackground({
            values: $values,
            colors: ['var(--map-background-hover)', 'var(--map-background-color)', 'var(--map-background-hover)'],
            min: $min,
            max: $max,
          })
        : 'var(--map-background-hover)'};
  }
`;

const StyledThumb = styled.div<{ $disabled: boolean }>`
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: var(--map-background-color);
  border: 2px solid #fff;
  outline: none;

  ${props =>
    !props.$disabled
      ? `
        &:hover,
        &:active,
        &:focus {
          background-color: var(--map-active-color);
        }
      `
      : ''}

  .label {
    pointer-events: none;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;

    .label-inner {
      box-shadow:
        0px 6px 10px 2px rgba(50, 50, 71, 0.08),
        0px 4px 8px rgba(50, 50, 71, 0.06);
      background-color: #fff;
      border-radius: 4px;
      border: 0.5px solid var(--map-outline-color);
      color: var(--map-text-color-primary);
      max-width: 200px;
      min-width: 25px;
      padding: 2px 5px;
      text-align: center;
    }

    .arrow {
      position: absolute;
      display: block;
      width: 12px;
      height: 6px;
      left: 50%;
      transform: translateX(-50%);

      &:before {
        content: '';
        position: absolute;
        border-color: transparent;
        border-style: solid;
        border-width: 6px 6px 0;
        border-top-color: var(--map-outline-color);
        top: 0;
      }

      &:after {
        content: '';
        position: absolute;
        border-color: transparent;
        border-style: solid;
        border-top-color: #fff;
        border-width: 6px 6px 0;
        top: -1px;
      }
    }
  }
`;

const StyledMark = styled.div`
  height: 3px;
  width: 2px;
  top: 5px;
  background-color: var(--map-background-hover);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  .slider-mark-label {
    position: absolute;
    top: calc(100% + 4px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 8px;
    color: var(--map-background-color);
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }
`;

export default React.memo(RangeSlider);
