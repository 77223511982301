import React, { useMemo, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { CustomersRightsTable } from 'components/_tables';
import { CustomerRightsProfileModal } from 'components/_modals';
import {
  DataTableTemplate,
  FormReactSelect,
  SelectSolved,
  Checkbox,
  SelectCustomerExistingDERs,
} from 'components/_common';
import PagePermissionsProvider from './PagePermissionsProvider';
import {
  PaginationType,
  SelectCustomersRightsLoadTypes,
  SelectCustomersRightsMissingTypes,
  PermissionsTypes,
} from 'constants/index';

const PageCustomers: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.CUSTOMERS_RIGHTS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const SelectCustomersRightsLoadTypesLabelsMap = useMemo(
    () => ({
      [SelectCustomersRightsLoadTypes.BOTH]: 'Both',
      [SelectCustomersRightsLoadTypes.CONSUMPTION]: 'Consumption',
      [SelectCustomersRightsLoadTypes.PRODUCTION]: 'Production',
    }),
    []
  );

  const { loadTypeOptions, loadTypeSelectValue } = useMemo(
    () =>
      Object.values(SelectCustomersRightsLoadTypes).reduce(
        (acc: any, loadType: string) => {
          const item = {
            value: loadType,
            label: getIntl(SelectCustomersRightsLoadTypesLabelsMap[loadType as Type.SelectCustomersRightsLoadTypes]),
          };
          if (loadType === filters?.loadType) acc.loadTypeSelectValue = item;
          acc.loadTypeOptions.push(item);
          return acc;
        },
        { loadTypeOptions: [], loadTypeSelectValue: null }
      ),
    [filters, SelectCustomersRightsLoadTypesLabelsMap, getIntl]
  );

  const { missingTypeOptions, missingTypeSelectValue } = useMemo(
    () =>
      Object.values<Type.SelectCustomersRightsMissingTypes>(SelectCustomersRightsMissingTypes).reduce(
        (acc: any, type) => {
          const item = { value: type, label: getIntl(type) };
          if (type === filters?.missingType) acc.missingTypeSelectValue = item;
          acc.missingTypeOptions.push(item);
          return acc;
        },
        { missingTypeOptions: [], missingTypeSelectValue: null }
      ),
    [filters, getIntl]
  );

  const { percentileOptions, percentileSelectValue } = useMemo(
    () =>
      [99, 99.5, 99.9, 100].reduce(
        (acc: any, percentile: number) => {
          const item = { value: percentile, label: percentile };
          if (percentile === filters?.percentile) acc.percentileSelectValue = item;
          acc.percentileOptions.push(item);
          return acc;
        },
        { percentileOptions: [], percentileSelectValue: null }
      ),
    [filters]
  );

  const handleSelectChange = useCallback(
    (value: Type.SelectOption, actionMeta: any) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, [actionMeta.name]: (value as any)?.value } },
        })
      );
    },
    [dispatch, filters, type]
  );

  const handleSolvedSelectChange = useCallback(
    (solvedValue: Type.SelectSolvedValues) => {
      dispatch(setPaginationAction({ type, modifier: { filters: { ...filters, solvedValue } } }));
    },
    [dispatch, filters, type]
  );

  const handleSelectCustomerExistingDERsChange = useCallback(
    (existingDersId: number | null) => {
      dispatch(setPaginationAction({ type, modifier: { filters: { ...filters, existingDersId } } }));
    },
    [dispatch, filters, type]
  );

  const handleOutliersRemovedSwitch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, outliersRemoved: event.currentTarget.checked } },
        })
      );
    },
    [dispatch, filters, type]
  );

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isCustomersEnabled}>
      <DataTableTemplate paddingBottom>
        <Row>
          <Col xs={1}>
            <FormReactSelect
              name="percentile"
              mutedTextLabelKey="Percentile"
              value={percentileSelectValue}
              options={percentileOptions}
              onChange={handleSelectChange}
              isSearchable={false}
            />
          </Col>
          <Col xs={2}>
            <FormReactSelect
              name="loadType"
              mutedTextLabelKey="Load type"
              value={loadTypeSelectValue}
              options={loadTypeOptions}
              onChange={handleSelectChange}
              isSearchable={false}
            />
          </Col>
          <Col xs={2}>
            <FormReactSelect
              name="missingType"
              mutedTextLabelKey="Missing type"
              value={missingTypeSelectValue}
              options={missingTypeOptions}
              onChange={handleSelectChange}
              isSearchable={false}
            />
          </Col>
          <Col xs={2}>
            <SelectCustomerExistingDERs
              mutedTextLabelKey="Existing DERs"
              value={filters?.existingDersId || null}
              onChange={handleSelectCustomerExistingDERsChange}
              isClearable
              isSearchable={false}
            />
          </Col>
          <Col xs="auto">
            <Checkbox
              labelKey="Outliers removed"
              className="icheck-primary"
              checked={filters?.outliersRemoved}
              onChange={handleOutliersRemovedSwitch}
            />
          </Col>
          <Col xs={2} className="ml-auto">
            <SelectSolved
              mutedTextLabelKey="Filter solved"
              value={filters?.solvedValue}
              onChange={handleSolvedSelectChange}
            />
          </Col>
        </Row>
        <>
          <CustomersRightsTable />
          <CustomerRightsProfileModal outliersRemoved={Boolean(filters?.outliersRemoved)} />
        </>
      </DataTableTemplate>
    </PagePermissionsProvider>
  );
};

export default PageCustomers;
