import React, { useCallback } from 'react';
import { useLocale } from 'hooks';
import { useSelector, useDispatch } from 'react-redux';
import { categoriesCountSelector, categoriesSelector } from 'modules/assets/selectors';
import { fetchAssetCategoriesAction, deleteAssetCategoryAction } from 'modules/assets';
import { setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType, isProduction } from 'constants/index';
import { IconDelete } from '@utiligize/shared/resources';

const CategoriesTable: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(categoriesCountSelector);
  const items = useSelector(categoriesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchAssetCategoriesAction(params)).then(
        (action: Shared.ReduxAction<{ categoriesHash: Type.Hash<Asset.Category> }>) =>
          Object.values(action.payload?.categoriesHash || {})
      ),
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteAssetCategoryAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.CATEGORIES}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
    >
      {items?.map((item: Asset.Category) => (
        <tr key={item.id}>
          <td>{getIntl(item.name)}</td>
          <td>{item.code}</td>
          {!isProduction && (
            <td className="text-right">
              <Button
                tooltipKey="Delete"
                icon={<IconDelete />}
                data-id={item.id}
                onClick={handleDeleteBtnClick}
                size="small"
                variant="primary"
              />
            </td>
          )}
        </tr>
      ))}
    </DataTable>
  );
};

export default CategoriesTable;
