import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.forms;

const formsItemsHashSelector = createSelector(
  rootSelector,
  (forms: Forms.Root): Type.Hash<Forms.Item> => forms.itemsHash
);

export const formsCountSelector = createSelector(rootSelector, (forms: Forms.Root): number => forms.count);

export const formsItemsSelector = createSelector(
  formsItemsHashSelector,
  (itemsHash: Type.Hash<Forms.Item>): Forms.Item[] => Object.values(itemsHash)
);

export const formsItemSelectorFactory = (formId: number | null) =>
  createSelector(
    formsItemsHashSelector,
    (itemsHash: Type.Hash<Forms.Item>): Forms.Item | undefined => itemsHash[`_${formId}_`]
  );

export const builderDataSelector = createSelector(rootSelector, (forms: Forms.Root): Builder.Data => forms.builderData);

export const formBuilderFormTypesSelector = createSelector(
  builderDataSelector,
  (builderData: Builder.Data): Type.FormType[] => builderData.formTypes
);

const formBuilderTimestampsSelector = createSelector(
  rootSelector,
  (forms: Forms.Root): Forms.Timestamps => forms.timestamps
);

export const formBuilderTimestampsSelectorFactory = (assetCategoryCode: Type.AssetCategories | null) =>
  createSelector(
    formBuilderTimestampsSelector,
    (timestamps: Forms.Timestamps): string[] | null =>
      (assetCategoryCode && (timestamps as any)[assetCategoryCode]) || null
  );
