import { useCallback } from 'react';

export default function useEventPreventedExec(func: any) {
  return useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      func();
    },
    [func]
  );
}
