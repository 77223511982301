import React from 'react';
import { useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import { useLocale } from 'hooks';
import { rawDataMetaSelector } from 'modules/setup/selectors';
import TableRawData from './TableRawData';
import { DataTableTemplate } from 'components/_common';

const ViewRawData: React.FC = () => {
  const { getIntl } = useLocale();
  const rawDataMeta = useSelector(rawDataMetaSelector);

  return (
    <DataTableTemplate
      titleComponent={
        <Col xs="auto" className="d-flex ml-auto align-items-center">
          <small className="text-muted">
            {getIntl('Version')}: {rawDataMeta?.version || '-'}
            {' | '}
            {getIntl('Last update')}: {rawDataMeta?.timeLoaded || '-'}
          </small>
        </Col>
      }
      paddingBottom
    >
      <React.Fragment />
      <TableRawData />
    </DataTableTemplate>
  );
};

export default ViewRawData;
