import React from 'react';
import { useLocale } from 'hooks';
import { Alert } from 'components/_common';

const CustomerAssetTypeSupportAlert: React.FC = () => {
  const { getIntl } = useLocale();
  return (
    <Alert className="mb-0 text-center" variant="warning">
      {getIntl("Customer asset type doesn't support this tab")}
    </Alert>
  );
};

export default CustomerAssetTypeSupportAlert;
