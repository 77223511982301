export enum TaskAppActions {
  LogMaintenance = 'Log Maintenance',
  Repair = 'Repair',
  Inspection = 'Inspection',
  ToolInspection = 'Tool inspection',
  FinalCheck = 'Final Check',
  // custom values
  NoAsset = 'No Asset',
  Replacement = 'Replacement',
  NewSmartMeter = 'New smart meter',
  ReplaceSmartMeter = 'Replace smart meter',
  RemoveSmartMeter = 'Remove smart meter',
}

export const AssetTaskAppActions: TaskAppActions[] = [
  TaskAppActions.LogMaintenance,
  TaskAppActions.Replacement,
  TaskAppActions.FinalCheck,
  TaskAppActions.Inspection,
  TaskAppActions.Repair,
];

export const NonAssetTaskAppActions = Object.values(TaskAppActions).filter(
  (action: TaskAppActions) => !AssetTaskAppActions.includes(action)
);

export const InstallationNumbersTaskAppActions = [
  TaskAppActions.NewSmartMeter,
  TaskAppActions.ReplaceSmartMeter,
  TaskAppActions.RemoveSmartMeter,
];

export const CustomerComplaintTaskAppActions = Object.values(TaskAppActions).filter(
  (action: TaskAppActions) => action === TaskAppActions.ToolInspection
);

export enum TaskTypes {
  Immediate = 'Immediate',
  Expected = 'Expected',
  Autogenerated = 'Autogenerated',
  CustomerComplaint = 'Customer Complaint',
}

export enum TaskStatus {
  MobileSubmitted = 'Mobile submitted', // #17a2b8
  NotStarted = 'Not started', // #17a2b8
  Ongoing = 'Ongoing', // #ffc107
  Finished = 'Finished', // #28a745
}

export const TaskStatusVariantMap: { [key in Tasks.TaskStatus]: string } = {
  [TaskStatus.MobileSubmitted]: 'info',
  [TaskStatus.NotStarted]: 'info',
  [TaskStatus.Ongoing]: 'warning',
  [TaskStatus.Finished]: 'success',
};

export const TaskStatusColorMap: { [key in Tasks.TaskStatus]: string } = {
  [TaskStatus.MobileSubmitted]: '#17a2b8',
  [TaskStatus.NotStarted]: '#ff9501',
  [TaskStatus.Ongoing]: '#ff9501',
  [TaskStatus.Finished]: '#34c759',
};

export enum TaskMonthsEnum {
  Jan = 'Jan',
  Feb = 'Feb',
  Mar = 'Mar',
  Apr = 'Apr',
  May = 'May',
  Jun = 'Jun',
  Jul = 'Jul',
  Aug = 'Aug',
  Sep = 'Sep',
  Oct = 'Oct',
  Nov = 'Nov',
  Dec = 'Dec',
}

export const TaskMonths = Object.values(TaskMonthsEnum);
