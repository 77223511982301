import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  settingsSelector,
  selectedN1RoutesSelector,
  selectedN1RouteIdSelector,
  enabledLayersListSelector,
  createMapStateSelectorFactory,
  showCableAnimationSelector,
} from 'modules/map/selectors';
import { setN1RouteAction, fetchN1RouteAction, mapStateAction } from 'modules/map';
import { showTheme, hideTheme, isLayerExist, getInitMapState, parseObject } from 'utils/map';
import { filterN1 } from 'components/Map/core/layers/n1';

interface Props {
  map: Map.MapboxMap;
}

const N1: React.FC<Props> = ({ map }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const settings = useSelector(settingsSelector);
  const selectedN1Routes = useSelector(selectedN1RoutesSelector);
  const selectedN1RouteId = useSelector(selectedN1RouteIdSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const layerFilters = useSelector(createMapStateSelectorFactory('layerFilters'));
  const history = useHistory<{ n1Route: N1.Item['positionOnTheMap'] }>();
  const enabledLayersList = useSelector(enabledLayersListSelector);

  useEffect(() => {
    const onMapClick = (e: mapboxgl.MapMouseEvent) => {
      const isRMB = e.originalEvent.button === 2;
      if (!isRMB) return;
      const p = e.point;
      const z = Math.round(map.getZoom()); // zoom
      const t = z >= 16 ? z * 0.5 : z >= 10 ? 3 : 1; // tolerance
      const layers = settings.infoLayers!.filter(i => isLayerExist(map, i));
      const bbox = [
        [p.x - t, p.y - t],
        [p.x + t, p.y + t],
      ] as any;
      const features = map.queryRenderedFeatures(bbox, { layers }).filter(f => f.properties?.asset_code);
      const uniqueFeatures = [...new Map(features.map(item => [item.id, item])).values()];
      const candidate = uniqueFeatures[0];

      if (!candidate) return;

      const props = candidate.properties;
      const layer = candidate.layer.id;
      const source = candidate.source;
      const assetId = props?.asset_id;

      const hasN1Route = source.startsWith('n_1__') && !layer.endsWith('additional') && props?.in_theme;

      if (!hasN1Route || !assetId) return;

      dispatch(fetchN1RouteAction({ asset_uuid: assetId }));
    };

    map.on('mousedown', onMapClick);
    return () => {
      map.off('mousedown', onMapClick);
    };
  }, [map, dispatch, settings.infoLayers]);

  // update styles for routes
  useEffect(() => {
    filterN1(map, selectedN1Routes, selectedN1RouteId, settings);
  }, [map, selectedN1Routes, selectedN1RouteId]); // eslint-disable-line

  // show all necessary layers for current routes
  useEffect(() => {
    const n1Layers = (selectedN1Routes ?? []).flatMap(i => Object.keys(i.ids));
    const themeLayers = [...new Set(n1Layers)].map(i => i.replace('n_1__', ''));
    if (!selectedN1Routes) return;
    dispatch(mapStateAction(getInitMapState(settings, layerFilters, false, themeLayers)));
  }, [dispatch, selectedN1Routes]); // eslint-disable-line

  // show and update all layers when theme is active
  useEffect(() => {
    showTheme(map, settings.n1Layers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  // hide all theme layers when theme is inactive
  useEffect(() => {
    return () => {
      hideTheme(map, settings.n1Layers!);
      dispatch(setN1RouteAction(null));
    };
  }, [dispatch, map]); // eslint-disable-line

  // handle route state from n1 table
  useEffect(() => {
    if (!history.location.state?.n1Route) return;
    const { target_id, md5sum, md5sum_normal } = parseObject(history.location.state.n1Route);
    dispatch(fetchN1RouteAction({ asset_uuid: target_id, md5sum, md5sum_normal }));
    dispatch(mapStateAction(getInitMapState(settings, layerFilters, true)));
  }, [dispatch, history]); // eslint-disable-line

  return null;
};

export default React.memo(N1);
