import React from 'react';
import { componentPriceTypeOptionsSelector } from 'modules/options/selectors';
import { fetchComponentPriceTypeOptionsAction } from 'modules/options';
import { SelectRedux, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectPriceType: React.FC<Props> = props => (
  <SelectRedux
    {...props}
    optionsSelector={componentPriceTypeOptionsSelector}
    optionsAction={fetchComponentPriceTypeOptionsAction}
  />
);

export default SelectPriceType;
