import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { PageSmartMeterDetails } from 'pages';
import { SmartMeterActionsTable, MetersPopulationTable } from 'components/_tables';
import { MetersPopulationModal } from 'components/_modals';
import PagePermissionsProvider from './PagePermissionsProvider';
import { Routes, PermissionsTypes } from 'constants/index';

const PageMeters: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isSmartMetersEnabled}>
    <Switch>
      <Route path={Routes.MetersPopulation}>
        <>
          <MetersPopulationModal />
          <MetersPopulationTable />
        </>
      </Route>
      {/* Routes.Meters route must be specified last  */}
      <Route
        exact
        path={Routes.Meters}
        render={
          ((props: RouteComponentProps<{ id: string }>) =>
            props.match.params.id ? <PageSmartMeterDetails {...props} /> : <SmartMeterActionsTable />) as any
        }
      />
    </Switch>
  </PagePermissionsProvider>
);

export default PageMeters;
