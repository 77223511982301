import React, { useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { setSelectedSimulationAction } from 'modules/map';
import { simulationsSelector, selectedSimulationSelector } from 'modules/map/selectors';
import ControlBox from 'components/Map/common/ControlBox';
import CollapseGroup from 'components/Map/common/CollapseGroup';
import { ReactComponent as GroupIcon } from 'components/Map/icons/icon • map panel section • added objects.svg';

const FilterSimulationVersion: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const simulations = useSelector(simulationsSelector);
  const selectedSimulation = useSelector(selectedSimulationSelector);
  const selectedSimulationId = `${selectedSimulation?.version_value}-${selectedSimulation?.value}`;

  const setSelectedSimulation = useCallback(
    (s: Map.SimulationFilter) => {
      dispatch(setSelectedSimulationAction(s));
    },
    [dispatch]
  );

  const getSimulationLabel = useCallback(
    (s: Map.SimulationFilter | null) => {
      if (!s) return getIntl('Nothing selected');
      return `${moment(s.date).format('YYYY-MM-DD HH:mm')} • ${getIntl('version')} ${s.version_value} • ${getIntl(
        'simulation'
      )} ${s.value}`;
    },
    [getIntl]
  );

  return (
    <StyledCollapseGroup
      id="simulations"
      titleKey="Assets and simulation version"
      icon={<GroupIcon className="filter-group-icon" />}
      description={getSimulationLabel(selectedSimulation)}
      disabled={!simulations?.length}
    >
      <OverlayScrollbarsComponent style={{ maxHeight: '140px' }}>
        <div className="scroll-inner">
          {simulations?.map(s => {
            const id = `${s.version_value}-${s.value}`;
            return (
              <div className="item" key={id}>
                <ControlBox
                  type="radio"
                  id={id}
                  name={id}
                  checked={selectedSimulationId === id}
                  labelKey={getSimulationLabel(s)}
                  onChange={() => setSelectedSimulation(s)}
                />
              </div>
            );
          })}
        </div>
      </OverlayScrollbarsComponent>
    </StyledCollapseGroup>
  );
};

const StyledCollapseGroup = styled(CollapseGroup)`
  .os-host {
    margin: 0 -18px;
  }

  .scroll-inner {
    padding: 0 18px;
    position: relative;
  }
`;

export default FilterSimulationVersion;
