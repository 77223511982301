import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectVoltageUnits } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Type.SelectVoltageUnits | null;
}

const SelectVoltageUnit: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const LabelsMap = useMemo(() => ({ [SelectVoltageUnits.False]: 'kV', [SelectVoltageUnits.True]: 'per unit' }), []);

  const { options, selectValue } = useMemo(
    () =>
      Object.keys(SelectVoltageUnits).reduce(
        (acc: any, key: string) => {
          const item = {
            value: key,
            label: getIntl(LabelsMap[key as Type.SelectVoltageUnits]),
          };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, LabelsMap, getIntl]
  );

  return <FormReactSelect value={selectValue} options={options} {...props} />;
};

export default SelectVoltageUnit;
