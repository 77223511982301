import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationSelectedOptionVersionIdSelector } from 'modules/options/selectors';
import { mapPanelSelectedAssetUUIDSelector } from 'modules/router/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { getGalleryImages } from 'utils';
import { _keyBy, _pick } from '@utiligize/shared/utils';
import { AssetCategories, PaginationType, plumberAPI } from 'constants/index';

const baseUrl: string = 'api/admin/v1/secure/';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchAssetCategoriesAction: any = createAction(
  'assets/FETCH_CATEGORIES',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'categoriesCount' | 'categoriesHash' | 'categoriesFetched'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(PaginationType.CATEGORIES)(state);
      return axios
        .get(`${baseUrl}assetcategories`, {
          params: {
            limit: skipPagination ? null : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          categoriesCount: res.data.countAssetCategories,
          categoriesHash: _keyBy(res.data.assetCategories, (item: Asset.Category) => `_${item.id}_`),
          categoriesFetched: true,
          skipStoreUpdate,
        }));
    }
);

export const createAssetCategoryAction = createAction(
  'assets/CREATE_CATEGORY',
  async (category: { name: string; code: string }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post(`${baseUrl}assetcategories`, { isNew: true, deletemark: false, ...category }).then(async () => {
        await dispatch(fetchAssetCategoriesAction());
        dispatch(setSuccessToastAction('Asset category has been created'));
      });
    }
);

export const deleteAssetCategoryAction = createAction('assets/DELETE_CATEGORY', async (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return axios.delete(`${baseUrl}assetcategories/${id}`).then(async () => {
      await dispatch(fetchAssetCategoriesAction());
      dispatch(setSuccessToastAction('Asset category has been deleted'));
    });
  };
});

export const fetchAssetsAction = createAction(
  'assets/FETCH_ASSETS',
  async () =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'assetsCount' | 'assetsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.ASSETS)(state);
      return axios
        .get(`${baseUrl}assets`, {
          params: {
            assetCategoryCode: filters?.assetcategoryCode,
            limit,
            offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          assetsCount: res.data.count,
          assetsHash: _keyBy(res.data.rows, (item: Asset.Item) => `_${item.id}_`),
        }));
    }
);

export const fetchAssetAction = createAction(
  'assets/FETCH_ASSET',
  ({ uuid, assetCode, simulationVersionId }: { uuid?: string; assetCode?: string; simulationVersionId?: number }) =>
    (): Promise<Asset.ExtendedItem | null> => {
      return axios
        .get(`api/admin/v2/secure/assets/${uuid || assetCode}`, { params: { versionId: simulationVersionId } })
        .then(res => {
          const attachments = res.data.attachments || res.data.liveData?.attachments;
          const images = attachments
            ? getGalleryImages(
                attachments.filter((attachment: Type.Attachment) => attachment.width && attachment.height)
              )
            : undefined;
          return {
            ...res.data,
            images,
            ...(res.data.liveData ? { liveData: { ...res.data.liveData, images } } : null),
          };
        })
        .catch(() => null);
    }
);

export const createAssetAction = createAction(
  'assets/CREATE_ASSET',
  (assetMeta: Asset.Item) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post(`${baseUrl}assets?assetCategoryCode=${AssetCategories.BD}`, assetMeta).then(async () => {
        // redux state must be updated with for assigned meter number
        await dispatch(fetchSmartMetersAction());
        dispatch(setSuccessToastAction('Asset has been created'));
      });
    }
);

export const updateAssetAction = createAction(
  'assets/UPDATE_ASSET',
  (assetMeta: Asset.Item) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      return axios.put(`${baseUrl}assets/${assetMeta.assetCode}`, assetMeta).then(async () => {
        const state = getState();
        // redux state must be updated with for assigned meter number
        if (assetMeta.elInTablID) await dispatch(fetchSmartMetersAction());
        // update asset meta for UnifiedAssetPanel
        const uuid = mapPanelSelectedAssetUUIDSelector(state);
        const simulationVersionId = simulationSelectedOptionVersionIdSelector(state);
        if (uuid && simulationVersionId) {
          await dispatch(fetchAssetAction({ uuid, simulationVersionId }));
        }
        dispatch(setSuccessToastAction('Asset has been saved'));
      });
    }
);

export const deleteAssetAction = createAction('assets/DELETE_ASSET', async (assetCode: string) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return axios.delete(`${baseUrl}assets/${assetCode}`).then(async () => {
      await dispatch(fetchAssetsAction());
      dispatch(setSuccessToastAction('Asset has been deleted'));
    });
  };
});

export const deleteAssetPhotosAction = createAction(
  'assets/DELETE_ASSET_PHOTOS',
  ({ uuid, assetCode, attachmentKeys }: { uuid?: string; assetCode?: string; attachmentKeys: string[] }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const simulationVersionId = simulationSelectedOptionVersionIdSelector(getState());
      return axios
        .post(`api/admin/v2/secure/assets/${uuid || assetCode}/attachments/delete`, {
          attachmentKeys,
          versionId: simulationVersionId,
        })
        .then(() => {
          dispatch(setSuccessToastAction('Selected photos has been deleted'));
        });
    }
);

export const fetchModelsByAssetCategoryAction = createAction(
  'assets/FETCH_MODELS_BY_CATEGORY',
  (categoryCode: Type.AssetCategories) => (): Promise<AssetModels.Item[]> => {
    return axios.get(`${baseUrl}assets/${categoryCode}/assetmodels`).then(res => res.data.rows);
  }
);

export const fetchSubComponentsAction: any = createAction(
  'assets/FETCH_SUB_COMPONENTS',
  async (
    {
      skipPagination,
      code,
      skipStoreUpdate,
    }: { skipPagination: boolean; code?: Type.AssetCategories; skipStoreUpdate: true | undefined } = {
      skipPagination: false,
      skipStoreUpdate: undefined,
    }
  ) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'subComponentsCount' | 'subComponentsHash'>> => {
      const state = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.SUB_COMPONENTS)(
        state
      );
      return axios
        .get(`${baseUrl}subcomponentsType`, {
          params: {
            assetCategoryCode: code || filters?.assetcategoryCode,
            limit: skipPagination ? null : limit,
            offset: skipPagination ? null : offset,
            sort,
            column,
            query: skipPagination ? null : query,
            lang: appLangSelector(state).toLowerCase(),
          },
        })
        .then((res: any) => ({
          subComponentsCount: res.data.count,
          subComponentsHash: _keyBy(res.data.rows, (item: Asset.SubComponentType) => `_${item.id}_`),
          skipStoreUpdate,
        }))
        .catch(() => ({ subComponentsCount: 0, subComponentsHash: {} }));
    }
);

export const createSubComponentAction = createAction(
  'assets/CREATE_SUB_COMPONENT',
  async (data: { name: string; assetCategoryCode: Type.AssetCategories }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.post(`${baseUrl}subcomponentsType`, data).then(async (res: any) => {
        await dispatch(fetchSubComponentsAction());
        dispatch(setSuccessToastAction('Asset sub-component type has been created'));
        return res.data;
      });
    }
);

export const updateSubComponentAction = createAction(
  'assets/UPDATE_SUB_COMPONENT',
  async (data: { id: number; name: string; assetCategoryCode: Type.AssetCategories }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      return axios.put(`${baseUrl}subcomponentsType`, data).then(async () => {
        await dispatch(fetchSubComponentsAction());
        dispatch(setSuccessToastAction('Asset sub-component type has been updated'));
      });
    }
);

export const deleteSubComponentAction = createAction('assets/DELETE_SUB_COMPONENT', (id: number) => {
  return (dispatch: Shared.CustomDispatch): Promise<void> => {
    return axios.delete(`${baseUrl}subcomponentsType/${id}`).then(async () => {
      await dispatch(fetchSubComponentsAction());
      dispatch(setSuccessToastAction('Asset sub-component type has been deleted'));
    });
  };
});

export const fetchSmartMetersAction = createAction(
  'assets/FETCH_SMART_METERS',
  () => (): Promise<Pick<Asset.Root, 'smartMetersHash' | 'smartMetersHashFetched'>> =>
    axios.get(`${baseUrl}smartMeters`).then(res => ({
      smartMetersHash: _keyBy(res.data.rows, (item: Asset.SmartMeter) => `_${item.elInTablID}_`),
      smartMetersHashFetched: true,
    }))
);

export const fetchAssetTaskCompletionsAction = createAction(
  'assets/FETCH_ASSET_TASK_COMPLETIONS',
  async ({ uuid, assetCode }: { uuid?: string; assetCode?: string }) =>
    (): Promise<Pick<Asset.Root, 'taskCompletionsCount' | 'taskCompletions'>> =>
      axios.get(`api/admin/v2/secure/assets/${uuid || assetCode}/taskCompletions`).then((res: any) => ({
        taskCompletionsCount: res.data.taskCompletions.length,
        taskCompletions: res.data.taskCompletions,
      }))
);

export const fetchAssetCodeAutoCompleteAction: any = createAction(
  'assets/FETCH_ASSET_CODE_AUTO_COMPLETE',
  async (query: string) => (): Promise<string[]> =>
    axios
      .get(`${baseUrl}assets/codes/validate/${query}`)
      .then(res => res.data)
      .catch(() => [])
);

export const fetchAssetCodeAutoCompleteNewAction: any = createAction(
  'assets/FETCH_ASSET_CODE_AUTO_COMPLETE_NEW',
  async (query: string, withGeometry = true) =>
    (_dispatch: Function, getState: () => State.Root): Promise<string[]> => {
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const simulationVersionId = simulationSelectedOptionVersionIdSelector(state);
      return axios
        .get<Asset.AssetCodeItem[]>(`api/admin/v2/secure/assets/codes/search/${encodeURIComponent(query)}`, {
          params: {
            with_geometry: withGeometry,
            portfolio_id: portfolioId,
            version_id: simulationVersionId,
          },
        })
        .then(res => res.data.map(item => _pick(item, ['code', 'uuid', 'map_asset_name', 'voltage_level'])))
        .catch(() => [] as any);
    }
);

export const fetchInstallationNumberAutoCompleteAction: any = createAction(
  'assets/FETCH_INSTALLATION_NUMBER_AUTO_COMPLETE',
  async (query: string) => (): Promise<string[]> =>
    axios
      .get(`${baseUrl}smartMeters/codes/validate/${query}`)
      .then(res => res.data)
      .catch(() => [])
);

export const fetchMissingAgeAction: any = createAction(
  'assets/FETCH_MISSING_AGE',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Asset.Root, 'missingAgeCount' | 'missingAgeHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(
        PaginationType.ASSETS_MISSING_AGE
      )(state);
      return plumberAPI
        .get('missing_age', {
          params: {
            limit: skipPagination ? 10000 : limit,
            offset: skipPagination ? 0 : offset,
            sort,
            column,
            query: skipPagination ? '' : query,
            lang: appLangSelector(state).toLowerCase(),
            asset_category: filters?.assetcategoryCode,
          },
        })
        .then((res: any) => ({
          missingAgeCount: res.data.count,
          missingAgeHash: _keyBy(res.data.rows, (item: Asset.MissingAge) => `_${item.id}_`),
          skipStoreUpdate,
        }));
    }
);
