import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.inspections;

export const inspectionsCountSelector = createSelector(
  rootSelector,
  (inspections: Inspections.Root): number => inspections.count
);

export const inspectionsItemsSelector = createSelector(
  rootSelector,
  (inspections: Inspections.Root): Inspections.Item[] => inspections.rows
);

export const inspectionsBuilderDataSelector = createSelector(
  rootSelector,
  (inspections: Inspections.Root): Builder.Data => inspections.builderData
);
