import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fetchModelsByAssetCategoryAction } from 'modules/assets';
import { FormikProps } from './useConfiguredFormik';
import SubComponentItem from './SubComponentItem';
import { _set, _cloneDeep } from '@utiligize/shared/utils';

interface Props {
  categoryCode: Type.AssetCategories | null;
  models: Asset.AssetModel[];
  formik: FormikProps;
}

const SubComponents: React.FC<Props> = ({ categoryCode, models, formik }) => {
  const { setFieldValue } = formik;
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [assetModels, setAssetModels] = useState<AssetModels.Item[] | null>(null);

  useEffect(() => {
    if (!categoryCode) return;
    dispatch(fetchModelsByAssetCategoryAction(categoryCode)).then((action: Shared.ReduxAction<AssetModels.Item[]>) =>
      setAssetModels(action.payload)
    );
  }, [categoryCode, dispatch]);

  const manufacturerOptions = useMemo(() => {
    if (!assetModels) return [];
    const { items } = assetModels.reduce(
      (acc: any, item: AssetModels.Item) => {
        if (acc.memo.includes(item.manufacturerId)) return acc;
        acc.memo.push(item.manufacturerId);
        acc.items.push({ value: item.manufacturerId, label: item.manufacturerName });
        return acc;
      },
      { items: [], memo: [] }
    );
    return items;
  }, [assetModels]);

  const handleManufacturerChange = useCallback(
    (value: { value: number; label: string }, actionMeta: any): void => {
      const index = Number(actionMeta.name);
      const modelsClone = _cloneDeep(models);
      _set(modelsClone, `[${index}].manufacturerId`, value?.value || null);
      _set(modelsClone, `[${index}].manufacturerName`, value?.label || '');
      _set(modelsClone, `[${index}].modelId`, null);
      _set(modelsClone, `[${index}].modelName`, '');

      setFieldValue('models', modelsClone);
    },
    [models, setFieldValue]
  );

  const handleAssetModelChange = useCallback(
    (value: { value: number; label: string }, actionMeta: any): void => {
      const index = Number(actionMeta.name);
      const modelsClone = _cloneDeep(models);
      _set(modelsClone, `[${index}].modelId`, value?.value || null);
      _set(modelsClone, `[${index}].modelName`, value?.label || '');
      if (!value?.value) {
        _set(modelsClone, `[${index}].manufacturerId`, null);
        _set(modelsClone, `[${index}].manufacturerName`, '');
      }
      setFieldValue('models', modelsClone);
    },
    [models, setFieldValue]
  );

  const handleRemoveSubcomponentButtonClick = useCallback(
    (event: React.SyntheticEvent): void => {
      if (!models.length) return;
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      setFieldValue(
        'models',
        models.filter(m => m.subcomponentId !== id)
      );
    },
    [models, setFieldValue]
  );

  return (
    <>
      {models?.map((model: Asset.AssetModel, index: number) => (
        <SubComponentItem
          key={model.subcomponentId || index}
          index={index}
          categoryCode={categoryCode}
          models={models}
          manufacturerOptions={manufacturerOptions}
          assetModels={assetModels}
          handleManufacturerChange={handleManufacturerChange}
          handleAssetModelChange={handleAssetModelChange}
          handleRemoveSubcomponentButtonClick={handleRemoveSubcomponentButtonClick}
        />
      ))}
    </>
  );
};

export default SubComponents;
