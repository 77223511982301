import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useRecalculateButton, useBudgetingResetButton } from 'hooks';
import { recalculateBudgetingAction } from 'modules/budgeting';
import { DataTableTemplate } from 'components/_common';
// import { capitalizeFirstLetter } from 'utils';
import { PriceTypes } from 'constants/index';
import BudgetingRevenueCapTabs from './BudgetingRevenueCapTabs';

const BudgetingRevenueCap: React.FC = () => {
  // const { getIntl } = useLocale();
  const { loading: resetLoading, ButtonReset } = useBudgetingResetButton();
  const { loading, ButtonRecalculate } = useRecalculateButton(recalculateBudgetingAction);
  const [priceType /* setPriceType */] = React.useState(PriceTypes.FIXED);

  // const priceTypeOptions = useMemo(
  //   () =>
  //     Object.values(PriceTypes)
  //       .filter(i => typeof i !== 'number')
  //       .map((label: string | PriceTypes) => ({ value: label, label: getIntl(capitalizeFirstLetter(label)) })),
  //   [getIntl]
  // );

  // const handleChangePriceTypeSelect = useCallback(value => {
  //   setPriceType(value.value);
  // }, []);

  return (
    <DataTableTemplate>
      <Row>
        {/* <Col xl={2} xs={3}>
          <FormReactSelect
            labelKey=""
            options={priceTypeOptions}
            value={{ value: priceType, label: getIntl(capitalizeFirstLetter(priceType)) }}
            onChange={handleChangePriceTypeSelect}
          />
        </Col> */}
        <Col>
          {ButtonReset}
          {ButtonRecalculate}
        </Col>
      </Row>
      <BudgetingRevenueCapTabs loading={resetLoading || loading} priceType={priceType} />
    </DataTableTemplate>
  );
};

export default BudgetingRevenueCap;
