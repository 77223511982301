import React from 'react';
import { DataTableTemplate, ContentContainer, ContentCard } from 'components/_common';
import ChartCO2Emission, { DataItem } from './ChartCO2Emission';
import Filters from './Filters';

const ResponseUsedKeysLocalesMap: { [key in keyof DataItem]?: string } = {
  scope_1_t_co2e: 'Scope 1',
  scope_2_t_co2e: 'Scope 2',
  scope_3_t_co2e: 'Scope 3',
};

const ViewEmissionsByScope: React.FC = () => (
  <DataTableTemplate paddingBottom>
    <Filters />
    <ContentContainer>
      <ContentCard>
        <ChartCO2Emission
          enableColumnChartWhenConditionsMet
          titleKey="Emissions by scope"
          ResponseUsedKeysLocalesMap={ResponseUsedKeysLocalesMap}
        />
      </ContentCard>
    </ContentContainer>
  </DataTableTemplate>
);

export default ViewEmissionsByScope;
