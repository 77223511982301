import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.manufacturers;

export const manufacturersItemsSelector = createSelector(
  rootSelector,
  (manufacturers: Manufacturers.Root): Manufacturers.Item[] => manufacturers.items
);

export const manufacturersCountSelector = createSelector(
  rootSelector,
  (manufacturers: Manufacturers.Root): number => manufacturers.count
);
