import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { enabledLayersListSelector, settingsSelector, showCableAnimationSelector } from 'modules/map/selectors';
import { showTheme, hideTheme } from 'utils/map';

interface Props {
  map: Map.MapboxMap;
}

const Assets: React.FC<Props> = ({ map }) => {
  const settings = useSelector(settingsSelector);
  const showCableAnimation = useSelector(showCableAnimationSelector);
  const enabledLayersList = useSelector(enabledLayersListSelector);

  useEffect(() => {
    showTheme(map, settings.assetLayers!, enabledLayersList, showCableAnimation);
  }, [map, enabledLayersList, showCableAnimation]); // eslint-disable-line

  useEffect(() => {
    return () => {
      hideTheme(map, settings.assetLayers!);
    };
  }, [map]); // eslint-disable-line

  return null;
};

export default React.memo(Assets);
