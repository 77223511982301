import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import { transformersSelector, transformersCountSelector } from 'modules/networkLoading/selectors';
import { fetchTransformersAction } from 'modules/networkLoading';
import { setProfileModalMetaAction } from 'modules/layouts';
import { paginationSelectorFactory, portfolioIdSelector, scenarioIdSelector } from 'modules/layouts/selectors';
import { DataTable, Button, SolvedOption } from 'components/_common';
import { PaginationType, Routes, ProfileModalTypes, TableHeaders, BICalculations } from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const TableTransformers: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const paginationType = PaginationType.TRANSFORMERS;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(paginationType));
  const isBICalculationFilterOn = filters?.BICalculation === BICalculations.On;
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);

  const items = useSelector(transformersSelector);
  const count = useSelector(transformersCountSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTransformersAction(params)).then(
        (action: Shared.ReduxAction<{ transformersHash: Type.Hash<NetworkLoading.Transformer> }>) =>
          Object.values(action.payload?.transformersHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(setProfileModalMetaAction({ id: assetName, type: ProfileModalTypes.Transformer }));
    },
    [dispatch]
  );

  const customHeaders = useMemo(
    () => [
      ...TableHeaders[paginationType].reduce((acc: Type.DataTableHeader[], header) => {
        if (isBICalculationFilterOn && header.titleKey === 'Voltage') {
          acc.push({
            titleKey: 'Net consumption',
            className: 'text-center',
            subTitles: [
              { titleKey: 'Min', sortKey: 'max_production' },
              { titleKey: 'Max', sortKey: 'max_consumption' },
            ],
          });
        } else {
          acc.push(header);
        }
        return acc;
      }, []),
    ],
    [paginationType, isBICalculationFilterOn]
  );

  return (
    <DataTable
      customHeaders={customHeaders}
      paginationType={paginationType}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={!portfolioId || !scenarioId || !Boolean(filters?.percentile && filters?.year)}
      triggerTableUpdateDeps={[portfolioId, scenarioId]}
      maxHeight="calc(100vh - 369px)"
    >
      {items?.map((item: NetworkLoading.Transformer) => (
        <tr key={item.customer_asset_name}>
          <td>
            <Link to={generatePath(Routes.Map, { uuid: item.id })}>{item.customer_asset_name}</Link>
          </td>
          <td>{item.date_commissioned ? dateFormat(item.date_commissioned) : '-'}</td>
          <td>{item.kva}</td>
          <td className="text-center">
            <SolvedOption uuid={item.id} checked={item.ignore} paginationType={paginationType} />
          </td>
          <td>{item.explanation || '-'}</td>
          <td>{numberFormat(item.maxLoading)}</td>
          <td>{numberFormat(item.maxReactive)}</td>
          <td>{numberFormat(item.gridLosses)}</td>
          <td className="text-nowrap">{dateFormat(item.maxLoadingTime, 'L LT')}</td>
          <td>{numberFormat(item.utilization)}</td>
          <td>{numberFormat(item.annualConsumption, { fallback: '-' })}</td>
          {isBICalculationFilterOn ? (
            <>
              <td className="text-nowrap">{numberFormat(item.max_production, { fallback: '-' })}</td>
              <td className="text-nowrap">{numberFormat(item.max_consumption, { fallback: '-' })}</td>
            </>
          ) : (
            <>
              <td className="text-nowrap">{numberFormat(item.voltage_min, { fallback: '-' })}</td>
              <td className="text-nowrap">{numberFormat(item.voltage_max, { fallback: '-' })}</td>
            </>
          )}
          <td className="text-right">
            <Button
              dataMarker="actions_profile"
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
              variant="primary"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableTransformers;
