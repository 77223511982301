import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createPermissionsGroupAction, editPermissionsGroupAction } from 'modules/permissions';

interface Props {
  id: number | null;
  permissionsTypes: string[] | null;
  toggleModal: () => void;
}

const useConfiguredFormik = ({ id, permissionsTypes, toggleModal }: Props) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const formik = useFormik<Omit<Permissions.Group, 'id'>>({
    initialValues: {
      name: '',
      permissionsTypes: (permissionsTypes || ([] as any)).reduce(
        (acc: Permissions.Permissions, key: Permissions.PermissionsTypes) => {
          acc[key] = false;
          return acc;
        },
        {}
      ),
      isDefault: false,
    },

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      name: Yup.string().required("Field can't be empty!"),
      permissionsTypes: Yup.object().test(
        'test-permissions-types',
        'You must select at least one view permission!',
        (permissionsTypes: any) => {
          return Object.keys(permissionsTypes).some((key: string) =>
            Boolean(
              permissionsTypes[key as Permissions.PermissionsTypes] &&
                !key.includes('ReadOnly') &&
                !key.includes('isTaskType')
            )
          );
        }
      ),
    }),

    onSubmit: values => {
      if (id) return dispatch(editPermissionsGroupAction(id, values)).then(toggleModal).catch(console.error);
      return dispatch(createPermissionsGroupAction(values)).then(toggleModal).catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
