import React, { useCallback } from 'react';
import { FormikErrors } from 'formik';
import { Col, Form } from 'react-bootstrap';
import { SelectEmployee } from 'components/_common';
import { DataTableFilterKeys } from 'constants/index';

interface Props {
  inDataTableHeaderFilters?: boolean;
  values: Layouts.Filters;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<Layouts.Filters>> | Promise<void>;
  submitForm: () => Promise<any>;
}

const SelectEmployeesFilter: React.FC<Props> = ({
  inDataTableHeaderFilters = false,
  values,
  setFieldValue,
  submitForm,
  ...props
}) => {
  const handleSelectChange = useCallback(
    async (userIds: number[]) => {
      await setFieldValue(DataTableFilterKeys.EMPLOYEES_IDS, userIds?.length ? userIds : null);
      if (inDataTableHeaderFilters) submitForm();
    },
    [setFieldValue, inDataTableHeaderFilters, submitForm]
  );

  if (!values.hasOwnProperty(DataTableFilterKeys.EMPLOYEES_IDS)) return null;

  const Select = (
    <SelectEmployee
      labelKey={inDataTableHeaderFilters ? '' : undefined}
      {...props}
      ids={values[DataTableFilterKeys.EMPLOYEES_IDS] || []}
      setIds={handleSelectChange}
      isMulti
    />
  );

  return inDataTableHeaderFilters ? <Col sm={4}>{Select}</Col> : <Form.Group>{Select}</Form.Group>;
};

export default SelectEmployeesFilter;
