import React from 'react';
import { useLocale } from 'hooks';

interface Props {
  values: string[];
  showMoreItems?: boolean;
  defaultValue?: string;
}

const GroupHiddenDescription: React.FC<Props> = ({ values, showMoreItems, defaultValue = '' }) => {
  const { getIntl } = useLocale();
  const itemCount = showMoreItems || values.length < 3 ? values.length : 3;
  const hasMoreItems = values.length > 3;
  const lastItem = itemCount - 1;

  if (!Boolean(values.length)) return <>{getIntl(defaultValue)}</>;
  return (
    <>
      {values.slice(0, itemCount).map((v, i) => (
        <span key={i}>
          {getIntl(v)}
          {i !== lastItem && <span>,&nbsp;</span>}
          {i === lastItem && hasMoreItems && !showMoreItems && <span>,&nbsp;{getIntl('etc')}.</span>}
        </span>
      ))}
    </>
  );
};

export default GroupHiddenDescription;
